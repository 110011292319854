import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditGrupoRecintosComponent } from './edit-grupo-recintos/edit-grupo-recintos.component';

@Component({
  selector: 'app-grupo-recintos',
  templateUrl: './grupo-recintos.component.html',
  styleUrls: ['./grupo-recintos.component.scss']
})
export class GrupoRecintosComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;

  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.closepanel();
  }

  openPanel() {
    this.customanpanel = EditGrupoRecintosComponent
    this.editChangeCss();
    this.cdRef.detectChanges();

  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      this.customanpanel = undefined;
      this.closePanelChangeCss();

    })
    
  }

  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){

    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }
}
