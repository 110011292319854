import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditRecintosVisitaComponent } from './edit-recintos-visita/edit-recintos-visita.component';

@Component({
  selector: 'app-recintos-visita',
  templateUrl: './recintos-visita.component.html',
  styleUrls: ['./recintos-visita.component.scss']
})
export class RecintosVisitaComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(private panelService: PanelService,
    private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.closepanel()
  }

  openPanel() {
    //this.customanpanel = EditClientGroupComponent;
    this.customanpanel = EditRecintosVisitaComponent;
    this.editChangeCss()
    this.cdRef.detectChanges();
  }

  editChangeCss() {
    let classElem;
    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "none"

    }
  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close => {
      console.log("closepanel subscribe")
      this.customanpanel = undefined;
      this.closePanelChangeCss()
    })

  }

  closePanelChangeCss(){

    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
    }
  }

}
