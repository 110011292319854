<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="editor-registro">
  <div class="divForm">
    <form [formGroup]="editRegistroForm" (onSubmit)="submit()">

      <div class="form-group row">

        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Nombre}}</mat-label>
            <input matInput formControlName="Nombre" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Nombre')">
              {{getErrorMessage("Denominacion")}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

        <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Descripcion}}</mat-label>
            <input matInput formControlName="Descripcion" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Descripcion')">
              {{getErrorMessage("Descripcion")}}
            </mat-error>
          </mat-form-field>
        </div>
        </div>
        <div class="form-group row">
        <div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Orden}}</mat-label>
            <input type="number" matInput formControlName="Orden" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Orden')">
              {{getErrorMessage("Orden")}}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
     
      <div style="margin: 20px 5px 0 5px; text-align: center;">
        <mat-label>{{translation.Centros}}</mat-label>
  
        <customan-dual-list [source]="centrosSinAsignar"
        [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
        </customan-dual-list>
      </div>
      
    </form>
  </div>
  </div>
</div>