import { Injectable } from '@angular/core';
import { Butaca } from '../modelos/butaca.model';
import { Subject, BehaviorSubject, Observable, forkJoin, onErrorResumeNext } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InfoMapasService } from './info-mapas.service';
import { mergeMap, map } from 'rxjs/operators';
import { ModalService } from './modal.service';
//import { AppConfiguration } from 'src/app/app.configuration';
import { TranslateService } from 'src/app/shared/translate.service';
//import { ConfigurationService } from 'src/app/core/config/configuration.service';
import * as d3 from 'd3';
//import { TokenManagerService } from 'src/app/core/token/token-manager.service';
import { ButacasService } from './butacas.service';

interface HashTable<T> {
  [key: string]: T;
}
@Injectable({
  providedIn: 'root'
})
export class EntradasService {
  datosButacaActualizados = new BehaviorSubject(false);
  primeraButacaMultiple: Butaca;
  butacasMarcadasMultiple: Butaca[];
  idsSeleccionados: number[] = [];
  butacasMap: HashTable<string> = {};
  seleccionMultiple: boolean;
  nombreRecinto: any;
  noNumeradas = false;
  listaButacasDisponibles = '';
  butacasConPrecio = [];
  filtroPreparado = new BehaviorSubject(false);
  maxFiltro: number;
  minFiltro: number;
  leyenda = [];
  leyendaSubject = new Subject();
  butacasDeshabilitadasPorFiltro = [];
  listaRecintosPorSesion = []; // Respuesta de /GetTipoButacasPorRecinto
  listaIdRecintosNUMERADOS: number[] = [];
  listaIdRecintosNONUMERADOS: number[] = [];
  prefijo = '';
  colorStroke: string;
  butacaSeleccionada: boolean;


/*   const private plataformas = [{
    id: 1,
    clic: 'ClicInternet',
    mostrar: 'MostrarInternet'
  },
  {
    id: 2,
    clic: 'ClicTaquilla',
    mostrar: 'MostrarTaquilla'
  },
  {
    id: 3,
    clic: 'ClicReserva',
    mostrar: 'MostrarReserva'
  }]; */


  private butacaMarcadaSubject: BehaviorSubject<any>;
  public butacaMarcada: Observable<any>;

  private butacasNONumeradaMarcadaSubject: BehaviorSubject<any>;
  public butacasNONumeradaMarcada: Observable<any>;

  private entradasSeleccionadasSubject: BehaviorSubject<any>;
  public entradasSeleccionadas: Observable<any>;

  private estadosButacasSubject: BehaviorSubject<any>;
  public estadosButacas: Observable<HashTable<any>>;

  private noNumeradaSubject: BehaviorSubject<any>;
  public noNumerada: Observable<any>;

  private asientosDeshabilitadosSubject: BehaviorSubject<any>;
  public asientosDeshabilitados: Observable<any>;

  constructor(
    private http: HttpClient,
    private infoMapasService: InfoMapasService,
    private modalService: ModalService,
    //private config: AppConfiguration,
    //private configurationService: ConfigurationService,
    private butacasService: ButacasService
  ) {
    this.seleccionMultiple = false;

    this.butacaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacaMarcada = this.butacaMarcadaSubject.asObservable();

    this.butacasNONumeradaMarcadaSubject = new BehaviorSubject(undefined);
    this.butacasNONumeradaMarcada = this.butacasNONumeradaMarcadaSubject.asObservable();

    this.entradasSeleccionadasSubject = new BehaviorSubject([]);
    this.entradasSeleccionadas = this.entradasSeleccionadasSubject.asObservable();

    this.estadosButacasSubject = new BehaviorSubject([]);
    this.estadosButacas = this.estadosButacasSubject.asObservable();

    this.noNumeradaSubject = new BehaviorSubject(false);
    this.noNumerada = this.noNumeradaSubject.asObservable();

    this.asientosDeshabilitadosSubject = new BehaviorSubject([]);
    this.asientosDeshabilitados = this.asientosDeshabilitadosSubject.asObservable();
  }

  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    this.modalService.close(id);
  }

  public get asientosDeshabilitadosValue() {
    return this.asientosDeshabilitadosSubject.value;
  }

  public setAsientosDeshablitados(asientos) {
    const uniqueAsientos = Array.from(new Set(asientos));
    this.asientosDeshabilitadosSubject.next(uniqueAsientos);
  }

  public addAsientoDeshabilitado(asiento) {
    const asientos = this.asientosDeshabilitadosValue;
    asientos.push(asiento);
    this.setAsientosDeshablitados(asientos);
  }

  public get estadosButacasValue() {
    return this.estadosButacasSubject.value;
  }

  public setEstadosButacaValue(estados) {
    this.estadosButacasSubject.next(estados);
  }

  public get entradasSeleccionadasValue() {
    return this.entradasSeleccionadasSubject.value;
  }

  public setEntradasSeleccionadasValue(entradas) {
    this.entradasSeleccionadasSubject.next(entradas);
  }

  public pushEntrada(entrada) {
    const newEntradas = this.entradasSeleccionadasValue.push(entrada);
    this.entradasSeleccionadasSubject.next(newEntradas);
  }

  public get butacaMarcadaValue() {
    return this.butacaMarcadaSubject.value;
  }

  public setButacaMarcadaValue(butaca) {
    this.butacaMarcadaSubject.next(butaca);
  }

  public get ButacasNONumeradaMarcadaValue() {
    return this.butacasNONumeradaMarcadaSubject.value;
  }

  public setButacasNONumeradaMarcadaValue(butacas) {
    this.butacasNONumeradaMarcadaSubject.next(butacas);
  }

  pushMultipleButaca(butacaSeleccionada: Butaca, butacas: [Butaca]) {
    const minButaca = Math.min(...this.idsSeleccionados) - 1;
    const maxButaca = Math.max(...this.idsSeleccionados) + 1;
    this.butacasMarcadasMultiple = [];
    if (
      butacaSeleccionada.idRecintoButaca <
      this.primeraButacaMultiple.idRecintoButaca
    ) {
      butacas.forEach(butaca => {
        if (
          Math.floor(butaca.idRecintoButaca) >=
          Math.floor(butacaSeleccionada.idRecintoButaca) &&
          Math.floor(butaca.idRecintoButaca) <=
          Math.floor(this.primeraButacaMultiple.idRecintoButaca)
        ) {
          if (this.comprobarDisponibilidadButaca(butaca)) {
            this.butacasMarcadasMultiple.push(butaca);
          }
        }
      });
      // this.butacasMarcadasMultiple.push(this.primeraButacaMultiple);
    }
    /*     else if (butacaSeleccionada.idRecintoButaca > maxButaca) {
      butacas.forEach(butaca => {
        if (
          butaca.idRecintoButaca <= butacaSeleccionada.idRecintoButaca &&
          butaca.idRecintoButaca >= maxButaca
        ) {
          this.butacasMarcadasMultiple.push(butaca);
        }
      });
      this.butacasMarcadasMultiple.push(this.primeraButacaMultiple);
    } */
  }

  pushButaca(butaca: any): boolean {
    if (this.comprobarSiButacaAgregada(butaca)) {
      return true;
    }
    const butacas = this.entradasSeleccionadasValue;
    butacas.push(butaca);
    this.setEntradasSeleccionadasValue(butacas);
    return true;
  }

  comprobarSiButacaAgregada(butaca: any) {
    return this.entradasSeleccionadasValue
      .filter(
        entrada =>
          entrada.idEspectaculoAsociado ===
          this.butacasService.espectaculoValue.EspectaculosId &&
          entrada.fecha.format('DD/MM/YYYY') ===
          this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
          entrada.hora.format('HH:mm') ===
          this.butacasService.horaSeleccionadaValue.format('HH:mm')
      )
      .find(
        entrada =>
          butaca.idRecintoButaca === entrada.idRecintoButaca &&
          butaca.dataNumButaca === entrada.dataNumButaca &&
          butaca.dataNumFila === entrada.dataNumFila
      );
  }

  pushButacaNoNumerada(butaca) {
    const butacas = this.entradasSeleccionadasValue;
    butacas.push(butaca);
    this.setEntradasSeleccionadasValue(butacas);
  }

  private comprobarDisponibilidadButaca(butaca: Butaca) {
    console.log(butaca);
    const idsSeleccionados = this.entradasSeleccionadasValue
      .filter(
        entrada =>
          entrada.idEspectaculoAsociado ===
          this.butacasService.espectaculoValue.EspectaculosId
      )
      .map(e => +e.idRecintoButaca);
    const minButaca =
      Math.min(...idsSeleccionados) - 1 === +butaca.idRecintoButaca;
    const maxButaca =
      Math.max(...idsSeleccionados) + 1 === +butaca.idRecintoButaca;
    let modficacionReserva = false;

    if (this.entradasSeleccionadasValue.carroReservaInicial) {
      modficacionReserva = this.entradasSeleccionadasValue.carroReservaInicial.some(e =>
        +e.PKIDRB === +butaca.PKIDRB
      );
      }
    if (!modficacionReserva && this.comprobarSiButacaAgregada(butaca)) {
      return false;
    }
    if (!modficacionReserva && this.asientosDeshabilitadosValue.includes(+butaca.idRecintoButaca)) {
      this.getEstadosButacas(this.infoMapasService.recinto, this.nombreRecinto);
      alert(this.butacasService.getTranslate().data.ERR_BUTACA_NO_DISPONIBLE); // `Solo se pueden seleccionar butacas disponibles`
      return false;
    }
    // if (idsSeleccionados.length > 0 && (!minButaca && !maxButaca)) {
    //   alert(this.butacasService.getTranslate().data.ERR_BUTACA_NO_CORRELATIVA); // Debe seleccionar butacas correlativas
    //   return false;
    // }
    return true;
  }

  popButaca(): void {
    this.entradasSeleccionadasValue.pop();
  }

  sumaTotal() {
    let total = this.getGastosDeGestion();
    this.entradasSeleccionadasValue
      .filter(
        e =>
          e.idEspectaculoAsociado ===
          this.butacasService.espectaculoValue.EspectaculosId &&
          e.fecha.format('DD/MM/YYYY') ===
          this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
          e.hora.format('HH:mm') ===
          this.butacasService.horaSeleccionadaValue.format('HH:mm')
      )
      .forEach(e => {
        total += Number.parseFloat(e.tipoSeleccionado.PrecioInternet);
      });
    return total;
  }

  sumaTotalGeneral() {
    let total = this.getGastosDeGestion();
    this.entradasSeleccionadasValue.forEach(e => {
      total += Number.parseFloat(e.tipoSeleccionado.PrecioInternet);
    });
    return total;
  }

  sumaTotalPrecioBruto() {
    let total = this.getGastosDeGestion();
    this.entradasSeleccionadasValue.forEach(e => {
      total += Number.parseFloat(e.tipoSeleccionado.BaseImponible);
    });
    return total;
  }

  getGastosDeGestion() {
    let total = 0;
    this.entradasSeleccionadasValue.forEach(e => {
      total += Number.parseFloat(e.tipoSeleccionado.Comision);
    });
    return total;
  }

  sumaTotalBaseImponible() {
    let total = 0;
    this.entradasSeleccionadasValue.forEach(e => {
      total += Number.parseFloat(e.tipoSeleccionado.BaseImponible);
    });
    return total;
  }

  removeButacaNoNumerada(espectaculoId, fecha, hora, butaca, fila, recinto, idtarifa) {

    const entradasNoEspectaculo =
      this.entradasSeleccionadasValue.filter(
        e =>
          e.idEspectaculoAsociado !== espectaculoId ||
          e.fecha.format('DD/MM/YYYY') !== fecha.format('DD/MM/YYYY') ||
          e.hora.format('HH:mm') !== hora.format('HH:mm') ||
          e.dataIdRecinto !== recinto
      ) || [];

    const entradas =
      this.entradasSeleccionadasValue.filter(
        e =>
          e.idEspectaculoAsociado === espectaculoId &&
          e.fecha.format('DD/MM/YYYY') === fecha.format('DD/MM/YYYY') &&
          e.hora.format('HH:mm') === hora.format('HH:mm') &&
          e.dataIdRecinto === recinto
      ) || [];


    let indiceButaca = -1;
    if (this.entradasSeleccionadasValue.carroReservaInicial) {
      const listaIdsOriginal = this.entradasSeleccionadasValue.carroReservaInicial.map(e => +e.PKIDRB);

      for (let indiceCarro = 0; indiceCarro < entradas.length; indiceCarro++) {
        const element = entradas[indiceCarro];

        if (!listaIdsOriginal.includes(+element.PKIDRB) && +element.tipoSeleccionado.IdTarifa === +idtarifa) {
          indiceButaca = indiceCarro;
          break;
        }

      }
    }

    if (indiceButaca < 0) {
      indiceButaca = entradas.map(function (e) { return +e.tipoSeleccionado.IdTarifa; }).indexOf(+idtarifa);

    }


    entradas.splice(indiceButaca, 1);
     // entradas.pop();



  /*   entradas.index(element => {
      console.log(element.fecha.format('DD/MM/YYYY'));
    }); */


   // console.log(entradas);

  /*   const entradasFinal = entradas.filter(
      e => e.dataNumButaca !== butaca || e.dataNumFila !== fila
    ); */

    const carro = entradasNoEspectaculo.concat(entradas);

    if (this.entradasSeleccionadasValue.clienteCompraReserva) {
      carro.clienteCompraReserva = this.entradasSeleccionadasValue.clienteCompraReserva;
    }
    if (this.entradasSeleccionadasValue.carroReservaInicial) {
      carro.carroReservaInicial = this.entradasSeleccionadasValue.carroReservaInicial;
    }

    this.setEntradasSeleccionadasValue(
      carro
    );
  }

  removeButaca(butaca: any): boolean {
    // const idsSeleccionados = this.entradasSeleccionadasValue
    // .filter( entrada => entrada.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId)
    // .map( e => +e.idRecintoButaca);
    // const minButaca = (Math.min(...idsSeleccionados)) === +butaca.idRecintoButaca;
    // const maxButaca = (Math.max(...idsSeleccionados)) === +butaca.idRecintoButaca;
    // if (idsSeleccionados.length > 0 && (!minButaca && !maxButaca)) {
    //   alert(this.butacasService.getTranslate().transform('ERR_BUTACA_INTERMEDIA')); // `no puedes deseleccionar butacas intermedias`
    //   return false;
    // }
    // tslint:disable-next-line:max-line-length

    let clic = 'ClicInternet';
    let mostrar = 'MostrarInternet';

    if (this.butacasService.getPlataforma() === '4'){
      clic = 'ClicReserva';
      mostrar = 'MostrarReserva';
    }

    // let compraDesdeAdmin;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   clic = 'ClicReserva';
    //   mostrar = 'MostrarReserva';
    // }

    const filtrado = this.entradasSeleccionadasValue.filter(
      e =>
        e.idRecintoButaca !== butaca.idRecintoButaca ||
        e.fecha.format('DD/MM/YYYY') !== butaca.fecha.format('DD/MM/YYYY') ||
        e.hora.format('HH:mm') !== butaca.hora.format('HH:mm')
    );
    if (this.entradasSeleccionadasValue.clienteCompraReserva) {
      filtrado.clienteCompraReserva = this.entradasSeleccionadasValue.clienteCompraReserva;
    }
    if (this.entradasSeleccionadasValue.carroReservaInicial) {
      filtrado.carroReservaInicial = this.entradasSeleccionadasValue.carroReservaInicial;
    }
    this.setEntradasSeleccionadasValue(
     filtrado
    );
    // TODO.
    const quedanButacas = this.entradasSeleccionadasValue.filter(
      e =>
        e.idEspectaculoAsociado === butaca.idEspectaculoAsociado &&
        e.fecha.format('DD/MM/YYYY') === butaca.fecha.format('DD/MM/YYYY') &&
        e.hora.format('HH:mm') === butaca.hora.format('HH:mm')
    ).length;


/*     if (quedanButacas === 0) {
      const espectaculoAsociado = this.cestaService.espectaculos.find(esp => {
        return (
          esp.EspectaculosId === butaca.idEspectaculoAsociado &&
          esp.fecha.format('DD/MM/YYYY') ===
          butaca.fecha.format('DD/MM/YYYY') &&
          esp.hora.format('HH:mm') === butaca.hora.format('HH:mm')
        );
      });
      if (espectaculoAsociado) {
        this.cestaService.removeEspectaculo(espectaculoAsociado);
      }
    } */

    d3.select(`circle[_pkid = "${butaca.idRecintoButaca}" ]`).style(
      'fill',
      () => {
        let color = '';
        this.butacasService.estadosButacas.forEach(item => {
          if (
            +item[clic] === 1 &&
            +item[mostrar] === 1 &&
            butaca['data-tipo-butaca'] == item.TipoButaca
          ) {
            color = item.Color;
          }
        });
        return color;
      }
    );
    return true;
  }

  removeAllButaca(): void {
    this.setEntradasSeleccionadasValue([]);
  }

  httpPostMarcarDesmarcarMultiplesButacas(butacas: any, marcar) {
    const mapedButacas = butacas.map(
      b =>
        this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          b.idRecintoButaca,
          b.fecha,
          b.hora
        )
        ]
    );
    let recintoButacasIds = '';
    mapedButacas.forEach(Id => {
      recintoButacasIds = recintoButacasIds.concat(Id);
      recintoButacasIds = recintoButacasIds.concat(',');
    });
    recintoButacasIds = recintoButacasIds.substring(
      0,
      recintoButacasIds.length - 1
    );
    let compraDesdeAdmin;
    let plataforma;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   plataforma = '4';
    // }

    const body = {
      Marcar: marcar ? '1' : '0',
      Taquilla: '0',
      Plataforma:  this.butacasService.getPlataforma(), // plataforma,
      SesionInternetId: this.butacasService.getSessionID(),
      Marcado: [
        {
          idtarifa: '1826',
          RecintoButacaIds: recintoButacasIds
        }
      ]
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}MarcarDesmarcarButaca`,
      body
    );
  }

  httpPostMarcarDesmarcarButaca(butaca: any, marcar) {
    const a = this.butacasMap[
      this.generateId(
        this.butacasService.getSessionID(),
        butaca.idRecintoButaca,
        butaca.fecha,
        butaca.hora
      )
    ];

    let compraDesdeAdmin;
    let plataforma;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   plataforma = '4';
    // }

    const body = {
      Marcar: marcar ? '1' : '0',
      Taquilla: '0',
      Plataforma: this.butacasService.getPlataforma(), // plataforma,
      SesionInternetId:this.butacasService.getSessionID(),
      Marcado: [
        {
          idtarifa: butaca.tipoSeleccionado.IdTarifa,
          RecintoButacaIds: this.butacasMap[
            this.generateId(
              this.butacasService.getSessionID(),
              butaca.idRecintoButaca,
              butaca.fecha,
              butaca.hora
            )
          ]
        }
      ]
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}MarcarDesmarcarButaca`,
      body
    );
  }

  httpPostButacaInfo(butaca: any) {
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          butaca.idRecintoButaca,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        )
      ]
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}Butacainfo`,
      body
    );
  }

  httpPostButacaInfoSinFecha(butaca: any) {
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: butaca
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}Butacainfo`,
      body
    );
  }

  httpPostTodasButacasDetalles(butacas: any, filtroGI: boolean = true) {
    //const gruposInternet = JSON.parse(sessionStorage.getItem('gruposInternet'));
    const gruposInternet = this.butacasService.getGrupoInternet();  //JSON.parse(sessionStorage.getItem('gruposInternet'));

    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: butacas,
      GruposInternetId: filtroGI ? gruposInternet : undefined,
      EnlaceMenuId: this.butacasService.getEnlaceMenuId(),
      Nombre: null,
      Contrasena: null
    };

    return this.http.post(
      `${this.butacasService.getAPIUrl()}ButacaDetalle`,
      body
    ); 

  }

  httpPostRecintosPorSesion(butacas: any) {
   // const gruposInternet = JSON.parse(sessionStorage.getItem('gruposInternet'));
    const gruposInternet = this.butacasService.getGrupoInternet();   //JSON.parse(sessionStorage.getItem('gruposInternet'));

    //console.log("DATOS:: ", this.ventaService.espectaculoValue.EspectaculosId, this.ventaService.horaSeleccionadaValue.format('HH:mm:ss'));
    const body = {
      Idioma: this.butacasService.getLocale(),
      EspectaculoId: this.butacasService.espectaculoValue.EspectaculosId, //   this.ventaService.espectaculoValue.EspectaculosId,
      RecintosId: null,
      FechaSesion: this.butacasService.fechaSeleccionadaValue.format('YYYY/MM/DD'), 
      HoraInicioSesion: this.butacasService.horaSeleccionadaValue.format('HH:mm:ss'), 
      SesionInternetId: this.butacasService.getSessionID(),
      GruposInternet: gruposInternet

    }; 
    return this.http.post(
      `${this.butacasService.getAPIUrl()}GetTipoButacasPorRecinto`,
      body
    );
  }

  httpPostButacaDetalles(butaca: any) {
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          butaca.idRecintoButaca,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        )
      ],
      Nombre: null,
      Contrasena: null,
      EnlaceMenuId: this.butacasService.getEnlaceMenuId(),
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}ButacaDetalle`,
      body
    );
  }

  getInfoButacaNoNumeradas(butaca) {
    const gruposInternet = this.butacasService.getGrupoInternet();   //JSON.parse(sessionStorage.getItem('gruposInternet'));
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          butaca.idRecintoButaca,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        )
      ],
      GrupoInternet: gruposInternet,
      EnlaceMenuId: this.butacasService.getEnlaceMenuId(),
    };
    return this.http
      .post(`${this.butacasService.getAPIUrl()}Butacainfo`, body)
      .pipe(
        mergeMap((res: any) =>
          this.http
            .post(`${this.butacasService.getAPIUrl()}ButacaDetalle`, {
              ...body,
              Nombre: null,
              Contrasena: null,
              GruposInternetId: gruposInternet
            })
            .pipe(
              map((detalles: any) => {
                const infoButaca = {
                  infoGeneral: res.DatosResult[0],
                  tiposDeEntrada: detalles.DatosResult
                };
                return infoButaca;
              })
            )
        )
      );
  }

  getInfoButaca(butaca) {
    const gruposInternet = this.butacasService.getGrupoInternet();   //JSON.parse(sessionStorage.getItem('gruposInternet'));
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          butaca.idRecintoButaca,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        )
      ],
      GrupoInternet: gruposInternet //this.ventaService.grupoInternetValue
    };
    return this.http
      .post(`${this.butacasService.getAPIUrl()}Butacainfo`, body)
      .pipe(
        mergeMap((res: any) => {
          const infoButaca = this.butacasConPrecio.map(item => {
            const iButaca = {
              infoGeneral: res.DatosResult[0],
              tiposDeEntrada: item.tarifas
            };
            return iButaca;
          });
          return infoButaca;
        })
      );
  }

  getInfoButacaClick(butaca) {
    const gruposInternet = this.butacasService.getGrupoInternet() ;   //JSON.parse(sessionStorage.getItem('gruposInternet'));
    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintosButacasId: this.butacasMap[
        this.generateId(
          this.butacasService.getSessionID(),
          butaca.idRecintoButaca,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        )
      ],
      GrupoInternet: gruposInternet //this.butacasService.grupoInternetValue
    };
    return this.http
      .post(`${this.butacasService.getAPIUrl()}Butacainfo`, body)
      .pipe(
       map((res: any)  => {

            const iButaca = {
              infoGeneral: res.DatosResult[0],
              // tiposDeEntrada: infoButaca[0].tarifas
            };

          return iButaca;

          })
      );
  }


  getButacaTarifasEstado(PKIDRB: string) {
    //const enlaceMenu  = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    //const gruposInternet = JSON.parse(sessionStorage.getItem('gruposInternet'));
    const gruposInternet = this.butacasService.getGrupoInternet();   //JSON.parse(sessionStorage.getItem('gruposInternet'));

    const body = {
      Idioma: this.butacasService.getLocale(),
      RecintoButacaId: PKIDRB,
      EnlaceMenuId: this.butacasService.getEnlaceMenuId(),
      GrupoInternetId: gruposInternet
    };
    return this.http
      .post(`${this.butacasService.getAPIUrl()}ButacaTarifasEstado`, body)
      .pipe(
        map((res: any) => {
          return res.DatosResult;
        })
      );
  }


  getButacasMarcadasPorSesion() {
    const body = {
      SesionInternetId: this.butacasService.getSessionID()
    };
    return this.http
      .post(
        `${this.butacasService.getAPIUrl()}GetEspectaculosMarcacion`,
        body
      ) 
      .pipe(
        map((res: any) => {
          if (res.DatosResult) {
            return res.DatosResult;
          }
          return [];
        })
      );
  }

  httpPostEstadosButacasPorSesion() {
    const body = {
      Idioma: this.butacasService.getLocale(),
      EspectaculoId: this.butacasService.espectaculoValue.EspectaculosId,
      RecintosId: '',
      RDGIds: '',
      FechaSesion: this.butacasService.fechaSeleccionadaValue.format('YYYY/MM/DD'),
      HoraInicioSesion: this.butacasService.horaSeleccionadaValue.format('HH:mm:ss'),
      SesionesInternetId: this.butacasService.getSessionID()
    };
    return this.http.post(
      `${this.butacasService.getAPIUrl()}EstadosButacasPorSesion`,
      body
    );
  }

  getStatusButacaById(id: any) {
    for (let i = 0; i < this.estadosButacasValue.length; ++i) {
      const status = this.estadosButacasValue[i].Estado;
      const correctStatus = this.estadosButacasValue[i].IdsRelacion.find(
        function (findId: any) {
          return findId.IdSvg === id;
        }
      );
      if (correctStatus) {
        return status;
      }
    }
    return 3;
  }

  getButacasMap() {

    //this.ventaService.setFechaSeleccionadaValue(moment("30/12/2022"));
    //this.ventaService.setHoraSeleccionadaValue()
    this.estadosButacasValue
      .map(estado => estado.IdsRelacion)
      // .flat()
      .reduce((acc, val) => acc.concat(val), [])
      .forEach(butaca => {
        const id = this.generateId(
          this.butacasService.getSessionID(),
          butaca.IdSvg,
          this.butacasService.fechaSeleccionadaValue,
          this.butacasService.horaSeleccionadaValue
        );
        this.butacasMap[id] = butaca.IdRB;  // MMM
      });
  }

  generateId(sessionId, svgId, fecha, hora) {
    return sessionId + svgId + fecha.valueOf() + hora.valueOf();
  }

  marcarNoNumeradas(
    cantidad,
    marcadas,
    marcar,
    recinto = 0,
    idEspectaculoAsociado,
    fecha,
    hora,
    tipoButaca
  ) {

    let compraDesdeAdmin;
    let plataforma = this.butacasService.getPlataforma();
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   plataforma = '4';
    // }

    const body = {
      RecintoId: recinto,
      EspectaculoId: idEspectaculoAsociado,
      FechaEspectaculo: fecha.format('YYYY/MM/DD'),
      HoraEspectaculo: hora.format('HH:mm:ss'),
      Marcar: marcar ? 1 : 0,
      Taquilla: '0',
      Plataforma: plataforma,
      Cantidad: cantidad,
      TipoButaca: tipoButaca,
      SesionInternetId: this.butacasService.getSessionID(),
      Marcado: marcadas
    };
    return this.http
      .post(
        `${this.butacasService.getAPIUrl()}MarcarDesmarcarNoNumerada`,
        body
      )
      .pipe(
        map((res: any) => {
          if (!res.DatosResult) {
            return [];
          }
          return res.DatosResult;
        })
      );
  }

  getEstadosButacas(recinto: any, nombreRecinto: any) {
    this.listaIdRecintosNONUMERADOS = [];
    this.listaIdRecintosNUMERADOS = [];

    let idRBCarroOriginal = [];

    let coloresReserva = false;

    let clic = 'ClicInternet';
    let mostrar = 'MostrarInternet';

    if (this.butacasService.getPlataforma() === '4') {
      coloresReserva = true;
      clic = 'ClicReserva';
      mostrar = 'MostrarReserva';
    }
    // let compraDesdeAdmin;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   coloresReserva = true;
    //   clic = 'ClicReserva';
    //   mostrar = 'MostrarReserva';
    // }

    if (this.entradasSeleccionadasValue.carroReservaInicial) {
       idRBCarroOriginal = this.entradasSeleccionadasValue.carroReservaInicial.map(e => e.PKIDRB );
    }
    this.datosButacaActualizados.next(false);
    this.asientosDeshabilitadosSubject.next([]);
    this.httpPostEstadosButacasPorSesion()
      .pipe(
        mergeMap((res: any) => {
          return this.getButacasMarcadasPorSesion().pipe(
            map(butacasMarcadas => {
              res.butacasMarcadas = butacasMarcadas;
              return res;
            })
          );
        })
      )
      .subscribe((res: any) => {
        this.listaButacasDisponibles = '';
        idRBCarroOriginal.forEach(idsOriginal => {
          this.listaButacasDisponibles += idsOriginal + ',';
        });

        const idsEstadosDisponibles: number[] = this.butacasService.estadosButacas.map(itemEstado => {
          if (+itemEstado[clic] === 1 && +itemEstado[mostrar] === 1) {
            return +itemEstado.EstadoButaca;
          }
          return null;
        });
        res.DatosResult.Butacas.forEach(item => {
          if (idsEstadosDisponibles.includes(+item.Estado)) {
            item.IdsRelacion.forEach(
              subItem => (this.listaButacasDisponibles += subItem.IdRB + ',')
            );
          }
        });
        this.listaButacasDisponibles = this.listaButacasDisponibles.slice(
          0,
          -1
        );
        this.setEstadosButacaValue(res.DatosResult.Butacas);
        this.getButacasMap();
        this.infoMapasService
          .getButacas(nombreRecinto)
          .subscribe((butacas: [any]) => {
            this.butacasConPrecio = butacas;
            this.estadosButacasValue.map(butacasConEstado => {
              butacasConEstado.IdsRelacion.map(cadaButaca => {
                this.butacasConPrecio.forEach(butacasDelJSON => {
                  if (cadaButaca.IdSvg === butacasDelJSON.idRecintoButaca) {
                    butacasDelJSON['data-tipo-butaca'] = cadaButaca.TipoBTC;
                    butacasDelJSON.PKIDRB = cadaButaca.IdRB;
                    butacasDelJSON.EstadoInicial = cadaButaca.EstadoInicial;
                  }
                });
              });
            });
            this.httpPostRecintosPorSesion(
              this.listaButacasDisponibles
            ).subscribe((resultado: any) => {
              if (resultado && (resultado.DatosResult != null || resultado.DatosResult !== undefined)) {
                this.listaRecintosPorSesion = resultado.DatosResult;
                for (let i = 0; i < resultado.DatosResult.length; i++) {
                  if (resultado.DatosResult[i].Numeradas === '1') {
                    this.listaIdRecintosNUMERADOS.push(resultado.DatosResult[i].RecintoID);
                  } else {
                    this.listaIdRecintosNONUMERADOS.push(resultado.DatosResult[i].RecintoID);
                  }
                }
              } else {
                console.error('ERROR: /GetTipoButacasPorRecinto');
              }



              /*if (this.listaIdRecintosNUMERADOS.length === 0 && this.listaIdRecintosNONUMERADOS.length === 1) {
                this.noNumeradaSubject.next(true);
                // const b = this.butacasMap[butacas[0].idRecintoButaca];
                this.getInfoButacaNoNumeradas(butacas[0]).subscribe(
                  (infoButaca: any) => {
                    this.datosButacaActualizados.next(true);
                    butacas[0].info = infoButaca;
                    this.setButacaMarcadaValue(butacas[0]);
                  }
                );
              } else {*/
                this.httpPostTodasButacasDetalles(
                  this.listaButacasDisponibles
                ).subscribe((detalle: any) => {
                  if (detalle.DatosResult[0])  this.prefijo = detalle.DatosResult[0].Prefijo;
                  this.noNumeradaSubject.next(false);
                  recinto.selectAll('circle').remove();
                  this.leyenda = [];
                  this.butacasConPrecio = this.butacasConPrecio.map(item => {
                    const tarifas = [];
                    const precios = [];
                    detalle.DatosResult.forEach(x => {
                      if (item['data-tipo-butaca'] == x.TipoButacaId && x.idRecinto == item.dataIdRecinto) {
                        tarifas.push(x);
                        precios.push(parseInt(x.PrecioInternet, 10));
                      }
                    });
                    item.tarifas = tarifas;
                    if (precios.length > 0) {
                      item.precioMaximo = Math.max(...precios);
                      item.precioMinimo = Math.min(...precios);
                    }
                    return item;
                  });
                  const preciosFiltro = detalle.DatosResult.map(item => {
                    return parseInt(item.PrecioInternet, 10);
                  });
                  this.maxFiltro = Math.max(...preciosFiltro);
                  this.minFiltro = Math.min(...preciosFiltro);
                  if (this.maxFiltro !== this.minFiltro) {
                    this.filtroPreparado.next(true);
                  }



                  this.listaRecintosPorSesion.filter(r => +r.Numeradas === 0).forEach(recintosNoNumerados => {

                    let totalDisponible = recintosNoNumerados.ButacasDisponible;
                    const IdRecinto = recintosNoNumerados.RecintoID;
                    if (this.listaIdRecintosNUMERADOS.length === 0 && this.listaIdRecintosNONUMERADOS.length === 1) {
                      this.clicRecintosNoNumerados(IdRecinto);
                    }
                    if (totalDisponible === undefined
                      || totalDisponible == null
                      || totalDisponible === '') {
                    totalDisponible = 0;
                  } else {
                      totalDisponible = +totalDisponible;
                  }

                    if (this.entradasSeleccionadasValue.carroReservaInicial) {
                      totalDisponible += this.entradasSeleccionadasValue.carroReservaInicial.filter(
                        e =>
                          e.idEspectaculoAsociado === this.butacasService.espectaculoValue.EspectaculosId &&
                          e.fecha.format('DD/MM/YYYY') === this.butacasService.fechaSeleccionadaValue.format('DD/MM/YYYY') &&
                          e.hora.format('HH:mm') === this.butacasService.horaSeleccionadaValue.format('HH:mm') &&
                          +e.dataIdRecinto === +IdRecinto
                      ).length;
                    }

                    recinto.selectAll('[id="R' + IdRecinto + '"]').selectAll('title').remove();
                    recinto.selectAll('[id="R' + IdRecinto + '"]')
                    .style('cursor', 'pointer')
                    .style('stroke', 'grey')
                    /*function () { //pillaba el color actual
                        return d3.select(this).style('fill');
                      })*/
                      .style('fill', totalDisponible > 0 ? '#ffffff' : 'grey')
                      .style('stroke-width', '2')
                      .on('mouseover', function () {
                        d3.select(this).style('fill-opacity', '0.7');
                      })
                      .on('mouseout', function () {
                        d3.select(this).style('fill-opacity', '1');
                      })
                      .on('click', () => {
                        console.log('entra en el evento click')
                        if (totalDisponible > 0) {
                      this.clicRecintosNoNumerados(IdRecinto);
                    } else {
                      alert(this.butacasService.getTranslate().data.ERR_RECINTO_NO_DISPONIBLE); // `Solo se pueden seleccionar butacas disponibles`
                    }
                    })
                    .insert('svg:title')
                      .text(recintosNoNumerados.NombreRecinto + ' - ' +
                      this.butacasService.getTranslate().data.TITLE_NO_NUMERADA.toString().replace('{cantidad}', totalDisponible));
                  });

                  if (this.listaIdRecintosNONUMERADOS.length > 0) {
                    this.leyenda.push({
                      Descripcion: (this.butacasService.getTranslate().data.RECINTO_NO_NUMERADO_LEYENDA),
                      Color: '#FFFFFF'
                    });
                  }
                  if (idRBCarroOriginal && idRBCarroOriginal.length > 0) {
                    this.butacasConPrecio.forEach(butaca => {
                      idRBCarroOriginal.forEach(e => {
                          if (+e === +butaca.PKIDRB) {
                            butaca.estado = butaca.EstadoInicial;
                          }
                        });
                    });
                  }
                  // corte
                  this.butacasConPrecio.forEach(butaca => {
                    this.butacaSeleccionada = false;

                    if (this.comprobarSiButacaAgregada(butaca)) {
                      this.butacaSeleccionada = true;
                    }
                    const tooltipText = this.butacasService.getTranslate().data.TITLE_FILA + ': ' + butaca.dataNumFila + ' '
                     + this.butacasService.getTranslate().data.TITLE_NUM_BUTACA + ': ' + butaca.dataNumButaca;

                    const esNoNumerada = this.listaIdRecintosNONUMERADOS.includes(butaca.dataIdRecinto);
                    const c = recinto
                      .append('circle')
                      .attr('_pkid', butaca.idRecintoButaca)
                      .attr('_PKIDRB', butaca.PKIDRB)
                      .style('fill', this.getColorButaca(butaca, esNoNumerada, coloresReserva))
                      .style('stroke', this.colorStroke)
                      .style('fill-opacity', this.butacaSeleccionada ? 0.2 : 1)
                      .attr('class', this.butacaSeleccionada ? 'marcada' : '')
                      .style('display', () => {
                        return esNoNumerada ? 'none' : 'block';
                      })
                      .style('cursor', () => {
                        let displayValue = 'pointer';
                        this.butacasService.estadosButacas.forEach(item => {
                          const status = this.getStatusButacaById(
                            butaca.idRecintoButaca
                          );
                          if (item.EstadoButaca == status &&
                            +item[clic] === 0) {
                            displayValue = 'move';
                          }
                        });
                        return displayValue;
                      })
                      .attr('cx', butaca.cx)
                      .attr('cy', butaca.cy)
                      .attr('r', butaca.r)
                      .on('click', () => {
                        let desmarcada = false;
                        if (this.comprobarSiButacaAgregada(butaca)) {
                          desmarcada = true;

                          const b = this.entradasSeleccionadasValue.find(
                            e => e.idRecintoButaca === butaca.idRecintoButaca
                          );
                          this.httpPostMarcarDesmarcarButaca(b, false).subscribe(
                            () => {
                              this.removeButaca(b);
                              c.style('fill-opacity', 1);
                              const butacaDom = document.querySelector('circle[_PKIDRB=\'' + b.PKIDRB + '\']');
                              butacaDom.classList.remove('marcada');
                              return;
                            }
                          );
                        }
                        // TODO
                        if (!desmarcada && this.seleccionMultiple) {
                          if (this.idsSeleccionados.length !== 0) {
                            if (this.comprobarDisponibilidadButaca(butaca)) {
                              this.pushMultipleButaca(butaca, butacas);
                              this.httpPostMarcarDesmarcarMultiplesButacas(
                                this.butacasMarcadasMultiple,
                                '1'
                              ).subscribe(ans => {
                                this.getEstadosButacas(recinto, nombreRecinto);
                                this.httpPostButacaDetalles(
                                  this.primeraButacaMultiple
                                ).subscribe((butacaDetalles: any) => {
                                  butaca.extraData = butacaDetalles.DatosResult;
                                  this.httpPostButacaInfo(butaca).subscribe(
                                    (binfo: any) => {
                                      this.modalService.open(
                                        'selector',
                                        binfo.DatosResult,
                                        this.butacasMarcadasMultiple
                                      );
                                    }
                                  );
                                });
                              });
                            }
                          } else {
                            // TODO trabajando aqui
                            // if (this.pushButaca(butaca)) {
                            this.butacaSeleccionada = true;
                            this.primeraButacaMultiple = butaca;
                            this.idsSeleccionados.push(butaca.idRecintoButaca);
                            if (this.comprobarDisponibilidadButaca(butaca)) {
                              this.getInfoButaca(butaca).subscribe(
                                (infoButaca: any) => {
                                  butaca.info = infoButaca;
                                  // butaca.DOMElement = c;
                                  this.setButacaMarcadaValue(butaca);
                                  c.style('fill-opacity', 0.2);
                                  c.classList.add('marcada');
                                  // this.modalService.open('selector');
                                }
                              );
                            }
                            // }
                          }
                        } else if (!desmarcada && !this.seleccionMultiple) {

                          if (this.comprobarDisponibilidadButaca(butaca)) {
                            const observables = [];
                            observables.push(this.entradasSeleccionadasValue.carroReservaInicial ?
                              new Observable(e => { e.next(undefined); e.complete(); }) :
                               this.getButacaTarifasEstado(butaca.PKIDRB));

                            observables.push(this.getInfoButacaClick(butaca));
                            forkJoin(observables).subscribe(
                              (res1) => {
                                const respuestaTarifasEstado: any = res1[0];
                                const respuestaInfo: any = res1[1];
                                butaca.info = respuestaInfo;
                                if (respuestaTarifasEstado && respuestaTarifasEstado.TarifasEstados) {
                                  let tarifasDeshabilitadas;

                                  respuestaTarifasEstado.TarifasEstados.forEach(respuetatarifas => {
                                    if (+respuetatarifas.Estado === 0) {
                                     tarifasDeshabilitadas = respuetatarifas.IdTarifas;
                                    }
                                  });

                                  const updateTarifas = butaca.tarifas.map(tarifasButaca => {
                                    if (tarifasDeshabilitadas && tarifasDeshabilitadas.map(e => +e).includes(+tarifasButaca.IdTarifa)) {
                                      tarifasButaca.Habilitado = 0;
                                    } else {
                                      tarifasButaca.Habilitado = 1;
                                    }
                                  return tarifasButaca;
                                  });

                                  if (updateTarifas && updateTarifas.length > 0) {
                                    butaca.tarifas = updateTarifas;
                                  } else {
                                    butaca.tarifas = butaca.tarifas.map(tarifa => {
                                      tarifa.Habilitado = 1;
                                      return tarifa;
                                    });
                                  }

                                } else {
                                  butaca.tarifas = butaca.tarifas.map(tarifa => {
                                    tarifa.Habilitado = 1;
                                    return tarifa;
                                  });
                                }

                                this.setButacaMarcadaValue(butaca);
                                this.modalService.open('selector');

                              });
                           /*  this.getButacaTarifasEstado(butaca).subscribe().unsubscribe();
                            this.getInfoButacaClick(butaca).subscribe(
                              (infoButaca: any) => {
                                butaca.info = infoButaca;
                                // butaca.DOMElement = c;

                                this.setButacaMarcadaValue(butaca);
                                this.modalService.open('selector');
                              }
                            ); */
                          }
                        }
                      })
                      .on('mouseover', function () {
                        d3.select(this)
                          .insert('svg:title')
                          .text(tooltipText);
                      })
                      .on('mouseout', function () {
                        const listaHijos = this.childNodes;
                        const longitud = this.childNodes.length;
                        for (let index = 0; index < longitud; index++) {
                          this.removeChild(listaHijos[index]);
                        }
                      });

                  });
                  this.leyenda.sort((a, b) => {
                   if (a.Descripcion > b.Descripcion) {
                     return 1;
                   }
                   if (a.Descripcion < b.Descripcion) {
                     return -1;
                   }
                   return 0;
                  });
                  this.leyendaSubject.next(this.leyenda);

                });
             // } IF de NO numeradas
            });
          });
      });
  }


  clicRecintosNoNumerados(recintosNoNumerados: number) {

    const id = recintosNoNumerados;
    const listaEstadosDisponibles: number[] = [];
    const listaTiposButaca: number[] = [];
    const butacasMarcadasNONumeradas: any[] = [];

    //let clic = 'ClicInternet';
    let clic = this.butacasService.getPlataforma() === '4' ? 'ClicReserva':'ClicInternet' ;
    // let compraDesdeAdmin;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   clic = 'ClicReserva';
    // }

    const butacasPorRecinto = this.butacasConPrecio.filter(butaca => {
      return butaca.dataIdRecinto === id;
    });

    this.butacasService.estadosButacas.forEach(item => {
      if (+item[clic] === 1) {
        listaEstadosDisponibles.push(item.EstadoButaca);
      }
    });

    this.getInfoButacaClick(butacasPorRecinto[0]).subscribe(
      (infoButaca: any) => {
        if (infoButaca != null && infoButaca != undefined) {
          infoButaca.infoGeneral.recintoButacaId = '';
          infoButaca.infoGeneral.acceso = '';
        }

        butacasPorRecinto.forEach(butaca => {
          const status = this.getStatusButacaById(butaca.idRecintoButaca);
          if (!listaTiposButaca.includes(+butaca['data-tipo-butaca']) && listaEstadosDisponibles.includes(status)) {
            butaca.info = infoButaca;
            const nombreTipo = this.butacasService.estadosButacas.filter(
              item => +item.EstadoButaca === +status && +item.TipoButaca === +butaca['data-tipo-butaca']);
            if (nombreTipo != undefined && nombreTipo.length > 0) {
              butaca.tarifas.forEach(element => {
                element.NombreTipo = nombreTipo[0].Descripcion;
              });
            }
            listaTiposButaca.push(+butaca['data-tipo-butaca']);
            butacasMarcadasNONumeradas.push(butaca);
          }
        });

        this.datosButacaActualizados.next(true);

        this.setButacasNONumeradaMarcadaValue(butacasMarcadasNONumeradas);

        this.modalService.open('selectorNONumerado');
      }
    );

  }

  private getColorButaca(butaca, noNumerada: boolean, modoReserva: boolean): string {
    console.log('butaca',butaca);
    const status = butaca.estado || this.getStatusButacaById(
      butaca.idRecintoButaca
    );
    const clic = modoReserva ? 'ClicReserva' : 'ClicInternet';
    const mostrar = modoReserva ? 'MostrarReserva' : 'MostrarInternet';

    const returnColor = '#000000';

    for (let indexEstado = 0; indexEstado < this.butacasService.estadosButacas.length; indexEstado++) {
      const itemEstado = this.butacasService.estadosButacas[indexEstado];
      if (status === itemEstado.EstadoButaca && butaca['data-tipo-butaca'] === itemEstado.TipoButaca) {

        if (!noNumerada) {
          this.addColorLeyendaSiNoExiste(itemEstado, butaca);
        }
        if (+itemEstado[clic] === 0 || butaca.tarifas.length === 0) {
          this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
          this.addColorLeyendaSiNoExiste(itemEstado, butaca);

        }
        this.colorStroke = itemEstado.Color;
        return  itemEstado.Color;
        }
      }

    const ocupada = this.butacasService.estadosButacas.filter(e => +e[clic] === 0 && +e[mostrar] === 1) ;
        if (ocupada.length > 0) {
          this.addColorLeyendaSiNoExiste(ocupada[0], butaca);

          this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
          this.colorStroke = ocupada[0].Color;

          return ocupada[0].Color;
        }
        this.addAsientoDeshabilitado(+butaca.idRecintoButaca);
        this.colorStroke = returnColor;

        return returnColor;


  }

  private addColorLeyendaSiNoExiste(itemEstado, butaca) {
    if (
      this.leyenda.find(x => x.Color === itemEstado.Color) ==
      undefined
    ) {
 /*      if (+itemEstado.ClicInternet === 0 && +itemEstado.ClicTaquilla === 1 && butaca.tarifas.length > 0) {
        this.leyenda.push({
          Descripcion: (itemEstado.Descripcion + ' ' + this.butacasService.getTranslate().data.RESERVADO_TAQUILLA),
          Color: itemEstado.Color
        });
      } else { */
        this.leyenda.push({
          Descripcion: itemEstado.Descripcion,
          Color: itemEstado.Color
        });
     // }
    }
  }

  mapaFiltradoPorPrecio(recinto, min, max) {

    let coloresReserva = this.butacasService.getPlataforma() === '4'? true : false;

    // let compraDesdeAdmin;
    // const enlaceMenu = JSON.parse(sessionStorage.getItem('enlaceMenu'));
    // if (enlaceMenu && enlaceMenu.CaracteristicasAdicionales) {
    //   const caracteristicasAdicionales = JSON.parse(enlaceMenu.CaracteristicasAdicionales);
    //   if (caracteristicasAdicionales.CaracteristicasAdicionales &&
    //     caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin) {
    //     compraDesdeAdmin = caracteristicasAdicionales.CaracteristicasAdicionales.CompraDesdeAdmin;
    //   }
    // }
    // if (compraDesdeAdmin && +compraDesdeAdmin === 1) {
    //   coloresReserva = true;
    // }

    this.butacasDeshabilitadasPorFiltro = [];
    recinto.selectAll('circle').remove();
    this.butacasConPrecio.forEach(butaca => {
      this.butacaSeleccionada = false;

      if (this.comprobarSiButacaAgregada(butaca)) {
        this.butacaSeleccionada = true;
      }
      const esNoNumerada = this.listaIdRecintosNONUMERADOS.includes(butaca.dataIdRecinto);

      const c = recinto
        .append('circle')
        .attr('_pkid', butaca.idRecintoButaca)
        .style('fill', this.getColorButaca(butaca, esNoNumerada, coloresReserva))
        .style('stroke', this.colorStroke)
        .style('fill-opacity', this.butacaSeleccionada ? 0.2 : 1)
        .attr('cx', butaca.cx)
        .attr('cy', butaca.cy)
        .attr('r', butaca.r)
        .style('display', () => {
          return esNoNumerada ? 'none' : 'block';
        })
        .on('click', () => {
          console.log('entra en el evento click')
          if (
            this.butacasDeshabilitadasPorFiltro.includes(butaca.idRecintoButaca)
          ) {
            console.log('butaca deshabilitadaPorfiltro')
            return;
          }

          if (this.comprobarSiButacaAgregada(butaca)) {
            const b = this.entradasSeleccionadasValue.find(
              e => e.idRecintoButaca === butaca.idRecintoButaca
            );
            this.httpPostMarcarDesmarcarButaca(b, false).subscribe(() => {
              this.removeButaca(b);
              c.style('fill-opacity', 1);
              const butacaDom = document.querySelector('circle[_PKIDRB=\'' + b.PKIDRB + '\']');
              butacaDom.classList.remove('marcada');
              console.log('butaca Marcada/Desmarcada')
              return;
            });
          }
          // TODO
          /* if (this.seleccionMultiple) {
            if (this.idsSeleccionados.length !== 0) {
              this.pushMultipleButaca(butaca, butacas);
              this.httpPostMarcarDesmarcarMultiplesButacas(
                this.butacasMarcadasMultiple,
                '1'
              ).subscribe(ans => {
                this.getEstadosButacas(recinto, nombreRecinto);
                this.httpPostButacaDetalles(this.primeraButacaMultiple).subscribe(
                  (butacaDetalles: any) => {
                    butaca.extraData = butacaDetalles.DatosResult;
                    this.httpPostButacaInfo(butaca).subscribe((binfo: any) => {
                      this.modalService.open(
                        'selector',
                        binfo.DatosResult,
                        this.butacasMarcadasMultiple
                      );
                    });
                  }
                );
              });
            } else {
              if (this.pushButaca(butaca)) {
                this.primeraButacaMultiple = butaca;
              }
            }
          } else */ if (
            !this.seleccionMultiple
          ) {
            console.log('no es seleccion multiple')
            if (this.comprobarDisponibilidadButaca(butaca)) {
              console.log('butacaDisponible');
              this.getInfoButaca(butaca).subscribe((infoButaca: any) => {
                butaca.info = infoButaca;
                // butaca.DOMElement = c;
                this.setButacaMarcadaValue(butaca);
                console.log('solicita abrir el selector');
                this.modalService.open('selector');
              });
            }
          }
        });
    });
  }
}
