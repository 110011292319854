import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditGruposTipoEntradaV11Component } from './edit-grupos-tipo-entrada-v11/edit-grupos-tipo-entrada-v11.component';


@Component({
  selector: 'app-grupos-tipo-entrada-v11',
  templateUrl: './grupos-tipo-entrada-v11.component.html',
  styleUrls: ['./grupos-tipo-entrada-v11.component.scss']
})
export class GruposTipoEntradaV11Component implements OnInit {
  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.closepanel();
  }

  openPanel(){
    this.customanpanel=EditGruposTipoEntradaV11Component
    this.editChangeCss()
    this.cdRef.detectChanges();
  }

  closepanel(){
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      console.log()
      this.customanpanel = undefined;
     this.closePanelChangeCss()
    })
  }

  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){
    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
  }

}
}
