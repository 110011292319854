import { Component, OnInit, Input } from '@angular/core';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { Formas } from '@sharedV11/classes/FormasPago/formas';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { takeUntil } from 'rxjs/operators';
import { Globales } from '@clasesV6/globales';
import { GrupoTiposEntrada } from '@sharedV11/classes/EntradasPromociones/GrupoEntradas/GrupoTiposEntrada';
import { EditGruposTipoEntradaService } from '@sharedV11/services/edit_grupos_tipo_entrada/edit-grupos-tipo-entrada.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';


@Component({
  selector: 'app-edit-grupos-tipo-entrada-v11',
  templateUrl: './edit-grupos-tipo-entrada-v11.component.html',
  styleUrls: ['./edit-grupos-tipo-entrada-v11.component.scss']
})
export class EditGruposTipoEntradaV11Component implements OnInit {
  loading = true;
  private ngUnsubscribe = new Subject();
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editGrupoEntrada: FormGroup;
  gruposTipoEntrada: GrupoTiposEntrada;
  globales: Globales;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };
  grupoTipoEntrada: GrupoTiposEntrada;
  
  constructor(
    private editGrupoEntradaService: EditGruposTipoEntradaService,
    private translator: Translator,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private panelService: PanelService,
    private tokenService: ApiTokenService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  changeValueForm(fp: GrupoTiposEntrada) {
    this.formControls["pkId"].setValue(fp.pkId);
    this.formControls["NombreGrupo"].setValue(fp.NombreGrupo);
    this.formControls["Descripcion"].setValue(fp.Descripcion);
    this.formControls["ConMotivo"].setValue(this.stringToBoolean(fp.ConMotivo));
  }
  initializeFormWithoutData() {
    this.editGrupoEntrada = this.fb.group({
      pkId: [""],
      NombreGrupo: ["", Validators.required],
      Descripcion: [""],
      ConMotivo: [false],
    });
  }
  stringToBoolean(value) {

    if (typeof value !== "boolean") {

      if (value == "1") {
        value = true;
      } else {
        value = false;
      }
    }
    return value
  }
  get formControls() {
    return this.editGrupoEntrada.controls
  }
  manageForm(pkId, mode) {
    if (mode == "I") {
      this.gruposTipoEntrada = this.editGrupoEntrada.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findGrupoTipoEntrada(pkId)
    }
  }
  findGrupoTipoEntrada(pkId) {
    this.editGrupoEntradaService.findGrupoEntradaByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(userApi => {
      let grupoTipoEntradaData: GrupoTiposEntrada = userApi.DatosResult.customanGrupoTipoEntrada[0]
      if (grupoTipoEntradaData != null) {
        this.changeValueForm(grupoTipoEntradaData);
        this.grupoTipoEntrada = grupoTipoEntradaData;
        this.grupoTipoEntrada.CentrosIds = grupoTipoEntradaData.CentrosIds;
        console.log("Grupos Tipo de Entrada", this.grupoTipoEntrada);
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.grupoTipoEntrada);
        this.loading = false;
      }
    })
  }
  valuedChanged() {
    this.editGrupoEntrada.valueChanges.subscribe(value => {
      console.log(value)
      console.log(this.gruposTipoEntrada)
      if (this.gruposTipoEntrada != null) {
        this.panelService.setDiscardChanges(false)
        console.log(value.Baja)
        if (JSON.stringify(value) === JSON.stringify(this.gruposTipoEntrada)) {
          console.log("same")
          this.panelService.setDiscardChanges(true)


        }
      }
    })
  }
  manageApi(gruposTipoEntrada: GrupoTiposEntrada, mode) {

    gruposTipoEntrada.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        gruposTipoEntrada.pkId = "0"
        this.InsGruposTipoEntrada(gruposTipoEntrada)
        console.log(gruposTipoEntrada)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdGruposTipoEntrada(gruposTipoEntrada)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        gruposTipoEntrada.pkId = "0"
        this.InsGruposTipoEntrada(gruposTipoEntrada)
        break;

      default:
        break;
    }
  }
  UpdGruposTipoEntrada(gruposTipoEntrada){
    this.editGrupoEntradaService.updGruposTipoEntrada(gruposTipoEntrada, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response =>{
      console.log(response)
      this.save();
      this.destroy();
    },
    error => { this.httpErrorService.identificarErrores(error); 
    });
  }
  InsGruposTipoEntrada(gruposTipoEntrada){
    this.editGrupoEntradaService.insGruposTipoEntrada(gruposTipoEntrada, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response =>{
      this.save();
      this.destroy();
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  submit() {
    let gruposTipoEntrada: GrupoTiposEntrada = this.editGrupoEntrada.value
    gruposTipoEntrada.ConMotivo = gruposTipoEntrada.ConMotivo.toString().toLowerCase() == "true" ? "1" : "0";
    this.manageApi(gruposTipoEntrada, this.mode)
  }
  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.gruposTipoEntrada)
  }

}
