<div class="component">

<app-buttons  (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>

<app-filter [tableName]="'tarifas'" ></app-filter>

</div>

<app-table [tableName]="'tarifas'" (addbut)="addbut=$event"></app-table>

<app-panelcustoman
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 