<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar> 
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="editor-registro">
  <div class="divForm">
    <form [formGroup]="editRegistroForm" (onSubmit)="submit()">
    <ng-container *ngIf="mode=='I' || mode=='D'">
    <section class="combo-section">
    
        <mat-radio-group [(ngModel)]="modeinterval">
          <mat-radio-button class="example-margin" value="0" checked>
            Dias del intervalo
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="1">
            Dias semana del intervalo
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="2">
            Dia del mes en el intervalo
          </mat-radio-button>
        </mat-radio-group>
      </section>


      <div class="form-group row">
        <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" >
            <mat-label>{{translation.FechaDesde}}</mat-label>
            <input matInput [matDatepicker]="dpdesde" placeholder="{{translation.FechaDesde}}" formControlName="FechaDesde">
            <mat-datepicker-toggle matSuffix [for]="dpdesde"></mat-datepicker-toggle>
            <mat-datepicker #dpdesde></mat-datepicker>
        </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline" >
            <mat-label>{{translation.FechaHasta}}</mat-label>
            <input matInput [matDatepicker]="dphasta" placeholder="{{translation.FechaHasta}}" formControlName="FechaHasta">
            <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
            <mat-datepicker #dphasta></mat-datepicker>
        </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4" *ngIf="modeinterval=='1'">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.Dias}}</mat-label>
              <mat-select placeholder="{{translation.Dias}}" 
                name="dias" multiple [(ngModel)]="diassemana"
                >
                <mat-option (onSelectionChange)="getValues($event)" value="1">{{translation.Lunes}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="2">{{translation.Martes}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="3">{{translation.Miercoles}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="4">{{translation.Jueves}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="5">{{translation.Viernes}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="6">{{translation.Sabado}}</mat-option>
                <mat-option (onSelectionChange)="getValues($event)" value="0">{{translation.Domingo}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-sm-12 col-md-4" *ngIf="modeinterval=='2'">
            <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.Dia}}</mat-label>
            <input matInput type="number" min="1" max="31" name="dia" placeholder="{{translation.Dia}}" [(ngModel)]="diames" />
            </mat-form-field>
          </div>
    </div>
    </ng-container>




        <div class="form-group" *ngIf="mode=='U'">
            <mat-form-field appearance="outline" >
                <mat-label>{{translation.FechaDesde}}</mat-label>
                <input matInput [matDatepicker]="fecha" placeholder="{{translation.Fecha}}" formControlName="Fecha"> 
                <mat-datepicker-toggle matSuffix [for]="fecha"></mat-datepicker-toggle>
                <mat-datepicker #fecha></mat-datepicker>
            </mat-form-field>
            </div>

       <div class="form-group">

            <mat-form-field appearance="outline">
                <mat-label>{{translation.Motivo}}</mat-label>
                <input matInput formControlName="Motivo">
                <mat-error *ngIf="validateFields('Motivo')">
                {{getErrorMessage("Motivo")}}
                </mat-error>
            </mat-form-field>

        </div>

        <div class="form-group">
            <mat-label>
              <mat-slide-toggle matInput name="Venta" formControlName="Venta"></mat-slide-toggle><span class="slidetitle" style="text-overflow: ellipsis">{{translation.PVKore}}</span>
            </mat-label>
        </div>

        <ng-container *ngIf="editRegistroForm.controls['Venta'].value">
        <div class="form-group row">
            <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                <mat-label>{{translation.HoraInicio}}</mat-label>
                <input matInput formControlName="HoraInicio1Venta" class="editor-campo-registro time-picker"
                    placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                </mat-form-field>
            </div>

            <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                <mat-label>{{translation.HoraFin}}</mat-label>
                <input matInput formControlName="HoraFin1Venta" class="editor-campo-registro time-picker"
                    placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                </mat-form-field>
            </div>
            </div>

            <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraInicio}}</mat-label>
                    <input matInput formControlName="HoraInicio2Venta" class="editor-campo-registro time-picker"
                        placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                    </mat-form-field>
                </div>
    
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraFin}}</mat-label>
                    <input matInput formControlName="HoraFin2Venta" class="editor-campo-registro time-picker"
                        placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                    </mat-form-field>
                </div>
            </div>
        </ng-container>


        <div class="form-group">
            <mat-slide-toggle matInput name="Reserva" formControlName="Reserva"></mat-slide-toggle>
            <mat-label><span class="slidetitle">{{translation.Internet}}</span>
            </mat-label>
        </div>
        <ng-container *ngIf="editRegistroForm.controls['Reserva'].value">
            <div class="form-group row">
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraInicio}}</mat-label>
                    <input matInput formControlName="HoraInicio1Reserva" class="editor-campo-registro time-picker"
                        placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                    </mat-form-field>
                </div>
    
                <div class="col-sm-12 col-md-6">
                    <mat-form-field appearance="outline">
                    <mat-label>{{translation.HoraFin}}</mat-label>
                    <input matInput formControlName="HoraFin1Reserva" class="editor-campo-registro time-picker"
                        placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                    </mat-form-field>
                </div>
                </div>
    
                <div class="form-group row">
                    <div class="col-sm-12 col-md-6">
                        <mat-form-field appearance="outline">
                        <mat-label>{{translation.HoraInicio}}</mat-label>
                        <input matInput formControlName="HoraInicio2Reserva" class="editor-campo-registro time-picker"
                            placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                        </mat-form-field>
                    </div>
        
                    <div class="col-sm-12 col-md-6">
                        <mat-form-field appearance="outline">
                        <mat-label>{{translation.HoraFin}}</mat-label>
                        <input matInput formControlName="HoraFin2Reserva" class="editor-campo-registro time-picker"
                            placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <div style="margin: 20px 5px 0 5px; text-align: center;">
              <mat-label>{{translation.Centros}}</mat-label>
        
              <customan-dual-list [source]="centrosSinAsignar"
              [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
              </customan-dual-list>
            </div>
            


<!--             <mat-accordion>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-label>{{translation.Venta}}<mat-slide-toggle matInput name="Venta" formControlName="Venta"></mat-slide-toggle>
                         </mat-label>
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
              
                  <ng-container *ngIf="editRegistroForm.controls['Venta'].value">
                    <div class="form-group row">
                        <div class="col-sm-12 col-md-6">
                            <mat-form-field appearance="outline">
                            <mat-label>{{translation.HoraInicio}}</mat-label>
                            <input matInput formControlName="HoraInicio1Venta" class="editor-campo-registro time-picker"
                                placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                            </mat-form-field>
                        </div>
            
                        <div class="col-sm-12 col-md-6">
                            <mat-form-field appearance="outline">
                            <mat-label>{{translation.HoraFin}}</mat-label>
                            <input matInput formControlName="HoraFin1Venta" class="editor-campo-registro time-picker"
                                placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                            </mat-form-field>
                        </div>
                        </div>
            
                        <div class="form-group row">
                            <div class="col-sm-12 col-md-6">
                                <mat-form-field appearance="outline">
                                <mat-label>{{translation.HoraInicio}}</mat-label>
                                <input matInput formControlName="HoraInicio2Venta" class="editor-campo-registro time-picker"
                                    placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                                </mat-form-field>
                            </div>
                
                            <div class="col-sm-12 col-md-6">
                                <mat-form-field appearance="outline">
                                <mat-label>{{translation.HoraFin}}</mat-label>
                                <input matInput formControlName="HoraFin2Venta" class="editor-campo-registro time-picker"
                                    placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                                </mat-form-field>
                            </div>
                        </div>
                    </ng-container>
              
                </mat-expansion-panel>
            
              </mat-accordion> -->

            <!-- -->
            <h2>{{translation.MotivoIdioma}}</h2>
            <mat-accordion *ngFor="let idioma of editorListaIdiomas; let i = index" >
                <mat-expansion-panel [expanded]="i==0">
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{idioma.Nombre}}
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                  </mat-expansion-panel-header>
              
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                      <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                        [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                        formControlName="{{'nombre' + idioma.CodIdioma}}">
                  </mat-form-field>
        
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                      <textarea matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                        [value]="idioma.Descripcion" formControlName="{{'descripcion' + idioma.CodIdioma}}"></textarea>
                  </mat-form-field>
              
                </mat-expansion-panel>
            
              </mat-accordion>

            <!-- -->

<!--             <div *ngFor="let idioma of editorListaIdiomas" class="form-group">
                <div class="col-sm-12">
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                      <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                        [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                        formControlName="{{'nombre' + idioma.CodIdioma}}">
                  </mat-form-field>
        
                  <mat-form-field appearance="outline">
                    <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                      <textarea matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                        [value]="idioma.Descripcion" formControlName="{{'descripcion' + idioma.CodIdioma}}"></textarea>
                  </mat-form-field>
                </div>
            </div> -->
        
    </form>
  </div>
</div>
