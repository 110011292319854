<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>

<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div class="divForm" [ngClass]="{panelalpha: loading==true}">
<form  [formGroup]="editTiposSesionForm" (onSubmit)="submit()">

  <div class="form-group row">
    <div class="col-sm-12 col-md-6">
      <app-input-filter class="input-select" *ngIf="tiposesiondata && Recintos" formControlText="Recinto" [label]="translation.Recinto"
      [placeHolderTranslation]="translation.Recinto" [list]="Recintos" textShow="Nombre"
      dataInput="pkId" [data]="tiposesiondata.Recinto" required="false" (close)="closeInputFilter($event)" [disabled]="disabled" 
      (change)="cambiodeRecinto($event)"></app-input-filter>
    </div>
        <!-- <mat-form-field class="input-select" appearance="outline" fxFlex="30">
          <mat-label>{{translation.Recinto}}</mat-label>
          <mat-select placeholder="{{translation.Recinto}}" formControlName="Recinto"  [disabled]="(mode == 'U') && (pkId!='0') "
            (selectionChange)="CambioRecintoSeleccionado($event)">
            <mat-option *ngFor="let recinto of Recintos"
              [value]="recinto.pkId">
              {{recinto.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Idioma}}</mat-label>
            <mat-select placeholder="{{translation.Idioma}}" formControlName="CodIdioma">
            <mat-option value=""> --- --- </mat-option>
            <mat-option *ngFor="let idioma of idiomasApp"
              [value]="idioma.CodIdioma">
              {{idioma.Nombre}}
            </mat-option>
           </mat-select>
          </mat-form-field>
          
       </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-10">
          <mat-form-field appearance="outline" >
            <mat-label>{{translation.Descripcion}}</mat-label>
            <input matInput formControlName="Descripcion">
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-2">
          <div class="tipoaforo" *ngIf="RecintoInfo">{{RecintoInfo.TipoAforoDesc}}</div>
       </div>
     </div>

     <div class="form-group row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline" >
          <mat-label>{{translation.AforoMaximo}}</mat-label>
          <input matInput type="number" formControlName="AforoMaximo" min="0">
          <div *ngIf="formControls.AforoMaximo.errors" class="invalid-feedback">
            <div *ngIf="formControls.AforoMaximo.hasError('noenRango')">Superado el máximo disponible
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline" >
          <mat-label>{{translation.AforoMinimo}}</mat-label>
          <input matInput type="number" formControlName="MinimoAforo" min="0">
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline" >
          <mat-label>{{translation.AforoInternet}}</mat-label>
          <input matInput type="number" formControlName="MaximoInternet" min="0">
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline" >
          <mat-label>{{translation.IntervaloAforo}}</mat-label>
          <mat-select placeholder="{{translation.IntervaloAforo}}" formControlName="Intervalo"
            name="tipoIntervalo" #listaIntervalos
            (selectionChange)="cambiarDuracionDisabled($event)">
            <mat-option *ngFor="let tipointervalo of arr_intervalos_aforo"
              [value]="tipointervalo.minutos.toString()">
              {{tipointervalo.texto}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

     <div class="form-group row">

      <div class="col-sm-12 col-md-6 col-lg-3">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput formControlName="FechaInicio" [matDatepicker]="dpdesde" placeholder="{{translation.FechaDesde}}" [min]="fecha_minDesde" [max]="fecha_maxDesde"
          (dateChange)="reloadVal('FechaFin')">
          <mat-datepicker-toggle matSuffix [for]="dpdesde"></mat-datepicker-toggle>
          <mat-datepicker #dpdesde></mat-datepicker>
        </mat-form-field>
      </div>
        <!--mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput placeholder="{{translation.FechaDesde}}" formControlName="fechaDesdeVisita"
            [matDatepicker]="dpdesdevisita" [min]="fechaFV_minDesde" [max]="fechaFV_maxDesde">
          <mat-datepicker-toggle matSuffix [for]="dpdesdevisita"></mat-datepicker-toggle>
          <mat-datepicker #dpdesdevisita></mat-datepicker>
        </mat-form-field-->
        <div class="col-sm-12 col-md-6 col-lg-3">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.HoraInicio}}</mat-label>
        <input matInput formControlName="HoraInicio" type="time" placeholder="{{translation.SeleccionaHoraDesde}}"  (change)="reloadVal('HoraFin')">
        <div *ngIf="f.HoraInicio.errors" class="invalid-feedback">
          <div *ngIf="f.HoraInicio.hasError('noenRango')">{{translation.NoConcuerdaConElHorarioDelRecinto}}
          </div>
        </div>
      </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.FechaHasta}}</mat-label>
        <input matInput formControlName="FechaFin" [matDatepicker]="dphasta" placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta" [max]="fecha_maxHasta"
          (dateChange)="cambiahasta($event)">
        <mat-datepicker-toggle matSuffix [for]="dphasta"></mat-datepicker-toggle>
        <mat-datepicker #dphasta></mat-datepicker>
      </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.HoraFin}}</mat-label>
        <input matInput formControlName="HoraFin" type="time" placeholder="{{translation.SeleccionaHoraHasta}}" (change)="cambiaHorahasta($event)">
        <div *ngIf="f.HoraFin.errors" class="invalid-feedback">
          <div *ngIf="f.HoraFin.hasError('noenRango')">{{translation.NoConcuerdaConElHorarioDelRecinto}}
          </div>
        </div>
      </mat-form-field>
      </div>
     </div>

     <div class="form-group row">
      <div class="col-sm-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.Duracion}} {{translation.Hor}}</mat-label>
        <input matInput type="number" formControlName="duracionhora" placeholder="{{translation.Hor}}">
      </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.Duracion}} {{translation.Min}}</mat-label>
        <input matInput type="number" formControlName="duracionminuto" placeholder="{{translation.Min}}">
      </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>{{translation.Didactica}}</mat-label>
        <input matInput type="number" formControlName="Didactica" placeholder="{{translation.Didactica}}">
      </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <div class="flex">
          <div>
            <mat-label>{{translation.Butacas}} <mat-slide-toggle formControlName="Butacas"></mat-slide-toggle></mat-label>
          </div>
          <div appearance="outline">
            <mat-label>{{translation.EnvioExternos}} <mat-slide-toggle formControlName="EnvioExternos"></mat-slide-toggle></mat-label>
          </div>
          <div appearance="outline">
            <mat-label>{{translation.EventoEspecial}} <mat-slide-toggle formControlName="Evento"></mat-slide-toggle></mat-label>
          </div>
        </div>
      </div>
     </div>

     <div class="form-group row">
      <div class="col-12">
        <mat-form-field appearance="outline" >
          <mat-select placeholder="{{translation.DiasConAcceso}}" formControlName="tipoControlDias"
          name="tipoDias" multiple [(ngModel)]="defaultseldays" [compareWith]="comparaObjetosSelect"
          (selectionChange)="CambioDiasSemanaMultiple($event)">
          <mat-option *ngFor="let dia of globales.diasSemana" [value]="dia.id">
            {{dia.value}}
          </mat-option>
        </mat-select>
        </mat-form-field>
      </div>
     </div>


     <div class="form-group row">
     

      <div class="col-sm-12 col-md-1 col-lg-1 mb-4" >
        <mat-label>{{translation.Grupos}} <mat-slide-toggle formControlName="haygrupos"></mat-slide-toggle></mat-label>
      </div>
        <div class="col-sm-12 col-md-5 col-lg-5">
        <mat-form-field *ngIf="editTiposSesionForm.controls['haygrupos'].value === true" appearance="outline" >
          <mat-label>{{translation.NumeroGrupos}}</mat-label>
          <input matInput type="number" formControlName="NumeroGrupos" >
        </mat-form-field>
        </div>
     </div>

     <div style="margin: 20px 5px 0 5px; text-align: center;">
      <mat-label>{{translation.Centros}}</mat-label>

      <customan-dual-list [source]="centrosSinAsignar"
      [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
      </customan-dual-list>
    </div>

  </form>
</div>
</div>