import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.scss']
})
export class PortadaComponent implements OnInit {

  name:string="User";
  constructor() { }

  ngOnInit(): void {
    this.getName();
  }

  getName(){
    let sessionData=JSON.parse(sessionStorage.getItem("currentUser"));
    if (sessionData!=null) {
      this.name = sessionData["DatosResult"]["Nombre"];
    }
  }
  

}
