<div id="component">
    <app-buttons  (open)="openPanel()" [buttonsWrap]="'add'"></app-buttons>
    
    
    
    <app-filter [tableName]="'typeAccessControl'" ></app-filter>
    
    
    
    </div>
    <app-table [tableName]="'typeAccessControl'"></app-table>

    <app-panelcustoman 
    *ngIf="customanpanel!==undefined" 
    [ConfigPanel]="customanpanel"
    [mode]="'I'" >
    </app-panelcustoman> 