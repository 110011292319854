import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Form, FormControl, Validators } from '@angular/forms';

import * as d3 from 'd3';

import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';

import moment from 'moment';

import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { InfoMapasService } from '../selector-butacas/servicios/info-mapas.service';
import { EspectaculosService } from '@sharedV11/services/espectaculos/espectaculos.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatMenu } from '@angular/material/menu';


@Component({
  selector: 'customan-sesiones-mapa',
  templateUrl: './sesiones-mapa.component.html',
  styleUrls: ['./sesiones-mapa.component.css']
})
export class SesionesMapaComponent implements OnInit {
  loading = false;
  editorButacasForm: FormGroup;
  editorButacasSinNumerarForm: FormGroup;
  estadosLeyenda: FormGroup;
  numeradas: boolean;
  nonumeradas: boolean;
  svg: any;
  recinto: any;
  idsButacasMarcadas = [];
  getButacasRes: any;
  getEstadosButacas: any;
  getEstadosButacasPorRecinto: any;
  listaEstadosButacas: any;
  listaTiposButacas: any;
  listaRecintos: any;
  items: FormArray;
  divTiposButaca = [''];
  recintoNoNumeradoSeleccionado: 0;

  testing = true;
  element			= null;
  previousElement = null;
  currentY		= 0;
  currentX		= 0;
  originX			= 0;
  originY			= 0;

  @Input() GrupoRecinto:string;
  @Input() chPathMapaSVG:string;
  @Input() horaInicioSesion: string;
  @Input() fechaSesion: string;
  @Input() espectaculoId: string;
  @Input() sesionId: string;
  @Input() contextual: boolean = true;
  @Input() svgheight: string = "450";
  @Input() svgwidth: string = "560";
  clickTime: any;
  attributesText: any;
  rectElement: any;
  lastTransform: any = {k: 2.441893025338287, x: 477.85381396380967, y: -903.029665907032 };
  

/*group: any;*/
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private infoMapasService: InfoMapasService,
    private espectaculosService: EspectaculosService,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
  }
  ngOnInit() {

    this.clickTime = d3.select("#clicktime");
    this.attributesText = d3.select("#attributestext");

    this.espectaculosService.sesionSeleccionada.GrupoRecinto = this.GrupoRecinto;
    this.espectaculosService.sesionSeleccionada.espectaculoId = parseInt(this.espectaculoId);
    this.espectaculosService.sesionSeleccionada.sesionId = parseInt(this.sesionId);
    this.espectaculosService.sesionSeleccionada.fechaSesion = this.fechaSesion;
    this.espectaculosService.sesionSeleccionada.horaInicioSesion = this.horaInicioSesion;
    this.espectaculosService.sesionSeleccionada.chPathMapaSVG = this.chPathMapaSVG;
    



    if (!this.espectaculosService.sesionSeleccionada.espectaculoId) {
    //if (!this.espectaculosService.espectaculo) {
      const urlSections = this.router.url.split('/');
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();
      urlSections.pop();

      let backUrl = '';

      urlSections.forEach(section => {
        backUrl += section + '/';
      });
      this.router.navigate([backUrl]);
    }
    this.editorButacasForm = this.formBuilder.group({
      EditarEstado: [{ value: '', disabled: false }],
      EditarTipo: [{ value: '', disabled: false }]
    });

    this.editorButacasSinNumerarForm = this.formBuilder.group({
      EditarEstado: [{ value: '', disabled: false }],
      EditarTipo: [{ value: '', disabled: false }],
      cantidadNORMAL: [{ value: 0, disabled: false }],
      cantidadVREDUCIDA: [{ value: 0, disabled: false }],
      cantidadMINUSVALIDO: [{ value: 0, disabled: false }],
      cantidadBLOQUEOS: [{ value: 0, disabled: false }],
      cantidadRESERVA: [{ value: 0, disabled: false }],
      cantidadVIP: [{ value: 0, disabled: false }],
      lblNORMAL: [{ value: '0', disabled: true }],
      lblBLOQUEOS: [{ value: '0', disabled: true }],
      lblVREDUCIDA: [{ value: '0', disabled: true }],
      lblMINUSVALIDO: [{ value: '0', disabled: true }],
      lblRESERVA: [{ value: '0', disabled: true }],
      lblVIP: [{ value: '0', disabled: true }]
    });

    /*Control butacas numeradas*/
    this.numeradas = false;
    /*Control butacas sinnumerar*/
    this.nonumeradas = false;

    // tslint:disable-next-line: max-line-length
    this.servicioAPI.GetTipoButacasPorRecintoAsync(this.espectaculosService.sesionSeleccionada.espectaculoId, this.espectaculosService.sesionSeleccionada.fechaSesion, this.espectaculosService.sesionSeleccionada.horaInicioSesion,
      this.espectaculosService.sesionSeleccionada.GrupoRecinto).subscribe(tipoRecinto => {
        if (tipoRecinto === undefined && tipoRecinto.DatosListas.length === 0) {
          this.listaRecintos = undefined;
        } else {
          this.listaRecintos = tipoRecinto.DatosResult;
        }
        this.servicioAPI.TraerDatosListasAsync('estadosbutaca').subscribe(estadosbutaca => {
          if (estadosbutaca === undefined && estadosbutaca.DatosListas.length === 0) {
            this.listaEstadosButacas = undefined;
          } else {
            this.listaEstadosButacas = estadosbutaca.DatosListas;
          }
          if (estadosbutaca !== undefined) {

            this.servicioAPI.TraerDatosListasAsync('tiposbutaca').subscribe(tiposbutaca => {
              if (tiposbutaca === undefined && tiposbutaca.DatosListas.length === 0) {
                this.listaTiposButacas = undefined;
              } else {
                this.listaTiposButacas = tiposbutaca.DatosListas;
              }
              if (tiposbutaca !== undefined) {
                this.listaTiposButacas.forEach(tipo =>{
                  this.divTiposButaca.push(tipo.Nombre);
                });
                this.loadMap(estadosbutaca.DatosListas, tiposbutaca.DatosListas);
              }
            });
          }
      });
    });
  }

  async loadMap(estadosbutaca: any, tiposbutaca: any) {
    let fecha = this.espectaculosService.sesionSeleccionada.fechaSesion;
    await this.servicioAPI.getButacasAsync(this.espectaculosService.sesionSeleccionada.GrupoRecinto, this.espectaculosService.sesionSeleccionada.sesionId,
      "", "").then(res => {
      if (res === undefined && res.DatosResult.sesiones.length === 0) {
        this.getButacasRes = undefined;
      } else {
        this.getButacasRes = res;
      }
    });

    await this.servicioAPI.EstadosButacasAsync().then(estados => {
      if (estados === undefined && estados.DatosResult.Estados.length === 0) {
        this.getEstadosButacas = undefined;
      } else {
        this.getEstadosButacas = estados.DatosResult.Estados;
      }
    });

    await this.servicioAPI.EstadosButacasPorRecintoAsync(this.espectaculosService.sesionSeleccionada.espectaculoId, fecha,
      this.espectaculosService.sesionSeleccionada.horaInicioSesion, this.espectaculosService.sesionSeleccionada.GrupoRecinto).then(estados => {
      if (estados === undefined && estados.DatosResult.Estados.length === 0) {
        this.getEstadosButacasPorRecinto = undefined;
      } else {
        this.getEstadosButacasPorRecinto = estados.DatosResult.Estados;
      }
    });

    const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'tooltip')
    .attr('id', 'tooltip')
    .style('opacity', 0);

    this.loading = true;
    this.infoMapasService.getSVG(this.espectaculosService.sesionSeleccionada.chPathMapaSVG.split('.')[0]).subscribe(async (data: string) => {
      this.loading = false;
      const espacio = {
        width: parseInt(this.svgwidth),
        height: parseInt(this.svgheight)
      };

      this.svg = d3.select('#mapaButacas')
        .attr('preserveAspectRatio', 'xMidYMid meet')
        .attr('viewBox', '0 0 ' + 2800 + ' ' + 1000)
        .attr('width', espacio.width)
        .attr('height', espacio.height);

      let _this = this;  
      d3.selectAll('g').remove();
      this.recinto = this.svg.append('g')
        .append('g')
        .attr('id', 'recinto')
        .attr('width', espacio.width)
        .attr('height', espacio.height)
        //.attr('transform', 'translate(' + espacio.width / 2 + ',0) scale(1.4)')
        //.attr('transform', 'translate(' + this.lastTransform.x + ','+this.lastTransform.y+') scale('+this.lastTransform.k+')')
        //.attr('x', '0')
        //.attr('y', '0');
        .attr('transform', 'translate(' + this.lastTransform.x + ','+this.lastTransform.y+') scale('+this.lastTransform.k+')');

        const zoom = d3
        .zoom()
        .scaleExtent([2, 10])
        .on('zoom', () => {
          console.log("Zoom", d3.event, d3.event.transform);
          if (d3 && d3.event.transform != undefined) _this.lastTransform = d3.event.transform;
          this.recinto.attr('transform', d3.event.transform);
        });

        

        this.svg.call(zoom);
        //this.svg.call(zoom.transform, d3.zoomIdentity);
        this.recinto.attr('transform', 'translate(' + this.lastTransform.x + ','+this.lastTransform.y+') scale('+this.lastTransform.k+')');
        //this.recinto.attr('transform', this.lastTransform);

        ///// DRAG

  /*       var selectionRect = {
          element			: null,
          previousElement : null,
          currentY		: 0,
          currentX		: 0,
          originX			: 0,
          originY			: 0,
          setElement: function(ele) {
            this.previousElement = this.element;
            this.element = ele;
          },
          getNewAttributes: function() {
            var x = this.currentX<this.originX?this.currentX:this.originX;
            var y = this.currentY<this.originY?this.currentY:this.originY;
            var width = Math.abs(this.currentX - this.originX);
            var height = Math.abs(this.currentY - this.originY);
            return {
                  x       : x,
                  y       : y,
                  width  	: width,
                  height  : height
            };
          },
          getCurrentAttributes: function() {
            // use plus sign to convert string into number
            var x = +this.element.attr("x");
            var y = +this.element.attr("y");
            var width = +this.element.attr("width");
            var height = +this.element.attr("height");
            return {
              x1  : x,
                  y1	: y,
                  x2  : x + width,
                  y2  : y + height
            };
          },
          getCurrentAttributesAsText: function() {
            var attrs = this.getCurrentAttributes();
            return "x1: " + attrs.x1 + " x2: " + attrs.x2 + " y1: " + attrs.y1 + " y2: " + attrs.y2;
          },
          init: function(newX, newY) {
            var rectElement = this.svg.append("rect")
                .attr({
                    rx      : 4,
                    ry      : 4,
                    x       : 0,
                    y       : 0,
                    width   : 0,
                    height  : 0
                })
                .classed("selection", true);
              this.setElement(rectElement);
            this.originX = newX;
            this.originY = newY;
            this.update(newX, newY);
          },
          update: function(newX, newY) {
            this.currentX = newX;
            this.currentY = newY;
            this.element.attr(this.getNewAttributes());
          },
          focus: function() {
                this.element
                    .style("stroke", "#DE695B")
                    .style("stroke-width", "2.5");
            },
            remove: function() {
              this.element.remove();
              this.element = null;
            },
            removePrevious: function() {
              if(this.previousElement) {
                this.previousElement.remove();
              }
            }
        }; */
        



        //https://gist.github.com/paradite/71869a0f30592ade5246

        let _that = this;
        var dragBehavior = d3.drag()
        .on("drag", function() {
          console.log("dragMove");
          var p = d3.mouse(this);
          _that.update(p[0], p[1]);
          _that.attributesText
              .text(_that.getCurrentAttributesAsText());
        })
        .on("start", function() {
          console.log("dragStart");
            var p = d3.mouse(this);
            _that.inicializa(p[0], p[1]);
            _that.removePrevious();
        })
        .on("end", function() {
          console.log("dragEnd");
          var finalAttributes = _that.getCurrentAttributes();
          console.dir(finalAttributes);
          if(finalAttributes.x2 - finalAttributes.x1 > 1 && finalAttributes.y2 - finalAttributes.y1 > 1){
            console.log("range selected");
            // range selected
            d3.event.sourceEvent.preventDefault();
            this.focus();
          } else {
            console.log("single point");
                // single point selected
                _that.removeSelRect();
                // trigger click event manually
                _that.clicked();
            }
        });
    
        this.svg.call(dragBehavior);

        /////

      const rec = document.getElementById('recinto');
      if (rec) {
        rec.insertAdjacentHTML('beforeend', data);
      }
      console.log(this.recinto);
      this.recinto.selectAll('circle').remove();
      //this.recinto.attr('transform', this.lastTransform);
      this.infoMapasService.getButacas(this.espectaculosService.sesionSeleccionada.chPathMapaSVG.split('.')[0]).subscribe((butacas: [any]) => {
        for (let i = 0; i < butacas.length; ++i)
        {
          if (butacas[i].idRecintoButaca !== undefined) {
            for (let o = 0; o <  this.getButacasRes.DatosResult.sesiones.length; ++o) {
              if (this.getButacasRes.DatosResult.sesiones[o] !== undefined &&
                this.getButacasRes.DatosResult.sesiones[o].DistribucionGrafica !== undefined &&
                 butacas[i].idRecintoButaca === this.getButacasRes.DatosResult.sesiones[o].DistribucionGrafica) {
                  butacas[i].ButacaDDBB = this.getButacasRes.DatosResult.sesiones[o];
              }
            }
          }
        }
        butacas.forEach(butaca => {
            if (butaca.ButacaDDBB !== undefined && butaca.ButacaDDBB.idRecintoButaca !== undefined) {
              let encontrado = false;
              this.listaRecintos.forEach (rec => {
                if (rec.RecintoID === butaca.ButacaDDBB.RecintoId && rec.Numeradas === '0') {
                  encontrado = true;
                }
              });
              if (!encontrado) {
                const c = this.recinto.append('circle')
                .attr('_pkid', butaca.ButacaDDBB.idRecintoButaca)
                .style('fill', () => {
                  let colorestilo = '';
                  this.getEstadosButacas.forEach(estado => {
                    if (butaca.ButacaDDBB.Estado === estado.EstadoButaca.toString() &&
                    (estado.TipoButaca === null || butaca.ButacaDDBB.TipoButaca === estado.TipoButaca.toString())) {
                      colorestilo = estado.Color;
                    }
                    });
                    return colorestilo;
                })
                .attr('cx', butaca.cx)
                .attr('cy', butaca.cy)
                .attr('r', butaca.r)
                .on('mouseover', d => {
                  d3.select(this).style("cursor", "pointer");
                  tooltip.style('opacity', 1);
                  tooltip
                    .html(function() {
                      let nombreestadobutaca = '';
                      let nombretipobutaca= '';
                      estadosbutaca.forEach(obj =>{
                        if (obj.Id.toString() === butaca.ButacaDDBB.Estado.toString())
                        {
                          nombreestadobutaca =  obj.Nombre;
                        }
                      });
                      tiposbutaca.forEach(obj =>{
                        if (obj.Id.toString() === butaca.ButacaDDBB.TipoButaca.toString())
                        {
                          nombretipobutaca =  obj.Nombre;
                        }
                      });
                        return 'Estado: ' + nombreestadobutaca + ' TipoButaca: ' + nombretipobutaca ;
                      })
                    .style('left', d3.event.pageX + 10 + 'px')
                    .style('background', 'rgba(255, 255, 204, 0.9)')
                    .style('top', d3.event.pageY - 28 + 'px')
                   

                  })
                  .on('mouseout', function(d) {
                    tooltip.style('opacity', 0);
                  })
                  .on('click', () => {
                    this.numeradas = true;
                    if (this.nonumeradas)
                    {
                      this.borraSeleccionSinRecarga();
                    }
                    this.nonumeradas = false;
                    if (this.idsButacasMarcadas.includes(butaca.ButacaDDBB.idRecintoButaca)) { // remove
                      this.idsButacasMarcadas.splice(this.idsButacasMarcadas.indexOf(butaca.ButacaDDBB.idRecintoButaca), 1);
                      this.getEstadosButacas.forEach(estado => {
                        if (butaca.ButacaDDBB.Estado === estado.EstadoButaca.toString() &&
                        (estado.TipoButaca === null || butaca.ButacaDDBB.TipoButaca === estado.TipoButaca.toString())) {
                          const marcado = [{
                            'RecintoButacaIds': butaca.ButacaDDBB.idRecintoButaca,
                            'idTarifa': 0
                          }];
                          this.servicioAPI.MarcarDesmarcarButacaAsync(marcado, 0).subscribe(respuesta => {
                            if (respuesta !== undefined && respuesta.DatosResult) {
                              c.style('fill',  estado.Color);
                            }
                          });
                        }
                        });
                    } else { // add
                      this.idsButacasMarcadas.push(butaca.ButacaDDBB.idRecintoButaca);
                      this.getEstadosButacas.forEach(element => {
                        this.editorButacasForm = this.formBuilder.group({
                          EditarEstado: [{ value: butaca.ButacaDDBB.Estado, disabled: false }],
                          EditarTipo: [{ value: butaca.ButacaDDBB.TipoButaca, disabled: false }]
                        });
                      });
                      const marcado = [{
                        'RecintoButacaIds': butaca.ButacaDDBB.idRecintoButaca,
                        'idTarifa': 0
                      }];
                      this.servicioAPI.MarcarDesmarcarButacaAsync(marcado, 1).subscribe(respuesta => {
                        if (respuesta !== undefined && respuesta.DatosResult && respuesta.DatosResult.IdRB.length > 0) {
                          c.style('fill', 'blue');
                        }
                      });
                    }
                  });
              }
            }
          });
        this.listaRecintos.forEach (rec => {
          if (rec.Numeradas === '0') {
            this.recinto.selectAll('[id="R' + rec.RecintoID + '"]')
            .style('cursor', 'pointer')
            .style('stroke', function () {
              return d3.select(this).style('fill');
            })
            .style('stroke-width', '2')
            .on('mouseover', function () {
              d3.select(this).style('fill-opacity', '0.6');
            })
            .on('mouseout', function () {
              d3.select(this).style('fill-opacity', '1');
            })
            .on('click', () => {
              this.recintoNoNumeradoSeleccionado = rec.RecintoID;
              this.nonumeradas = true;
              this.numeradas = false;
              this.resetearCantidadesNoNumeradas();
              this.borraSeleccionSinRecarga();
              const form = this.editorButacasSinNumerarForm.value;
              this.getEstadosButacasPorRecinto.forEach(estadosbutaca => {
                if (estadosbutaca.IDRecinto === rec.RecintoID) {
                  if (estadosbutaca.Descripcion === 'NORMAL') {
                    this.editorButacasSinNumerarForm.patchValue({ lblNORMAL: estadosbutaca.NumButaca }); }
                  else if (estadosbutaca.Descripcion === 'MINUSVALIDO') {
                    this.editorButacasSinNumerarForm.patchValue({ lblMINUSVALIDO: estadosbutaca.NumButaca }); }
                  else if (estadosbutaca.Descripcion === 'VIP') {
                    this.editorButacasSinNumerarForm.patchValue({ lblVIP: estadosbutaca.NumButaca }); }
                  else if (estadosbutaca.Descripcion === 'VREDUCIDA') {
                    this.editorButacasSinNumerarForm.patchValue({ lblVREDUCIDA: estadosbutaca.NumButaca }); }
                  else if (estadosbutaca.Descripcion === 'BLOQUEOS') {
                    this.editorButacasSinNumerarForm.patchValue({ lblBLOQUEOS: estadosbutaca.NumButaca }); }
                  else if (estadosbutaca.Descripcion === 'RESERVA') {
                    this.editorButacasSinNumerarForm.patchValue({ lblRESERVA: estadosbutaca.NumButaca }); }
                }
              });
              console.log('Has seleccionado un recinto no numerado.');
            })
            .append('svg:title')
            .text('Recinto no numerado.');
          }
        });
      });
    });
  }

  borrarSeleccion() {
    this.idsButacasMarcadas = [];
    this.servicioAPI.DesamarcarButacasBySesionId().subscribe(respuesta => {
      this.ngOnInit();
    });
  }

  borraSeleccionSinRecarga() {
    this.idsButacasMarcadas = [];
    this.servicioAPI.DesamarcarButacasBySesionId().subscribe(respuesta => {
    });
  }

  async guardarButacas() {
    const valor = this.editorButacasForm.value;
    this.servicioAPI.RecintosButacasCambioTipoEstado(valor.EditarTipo, valor.EditarEstado).subscribe(respuesta => {
      this.borrarSeleccion();
    });
  }

  async guardarButacasSinNumerar() {
    const valor = this.editorButacasSinNumerarForm.value;
    this.servicioAPI.RecintosButacasCambioTipoEstado(valor.EditarTipo, valor.EditarEstado).subscribe(respuesta => {
      this.borrarSeleccion();
    });
  }


  async guardarButacasSinNumerarMenuTipo(estado,tipo) {

    let _that = this;
    this.servicioAPI.RecintosButacasCambioTipoEstado(tipo, estado).subscribe(respuesta => {
      this.recinto.attr('transform', _that.lastTransform);
      //this.recinto.attr('transform', 'translate(' + this.lastTransform.x + ','+this.lastTransform.y+') scale('+this.lastTransform.k+')');
      this.borrarSeleccion();
    });
  }

  resetearCantidadesNoNumeradas() {
    this.editorButacasSinNumerarForm.patchValue({ cantidadNORMAL: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadMINUSVALIDO: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadVIP: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadVREDUCIDA: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadBLOQUEOS: 0 });
    this.editorButacasSinNumerarForm.patchValue({ cantidadRESERVA: 0 });
  }

  eliminar(valor: string) {
    let cantidad = 0;
    const form = this.editorButacasSinNumerarForm.value;
    if (valor === 'NORMAL') { cantidad = form.cantidadNORMAL; }
    else if (valor === 'MINUSVALIDO') { cantidad = form.cantidadMINUSVALIDO; }
    else if (valor === 'VIP') { cantidad = form.cantidadVIP; }
    else if (valor === 'VREDUCIDA') { cantidad = form.cantidadVREDUCIDA; }
    else if (valor === 'BLOQUEOS') { cantidad = form.cantidadBLOQUEOS; }
    else if (valor === 'RESERVA') { cantidad = form.cantidadRESERVA; }
    if (cantidad <= 0) { cantidad = 1; }
    cantidad --;
    const marcado = [{
      'Cantidad': cantidad,
      'idTarifa': 0
    }];
    let fecha = moment(this.espectaculosService.sesionSeleccionada.fechaSesion, 'DD/MM/YYYY').format('YYYY/MM/DD');
    this.servicioAPI.MarcarDesmarcarButacaNoNumeradaAsync(this.recintoNoNumeradoSeleccionado, this.espectaculosService.sesionSeleccionada.espectaculoId,
      fecha, this.espectaculosService.sesionSeleccionada.horaInicioSesion, valor, marcado).subscribe(respuesta => {
      if (respuesta !== undefined && respuesta.DatosResult && respuesta.DatosResult.Butacas.length >= 0) {
        this.noNumeradasSetCantidad(valor, cantidad);
      } else {
        this._snackBar.open('Error al liberar la butaca', null, {
            duration: 4000,
            //panelClass: ['blue-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
      }
    });
  }

  agregar(valor: string){
    let cantidad = 0;
    const form = this.editorButacasSinNumerarForm.value;
    if (valor === 'NORMAL') { cantidad = form.cantidadNORMAL; }
    else if (valor === 'MINUSVALIDO') { cantidad = form.cantidadMINUSVALIDO; }
    else if (valor === 'VIP') { cantidad = form.cantidadVIP; }
    else if (valor === 'VREDUCIDA') { cantidad = form.cantidadVREDUCIDA; }
    else if (valor === 'BLOQUEOS') { cantidad = form.cantidadBLOQUEOS; }
    else if (valor === 'RESERVA') { cantidad = form.cantidadRESERVA; }
    cantidad ++;
    const marcado = [{
      'Cantidad': cantidad,
      'idTarifa': 0
    }];
    let fecha = moment(this.espectaculosService.sesionSeleccionada.fechaSesion, 'DD/MM/YYYY').format('YYYY/MM/DD');
    this.servicioAPI.MarcarDesmarcarButacaNoNumeradaAsync(this.recintoNoNumeradoSeleccionado, this.espectaculosService.sesionSeleccionada.espectaculoId,
      fecha, this.espectaculosService.sesionSeleccionada.horaInicioSesion, valor, marcado).subscribe(respuesta => {
      if (respuesta !== undefined && respuesta.DatosResult && respuesta.DatosResult.Butacas.length > 0) {
        this.noNumeradasSetCantidad(valor, cantidad);
      } else {
        this._snackBar.open('No puede marcar mas butacas', null, {
            duration: 4000,
            //panelClass: ['blue-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
      }
    });
  }

  noNumeradasSetCantidad(valor: string, cantidad: number) {
    if (valor === 'NORMAL') { this.editorButacasSinNumerarForm.patchValue({ cantidadNORMAL: cantidad }); }
    else if (valor === 'MINUSVALIDO') { this.editorButacasSinNumerarForm.patchValue({ cantidadMINUSVALIDO: cantidad }); }
    else if (valor === 'VIP') { this.editorButacasSinNumerarForm.patchValue({ cantidadVIP: cantidad }); }
    else if (valor === 'VREDUCIDA') { this.editorButacasSinNumerarForm.patchValue({ cantidadVREDUCIDA: cantidad }); }
    else if (valor === 'BLOQUEOS') { this.editorButacasSinNumerarForm.patchValue({ cantidadBLOQUEOS: cantidad }); }
    else if (valor === 'RESERVA') { this.editorButacasSinNumerarForm.patchValue({ cantidadRESERVA: cantidad }); }
  }

  onChange(tipoN: string, event: any): void {
    let valor = event.target.value;
    const value = this.stringIsNumber(valor) ? Number(valor) : 0;
    if (value > 0 && Number.isInteger(value)) {
      console.log(valor);
    } else {
      valor = '0';
    }
    const marcado = [{
      'Cantidad': value,
      'idTarifa': 0
    }];
    let fecha = moment(this.espectaculosService.sesionSeleccionada.fechaSesion, 'DD/MM/YYYY').format('YYYY/MM/DD');
    this.servicioAPI.MarcarDesmarcarButacaNoNumeradaAsync(this.recintoNoNumeradoSeleccionado, this.espectaculosService.sesionSeleccionada.espectaculoId,
      fecha, this.espectaculosService.sesionSeleccionada.horaInicioSesion, tipoN, marcado).subscribe(respuesta => {
      if (respuesta !== undefined && respuesta.DatosResult && respuesta.DatosResult.Butacas.length >= 0) {
        event.target.value = respuesta.DatosResult.Butacas.length;
      } else {
        this._snackBar.open('No puede marcar mas butacas', null, {
            duration: 4000,
            //panelClass: ['blue-snackbar'],
            horizontalPosition: 'center',
            verticalPosition: 'top'
          });
      }
    });
  }

  stringIsNumber(s) {
    const x = +s;
    return x.toString() === s;
  }

  mostrarEditarSesion() {
    const urlSections = this.router.url.split('/');
    urlSections.pop();

    let backUrl = '';

    urlSections.forEach(section => {
      backUrl += section + '/';
    });
    this.router.navigate([backUrl]);
  }

  goBack() {
    const urlSections = this.router.url.split('/');
    urlSections.pop();
    urlSections.pop();
    urlSections.pop();

    let backUrl = '';

    urlSections.forEach(section => {
      backUrl += section + '/';
    });
    this.router.navigate([backUrl]);
  }

  traerEstado(estado){
    let respuestaEstado = '';
     this.listaEstadosButacas.forEach(obj =>{
      if (obj.Id.toString() === estado.toString()){
        respuestaEstado = obj.Nombre;
      }
    });
    return respuestaEstado;
  }


  // DRAG FUNCTIONS


    setElement(ele) {
      this.previousElement = this.element;
      this.element = ele;
    };

    getNewAttributes() {
      var x = this.currentX<this.originX?this.currentX:this.originX;
      var y = this.currentY<this.originY?this.currentY:this.originY;
      var width = Math.abs(this.currentX - this.originX);
      var height = Math.abs(this.currentY - this.originY);
      return {
            x       : x,
            y       : y,
            width  	: width,
            height  : height
      };
    };

    getCurrentAttributes() {
      // use plus sign to convert string into number
      var x = +this.element.attr("x");
      var y = +this.element.attr("y");
      var width = +this.element.attr("width");
      var height = +this.element.attr("height");
      return {
        x1  : x,
            y1	: y,
            x2  : x + width,
            y2  : y + height
      };
    };

    getCurrentAttributesAsText() {
      var attrs = this.getCurrentAttributes();
      return "x1: " + attrs.x1 + " x2: " + attrs.x2 + " y1: " + attrs.y1 + " y2: " + attrs.y2;
    };

    inicializa(newX, newY) {
/*       var rr = this.svg.append("rect")
      .attr("width", 200)
      .attr("height", 200)
      .attr("x", newX)
      .attr("y", newY)
      .attr("rx", 4)
      .attr("ry", 4);

      rr.classed("selectionBox", true);
      this.focus(); */

      this.rectElement = this.svg.append("rect");

    this.rectElement.attr({
              rx      : 4,
              ry      : 4,
              x       : 0,
              y       : 0,
              width   : 0,
              height  : 0
          });
      this.rectElement.classed("selection", true);
      this.setElement(this.rectElement);
      this.originX = newX;
      this.originY = newY;
      this.update(newX, newY);
    };

    update(newX, newY) {
      this.currentX = newX;
      this.currentY = newY;
      this.element.attr(this.getNewAttributes());
    };
    
    focus() {
          this.element
              .style("stroke", "#DE695B")
              .style("stroke-width", "2.5");
    };

    removeSelRect() {
      this.element.remove();
      this.element = null;
    };


    removePrevious(){
      if(this.previousElement) {
        this.previousElement.remove();
      }
    };


/*     dragStart() {
      console.log("dragStart");
        var p = d3.mouse(this);
        this.inicializa(p[0], p[1]);
      this.removePrevious();
    };
      
    dragMove() {
      console.log("dragMove");
      var p = d3.mouse(this);
        this.update(p[0], p[1]);
        this.attributesText
          .text(this.getCurrentAttributesAsText());
    }; */
      
    clicked() {
      var d = new Date();
        this.clickTime
          .text("Clicked at " + d.toTimeString().substr(0,8) + ":" + d.getMilliseconds());
    };

/*     dragEnd() {
      console.log("dragEnd");
      var finalAttributes = this.getCurrentAttributes();
      console.dir(finalAttributes);
      if(finalAttributes.x2 - finalAttributes.x1 > 1 && finalAttributes.y2 - finalAttributes.y1 > 1){
        console.log("range selected");
        // range selected
        d3.event.sourceEvent.preventDefault();
        this.focus();
      } else {
        console.log("single point");
            // single point selected
            this.removeSelRect();
            // trigger click event manually
            this.clicked();
        }
    }; */

  
  /// 

}
