import { Component, OnInit, HostBinding, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

import { first } from 'rxjs/operators';

import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TiposControl_Respuesta, TipoControl } from '../../_modelos/tipos-control-Respuesta.model';
import { Recintos_Respuesta, Recintos, IdiomaR } from '../../_modelos/recintos-Respuesta.model';
import { grupos_tipos_entrada_Respuesta, GrupoTiposEntrada } from '../../_modelos/grupos-tipos-entrada-Respuesta.model';
import { TiposEntrada_Respuesta, TipoEntrada, RespuestaBotonera, Botonera } from '../../_modelos/tipos-entrada-Respuesta.model';
import { TipoButaca_Respuesta, TipoButaca } from '../../_modelos/tipos-butacas-Respuesta.model';

import { ConectarAPIService } from '../../_API/conectar-api.service';
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from '../../shared/_alerta/alerta.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Globales } from '@clasesV6/globales';
import { Translator } from 'src/app/shared/services/translator/translator.service';



export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class ColumnaIdiomas {
  textoHeader: string;
  CodIdioma: string;
}

export class traspasomodelo {
  EntradaId: string;
  Nombre: string;
  Descripcion: string;
  PrecioUnitario: string;
  DivisaId: string;
  ALaVenta: boolean;
  FormatoId: string;
  TextoPantalla: string;
  CondicionesVenta: string;
  TextoImpresion: string;
  TextoDisplay: string;
  CodigoBarras: boolean;
  IVA: string;
  Funcion: string;
  BaseImponible: string;
  TipoAbreviado: string;
  MaximoPorDia: string;
  GrupoId: string;
  ImprimirTicket: boolean;
  ControlarAcceso: boolean;
  CodigoTipoControl: string;
  TeclaRapida: string;
  GrupoMotivoId: string;
  ImpresoraSecundaria: string;
  TicketVendido: string;
  ImprimirPrecio: boolean;
  TipoButaca: string;
  CuentaVisitante: boolean;
  CaracteristicaId: string;
  ArgumentosCaracteristica: string;
  TipoButacaId: string;
  BarCodeInternet: boolean;
  RecintosAsociados: string;
  ActividadesAsociadas: string;

  // Nuevos campos
  TipoProducto: string;
  GrupoAsociado: string;
  TipoVisita: string;
  TipoNivel: string;
  Categoria: string;
  TEDatos: string;
  ConfiguracionAdicional: string;
  CentrosIds: string;
  CentroCosteId: string;
  CanalId: string;

  deserialize: null
}

export class filtrosActivos {
  Nombre: string;
  Activo: string;
  CodigoTipoControl: string;
  Grupo: string;
  GrupoMotivo: string;
  textoGeneral: string;
}

@Component({
  selector: 'app-tipos-entrada',
  templateUrl: './tipos-entrada.component.html',
  styleUrls: ['./tipos-entrada.component.css']
})
export class TiposEntradaComponent implements OnInit {
  displayedColumns: string[] = ['Acciones', 'Nombre', 'Descripcion', 'TextoPantalla', 'TextoDisplay', 'TextoImpresion',
    'TipoAbreviado', 'Grupo', 'GrupoMotivo', 'ControlaAcceso', 'TipoControl', 'AlaVenta', 'ImprimirTicket', 'ImprimirCBarras',
    'ImprimirCBarrasOnline', 'ImprimirPrecio', 'ActividadesAsociados', 'Impresora', 'Butacas',
    'CuentaVisitante'];
  columnsToDisplay: string[] = ['Acciones', 'Nombre', 'Descripcion', 'TextoPantalla', 'Grupo', 'GrupoMotivo', 'ControlaAcceso', 'TipoControl',
    'AlaVenta', 'ActividadesAsociados'];
  displayedFilters: string[] = ['Nombre', 'EstaALaVenta', 'TipoControlAcceso', 'GrupoDeTipoDeEntrada', 'Motivos'];
  filtersToDisplay: string[] = this.displayedFilters.slice();
  dataSource: MatTableDataSource<TipoEntrada>;
  seleccion = new SelectionModel<TipoEntrada>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  usuarios;
  respuestaAPI: Recintos_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  panelFiltrosvisible: boolean = false;
  //editorListaIdiomas: Array<IdiomaGR>;
  //--
  // tiposIdiomasAPI: Idiomas_Respuesta;
  // tiposIdiomas: Array<Idiomas>;
  // tipoControl = new FormControl('', [Validators.required]);
  // tipoControlIdiomas = new FormControl('', [Validators.required]);
  //---
  tiposRecintosAPI: Recintos_Respuesta;
  tiposRecintoTotal: Array<Recintos>;
  tiposRecinto: Array<Recintos>;
  tiposActividad: Array<Recintos>;
  //--
  tiposTiposControlAPI: TiposControl_Respuesta;
  tiposTiposControl: Array<TipoControl>;
  tipocontrol = new FormControl('');
  tipocontrolbotonera = new FormControl('');


  // Nuevos Campos
  tipoproductocontrol = new FormControl('');
  grupoentradacontrol = new FormControl('');
  grupoentradamotivo = new FormControl('');
  grupoasociadocontrol = new FormControl('');
  tipovisitacontrol = new FormControl('');
  tiponivelcontrol = new FormControl('');
  categoriacontrol = new FormControl('');
  TEDatoscontrol = new FormControl('');
  CentroCoste = new FormControl('');
  Canales = new FormControl('');

  tipoControlTipoButacas = new FormControl('');
  tipoimpresora = new FormControl('');
  //--
  tiposTiposButacaAPI: TipoButaca_Respuesta;
  tiposTiposButaca: Array<TipoButaca>;
  //--
  tiposBotoneraEntradaAPI: RespuestaBotonera;
  tiposBotoneraEntrada: Array<Botonera>;
  //--
  tiposGruposTipoEntradaAPI: grupos_tipos_entrada_Respuesta;
  tiposGrupoTipoEntrada: Array<GrupoTiposEntrada>;
  tiposGrupoTipoEntradaMotivo: Array<GrupoTiposEntrada>;
  //tipoGrupoEntradaControl = new FormControl('', [Validators.required]);
  //--
  tiposTiposEntradaAPI: TiposEntrada_Respuesta;
  tiposTipoEntrada: Array<TipoEntrada>;
  tiposTipoEntradaTaller: Array<TipoEntrada>;
  tiposTipoEntradaRecinto: Array<TipoEntrada>;
  //--
  columnasIdiomas: Array<ColumnaIdiomas>;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  //compruebaPorDefectovalor:boolean = true;
  tallerActividad: boolean = false;
  butacasCheck: boolean = false;
  recintosasignados: string[] = [];
  ordenRecintosAsociados: string[] = [];
  panelSelectorFiltrosvisible: boolean = false;
  actividadesasociadas: string[] = [];
  ordenActividadesAsociadas: string[] = [];
  translation;
  formControlListaFiltros: FormControl;
  TiposEntradaDatosAPI: any;
  tiposEntradaDatos: any[] = [];
  GruposAsociadosAPI: any;
  TiposNivelesAPI: any;
  TiposProductosAPI: any;
  gruposAsociadosDatos: any[] = [];
  tiposNivelesDatos: any[] = [];
  tiposProductosDatos: any[] = [];
  TipoVisitaAPI: any;
  tipoVisitaDatos: any[] = [];
  CategoriaAPI: any;
  categoriaDatos: any[] = [];
  CentroCosteAPI: any;
  centroCosteDatos: any[] = [];
  CanalesAPI: any;
  canalesDatos: any[] = [];
  CentrosAPI: any;
  tipoCentros: any[] = [];
  centrosasociados: string[] = [];
  // tiposCentros: Array<any>;
  //
  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private cdRef:ChangeDetectorRef,
    private translator: Translator) {

  }

  @HostBinding('class.col') true;

  ngAfterViewInit(){
    //this.initializeForms()
    this.cdRef.detectChanges();

  }
  async ngOnInit() {

    this.translation = this.translator.GetTranslations();

    this.displayedColumns = ['Acciones', 'Nombre', 'Descripcion', 'TextoPantalla', 'TextoDisplay', 'TextoImpresion',
      'TipoAbreviado', 'Grupo', 'GrupoMotivo', 'ControlaAcceso', 'AlaVenta', 'ImprimirTicket', 'ImprimirCBarras',
      'ImprimirCBarrasOnline', 'ImprimirPrecio', 'Impresora', 'Butacas',
      'CuentaVisitante'];
    this.columnsToDisplay = ['Acciones', 'Nombre', 'Descripcion', 'TextoPantalla', 'Grupo', 'GrupoMotivo', 'ControlaAcceso',
      'AlaVenta'];
    this.displayedFilters = [this.translation.Nombre, this.translation.EstaALaVenta, this.translation.TipoControlAcceso, this.translation.GrupoDeTiposDeEntrada, this.translation.Motivos];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(

      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        if (!this.permisoFormulario) { this.router.navigate(['/portada']) };
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: "borrar", texto: this.translation.Borrar, funcion: "BorrarSeleccionados" });
        }
      }
    );
    //Seguimos
    //this.cargarDatos();
    this.resetearFiltrosActivos();


    this.editorRegistroForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      descripcion: [''],
      textopantalla: [''],
      textodisplay: [''],
      textoimpresion: [''],
      nombreabreviado: [''],
      //grupoentradacontrol: [''],
      //grupoentradamotivo: [''],
      contolaacceso: [''],
      tipocontrol: [''],
      //tipoimpresora: [''],
      alaventa: [''],
      imprimirticket: [''],
      imprimircbarras: [''],
      imprimircbarrasonline: [''],
      imprimirprecio: [''],
      //tipoControlTipoButacas: [''],
      cuentavisitante: [''],
      tipoTipoControlBotonera: [''],
    });

    const asociarCentrosCosteCanales = JSON.parse(localStorage.getItem('AsociarCentrosCosteCanales'));
    const ObligatorioCentrosDeCoste = JSON.parse(localStorage.getItem('ObligatorioCentrosDeCoste'));
    const ObligatorioCanalesEntradas = JSON.parse(localStorage.getItem('ObligatorioCanalesEntradas'));
    if (asociarCentrosCosteCanales == '1') {
      if (ObligatorioCentrosDeCoste == '1') {
        this.editorRegistroForm.addControl('centroCosteId', new FormControl('', Validators.required));
      } else {
        this.editorRegistroForm.addControl('centroCosteId', new FormControl(''));
      }

      if (ObligatorioCanalesEntradas == '1') {
        this.editorRegistroForm.addControl('canalId', new FormControl('', Validators.required));
      } else {
        this.editorRegistroForm.addControl('canalId', new FormControl(''));
      }
    }

    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [''],
      activo: [''],
      tipoControl: [''],
      tipoControlG: [''],
      tipoControlM: ['']
    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);

  }

  // un getter para facilitar el acceso a los campos del form
  get f() { return this.editorRegistroForm.controls; } //Editor
  get fFiltro() { return this.editorFiltrosForm.controls; } //Filtros

  model = new traspasomodelo();

  x() {
  }
  AnadirRegistro() {
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.ALaVenta = false;
    this.model.CodigoBarras = false;
    this.model.ImprimirTicket = false;
    this.model.ControlarAcceso = false;
    this.model.ImprimirPrecio = false;
    this.model.CuentaVisitante = false;
    this.model.BarCodeInternet = false;
    this.recintosasignados = [];
    this.centrosasociados = [];
    this.ordenRecintosAsociados = [];
    this.actividadesasociadas = [];
    this.ordenActividadesAsociadas = [];

    this.editorRegistroForm.reset();
  }

  async editar(item) {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
       if (this.editorRegistroForm.get(key)) this.editorRegistroForm.get(key).reset();
    });
    let objtraspaso = new traspasomodelo();
    //this.compruebaPorDefectovalor = this.compruebaPorDefecto(item.PorDefecto);
    objtraspaso.EntradaId = item.EntradaId;
    objtraspaso.Nombre = item.Nombre;
    objtraspaso.Descripcion = item.Descripcion;
    objtraspaso.PrecioUnitario = item.PrecioUnitario;
    objtraspaso.DivisaId = item.DivisaId;
    objtraspaso.ALaVenta = this.globales.aBooleano(item.ALaVenta);
    objtraspaso.CodigoBarras = this.globales.aBooleano(item.CodigoBarras);
    objtraspaso.ImprimirTicket = this.globales.aBooleano(item.ImprimirTicket);
    objtraspaso.FormatoId = item.FormatoId;
    objtraspaso.TextoPantalla = item.TextoPantalla == null || item.TextoPantalla.trim() == "" ? item.Nombre : item.TextoPantalla; //
    objtraspaso.TextoImpresion = item.TextoImpresion == null || item.TextoImpresion.trim() == "" ? item.Nombre : item.TextoImpresion; //
    objtraspaso.TextoDisplay = item.TextoDisplay == null || item.TextoDisplay.trim() == "" ? item.Nombre : item.TextoDisplay; //
    objtraspaso.CondicionesVenta = item.CondicionesVenta;
    objtraspaso.IVA = item.IVA;
    objtraspaso.BaseImponible = item.BaseImponible;
    objtraspaso.TipoAbreviado = item.TipoAbreviado;
    objtraspaso.MaximoPorDia = item.MaximoPorDia;
    objtraspaso.CodigoTipoControl = item.CodigoTipoControl;
    objtraspaso.ControlarAcceso = this.globales.aBooleano(item.ControlarAcceso);
    objtraspaso.GrupoId = item.GrupoId;
    objtraspaso.TeclaRapida = item.TeclaRapida;
    objtraspaso.GrupoMotivoId = item.GrupoMotivoId;
    objtraspaso.ImpresoraSecundaria = item.ImpresoraSecundaria;
    objtraspaso.TicketVendido = item.TicketVendido;
    objtraspaso.ImprimirPrecio = this.globales.aBooleano(item.ImprimirPrecio);
    objtraspaso.TipoButaca = item.TipoButaca;
    objtraspaso.CuentaVisitante = this.globales.aBooleano(item.CuentaVisitante);
    objtraspaso.CaracteristicaId = item.CaracteristicaId;
    objtraspaso.ArgumentosCaracteristica = item.ArgumentosCaracteristica;
    objtraspaso.TipoButacaId = item.TipoButacaId;
    objtraspaso.BarCodeInternet = this.globales.aBooleano(item.BarCodeInternet);
    objtraspaso.RecintosAsociados = item.RecintosAsociados;
    objtraspaso.ActividadesAsociadas = item.ActividadesAsociadas;

    // Nuevos campos
    objtraspaso.TipoProducto = item.TipoProducto;
    objtraspaso.GrupoAsociado = item.GrupoAsociado;
    objtraspaso.TipoVisita = item.TipoVisita;
    objtraspaso.TipoNivel = item.TipoNivel;
    objtraspaso.Categoria = item.Categoria;
    objtraspaso.TEDatos = item.TEDatos;
    objtraspaso.ConfiguracionAdicional = item.ConfiguracionAdicional;
    objtraspaso.CentroCosteId = item.CentroCosteId;
    objtraspaso.CanalId = item.CanalId;

    this.model = objtraspaso;
    const trim = item.RecintosAsociados.split(',');
    trim.forEach(x => {
      const num = x.split(':');
      this.tiposRecinto.forEach(recinto => {
        if (recinto.PkId == num[0]) {
          if (!this.editorRegistroForm.get(recinto.PkId)) { // aqui
            this.editorRegistroForm.addControl(recinto.PkId, new FormControl(''));
          }
          this.editorRegistroForm.get(recinto.PkId).setValue(num[1]);
        }
      });
    });

    const trimActividades = item.ActividadesAsociadas.split(',');
    trimActividades.forEach(x => {
      const num = x.split(':');
      this.tiposRecinto.forEach(recinto => {
        if (recinto.PkId == num[0]) {
          if (!this.editorRegistroForm.get(recinto.PkId)) {
            this.editorRegistroForm.addControl(recinto.PkId, new FormControl(''));
          }
          this.editorRegistroForm.get(recinto.PkId).setValue(num[1]);
        }
      });
    });



    const asociarCentrosCosteCanales = JSON.parse(localStorage.getItem('AsociarCentrosCosteCanales'));
    const ObligatorioCentrosDeCoste = JSON.parse(localStorage.getItem('ObligatorioCentrosDeCoste'));
    const ObligatorioCanalesEntradas = JSON.parse(localStorage.getItem('ObligatorioCanalesEntradas'));
    if (asociarCentrosCosteCanales == '0') {
      if (ObligatorioCentrosDeCoste == '1') {
        this.editorRegistroForm.addControl('centroCosteId', new FormControl(item.CentroCosteId, Validators.required));
      } else {
        this.editorRegistroForm.addControl('centroCosteId', new FormControl(item.CentroCosteId));
      }
      if (ObligatorioCanalesEntradas == '1') {
        this.editorRegistroForm.addControl('canalId', new FormControl(item.CanalId, Validators.required));
      } else {
        this.editorRegistroForm.addControl('canalId', new FormControl(item.CanalId));
      }
      /* this.editorRegistroForm.controls['centroCosteId'].setValue(item.CentroCosteId);
      this.editorRegistroForm.controls['canalId'].setValue(parseInt(item.CanalId));
      this.editorRegistroForm.addControl('centroCoste', new FormControl(item.CentroCosteId));
      this.editorRegistroForm.addControl('canales', new FormControl(item.CanalId)); */
    }

    const centrosIds = item.CentrosIds.split(',');
    centrosIds.forEach(centroId => {
      this.centrosasociados.push(centroId);
    })

    // this.editorRegistroForm.addControl(r.Nombre, new FormControl(''));
    let tmprecintos = this.separarOrdenDeRecinto(item.RecintosAsociados != null && item.RecintosAsociados.trim() != "" ? item.RecintosAsociados.split(',') : []);
    this.recintosasignados = tmprecintos.valor;
    this.ordenRecintosAsociados = tmprecintos.orden;
    let tmpactividades = this.separarOrdenDeRecinto(item.ActividadesAsociadas != null && item.ActividadesAsociadas.trim() != "" ? item.ActividadesAsociadas.split(',') : []);
    this.actividadesasociadas = tmpactividades.valor;
    this.ordenActividadesAsociadas = tmpactividades.orden;
    //Cargamos entradas especificas de Taller y de Recinto
    //await this.CargarDatosTiposEntradas(1,objtraspaso.PkId);
    //await this.CargarDatosTiposEntradas(2,objtraspaso.PkId);
    //Last Touches ;)
    this.editando = true;
    this.alertaServicio.limpiar();
    this.cdRef.detectChanges();

  }

  separaRecintos(conjunto: string): string[] {
    let respuesta: string[] = conjunto != null && conjunto.trim() != "" ? conjunto.split(',') : [];
    return respuesta;
  }

  separarOrdenDeRecinto(conjunto: string[]): { valor: string[], orden: string[] } {
    let rValor: string[] = [];
    let rOrden: string[] = [];
    conjunto.forEach(element => {
      let elemento = element.split(':')
      if (elemento.length > 0) {
        if (elemento.length = 2) {
          rValor.push(elemento[0]);
          rOrden.push(elemento[1] == '0' ? null : elemento[1]);
        }
        else {
          rValor.push(elemento[0]);
          rOrden.push(null);
        }
      }


    });
    return { valor: rValor, orden: rOrden }
  }

  guardar() {
    //TODO, de momento no se guarda hay que cambiar todos los modelos y lo que se pasa
    //y el procedimiento (no es motivo grupo) WOIT
    let insertando = this.model.EntradaId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      let objtraspaso = new traspasomodelo;
      if (this.model.EntradaId != null) {
        objtraspaso = this.dataSource.data.map<traspasomodelo>(function (item) {
          return {
            Nombre: item.Nombre,
            Descripcion: item.Descripcion,
            PrecioUnitario: item.PrecioUnitario,
            DivisaId: item.DivisaId,
            ALaVenta: globales.aBooleano(item.ALaVenta),
            FormatoId: item.FormatoId,
            TextoPantalla: item.TextoPantalla,
            CondicionesVenta: item.CondicionesVenta,
            TextoImpresion: item.TextoImpresion,
            TextoDisplay: item.TextoDisplay,
            CodigoBarras: globales.aBooleano(item.CodigoBarras),
            IVA: item.IVA,
            BaseImponible: item.BaseImponible,
            TipoAbreviado: item.TipoAbreviado,
            MaximoPorDia: item.MaximoPorDia,
            GrupoId: item.GrupoId,
            ImprimirTicket: globales.aBooleano(item.ImprimirTicket),
            ControlarAcceso: globales.aBooleano(item.ControlarAcceso),
            CodigoTipoControl: item.CodigoTipoControl,
            TeclaRapida: item.TeclaRapida,
            GrupoMotivoId: item.GrupoMotivoId,
            ImpresoraSecundaria: item.ImpresoraSecundaria,
            TicketVendido: item.TicketVendido,
            ImprimirPrecio: globales.aBooleano(item.ImprimirPrecio),
            TipoButaca: item.TipoButaca,
            CuentaVisitante: globales.aBooleano(item.CuentaVisitante),
            CaracteristicaId: item.CaracteristicaId,
            ArgumentosCaracteristica: item.ArgumentosCaracteristica,
            TipoButacaId: item.TipoButacaId,
            BarCodeInternet: globales.aBooleano(item.BarCodeInternet),
            RecintosAsociados: item.RecintosAsociados,
            ActividadesAsociadas: item.ActividadesAsociadas,
            deserialize: null,
            // Nuevos campos
            Funcion: 'U',
            EntradaId: item.EntradaId ? item.EntradaId : "0",
            TipoProducto: item.TipoProducto,
            GrupoAsociado: item.GrupoAsociado,
            TipoVisita: item.TipoVisita,
            TipoNivel: item.TipoNivel,
            Categoria: item.Categoria,
            TEDatos: item.TEDatos,
            ConfiguracionAdicional: item.ConfiguracionAdicional,
            CentrosIds: item.CentrosIds,
            CentroCosteId: item.CentroCosteId,
            CanalId: item.CanalId,

          }
        }).find(item => item.EntradaId == this.model.EntradaId);
      }


      objtraspaso.Funcion = this.model.EntradaId ? 'U' : 'I';
      objtraspaso.Nombre = this.model.Nombre;
      objtraspaso.Descripcion = this.model.Descripcion;
      objtraspaso.PrecioUnitario = "0"; //Ver Doc Requisitos
      objtraspaso.DivisaId = this.globales.divisapordefectoSistema; //Ver Doc Requisitos
      objtraspaso.ALaVenta = this.model.ALaVenta;
      objtraspaso.CodigoBarras = this.model.CodigoBarras;
      objtraspaso.ImprimirTicket = this.model.ImprimirTicket;
      objtraspaso.FormatoId = "1"; //Ver Doc Requisitos
      objtraspaso.TextoPantalla = this.model.TextoPantalla;
      objtraspaso.TextoImpresion = this.model.TextoImpresion;
      objtraspaso.TextoDisplay = this.model.TextoDisplay;
      objtraspaso.CondicionesVenta = ""; //Ver Doc Requisitos
      objtraspaso.IVA = "0"; //Ver Doc Requisitos
      objtraspaso.BaseImponible = "0"; //Ver Doc Requisitos
      objtraspaso.TipoAbreviado = this.model.TipoAbreviado;
      objtraspaso.MaximoPorDia = "0"; //Ver Doc Requisitos
      objtraspaso.CodigoTipoControl = this.model.CodigoTipoControl;
      objtraspaso.ControlarAcceso = this.model.ControlarAcceso;
      objtraspaso.GrupoId = this.model.GrupoId;
      objtraspaso.TeclaRapida = this.model.TeclaRapida; //Ver Doc Requisitos //Se vuelven a modificar los requisitos y esta vez se usa
      objtraspaso.GrupoMotivoId = this.model.GrupoMotivoId;
      objtraspaso.ImpresoraSecundaria = this.model.ImpresoraSecundaria;
      objtraspaso.TicketVendido = "0"; //Ver Doc Requisitos
      objtraspaso.ImprimirPrecio = this.model.ImprimirPrecio;
      objtraspaso.TipoButacaId = this.model.TipoButacaId;
      let tmTipoButaca = this.tiposTiposButaca.filter(x => objtraspaso.TipoButacaId == x.Id)
      if (tmTipoButaca == null || tmTipoButaca.length == 0) { objtraspaso.TipoButaca = "" }
      else { objtraspaso.TipoButaca = tmTipoButaca[0].Nombre; }
      objtraspaso.CuentaVisitante = this.model.CuentaVisitante;
      objtraspaso.CaracteristicaId = ""; //Ver Doc Requisitos
      objtraspaso.ArgumentosCaracteristica = ""; //Ver Doc Requisitos
      objtraspaso.BarCodeInternet = this.model.BarCodeInternet;
      objtraspaso.RecintosAsociados = this.obtenerRecintosAsociadosconOrden(); //this.recintosasociados.join();
      objtraspaso.ActividadesAsociadas = this.obtenerActividadesAsociadosconOrden();

      // Nuevos campos
      objtraspaso.TipoProducto = this.model.TipoProducto;
      objtraspaso.GrupoAsociado = this.model.GrupoAsociado;
      objtraspaso.TipoVisita = this.model.TipoVisita;
      objtraspaso.TipoNivel = this.model.TipoNivel;
      objtraspaso.Categoria = this.model.Categoria;
      objtraspaso.TEDatos = this.model.TEDatos;
      objtraspaso.ConfiguracionAdicional = this.model.ConfiguracionAdicional;
      objtraspaso.CentrosIds = this.centrosasociados.toString();
      objtraspaso.CentroCosteId = this.model.CentroCosteId;
      //objtraspaso.CanalId = this.model.CanalId.toString();

      let itemBotonera = this.tiposBotoneraEntrada.find(item => item.TeclaRapida === objtraspaso.TeclaRapida);
      //console.log('itemBotonera: ', itemBotonera);
      //console.log('itemBotonera.NombreTipo: ', itemBotonera.NombreTipo);

      if (itemBotonera == null || itemBotonera.NombreTipo == "" || objtraspaso.Funcion === 'U') {

        //Salvar a API
      /*   this.servicioAPI.insUpdTiposEntradas(
          this.model.EntradaId,
          objtraspaso.Nombre,
          objtraspaso.Descripcion,
          objtraspaso.PrecioUnitario,
          objtraspaso.DivisaId,
          globales.aBit(objtraspaso.ALaVenta).toString(),
          objtraspaso.FormatoId, objtraspaso.TextoPantalla, objtraspaso.CondicionesVenta, objtraspaso.TextoImpresion, objtraspaso.TextoDisplay,
          globales.aBit(objtraspaso.CodigoBarras).toString(),
          objtraspaso.IVA, objtraspaso.BaseImponible, objtraspaso.TipoAbreviado,
          objtraspaso.MaximoPorDia, objtraspaso.GrupoId,
          globales.aBit(objtraspaso.ImprimirTicket).toString(),
          globales.aBit(objtraspaso.ControlarAcceso).toString(),
          objtraspaso.CodigoTipoControl,
          objtraspaso.TeclaRapida, objtraspaso.GrupoMotivoId,
          objtraspaso.ImpresoraSecundaria, objtraspaso.TicketVendido,
          globales.aBit(objtraspaso.ImprimirPrecio).toString(),
          objtraspaso.TipoButaca,
          globales.aBit(objtraspaso.CuentaVisitante).toString(),
          objtraspaso.CaracteristicaId, objtraspaso.ArgumentosCaracteristica,
          objtraspaso.TipoButacaId,
          globales.aBit(objtraspaso.BarCodeInternet).toString(),
          objtraspaso.RecintosAsociados, objtraspaso.ActividadesAsociadas,

          // Campos Nuevos
          objtraspaso.TipoProducto,
          objtraspaso.GrupoAsociado,
          objtraspaso.TipoVisita,
          objtraspaso.TipoNivel,
          objtraspaso.Categoria,
          objtraspaso.TEDatos,
          objtraspaso.ConfiguracionAdicional,
          objtraspaso.CentrosIds,
          objtraspaso.CentroCosteId,
          objtraspaso.CanalId,

        )
          .pipe(first())
          .subscribe(
            data => {
              this.respuestaAPI = new Recintos_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Guardados Correctamente")
                //let max = Math.max.apply(Math,this.dataSource.data.map(function(item){return item.PkId;}));
                //Hacemos un push al data para que se muestre
                this.cargarDatosAsync();
                if (insertando) {
                  // this.model.PkId = ''+(max + 1);
                  // let pusheddata =   this.dataSource.data;
                  // pusheddata .push(this.modeloaclaseAPI(this.model));
                  // this.dataSource.data = pusheddata;
                  //Cargamos la Botonera Fuera del principal
                  this.cargarBotonera();
                }
                else {
                  this.cargarDatosAsync();
                  this.cargarBotonera();
                }
                this.editando = false;
              }
              else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
                //this.editando = false;
              }

            },
            error => {
              this.alertaServicio.error(error);
              //this.editando = false;
            }
          );

      } else { // Tecla rapida ya asignada
        const dialogRef = this.dialog.open(DialogoPopUp, {
          width: '250px',
          data: {
            titulo: this.translation.TeclaRapidaYaAsignada,
            texto: this.translation.SeDebeSeleccionarUnaTeclaRapidaDisponible,
            ok: this.translation.Confirmar
          }
        }); */
      }

    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.EntradaId != null) {
      this.model.EntradaId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.EntradaId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
      }
      else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas()
      }
    }

  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
      }
    }
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      //console.log(evento.option.value + " " + evento.option.selected);

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //   console.log(a.value);}
      }
    }
  }

  traeNombreTipoControlxID(id) {
    let respuesta = this.tiposTiposControl.filter(x => x.CodigoTipoControl == id)
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
  }
  // traeNombreCategoriaxID(id)
  // {
  //   let respuesta = this.tiposTiposCategoria.filter(x=> x.PkId == id)
  //   return respuesta == null || respuesta.length == 0? null: respuesta[0].Nombre;
  // }
  traeNombreGrupoxID(id) {
    let respuesta = this.tiposGrupoTipoEntrada.filter(x => x.pkId == id)
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].NombreGrupo;
  }
  traeNombreImpresoraxID(id) {
    let respuesta = this.globales.impresorasSeleccionables.filter(x => x.id == id)
    return respuesta == null || respuesta.length == 0 ? null : respuesta[0].value;
  }
  traeNombreTipoButacaxID(id) {
    //WOIT
    //let respuesta = this.tiposTiposButaca.filter(x=> x.id == id)
    //return respuesta == null || respuesta.length == 0? null: respuesta[0].Nombre;
    return "";
  }

  compruebadrops(): boolean {
    return this.editorRegistroForm.valid;
    if (this.model.GrupoId == null || this.model.GrupoId.trim() == "") {
      return false;
    } else {
    }
  }
  // compruebaPorDefecto(valor:string)
  // {
  //   if(!(valor.trim().toLowerCase() == "true" || valor.trim().toLowerCase() == "1")){
  //   return this.dataSource != null && this.dataSource.data != null && this.dataSource.data.length != 0 &&
  //   this.dataSource.data.some(x => x.PorDefecto.trim() == "1" || x.PorDefecto.toLocaleLowerCase().trim()  == "true")}
  //   else {return false;}
  // }

  cambiaNombre(evento) {
    //Si los modelos de Display, Taquilla y Imprimir estan vacios les pongo el nombre del evento.
    if (this.model.TextoDisplay == null || this.model.TextoDisplay.trim() == "") {
      this.model.TextoDisplay = evento.target.value;
    }
    if (this.model.TextoPantalla == null || this.model.TextoPantalla.trim() == "") {
      this.model.TextoPantalla = evento.target.value;
    }
    if (this.model.TextoImpresion == null || this.model.TextoImpresion.trim() == "") {
      this.model.TextoImpresion = evento.target.value;
    }
  }

  cambiaActividadTaller(evento) {
    //this.CargarDatosTiposEntradas(evento.target.checked?2:1,this.model.PkId);
    this.tallerActividad = evento.target.checked;
    if (this.tallerActividad) {
      this.tiposTipoEntradaTaller = this.tiposTipoEntradaRecinto;
      this.tiposTipoEntradaRecinto = [];
    }
    else {
      this.tiposTipoEntradaRecinto = this.tiposTipoEntradaTaller;
      this.tiposTipoEntradaTaller = [];
    }

  }
  cambiaButacas(evento) {
    this.butacasCheck = evento.target.checked;
  }

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();

    if (this.dataSource != null && this.dataSource.data != null) {
      if (filtro != null) {

        if (filtro.Nombre != null && filtro.Nombre.trim() != "") {
          this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
        }
        this.fFiltro["nombre"].setValue(filtro.Nombre.trim());

        if (filtro.Activo != null && filtro.Activo.trim() != "") {
          this.filtrosactivosModelo.Activo = filtro.Activo.trim();
        }
        this.fFiltro["activo"].setValue(filtro.Activo.trim());

        if (filtro.CodigoTipoControl != null && filtro.CodigoTipoControl != "") {
          this.filtrosactivosModelo.CodigoTipoControl = filtro.CodigoTipoControl;
        }
        this.fFiltro["tipoControl"].setValue(filtro.CodigoTipoControl);

        if (filtro.Grupo != null && filtro.Grupo != "") {
          this.filtrosactivosModelo.Grupo = filtro.Grupo;
        }
        this.fFiltro["tipoControlG"].setValue(filtro.Grupo);

        if (filtro.GrupoMotivo != null && filtro.GrupoMotivo.trim() != "") {
          this.filtrosactivosModelo.GrupoMotivo = filtro.GrupoMotivo;
        }
        this.fFiltro["tipoControlM"].setValue(filtro.GrupoMotivo);
      }
      else {
        if (this.fFiltro["nombre"].value != null && this.fFiltro["nombre"].value.trim() != "") {
          this.filtrosactivosModelo.Nombre = this.fFiltro["nombre"].value;
        }
        if (this.fFiltro["activo"].value != null && this.fFiltro["activo"].value.trim() != "") {
          this.filtrosactivosModelo.Activo = this.fFiltro["activo"].value;
        }
        if (this.fFiltro["tipoControl"].value != null && this.fFiltro["tipoControl"].value != "") {
          this.filtrosactivosModelo.CodigoTipoControl = this.fFiltro["tipoControl"].value;
          this.fFiltro["tipoControl"].reset();
        }
        if (this.fFiltro["tipoControlG"].value != null && this.fFiltro["tipoControlG"].value != "") {
          this.filtrosactivosModelo.Grupo = this.fFiltro["tipoControlG"].value;
          this.fFiltro["tipoControlG"].reset();
        }
        if (this.fFiltro["tipoControlM"].value != null && this.fFiltro["tipoControlM"].value != "") {
          this.filtrosactivosModelo.GrupoMotivo = this.fFiltro["tipoControlM"].value;
          this.fFiltro["tipoControlM"].reset();
        }
      }
    }
    await this.cargarDatosAsync().then(
      respuesta => {
        let siono = new Boolean(respuesta);
        if (siono) {
          //let datostemporales = this.dataSource.data;
          //this.dataSource.data = datostemporales;

          // if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != "") {
          //   this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          // }
          // else {
          //   //Solo borramos el input text, el filtro se actualiza Ok
          //   //this.filtrogeneral.nativeElement.value = '';
          // }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        }
        else {
          //No hemos podido cargar los datos
        }

      },
      error => {
        //No hemos podido cargar los datos
      });
    //Cargamos la Botonera Fuera del principal
    await this.cargarBotonera().then(
      respuesta => { },
      error => {
      });
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre = filtro == null || filtro == "Nombre" ? "" : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.Activo = filtro == null || filtro == "Activo" ? "" : this.filtrosactivosModelo.Activo;
    this.filtrosactivosModelo.CodigoTipoControl = filtro == null || filtro == "CodigoTipoControl" ? null : this.filtrosactivosModelo.CodigoTipoControl;
    this.filtrosactivosModelo.Grupo = filtro == null || filtro == "Grupo" ? null : this.filtrosactivosModelo.Grupo;
    this.filtrosactivosModelo.GrupoMotivo = filtro == null || filtro == "GrupoMotivo" ? null : this.filtrosactivosModelo.GrupoMotivo;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == "General" ? "" : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Nombre != null && filtros.Nombre.trim() != "") || (filtros.Activo != null && filtros.Activo.trim() != "") ||
      filtros.CodigoTipoControl != null || filtros.Grupo != null || filtros.GrupoMotivo != null ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")) return true; else return false;
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }
  ///Validador


  ///Funcionalidades

  cargarDatos() {
    //Cargamos Datos Asincronamente

  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;

    this.servicioAPI.GetTiposEntradaDatos().subscribe(data => {
      this.TiposEntradaDatosAPI = data;
      this.tiposEntradaDatos = [];
      if (this.TiposEntradaDatosAPI.DatosResult != null) {
        if (this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos != null) {
          this.TiposEntradaDatosAPI.DatosResult.ListadoTiposEntradaDatos.forEach(element => {
            this.tiposEntradaDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.GetGruposAsociados().subscribe(data => {
      this.GruposAsociadosAPI = data;
      this.gruposAsociadosDatos = [];
      if (this.GruposAsociadosAPI.DatosResult != null) {
        if (this.GruposAsociadosAPI.DatosResult.ListadoGruposAsociados != null) {
          this.GruposAsociadosAPI.DatosResult.ListadoGruposAsociados.forEach(element => {
            this.gruposAsociadosDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.GetTiposNiveles().subscribe(data => {
      this.TiposNivelesAPI = data;
      this.tiposNivelesDatos = [];
      if (this.TiposNivelesAPI.DatosResult != null) {
        if (this.TiposNivelesAPI.DatosResult.ListadoTiposNiveles != null) {
          this.TiposNivelesAPI.DatosResult.ListadoTiposNiveles.forEach(element => {
            this.tiposNivelesDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.GetTiposProductos().subscribe(data => {
      this.TiposProductosAPI = data;
      this.tiposProductosDatos = [];
      if (this.TiposProductosAPI.DatosResult != null) {
        if (this.TiposProductosAPI.DatosResult.ListadoTiposProductos != null) {
          this.TiposProductosAPI.DatosResult.ListadoTiposProductos.forEach(element => {
            this.tiposProductosDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.ObtenerCustomanTipoVisita().subscribe(data => {
      this.TipoVisitaAPI = data;
      this.tipoVisitaDatos = [];
      if (this.TipoVisitaAPI.DatosResult != null) {
        if (this.TipoVisitaAPI.DatosResult.lista != null) {
          this.TipoVisitaAPI.DatosResult.lista.forEach(element => {
            this.tipoVisitaDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.ObtenerCustomanCategoria().subscribe(data => {
      this.CategoriaAPI = data;
      this.categoriaDatos = [];
      if (this.CategoriaAPI.DatosResult != null) {
        if (this.CategoriaAPI.DatosResult.lista != null) {
          this.CategoriaAPI.DatosResult.lista.forEach(element => {
            this.categoriaDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.ObtenerCustomanCentroCoste().subscribe(data => {
      this.CentroCosteAPI = data;
      this.centroCosteDatos = [];
      if (this.CentroCosteAPI.DatosResult != null) {
        if (this.CentroCosteAPI.DatosResult.Lista != null) {
          this.CentroCosteAPI.DatosResult.Lista.forEach(element => {
            this.centroCosteDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.ObtenerCustomanCanalesSub().subscribe(data => {
      this.CanalesAPI = data;
      this.canalesDatos = [];
      if (this.CanalesAPI.DatosResult != null) {
        if (this.CanalesAPI.DatosResult.Lista != null) {
          this.CanalesAPI.DatosResult.Lista.forEach(element => {
            this.canalesDatos.push(element);
          });
        }
      }
    });

    this.servicioAPI.getCentros().subscribe(data => {
      this.CentrosAPI = data;
      this.tipoCentros = [];
      if (this.CentrosAPI.DatosResult != null) {
        if (this.CentrosAPI.DatosResult.ListaCentros != null) {
          this.tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
          /*this.CentrosAPI.DatosResult.ListaCentros.forEach(element => {
            this.centrosDatos.push(element);
          });*/
        }
      }
    });

    await this.servicioAPI.getTiposControlAsync()
      .then(
        data => {
          this.tiposTiposControlAPI = new TiposControl_Respuesta().deserialize(data);
          if (this.tiposTiposControlAPI.DatosResult != null) {
            this.tiposTiposControl = this.tiposTiposControlAPI.DatosResult.customanTiposControl;
          }
          else {
            this.alertaServicio.error(this.tiposTiposControlAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getGruposTiposEntradaAsync()
      .then(
        data => {
          this.tiposGruposTipoEntradaAPI = new grupos_tipos_entrada_Respuesta().deserialize(data);
          if (this.tiposGruposTipoEntradaAPI.DatosResult != null) {
            this.tiposGrupoTipoEntrada = this.tiposGruposTipoEntradaAPI.DatosResult.customanGrupoTipoEntrada;
            this.tiposGrupoTipoEntradaMotivo = this.tiposGruposTipoEntradaAPI.DatosResult.customanGrupoTipoEntrada.filter(x => x.ConMotivo == "1");
          }
          else {
            //this.alertaServicio.error(this.tiposGruposActividadesAPI.Mensajes[0].DescripcionMensaje);
            this.tiposGrupoTipoEntrada = [];
            this.tiposGrupoTipoEntradaMotivo = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getRecintosAsync()
      .then(
        data => {
          this.tiposRecintosAPI = new Recintos_Respuesta().deserialize(data);
          if (this.tiposRecintosAPI.DatosResult != null) {
            this.tiposRecinto = this.tiposRecintosAPI.DatosResult.ListaRecintos;
            this.createOrderFormFields();
          }
          else {
            this.tiposRecinto = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getTiposButacaAsync()
      .then(
        data => {
          this.tiposTiposButacaAPI = new TipoButaca_Respuesta().deserialize(data);
          if (this.tiposTiposButacaAPI.DatosResult != null) {
            this.tiposTiposButaca = this.tiposTiposButacaAPI.DatosResult.DatosListas;
          }
          else {
            this.tiposTiposButaca = [];
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado

    await this.servicioAPI.getTiposEntradasAsync(null,
      this.filtrosactivosModelo.Nombre,
      null, null, this.filtrosactivosModelo.Grupo,
      this.filtrosactivosModelo.GrupoMotivo,
      this.filtrosactivosModelo.CodigoTipoControl,
      this.filtrosactivosModelo.Activo,
      this.filtrosactivosModelo.textoGeneral)
      .then(
        data => {
          this.tiposTiposEntradaAPI = new TiposEntrada_Respuesta().deserialize(data);
          if (this.tiposTiposEntradaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.tiposTiposEntradaAPI.DatosResult.Lista);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            resultado = true;
          }
          else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );

    return resultado;
  }

  createOrderFormFields() {
    this.tiposRecinto.forEach(r => {
      this.editorRegistroForm.addControl(r.PkId, new FormControl(''));
    });
  }

  async cargarBotonera(): Promise<boolean> {
    let resultado: boolean = false;
    await this.servicioAPI.getBotoneraAsync(null, this.globales.maxBotonesRapidosTPV.toString().trim())
      .then(
        data => {
          this.tiposBotoneraEntradaAPI = new RespuestaBotonera().deserialize(data);
          if (this.tiposBotoneraEntradaAPI.DatosResult != null) {
            this.tiposBotoneraEntrada = this.tiposBotoneraEntradaAPI.DatosResult.Lista;
          }
          else {
            this.alertaServicio.error('Botonera: ' + this.tiposBotoneraEntradaAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }
        },
        error => {
          this.alertaServicio.error('Botonera: ' + error);
          resultado = false;
        }
      );
    return resultado;
  }

  //True = recinto, false = Actividad
  filtrarTiposRecintos(tipo?: boolean): Recintos[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {
      if (tipo) {
        let Recintos = this.tiposRecinto.filter(x => x.Taller != "1");
        let tmpRecintosAsociados = this.recintosasignados;
        if (Recintos != null && Recintos.length > 0) {
          respuesta = Recintos.filter(function (item) {
            return tmpRecintosAsociados.findIndex(function (itemSub) { return itemSub == item.PkId }) === -1;
          });
        }
        else {
          respuesta = this.tiposRecinto;
        }
      } else {
        let Talleres = this.tiposRecinto.filter(x => x.Taller == "1");
        let tmpActividadesAsociadas = this.actividadesasociadas;
        if (Talleres != null && Talleres.length > 0) {
          respuesta = Talleres.filter(function (item) {
            return tmpActividadesAsociadas.findIndex(function (itemSub) { return itemSub == item.PkId }) === -1;
          });
        }
        else {
          respuesta = this.tiposRecinto;
        }
      }
    }
    return respuesta;
  }

  //True = recinto, false = Actividad heree
  daTiposRecintos(tipo?: boolean): Recintos[] {
    let respuesta = [];

    if (tipo != null && this.tiposRecinto != null) {

      if (tipo) {
        respuesta = this.tiposRecinto.filter(x => x.Taller != "1" && this.recintosasignados.findIndex(y => y == x.PkId) >= 0);
      }
      else {
        respuesta = this.tiposRecinto.filter(x => x.Taller == "1" && this.actividadesasociadas.findIndex(y => y == x.PkId) >= 0);
      }
    }
    return respuesta;
  }
  //---Asignacion Recintos
  asignarTipoRecinto(item, evento) {
    this.recintosasignados.push(item.PkId);
    this.ordenRecintosAsociados.push(null);

  }

  desasignarTipoRecinto(item, evento) {
    let arrtemp = [];
    let index = this.recintosasignados.indexOf(item.PkId);
    if (index >= 0) { this.ordenRecintosAsociados.splice(index, 1) };
    arrtemp = this.recintosasignados.filter(function (x) { return x != item.PkId });
    this.recintosasignados = arrtemp;
  }

  deasignarTodosRecintos() {
    this.recintosasignados = [];
    this.ordenRecintosAsociados = [];
  }
  asignarTodosRecintos() {
    // Coger los Ordenes Actuales y ponerlos en el nuevo array
    let numItem: number = 0;
    let tmpElementos: { idRecinto: string, orden: string }[] = [];
    this.ordenRecintosAsociados.forEach(element => {
      if (element != null && element.trim() != "") {
        tmpElementos.push({ idRecinto: this.recintosasignados[numItem], orden: element })
      }
      numItem++
    });
    //cargamos todos
    this.recintosasignados = this.tiposRecinto.filter(x => x.Taller != "1").map(function (x) { return x.PkId });
    this.ordenRecintosAsociados = [];
    //Repasamos para poner los ordenes correctos y lo demas a null
    this.recintosasignados.forEach(element => {
      let tmpIndex = tmpElementos.findIndex(y => y.idRecinto == element);
      if (tmpIndex >= 0) {
        this.ordenRecintosAsociados.push(tmpElementos[tmpIndex].orden);
      } else {
        this.ordenRecintosAsociados.push(null);
      }
    });
  }

  obtenerRecintosAsociadosconOrden(): string {
    let respuesta: string = '';
    let elemento: string;
    let count = 0;
    this.recintosasignados.forEach(recinto => {
      let orden = this.getOrdenRecinto(recinto);
      elemento = recinto + ':' + (orden ? orden : '0') + ',';
      // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
      respuesta = respuesta + elemento;
      count++;
    });
    return respuesta.substring(0, respuesta.length - 1);
  }

  getOrdenRecinto(pkid) {
    let response;
    this.tiposRecinto.forEach(r => {
      if (r.PkId == pkid) {
        response = this.editorRegistroForm.get(r.PkId).value; // aqui
      }
    });
    return response;
  }

  obtenerActividadesAsociadosconOrden(): string {
    let respuesta: string = '';
    let elemento: string;
    let count = 0;
    this.actividadesasociadas.forEach(actividad => {
      let orden = this.getOrdenRecinto(actividad);
      elemento = actividad + ':' + (orden ? orden : '0') + ',';
      // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
      respuesta = respuesta + elemento;
      count++;
    });
    return respuesta.substring(0, respuesta.length - 1);
  }

  onCambiodeOrden(orden, indice): void {
    //alert(''+orden+' -> '+indice);
    this.ordenRecintosAsociados[indice] = orden;
  }

  //---Asignacion Actividades
  asignarTipoActividad(item, evento) {
    this.actividadesasociadas.push(item.PkId);
  }

  desasignarTipoActividad(item, evento) {
    let arrtemp = [];
    arrtemp = this.actividadesasociadas.filter(function (x) { return x != item.PkId });
    this.actividadesasociadas = arrtemp;

  }

  deasignarTodasActividades() {
    this.actividadesasociadas = [];
  }
  asignarTodasActividades() {
    this.actividadesasociadas = this.tiposRecinto.filter(x => x.Taller == "1").map(function (x) { return x.PkId });
  }

  /////////////////
  /// CENTROS here
  /////////////////
  filtrarTiposCentros() {
    // return this.tipoCentros.filter(x => x.Activo == "True");
    let respuesta = [];
    let Centros = this.tipoCentros.filter(x => x.Activo == "True");
    let tmpCentrosAsociados = this.centrosasociados;
    if (Centros != null && Centros.length > 0) {
      respuesta = Centros.filter(function (item) {
        return tmpCentrosAsociados.findIndex(function (itemSub) { return itemSub == item.PkId }) === -1;
      });
    }
    else {
      respuesta = this.tipoCentros.filter(x => x.Activo == "True");
    }
    return respuesta;
  }

  daTiposCentros(): any[] {
    let respuesta = [];
    if (this.tipoCentros != null) {
      respuesta = this.tipoCentros.filter(x => x.Activo == "True" && this.centrosasociados.findIndex(y => y == x.PkId) >= 0);
    }
    // respuesta = this.centrosDatos.filter(x => x.Activo == "True");
    return respuesta;
  }

  asignarTipoCentro(item, evento) {
    this.centrosasociados.push(item.PkId);
  }

  desasignarTipoCentro(item, evento) {
    let arrtemp = [];
    arrtemp = this.centrosasociados.filter(function (x) { return x != item.PkId });
    this.centrosasociados = arrtemp;

  }

  deasignarTodosCentros() {
    this.centrosasociados = [];
  }
  asignarTodosCentros() {
    this.centrosasociados = this.tipoCentros.filter(x => x.Activo == "True").map(function (x) { return x.PkId });
  }

  ////////////////////
  ////////////////////
  ////////////////////
  ////////////////////

  recortav35(texto: string): string {
    if (texto.length > 36) { return texto.substring(0, 36) + '...' } else return texto;
  }
  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): TipoEntrada {
    let respuesta = new TipoEntrada;
    // respuesta.Activo = item.Activo?"True":"False";
    // respuesta.Nombre = item.Nombre;
    // respuesta.subGrupoRecinto = item.subGrupoRecinto?"True":"False";
    // respuesta.PkId = item.PkId;
    // respuesta.GrupoRecintosId = item.GrupoRecintosId;
    // respuesta.ControlarAforo = item.ControlarAforo?"True":"False";
    // respuesta.ControlarAforoReserva = item.ControlarAforoReserva?"True":"False";
    // respuesta.TipoAforo = item.TipoAforo;
    // respuesta.AforoMaximo = item.AforoMaximo;
    // respuesta.LimiteAforo = item.LimiteAforo;
    // respuesta.HoraInicio = item.HoraInicio;
    // respuesta.HoraFin = item.HoraFin;
    // respuesta.PorDefecto = item.PorDefecto?"True":"False";
    // respuesta.MinimoAforo = item.MinimoAforo;
    // respuesta.Taller = item.Taller?"True":"False";
    // respuesta.Butacas = item.Butacas?"True":"False";
    // respuesta.MostrarInternet = item.MostrarInternet?"True":"False";
    // respuesta.MaximoInternet = item.MaximoInternet;
    // respuesta.GrupoActividadId = item.GrupoActividadId;
    // respuesta.CodigoTipoControl = item.CodigoTipoControl;
    // respuesta.Secciones = item.Secciones;
    // respuesta.Shape  = item.Shape;
    // respuesta.Coords = item.Coords;
    // respuesta.OrdenFilas = item.OrdenFilas;
    // respuesta.OrdenButacas = item.OrdenButacas;
    // respuesta.Numeracion = item.Numeracion;
    // respuesta.NumSesion = item.NumSesion?"True":"False";
    // respuesta.TiempoExtra = item.TiempoExtra;

    // respuesta.Idiomas = this.editorListaIdiomas;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI.delTiposEntradas(
          this.model.EntradaId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (data.DatosResult != null && (data.Mensajes == null || data.Mensajes.length == 0)) {
                this.alertaServicio.success("Datos Borrados Correctamente")
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.EntradaId !== this.model.EntradaId);
              }
              else {
                this.alertaServicio.error(data.Mensajes[0].DescripcionMensaje);
              }

            },
            error => { this.alertaServicio.error(error); }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.eleccion_dialogo = result;
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delTiposEntradasAsync(
              element.EntradaId)
              .then(
                data => {
                  //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
                  if (data.DatosResult != null && (data.Mensajes == null || data.Mensajes.length == 0)) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.EntradaId !== element.EntradaId);
                  }
                  else {
                    resultado += data.Mensajes[0].DescripcionMensaje + " <BR />";
                  }
                },
                error => { resultado += error + " <BR />"; }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        }
        else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
        this.cargarDatosAsync();
        this.cargarBotonera();
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  duplicar(row: traspasomodelo) {
    this.model = row;
    if (this.model.EntradaId != null) {
      this.model.EntradaId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        // llamar a borrar entrada  
        this.servicioAPI.delTiposEntradasAsync(pkId).then(res => {
          var entradaToDelete = this.dataSource.data.find(item => item.EntradaId === pkId);
          this.dataSource.data = this.dataSource.data.filter(item => item !== entradaToDelete);
          this.dataSource.filteredData = this.dataSource.filteredData.filter(item => item !== entradaToDelete);
        });
      }
    });
  }

  validacion() {
    const separador = document.getElementsByClassName('separadorLateral');
    const campos = document.getElementsByClassName('editor-campo-registro');
    for (let i = 0; i < campos.length; i++) {
      if (campos[i].classList.contains('ng-valid')) {
        if (campos[i].classList.contains('editor-campo-registro-check')) {
          separador[i].setAttribute('style', 'border-color:#002c76');
        } else {
          separador[i].setAttribute('style', 'border-color:green');
        }
      } else {
        separador[i].setAttribute('style', 'border-color:#002c76');
      }
    }
  }

  mostrar() {
    const recintos = document.getElementById('listaRecintos');
    const boton = document.getElementById('botonMostrarRecinto');
    if (recintos.style.display === 'none') {
      recintos.style.display = 'block';
      boton.style.backgroundImage = 'url(/assets/icon/icono-mas-activo.svg)';
    } else {
      recintos.style.display = 'none';
      boton.style.backgroundImage = 'url(/assets/icon/icono-mas.svg)';
    }
  }

  mostrar2() {
    const recintos = document.getElementById('listaActividades');
    const boton = document.getElementById('botonMostrarActividad');
    if (recintos.style.display === 'none') {
      recintos.style.display = 'block';
      boton.style.backgroundImage = 'url(/assets/icon/icono-mas-activo.svg)';
    } else {
      recintos.style.display = 'none';
      boton.style.backgroundImage = 'url(/assets/icon/icono-mas.svg)';
    }
  }

  subirBajar(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  intToBool(int: string) {
    if (int === "1") {
      return "True";
    } else if (int === "0") {
      return "False";
    } else {
      return "";
    }
  }

}
