import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Categoria } from '@sharedV11/classes/categoria/categoria';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { EditCategoriasService } from '@sharedV11/services/components/Categorias/edit-categorias.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';
import { RecintosVisitaService } from '@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service';
import { TipoEntrada, TiposEntrada_Respuesta } from '@sharedV11/classes/clients/client/tipos-entrada-Respuesta.model';
import { EspectaculosService } from '@sharedV11/services/espectaculos/espectaculos.service';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';

@Component({
  selector: 'app-edit-espectaculos',
  templateUrl: './edit-espectaculos.component.html',
  styleUrls: ['./edit-espectaculos.component.scss']
})
export class EditEspectaculosComponent implements OnInit {


  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  idiomas: Array<IdiomaGR> = [];

  editRegistroForm: FormGroup;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  tiposTiposEntradaAPI: TiposEntrada_Respuesta;

  // Tipo Entrada 
  tiposTipoEntrada: Array<TipoEntrada>;
    //entradas dual-list
    entradasSinAsignar: any = [];
    entradasAsignadas: any = [];

    grSinAsignar: any = [];
    grAsignadas: any = [];


    
  Categoria:Categoria
  constructor(
    private espectaculoService:EspectaculosService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros,
    private conectarApiService: ConectarApiService,
    private recintosVisitaService: RecintosVisitaService
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async ngOnInit() {
   
    this.loadCentros();
    this.initializeFormWithoutData();
    this.initializeTranslate();

    await this.inicializarIdiomas();

    await this.CargarDatosTiposEntradas(); // para el dual-list
    this.CargarDatosGR();

    

    this.manageForm(this.pkId, this.mode)

    // this.pkId="4"

  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();

  }

  async initializeFormWithoutData() {
    this.editRegistroForm = this.fb.group({
      pkId: [""],
      chNombreEspectaculo: ["", Validators.required],
      Activo:[""],
      //chIdioma: [""],
      chPathMapaSVG: ["", Validators.pattern(".*.svg")],
      CIF: [""],
      Promotor: [""]
    });

  }

  async inicializarIdiomas() {
    await this.conectarApiService.getIdiomasAync().then(idioma => {
      idioma.DatosResult.ListaIdiomas.map(idio => {
        this.idiomas.push(idio);
      });
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

    this.inicializarIdiomasFormGroup();
    localStorage.setItem("Idiomas", this.idiomas.values.toString());
  }

  inicializarIdiomasFormGroup() {
    this.idiomas.map(idioma => {
      this.editRegistroForm.addControl("Nombre-" + idioma.CodIdioma, new FormControl(''));
      this.editRegistroForm.addControl("Descripcion-" + idioma.CodIdioma, new FormControl(''));
    });
  }

  get formControls() {
    return this.editRegistroForm.controls;
  }

  manageForm(pkId, mode) {

    if (mode == "I") {
      this.Categoria = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findEspectaculo(pkId)
    }
  }

  findEspectaculo(pkId) {
    this.espectaculoService.findEspectaculoByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(EspectaculoApi => {
      // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
      console.log(EspectaculoApi)
      let Espectaculo = EspectaculoApi["DatosResult"]["espectaculos"][0]

      if (Espectaculo != null) {
        this.changeValueForm(Espectaculo);
        this.insertEntradasAsignadas(Espectaculo.TiposEntradas.split(','));
        this.insertgrAsignadas(JSON.parse(Espectaculo.chGrupoRecintos));
        this.Categoria = this.editRegistroForm.value;
        this.Categoria.CentrosIds = Espectaculo.CentrosIds;
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.Categoria);
        this.valuedChanged();
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }


  cambiaNombre(evento, idioma) {
    this.idiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  
  changeValueForm(Espectaculo:any) {
    this.editRegistroForm.get("pkId").setValue(Espectaculo.pkId);
   
    this.editRegistroForm.get("chNombreEspectaculo").setValue(Espectaculo.chNombreEspectaculo);
    this.editRegistroForm.get("CIF").setValue(Espectaculo.CIF);
    this.editRegistroForm.get("Promotor").setValue(Espectaculo.Promotor);
    this.editRegistroForm.get("Activo").setValue(Espectaculo.bActivo == "1" ? true: false);
    this.editRegistroForm.get("chPathMapaSVG").setValue(Espectaculo.chPathMapaSVG);
    //this.editRegistroForm.get("chIdioma").setValue(Espectaculo.chIdioma);

    if(Espectaculo.chTraducciones){
      this.idiomas.map(idio => {

        this.formControls["Nombre-" + idio.CodIdioma].setValue(Espectaculo.chTraducciones.Nombre.find(val => val.i18n === idio.CodIdioma).msg);
        this.formControls["Descripcion-" + idio.CodIdioma].setValue(Espectaculo.chTraducciones.Descripcion.find(val => val.i18n === idio.CodIdioma).msg);


      });
    }

    //this.editRegistroForm.get("Descripcion").setValue(Categoria.chDescripcion==undefined?Categoria.Descripcion:Categoria.chDescripcion)
    //this.editRegistroForm.get("Orden").setValue(Categoria.Orden)
 
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe(value => {
      if (this.Categoria != null) {
        this.panelService.setDiscardChanges(false)
        console.log(value.Baja)
        if (JSON.stringify(value) === JSON.stringify(this.Categoria)) {
          console.log("same")
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }
  submit() {
    //this.flagInput=!this.flagInput
    /*  console.log(this.editRegistroForm.value)
     console.log(this.editRegistroForm.valid)
     console.log(this.editRegistroForm) */
     this.editRegistroForm.markAllAsTouched()
    let Categoria: Categoria = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(Categoria, this.mode)
    } 

  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true)

    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.Categoria)
  }

  obtenerentradasAsociadosconOrden(centrosAsignados): string {
    let respuesta: string = '';
    let elemento: string;
    let count = 0;
    if (centrosAsignados) {

      centrosAsignados.forEach(centro => {
        //let orden = this.getOrdenRecinto(actividad);
        console.log(centro)
        elemento = centro.pkId + ',';
        // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }

  obtenerGruposRecintosAsociadosconOrden(grAsignados): string {
    let respuesta: string = '';
    let elemento: string;
    let count = 0;
    if (grAsignados) {

      grAsignados.forEach(centro => {
        //let orden = this.getOrdenRecinto(actividad);
        console.log(centro)
        elemento = centro.pkId + ',';
        // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
        respuesta = respuesta + elemento;
        count++;
      });
    }
    return respuesta.substring(0, respuesta.length - 1);
  }

  manageApi(Espectaculo: any, mode) {
    Espectaculo.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    Espectaculo.bActivo = Espectaculo.Activo ==true?"1":"0";
    delete Espectaculo.Activo;

    this.idiomas.map(idioma => {
      idioma.Nombre = this.formControls["Nombre-" + idioma.CodIdioma].value
      idioma.Descripcion = this.formControls["Descripcion-" + idioma.CodIdioma].value
      delete Espectaculo["Nombre-" + idioma.CodIdioma];
      delete Espectaculo["Descripcion-" + idioma.CodIdioma];
    })
    Espectaculo.chTiposEntrada = this.obtenerentradasAsociadosconOrden(this.entradasAsignadas);
    Espectaculo.Idiomas = this.idiomas;
    Espectaculo.chGrupoRecintos = this.obtenerentradasAsociadosconOrden(this.grAsignadas);

    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        Espectaculo.pkId = "0"
        this.InsEspectaculo(Espectaculo)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdEspectaculo(Espectaculo)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        Espectaculo.pkId = "0"
        this.InsEspectaculo(Espectaculo)

        break;

      default:
        break;
    }
  }
  UpdEspectaculo(Espectaculo){
    this.espectaculoService.updEspectaculo(Espectaculo, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });    
   }
   InsEspectaculo(Espectaculo){
    this.espectaculoService.insEspectaculo(Espectaculo, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   
   }

   DelEspectaculo(Espectaculo) {

   }


   nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach(element => {
          this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
        });
      } else {
        this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
      }
    }
  }
  existErrors() {
    return (this.editRegistroForm.get("pkId").errors || this.editRegistroForm.get("chNombreEspectaculo").errors  || this.editRegistroForm.get("chPathMapaSVG").errors) ? true : false;
  }
  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if(fc_pv){
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }
    
    return "Error";
  }


  insertEntradasAsignadas(asignadas) {
    asignadas.map(asignada => {
      let entradaFind = this.entradasSinAsignar.find(find => find.pkId == asignada)
      if (entradaFind) {
        this.entradasAsignadas.push(entradaFind)
      }
    })

  }

  insertgrAsignadas(asignadas) {
    asignadas.map(asignada => {
      let grFind = this.grSinAsignar.find(find => find.pkId == Object.keys(asignada)[0])
      if (grFind) {
        this.grAsignadas.push(grFind)
      }
    })

  }

  async CargarDatosTiposEntradas(tipo?: number, recinto?: string): Promise<boolean> //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
  {
    let resultado: boolean = false;
    await this.recintosVisitaService.getTiposEntradasAsync(null, null, tipo == null || tipo == 0 || recinto == null ? null : recinto, tipo == null || tipo == 0 ? null : (tipo - 1).toString())
      .then(
        data => {
          this.tiposTiposEntradaAPI = new TiposEntrada_Respuesta().deserialize(data);
          if (this.tiposTiposEntradaAPI.DatosResult != null) {
            console.log("---Tipo: ", tipo)
            switch (tipo) {
              case 1:
                //this.tiposTipoEntradaRecinto = this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              case 2:
                //this.tiposTipoEntradaTaller = this.tiposTiposEntradaAPI.DatosResult.Lista;
                break;

              default:
                this.tiposTipoEntrada = this.tiposTiposEntradaAPI.DatosResult.Lista;
                this.entradasSinAsignar = this.tiposTipoEntrada;
                break;
            }


            resultado = true;
          } else {
            switch (tipo) {
              case 1:
                //this.tiposTipoEntradaRecinto = [];
                break;

              case 2:
                //this.tiposTipoEntradaTaller = [];
                break;

              default:
                //this.tiposTipoEntrada = [];
                break;
            }
            resultado = false;
          }

        },
        error => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );

    return resultado;
  }


  async CargarDatosGR(tipo?: number, recinto?: string): Promise<boolean> //tipo 0-null:Todas, 1:Recinto, 2:Taller/actividad
  {
    let resultado: boolean = false;
    this.conectarApiService.getGruposRecintos()
      .subscribe(
        data => {
          this.grSinAsignar = data.DatosResult.ListaGrupoRecintos.filter(x => x.Activo == "1");

        },
        error => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );

    return resultado;
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }
}
