import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiTokenService } from '../../services/api/token/api-token.service';
import { AuthenticationService } from '../../services/authentication/authentication.service';
import { LayoutService } from '../../services/layouts/layout.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})
export class SidemenuComponent implements OnInit {


  menuDevelopment:any =[];
  logueado: boolean;
  Botones;
  Version: string;
  events: string[] = [];
  opened: boolean;
  modelo = [];
  submenuTitulo = '';
  layoutservices: LayoutService;
  constructor(
    private autentificador: AuthenticationService,
    private router: Router,
    private _ruta: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private _layoutservices: LayoutService,
    private tokenService: ApiTokenService
  ) {
    this.layoutservices = _layoutservices;
   }

  ngOnInit(): void {
   
    if(!environment.production){
      this.initializeDataListDevelopment();
    }

   // this.Version = environment.version;
    this.logueado = !this.autentificador.ocultarMenusQueryString(this._ruta);
    if (this.logueado) {
      this.Botones = this.autentificador.getPermisosComoBotones();
      console.log("all routes ",this.Botones);
      if (this.Botones.length === 0) {
        //this.router.navigate(['login']);
      } else {
      }
    }
   
    this.layoutservices.urlclicked.subscribe( u => {
      if (u && u!==undefined) this.clicksubmenu(u)
    });
  }

  initializeDataListDevelopment(){
    this.menuDevelopment =[
      {"name":"Recintos V11","subMenu":[
        {"name":"Tipos de Sesión","route":"/Recintos/TipoSesionV11"},
        {"name":"Grupos Recintos","route":"/Recintos/GruposRecintosV11"},
        {"name":"Recintos Visita","route":"/Recintos/RecintosVisitasV11"},
        {"name":"Recintos Actividad Taller","route":"/Recintos/GruposActividadTallerV11"},
        
      ]},
      {"name":"Espacios","subMenu":[
        {"name":"Espectáculos","route":"/Recintos/Espectaculos"},
        {"name":"Sesiones con Butacas","route":"/Recintos/SesionesConButaca"}

      ]},
      {"name":"Codigos Promocionales","subMenu":[
        {"name":"CodigoPromocional","route":"/CodigosPromocionales/CodigoPromocional"},
        {"name":"TipoCodigoPromocional","route":"/CodigosPromocionales/TipoCodigoPromocional"},

      ]},/*
      {"name":"Clientes V11","subMenu":[
        {"name":"Cliente V11","route":"/Clientes/ClienteV11"},
        {"name":"Client Group V11","route":"/Clientes/GrupoClienteV11"},
        {"name":"Formas de Pago V11","route":"/Clientes/FormasPagoV11"}


      ]},
      {"name":"Maestros V11","subMenu":[
        {"name":"Type access control  V11","route":"/Maestros/TypeAccessControlV11"}

      ]},*/
      {"name":"Maestres V11","subMenu":[
        {"name":"Categorias","route":"/Maestros/Categorias"},
        {"name":"TPVs","route":"/Maestros/Tpvs"},
        {"name":"Festivos","route":"/Maestros/Festivos"},
        {"name":"Formas de Pago V11","route":"/Clientes/FormasPago"},
        {"name":"Tipos CLiente V6","route":"/Clientes/TipoCliente"},
        {"name":"Type access control  V11","route":"/Maestros/TypeAccessControlV11"}

      ]},
    ];
    
  }

  clicksubmenu(ruta) {
    var allRoutes: string[] = [];

    for (var i = 0; i < this.router.config.length; i++) {
      allRoutes.push(this.router.config[i].path);
    }
    for (var i = 0; i < this.router.config[0].children.length; i++) {
      allRoutes.push(this.router.config[0].children[i].path);
      if (this.router.config[0].children[i].children) {
        for (var j = 0; j < this.router.config[0].children[i].children.length; j++) {
          allRoutes.push(this.router.config[0].children[i].path +'/'+ this.router.config[0].children[i].children[j].path);
        }
      }
    }

    if (allRoutes.find(x => '/' + x == ruta)) {
      console.log(ruta)
      this.router.navigate([ruta]);
    } else {
      console.log('ruta introducida: ', ruta);
      console.log('verifica si existe dentro de allRoutes');
      this.openSnackBar('Opción No Disponible','Acepar');
    }
  }

  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2500,
      panelClass: ['blue-snackbar'],
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });
  }
}
