<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
  <app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
  <div class="editor-registro">
    <div class="divForm">
      <form [formGroup]="editRegistroForm" (onSubmit)="submit()">

        <div class="form-group">

           <div class="col-sm-12 col-md-6">
            <app-input-filter *ngIf="tiposCodigosPromocionales && tiposCodigosPromocionales.length > 0  && CodigoPromocional " formControlText="TipoDeCodigo" [disabled]="tiposCodigosPromocionales.length<1"
            [label]="translation.TipoDeEntrada" [placeHolderTranslation]="translation.TipoDeEntrada"
            [list]="tiposCodigosPromocionales" textShow="Nombre" dataInput="pkId" required="true"
            [data]="CodigoPromocional.TipoDeCodigo" (close)="closeInputFilter($event)"></app-input-filter>
          </div> 

          <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Codigo}}</mat-label>
              <input matInput formControlName="Codigo" class="editor-campo-registro">
              <mat-error *ngIf="validateFields('Codigo')">
                {{getErrorMessage("Codigo")}}
              </mat-error>
            </mat-form-field>
          </div>

        </div>

        <div class="form-group ">
          
          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.TipoDePaquete}}</mat-label>
              <mat-select placeholder="{{translation.TipoDePaquete}}" formControlName="TipoDeVenta" >
                <mat-option>
                  --
                </mat-option>
                <mat-option value="0">
                  Todos
                </mat-option>
                <mat-option value="1">
                  Internet/Admin
                </mat-option>
                <mat-option value="2">
                  Taquilla
                </mat-option>
              </mat-select>
              <mat-error *ngIf="validateFields('TipoDeVenta')">
                {{getErrorMessage("TipoDeVenta")}}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.TipoDePaquete}}</mat-label>
              <mat-select placeholder="{{translation.TipoDePaquete}}" formControlName="ClaseDeCodigo" >
                <mat-option>
                  --
                </mat-option>
                <mat-option value="M">
                  multiple
                </mat-option>
                <mat-option value="U">
                  Unico
                </mat-option>
                <mat-option value="P">
                  Producto
                </mat-option>
              </mat-select>
              <mat-error *ngIf="validateFields('ClaseDeCodigo')">
                {{getErrorMessage("ClaseDeCodigo")}}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

          <!--div class="half">
            <app-input-filter *ngIf="user && userTypes && flagInput" formControlText="Tipo"
            [label]="translation.Tipo" [placeHolderTranslation]="translation.Tipo" 
            [list]="userTypes" textShow="Nombre" dataInput="pkId" required="true"
            [data]="user.Tipo" (close)="closeInputFilter($event)"></app-input-filter>
          </div-->

          <div class="form-group ">
            <div class="col-sm-12 col-md-6">
              <mat-form-field  appearance="outline">
                <mat-label>{{translation.FechaDesde}}FechaVisitaInicio</mat-label>
                <input matInput placeholder="{{translation.PeriodoDeCompra}}" formControlName="FechaVisitaInicio"
                  [matDatepicker]="FechaVisitaInicio" >
                <mat-datepicker-toggle matSuffix [for]="FechaVisitaInicio"></mat-datepicker-toggle>
                <mat-datepicker #FechaVisitaInicio></mat-datepicker>
              </mat-form-field>
            </div>
      
            <div class="col-sm-12 col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>{{translation.FechaHasta}}FechaVisitaFin</mat-label>
                <input matInput formControlName="FechaVisitaFin" [matDatepicker]="FechaVisitaFin"
                  placeholder="{{translation.FechaHasta}}">
                <mat-datepicker-toggle matSuffix [for]="FechaVisitaFin"></mat-datepicker-toggle>
                <mat-datepicker #FechaVisitaFin></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <div class="form-group ">
            <div class="col-sm-12 col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>{{translation.FechaDesde}}FechaCompraInicio</mat-label>
                <input matInput placeholder="{{translation.PeriodoDeCompra}}" formControlName="FechaCompraInicio" [matDatepicker]="FechaCompraInicio" >
                <mat-datepicker-toggle matSuffix [for]="FechaCompraInicio"></mat-datepicker-toggle>
                <mat-datepicker #FechaCompraInicio></mat-datepicker>
              </mat-form-field>
            </div>
      
            <div class="col-sm-12 col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>{{translation.FechaHasta}}FechaCompraFin</mat-label>
                <input matInput formControlName="FechaCompraFin" [matDatepicker]="FechaCompraFin"
                  placeholder="{{translation.FechaHasta}}">
                <mat-datepicker-toggle matSuffix [for]="FechaCompraFin"></mat-datepicker-toggle>
                <mat-datepicker #FechaCompraFin></mat-datepicker>
              </mat-form-field>
            </div>
          </div>

          <!--<div class="col-sm-12 col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Cliente}}</mat-label>
            <input type="number" matInput formControlName="Cliente" class="editor-campo-registro">
            <mat-error *ngIf="validateFields('Cliente')">
              {{getErrorMessage("Cliente")}}
            </mat-error>
          </mat-form-field>
        </div>-->

        <div class="matslide">
          <mat-label>Habilitado <mat-slide-toggle matInput name="Baja" formControlName="Habilitado"></mat-slide-toggle>
          </mat-label>
        </div>


        <!-- TABLAS INTERACTIVAS -->
<!--         <app-filter [tableName]="'usuarios'"></app-filter>
        <app-table-interactive [tableName]="'usuarios'"></app-table-interactive> -->
        <app-table-interactive [tableName]="'tbTiposCodigosPromocionales_tiposEntrada'"></app-table-interactive>
        <app-table-interactive [tableName]="'tbTiposCodigosPromocionales_tiposPromociones'"></app-table-interactive>

      </form>
    </div>
  </div>
</div>