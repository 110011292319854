<div class="container">
  <div class="menu-acciones-metodo" id="top" #top>
    <!--button class="btn btn-primary btn-magento-atras" (click)="atras();" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button-->
    <div class="row flex justify-content-between" [hidden]="!editando">
    <div style="margin-left: 15px">  
    <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="isnovalid()"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}
    </button>
    <button class="btn btn-warning btn-magento-gris-claro"  (click)="atras()">
      {{translation.Descartar}}
    </button>
    </div>  
    <button class="btn" (click)="atras()" [matTooltip]="translation.Cerrar">
      <mat-icon style="font-size: 32px;">close</mat-icon>
    </button>

   </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTipoPromocion}}</button>
  </div>
  <alert></alert>  
  <div [hidden]="editando" class="data-grid">
    
    <div class="row flex">
    <!--Filtros Activos -->
    <div *ngIf="!hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos"></div>
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
 

      <mat-chip-list aria-label="Fish selection" class="custom-list-horizontal">
        <mat-chip *ngIf="(filtrosactivos.chCodigo != null && filtrosactivos.chCodigo.trim() !='')"> {{translation.chCodigo}}:
            {{filtrosactivos.chCodigo}}
            &nbsp;<i class="fa fa-times-circle fa-sm"
              (click)="resetearFiltrosActivos('chCodigo')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chNombrePromo != null && filtrosactivos.chNombrePromo.trim() !='')"> {{translation.chNombrePromo}}:
            {{filtrosactivos.chNombrePromo}}
            &nbsp;<i class="fa fa-times-circle fa-sm"
              (click)="resetearFiltrosActivos('chNombrePromo')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chDescripcionNombre != null && filtrosactivos.chDescripcionNombre.trim() !='')"> {{translation.chDescripcionNombre}}:
          {{filtrosactivos.chDescripcionNombre}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chDescripcionNombre')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chTextoPantalla != null && filtrosactivos.chTextoPantalla.trim() !='')"> {{translation.chTextoPantalla}}:
          {{filtrosactivos.chTextoPantalla}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chTextoPantalla')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chTextoImpresion != null && filtrosactivos.chTextoImpresion.trim() !='')"> {{translation.chTextoImpresion}}:
          {{filtrosactivos.chTextoImpresion}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chTextoImpresion')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chTextoDisplay != null && filtrosactivos.chTextoDisplay.trim() !='')"> {{translation.chTextoDisplay}}:
          {{filtrosactivos.chTextoDisplay}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chTextoDisplay')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chTipoValidez != null && filtrosactivos.chTipoValidez.trim() !='')"> {{translation.chTipoValidez}}:
          {{filtrosactivos.chTipoValidez}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chTipoValidez')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iValidez != null && filtrosactivos.iValidez !='')"> {{translation.iValidez}}:
          {{filtrosactivos.iValidez}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iValidez')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iGrupoId != null && filtrosactivos.iGrupoId.trim() !='')"> {{translation.iGrupoId}}:
          {{filtrosactivos.iGrupoId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iGrupoId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iTipoProductoId != null && filtrosactivos.iTipoProductoId.trim() !='')"> {{translation.iTipoProductoId}}:
          {{filtrosactivos.iTipoProductoId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iTipoProductoId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iGrupoAsociadoId != null && filtrosactivos.iGrupoAsociadoId.trim() !='')"> {{translation.iGrupoAsociadoId}}:
          {{filtrosactivos.iGrupoAsociadoId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iGrupoAsociadoId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iTipoVisitaId != null && filtrosactivos.iTipoVisitaId.trim() !='')"> {{translation.iTipoVisitaId}}:
          {{filtrosactivos.iTipoVisitaId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iTipoVisitaId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iTipoNivelId != null && filtrosactivos.iTipoNivelId.trim() !='')"> {{translation.iTipoNivelId}}:
          {{filtrosactivos.iTipoNivelId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iTipoNivelId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iCategoriaId != null && filtrosactivos.iCategoriaId.trim() !='')"> {{translation.iCategoriaId}}:
          {{filtrosactivos.iCategoriaId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iCategoriaId')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.bALaVenta != null && filtrosactivos.bALaVenta.trim() !='')"> {{translation.bALaVenta}}:
          {{filtrosactivos.bALaVenta}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('bALaVenta')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.bImprimirPromo != null && filtrosactivos.bImprimirPromo.trim() !='')"> {{translation.bImprimirPromo}}:
          {{filtrosactivos.bImprimirPromo}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('bImprimirPromo')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.bImprimirPrecio != null && filtrosactivos.bImprimirPrecio.trim() !='')"> {{translation.bImprimirPrecio}}:
          {{filtrosactivos.bImprimirPrecio}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('bImprimirPrecio')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.chTeclaRapida != null && filtrosactivos.chTeclaRapida.trim() !='')"> {{translation.chTeclaRapida}}:
          {{filtrosactivos.chTeclaRapida}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('chTeclaRapida')"></i>
        </mat-chip>
        <mat-chip *ngIf="(filtrosactivos.iTEDatosId != null && filtrosactivos.iTEDatosId.trim() !='')"> {{translation.iTEDatosId}}:
          {{filtrosactivos.iTEDatosId}}
          &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('iTEDatosId')"></i>
        </mat-chip>
      </mat-chip-list>
    </div>
    <!--Botonera-->
   
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" 
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>

    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.chCodigo)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chCodigo}}</mat-label>
              <input matInput placeholder="{{translation.chCodigo}}" formControlName="chCodigo"
                [value]="filtrosactivosModelo.chCodigo">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.chNombrePromo)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chNombrePromo}}</mat-label>
              <input matInput placeholder="{{translation.chNombrePromo}}" formControlName="chNombrePromo"
                [value]="filtrosactivosModelo.chNombrePromo">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.chDescripcionNombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chDescripcionNombre}}</mat-label>
              <input matInput placeholder="{{translation.chDescripcionNombre}}" formControlName="chDescripcionNombre"
                [value]="filtrosactivosModelo.chDescripcionNombre">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.chTextoPantalla)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chTextoPantalla}}</mat-label>
              <input matInput placeholder="{{translation.chTextoPantalla}}" formControlName="chTextoPantalla"
                [value]="filtrosactivosModelo.chTextoPantalla">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.chTextoImpresion)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chTextoImpresion}}</mat-label>
              <input matInput placeholder="{{translation.chTextoImpresion}}" formControlName="chTextoImpresion"
                [value]="filtrosactivosModelo.chTextoImpresion">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.chTextoDisplay)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.chTextoDisplay}}</mat-label>
              <input matInput placeholder="{{translation.chTextoDisplay}}" formControlName="chTextoDisplay"
                [value]="filtrosactivosModelo.chTextoDisplay">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.chTipoValidez)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.chTipoValidez}}" (selectionChange)="iValidezaCero($event,editorRegistroForm)" formControlName="chTipoValidez"
                name="chTipoValidez" [value]="filtrosactivosModelo.chTipoValidez">
                <mat-option value="D">{{translation.Dias}}</mat-option>
                <mat-option value="M">{{translation.Meses}}</mat-option>
                <mat-option value="S">{{translation.SinCaducidad}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <!-- TODO: Modificar los campos para adaptarlos a los filtros (tmb en edicion) -->

          <div *ngIf="filtersToDisplay.includes(translation.iValidez)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.iValidez}}</mat-label>
              <input matInput type="number" placeholder="{{translation.iValidez}}" formControlName="iValidez"
                [value]="filtrosactivosModelo.iValidez">
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.iGrupoId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iGrupoId}}" formControlName="iGrupoId" name="iGrupoId"
                [value]="filtrosactivosModelo.iGrupoId">
                <mat-option *ngFor="let element of gruposTipoBono" [value]="element.PkId">
                  {{element.NombreGrupo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.iTipoProductoId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iTipoProductoId}}" formControlName="iTipoProductoId"
                name="iTipoProductoId" [value]="filtrosactivosModelo.iTipoProductoId">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let element of tipoProducto" [value]="element.pkId">
                  {{element.Descripcion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.iGrupoAsociadoId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iGrupoAsociadoId}}" formControlName="iGrupoAsociadoId"
                name="iGrupoAsociadoId" [value]="filtrosactivosModelo.iGrupoAsociadoId">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let element of gruposAsociados" [value]="element.pkId">
                  {{element.Descripcion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.iTipoVisitaId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iTipoVisitaId}}" formControlName="iTipoVisitaId"
                name="iTipoVisitaId" [value]="filtrosactivosModelo.iTipoVisitaId">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let element of tipoVisita" [value]="element.pkId">
                  {{element.Descripcion}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.iTipoNivelId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iTipoNivelId}}" formControlName="iTipoNivelId" name="iTipoNivelId"
                [value]="filtrosactivosModelo.iTipoNivelId">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let element of tiposNiveles" [value]="element.pkId">
                  {{element.Codigo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <div *ngIf="filtersToDisplay.includes(translation.iCategoriaId)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.iCategoriaId}}" formControlName="iCategoriaId" name="iCategoriaId"
                [value]="filtrosactivosModelo.iCategoriaId">
                <mat-option> --- --- </mat-option>
                <mat-option *ngFor="let element of categoria" [value]="element.pkId">
                  {{element.chNombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="filtersToDisplay.includes(translation.bALaVenta)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.bALaVenta}}" formControlName="bALaVenta" name="bALaVenta"
                [value]="filtrosactivosModelo.bALaVenta">
                <mat-option> --- --- </mat-option>
                <mat-option value="1">{{translation.Si}}</mat-option>
                <mat-option value="0">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->
    <div class="mat-elevation-z8 overflow-table">
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" class="magento-paginator" [showFirstLastButtons]="true">
      </mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">

              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)=duplicar(row) style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.pkId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>

            </div>
          </td>
        </ng-container>

        <!-- chNombrePromo Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.chNombrePromo}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chNombrePromo}} </td>
        </ng-container>

        <!-- chDescripcionNombre Column -->
        <ng-container matColumnDef="Descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.chDescripcionNombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chDescripcionNombre}} </td>
        </ng-container>

        <!-- chTextoPantalla Column -->
        <ng-container matColumnDef="TextoPantalla">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.chTextoPantalla}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chTextoPantalla}} </td>
        </ng-container>

        <!-- chTextoImpresion Column -->
        <ng-container matColumnDef="TextoImpresion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.chTextoImpresion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chTextoImpresion}} </td>
        </ng-container>

        <!-- chTextoDisplay Column -->
        <ng-container matColumnDef="TextoDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.chTextoDisplay}} </th>
          <td mat-cell *matCellDef="let row"> {{row.chTextoDisplay}} </td>
        </ng-container>

        <!-- iGrupoId Column -->
        <ng-container matColumnDef="Grupo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.iGrupoId}} </th>
          <td mat-cell *matCellDef="let row"> {{getNombreGrupoById(row.iGrupoId)}} </td>
        </ng-container>

        <!-- bALaVenta Column -->
        <ng-container matColumnDef="ALaVenta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.bALaVenta}} </th>
          <td mat-cell *matCellDef="let row"> {{aLaVentaIntToBool(row.bALaVenta)}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>

  <div [hidden]="!editando" class="editor-registro">
    <span class="bajar" (click)='subirBajar(fin)'><img src="/assets/icon/desplegable.svg" alt=""></span>
    <form [formGroup]="editorRegistroForm" (click)="validacion()">

      <!-- form promocion -->


      <mat-form-field appearance="outline">
        <mat-label>{{translation.chCodigo}}</mat-label>
        <input matInput required formControlName="chCodigo"
        class="editor-campo-registro" [(ngModel)]="model.chCodigo" [ngClass]="{ 'is-invalid': f.chCodigo.errors }" >
        <mat-error *ngIf="f.chCodigo.errors?.required">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chNombrePromo}}</mat-label>
        <input matInput formControlName="chNombrePromo"
        class="editor-campo-registro" [(ngModel)]="model.chNombrePromo">
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chDescripcionNombre}}</mat-label>
        <input matInput formControlName="chDescripcionNombre"
        class="editor-campo-registro" [(ngModel)]="model.chDescripcionNombre">
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chTextoPantalla}}</mat-label>
        <input matInput formControlName="chTextoPantalla"
        class="editor-campo-registro" [(ngModel)]="model.chTextoPantalla">
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chTextoImpresion}}</mat-label>
        <input matInput formControlName="chTextoImpresion"
        class="editor-campo-registro" [(ngModel)]="model.chTextoImpresion">
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chTextoDisplay}}</mat-label>
        <input matInput formControlName="chTextoDisplay"
        class="editor-campo-registro" [(ngModel)]="model.chTextoDisplay">
      </mat-form-field> 

      <mat-form-field appearance="outline">
        <mat-label>{{translation.chTipoValidez}}</mat-label>
        <mat-select required placeholder="{{translation.chTipoValidez}}" (selectionChange)="iValidezaCero($event,editorRegistroForm)" formControlName="chTipoValidez"
        name="chTipoValidez" [value]="filtrosactivosModelo.chTipoValidez" [(ngModel)]="model.chTipoValidez" >
        <mat-option value="D">{{translation.Dias}}</mat-option>
        <mat-option value="M">{{translation.Meses}}</mat-option>
        <mat-option value="S">{{translation.SinCaducidad}}</mat-option>
      </mat-select>
      <mat-error *ngIf="f.chTipoValidez.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field> 

      <mat-form-field appearance="outline" *ngIf="f.chTipoValidez.value!='S'">
        <mat-label>{{translation.iValidez}}</mat-label>
        <input matInput type="number" formControlName="iValidez"
        class="editor-campo-registro" [(ngModel)]="model.iValidez" [ngClass]="{ 'is-invalid': f.iValidez.errors }" >
        <mat-error *ngIf="f.iValidez.errors?.required">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field> 


      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iGrupoId}}</mat-label>
          <mat-select required disableRipple formControlName="iGrupoId" name="iGrupoId"
          [(ngModel)]="model.iGrupoId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of gruposTipoBono" [value]="element.PkId">
            {{element.NombreGrupo}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="f.iGrupoId.hasError('required')">{{translation.CampoRequerido}}</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iTipoProductoId}}</mat-label>
          <mat-select disableRipple formControlName="iTipoProductoId" name="iTipoProductoId"
          [(ngModel)]="model.iTipoProductoId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of tipoProducto" [value]="element.pkId">
            {{element.Descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iGrupoAsociadoId}}</mat-label>
          <mat-select disableRipple formControlName="iGrupoAsociadoId" name="iGrupoAsociadoId"
          [(ngModel)]="model.iGrupoAsociadoId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of gruposAsociados" [value]="element.pkId">
            {{element.Descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iTipoVisitaId}}</mat-label>
          <mat-select disableRipple formControlName="iTipoVisitaId" name="iTipoVisitaId"
          [(ngModel)]="model.iTipoVisitaId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of tipoVisita" [value]="element.pkId">
            {{element.Descripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iTipoNivelId}}</mat-label>
          <mat-select disableRipple formControlName="iTipoNivelId" name="iTipoNivelId"
          [(ngModel)]="model.iTipoNivelId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of tiposNiveles" [value]="element.pkId">
            {{element.Codigo}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iCategoriaId}}</mat-label>
          <mat-select disableRipple formControlName="iCategoriaId" name="iCategoriaId"
          [(ngModel)]="model.iCategoriaId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of categoria" [value]="element.pkId">
            {{element.chNombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>{{translation.selecciona}} {{translation.iTEDatosId}}</mat-label>
          <mat-select disableRipple formControlName="iTEDatosId" name="iTEDatosId"
          [(ngModel)]="model.iTEDatosId">
          <mat-option>--</mat-option>
          <mat-option *ngFor="let element of tiposEntradaDatos" [value]="element.TEDatosId">
            {{element.Nombre}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div class='grupo-editor-campo-registro'>
      <mat-slide-toggle color="primary" formControlName="bALaVenta">{{translation.bALaVenta}}</mat-slide-toggle>
      </div>
      <div class='grupo-editor-campo-registro'>
      <mat-slide-toggle color="primary" formControlName="bImprimirPromo">{{translation.bImprimirPromo}}</mat-slide-toggle>
      </div>
      <div class='grupo-editor-campo-registro'>
      <mat-slide-toggle color="primary" formControlName="bImprimirPrecio">{{translation.bImprimirPrecio}}</mat-slide-toggle>
      </div>

      <div class="mat-elevation-z8 overflow-table" [ngClass]="{ 'is-invalid': !tablavalida }">
        <div class="tablebutton" >
        <button class="buttonadd" [matTooltip]="translation.AnadirEntrada" (click)="anadirEntrada()"
        [hidden]="!editando">
        <mat-icon class="tableadd">add</mat-icon>
        </button>
        </div>
        <table mat-table [dataSource]="dataSourceTarifaPromocionEntrada" matSort #entradastable formArrayName="promocionEntradaFormGroupArray">
          <!-- Acciones Column -->
          <ng-container matColumnDef="Acciones">
            <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
            <td mat-cell *matCellDef="let row; let i = index" (click)="$event.stopPropagation()" style="cursor: default;" [formGroupName]="i">
              <div class="action-container">

                <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Entrada" class="button-icon"
                  (click)="borrarPromocionEntrada(i)" style="width: 34px; height: 34px;">
                  <mat-icon>delete_forever</mat-icon>
                </button>
                <input type="hidden" formControlName="iTipoBonoEntradasId"> 
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="Grupo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.iGrupoId}} </th>
            <td mat-cell *matCellDef="let row"> {{getNombreGrupoById(row.iGrupoId)}} </td>
          </ng-container>

          <!-- Campos Reales -->
          <ng-container matColumnDef="NombreTipoEntrada">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.NombreTipoEntrada}}</th>
            <td mat-cell *matCellDef="let row; let i = index" [formGroupName]="i">
              <mat-select formControlName="iTipoEntradaId"  style="width: 100%">
                <mat-option *ngFor="let element of tiposEntrada" [value]="element.EntradaId">
                  {{element.Nombre}}
                </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="iNumEntradas">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.iNumEntradas}}</th>
            <td mat-cell *matCellDef="let row; let i = index"  [formGroupName]="i">
              <input mat-input type="number" style="text-align:right;width: 80px" formControlName="iNumEntradas" value="{{row.iNumEntradas}}">
            </td>
          </ng-container>

          <ng-container matColumnDef="rPorcentaje">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.rPorcentaje}}</th>
            <td mat-cell *matCellDef="let row; let i = index"  [formGroupName]="i">
              <input type="text" style="text-align:right;width: 80px" formControlName="rPorcentaje" value="{{row.rPorcentaje}}">
            </td>
          </ng-container>

          <ng-container matColumnDef="dtFechaInicio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.dtFechaInicio}}</th>
            <td mat-cell *matCellDef="let row; let i = index"  [formGroupName]="i">
              <input type="date" formControlName="dtFechaInicio" style="width:130px">
            </td>
          </ng-container>

          <ng-container matColumnDef="dtFechaFin">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.dtFechaFin}}</th>
            <td mat-cell *matCellDef="let row; let i = index"  [formGroupName]="i">
              <input type="date" formControlName="dtFechaFin" style="width:130px">
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplayTarifaEntrada"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplayTarifaEntrada;">
          </tr>
        </table>

        <!---->
      </div>

    </form>
    <span class="subir" id="bottom" (click)='subirBajar(top)'><img src="/assets/icon/desplegable.svg" alt=""></span>
     <div>  
      <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="isnovalid()"
        [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}
      </button>
      <button class="btn btn-warning btn-magento-gris-claro"  (click)="atras()">
        {{translation.Descartar}}
      </button>
      </div>  
  </div>
  <span id="fin" #fin></span>
</div>