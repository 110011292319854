<app-buttons  (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>


<app-filter [tableName]="'gruposinternet'" ></app-filter>

<app-table [tableName]="'gruposinternet'"  (addbut)="addbut=$event"></app-table>

<app-panelcustoman 
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 