import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import * as moment from 'moment'; //VO
import moment from 'moment';
import { BehaviorSubject, observable, Observable, of } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
//import { EspectaculosService } from 'src/app/business/espectaculos-butacas/espectaculos.service';
//import { ProductosService } from 'src/app/business/productos.service';
//import { VentaService } from 'src/app/core/services/venta.service';
import { TranslateService } from 'src/app/shared/translate.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })
  export class ButacasService {
    estadosButacas = [];
    showDays: [] = [];
    private espectaculoSubject: BehaviorSubject<any>;
    public espectaculo$: Observable<any>;
    private fechaSeleccionadaSubject: BehaviorSubject<any>;
    public fechaSeleccionada$: Observable<any>;
    private horaSeleccionadaSubject: BehaviorSubject<any>;
    public horaSeleccionada$: Observable<any>;
  

    constructor(
        private http: HttpClient,
        private translate: TranslateService,
        //private productosService: ProductosService,
        //private espectaculosService: EspectaculosService,
 
      ) {
        this.espectaculoSubject = new BehaviorSubject(undefined);
        this.espectaculo$ = this.espectaculoSubject.asObservable();

        this.fechaSeleccionadaSubject = new BehaviorSubject(moment());
        this.fechaSeleccionada$ = this.fechaSeleccionadaSubject.asObservable().pipe(distinctUntilChanged()).pipe(shareReplay(1));
    
        this.horaSeleccionadaSubject = new BehaviorSubject(undefined);
        this.horaSeleccionada$ = this.horaSeleccionadaSubject.asObservable().pipe(distinctUntilChanged()).pipe(shareReplay(1));
    
      } 

  public setEspectaculoValue(espectaculo) {
    console.log(espectaculo);
    this.espectaculoSubject.next(espectaculo); 
  }

    public get espectaculoValue() {
        return this.espectaculoSubject.value;
    }

    public get fechaSeleccionadaValue() {
        return this.fechaSeleccionadaSubject.value;
      }
    
      setFechaSeleccionadaValue(fecha = moment()) {
        this.fechaSeleccionadaSubject.next(fecha);
      }

      public get horaSeleccionadaValue() {
        return this.horaSeleccionadaSubject.value;
      }

      setHoraSeleccionadaValue(fecha = moment()) {
        this.horaSeleccionadaSubject.next(fecha);
      }

  //estaba en butacas en token-manager.service.ts
  getEstadosButacas(lang?) {
    const body = {
      Idioma: lang || this.getLocale(),
      Aplicacion: this.getPlataforma()
    };
  
    this.http
      .post(`${this.getConfig('API_URL')}EstadosButacas`, body)
      .subscribe((data: any) => {
        this.estadosButacas = data.DatosResult.Estados;
      });
    }
   
    //Obtener los datos del idioma de la aplicación
    getLocale(){
        return this.translate.getLocale()
    }

    //Obtener la plataforma que usamos en la aplicacion
    getPlataforma(){
        //return this.productosService.getPlataforma()
        return null;
    }

    //Obtenemos las variables de entorno
    getConfig(key: any){
        return environment[key];
    }
   
    getAPIUrl() {
      return this.getConfig("API_URL");
    }


    //añadimos la butaca al carrito
    addProduct(butaca){
        //this.espectaculosService.addProduct(butaca);
    }

    //obtenemos la instancia del servicio para las traducciones
    getTranslate(){
        return this.translate;
    }

    //obtenemos el enlace menu del espectaculo
    getEnlaceMenuId()
    {     
        return this.espectaculoValue.EnlaceMenuId;
        //return this.productosService.getEnlaceMenuId()?this.productosService.getEnlaceMenuId().pkid: null;
    }

    //obtenemos el grupo internet del espectaculo
    getGrupoInternet()
    {
        return this.espectaculoValue.GrupoInternetId;
       //return this.productosService.datosInitValue?JSON.parse(this.productosService.datosInitValue).gi:null ;
    }

    traerReserva(numeroPedido, localizador, tipo){
        return of({});
    }

    getSessionID () {
      //return this.configurationService.configurationValue.currentSession.sessionId;
      return 1;
    }

  }