import { Component, OnInit, HostBinding, ViewChild, LOCALE_ID, NgModule, ChangeDetectorRef } from '@angular/core';
import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from "@angular/animations";

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { first } from 'rxjs/operators';
import { MatDatepicker } from '@angular/material/datepicker';
import { merge, Observable, of as observableOf } from 'rxjs'
import { catchError, map, startWith, switchMap } from 'rxjs/operators';

import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { Idiomas_Respuesta, Idiomas } from '../../_modelos/Idiomas-Respuesta.model';
import { TiposEntrada_Respuesta, TipoEntrada } from '../../_modelos/tipos-entrada-Respuesta.model';
import { TipoPromocion_Respuesta, TipoPromocion, TipoPromocionEntrada } from '../../_modelos/promocion-Respuesta.model';
import { TipoAbonado_Respuesta, TipoAbonado } from '../../_modelos/tipos-abonado-Respuesta.model';

import { TipoIVA_Respuesta, TipoIVA } from '../../_modelos/tipos-iva-Respuesta.model';
import { CalendarioTipoIVA_Respuesta, CalendarioTipoIVA } from '../../_modelos/calendarios-tipos-iva-Respuesta.model';
import { divisas_Respuesta, Divisa_R } from '../../_modelos/divisas-Respuesta.model';
import { GruposInternet, GruposInternet_Respuesta } from '../../_modelos/grupos-internet-Respuesta.model';
import { GruposRecintos_Respuesta, GruposRecintos, IdiomaGR } from '../../_modelos/grupos-recintos-Respuesta.model';
import { Tarifas_Respuesta, Tarifas, tarifaPromocion } from '../../_modelos/tarifas-Respuesta.model';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { ConectarAPIService } from '../../_API/conectar-api.service';
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from '../../shared/_alerta/alerta.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Globales } from '@clasesV6/globales';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_FORMATOS_FECHA } from '../../_otrosproveedores/date-format.adapter';
import { peticion_Insercion_Upd_Tarifas, peticion_Tarifas_Lista } from '@modelosV6/peticion-tarifas.model';
import { AbstractControl } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import localeEs from '@angular/common/locales/es';
import { MatPaginatorIntlEses } from '../../_otrosproveedores/ES-es.paginator';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import moment from 'moment';

export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class ColumnaIdiomas {
  textoHeader: string;
  CodIdioma: string;
}

export class traspasomodelo {
  PkId: string;

  Internet: boolean;
  Taquilla: boolean;
  TaqAutomatica: boolean;
  AlaVenta: boolean;
  iTipoEntradaId: string;
  iTipoPromocionId: string;
  FecIniVisita: string;
  FecFinVisita: string;
  NoFecFinVisita: string;
  PrecioPVP: number;
  PorcIva: string;
  Comision: number;
  PorIvaComision: string;
  iDivisaId: string;
  iTipoAbonadoId: string;
  Agrupar: boolean;
  Orden: string;
  Minimo: string;
  Maximo: string;
  iGrupoInternetId: string;
  FecCompraDesde: string;
  FecCompraHasta: string;
  HoraIni: string;
  HoraFin: string;
  iTipoIvaId: string;
  iTipoIvaComisionId: string;
  BaseImponible: any;
  ComisionBI: any;
  DesglosarComision: boolean;
  Lunes: boolean;
  Martes: boolean;
  Miercoles: boolean;
  Jueves: boolean;
  Viernes: boolean;
  Sabado: boolean;
  Domingo: boolean;
  Idiomas: IdiomaGR[];
  TarifaPromocion: tarifaPromocion[];
  chPrefijo: string; //Prefijo Especial

  TipoProductoAsociado: string;
  AsumeIncrementoIVA: boolean;



  deserialize: null
  tipoControlDias: any;
}

export class filtrosActivos {
  desdeC: string;
  hastaC: string;
  desdeV: string;
  hastaV: string;
  Taquilla: string;
  Internet: string;
  Automatica: string;
  AlaVenta: string;
  TEntradaId: string;
  textoGeneral: string;
}


//registerLocaleData(localeEs, 'es');
/* @NgModule({
  providers: [DialogoPopUp, Translator,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
}) */

@Component({
  selector: 'app-tarifas',
  templateUrl: './tarifas.component.html',
  styleUrls: ['./tarifas.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("1000ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      )
    ])
  ]
})



export class TarifasComponent implements OnInit {
  displayedColumns: string[] = ['Acciones', 'Taquilla', 'Internet', 'Automatica',
    'Entrada', 'alaVenta', 'Semana', 'FecIniVisita', 'FecFinVisita', 'FecCompraDesde', 'FecCompraHasta',
    'PrecioPVP'
  ].filter(x => (x != (this.globales.plataformasVenta.findIndex(y => y.id == '1') >= 0 ? '' : 'Internet')) &&
    x != (this.globales.plataformasVenta.findIndex(y => y.id == '2') >= 0 ? '' : 'Taquilla') &&
    x != (this.globales.plataformasVenta.findIndex(y => y.id == '3') >= 0 ? '' : 'Automatica')); translation: any;
  formControlListaFiltros: FormControl;
  panelSelectorFiltrosvisible: boolean = false;
  edicionPkId: string;
  dataSourceTarifaPromocion: MatTableDataSource<unknown>;
  columnsToDisplayTarifaPromocion: ['NomTipoEntrada', 'iTipoIvaId', 'pvp', 'BaseImponible', 'TipoIvaComision', 'PvpComision', 'BaseImponibleComision'];

  columnsToDisplay: string[] = this.displayedColumns.slice();

  displayedFilters: string[] = ['FechaComnpra', 'FechaVisita', 'Taquilla', 'Internet', 'TaquillaAutomatica', 'ALaVenta', 'TipoEntrada'];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  dataSource: MatTableDataSource<Tarifas>;
  seleccion = new SelectionModel<Tarifas>(true, []);

  @ViewChild('paginatorPrincipal') paginator: MatPaginator;
  @ViewChild('sortPrincipal') sort: MatSort;

  usuarios;
  respuestaAPI: Tarifas_Respuesta;
  editando: boolean = false;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  formControlListaColumnas: FormControl;
  formitemsPromociones: FormGroup;
  itemsPromociones: FormArray = this.formBuilder.array([]);
  editorListaIdiomas: Array<IdiomaGR>;
  //--
  tiposIdiomasAPI: Idiomas_Respuesta;
  tiposIdiomas: Array<Idiomas> = [];
  tipoControl = new FormControl('', [Validators.required]);
  tipoControlIdiomas = new FormControl('', [Validators.required]);
  //---
  tiposEntradasAPI: TiposEntrada_Respuesta;
  tiposEntradas: Array<TipoEntrada> = [];
  tipoControlEntradas = new FormControl('', [Validators.required]);
  //---
  tipoControlPromocion = new FormControl('', [Validators.required]);
  //---
  tiposAbonadosAPI: TipoAbonado_Respuesta;
  tiposAbonados: Array<TipoAbonado> = [];
  tipoControlAbonados = new FormControl('', [Validators.required]);
  //---
  tiposGInternetAPI: GruposInternet_Respuesta;
  tiposGInternet: Array<GruposInternet> = [];
  tipoGrupoInternet = new FormControl('', [Validators.required]);
  //---
  tiposDivisasAPI: divisas_Respuesta;
  tiposDivisas: Array<Divisa_R> = [];
  tipoControlDivisas = new FormControl('', [Validators.required]);
  //---
  tiposIAVAAPI: TipoIVA_Respuesta;
  tiposIVA: Array<TipoIVA> = [];
  tiposIVACalendario: any[] = [];
  tipoControlTIVA = new FormControl('', [Validators.required]);
  tipoControlTIVAComision = new FormControl('', [Validators.required]);
  //---
  CalendarioTiposIAVAAPI: CalendarioTipoIVA_Respuesta;
  calendarioTiposIVA: Array<CalendarioTipoIVA> = [];
  //---
  columnasIdiomas: Array<ColumnaIdiomas>;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  arr_dias_semana_seleccionadas: Array<any> = [];
  arr_plataformas_venta_seleccionadas: Array<any> = [];
  fecha_minDesde = new Date();
  fecha_minHasta = new Date();
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  fechaFV_minDesde = new Date();
  fechaFV_minHasta = new Date();
  fechaFV_maxDesde: Date;
  fechaFV_maxHasta: Date;
  fechaPC_minHasta = new Date();
  fechaPC_maxHasta: Date;
  //--Para controlar la lectura de la API
  resultsLength = 0;
  isLoadingResults = true;
  haycambiosIVA = 0;
  errorFechaVenta = false;
  errorFechaCompra = false;
  TarifaPromocion: any;
  appConfigOtros: any;
  TieneInternet: string;
  TieneTaquillaAutomatica: string;
  TieneAdmon: string;
  tipoProductoEntrada: boolean;
  tipoProductoPaquete: boolean;
  tipoProductoAbonado: boolean;
  PVKSelected: boolean;
  IntSelected: boolean;
  TaqAutSelected: boolean;
  AdminSelected: boolean;
  platSelected: any;
  ProductosEntrada: any;
  ProductosEntradaStatic: any;
  ProductosPaquete: any;
  ProductosAbono: any;
  InsertOrUpdate: any;
  editandoTarifa: boolean;
  //
  // divisas
  NumeroDecimales: any;
  PosicionDivisa: any;
  SimboloDecimal: any;
  SimboloDerecha: any;
  simbolo: any;

  // tabla
  columnasTabla;
  columnasVanila;
  expandedElement: any | null;
  closeExpanded() {
    this.expandedElement = null;
  }

  // autocompletar
  filteredEntradas: Observable<any>;
  filteredProductos: Observable<any>;
  filteredInternet: Observable<any>;

  // disable internet y administracion
  disableAdministracion = false;
  disableInternet = false;

  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private cdRef:ChangeDetectorRef,
    private translator: Translator) {

  }

  @HostBinding('class.col') true;

  x() {
    
  }

  ngAfterViewInit(){
    //this.initializeForms()
    this.cdRef.detectChanges();

  }
  async ngOnInit() {
    this.translation = this.translator.GetTranslations();

    this.columnasTabla = ['Nombre Entrada', 'Porcentaje', 'Cantidad', 'Tipo IVA', 'PVP', 'Base imponible', 'Tipo IVA comisión', 'PVP comisión', 'Base imponible comisión', ' '];
    this.columnasVanila = ['nombreEntrada', 'porcentaje', 'cantidad', 'tipoIva', 'pvp', 'baseImponible', 'tipoIvaComision', 'pvpComision', 'baseImponibleComision', ' '];

    // divisas from localstorage
    this.NumeroDecimales = localStorage.getItem('NumeroDecimales');
    this.PosicionDivisa = localStorage.getItem('PosicionDivisa');
    this.SimboloDecimal = localStorage.getItem('SimboloDecimal');
    this.SimboloDerecha = localStorage.getItem('SimboloDerecha');
    this.simbolo = localStorage.getItem('simbolo');

    this.servicioAPI.GetTipoEntrada().subscribe(res => {
      this.tipoProductoEntrada = false;
      if (res.DatosResult !== null) {
        
        this.ProductosEntrada = res.DatosResult.Lista;
        this.ProductosEntradaStatic = res.DatosResult.Lista;
        res.DatosResult.Lista.forEach(element => {
          if (element.ALaVenta === 'True' && this.tipoProductoEntrada === false) {
            this.tipoProductoEntrada = true;
          }
        });
      }
    });

    this.servicioAPI.getTiposBonoAsync().then(res => {
      this.tipoProductoPaquete = false;
      if (res.DatosResult !== null) {
        this.ProductosPaquete = res.DatosResult.lista;
        res.DatosResult.lista.forEach(element => {
          if (element.bALaVenta === '1' && this.tipoProductoPaquete === false) {
            this.tipoProductoPaquete = true;
          }
        });
      }
    });

    this.servicioAPI.getTiposAbonado().subscribe(res => {
      this.tipoProductoAbonado = false;
      
      if (res.DatosResult !== null) {
        this.ProductosAbono = res.DatosResult.lista;
        res.DatosResult.lista.forEach(element => {
          if (element.bALaVenta === '1' && this.tipoProductoAbonado === false) {
            this.tipoProductoAbonado = true;
          }
        });
      }

    })

    this.TieneInternet = localStorage.getItem('TieneInternet');
    this.TieneTaquillaAutomatica = localStorage.getItem('TieneTaquillaAutomatica');
    this.TieneAdmon = localStorage.getItem('TieneAdmon');

    


    this.displayedFilters = [this.translation.FechaCompra, this.translation.FechaVisita, this.translation.Taquilla, this.translation.Internet, this.translation.TaquillaAutomatica, this.translation.ALaVenta, this.translation.TipoDeEntrada];
    this.filtersToDisplay = this.displayedFilters.slice();

    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(

      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + "/";
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        //
        if (!this.permisoFormulario) { this.router.navigate(['/portada']) };
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: "borrar", texto: this.translation.Borrar, funcion: "BorrarSeleccionados" });
        }
      }
    );


    this.editorRegistroForm = this.formBuilder.group({
      /// Campos despues de rework
      activa: [''],
      tipoControlPlataforma: [''],
      tipoControlproductoAsociado: [''],
      tipoControlproductoAsociadoEntrada: [''],
      tipoControlproductoAsociadoPaquete: [''],
      tipoControlproductoAsociadoAbono: [''],
      tipoControlDivisas: [''],
      chPrefijo: [''],
      tipoGrupoInternet: [''],
      agrupada: [''],
      fechaDesdeVisita: ['', Validators.required],
      fechaHastaVisita: [''],
      fechaDesdeCompra: ['', Validators.required],
      fechaHastaCompra: ['', Validators.required],
      sinFechaFin: [false],
      horarioDeVisitaInicio: [''],
      horarioDeVisitaFin: [''],
      tipoControlDias: [''],
      tipoIVA: ['0', Validators.required],
      pvp: [0, [Validators.min(0)]],
      bimp: [0, [Validators.min(0)]],
      desglosarGastosGestion: [''],
      tipoControlTIVAComision: ['0', Validators.required],
      pvpc: [0, [Validators.min(0)]],
      bimpc: [0, [Validators.min(0)]],
      minimo: [0, [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      maximo: [0, [Validators.pattern('^-?[0-9]*$'), Validators.min(0)]],
      orden: [0, Validators.pattern('^[0-9]*$')],
    });
    //this.editorRegistroForm.controls['fechaHastaVisita'].setValidators([Validators.required]);
    //this.editorRegistroForm.controls['fechaHastaVisita'].setValidators([Validators.required]);
    this.editorRegistroForm.controls['fechaHastaVisita'].setValidators([Validators.required]);
    this.editorFiltrosForm = this.formBuilder.group({
      UCdesde: [''],
      UChasta: [''],
      UVdesde: [''],
      UVhasta: [''],
      taquilla: [''],
      internet: [''],
      automatica: [''],
      alaventa: [''],
      tipoTipoEntrada: ['']
    });

    this.sort = new MatSort;
    this.paginator =new MatPaginator(new MatPaginatorIntl,null);
    //Para enlazar los cambios de pagina de tabla a un cambio de datos
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.cargarDatosAsync(this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(() => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = parseInt(this.dataSource.data[0].TotalRegistros);//data.total_count;
          //
          return true;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the API has reached its rate limit. Return empty data.
          return observableOf([]);
        })
      ).subscribe();

    //Array de forms de promociones WOIT
    this.formitemsPromociones = this.formBuilder.group({
      'itemsPromociones': this.formBuilder.array([])
      //this.initItemsPromociones() dentro      ^

    });

    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);
    this.resetearFiltrosActivos();


  }

  minimoValidator() {
    this.editorRegistroForm.controls['minimo'].setValidators([Validators.pattern('^-?[0-9]*$'), Validators.min(0), Validators.max(parseInt(this.editorRegistroForm.get('maximo').value))]);
  }

  // un getter para facilitar el acceso a los campos del form
  get f() { return this.editorRegistroForm.controls; } //Editor
  get fFiltro() { return this.editorFiltrosForm.controls; } //Filtros

  model = new traspasomodelo();

  initItemsPromociones(nombre?, tiva?, baseim?, pvp?, numentradas?) {
    return this.formBuilder.group({
      //  ---------------------forms fields on x level ------------------------
      'labelProducto': [nombre == null ? '' : nombre + ' x ' + numentradas.toString()],
      'tIVA': [tiva == null ? '' : tiva, [Validators.required, Validators.pattern('[0-9]{3}')]],
      'BASE': [baseim == null ? '' : baseim, [Validators.required, Validators.pattern('[0-9]{3}')]],
      'PVP': [pvp == null ? '' : pvp, [Validators.required, Validators.pattern('[0-9]{3}')]]
      // ---------------------------------------------------------------------
    });
  }

  AnadirRegistro() {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.editorRegistroForm.controls['tipoIVA'].setValue('0');
    this.editorRegistroForm.controls['pvp'].setValue(0);
    this.editorRegistroForm.controls['bimp'].setValue(0);
    this.editorRegistroForm.controls['tipoControlTIVAComision'].setValue('0');
    this.editorRegistroForm.controls['pvpc'].setValue(0);
    this.editorRegistroForm.controls['bimpc'].setValue(0);
    this.editorRegistroForm.controls['minimo'].setValue(0);
    this.editorRegistroForm.controls['maximo'].setValue(0);
    this.editorRegistroForm.controls['orden'].setValue(0);

    //Ponemos los idiomas a reset
    this.editorListaIdiomas = new Array<IdiomaGR>();
    this.tiposIdiomas.forEach(idioma => {
      this.editorListaIdiomas.push(<IdiomaGR>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null });
    });
    this.model = new traspasomodelo();

    this.InsertOrUpdate = 'I';
    this.editando = true;
    this.edicionPkId = "";

    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor

    this.model.Lunes = false;
    this.model.Martes = false;
    this.model.Miercoles = false;
    this.model.Jueves = false;
    this.model.Viernes = false;
    this.model.Sabado = false;
    this.model.Domingo = false;
    this.model.Internet = false;
    this.model.Taquilla = false;
    this.model.TaqAutomatica = false;
    this.model.AlaVenta = true;
    this.model.DesglosarComision = false;
    this.model.Agrupar = false;
    this.model.AsumeIncrementoIVA = false;
    this.model.iTipoIvaId = this.model.iTipoIvaComisionId = "0"
    this.arr_dias_semana_seleccionadas = [];
    this.arr_plataformas_venta_seleccionadas = [];
    this.f.tipoControlPlataforma.reset();
    this.f.tipoControlDias.reset();
    this.model.iDivisaId = this.globales.divisapordefectoSistema;
    this.tipoControlDivisas.setValue(this.model.iDivisaId);

    this.f.tipoControlproductoAsociado.reset();
    this.f.tipoControlproductoAsociadoEntrada.reset();
    this.f.tipoControlproductoAsociadoPaquete.reset();
    this.f.tipoControlproductoAsociadoAbono.reset();

    this.editorRegistroForm.get('fechaDesdeVisita').reset();
    this.editorRegistroForm.get('fechaHastaVisita').reset();
    this.editorRegistroForm.get('fechaDesdeCompra').reset();
    this.editorRegistroForm.get('fechaHastaCompra').reset();
    /* this.editorRegistroForm.controls['fechaDesdeVisita'].setValue("");
    this.editorRegistroForm.controls['fechaDesdeVisita'].updateValueAndValidity();

    this.editorRegistroForm.controls['fechaHastaVisita'].setValue("");
    this.editorRegistroForm.controls['fechaHastaVisita'].updateValueAndValidity();

    this.editorRegistroForm.controls['fechaDesdeCompra'].setValue("");
    this.editorRegistroForm.controls['fechaDesdeCompra'].updateValueAndValidity();

    this.editorRegistroForm.controls['fechaHastaCompra'].setValue("");
    this.editorRegistroForm.controls['fechaHastaCompra'].updateValueAndValidity(); */


    this.actualizarPlataformasSeleccionadas();
  }

  newEditar(item) { // editar tarifa
    // this.editorRegistroForm.reset();
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.editorRegistroForm.controls['tipoIVA'].setValue('0');
    this.editorRegistroForm.controls['pvp'].setValue(0);
    this.editorRegistroForm.controls['bimp'].setValue(0);
    this.editorRegistroForm.controls['tipoControlTIVAComision'].setValue('0');
    this.editorRegistroForm.controls['pvpc'].setValue(0);
    this.editorRegistroForm.controls['bimpc'].setValue(0);
    this.editorRegistroForm.controls['minimo'].setValue(0);
    this.editorRegistroForm.controls['maximo'].setValue(0);
    this.editorRegistroForm.controls['orden'].setValue(0);
    this.editorRegistroForm.get('fechaDesdeVisita').reset();
    this.editorRegistroForm.get('fechaHastaVisita').reset();
    this.editorRegistroForm.get('fechaDesdeCompra').reset();
    this.editorRegistroForm.get('fechaHastaCompra').reset();
    
    
    
    
    
    
    
    this.model.PkId = item.PkId;
    this.servicioAPI.getTiposIVA().subscribe(tipoIVA => {
      this.tiposIVA = tipoIVA.DatosResult.ListaTipoIva;

      
      
      

      this.TarifaPromocion = item.TarifaPromocion;
      
      this.editorListaIdiomas = [];
      // importantt editar
      item.Idiomas.forEach(idioma => {
        
        if (this.editorRegistroForm.get('nombre' + idioma.CodIdioma)) { // form field ya existe
          this.editorRegistroForm.controls['nombre' + idioma.CodIdioma].setValue(idioma.Nombre);
        } else {
          this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma, new FormControl(idioma.Nombre));
        }
        if (this.editorRegistroForm.get('descripcion' + idioma.CodIdioma)) { // form field ya existe
          this.editorRegistroForm.controls['descripcion' + idioma.CodIdioma].setValue(idioma.Descripcion);
        } else {
          this.editorRegistroForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(idioma.Descripcion));
        }
      });

      // campos tabla
      this.TarifaPromocion.forEach(tarifa => { // al elegir tipo de paquete crear campos tabla
        
        this.editorRegistroForm.addControl('pvp' + tarifa.Pkid, new FormControl(parseFloat(tarifa.Precio.replace(',', '.')), [Validators.min(0)]));
        this.editorRegistroForm.addControl('pvpComision' + tarifa.Pkid, new FormControl((parseFloat(tarifa.Comision.replace(',', '.'))), [Validators.min(0)]));
        this.editorRegistroForm.addControl('tipoIva' + tarifa.Pkid, new FormControl(tarifa.iTipoIvaId));
        this.editorRegistroForm.addControl('tipoIvaComision' + tarifa.Pkid, new FormControl(tarifa.TipoIVAComisionId));
        this.editorRegistroForm.addControl('baseImponible' + tarifa.Pkid, new FormControl(parseInt(tarifa.BaseImponible), [Validators.min(0)]));
        this.editorRegistroForm.addControl('baseImponibleComision' + tarifa.Pkid, new FormControl(parseInt(tarifa.ComisionBI), [Validators.min(0)]));
        tarifa.Idiomas.map(idioma => { // campos idoma dentro tabla (solo idiomas de cada paquete)
          
          this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma + tarifa.Pkid, new FormControl(idioma.Nombre));
          this.editorRegistroForm.addControl('descripcion' + idioma.CodIdioma + tarifa.Pkid, new FormControl(idioma.Descripcion));
        });
      })

      // CAMPOS INDIVIDUALES PVP COMISION...
      this.f['tipoIVA'].setValue(item.iTipoIvaId);
      this.f['tipoControlTIVAComision'].setValue(item.iTipoIvaComisionId);
      // pvps
      if (typeof item.PrecioPVP === "string" && item.PrecioPVP.includes(',')) {// si tiene decimales 
        this.f['pvp'].setValue(parseFloat(item.PrecioPVP.replace(',', '.')));
        this.model.PrecioPVP = parseFloat(item.PrecioPVP.replace(',', '.'));
      } else if (typeof item.PrecioPVP === "string" && !item.PrecioPVP.includes(',')) { // no tiene decimales
        this.f['pvp'].setValue(parseFloat(item.PrecioPVP));
        this.model.PrecioPVP = parseFloat(item.PrecioPVP);
      } else {
        this.f['pvp'].setValue(item.PrecioPVP);
        this.model.PrecioPVP = item.PrecioPVP;
      }

      if (typeof item.Comision === "string" && item.Comision.includes(',')) { // si tiene decimales
        this.f['pvpc'].setValue(parseFloat(item.Comision.replace(',', '.')));
        this.model.Comision = parseFloat(item.Comision.replace(',', '.'));
      } else if (typeof item.Comision === "string" && !item.Comision.includes(',')) { // no tiene decimales
        this.f['pvpc'].setValue(item.Comision);
        this.model.Comision = item.Comision;
      } else {
        this.f['pvpc'].setValue(item.Comision);
        this.model.Comision = item.Comision;
      }
      // bimps
      this.f['bimp'].setValue(parseFloat(item.BaseImponible.replace(',', '.')));
      this.model.BaseImponible = parseFloat(item.BaseImponible.replace(',', '.'));
      this.f['bimpc'].setValue(parseFloat(item.ComisionBI.replace(',', '.')));
      this.model.ComisionBI = parseFloat(item.ComisionBI.replace(',', '.'));


      this.editorListaIdiomas = item.Idiomas;
      this.tiposGInternet.forEach(i => {
        if (i.pkId == item.iGrupoInternetId) {
          this.editorRegistroForm.get('tipoGrupoInternet').setValue(i);
        }
      })
      // this.model.iGrupoInternetId = item.iGrupoInternetId;


      let diasList = [];
      if (item.Lunes === '1') {
        diasList.push({ id: '1', value: "Lunes" });
        this.model.Lunes = true;
      } else {
        this.model.Lunes = false;
      }
      if (item.Martes === '1') {
        diasList.push({ id: '2', value: "Martes" });
        this.model.Martes = true;
      } else {
        this.model.Martes = false;
      }
      if (item.Miercoles === '1') {
        diasList.push({ id: '3', value: "Miércoles" });
        this.model.Miercoles = true;
      } else {
        this.model.Miercoles = false;
      }
      if (item.Jueves === '1') {
        diasList.push({ id: '4', value: "Jueves" });
        this.model.Jueves = true;
      } else {
        this.model.Jueves = false;
      }
      if (item.Viernes === '1') {
        diasList.push({ id: '5', value: "Viernes" });
        this.model.Viernes = true;
      } else {
        this.model.Viernes = false;
      }
      if (item.Sabado === '1') {
        diasList.push({ id: '6', value: "Sábado" });
        this.model.Sabado = true;
      } else {
        this.model.Sabado = false;
      }
      if (item.Domingo === '1') {
        diasList.push({ id: '7', value: "Domingo" });
        this.model.Domingo = true;
      } else {
        this.model.Domingo = false;
      }

      let plataformas = []
      if (item.Taquilla === 'True') {
        plataformas.push('1');
      }
      if (item.Internet === 'True') {
        plataformas.push('2');
      }
      if (item.TaqAutomatica === 'True') {
        plataformas.push('3');
      }
      if (item.IntAdmon === 'True') {
        plataformas.push('4');
      }

      let tipoProducto;
      if (item.iTipoEntradaId !== '0') {
        tipoProducto = '1';
      } else if (item.iTipoPromocionId !== '0') {
        tipoProducto = '2';
      } else if (item.iTipoAbonadoId !== '0') {
        tipoProducto = '3';
      }

      this.f['activa'].setValue(item.AlaVenta === 'True' ? true : false);
      this.f['tipoControlPlataforma'].setValue(plataformas);
      this.f['tipoControlproductoAsociado'].setValue(tipoProducto);
      // select autocomplete
      this.ProductosEntrada.forEach(e => {
        if (e.EntradaId === item.iTipoEntradaId) {
          this.f['tipoControlproductoAsociadoEntrada'].setValue(e);
        }
      })
      this.ProductosPaquete.forEach(p => {
        if (p.pkId === item.iTipoPromocionId) {
          this.f['tipoControlproductoAsociadoPaquete'].setValue(p);
        }
      })
      this.f['tipoControlproductoAsociadoAbono'].setValue(item.iTipoAbonadoId);
      this.actualizarProductoSeleccionado();
      this.actualizarPlataformasSeleccionadas();
      this.f['tipoControlDivisas'].setValue(item.iDivisaId);
      //
      this.f['fechaDesdeVisita'].setValue(this.aFecha(item.FecIniVisita));
      this.f['fechaHastaVisita'].setValue(this.aFecha(item.FecFinVisita));
      this.f['fechaDesdeCompra'].setValue(this.aFecha(item.FecCompraDesde));
      this.f['fechaHastaCompra'].setValue(this.aFecha(item.FecCompraHasta));
      //this.model.FecIniVisita = item.FecIniVisita.split(' ')[0];
      //this.model.FecFinVisita = item.FecFinVisita.split(' ')[0];
      //this.model.FecCompraDesde = item.FecCompraDesde.split(' ')[0];
      //this.model.FecCompraHasta = item.FecCompraHasta.split(' ')[0];
      this.f['sinFechaFin'].setValue(item.NoFecFinVisita === 'True' ? true : false);
      this.sinFechaFin();
      this.model.HoraIni = this.globales.anadirCeroHoraLeft(item.HoraIni.substring(10, 20).trim());
      this.f['horarioDeVisitaInicio'].setValue(this.globales.anadirCeroHoraLeft(item.HoraIni.substring(10, 20).trim()));
      this.model.HoraFin = this.globales.anadirCeroHoraLeft(item.HoraFin.substring(10, 20).trim());
      this.f['horarioDeVisitaFin'].setValue(this.globales.anadirCeroHoraLeft(item.HoraFin.substring(10, 20).trim()));
      this.model.chPrefijo = item.chPrefijo;
      this.f['tipoControlDias'].setValue(diasList);

      this.f['minimo'].setValue(item.Minimo);
      this.f['maximo'].setValue(item.Maximo);
      this.f['orden'].setValue(item.Orden);
      this.actualizarPlataformasSeleccionadas();

      this.InsertOrUpdate = 'U';

      this.editando = true;
      this.edicionPkId = this.model.PkId;
      //
      //
      this.alertaServicio.limpiar();
      Object.keys(this.editorRegistroForm.controls).forEach(field => { // {1}
        const control = this.editorRegistroForm.get(field);            // {2}
        control.markAsTouched({ onlySelf: true });       // {3}
      });
    });
  }

 /*  internetSeleccionado() {
    
    if (!this.editorRegistroForm.get('tipoControlPlataforma').value) {
      return false;
    }
    this.editorRegistroForm.get('tipoControlPlataforma').value.forEach(value => {
      
      if (value === '2') {
        
        return true;
      }else{
        return false
      }
    });
  } */

  editar(item) {

    this.TarifaPromocion = item.TarifaPromocion;
    this.dataSourceTarifaPromocion = new MatTableDataSource(item.TarifaPromocion);

    let objtraspaso = new traspasomodelo();
    objtraspaso.PkId = item.PkId;
    objtraspaso.Internet = this.globales.aBooleano(item.Internet);
    objtraspaso.Taquilla = this.globales.aBooleano(item.Taquilla);
    objtraspaso.TaqAutomatica = this.globales.aBooleano(item.TaqAutomatica);
    objtraspaso.AlaVenta = this.globales.aBooleano(item.AlaVenta);
    objtraspaso.iTipoEntradaId = item.iTipoEntradaId;
    objtraspaso.iTipoPromocionId = item.iTipoPromocionId;
    objtraspaso.FecIniVisita = item.FecIniVisita;
    objtraspaso.FecFinVisita = item.FecFinVisita;
    objtraspaso.NoFecFinVisita = item.NoFecFinVisita;
    objtraspaso.PrecioPVP = item.PrecioPVP;
    objtraspaso.PorcIva = item.PorcIva;
    objtraspaso.Comision = item.Comision;
    objtraspaso.PorIvaComision = item.PorIvaComision;
    objtraspaso.iDivisaId = item.iDivisaId;
    objtraspaso.iTipoAbonadoId = item.iTipoAbonadoId;
    objtraspaso.Agrupar = this.globales.aBooleano(item.Agrupar);
    objtraspaso.Orden = item.Orden;
    objtraspaso.Minimo = item.Minimo;
    objtraspaso.Maximo = item.Maximo;
    objtraspaso.iGrupoInternetId = item.iGrupoInternetId;
    objtraspaso.FecCompraDesde = item.FecCompraDesde;
    objtraspaso.FecCompraHasta = item.FecCompraHasta;
    objtraspaso.HoraIni = this.globales.anadirCeroHoraLeft(item.HoraIni.substring(10, 20).trim());
    objtraspaso.HoraFin = this.globales.anadirCeroHoraLeft(item.HoraFin.substring(10, 20).trim());
    objtraspaso.iTipoIvaId = item.iTipoIvaId;
    objtraspaso.iTipoIvaComisionId = item.iTipoIvaComisionId;
    objtraspaso.BaseImponible = item.BaseImponible;
    objtraspaso.ComisionBI = item.ComisionBI;
    objtraspaso.DesglosarComision = this.globales.aBooleano(item.DesglosarComision);
    objtraspaso.Lunes = this.globales.aBooleano(item.Lunes);
    objtraspaso.Martes = this.globales.aBooleano(item.Martes);
    objtraspaso.Miercoles = this.globales.aBooleano(item.Miercoles);
    objtraspaso.Jueves = this.globales.aBooleano(item.Jueves);
    objtraspaso.Viernes = this.globales.aBooleano(item.Viernes);
    objtraspaso.Sabado = this.globales.aBooleano(item.Sabado);
    objtraspaso.Domingo = this.globales.aBooleano(item.Domingo);
    objtraspaso.chPrefijo = item.chPrefijo;
    //Dias
    this.arr_dias_semana_seleccionadas = [];
    if (objtraspaso.Lunes) { this.arr_dias_semana_seleccionadas.push({ id: '1', value: "Lunes" }) };
    if (objtraspaso.Martes) { this.arr_dias_semana_seleccionadas.push({ id: '2', value: "Martes" }) };
    if (objtraspaso.Miercoles) { this.arr_dias_semana_seleccionadas.push({ id: '3', value: "Miércoles" }) };
    if (objtraspaso.Jueves) { this.arr_dias_semana_seleccionadas.push({ id: '4', value: "Jueves" }) };
    if (objtraspaso.Viernes) { this.arr_dias_semana_seleccionadas.push({ id: '5', value: "Viernes" }) };
    if (objtraspaso.Sabado) { this.arr_dias_semana_seleccionadas.push({ id: '6', value: "Sábado" }) };
    if (objtraspaso.Domingo) { this.arr_dias_semana_seleccionadas.push({ id: '7', value: "Domingo" }) };
    this.f["tipoControlDias"].setValue(this.arr_dias_semana_seleccionadas);
    //Plataformas de venta
    this.arr_plataformas_venta_seleccionadas = [];
    if (objtraspaso.Internet || (!objtraspaso.Internet && !objtraspaso.Taquilla && !objtraspaso.TaqAutomatica)) { this.arr_plataformas_venta_seleccionadas.push({ id: '1', value: "Internet" }) };
    if (objtraspaso.Taquilla || (!objtraspaso.Internet && !objtraspaso.Taquilla && !objtraspaso.TaqAutomatica)) { this.arr_plataformas_venta_seleccionadas.push({ id: '2', value: "Taquilla" }) };
    if (objtraspaso.TaqAutomatica || (!objtraspaso.Internet && !objtraspaso.Taquilla && !objtraspaso.TaqAutomatica)) { this.arr_plataformas_venta_seleccionadas.push({ id: '3', value: "Taquilla Automática" }) };
    this.f["tipoControlPlataforma"].setValue(this.arr_plataformas_venta_seleccionadas);

    objtraspaso.TipoProductoAsociado = item.iTipoEntradaId != '0' ? '1' : item.iTipoPromocionId != '0' ? '2' : '3';
    //IDIOMAS
    //
    this.editorListaIdiomas = new Array<IdiomaGR>();
    if (this.tiposIdiomas != null && this.tiposIdiomas.length > 0) {
      this.tiposIdiomas.forEach(idioma => {
        let idiomafiltrado = item.Idiomas == null ? [] : item.Idiomas.filter(x => x.CodIdioma == idioma.CodIdioma);
        if (idiomafiltrado.length > 0) {
          this.editorListaIdiomas.push(<IdiomaGR>{ CodIdioma: idioma.CodIdioma, Nombre: idiomafiltrado[0].Nombre, Descripcion: idiomafiltrado[0].Descripcion });
        }
        else {
          this.editorListaIdiomas.push(<IdiomaGR>{ CodIdioma: idioma.CodIdioma, Nombre: null, Descripcion: null });
        }
      });
    }
    this.model = objtraspaso;
    

    var tiposDiasControl = [];
    if (this.model.Lunes === true) {
      tiposDiasControl.push('1');
    }
    if (this.model.Martes === true) {
      tiposDiasControl.push('2');
    }
    if (this.model.Miercoles === true) {
      tiposDiasControl.push('3');
    }
    if (this.model.Jueves === true) {
      tiposDiasControl.push('4');
    }
    if (this.model.Viernes === true) {
      tiposDiasControl.push('5');
    }
    if (this.model.Sabado === true) {
      tiposDiasControl.push('6');
    }
    if (this.model.Domingo === true) {
      tiposDiasControl.push('7');
    }

    this.editorRegistroForm.controls['tipoControlDivisas'].setValue(this.model.iDivisaId);
    this.editorRegistroForm.controls['tipoControlDias'].setValue(tiposDiasControl);

    this.InsertOrUpdate = 'U';
    this.editando = true;
    this.edicionPkId = this.model.PkId;
    //
    //
    this.alertaServicio.limpiar();
  }

  guardar() {
    this.isLoadingResults = true;
    //WOIT
    ////TODO, de momento no se guarda hay que cambiar todos los modelos y lo que se pasa
    ////y el procedimiento (no es motivo grupo) WOIT
    let insertando = this.model.PkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      //Comprobamos que el PkId no sea null (estariamos insertando)
      this.calculaModeloDiasSemana(false);
      let objtraspaso = new peticion_Insercion_Upd_Tarifas;
      if (this.model.PkId != null) {
        
        objtraspaso = this.dataSource.data.map<peticion_Insercion_Upd_Tarifas>((item) => {
          
          return {
            PkId: item.PkId,
            Internet: globales.aBit(item.Internet).toString(),
            Taquilla: globales.aBit(item.Taquilla).toString(),
            TaqAutomatica: globales.aBit(item.TaqAutomatica).toString(),
            AlaVenta: globales.aBit(item.AlaVenta).toString(),
            iTipoEntradaId: item.iTipoEntradaId,
            iTipoPromocionId: item.iTipoPromocionId,
            FecIniVisita: item.FecIniVisita,
            FecFinVisita: item.FecFinVisita,
            NoFecFinVisita: item.NoFecFinVisita,
            PrecioPVP: globales.ComaDecimalxPunto(item.PrecioPVP).toString(),
            PorcIva: globales.ComaDecimalxPunto(item.PorcIva),
            Comision: globales.ComaDecimalxPunto(item.Comision),
            PorIvaComision: globales.ComaDecimalxPunto(item.PorIvaComision),
            iDivisaId: item.iDivisaId,
            iTipoAbonadoId: item.iTipoAbonadoId,
            Agrupar: globales.aBit(item.Agrupar).toString(),
            Orden: item.Orden,
            Minimo: item.Minimo,
            Maximo: item.Maximo,
            iGrupoInternetId: this.editorRegistroForm.get('tipoGrupoInternet').value ? this.editorRegistroForm.get('tipoGrupoInternet').value.pkId : "0",
            FecCompraDesde: item.FecCompraDesde,
            FecCompraHasta: item.FecCompraHasta,
            HoraIni: item.HoraIni,
            HoraFin: item.HoraFin,
            iTipoIvaId: item.iTipoIvaId,
            iTipoIvaComisionId: item.iTipoIvaComisionId,
            BaseImponible: globales.ComaDecimalxPunto(item.BaseImponible),
            ComisionBI: globales.ComaDecimalxPunto(item.ComisionBI),
            DesglosarComision: globales.aBit(item.DesglosarComision).toString(),
            Lunes: globales.aBit(item.Lunes).toString(),
            Martes: globales.aBit(item.Martes).toString(),
            Miercoles: globales.aBit(item.Miercoles).toString(),
            Jueves: globales.aBit(item.Jueves).toString(),
            Viernes: globales.aBit(item.Viernes).toString(),
            Sabado: globales.aBit(item.Sabado).toString(),
            Domingo: globales.aBit(item.Domingo).toString(),
            AsumeIncrementoIVA: null,
            chPrefijo: item.chPrefijo,
            TarifaPromocion: item.TarifaPromocion,

            Idiomas: item.Idiomas,
            ConexionIacpos: null,
            clienteAPI: null,
            NombreEnTaquilla: null,
            Funcion: null,
            deserialize: null
          }
        }).find(item => item.PkId == this.model.PkId);
      }
      
      objtraspaso.PkId = this.model.PkId;
      objtraspaso.Internet = globales.aBit(this.model.Internet).toString();
      objtraspaso.Taquilla = globales.aBit(this.model.Taquilla).toString();
      objtraspaso.TaqAutomatica = globales.aBit(this.model.TaqAutomatica).toString();
      objtraspaso.AlaVenta = globales.aBit(this.model.AlaVenta).toString();
      objtraspaso.iTipoEntradaId = this.model.iTipoEntradaId;
      objtraspaso.iTipoPromocionId = this.model.iTipoPromocionId;
      objtraspaso.FecIniVisita = this.model.FecIniVisita;
      objtraspaso.FecFinVisita = this.model.FecFinVisita;
      objtraspaso.NoFecFinVisita = this.compruebaNoHayFechaFin();
      objtraspaso.PrecioPVP = globales.ComaDecimalxPunto(this.model.PrecioPVP.toString());
      objtraspaso.PorcIva = globales.ComaDecimalxPunto(this.model.PorcIva);
      objtraspaso.Comision = globales.ComaDecimalxPunto(this.model.Comision.toString());
      objtraspaso.PorIvaComision = globales.ComaDecimalxPunto(this.model.PorIvaComision);
      objtraspaso.iDivisaId = this.model.iDivisaId;
      objtraspaso.iTipoAbonadoId = this.model.iTipoAbonadoId;
      objtraspaso.Agrupar = globales.aBit(this.model.Agrupar).toString();
      objtraspaso.Orden = this.model.Orden;
      objtraspaso.Minimo = this.model.Minimo;
      objtraspaso.Maximo = this.model.Maximo;
      objtraspaso.iGrupoInternetId = this.editorRegistroForm.get('tipoGrupoInternet').value ? this.editorRegistroForm.get('tipoGrupoInternet').value.pkId : "0";
      objtraspaso.FecCompraDesde = this.model.FecCompraDesde;
      objtraspaso.FecCompraHasta = this.model.FecCompraHasta;
      objtraspaso.HoraIni = this.model.HoraIni == null || this.model.HoraIni.trim() == "" ? "00:00:01" : this.model.HoraIni;
      objtraspaso.HoraFin = this.model.HoraFin == null || this.model.HoraFin.trim() == "" ? "23:59:00" : this.model.HoraFin;
      objtraspaso.iTipoIvaId = this.model.iTipoIvaId;
      objtraspaso.iTipoIvaComisionId = this.model.iTipoIvaComisionId;
      objtraspaso.BaseImponible = globales.ComaDecimalxPunto(this.model.BaseImponible);
      objtraspaso.ComisionBI = globales.ComaDecimalxPunto(this.model.ComisionBI);
      objtraspaso.DesglosarComision = globales.aBit(this.model.DesglosarComision).toString();
      objtraspaso.Lunes = globales.aBit(this.model.Lunes).toString();
      objtraspaso.Martes = globales.aBit(this.model.Martes).toString();
      objtraspaso.Miercoles = globales.aBit(this.model.Miercoles).toString();
      objtraspaso.Jueves = globales.aBit(this.model.Jueves).toString();
      objtraspaso.Viernes = globales.aBit(this.model.Viernes).toString();
      objtraspaso.Sabado = globales.aBit(this.model.Sabado).toString();
      objtraspaso.Domingo = globales.aBit(this.model.Domingo).toString();
      objtraspaso.AsumeIncrementoIVA = globales.aBit(this.model.AsumeIncrementoIVA).toString();
      objtraspaso.chPrefijo = this.model.chPrefijo;
      //TarifaPromocion, lista de lo que haya en el FormArray
      //
      objtraspaso.TarifaPromocion = this.model.TarifaPromocion;
      objtraspaso.Idiomas = this.editorListaIdiomas;
      


      //Salvar a API
      this.servicioAPI.insUpdTarifas(objtraspaso)
        .pipe(first())
        .subscribe(
          data => {
            this.respuestaAPI = new Tarifas_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              this.alertaServicio.success("Datos Guardados Correctamente")
              
              let max = Math.max.apply(Math, this.dataSource.data.map(function (item) { return item.PkId; }));
              //
              //Hacemos un push al data para que se muestre
              if (insertando) {
                this.cargarDatosAsync(this.paginator.pageIndex, this.paginator.pageSize);
              }
              else {
                this.cargarDatosAsync(this.paginator.pageIndex, this.paginator.pageSize);
              }
              this.editando = false;
              //this.isLoadingResults = false;
            }
            else {
              this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              this.editando = false;
              this.isLoadingResults = false;
            }

          },
          error => {
            this.alertaServicio.error(error);
            this.editando = false;
            this.isLoadingResults = false;
          }
        );

    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el PkId a null y lo trataremos como insercion.
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.PkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog()
    }
  }

  async applyFilter(filterValue: string) {
    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    //this.dataSource.filter = filterValue.trim().toLowerCase();


    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia()
        //
      }
      else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas()
      }
    }

  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible
    //Cambiar el boton
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
        // for(let a of seleccioncolumnas) {
        //   
      }
    }
  }

  traeNombreIdiomaxID(id) {
    return this.tiposIdiomas.filter(x => x.PkId == id)[0].CodIdioma;
  }

  traeNombreEntradaxID(id) {
    if (this.tiposEntradas == null || id == null) { return null; } else {
      let respuesta = this.tiposEntradas.filter(x => x.EntradaId == id)
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
    }
  }
  /* traeNombreTipoAbonadoxID(id) {
    
      this.tiposAbonados.forEach(a => {
        if (a.PkId == id) {
          return a.Nombre;
        }
      })
      let respuesta = this.tiposAbonados.filter(x => x.PkId == id)
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
  } */

  traeDivisaxID(id): Divisa_R {
    if (this.tiposDivisas == null || id == null) { return null; } else {
      let respuesta = this.tiposDivisas.filter(x => x.PkId == id)
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0];
    }
  }

  redondeadivisa(importe: number): number {
    if (this.model.iDivisaId != null && this.model.iDivisaId.trim() != "") {
      let divisa = this.traeDivisaxID(this.model.iDivisaId);
      //
      //
      importe = Number(new DecimalPipe('en').transform(importe, '1.0-' + divisa.NumeroDecimales)) || 0;
    }
    return importe;
  }

  redondeadivisaBaseImp(importe: number): number {
    if (this.model.iDivisaId != null && this.model.iDivisaId.trim() != "") {
      //let divisa = this.traeDivisaxID(this.model.iDivisaId);
      //
      //
      importe = Number(new DecimalPipe('en').transform(importe, '1.0-' + 4)) || 0;
    }
    return importe;
  }

  columnaVariable(element, column) {
    element + column.toString()
  }

  cambiapvp(tipoPvp, evento?) {
    
    // platSelected
    
    let pvp = this.editorRegistroForm.get(tipoPvp).value;
    if (pvp && pvp !== '0' || evento && evento.target.classList.value.indexOf('is-invalid') == -1) {
      let tipoIva = this.editorRegistroForm.get(tipoPvp == 'pvp' ? 'tipoIVA' : 'tipoControlTIVAComision').value;
      if (tipoIva == '0') {// si iva es 0 base imponible sera pvp
        this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'bimp' : 'bimpc'].setValue(pvp);
      } else { // iva no es 0 se calcula base imponible
        this.calendarioTiposIVA.forEach(iva => {
          if (iva.id === tipoIva) {
            let bip = (pvp / (1 + (parseInt(iva.Iva) / 100))).toFixed(this.NumeroDecimales);
            this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'bimp' : 'bimpc'].setValue(bip);
            return;
          }
        });
      }
    }
  }

  ///////////////////////////////////////
  ///////////////////////////////////////
  // FUNCIONES TABLA 
  cambiapvpTabla(tipoPvp) {
    let pvp = this.editorRegistroForm.get(tipoPvp).value;
    this.TarifaPromocion.forEach(tarifa => {
      const campopor = tarifa.rPorcentaje ? tarifa.rPorcentaje : tarifa.Porcentaje;
      
      const porcentaje = (pvp * parseInt(campopor) / 100);
      
      
      

      const numEntradas = tarifa.iNumEntradas ? tarifa.iNumEntradas : tarifa.NumEntradas;
      
      this.editorRegistroForm.controls[(tipoPvp === 'pvp' ? 'pvp' : 'pvpComision') + tarifa.Pkid].setValue((porcentaje / parseInt(numEntradas)).toFixed(this.NumeroDecimales));
    })
    this.cambiapvpDesdeTabla(tipoPvp);
  }

  cambiapvpDesdeTabla(tipoPvp) {
    
    let totalPvp = 0;
    this.TarifaPromocion.forEach(tarifa => {
      const pvp = this.editorRegistroForm.get((tipoPvp === 'pvp' ? 'pvp' : 'pvpComision') + tarifa.Pkid).value;
      
      
      
      const numEntradas = tarifa.iNumEntradas ? tarifa.iNumEntradas : tarifa.NumEntradas;
      totalPvp += pvp * numEntradas;
      this.cambiarBaseImponibleDesdeTabla(tipoPvp, tarifa);
    });
    this.editorRegistroForm.controls[tipoPvp].setValue(totalPvp.toFixed(this.NumeroDecimales));
  }

  cambiarBaseImponibleDesdeTabla(tipoPvp, tarifa) {
    let pvp = this.editorRegistroForm.get((tipoPvp === 'pvp' ? 'pvp' : 'pvpComision') + tarifa.Pkid).value;
    let tipoIva = this.editorRegistroForm.get(tipoPvp == 'pvp' ? 'tipoIva' + tarifa.Pkid : 'tipoIvaComision' + tarifa.Pkid).value;
    
    if (tipoIva == '0') {
      this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'baseImponible' + tarifa.Pkid : 'baseImponibleComision' + tarifa.Pkid].setValue(pvp);
    } else {
      this.calendarioTiposIVA.forEach(iva => {
        if (iva.id === tipoIva) {
          
          
          
          let bip = (pvp / (1 + (parseInt(iva.Iva) / 100))).toFixed(this.NumeroDecimales);
          
          
          this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'baseImponible' + tarifa.Pkid : 'baseImponibleComision' + tarifa.Pkid].setValue(parseInt(bip));
          return;
        }
      });
    }
    /* this.tiposIVA.forEach(iva => {
      if (iva.PkId === tipoIva) {
        tipoIva = iva.Descripcion.split(' ')[1];
        tipoIva = tipoIva.slice(0, tipoIva.length - 1)
        
        let ratio = (1 / parseInt(tipoIva)) + 1;
        
        let bip = parseFloat((pvp / ratio).toFixed(this.NumeroDecimales));
        this.editorRegistroForm.controls[tipoPvp == 'pvp' ? 'baseImponible' + tarifa.Pkid : 'baseImponibleComision' + tarifa.Pkid].setValue(bip);
        return;
      }
    }); */
  }

  revesBaseImponibleDesdeTabla(tipoBase, element) {
    
    let base = this.editorRegistroForm.get(tipoBase + element.Pkid).value;
    if (base && base !== '0') {
      let tipoIva = this.editorRegistroForm.get('tipoIva' + element.Pkid).value;
      this.calendarioTiposIVA.forEach(iva => {
        if (iva.id === tipoIva) {
          let pvp = (base + (base * parseInt(iva.Iva) / 100)).toFixed(this.NumeroDecimales);
          this.editorRegistroForm.controls[(tipoBase == 'baseImponible' ? 'pvp' : 'pvpComision') + element.Pkid].setValue(pvp);
          return;
        }
      });
      /* this.tiposIVA.forEach(iva => {
        if (iva.PkId === tipoIva) {
          tipoIva = iva.Descripcion.split(' ')[1];
          tipoIva = tipoIva.slice(0, tipoIva.length - 1)
          let ratio = (1 / parseInt(tipoIva)) + 1;
          let pvp = parseFloat((base * ratio).toFixed(this.NumeroDecimales));
          this.editorRegistroForm.controls[(tipoBase == 'baseImponible' ? 'pvp' : 'pvpComision') + element.Pkid].setValue(pvp);
          return;
        }
      }); */
    }
    this.cambiapvpDesdeTabla('pvp')
    this.cambiapvpDesdeTabla('pvpc');
  }

  /*cambiaBaseImponibleTabla(tipoBase) { // bimp
    this.TarifaPromocion.forEach(tarifa => {
      let tipoIva = this.editorRegistroForm.get('tipoIva' + tarifa.Pkid).value;
      let base = this.editorRegistroForm.get(tipoBase).value;
      this.tiposIVA.forEach(iva => {
        if (iva.PkId === tipoIva) {
          tipoIva = iva.Descripcion.split(' ')[1];
          tipoIva = tipoIva.slice(0, tipoIva.length - 1)
          let ratio = (1 / parseInt(tipoIva)) + 1;
          let pvp = parseFloat((base * ratio).toFixed(this.NumeroDecimales));
          this.editorRegistroForm.controls[tipoBase == 'bimp' ? 'pvp' : 'pvpc'].setValue(pvp);
          return;
        }
      });
    });
  }*/
  ///////////////////////////////////////
  ///////////////////////////////////////
  ///////////////////////////////////////



  cambiabaseImp(tipoBase, evento?) {
    
    // platSelected
    let base = this.editorRegistroForm.get(tipoBase).value;
    if (base && base !== '0' || evento && evento.target.classList.value.indexOf('is-invalid') == -1) {
      let tipoIva = this.editorRegistroForm.get('tipoIVA').value;
      this.calendarioTiposIVA.forEach(iva => {
        if (iva.id === tipoIva) {
          tipoIva = parseFloat(1 + ',' + iva.Iva)
          let pvp = (base + (base * parseInt(iva.Iva) / 100)).toFixed(this.NumeroDecimales);
          this.editorRegistroForm.controls[tipoBase == 'bimp' ? 'pvp' : 'pvpc'].setValue(pvp);
          return;
        }
      });
      /* this.tiposIVA.forEach(iva => {
        if (iva.PkId === tipoIva) {
          tipoIva = iva.Descripcion.split(' ')[1];
          tipoIva = tipoIva.slice(0, tipoIva.length - 1)
          let ratio = (1 / parseInt(tipoIva)) + 1;
          let pvp = parseFloat((base * ratio).toFixed(this.NumeroDecimales));
          this.editorRegistroForm.controls[tipoBase == 'bimp' ? 'pvp' : 'pvpc'].setValue(pvp);
          return;
        }
      }); */
    }
  }
  cambiapvpComision(evento) {
    if (evento.target.classList.value.indexOf('is-invalid') == -1) {
      //
      let importe = parseFloat(this.globales.ComaDecimalxPunto(evento.target.value.toString()));
      // Aprovechamos que la distribucion es igual en todos los nodos para hacer una funcion generica.
      //alert(this.calculaFechaMenor());
      //
      // let selectmat = evento.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[2].children[0].childNodes[0].childNodes[0].childNodes[2].childNodes[1].innerHTML; //.attributes[4].value
      // 
      let valor: string;
      const size = this.tiposIVA.length;
      const rIVA = this.calcularPorcIVA(valor, this.calculaFechaMenor())
      evento.target.parentNode.parentNode.childNodes[2].children[1].value = this.redondeadivisaBaseImp(importe / (1 + rIVA / 100));
      this.model.BaseImponible = this.redondeadivisaBaseImp((importe / (1 + rIVA / 100))).toString();
    }
  }
  cambiabaseImpComision(evento) {
    if (evento.target.classList.value.indexOf('is-invalid') == -1) {
      //
      let importe = parseFloat(this.globales.ComaDecimalxPunto(evento.target.value.toString()));
      // Aprovechamos que la distribucion es igual en todos los nodos para hacer una funcion generica.
      //alert(this.calculaFechaMenor());
      //
      let selectmat = evento.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[2].children[0].childNodes[0].childNodes[0].childNodes[2].childNodes[1].innerHTML; //.attributes[4].value
      // 
      let valor: string;
      const size = this.tiposIVA.length;
      for (let i = 0; i < size; i++) {
        if (selectmat == this.tiposIVA[i].Nombre) {
          valor = this.tiposIVA[i].PkId;
          
        }
      }
      const rIVA = this.calcularPorcIVA(valor, this.calculaFechaMenor())
      evento.target.parentNode.parentNode.childNodes[2].children[1].value = this.redondeadivisaBaseImp(importe / (1 + rIVA / 100));
      this.model.BaseImponible = this.redondeadivisaBaseImp((importe / (1 + rIVA / 100))).toString();
    }
  }
  cambiapvpPromo(evento, id) {
    if (evento.target.classList.value.indexOf('is-invalid') == -1) {
      //
      let importe = parseFloat(this.globales.ComaDecimalxPunto(evento.target.value.toString()));
      // Aprovechamos que la distribucion es igual en todos los nodos para hacer una funcion generica.
      //alert(this.calculaFechaMenor());
      //
      //let selectmat = evento.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[2].children[0]; //.attributes[4].value
      //
      let selectmat = this.formitemsPromociones['controls'].itemsPromociones['controls'][id].controls['tIVA'];
      //
      let rIVA = this.calcularPorcIVA(selectmat.value, this.calculaFechaMenor())
      evento.target.parentNode.parentNode.childNodes[2].children[1].value = this.redondeadivisaBaseImp(importe / (1 + rIVA / 100));

    }
  }
  cambiabaseImpPromo(evento, id) {
    if (evento.target.classList.value.indexOf('is-invalid') == -1) {
      //
      let importe = parseFloat(this.globales.ComaDecimalxPunto(evento.target.value.toString()));
      // Aprovechamos que la distribucion es igual en todos los nodos para hacer una funcion generica.
      //alert(this.calculaFechaMenor());
      //
      //let selectmat = evento.target.parentNode.parentNode.childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[2].children[0]; //.attributes[4].value
      let selectmat = this.formitemsPromociones['controls'].itemsPromociones['controls'][id].controls['tIVA'];
      let rIVA = this.calcularPorcIVA(selectmat.value, this.calculaFechaMenor())
      evento.target.parentNode.parentNode.childNodes[1].children[1].value = this.redondeadivisa(importe * (1 + (rIVA / 100)));

    }
  }

  cambiaIVA(evento) {
    let rIVA = this.calcularPorcIVA(evento.value, this.calculaFechaMenor())
    this.model.PorcIva = rIVA.toString();
    let selectmatpvp = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1];
    let selectmatbi = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[1];
    selectmatpvp.value = this.redondeadivisa(selectmatbi.value * (1 + (rIVA / 100)));
  }

  cambiaIVAComision(evento) {
    let rIVA = this.calcularPorcIVA(evento.value, this.calculaFechaMenor())
    this.model.PorIvaComision = rIVA.toString();
    let selectmatpvp = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1];
    let selectmatbi = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[1];
    selectmatpvp.value = this.redondeadivisa(selectmatbi.value * (1 + (rIVA / 100)));
  }

  //TODO Comprobar que en Promociones es igual cuando haya un cliente con Promociones
  cambiaIVAPromo(evento) {
    let rIVA = this.calcularPorcIVA(evento.value, this.calculaFechaMenor())
    let selectmatpvp = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[1].childNodes[1];
    let selectmatbi = evento.source.trigger.nativeElement.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[2].childNodes[1];
    selectmatpvp.value = this.redondeadivisa(selectmatbi.value * (1 + (rIVA / 100)));
  }

  calcularPorcIVA(tivaId: string, fecha: Date): number {
    let civa = this.calendarioTiposIVA.filter(x => x.TipoIvaId == tivaId && (fecha >= this.aFecha(x.FechaDesde.substring(0, 10)) && fecha <= this.aFecha(x.FechaHasta.substring(0, 10))));
    if (civa.length == 0) {
      this.haycambiosIVA = 0;
      return 0;
    }
    else {
      if (civa.length == 1) {
        this.haycambiosIVA = 0;
        return parseInt(civa[0].Iva);
      }
      else {
        this.haycambiosIVA = 1;
        return parseInt(civa[0].Iva);
      }
    }
  }
  quitahoras(fecha: string) {
    if (fecha == null) { return null; } else {
      return fecha.substring(0, 10).trim();
    }
  }

  cambiaNombre(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
    //
  }

  formateaIdioma(idioma: Array<IdiomaGR>, columna: ColumnaIdiomas) {
    //
    //
    try {
      return columna.textoHeader.trim().toLowerCase() == 'nombre' ? idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Nombre : idioma.filter(x => x.CodIdioma == columna.CodIdioma)[0].Descripcion;
    }
    catch (e) {
      //
      return null
    }

  }

  getHoraInicio() {
    // 
    return this.model.HoraIni;
  }

  getHoraFin() {
    // 
    return this.model.HoraFin;
  }

  getFechaFCInicio(): Date {
    return this.aFecha(this.model.FecCompraDesde);
  }

  getFechaFCFin(): Date {
    if (this.model.FecCompraHasta == null || this.model.FecCompraHasta == undefined) {
      return this.aFecha(this.model.FecCompraHasta);
    } else {
      return this.aFecha(this.model.FecCompraHasta);
    }
    if (this.model.FecCompraHasta > this.model.FecFinVisita) {
      return this.aFecha(this.model.FecFinVisita);
    }
    /* return this.aFecha(this.model.FecCompraHasta); */
  }

  getFechaFVInicio(): Date {
    return this.aFecha(this.model.FecIniVisita);
  }

  getFechaFVFin(): Date {
    return this.aFecha(this.model.FecFinVisita);
  }

  aFecha(fecha: string): Date {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
  }

  //#region "activar guardar"
  compruebadrops(): boolean {
    if (!this.compruebaIdiomas() || !this.compruebaFechas() || !this.compruebaDivisa() || !this.compruebaProducto()) { return false; } else { return this.editorRegistroForm.valid; }
  }

  compruebaIdiomas(): boolean {
    let respuesta = false;
    if (!this.model.Internet) { respuesta = true; }
    else {
      if (this.editorListaIdiomas != null && this.editorListaIdiomas.length > 0) {
        if (this.editorListaIdiomas.find(x => x.Nombre !== null && x.Nombre.trim() !== "") != undefined) { respuesta = true };
      }
    }
    return respuesta;
  }

  compruebaFechas(): boolean {
    let respuesta = false;
    let fechaIV = this.aFecha(this.model.FecIniVisita == null ? '01/01/1900' : this.model.FecIniVisita);
    let fechaIC = this.aFecha(this.model.FecCompraDesde == null ? '01/01/1900' : this.model.FecCompraDesde);
    let fechaFV = this.aFecha(this.model.FecFinVisita == null ? '31/12/2999' : this.model.FecFinVisita);
    let fechaFC = this.aFecha(this.model.FecCompraHasta == null ? '31/12/2999' : this.model.FecCompraHasta);
    if (fechaIV <= fechaFV && fechaIC <= fechaFC) respuesta = true;
    if (!respuesta && fechaIV <= fechaFV) { this.errorFechaVenta = true; } else { this.errorFechaVenta = false; }
    if (!respuesta && fechaIC <= fechaFC) { this.errorFechaCompra = true; } else { this.errorFechaCompra = false; }
    return respuesta;
  }

  compruebaDivisa(): boolean {
    let respuesta = false;
    if (this.model.iDivisaId != null && this.model.iDivisaId.trim() != "") { respuesta = true; }
    return respuesta;
  }

  compruebaProducto(): boolean {
    //TipoProductoAsociado
    let respuesta = false;
    switch (this.model.TipoProductoAsociado) {
      case '1':
        //Entrada
        if (this.model.iTipoEntradaId != null && this.model.iTipoEntradaId.trim() != "") { respuesta = true; }
        break;
      case '2':
        //Promocion
        if (this.model.iTipoPromocionId != null && this.model.iTipoPromocionId.trim() != "") { respuesta = true; }
        break;
      case '3':
        //Abonado
        if (this.model.iTipoAbonadoId != null && this.model.iTipoAbonadoId.trim() != "") { respuesta = true; }
        break;
      default:
        break;
    }
    return respuesta;
  }
  //#endregion

  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.isLoadingResults = true;
    this.alertaServicio.limpiar();
    //CArgamos los datos de muevo solo porsiacaso
    if (this.dataSource != null && this.dataSource.data != null) {
      if (filtro != null) {

        if (filtro.desdeC != null && filtro.hastaC != null && (filtro.desdeC.trim() != "" || filtro.hastaC.trim() != "")) {
          let fC_D: Date = this.aFecha((filtro.desdeC.trim() == "" ? "01/01/1970 00:00:00" : filtro.desdeC.trim() + " 00:00:00"));
          let fC_H: Date = this.aFecha((filtro.hastaC.trim() == "" ? "31/12/2999 23:59:50" : filtro.hastaC.trim() + " 23:59:50"));
          //datostemporales = datostemporales.filter(x => (fC_H >= this.aFecha(x.FecCompraDesde.substr(0,10)) && fC_H <= this.aFecha(x.FecCompraHasta.substr(0,10))) ||
          //(fC_D >= this.aFecha(x.FecCompraDesde.substr(0,10)) && fC_D <= this.aFecha(x.FecCompraHasta.substr(0,10))));
          //Desde Hasta por Fechas
          this.filtrosactivosModelo.desdeC = filtro.desdeC.trim();
          this.filtrosactivosModelo.hastaC = filtro.hastaC.trim();
        }
        this.fFiltro["UCdesde"].setValue(this.aFecha(filtro.desdeC.trim()));
        this.fFiltro["UChasta"].setValue(this.aFecha(filtro.hastaC.trim()));

        if (filtro.desdeV != null && filtro.hastaV != null && (filtro.desdeV.trim() != "" || filtro.hastaV.trim() != "")) {
          let fV_D: Date = this.aFecha((filtro.desdeV.trim() == "" ? "01/01/1970 00:00:00" : filtro.desdeV.trim() + " 00:00:00"));
          let fV_H: Date = this.aFecha((filtro.hastaV.trim() == "" ? "31/12/2999 23:59:50" : filtro.hastaV.trim() + " 23:59:50"));
          //datostemporales = datostemporales.filter(x => (fV_D >= this.aFecha(x.FecIniVisita.substr(0,10)) && fV_H <= this.aFecha(x.FecFinVisita.substr(0,10))) ||
          //(fV_D >= this.aFecha(x.FecIniVisita.substr(0,10)) && fV_D <= this.aFecha(x.FecFinVisita.substr(0,10))));
          //Desde Hasta por Fechas
          this.filtrosactivosModelo.desdeV = filtro.desdeV.trim();
          this.filtrosactivosModelo.hastaV = filtro.hastaV.trim();
        }
        this.fFiltro["UVdesde"].setValue(this.aFecha(filtro.desdeV.trim()));
        this.fFiltro["UVhasta"].setValue(this.aFecha(filtro.hastaV.trim()));

        if (filtro.Internet != null && filtro.Internet.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.Internet.trim().toLowerCase() == (filtro.Internet.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Internet = filtro.Internet.trim();
        }
        this.fFiltro["internet"].setValue(filtro.Internet.trim());

        if (filtro.Taquilla != null && filtro.Taquilla.trim() != "") {
          // datostemporales = datostemporales.filter(x => x.Taquilla.trim().toLowerCase() == (filtro.Taquilla.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Taquilla = filtro.Taquilla.trim();
        }
        this.fFiltro["taquilla"].setValue(filtro.Taquilla.trim());

        if (filtro.Automatica != null && filtro.Automatica.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.TaqAutomatica.trim().toLowerCase() == (filtro.Automatica.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Automatica = filtro.Automatica.trim();
        }
        this.fFiltro["automatica"].setValue(filtro.Automatica.trim());

        if (filtro.AlaVenta != null && filtro.AlaVenta.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.AlaVenta.trim().toLowerCase() == (filtro.AlaVenta.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.AlaVenta = filtro.AlaVenta.trim();
        }
        this.fFiltro["alaventa"].setValue(filtro.AlaVenta.trim());

        if (filtro.TEntradaId != null && filtro.TEntradaId != "") {
          //datostemporales = datostemporales.filter(x => x.iTipoEntradaId == filtro.TEntradaId);
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.TEntradaId = filtro.TEntradaId;
        }
        this.fFiltro["tipoTipoEntrada"].setValue(filtro.TEntradaId);

      }
      else {
        if (this.fFiltro["UCdesde"].value != null && this.fFiltro["UChasta"].value != null && (this.fFiltro["UCdesde"].value != "" && this.fFiltro["UChasta"].value != "")) {
          let FechasDesde = new DatePipe('es').transform((new Date(this.fFiltro["UCdesde"].value)), 'dd/MM/yyyy').trim();
          let FechasHasta = new DatePipe('es').transform((new Date(this.fFiltro["UChasta"].value)), 'dd/MM/yyyy').trim();
          let fC_D: Date = this.aFecha(FechasDesde == "" ? "01/01/1970 00:00:00" : FechasDesde + " 00:00:00");
          let fC_H: Date = this.aFecha(FechasHasta == "" ? "31/12/2999 23:59:50" : FechasHasta + " 23:59:50");
          //datostemporales = datostemporales.filter(x => (fC_H >= this.aFecha(x.FecCompraDesde.substr(0,10)) && fC_H <= this.aFecha(x.FecCompraHasta.substr(0,10))) ||
          //(fC_D >= this.aFecha(x.FecCompraDesde.substr(0,10)) && fC_D <= this.aFecha(x.FecCompraHasta.substr(0,10))));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.desdeC = FechasDesde;
          this.filtrosactivosModelo.hastaC = FechasHasta;
        }

        if (this.fFiltro["UVdesde"].value != null && this.fFiltro["UVhasta"].value != null && (this.fFiltro["UVdesde"].value != "" && this.fFiltro["UVhasta"].value != "")) {
          let FechasDesde = new DatePipe('es').transform((new Date(this.fFiltro["UVdesde"].value)), 'dd/MM/yyyy').trim();
          let FechasHasta = new DatePipe('es').transform((new Date(this.fFiltro["UVhasta"].value)), 'dd/MM/yyyy').trim();
          let fV_D: Date = this.aFecha(FechasDesde == "" ? "01/01/1970 00:00:00" : FechasDesde + " 00:00:00");
          let fV_H: Date = this.aFecha(FechasHasta == "" ? "31/12/2999 23:59:50" : FechasHasta + " 23:59:50");
          //datostemporales = datostemporales.filter(x => (fV_D >= this.aFecha(x.FecIniVisita.substr(0,10)) && fV_H <= this.aFecha(x.FecFinVisita.substr(0,10))) ||
          //(fV_D >= this.aFecha(x.FecIniVisita.substr(0,10)) && fV_D <= this.aFecha(x.FecFinVisita.substr(0,10))));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.desdeV = FechasDesde;
          this.filtrosactivosModelo.hastaV = FechasHasta;
        }

        if (this.fFiltro["internet"].value != null && this.fFiltro["internet"].value.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.Internet.trim().toLowerCase() == (this.fFiltro["internet"].value.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Internet = this.fFiltro["internet"].value;
        }

        if (this.fFiltro["taquilla"].value != null && this.fFiltro["taquilla"].value.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.Taquilla.trim().toLowerCase() == (this.fFiltro["taquilla"].value.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Taquilla = this.fFiltro["taquilla"].value;
        }

        if (this.fFiltro["automatica"].value != null && this.fFiltro["automatica"].value.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.TaqAutomatica.trim().toLowerCase() == (this.fFiltro["automatica"].value.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.Automatica = this.fFiltro["automatica"].value;
        }

        if (this.fFiltro["alaventa"].value != null && this.fFiltro["alaventa"].value.trim() != "") {
          //datostemporales = datostemporales.filter(x => x.AlaVenta.trim().toLowerCase() == (this.fFiltro["alaventa"].value.trim().toLowerCase()=="si"?"true":"false"));
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.AlaVenta = this.fFiltro["alaventa"].value;
        }

        // // 
        if (this.fFiltro["tipoTipoEntrada"].value != null && this.fFiltro["tipoTipoEntrada"].value != "") {
          //datostemporales = datostemporales.filter(x => x.iTipoEntradaId == this.fFiltro["tipoTipoEntrada"].value);
          //Meter en FiltrosActivos
          this.filtrosactivosModelo.TEntradaId = this.fFiltro["tipoTipoEntrada"].value;
        }

      }
      await this.cargarDatosAsync(this.paginator.pageIndex, this.paginator.pageSize).then(
        respuesta => {
          let siono = new Boolean(respuesta);
          //
          if (siono) {
            //let datostemporales = this.dataSource.data;
            //aplicamos los filtros sobre los datos temporales


            //this.dataSource.data = datostemporales;

            //if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != "") {
            //  this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
            //}
            //else {

            //}
            this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
            this.resultsLength = parseInt(this.dataSource.data[0].TotalRegistros);//data.total_count;
            if (this.dataSource.paginator) {
              this.dataSource.paginator.firstPage();
            }

          }
          else {
            //No hemos podido cargar los datos
          }
          //Montamos los FormControlArray

        },
        error => {
          //No hemos podido cargar los datos
        });
    }
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    // this.filtrosactivosModelo.Nombre = filtro==null || filtro == "Nombre"?"":this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.Taquilla = filtro == null || filtro == "Taquilla" ? "" : this.filtrosactivosModelo.Taquilla;
    this.filtrosactivosModelo.Internet = filtro == null || filtro == "Internet" ? "" : this.filtrosactivosModelo.Internet;
    this.filtrosactivosModelo.Automatica = filtro == null || filtro == "Automatica" ? "" : this.filtrosactivosModelo.Automatica;
    this.filtrosactivosModelo.AlaVenta = filtro == null || filtro == "AlaVenta" ? "" : this.filtrosactivosModelo.AlaVenta;
    this.filtrosactivosModelo.desdeC = filtro == null || filtro == "fechaC" ? "" : this.filtrosactivosModelo.desdeC;
    this.filtrosactivosModelo.hastaC = filtro == null || filtro == "fechaC" ? "" : this.filtrosactivosModelo.hastaC;
    this.filtrosactivosModelo.desdeV = filtro == null || filtro == "fechaV" ? "" : this.filtrosactivosModelo.desdeV;
    this.filtrosactivosModelo.hastaV = filtro == null || filtro == "fechaV" ? "" : this.filtrosactivosModelo.hastaV;
    this.filtrosactivosModelo.TEntradaId = filtro == null || filtro == "TEntrada" ? "" : this.filtrosactivosModelo.TEntradaId;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == "General" ? "" : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
      //
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Taquilla != null && filtros.Taquilla.trim() != "") || (filtros.desdeC != null && filtros.desdeC.trim() != "") ||
      (filtros.hastaC != null && filtros.hastaC.trim() != "") || (filtros.desdeV != null && filtros.desdeV.trim() != "") ||
      (filtros.hastaV != null && filtros.hastaV.trim() != "") || (filtros.TEntradaId != null && filtros.TEntradaId.trim() != "") ||
      (filtros.Internet != null && filtros.Internet.trim() != "") || (filtros.Automatica != null && filtros.Automatica.trim() != "") || (filtros.AlaVenta != null && filtros.AlaVenta.trim() != "") ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != "")) return true; else return false;

  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }
  ///Validador

  getDias() {
    
    return this.model.tipoControlDias;
  }

  CambioDiasSemanaMultiple(evento) {
    
    //En principio no lo vamos a usar
  }
  CambioPlataformaVentaMultiple(evento) {
    //
    //controlamos el modelo de plataforma
    if (evento.value.indexOf("1") >= 0) {
      this.model.Internet = true;
    }
    else {
      this.model.Internet = false;
    }
    if (evento.value.indexOf("2") >= 0) {
      this.model.Taquilla = true;
    }
    else {
      this.model.Taquilla = false;
    }
    if (evento.value.indexOf("3") >= 0) {
      this.model.TaqAutomatica = true;
    }
    else {
      this.model.TaqAutomatica = false;
    }
  }
  ///Funcionalidades

  //Tipo => true -> Normal menos las Seleccionados
  //     => false -> Seleccionados
  filtrarTiposEntrada(tipo?: boolean): TipoEntrada[] {
    let respuesta = [];
    let entradasSeleccionadas = this.model.TarifaPromocion;
    if (tipo != null) {
      if (tipo) {
        if (entradasSeleccionadas != null && entradasSeleccionadas.length > 0) {
          respuesta = this.tiposEntradas.filter(function (item) {
            return entradasSeleccionadas.findIndex(function (itemSub) { return itemSub.pkid == item.EntradaId }) === -1;
          });
        }
        else {
          respuesta = this.tiposEntradas;
        }
      } else {
        if (entradasSeleccionadas != null && entradasSeleccionadas.length > 0) {
          respuesta = this.tiposEntradas.filter(function (item) {
            return entradasSeleccionadas.findIndex(function (itemSub) { return itemSub.pkid == item.EntradaId }) !== -1;
          });
        }
        else {
          respuesta = [];
        }
      }
    }
    return respuesta;
  }

  cambiaFCdesde(evento) {
    //
    this.fecha_minHasta = evento.value;
    this.model.FecCompraDesde = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();

    /*this.editorRegistroForm.patchValue({
      fechaDesdeCompra: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  cambiaFChasta(evento) {
    /* 
    this.fecha_maxDesde = evento.value;
    this.fechaPC_maxHasta = evento.value;
    this.model.FecCompraHasta = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();

    /*this.editorRegistroForm.patchValue({
      fechaHastaCompra: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }

  cambiaFVdesde(evento) {
    
    this.fechaFV_minHasta = evento.value;
    //this.fecha_minHasta = evento.value;
    this.model.FecIniVisita = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();

    /*this.editorRegistroForm.patchValue({
      fechaDesdeVisita: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }
  cambiaFVhasta(evento) {
    /* 
    this.fechaFV_maxDesde = evento.value;
    //this.fecha_maxDesde = evento.value;
    //this.fecha_maxHasta = evento.value;
    this.model.FecFinVisita = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
    /*if (this.model.FecFinVisita < this.model.FecCompraHasta) {
      this.model.FecCompraHasta = this.model.FecFinVisita;
    }*/

    /*this.editorRegistroForm.patchValue({
      fechaHastaVisita: new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim()
    });*/
  }

  campoGrupoInternet() {
    const activado = (this.editorRegistroForm.get('tipoControlPlataforma').value).includes("2");
    if (activado) {
      this.editorRegistroForm.controls['tipoGrupoInternet'].setValidators([Validators.required]);
      this.editorRegistroForm.controls['tipoGrupoInternet'].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls['tipoGrupoInternet'].clearValidators();
      this.editorRegistroForm.controls['tipoGrupoInternet'].updateValueAndValidity();
    }
  }


  sinFechaFin() {
    const activado = this.editorRegistroForm.get('sinFechaFin').value;
    if (!activado) {
      this.editorRegistroForm.controls['fechaHastaVisita'].setValidators([Validators.required]);
      this.editorRegistroForm.controls['fechaHastaVisita'].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls['fechaHastaVisita'].clearValidators();
      this.editorRegistroForm.controls['fechaHastaVisita'].updateValueAndValidity();
      this.editorRegistroForm.controls['fechaHastaVisita'].setValue("");
    }
  }

  calculaFechaMenor(): Date {
    let hoy = new Date();
    let fechaIFV = this.aFecha(this.model.FecIniVisita == null ? '31/12/2999' : this.model.FecIniVisita);
    let fechaIFC = this.aFecha(this.model.FecCompraDesde == null ? '31/12/2999' : this.model.FecCompraDesde);

    let mindate = new Date(Math.min.apply(null, [hoy, fechaIFV, fechaIFC]));
    return mindate;
  }

  calculaModeloDiasSemana(negar: boolean) {
    let lista = this.f["tipoControlDias"].value;
    if (lista != null && lista != "" && lista.length >= 0) {
      //
      if (negar) { this.model.Lunes = this.model.Martes = this.model.Miercoles = this.model.Jueves = this.model.Viernes = this.model.Sabado = this.model.Domingo = false }
      else {
        if (lista.findIndex(x => x == '1' || x.id == '1') >= 0) { this.model.Lunes = true; } else { this.model.Lunes = false; }
        if (lista.findIndex(x => x == '2' || x.id == '2') >= 0) { this.model.Martes = true; } else { this.model.Martes = false; }
        if (lista.findIndex(x => x == '3' || x.id == '3') >= 0) { this.model.Miercoles = true; } else { this.model.Miercoles = false; }
        if (lista.findIndex(x => x == '4' || x.id == '4') >= 0) { this.model.Jueves = true; } else { this.model.Jueves = false; }
        if (lista.findIndex(x => x == '5' || x.id == '5') >= 0) { this.model.Viernes = true; } else { this.model.Viernes = false; }
        if (lista.findIndex(x => x == '6' || x.id == '6') >= 0) { this.model.Sabado = true; } else { this.model.Sabado = false; }
        if (lista.findIndex(x => x == '7' || x.id == '7') >= 0) { this.model.Domingo = true; } else { this.model.Domingo = false; }
      }
    }
  }

  compruebaNoHayFechaFin(): string {
    if (this.model.FecCompraHasta == null || this.model.FecCompraHasta.trim() == "" || this.model.FecCompraHasta.trim().length < 10) {
      return "1"
    }
    else {
      return "0"
    }
  }
  cargarDatos() {
  }

  async cargarDatosAsync(pagina?, tamanopagina?): Promise<boolean> { // after ngoninit
    //Cargamos Datos
    pagina = pagina == null ? 0 : pagina;
    tamanopagina = tamanopagina == null ? 10 : tamanopagina;
    let resultado: boolean = false;
    var tiposUsuariotmp;

    this.servicioAPI.getIdiomas(null, null, "1").subscribe(idiomas => {
      if (idiomas.DatosResult && idiomas.DatosResult.ListaIdiomas.length > 0) {
        this.tiposIdiomas = idiomas.DatosResult.ListaIdiomas;
        this.tiposIdiomas.forEach(idioma => {
          this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma, new FormControl());
          this.editorRegistroForm.addControl('descripcion' + idioma.CodIdioma, new FormControl());
        });
      } else {
        this.tiposIdiomas = [];
        resultado = false;
      }
    });

    this.servicioAPI.getTiposEntradas().subscribe(tiposEntradas => {
      if (tiposEntradas.DatosResult && tiposEntradas.DatosResult.Lista.length > 0) {
        this.tiposEntradas = tiposEntradas.DatosResult.Lista.filter(x => this.globales.aBooleano(x.ALaVenta));
      } else {
        this.tiposEntradas = [];
        resultado = false;
      }
    });

    this.servicioAPI.getTiposIVA().subscribe(tipoIVA => {
      
      if (tipoIVA.DatosResult && tipoIVA.DatosResult.ListaTipoIva.length > 0) {
        this.tiposIVA = tipoIVA.DatosResult.ListaTipoIva;
      } else {
        this.tiposIVA = [];
        resultado = false;
      }
    });

    this.servicioAPI.getCalendariosTiposIVA().subscribe(tiposCalendarioIVA => {
      if (tiposCalendarioIVA.DatosResult && tiposCalendarioIVA.DatosResult.DatosListas.length > 0) {
        this.calendarioTiposIVA = tiposCalendarioIVA.DatosResult.DatosListas;
      } else {
        this.calendarioTiposIVA = [];
        resultado = false;
      }
    });

    this.servicioAPI.getTiposAbonado(null, null, "1").subscribe(tiposAbonado => {
      if (tiposAbonado.DatosResult && tiposAbonado.DatosResult.ListaTipoAbonado.length > 0) {
        this.tiposAbonados = tiposAbonado.DatosResult.ListaTipoAbonado;
      } else {
        this.tiposAbonados = [];
        resultado = false;
      }
    });

    /*this.servicioAPI.getDivisas().subscribe(divisas => {
      if (divisas.DatosResult && divisas.DatosResult.ListaDivisas.length > 0) {
        this.tiposDivisas = divisas.DatosResult.ListaDivisas;
        
      } else {
        this.tiposDivisas = [];
        resultado = false;
      }
    });*/

    this.servicioAPI.getGruposInternet().subscribe(gi => {
      if (gi.DatosResult && gi.DatosResult.listaGrupoInternet.length > 0) {
        this.tiposGInternet = gi.DatosResult.listaGrupoInternet;
      } else {
        this.tiposGInternet = [];
        resultado = false;
      }
    });

    let filtroTarifa: peticion_Tarifas_Lista = new peticion_Tarifas_Lista();
    if (this.filtrosactivosModelo.AlaVenta != null && this.filtrosactivosModelo.AlaVenta.toLowerCase().trim() == "si") { filtroTarifa.alaVenta = '1'; } else { this.filtrosactivosModelo.AlaVenta != null && this.filtrosactivosModelo.AlaVenta.trim() != "" ? filtroTarifa.alaVenta = '0' : filtroTarifa.alaVenta = null; }
    if (this.filtrosactivosModelo.Taquilla != null && this.filtrosactivosModelo.Taquilla.toLowerCase().trim() == "si") { filtroTarifa.taquilla = '1'; } else { this.filtrosactivosModelo.Taquilla != null && this.filtrosactivosModelo.Taquilla.trim() != "" ? filtroTarifa.taquilla = '0' : filtroTarifa.taquilla = null; }
    if (this.filtrosactivosModelo.Internet != null && this.filtrosactivosModelo.Internet.toLowerCase().trim() == "si") { filtroTarifa.internet = '1'; } else { this.filtrosactivosModelo.Internet != null && this.filtrosactivosModelo.Internet.trim() != "" ? filtroTarifa.internet = '0' : filtroTarifa.internet = null; }
    if (this.filtrosactivosModelo.Automatica != null && this.filtrosactivosModelo.Automatica.toLowerCase().trim() == "si") { filtroTarifa.taquillaAutomatica = '1'; } else { this.filtrosactivosModelo.Automatica != null && this.filtrosactivosModelo.Automatica.trim() != "" ? filtroTarifa.taquillaAutomatica = '0' : filtroTarifa.taquillaAutomatica = null; }
    filtroTarifa.feciniCompra = this.filtrosactivosModelo.desdeC;
    filtroTarifa.fecFinCompra = this.filtrosactivosModelo.hastaC;
    filtroTarifa.fecIniVisita = this.filtrosactivosModelo.desdeV;
    filtroTarifa.fecFinVisita = this.filtrosactivosModelo.hastaV;
    filtroTarifa.tipoEntradaId = this.filtrosactivosModelo.TEntradaId;
    filtroTarifa.filtro = this.filtrosactivosModelo.textoGeneral;
    filtroTarifa.pagina = pagina + 1;
    filtroTarifa.numeroRegistros = tamanopagina;

    await this.servicioAPI.getTarifasAsync(filtroTarifa)
      .then(
        data => {
          var globalInterno = this.globales;
          this.respuestaAPI = new Tarifas_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            let ListaTarifa: Tarifas[];
            //let listatemp:  Map<string, Tarifas>;
            //
            ListaTarifa = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaTarifas);

            this.dataSource = new MatTableDataSource(ListaTarifa);
            //this.dataSource.paginator = this.paginator;
            //this.dataSource.sort = this.sort;
            resultado = true;
            //Idiomas
            this.columnasIdiomas = new Array<ColumnaIdiomas>();
            this.displayedColumns = ['Acciones', 'Taquilla', 'Internet', 'Automatica',
              'Entrada', 'alaVenta', 'Semana', 'FecIniVisita', 'FecFinVisita', 'FecCompraDesde', 'FecCompraHasta',
              'PrecioPVP'
            ].filter(x => (x != (globalInterno.plataformasVenta.findIndex(y => y.id == '1') >= 0 ? '' : 'Internet')) &&
              x != (globalInterno.plataformasVenta.findIndex(y => y.id == '2') >= 0 ? '' : 'Taquilla') &&
              x != (globalInterno.plataformasVenta.findIndex(y => y.id == '3') >= 0 ? '' : 'Automatica')); //'Nombre',
            this.columnsToDisplay = this.displayedColumns.slice();
            this.displayedFilters = [this.translation.FechaCompra, this.translation.FechaVisita, this.translation.Taquilla, this.translation.Internet, this.translation.TaquillaAutomatica, this.translation.ALaVenta, this.translation.TipoDeEntrada];
            this.filtersToDisplay = this.displayedFilters.slice();
            //No sabemos si todos los elementos tiene todos los idiomas, ni siquiera si los comparten, nos vemos obligados a recorrerlos
            //   para saber las columnas que necesitamos
            ListaTarifa.forEach(elementoGR => {
              if (elementoGR.Idiomas) {
                elementoGR.Idiomas.forEach(elementoI => {
                  if (!(this.columnasIdiomas.filter(x => x.CodIdioma == elementoI.CodIdioma).length > 0)) {
                    this.columnasIdiomas.push(<ColumnaIdiomas>({ textoHeader: 'Nombre', CodIdioma: elementoI.CodIdioma }));
                    this.displayedColumns.push(elementoI.CodIdioma + '_Nombre');
                    this.columnsToDisplay.push(elementoI.CodIdioma + '_Nombre');
                    this.columnasIdiomas.push(<ColumnaIdiomas>({ textoHeader: 'Descripcion', CodIdioma: elementoI.CodIdioma }));
                    this.displayedColumns.push(elementoI.CodIdioma + '_Descripcion');
                    //No metemos la descripcion en las columnas a mostrar
                  }
                });
              }
            });
            //this.columnsToDisplay = this.displayedColumns.slice();
            //F Idiomas
          }
          else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );

    this.isLoadingResults = false;
    return resultado;
  }

  //TRY MAP dictionary 2 Array
  mapDiccionario2Array<T>(diccionario: Map<string, T>): Array<T> {
    let arrayLista = new Array<T>();
    try {
      for (var element in diccionario) {
        arrayLista.push(diccionario[element]);
      }
      //diccionario.forEach(element => {
      //  arrayLista.push(element);
      //});
      return arrayLista;
    }
    catch (Error) {
      alert(Error.message);
    }
    finally { return arrayLista }
  }
  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): Tarifas {
    let respuesta = new Tarifas;
    // respuesta.Activo = item.Activo?"True":"False";//item.Seleccionable?"True":"False";
    // respuesta.Nombre = item.Nombre;
    // respuesta.subGrupo = item.subGrupo?"True":"False";
    // respuesta.PkId = item.PkId;
    // respuesta.CentroId = item.CentroId;
    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI.delTarifas(
          this.model.PkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente")
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== this.model.PkId);
              }
              else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => { this.alertaServicio.error(error); }
          );
        this.editando = false;
      }
    });
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: this.ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == "SI") {
        var resultado: string = "";
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delTarifasAsync(
              element.PkId)
              .then(
                data => {
                  //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== element.PkId);
                  }
                  else {
                    resultado += this.respuestaAPI.Mensajes[0].DescripcionMensaje + " <BR />";
                  }
                },
                error => { resultado += error + " <BR />"; }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        }
        else {
          this.alertaServicio.success("Datos Borrados Correctamente");
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //   
      }
    }
  }

  duplicar(row) { // duplicar function
    
    this.model = row; //TODO: cambiar por los datos de la fila
    if (this.model.PkId != null) {
      this.model.PkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
    this.editando = true;
    this.edicionPkId = "";
    this.alertaServicio.limpiar();
    this.newEditar(row)
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        this.servicioAPI.delTarifas(
          pkId)
          .pipe(first())
          .subscribe(
            data => {
              //this.respuestaAPI = new ItemMotGrupo_Respuesta().deserialize(data); WOIT
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success("Datos Borrados Correctamente")
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.PkId !== pkId);
              }
              else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => { this.alertaServicio.error(error); }
          );
        this.editando = false;
      }
    });
  }

  ///////////////////////
  // FECHAS
  fechaApiToFechaFront(fecha: string) {
    let split1 = fecha.split(' ');
    let split2 = split1[0].split('/')
    let ans = split2[2] + '-' + split2[1] + '-' + split2[0];
    return ans;
  }

  fechaFrontToFechaApi(fecha: Date) {
    
    const month = (fecha.getMonth() + 1).toString().length === 2 ? (fecha.getMonth() + 1).toString() : '0' + fecha.getMonth().toString();
    const day = (fecha.getDate() + 1).toString().length === 2 ? (fecha.getDate() + 1).toString() : '0' + fecha.getDate().toString();
    
    
    
    return (day + '/' + month + '/' + fecha.getFullYear()).toString();
    // dia mes año
    /* let split1 = fecha.split('-');
    let ans = split1[2] + '/' + split1[1] + '/' + split1[0] + ' 0:00:00';
    return ans; */
  }
  /////////////////////
  /////////////////////

  newGuardar() {
    
    

    if (this.editorRegistroForm.value.tipoControlPlataforma === null) {
      this.editorRegistroForm.value.tipoControlPlataforma = [];
    }
    if (this.editorRegistroForm.value.tipoControlDias === null) {
      this.editorRegistroForm.value.tipoControlDias = [];
    }
    
    
    
    

    // const d: Date = this.editorRegistroForm.get('fechaDesdeVisita').value;

    // this.fechaFrontToFechaApi(d);

    const tipoControlproductoAsociado = this.editorRegistroForm.get('tipoControlproductoAsociado').value === "2";
    let bimpEmpty;
    let bimpcEmpty;
    if (tipoControlproductoAsociado) {
      bimpEmpty = 0;
      bimpcEmpty = 0;
      this.TarifaPromocion.forEach(tarifa => {
        bimpEmpty += parseInt(this.editorRegistroForm.get('baseImponible' + tarifa.Pkid).value);
        bimpcEmpty += parseInt(this.editorRegistroForm.get('baseImponibleComision' + tarifa.Pkid).value);
      })
    }


    let body = {
      "PkId": this.edicionPkId !== '' ? this.edicionPkId : '0',
      "Internet": this.editorRegistroForm.value.tipoControlPlataforma.includes('2') ? '1' : '0',
      "Taquilla": this.editorRegistroForm.value.tipoControlPlataforma.includes('1') ? '1' : '0',
      "TaqAutomatica": this.editorRegistroForm.value.tipoControlPlataforma.includes('3') ? '1' : '0',
      "IntAdmon": this.editorRegistroForm.value.tipoControlPlataforma.includes('4') ? '1' : '0',
      "AlaVenta": this.editorRegistroForm.value.activa === true ? '1' : '0',
      "iTipoEntradaId": this.editorRegistroForm.value.tipoControlproductoAsociado === '1' ? this.editorRegistroForm.value.tipoControlproductoAsociadoEntrada.EntradaId : '0',
      "iTipoPromocionId": this.editorRegistroForm.value.tipoControlproductoAsociado === '2' ? this.editorRegistroForm.value.tipoControlproductoAsociadoPaquete.pkId : '0',
      "NoFecFinVisita": this.editorRegistroForm.value.sinFechaFin === true ? '1' : '0',
      "PrecioPVP": this.editorRegistroForm.value.pvp,
      "PorcIva": tipoControlproductoAsociado ? "0" : this.getPorcIva(this.editorRegistroForm.value.tipoIVA), // falla
      "Comision": this.editorRegistroForm.value.pvpc,
      "PorIvaComision": tipoControlproductoAsociado ? "0" : this.getPorcIva(this.editorRegistroForm.value.tipoControlTIVAComision), // falla
      "iDivisaId": this.tipoControlDivisas.value !== '' ? this.tipoControlDivisas.value : this.globales.divisapordefectoSistema,
      "iTipoAbonadoId": this.editorRegistroForm.value.tipoControlproductoAsociado === '3' ? this.editorRegistroForm.value.tipoControlproductoAsociadoAbono : '0',
      "Agrupar": this.editorRegistroForm.value.agrupada === true ? '1' : '0',
      "Orden": this.editorRegistroForm.value.orden,
      "Minimo": this.editorRegistroForm.value.minimo,
      "Maximo": this.editorRegistroForm.value.maximo,
      "iGrupoInternetId": this.editorRegistroForm.value.tipoGrupoInternet ? this.editorRegistroForm.value.tipoGrupoInternet.pkId : "",

      "FecIniVisita": moment((this.editorRegistroForm.get('fechaDesdeVisita').value)).format('DD/MM/YYYY 0:00:00'),
      "FecFinVisita": this.editorRegistroForm.value.sinFechaFin === true ? "" : moment((this.editorRegistroForm.get('fechaHastaVisita').value)).format('DD/MM/YYYY 0:00:00'),
      "FecCompraDesde": moment((this.editorRegistroForm.get('fechaDesdeCompra').value)).format('DD/MM/YYYY 0:00:00'),
      "FecCompraHasta": moment((this.editorRegistroForm.get('fechaHastaCompra').value)).format('DD/MM/YYYY 0:00:00'),

      "HoraIni": this.editorRegistroForm.value.horarioDeVisitaInicio,
      "HoraFin": this.editorRegistroForm.value.horarioDeVisitaFin,
      "iTipoIvaId": tipoControlproductoAsociado ? "0" : this.editorRegistroForm.value.tipoIVA,
      "iTipoIvaComisionId": tipoControlproductoAsociado ? "0" : this.editorRegistroForm.value.tipoControlTIVAComision,
      "BaseImponible": this.editorRegistroForm.value.tipoControlproductoAsociado === '2' ? '0' : this.editorRegistroForm.value.bimp,
      "ComisionBI": this.editorRegistroForm.value.tipoControlproductoAsociado === '2' ? '0' : this.editorRegistroForm.value.bimpc,
      "DesglosarComision": "0",
      "Lunes": this.editorRegistroForm.value.tipoControlDias.includes('1') ? '1' : '0',
      "Martes": this.editorRegistroForm.value.tipoControlDias.includes('2') ? '1' : '0',
      "Miercoles": this.editorRegistroForm.value.tipoControlDias.includes('3') ? '1' : '0',
      "Jueves": this.editorRegistroForm.value.tipoControlDias.includes('4') ? '1' : '0',
      "Viernes": this.editorRegistroForm.value.tipoControlDias.includes('5') ? '1' : '0',
      "Sabado": this.editorRegistroForm.value.tipoControlDias.includes('6') ? '1' : '0',
      "Domingo": this.editorRegistroForm.value.tipoControlDias.includes('7') ? '1' : '0',
      "chPrefijo": this.editorRegistroForm.value.chPrefijo,
      "Idiomas": this.editorListaIdiomas,
      "Funcion": this.model.PkId ? 'U' : 'I',
      "AsumeIncrementoIVA": ""
    };

    
    


    if (this.editorRegistroForm.value.tipoControlproductoAsociado === '2') {
      let bodyTarifaPromocion = [];
      this.TarifaPromocion.forEach(element => {
        
        const idiomas = [];
        let IvaComision = "0";
        let PorcIva = "0";
        this.tiposIVACalendario.forEach(iva => {
          if (this.editorRegistroForm.get('tipoIva' + element.Pkid).value === iva.PkId) {
            IvaComision = iva.Iva;
          }
          if (this.editorRegistroForm.get('tipoIvaComision' + element.Pkid).value === iva.PkId) {
            PorcIva = iva.Iva
          }
        });
        this.tiposIdiomas.forEach(idioma => {
          idiomas.push({
            Nombre: this.editorRegistroForm.get('nombre' + idioma.CodIdioma + element.Pkid).value,
            Descripcion: this.editorRegistroForm.get('descripcion' + idioma.CodIdioma + element.Pkid).value,
            CodIdioma: idioma.CodIdioma
          });
        });
        let newTarifa = {
          "Pkid": element.Pkid,
          "iTarifaId": this.edicionPkId !== '' ? this.edicionPkId : '0',
          "iTipoEntradaId": element.iTipoEntradaId,
          "PorcIva": PorcIva, // iva comision ej 21%
          "iTipoBonoEntradaId": element.iTipoBonoEntradaId ? element.iTipoBonoEntradaId : element.iTipoBonoEntradasId,
          "iTipoIvaId": this.editorRegistroForm.get('tipoIva' + element.Pkid).value, // iva ID
          "Precio": this.editorRegistroForm.get('pvp' + element.Pkid).value,
          "BaseImponible": this.editorRegistroForm.get('baseImponible' + element.Pkid).value,
          "NumEntradas": element.iNumEntradas ? element.iNumEntradas : element.NumEntradas,
          "NomTipoEntrada": element.NomTipoEntrada,
          "Porcentaje": element.rPorcentaje ? element.rPorcentaje : element.Porcentaje, // porcentaje que sale en la tabla
          "TipoIVAComisionId": this.editorRegistroForm.get('tipoIvaComision' + element.Pkid).value, // iva comision ID
          "IvaComision": IvaComision, // numero de IVA comision ej 10%
          "ComisionBI": this.editorRegistroForm.get('baseImponibleComision' + element.Pkid).value,
          "Comision": this.editorRegistroForm.get('pvpComision' + element.Pkid).value,
          "Idiomas": idiomas,
          "rPorcentaje": element.rPorcentaje,
        }
        bodyTarifaPromocion.push(newTarifa);
      });
      body['TarifaPromocion'] = bodyTarifaPromocion;
    }

    

    this.servicioAPI.InsertarActualizarCustomanTarifas(body).subscribe(res => {
      
      if (res.DatosResult != null) {
        this.editando = false;
        this.alertaServicio.success("Datos Guardados Correctamente")
        this.cargarDatosAsync();
      } else {
        this.alertaServicio.error(res.Mensajes[0].DescripcionMensaje);
        //this.editando = false;
      }

    });

  }

  /*actualizarIdiomaPromocion(pkId, campo, valor) {
    this.TarifaPromocion.forEach(element => {
      if (element.Pkid === pkId) {
        switch (campo) {
          case "NombreESES": element.Idiomas.NombreESES = valor; break;
          case "NombreCAES": element.Idiomas.NombreCAES = valor; break;
          case "NombreDEDE": element.Idiomas.NombreDEDE = valor; break;
          case "NombreENGB": element.Idiomas.NombreENGB = valor; break;
          case "DescripcionESES": element.Idiomas.DescripcionESES = valor; break;
          case "DescripcionCAES": element.Idiomas.DescripcionCAES = valor; break;
          case "DescripcionDEDE": element.Idiomas.DescripcionDEDE = valor; break;
          case "DescripcionENGB": element.Idiomas.DescripcionENGB = valor; break;
        }
      }
    });
  }*/

  actualizarTarifaPromocion(event, pkid, field) {
    let inputValue = event.data;
    
    
    
    
    switch (field) {
      case field === 2:
        inputValue = event.value;
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.iTipoIvaId = inputValue;
            switch (item.iTipoIvaId) {
              case '0': item.PorcIva = '0'; break;
              case '1': item.PorcIva = '21'; break;
              case '2': item.PorcIva = '10'; break;
            }
            item.Precio = (+item.BaseImponible * ((+item.PorcIva / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
      case field === 3:
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.Precio = inputValue;
            item.BaseImponible = (+item.Precio / ((+item.PorcIva / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
      case field === 4:
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.BaseImponible = inputValue;
            item.Precio = (+item.BaseImponible * ((+item.PorcIva / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
      case field === 5:
        inputValue = event.value;
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.TipoIVAComisionId = inputValue;
            switch (item.TipoIVAComisionId) {
              case '0': item.PorcIvaComision = '0'; break;
              case '1': item.PorcIvaComision = '21'; break;
              case '2': item.PorcIvaComision = '10'; break;
            }
            item.Comision = (+item.ComisionBI * ((+item.PorcIvaComision / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
      case field === 6:
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.Comision = inputValue;
            item.ComisionBI = (+item.Comision / ((+item.PorcIvaComision / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
      case field === 7:
        this.TarifaPromocion.forEach(item => {
          if (item.Pkid === pkid) {
            item.ComisionBI = inputValue;
            item.Comision = (+item.ComisionBI * ((+item.PorcIvaComision / 100) + 1)).toFixed(2).toString();
          }
        });
        break;
    }
    if (this.PVKSelected || this.TaqAutSelected) {
      this.recalcularBaseImponibleTarifa();
      this.recalcularBaseImponibleComisionTarifa();
      this.recalcularPVPTarifa();
      this.recalcularPVPComisionTarifa();
      // this.cambiapvp();
    }
  }

  actualizarPlataformasSeleccionadas() {
    // 1 - Punto Venta Kore
    // 2 - Internet
    // 3 - Taquilla Automática
    // 4 - Administración

    this.disableAdministracion = false;
    this.disableInternet = false;
    const tipoPlataforma = this.editorRegistroForm.get('tipoControlPlataforma').value;
    tipoPlataforma.forEach(tipo => {
      if (tipo === "2") {
        this.disableAdministracion = true;
        return;
      }
      if (tipo === "4") {
        this.disableInternet = true;
        return;
      }
    })


    this.PVKSelected = this.editorRegistroForm.controls['tipoControlPlataforma'].value.includes('1') ? true : false;
    this.IntSelected = this.editorRegistroForm.controls['tipoControlPlataforma'].value.includes('2') ? true : false;
    this.TaqAutSelected = this.editorRegistroForm.controls['tipoControlPlataforma'].value.includes('3') ? true : false;
    this.AdminSelected = this.editorRegistroForm.controls['tipoControlPlataforma'].value.includes('4') ? true : false;
    this.campoGrupoInternet();

    if (this.editorRegistroForm.controls['tipoControlPlataforma'].value.includes('2')) {
      this.filteredInternet = this.editorRegistroForm.controls['tipoGrupoInternet'].valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.Nombre),
          map(Nombre => Nombre ? this._filterInternet(Nombre) : this.tiposGInternet.slice())
        );
    }
  }

  actualizarProductoSeleccionado() {
    this.platSelected = this.editorRegistroForm.controls['tipoControlproductoAsociado'].value;
    if (this.platSelected == '2') {
      this.editorRegistroForm.controls['tipoControlTIVAComision'].clearValidators();
      this.editorRegistroForm.controls['tipoControlTIVAComision'].updateValueAndValidity();
      this.editorRegistroForm.controls['tipoIVA'].clearValidators();
      this.editorRegistroForm.controls['tipoIVA'].updateValueAndValidity();
    } else {
      this.editorRegistroForm.controls['tipoControlTIVAComision'].setValidators([Validators.required]);
      this.editorRegistroForm.controls['tipoIVA'].setValidators([Validators.required]);
    }

    this.filteredEntradas = this.editorRegistroForm.controls['tipoControlproductoAsociadoEntrada'].valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.TextoPantalla),
        map(TextoPantalla => TextoPantalla ? this._filter(TextoPantalla) : this.ProductosEntrada.slice())
      );

    this.filteredProductos = this.editorRegistroForm.controls['tipoControlproductoAsociadoPaquete'].valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.chTextoPantalla),
        map(chTextoPantalla => chTextoPantalla ? this._filterPaquete(chTextoPantalla) : this.ProductosPaquete.slice())
      );// herere
  }
  /////////////////
  //////// autocomplete
  private _filter(TextoPantalla: string) {
    const filterValue = TextoPantalla.toLowerCase();
    
    return this.ProductosEntrada.filter(option => option.TextoPantalla.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterPaquete(chTextoPantalla: string) {
    const filterValue = chTextoPantalla.toLowerCase();
    
    return this.ProductosPaquete.filter(option => option.chTextoPantalla.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterInternet(Nombre: string) {
    const filterValue = Nombre.toLowerCase();
    
    return this.tiposGInternet.filter(option => option.Nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(entrada) {
    return entrada && entrada.TextoPantalla ? entrada.TextoPantalla : '';
  }

  displayFnProducto(producto) {
    return producto && producto.chTextoPantalla ? producto.chTextoPantalla : '';
  }

  displayFnInternet(internet) {
    return internet && internet.Nombre ? internet.Nombre : '';
  }

  ////////////////////////
  ////////////////////////
  ////////////////////////

  actualizarProductoEntrada(entradaId) {
    
    
  }

  actualizarProductoPaquete(paqueteId) { // al elegir paquete
    
    
    
    paqueteId = paqueteId.option.value.pkId;
    console.log("---------paqueteId: ",paqueteId)

    this.ProductosPaquete.forEach(element => {
      if (element.pkId === paqueteId) {
        console.log("---------element: ",element)

        // API CALL
        this.servicioAPI.getTiposBonoAsync(paqueteId).then(res => {
          
          console.log("---------res: ",res)

          var item = res.DatosResult.lista[0].TipoBonoEntadas;
          this.TarifaPromocion = [];
          item.forEach((element) => {
            let entrada = {
              Pkid: element.iTipoBonoEntradasId,
              NomTipoEntrada: element.NombreTipoEntrada,
              NombreTipoEntrada: element.NombreTipoEntrada,
              dtFechaFin: element.dtFechaFin,
              dtFechaInicio: element.dtFechaInicio,
              iNumEntradas: element.iNumEntradas,
              iTipoBonoEntradasId: element.iTipoBonoEntradasId,
              iTipoBonoId: element.iTipoBonoId,
              iTipoEntradaId: element.iTipoEntradaId,
              rPorcentaje: element.rPorcentaje,
              TipoIVA: '0',
              PorcIva: '0',
              Precio: '0',
              BaseImponible: '0',
              TipoIVAComision: '0',
              PorcIvaComision: '0',
              Comision: '0',
              ComisionBI: '0',
              /* falta idiomas */
            };
            this.TarifaPromocion.push(entrada);
          });
          
          // importantt
          this.TarifaPromocion.forEach(tarifa => { // al elegir tipo de paquete crear campos tabla
            
            // campos tabla
            this.editorRegistroForm.addControl('pvp' + tarifa.Pkid, new FormControl('0', [Validators.min(0)]));
            this.editorRegistroForm.addControl('pvpComision' + tarifa.Pkid, new FormControl('0', [Validators.min(0)]));
            this.editorRegistroForm.addControl('tipoIva' + tarifa.Pkid, new FormControl('0'));
            this.editorRegistroForm.addControl('tipoIvaComision' + tarifa.Pkid, new FormControl('0'));
            this.editorRegistroForm.addControl('baseImponible' + tarifa.Pkid, new FormControl('0', [Validators.min(0)]));
            this.editorRegistroForm.addControl('baseImponibleComision' + tarifa.Pkid, new FormControl('0', [Validators.min(0)]));
            this.tiposIdiomas.forEach(idioma => { // campos idoma dentro tabla (solo idiomas de cada paquete)
              this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma + tarifa.Pkid, new FormControl(''));
              this.editorRegistroForm.addControl('descripcion' + idioma.CodIdioma + tarifa.Pkid, new FormControl(''));
            });
          })
        });
      }
    });
    /*this.editorListaIdiomas.forEach(idioma => { // campos idioma FUERA TABLA (todos los idiomas posibles)
      this.editorRegistroForm.addControl('nombre' + idioma.CodIdioma, new FormControl(''));
      this.editorRegistroForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(''));
    });*/

  }

  actualizarProductoAbono(abonoId) {
    
  }

  recalcularPVPTarifa() {
    let newPvp = 0;
    this.TarifaPromocion.forEach(element => {
      let x = parseInt(this.editorRegistroForm.get('pvp' + element.Pkid).value) * parseInt(element.iNumEntradas);
      if (x) {
        newPvp += x;
      }
      //newPvp = newPvp + (+element.Precio * (+element.rPorcentaje / 100));
    });
    this.editorRegistroForm.controls['pvp'].setValue(newPvp.toFixed(2).toString());
    this.editorRegistroForm.patchValue({
      pvp: newPvp.toFixed(2).toString()
    })
  }

  recalcularBaseImponibleTarifa() {
    /*let newBi = 0;
    this.TarifaPromocion.forEach(element => {
      newBi = newBi + (+element.BaseImponible * (+element.rPorcentaje / 100));
    });
    this.editorRegistroForm.patchValue({
      bimp: newBi.toFixed(2).toString()
    })*/
    let pvp = this.editorRegistroForm.get('pvp').value;
    let tipoIva = this.editorRegistroForm.get('tipoIVA').value;
    this.tiposIVA.forEach(iva => {
      if (iva.PkId === tipoIva) {
        tipoIva = iva.Descripcion.split(' ')[1];
        tipoIva = tipoIva.slice(0, tipoIva.length - 1)
        this.editorRegistroForm.controls['bimp'].setValue(pvp - (pvp * parseInt(tipoIva) / 100));
        return;
      }
    });
  }

  reasignarTipoIVAEntradas(iva) {
    
    this.editorRegistroForm.patchValue({
      tipoIVA: iva
    })
    if (this.platSelected === '2') {
      this.TarifaPromocion.forEach(element => {
        
        element.TipoIVA = iva;
        switch (iva) {
          case '0': element.PorcIva = '0'; break;
          case '1': element.PorcIva = '21'; break;
          case '2': element.PorcIva = '10'; break;
        }
        // this.editorRegistroForm.controls['tipoIva' + element.Pkid].setValue(iva);
      });
    }
    this.recalcularBaseImponibleTarifa();
  }

  recalcularPVPComisionTarifa() {
    let newPvpC = 0;
    this.TarifaPromocion.forEach(element => {
      let x = newPvpC + parseInt(this.editorRegistroForm.get('pvpComision' + element.Pkid).value) * parseInt(element.iNumEntradas);
      if (x) {
        newPvpC += x;
      }
      newPvpC = newPvpC + (+element.Comision * (+element.rPorcentaje / 100));
    });
    this.editorRegistroForm.controls['pvpc'].setValue(newPvpC.toFixed(2).toString());
    this.editorRegistroForm.patchValue({
      pvpc: newPvpC.toFixed(2).toString()
    })
  }

  recalcularBaseImponibleComisionTarifa() {
    /*let newBimpC = 0;
    this.TarifaPromocion.forEach(element => {
      newBimpC = newBimpC + (+element.ComisionBI * (+element.rPorcentaje / 100));
    });
    this.editorRegistroForm.patchValue({
      bimpc: newBimpC.toFixed(2).toString()
    })*/

    let pvp = this.editorRegistroForm.get('pvpc').value;
    let tipoIva = this.editorRegistroForm.get('tipoControlTIVAComision').value;
    this.tiposIVA.forEach(iva => {
      if (iva.PkId === tipoIva) {
        tipoIva = iva.Descripcion.split(' ')[1];
        tipoIva = tipoIva.slice(0, tipoIva.length - 1)
        this.editorRegistroForm.controls['bimpc'].setValue(pvp - (pvp * parseInt(tipoIva) / 100));
        return;
      }
    });
  }

  reasignarTipoIVAComisionEntradas(iva) {
    this.editorRegistroForm.patchValue({
      tipoControlTIVAComision: iva.value
    })
    if (this.platSelected === '2') {
      this.TarifaPromocion.forEach(element => {
        element.TipoIVAComision = this.getPorcIva(iva.value);
      });
    }

    this.recalcularBaseImponibleComisionTarifa();
    /*this.TarifaPromocion.forEach(element => {
      this.editorRegistroForm.controls['tipoIvaComision' + element.Pkid].setValue(iva.value);
    });*/
  }

  getPorcIva(id) {
    switch (id) {
      case '0': return '0';
      case '1': return '21';
      case '2': return '10';
      default: return '0';
    }
  }


}
