import { Component, Input, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Tpvs } from '@sharedV11/classes/maestros/tpvs/tpvs';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { EntryTypeService } from '@sharedV11/services/components/entradas-y-promociones/entry-type/entry-type.service';
import { EditTpvsService } from '@sharedV11/services/components/Tpvs/edit-tpvs.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { DualListComponent } from 'angular-dual-listbox';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_FORMATOS_FECHA } from '@sharedV11/classes/dataAdapter/date-format.adapter';
import { MatDialog } from '@angular/material/dialog';
import { DialogoPopUp } from '@sharedV11/components/alert/dialog/popup.service';
import { AuthenticationService } from '@sharedV11/services/authentication/authentication.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';

@Component({
  selector: 'app-edit-tpvs',
  templateUrl: './edit-tpvs.component.html',
  styleUrls: ['./edit-tpvs.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA
    },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})


export class EditTpvsComponent implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;

  editRegistroForm: FormGroup;

  Tpv:Tpvs;
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  abonadosSinAsignar = [];
  abonadosAsignadas = [];

  CentrosAPI: any;
  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  //AsociarCentros = "0";
  
  GruposTipoBonoAPI:any;
  GruposTipoBonoAsignados = [];
  GruposTipoBonoSinAsignar = [];

  GruposTipoEntradasAPI:any;
  GruposTipoEntradasAsignados = [];
  GruposTipoEntradasSinAsignar = [];

  GruposTipoAbonadosAPI:any;
  GruposTipoAbonadosAsignados = [];
  GruposTipoAbonadosSinAsignar = [];
  cajas: any;
  fechacajas = new Date();
  loadingcajas: boolean = true;
  Botones: any[];
  lugares: any[];
  Abonadosloaded: boolean = false;
  Centrosloaded: boolean = false;

  constructor(private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private editTpvsService:EditTpvsService,
    private entryTypeService: EntryTypeService,
    private snackvar_service: SnackbarInfoService,
    public dialog: MatDialog,
    private autentificador: AuthenticationService,
    private servicioAPI: ConectarApiService,
    private gsCentros: GlobalServicCentros
    ) { }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.format = { add: this.translation.Anadir, remove: this.translation.Borrar, all: this.translation.Todos, none: this.translation.Ninguno, direction: DualListComponent.LTR, draggable: true, locale: 'es' };
    this.manageForm(this.pkId, this.mode);
    //this.AsociarCentros = JSON.parse(localStorage.getItem('AsociarCentros'));
    this.Botones = this.autentificador.getPermisosComoBotones();
    console.log("Botones: ", this.Botones);
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeFormWithoutData() {

    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Nombre: ["", Validators.required],
      Descripcion: [""],
      LugarId: ["0"],
      DireccionIP:[""],
      VMPath:[""],
      //NombrePc:[""],
      DeBaja:[""],
      NumTotTickets:[""],
      Asignado:[""],
      DivisaPrimaria:[""],
      CentrosIds:[""]
    
    });
  }

  manageForm(pkId, mode) {

    this.servicioAPI.getLugares().subscribe (data => {
      this.lugares = data.DatosResult.customanLugares;
      console.log("LUGARES:" , this.lugares);
    });
    

    if (mode == "I") {
      this.Tpv = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.Tpv);
      this.servicioAPI.getGrupoTipoBono().subscribe(data => {
        this.GruposTipoBonoAPI = data;
        let GruposTipoBonos = [];
          if (this.GruposTipoBonoAPI.DatosResult != null) {
            if (this.GruposTipoBonoAPI.DatosResult.customanGrupoTipoBono != null) {
              GruposTipoBonos = this.GruposTipoBonoAPI.DatosResult.customanGrupoTipoBono;
              GruposTipoBonos.forEach(gte => this.GruposTipoBonoSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
            }
          }
        },
          error => { this.httpErrorService.identificarErrores(error); }
      );

      this.servicioAPI.getGruposTiposEntrada().subscribe(data => {
        this.GruposTipoEntradasAPI = data;
        let GruposTipoEntradas = [];
          if (this.GruposTipoEntradasAPI.DatosResult != null) {
            if (this.GruposTipoEntradasAPI.DatosResult.customanGrupoTipoEntrada != null) {
              GruposTipoEntradas = this.GruposTipoEntradasAPI.DatosResult.customanGrupoTipoEntrada;
              GruposTipoEntradas.forEach(gte => this.GruposTipoEntradasSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
            }
          }
        },
          error => { this.httpErrorService.identificarErrores(error); }
      );


      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findTpv(pkId)
    }
  }

  findTpv(pkId) {

    this.editTpvsService.findTpvByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(TpvApi => {
      // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
      console.log(TpvApi)
      let Tpv: Tpvs = TpvApi["DatosResult"]["ListaTPVs"][0]

      if (Tpv != null) {
      
        this.changeValueForm(Tpv)
        this.Tpv = this.editRegistroForm.value;
        this.Tpv.CentrosIds = Tpv.CentrosIds;
        this.valuedChanged();
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.Tpv);
        //if (Tpv.Centros) Tpv.Centros.forEach(gte => this.centrosAsignados.push({"pkId":gte.pkId,"Nombre":gte.Nombre}))
        if (Tpv.GruposTipoBono) Tpv.GruposTipoBono.forEach(gte => this.GruposTipoBonoAsignados.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
        if (Tpv.GruposTipoEntradas) Tpv.GruposTipoEntradas.forEach(gte => this.GruposTipoEntradasAsignados.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
        if (Tpv.GruposTipoAbonados) Tpv.GruposTipoAbonados.forEach(gte => this.GruposTipoAbonadosAsignados.push({"pkId":gte.pkId,"Nombre":gte.Nombre}))

        console.log("this.centrosAsignados", this.centrosAsignados, this.GruposTipoAbonadosAsignados);        
        this.Abonadosloaded = true;
        this.Centrosloaded = true;
        
        this.loading = false;
      }

/*       this.servicioAPI.getLugares().subscribe (data => {
        this.lugares = data.DatosResult.customanLugares;
        console.log("LUGARES:" , this.lugares);
      });
       */

/*       this.entryTypeService.getCentros().subscribe(data => {
        this.CentrosAPI = data;
        let tipoCentros = [];
          if (this.CentrosAPI.DatosResult != null) {
            if (this.CentrosAPI.DatosResult.ListaCentros != null) {
              tipoCentros = this.CentrosAPI.DatosResult.ListaCentros;
              tipoCentros.forEach(gte => this.centrosSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.Nombre}))
            }
          }
        },
          error => { this.httpErrorService.identificarErrores(error); }
        ); */

        this.servicioAPI.getGrupoTipoBono().subscribe(data => {
          this.GruposTipoBonoAPI = data;
          let GruposTipoBonos = [];
            if (this.GruposTipoBonoAPI.DatosResult != null) {
              if (this.GruposTipoBonoAPI.DatosResult.customanGrupoTipoBono != null) {
                GruposTipoBonos = this.GruposTipoBonoAPI.DatosResult.customanGrupoTipoBono;
                GruposTipoBonos.forEach(gte => this.GruposTipoBonoSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
              }
            }
          },
            error => { this.httpErrorService.identificarErrores(error); }
        );

        this.servicioAPI.getGruposTiposEntrada().subscribe(data => {
          this.GruposTipoEntradasAPI = data;
          let GruposTipoEntradas = [];
            if (this.GruposTipoEntradasAPI.DatosResult != null) {
              if (this.GruposTipoEntradasAPI.DatosResult.customanGrupoTipoEntrada != null) {
                GruposTipoEntradas = this.GruposTipoEntradasAPI.DatosResult.customanGrupoTipoEntrada;
                GruposTipoEntradas.forEach(gte => this.GruposTipoEntradasSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.NombreGrupo}))
              }
            }
          },
            error => { this.httpErrorService.identificarErrores(error); }
        );
          
/*         this.servicioAPI.getTiposAbonados().subscribe(data => {
          this.GruposTipoAbonadosAPI = data;
          let GruposTipoAbonados = [];
            if (this.GruposTipoAbonadosAPI.DatosResult != null) {
              if (this.GruposTipoAbonadosAPI.DatosResult.Lista != null) {
                GruposTipoAbonados = this.GruposTipoAbonadosAPI.DatosResult.Lista;
                GruposTipoAbonados.forEach(gte => this.GruposTipoAbonadosSinAsignar.push({"pkId":gte.pkId,"Nombre":gte.Nombre}))
              }
            }
          },
            error => { this.httpErrorService.identificarErrores(error); }
        );      
 */


        this.servicioAPI.getTPVCajas(Tpv.pkId).subscribe(data => {
          
          this.cajas = data.DatosResult.Listado;
          //this.cajas = JSON.parse('[{ "pkId":3310, "Codigo":127, "FechaInicio":"22/10/2021 9:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3320, "Codigo": 128, "FechaInicio": "22/10/2021 10:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" } ]');
          this.loadingcajas = false;
          console.log("Data: ", this.cajas);
          },
            error => { this.httpErrorService.identificarErrores(error); }
        );  

    },
      error => { this.httpErrorService.identificarErrores(error); }
    )


  }

  selectionChange(eop) {

    console.log("eop", eop._element);
  }

  seguro(e,pkId) {

    if (pkId != null) {//Si no estamos añadiendo
      let titulo_dialogo = this.translation.CerrarCaja;
      let mensaje_dialogo = this.translation.EstaSeguroQueDeseaCerrarEstaCaja;
      let ok_dialogo = this.translation.Si;
      let ko_dialogo = this.translation.Cancelar;
      this.openDialog(pkId,titulo_dialogo,mensaje_dialogo,ok_dialogo,ko_dialogo)
    }
  }

  openDialog(pkId,titulo_dialogo,mensaje_dialogo,ok_dialogo,ko_dialogo): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { pkId: pkId, titulo: titulo_dialogo, texto: mensaje_dialogo, ok: ok_dialogo, ko: ko_dialogo }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.cajas = [];
      this.loadingcajas = true;
      //console.log(result);
      if (result == "SI") {
        //Salvar a API
        this.servicioAPI.setTPVCerrarCaja(JSON.parse(sessionStorage.getItem('currentUser')).DatosResult.Id,pkId,moment().format('YYYY/MM/DD HH:mm:ss'),this.Tpv.DivisaPrimaria).subscribe(datacierre => {
          this.servicioAPI.getTPVCajas(this.Tpv.pkId).subscribe(data => {
          
            this.cajas = data.DatosResult.Listado;
            //this.cajas = JSON.parse('[{ "pkId":3310, "Codigo":127, "FechaInicio":"22/10/2021 9:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3320, "Codigo": 128, "FechaInicio": "22/10/2021 10:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" } ]');
            this.loadingcajas = false;
            console.log("Data: ", this.cajas);
            },
              error => { this.httpErrorService.identificarErrores(error); }
          );  
        });

      } else {
        this.servicioAPI.getTPVCajas(this.Tpv.pkId).subscribe(data => {
          
          this.cajas = data.DatosResult.Listado;
          //this.cajas = JSON.parse('[{ "pkId":3310, "Codigo":127, "FechaInicio":"22/10/2021 9:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3320, "Codigo": 128, "FechaInicio": "22/10/2021 10:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" } ]');
          this.loadingcajas = false;
          console.log("Data: ", this.cajas);
          },
            error => { this.httpErrorService.identificarErrores(error); }
        );  
      }
    });
  }


  setDate(e) {
    this.servicioAPI.getTPVCajas(this.Tpv.pkId, e.value.toDate()).subscribe(data => {
          
      this.cajas = data.DatosResult.Listado;
      this.loadingcajas = false;
      //this.cajas = JSON.parse('[{ "pkId":3310, "Codigo":127, "FechaInicio":"22/10/2021 9:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3320, "Codigo": 128, "FechaInicio": "22/10/2021 10:00:32", "FechaFin": "", "Estado": "AC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" }, { "pkId": 3321, "Codigo": 129, "FechaInicio": "22/10/2021 11:00:32", "FechaFin": "22/10/2021 12:00:32", "Estado": "CC" } ]');
      console.log("Data: ", this.cajas);
      },
        error => { this.httpErrorService.identificarErrores(error); }
    );  
  }

 


  aFechaddmmyyyy(fecha: string): Date {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {
        //
        //
        return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
      } else {
        return null;
      }
    }
  }

  aFecha(fecha: string): Date {
    // 
    // 
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      var parts = fecha.split("/");
      if (parts[0] != "Invalid date") {

        //
        //return new Date(parseInt(parts[0].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[2]));
        return new Date(parseInt(parts[0].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[2].substring(0, 2)));
      } else {
        return null;
      }
    }
  }

  changeValueForm(Tpv:Tpvs) {
    this.editRegistroForm.get("pkId").setValue(Tpv.pkId);
   
    this.editRegistroForm.get("Nombre").setValue(Tpv.Nombre);
    this.editRegistroForm.get("Descripcion").setValue(Tpv.Descripcion);
    this.editRegistroForm.get("LugarId").setValue(Tpv.LugarId.toString());
    this.editRegistroForm.get("VMPath").setValue(Tpv.VMPath);
    this.editRegistroForm.get("DireccionIP").setValue(Tpv.DireccionIP);
    //this.editRegistroForm.get("NombrePC").setValue(Tpv.NombrePC);
    this.editRegistroForm.get("DeBaja").setValue(Tpv.DeBaja);
    this.editRegistroForm.get("NumTotTickets").setValue(Tpv.NumTotTickets);
    
    
    this.editRegistroForm.get("Asignado").setValue(Tpv.Asignado);
    this.editRegistroForm.get("DivisaPrimaria").setValue(Tpv.DivisaPrimaria);
 
 
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe(value => {
      if (this.Tpv != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.Tpv)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }


  discard() {
    //this.changeValueForm(this.Categoria)
  }

  manageApi(TPV: Tpvs, mode) {
    this.loading = true;

    let TpvData: any = {};
    TpvData.pkId = TPV.pkId;
    TpvData.Nombre = TPV.Nombre;
    TpvData.Descripcion = TPV.Descripcion;
    if (TPV.LugarId=="") TPV.LugarId = "0"; 
    TpvData.LugarId = parseInt(TPV.LugarId);
    TpvData.DireccionIP = TPV.DireccionIP;
    TpvData.DeBaja = TPV.DeBaja ? 1:0;
    TpvData.NumTotTickets = TPV.NumTotTickets ? 1:0;
    TpvData.VMPath =TPV.VMPath;
    TpvData.Asignado = TPV.Asignado;
    //TpvData.TPVIdCentroId = this.centrosAsignados.map(c => c.pkId).join();
    TpvData.TPVIdGrupoTipoBono = this.GruposTipoBonoAsignados.map(c => c.pkId).join();
    TpvData.TPVIdGrupoTipoAbonado =this.GruposTipoAbonadosAsignados.map(c => c.pkId).join();
    TpvData.TPVIdGrupoTipoEntradas = this.GruposTipoEntradasAsignados.map(c => c.pkId).join();

    //TpvData.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    TpvData.CentrosIds = TPV.CentrosIds;
    TpvData.TPVIdCentroId = TPV.CentrosIds;

    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        TpvData.pkId = "0"
        this.InsertTpv(TpvData)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdateTpv(TpvData)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        TpvData.pkId = "0"
        this.InsertTpv(TpvData)

        break;

      default:
        break;
    }
  }


  DelTPV(TPV){
/*     this.editCategoriaService.updCategoria(TPV, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });    */ 
   }

   UpdateTpv(TpvData){

    this.servicioAPI.setTPV(TpvData, "U").subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   

    
  }

   InsertTpv(TpvData){

    this.servicioAPI.setTPV(TpvData, "I").subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   

   }


   nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      console.table(response.Mensajes);
      response.Mensajes.forEach(element => {
        this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
      });
    } else {
      this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
    }
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  submit() {

     this.editRegistroForm.markAllAsTouched()
    let TPV: Tpvs = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(TPV, this.mode)
    } 

  }

  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if(fc_pv){
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }
    
    return "Error";
  }



  validateFields(field: string) {
    return this.isValidAnyField(field);
  }
  
  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }

  closeInputFilter(e) {
    console.log(e);
    this.editRegistroForm.get(e.name).setValue(e.value)
    
  }

}
