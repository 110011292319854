import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditGroupComponent } from './edit-group/edit-group.component';

@Component({
  selector: 'app-internet-group',
  templateUrl: './internet-group.component.html',
  styleUrls: ['./internet-group.component.scss']
})
export class InternetGroupComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.closepanel()

  }

  openPanel(){
    this.customanpanel=EditGroupComponent
    this.editChangeCss()
    this.cdRef.detectChanges();

  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      console.log()
      this.customanpanel = undefined;
     this.closePanelChangeCss()
    })
    
  }

  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){
    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }
  }

}
