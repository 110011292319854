<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>

<div class="divForm">
  <form [formGroup]="editorRegistroForm" *ngIf="editorRegistroForm">
    <div class="main-container"> 
      <div>
        <div class="min-height-200px">
          <!-- horizontal Basic Forms Start -->
          <div class="pd-20 card-box mb-30">
            <div class="clearfix">
              <div class="pull-left">
                <h4 class="text-blue h4">{{translation.Recintos}}</h4>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-12 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Nombre}}</mat-label>
                  <input matInput formControlName="Nombre" class="editor-campo-registro" type="text"
                    placeholder="{{translation.Nombre}}">
                  <mat-error *ngIf="validateFields('Nombre')">
                    {{getErrorMessage("Nombre")}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.NumeroDeProyecto}}</mat-label>
                  <input matInput formControlName="NumProj" class="editor-campo-registro" type="text"
                    placeholder="{{translation.NumeroProyecto}}">
                </mat-form-field>
              </div>
            </div>

            <!---------------------SWITCHES-------------------------->
            <div class="form-group row">
              <div class="col-md-6 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="Activo">
                    {{translation.Activo}}
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="col-md-6 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="PorDefecto">
                    {{translation.PorDefecto}}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="Taller">
                    {{translation.SeleccionDeHoraOpcional}}
                  </mat-slide-toggle>
                </div>
              </div>

              <!-- <div class="col-md-6 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="NumSesion" >
                    {{translation.ControlDeSesiones}}
                  </mat-slide-toggle>
                </div>
              </div> -->
            </div>
            <!---------------------HORAS-------------------------->

            <div class="form-group row">
              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.HoraInicio}}</mat-label>
                  <input matInput formControlName="HoraInicio" class="editor-campo-registro time-picker"
                    placeholder="{{translation.SeleccionaHoraInicio}}" type="time">
                </mat-form-field>
              </div>

              <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.HoraFin}}</mat-label>
                  <input matInput formControlName="HoraFin" class="editor-campo-registro time-picker"
                    placeholder="{{translation.SeleccionaHoraFin}}" type="time">
                </mat-form-field>
              </div>
            </div>

            <!---------------------DOS SWITCHES-------------------------->
            <div class="form-group row">
              <div class="col-md-4 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="ControlarAforo" (click)="toggleAforoMaximo()">
                    {{translation.ControlarAforo}}
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="ControlarAforoReserva"
                    (click)="toggleAforoInternet()">
                    {{translation.ControlaAforoOnline}}
                  </mat-slide-toggle>
                </div>
              </div>

              <div class="col-md-4 col-sm-12">
                <div class='grupo-editor-campo-registro'>
                  <mat-slide-toggle color="primary" formControlName="MostrarInternet">
                    {{translation.MostrarOnline}}
                  </mat-slide-toggle>
                </div>
              </div>
            </div>

            <!---------------------TIPO AFORO SELECT-------------------------->
            <div class="form-group row">
              <div class="col-md-12 col-sm-12">
                <app-input-filter *ngIf="recintoProfile" formControlText="TipoAforo" [label]="translation.TipoAforo"
                  [placeHolderTranslation]="translation.TipoAforo" [list]="global.tiposAforo" [disabled]="global.tiposAforo.length<1"  textShow="value"
                  dataInput="id" [data]="recintoProfile.TipoAforo" (close)="closeInputFilter($event)">

                </app-input-filter>


                <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.TipoAforo}}</mat-label>
                  <mat-select formControlName="TipoAforo" >
                    <mat-option> --- --- </mat-option>
                    <mat-option *ngFor="let tipoaforo of global.tiposAforo" [value]="tipoaforo.id">
                      {{tipoaforo.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="editorRegistroForm.controls['TipoAforo'].hasError('required')">
                    {{translation.PorFavorSeleccioneUnTipoDeAforo}}</mat-error>
                </mat-form-field> -->
              </div>
            </div>

            <!---------------------CAMPOS AFORO NUMEROS-------------------------->
            <div class="form-group row">
              <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoMaximo}}</mat-label>
                  <input matInput formControlName="AforoMaximo" (change)="validarAforoInternet()"
                    class="editor-campo-registro" type="number">
                  <mat-error *ngIf="validateFields('AforoMaximo')">
                    {{getErrorMessage("AforoMaximo")}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoMinimo}}</mat-label>
                  <input matInput formControlName="MinimoAforo" class="editor-campo-registro" type="number">
                  <mat-error *ngIf="validateFields('MinimoAforo')">
                    {{getErrorMessage("MinimoAforo")}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="form-group row">

              <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.LimiteAforo}}</mat-label>
                  <input matInput formControlName="LimiteAforo" class="editor-campo-registro" type="number">
                  <mat-error *ngIf="validateFields('LimiteAforo')">
                    {{getErrorMessage("LimiteAforo")}}
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="col-md-6 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.AforoInternet}}</mat-label>
                  <input matInput formControlName="MaximoInternet" (change)="validarAforoInternet()"
                    class="editor-campo-registro" type="number">
                  <mat-error *ngIf="validateFields('MaximoInternet')">
                    {{getErrorMessage("MaximoInternet")}}
                  </mat-error>
                </mat-form-field>
              </div>

            </div>

            <div class="form-group row">
              <div class="col-md-12 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.TiempoExtraMin}}</mat-label>
                  <input matInput formControlName="TiempoExtra" class="editor-campo-registro" type="number">
                  <mat-error *ngIf="validateFields('MaximoInternet')">
                    {{getErrorMessage("MaximoInternet")}}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>


            <div class="form-group row">
              <div class="col-sm-12 col-md-12">
                <app-input-filter *ngIf="recintoProfile" formControlText="CodigoTipoControl"
                  [label]="translation.TipoControl" [placeHolderTranslation]="translation.TipoControl"
                  [list]="tiposTiposControl" [disabled]="tiposTiposControl.length<1" textShow="Nombre" dataInput="pkId"
                  [data]="recintoProfile.CodigoTipoControl" (close)="closeInputFilter($event)"></app-input-filter>

                <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.TipoControl}}</mat-label>
                  <mat-select placeholder="{{translation.TipoControlAcceso}}" formControlName="CodigoTipoControl"
                    name="tipoUsuario" >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let tipoGrupos of tiposTiposControl" [value]="tipoGrupos.pkId">
                      {{tipoGrupos.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>
            </div>
            <!---------------------IDIOMAS-------------------------->
<!--             <div *ngFor="let idioma of editorListaIdiomas">
              <div *ngIf="editorRegistroForm.value.MostrarInternet || editorRegistroForm.value.ControlarAforoReserva">
                <div class="form-group row">
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.NombreInternet}}
                        ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text" placeholder="Nombre*"
                        formControlName="Nombre-{{idioma.CodIdioma}}">
                    </mat-form-field>
                  </div>
                  <div class="col-md-6 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.URL}} ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text" placeholder="URL"
                        formControlName="Url-{{idioma.CodIdioma}}">
                    </mat-form-field>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-12 col-sm-12">
                    <mat-form-field appearance="outline">
                      <mat-label>{{translation.Descripcion}}
                        ({{idioma.CodIdioma}})</mat-label>
                      <input matInput class="editor-campo-registro" type="text"
                        placeholder="{{translation.Descripcion}}" formControlName="Descripcion-{{idioma.CodIdioma}}">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div> -->

                        <!-- -->
                        <h2>{{translation.Idiomas}}</h2>
                        <mat-accordion *ngFor="let idioma of editorListaIdiomas; let i = index" >
                            <mat-expansion-panel [expanded]="i==0">
                              <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{idioma.Nombre}}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                              </mat-expansion-panel-header>
                          
                              <mat-form-field appearance="outline">
                                <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                                  <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                                    [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                                    formControlName="Nombre-{{idioma.CodIdioma}}">
                              </mat-form-field>
                    
                              <mat-form-field appearance="outline">
                                <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                                  <textarea matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                                    [value]="idioma.Descripcion" formControlName="Descripcion-{{idioma.CodIdioma}}"></textarea>
                              </mat-form-field>
                          
                              <mat-form-field appearance="outline">
                                <mat-label>{{translation.URL}} ({{idioma.CodIdioma}})</mat-label>
                                  <textarea matInput type="text" placeholder="{{translation.URL}} {{idioma.Nombre}}"
                                    [value]="idioma.URL" formControlName="Url-{{idioma.CodIdioma}}"></textarea>
                              </mat-form-field>

                            </mat-expansion-panel>
                        
                          </mat-accordion>
            
                        <!-- -->
            <!---------------------ENTRADAS-------------------------->
            <div class="form-group row">
              <div class="col-sm-12 col-md-12">

                <div style="margin: 20px 5px 0 5px; text-align: center;">
                  <mat-label>{{translation.Entradas}}</mat-label>

                  <customan-dual-list [source]="entradasSinAsignar" [(destination)]="entradasAsignadas" key="pkId"
                    display="Nombre" [format]="format" [filter]='true'>
                  </customan-dual-list>
                </div> 

                <div style="margin: 20px 5px 0 5px; text-align: center;">
                  <mat-label>{{translation.Centros}}</mat-label>
            
                  <customan-dual-list [source]="centrosSinAsignar"
                  [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
                  </customan-dual-list>
                </div>
                
              </div>
              <!-- <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
                <mat-list class="w33 izquierda" dense>
                  <p mat-subheader class="list-header">{{translation.EntradasAsignadas}}</p>
                  <mat-list-item *ngFor="let entradai"
                    (click)="desasignarTipoentrada(entradai, $event)">
                    <span mat-line>{{entradai.Nombre}} <mat-icon>arrow_forward_ios</mat-icon> </span>
                  </mat-list-item>
                </mat-list>
                <div class="w33 alinear-contenido-centrado">
                  <mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
                    (click)="deasignarTodas()">arrow_forward_ios</mat-icon>
                </div>
                <mat-list class="w33 derecha" dense>
                  <p mat-subheader class="list-header">{{translation.EntradasSinAsignar}}</p>
                  <mat-list-item *ngFor="let entradad"
                    (click)="asignarTipoentrada(entradad, $event)">
                    <span mat-line>
                      <mat-icon>arrow_back_ios</mat-icon> {{entradad.Nombre}}
                    </span>
                  </mat-list-item>
                </mat-list>
              </div> -->
            </div>

            <div class="clearfix">
              <div class="pull-left">
                <h4 class="text-blue h4">{{translation.Idiomas}}</h4>
              </div>
            </div>



            <!---------------------EMAIL-------------------------->
            <div class="form-group row">
              <div class="col-sm-12 col-md-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Email}}</mat-label>
                  <textarea matInput formControlName="EmailEnvioBD" placeholder="Lista de eMails"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 col-sm-12">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.ControlExterno}}</mat-label>
                  <input matInput formControlName="ControlExterno" class="editor-campo-registro" type="text"
                    placeholder="Control externo">
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row">

              <div class="col-sm-12 col-md-12">
                <app-input-filter *ngIf="recintoProfile" formControlText="GrupoActividadId"
                  [label]="translation.GrupoActividad" [placeHolderTranslation]="translation.GrupoActividad"
                  [list]="tiposGruposActividades" [disabled]="tiposGruposActividades.length<1" textShow="Nombre" dataInput="pkId"
                  [data]="recintoProfile.GrupoActividadId" (close)="closeInputFilter($event)"></app-input-filter>

                <!--  <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.GrupoActividad}}</mat-label>
                  <mat-select placeholder="Grupo Actividad" formControlName="GrupoActividadId" name="GrupoActividadId"
                    >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let actividad of tiposGruposActividades" [value]="actividad.PkId">
                      {{actividad.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

            </div>


            <div class="form-group row">

              <div class="col-sm-12 col-md-12">
                <app-input-filter *ngIf="recintoProfile" formControlText="GrupoRecintosId"
                  [label]="translation.GrupoRecinto" [placeHolderTranslation]="translation.GrupoRecinto"
                  [list]="gruposRecintos"  [disabled]="gruposRecintos.length<1" textShow="Nombre" dataInput="pkId" [data]="recintoProfile.GrupoRecintosId"
                  (close)="closeInputFilter($event)"></app-input-filter>

                <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.GrupoRecinto}}</mat-label>
                  <mat-select placeholder="Grupo Actividad" formControlName="GrupoRecintosId"
                    name="tipoUsuario" >
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let tipoGrupos of gruposRecintos" [value]="tipoGrupos.PkId">
                      {{tipoGrupos.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

            </div>


            <div class="form-group row">

              <div class="col-sm-12 col-md-12">
                <app-input-filter *ngIf="recintoProfile" formControlText="SubGrupoRecinto"
                  [label]="translation.SubGrupoRecinto" [placeHolderTranslation]="translation.SubGrupoRecinto"
                  [list]="subGruposRecintos"  [disabled]="subGruposRecintos.length<1" textShow="Nombre" dataInput="pkId" [data]="recintoProfile.SubGrupoRecinto"
                  (close)="closeInputFilter($event)"></app-input-filter>

                <!-- <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.SubGrupoRecinto}}</mat-label>
                  <mat-select placeholder="Grupo Actividad" formControlName="subGrupoRecinto" name="subGrupoRecinto">
                    <mat-option>--</mat-option>
                    <mat-option *ngFor="let recinto of subGruposRecintos" [value]="recinto.PkId">
                      {{recinto.Nombre}}
                    </mat-option>
                  </mat-select>
                </mat-form-field> -->
              </div>

            </div>


            <div class="form-group">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <div class="custom-control custom-checkbox mb-5">
                    <mat-slide-toggle color="primary" formControlName="Butacas" (change)="cambiaButacas()">
                      {{translation.Butacas}}
                    </mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" *ngIf="butacasCheck">
              <div class="col-sm-12 col-md-12">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.FormaDelRecinto}}</mat-label>
                  <mat-select placeholder="{{translation.FormaDelRecinto}}" formControlName="Shape" name="tipoForma">
                    <mat-option> --- --- </mat-option>
                    <mat-option value="POLY">{{translation.Poligono}}</mat-option>
                    <mat-option value="RECT">{{translation.Rectangulo}}</mat-option>
                    <mat-option value="CIRCLE">{{translation.Circulo}}</mat-option>
                    <mat-option value="PATH">{{translation.Trayectoria}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="form-group row" *ngIf="butacasCheck">
              <div class="col-sm-12 col-md-4">
                <mat-form-field appearance="outline">
                  <mat-label>{{translation.Coordenadas}}</mat-label>
                  <input matInput formControlName="Coords" class="editor-campo-registro" type="text" placeholder="">
                </mat-form-field>
              </div>

              <div class="col-sm-12 col-md-4">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.OrdenFilas}}</mat-label>
                  <mat-select placeholder="{{translation.OrdenFilas}}" formControlName="OrdenFilas"
                    name="tipoOrdenFilas">
                    <mat-option> --- --- </mat-option>
                    <mat-option value="ASC">{{translation.Abajo}}</mat-option>
                    <mat-option value="DES">{{translation.Arriba}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>


              <div class="col-sm-12 col-md-4">
                <mat-form-field class="input-select" appearance="outline">
                  <mat-label>{{translation.OrdenButacas}}</mat-label>
                  <mat-select placeholder="{{translation.OrdenButacas}}" formControlName="OrdenButacas"
                    name="tipoOrdenButacas">
                    <mat-option> --- --- </mat-option>
                    <mat-option value="ASC">{{translation.Izquierda}}</mat-option>
                    <mat-option value="DES">{{translation.Derecha}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

            </div>

            <div class="collapse collapse-box" id="horizontal-basic-form1">
              <div class="code-box">
                <div class="clearfix">
                  <a href="javascript:;" class="btn btn-primary btn-sm code-copy pull-left"
                    data-clipboard-target="#horizontal-basic"><i class="fa fa-clipboard"></i> Copy Code</a>
                  <a href="#horizontal-basic-form1" class="btn btn-primary btn-sm pull-right" rel="content-y"
                    data-toggle="collapse" role="button"><i class="fa fa-eye-slash"></i> Hide Code</a>
                </div>
              </div>
            </div>
            <!-- horizontal Basic Forms End -->
          </div>
        </div>
      </div>


    </div>
  </form>
</div>
</div>