import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditSinButacasComponent } from './edit-sinbutacas/edit-sinbutacas.component';

@Component({
  selector: 'app-sinbutacas',
  templateUrl: './sinbutacas.component.html',
  styleUrls: ['./sinbutacas.component.scss']
})
export class SinButacasComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.closepanel()

  }

 

  openPanel(){
    this.customanpanel=EditSinButacasComponent
    this.editChangeCss();
    this.cdRef.detectChanges();

  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      this.customanpanel = undefined;
      this.closePanelChangeCss();
    })
  }


  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){

    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
    }
  }


}
