import { Injectable } from '@angular/core';
import  esES from '../../languages/es-ES.json';
import caES from '../../languages/ca-ES.json';


@Injectable({
  providedIn: 'root'
})

export class Translator {
     GetTranslations() {
      const language = localStorage.getItem('Language');

        switch (language) {
            case "es-ES": return esES;
            case "ca": return caES;
            default:
                localStorage.setItem('Language',"es-ES");
                console.log('no se reconoce el idioma como válido: ', language);
                console.log('se establece es-ES por defecto.');
                return esES;
        }
    }

}
