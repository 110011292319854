<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="divForm">
    <form [formGroup]="editGruposActividadTallerForm" (onSubmit)="submit()">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="Nombre">
                <mat-error *ngIf="validateFields('Nombre')">
                    {{getErrorMessage("Nombre")}}
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Descripcion</mat-label>
                <input matInput formControlName="Descripcion">
                <mat-error *ngIf="validateFields('Descripcion')">
                    {{getErrorMessage("Nombre")}}
                </mat-error>
            </mat-form-field>
        </div>
    </form>

    <div style="margin: 20px 5px 0 5px; text-align: center;">
        <mat-label>{{translation.Centros}}</mat-label>
  
        <customan-dual-list [source]="centrosSinAsignar"
        [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
        </customan-dual-list>
    </div>

</div>
</div>