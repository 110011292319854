import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { peticion_Grupos_Tipos_Entrada_Lista } from '@sharedV11/classes/clients/client/peticion-grupos-tipos-entrada.model';
import { ItemsMotivosGroupProfile } from '@sharedV11/classes/EntradasPromociones/ItemsMotivosGroup/ItemsMotivosGroupProfile/itemsMotivosGroupsProfile';
import { peticion_Del_ItemMotGrupo, peticion_Insercion_Upd_ItemMotGrupo } from '@sharedV11/classes/EntradasPromociones/ItemsMotivosGroup/Peticiones/peticion-items-motivos-grupos.model';
import { Request_items_motivos_group_list } from '@sharedV11/classes/EntradasPromociones/ItemsMotivosGroup/RequestItemsMotivosGroup/request_items_motivos_group';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ItemsMotivosGruposV11Service {

  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService,
  ) { }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  findIMGByPkId(pkId: string): Observable<any> {

    let peticion: Request_items_motivos_group_list = new Request_items_motivos_group_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = "";
    peticion.Descripcion = "";
    peticion.pkId = pkId
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  getGruposTiposEntrada(nombre?: string, descripcion?: string): Observable<any> {
    //Montamos la peticion Listado
    let peticion: peticion_Grupos_Tipos_Entrada_Lista = new peticion_Grupos_Tipos_Entrada_Lista();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) { peticion.NombreGrupo = nombre; } else { peticion.NombreGrupo = ""; }
    if (descripcion != null) { peticion.chDescripcion = descripcion; } else { peticion.chDescripcion = ""; }

    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

/*   insUpdItemsMotivosGrupo(itemsMotivosGrupos: ItemsMotivosGroupProfile): Observable<any> {
    // Montamos la peticion insercion/Update
    let peticion: peticion_Insercion_Upd_ItemMotGrupo = new peticion_Insercion_Upd_ItemMotGrupo();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.Nombre = itemsMotivosGrupos.Nombre;
    peticion.Descripcion = itemsMotivosGrupos.Descripcion;
    peticion.Seleccionable = itemsMotivosGrupos.Seleccionable;
    peticion.GrupoId = itemsMotivosGrupos.GrupoId;

    if (itemsMotivosGrupos.pkId != null && itemsMotivosGrupos.pkId != "0") {
      peticion.funcion = "U";
      peticion.pkId = itemsMotivosGrupos.pkId;
    }
    else {
      peticion.funcion = "I";
      peticion.pkId = "0"
    }
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  } */
  insItemsMotivo(itemsmotivos,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = itemsmotivos;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  updItemsMotivo(itemsmotivos,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = itemsmotivos;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
  insUpdItemsMotivo(itemsmotivos,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = itemsmotivos;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarActualizarCustomanItemsMotivosGrupos'))
    );
  }

  delItemsMotivosGrupo(pkId: string): Observable<any> {
    let peticion: peticion_Del_ItemMotGrupo = new peticion_Del_ItemMotGrupo();
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanItemsMotivosGrupos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

}
