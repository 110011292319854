<!--<div id="divForm">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="example-form" autocomplete="off">
    <mat-form-field class="example-full-width">
      <mat-label>Username</mat-label>
      <input matInput type="text" name="" formControlName="username">
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <mat-label>Password</mat-label>
      <input matInput type="password" name="" formControlName="password">
    </mat-form-field>
  <button mat-raised-button color="primary">Enviar</button>
  </form>
</div>
-->
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<section class="page-wrapper">
	<header class="login-header">
		<img class="logo-img" src="assets/layout-img/logo-portada.png" alt="Panel de configuración de Ticketing"
			title="Panel de configuración de Ticketing">
	</header>
	<form [formGroup]="loginForm"  autocomplete="off">

	<div class="login-content" *ngIf="!vercodigoform">
			<!-- Loggin -->
			<fieldset class="admin__fieldset" *ngIf="!restorePassword">
				<legend class="admin__legend">
					<span *ngIf="translation">{{translation.BienvenidoPorFavorInicieSesion}}</span>
				</legend><br>
				<alert class="alerta-login"></alert>
				<div class="admin__field _required field-username" [ngClass]="{zonaoculta: loading===true}">
					<label for="username" class="admin__field-label">
						<span *ngIf="translation">{{translation.NombreDeUsuario}}</span>
					</label>
					<div *ngIf="translation" class="admin__field-control">
						<input type="text" formControlName="username" class="form-control admin__control-text" [readonly]="loading || loadingDivisas"
							[ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus="" value=""
							placeholder="{{translation.NombreDeUsuario}}" />
						<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
							<div *ngIf="f.username.errors.required">{{translation.SeRequiereNombreDeUsuario}}</div>
						</div>
					</div>
				</div>
				<div class="admin__field _required field-password" [ngClass]="{zonaoculta: loading===true}">
					<label for="login" class="admin__field-label">
						<span *ngIf="translation">{{translation.Contrasena}}</span>
					</label>
					<div class="admin__field-control">
						<input *ngIf="translation" type="password" formControlName="password" [readonly]="loading || loadingDivisas"
							class="form-control admin__control-text"
							[ngClass]="{ 'is-invalid': submitted && f.password.errors }" value=""
							placeholder="{{translation.Contrasena}}" autocomplete="new-password" />
						<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
							<div *ngIf="f.password.errors.required">{{translation.ContrasenaRequerida}}</div>
						</div>
					</div>
				</div>
<!-- 				<label for="" class="recordarContrasena"  >
					<button class="noneButton" (click)="changeLoggin(true)" [disabled]="loading" >
						<span *ngIf="translation">{{translation.RestorePassword}}</span>
					</button>
				</label> -->

				<div class="form-actions">
					<div class="actions">
						<button class="btn btn-primary btn-primary-login" (click)="robotChallenge()" [disabled]="loading" >
							<span *ngIf="translation">{{translation.IniciarSesion}}</span>
						</button>
					</div>
					<!-- <div class="links">
						<a class="action-forgotpassword" href="http://museodelprado-pre.demohiberus.com/admin_1kmqqk/admin/auth/forgotpassword/">¿Olvidó su contraseña?</a>
					</div> -->
				</div>
				<label for="" class="recordarContrasena"  >
					<button class="noneButton" (click)="changeLoggin(true)" [disabled]="loading" >
						<span *ngIf="translation">{{translation.RestorePassword}}...</span>
					</button>
				</label>
			</fieldset>
			<!-- Restore password -->
			<fieldset class="admin__fieldset" *ngIf="restorePassword">
				<legend class="admin__legend">
					<span *ngIf="translation">{{translation.BienvenidoPorFavorInicieSesion}}</span>
				</legend><br>
				<button mat-button class="btn btn-primary" (click)="changeLoggin(false)" *ngIf="!restore">
					<span class="material-icons">
						keyboard_return
						</span>
						{{translation.VolverAlLogin}}
				</button>
				<br><br>
				<h2>{{translation.RestorePassword}}</h2>
				<label for="">{{translation.UserRestorePassword}}</label>
				<alert class="alerta-login"></alert>
				<div class="admin__field _required field-username" [ngClass]="{zonaoculta: loading===true}">
					<label for="username" class="admin__field-label">
						<span *ngIf="translation">{{translation.NombreDeUsuario}}</span>
					</label>
					<div *ngIf="translation" class="admin__field-control">
						<input type="text" formControlName="username" class="form-control admin__control-text" [readonly]="loading"
							[ngClass]="{ 'is-invalid': submitted && f.username.errors }" autofocus="" value=""
							placeholder="{{translation.NombreDeUsuario}}" />
						<div *ngIf="submitted && f.username.errors" class="invalid-feedback">
							<div *ngIf="f.username.errors.required">{{translation.SeRequiereNombreDeUsuario}}</div>
						</div>
					</div>
				</div>
				<div class="admin__field _required field-password" [ngClass]="{zonaoculta: loading===true}">
					<label for="login" class="admin__field-label">
						<span *ngIf="translation">{{translation.Email}}</span>
					</label>
					<div class="admin__field-control">
						<input *ngIf="translation" type="email" formControlName="email" [readonly]="loading"
							class="form-control admin__control-text"
							[ngClass]="{ 'is-invalid': submitted && f.email.errors }" value=""
							placeholder="{{translation.Email}}"  />
						<div *ngIf="submitted && f.email.errors" class="invalid-feedback">
							<div *ngIf="f.email.errors.required">{{translation.CampoRequerido}}</div>
						</div>
					</div>
				</div>
				<div class="form-actions">
					<div class="actions">
						<button class="btn btn-primary btn-primary-login" [disabled]="loading" (click)="restoreChallenge()" >
							<span *ngIf="translation">{{translation.RestorePassword}}</span>
						</button>
						
					</div>
					<!-- <div class="links">
						<a class="action-forgotpassword" href="http://museodelprado-pre.demohiberus.com/admin_1kmqqk/admin/auth/forgotpassword/">¿Olvidó su contraseña?</a>
					</div> -->
				</div>
			</fieldset>
	
	</div>

	<div class="login-content" *ngIf="vercodigoform">

		<legend class="admin__legend">
			<span *ngIf="translation">{{translation.CodigoporEmail}}</span>
		</legend><br>
		
		<div class="admin__field-control">
			<input *ngIf="translation" type="password" formControlName="codigosec"
				class="form-control admin__control-text"
				[ngClass]="{ 'is-invalid': submitted && f.password.errors }" value=""
				placeholder="{{translation.CodigoSec}}" autocomplete="new-password" />
			<div *ngIf="submitted && f.password.errors" class="invalid-feedback">
				<div *ngIf="f.codigosec.errors.required">{{translation.ContrasenaRequerida}}</div>
			</div>
		</div>
		
		<div class="form-actions">
			<div class="actions" style="text-align: center; margin: 15px 0px 10px 0px">
				<button class="btn btn-primary btn-primary-login" (click)="onSubmit(f.codigosec.value)" [disabled]="f.codigosec.value==''" >
					<span *ngIf="translation">{{translation.Aceptar}}</span>
				</button>
			</div>
			<label for="" class="recordarContrasena"  >
				<button  class="noneButton" (click)="reenviarcodigo()" >
					<span *ngIf="translation">{{translation.ReenviarCodigo}}</span>
				</button>
			</label>
		</div>
	</div>
	</form>
	<re-captcha *ngIf="robot" (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"></re-captcha>

	<footer class="login-footer">
		{{this.Copyright}}
	</footer>
</section>

