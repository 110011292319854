<div class="container resize">
  <div class="menu-acciones-metodo">
    <div class="row flex justify-content-between" [hidden]="!editando">
      <div style="margin-left: 15px">
        <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!compruebadrops()"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
        <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
          {{translation.Descartar}}</button>
      </div>
      <button class="btn" (click)="editando=false;" matTooltip="{{translation.Cerrar}}">
        <mat-icon style="font-size: 32px;">close</mat-icon></button>
    </div>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTipoEntrada}}</button>
  </div>
  <!--<div class="menu-acciones-metodo" id="top" #top>
    <button class="btn btn-primary btn-magento-atras" (click)="editando=false;" [hidden]="!editando"><i
        class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;{{translation.Atras}}</button>
    <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!compruebadrops()"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
    <button class="btn btn-warning btn-magento-naranja" (click)="AnadirRegistro()"
      [hidden]="!permisobtnNuevo || editando">{{translation.AnadirTipoEntrada}}</button>
  </div>-->
  <alert></alert>
  <div [hidden]="editando" class="data-grid">
    <!--Filtros Activos -->
    <div *ngIf="hayFiltros(filtrosactivos)" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <ul class="custom-list-horizontal">
        <li *ngIf="(filtrosactivos.textoGeneral != null && filtrosactivos.textoGeneral.trim() !='')">
          {{translation.PalabraClave}}: <span>{{filtrosactivos.textoGeneral}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('General')"></i></li>
        <li *ngIf="(filtrosactivos.Nombre != null && filtrosactivos.Nombre.trim() !='')"> {{translation.Nombre}}:
          <span>{{filtrosactivos.Nombre}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Nombre')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Activo != null && filtrosactivos.Activo.trim() !='')"> {{translation.Activo}}:
          <span>{{filtrosactivos.Activo}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Activo')"></i>
        </li>
        <li *ngIf="(filtrosactivos.CodigoTipoControl != null)"> {{translation.ControlAcc}}:
          <span>{{traeNombreTipoControlxID(filtrosactivos.CodigoTipoControl)}}</span> &nbsp;<i
            class="fa fa-times-circle fa-sm" (click)="resetearFiltrosActivos('CodigoTipoControl')"></i>
        </li>
        <li *ngIf="(filtrosactivos.Grupo != null)"> {{translation.Grupo}}:
          <span>{{traeNombreGrupoxID(filtrosactivos.Grupo)}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('Grupo')"></i>
        </li>
        <li *ngIf="(filtrosactivos.GrupoMotivo != null)"> {{translation.Motivo}}:
          <span>{{traeNombreGrupoxID(filtrosactivos.GrupoMotivo)}}</span> &nbsp;<i class="fa fa-times-circle fa-sm"
            (click)="resetearFiltrosActivos('GrupoMotivo')"></i>
        </li>
      </ul>
    </div>
    <!--Botonera-->
    <div class="customan-botonera-magento-tabla">
      <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>
      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
      <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedColumns.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <!--#columnas-->
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let columna of displayedColumns" [value]="columna">
            {{columna}}
          </mat-list-option>
        </mat-selection-list>

      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible" class="customan-panel-columnas-tabla">
      {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ displayedFilters.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadFiltro($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
          <mat-list-option class="columnas-listado-elemento" checkboxPosition="before"
            *ngFor="let filtro of displayedFilters" [value]="filtro">
            {{filtro}}
          </mat-list-option>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div class="customan-panel-filtros-tabla">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editorFiltrosForm">
          <div *ngIf="filtersToDisplay.includes(translation.Nombre)" class="grupo-filtros-campo-registro">
            <mat-form-field appearance="outline">
              <mat-label>{{translation.Nombre}}</mat-label>
              <input matInput placeholder="{{translation.FiltroDeNombre}}" formControlName="nombre"
                [value]="filtrosactivosModelo.Nombre">
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.EstaALaVenta)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.EstaALaVenta}}" formControlName="activo" name="tipoActivo"
                [value]="filtrosactivosModelo.Activo">
                <mat-option> --- --- </mat-option>
                <mat-option value="si">{{translation.Si}}</mat-option>
                <mat-option value="no">{{translation.No}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.TipoControlAcceso)" class="grupo-filtros-campo-registro">
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.TipoControlAcceso}}" formControlName="tipoControl"
                [value]="filtrosactivosModelo.CodigoTipoControl" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos of tiposTiposControl" [value]="tipoGrupos.pkId">
                  {{tipoGrupos.Nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.GrupoDeTiposDeEntrada)"
            class="grupo-filtros-campo-registro">
            <!---->
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.GrupoDeTiposDeEntrada}}" formControlName="tipoControlG"
                [value]="filtrosactivosModelo.Grupo" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos2 of tiposGrupoTipoEntrada" [value]="tipoGrupos2.pkId">
                  {{tipoGrupos2.NombreGrupo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="filtersToDisplay.includes(translation.Motivos)" class="grupo-filtros-campo-registro">
            <!---->
            <mat-form-field class="input-select" appearance="outline">
              <mat-select placeholder="{{translation.Motivos}}" formControlName="tipoControlM"
                [value]="filtrosactivosModelo.GrupoMotivo" [compareWith]="comparaObjetosSelect">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let tipoGrupos3 of tiposGrupoTipoEntradaMotivo" [value]="tipoGrupos3.pkId">
                  {{tipoGrupos3.NombreGrupo}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="customan-botonera-magento-tabla">
        <button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>
      </div>
    </div>
    <div>
      <mat-form-field class="customan-filtro-input">
        <input matInput placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="applyFilter($any($event.target).value)" [value]="filtrosactivosModelo.textoGeneral">
        <i (click)="applyFilter($any($event.target).parentNode.children[0].value)" class="fa fa-search fa-lg"></i>
      </mat-form-field>
    </div>
    <!--Tabla-->
    <div class="mat-elevation-z8 overflow-table">
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" class="magento-paginator" [showFirstLastButtons]="true">
      </mat-paginator>

      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Acciones Column -->
        <ng-container matColumnDef="Acciones">
          <th mat-header-cell *matHeaderCellDef>{{translation.Acciones}}</th>
          <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" style="cursor: default;">
            <div class="action-container">

              <button customanTooltip="Duplicar Registro" *ngIf="permisobtnDuplicar" class="button-icon"
                (click)="duplicar(row)" style="width: 34px; height: 34px;">
                <mat-icon>content_copy</mat-icon>
              </button>

              <button *ngIf="permisobtnEliminar" customanTooltip="Borrar Registro" class="button-icon"
                (click)="borrar(row.EntradaId)" style="width: 34px; height: 34px;">
                <mat-icon>delete_forever</mat-icon>
              </button>

            </div>
          </td>
        </ng-container>

        <!-- Nombre Column -->
        <ng-container matColumnDef="Nombre">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.Nombre}} </th>
          <td mat-cell *matCellDef="let row"> {{row.Nombre}} </td>
        </ng-container>

        <!-- Descripcion Column * -->
        <ng-container matColumnDef="Descripcion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.Descripcion}}</th>
          <td mat-cell *matCellDef="let row"> {{row.Descripcion}} </td>
        </ng-container>

        <!-- T Pantalla Column * -->
        <ng-container matColumnDef="TextoPantalla">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TextoEnPantalla}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TextoPantalla}} </td>
        </ng-container>
        <!-- T Display Column * -->
        <ng-container matColumnDef="TextoDisplay">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TextoDisplay}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TextoDisplay}} </td>
        </ng-container>
        <!-- T Impresion Column * -->
        <ng-container matColumnDef="TextoImpresion">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TextoImpresion}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TextoImpresion}} </td>
        </ng-container>

        <!-- Nombre Abreviado Column -->
        <ng-container matColumnDef="TipoAbreviado">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.NombreAbrev}} </th>
          <td mat-cell *matCellDef="let row"> {{row.TipoAbreviado}} </td>
        </ng-container>

        <!-- Categoria Column -->
        <!-- <ng-container matColumnDef="Categoria">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Categoria </th>
                  <td mat-cell *matCellDef="let row"> {{traeNombreCategoriaxID(row.Categoria)}} </td>
                </ng-container> -->

        <!-- Grupo Column -->
        <ng-container matColumnDef="Grupo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.GrupoEntrada}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreGrupoxID(row.GrupoId)}} </td>
        </ng-container>

        <!-- Grupo Motivo Column -->
        <ng-container matColumnDef="GrupoMotivo">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.GrupoMotivo}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreGrupoxID(row.GrupoMotivoId)}} </td>
        </ng-container>

        <!-- ControlaAcceso Column -->
        <ng-container matColumnDef="ControlaAcceso">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ControlaAcceso}} </th>
          <td mat-cell *matCellDef="let row"> {{row.ControlarAcceso}} </td>
        </ng-container>

        <!-- TipoControl Column -->
        <ng-container matColumnDef="TipoControl">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TipoControl}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreTipoControlxID(row.CodigoTipoControl)}} </td>
        </ng-container>

        <!-- AlaVenta Column -->
        <ng-container matColumnDef="AlaVenta">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ALaVenta}} </th>
          <td mat-cell *matCellDef="let row"> {{row.ALaVenta}} </td>
        </ng-container>

        <!-- ImprimirTicket Column -->
        <ng-container matColumnDef="ImprimirTicket">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ImprimirTicket}}</th>
          <td mat-cell *matCellDef="let row"> {{row.ImprimirTicket}} </td>
        </ng-container>

        <!-- ImprimirCBarras Column -->
        <ng-container matColumnDef="ImprimirCBarras">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Imprimir código barras</th>
          <td mat-cell *matCellDef="let row"> {{row.CodigoBarras}} </td>
        </ng-container>

        <!-- ImprimirCBarrasOnline Column -->
        <ng-container matColumnDef="ImprimirCBarrasOnline">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ImprimirCodigoBarrasOnline}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.BarCodeInternet)}} </td>
        </ng-container>

        <!-- ImprimirPrecio Column -->
        <ng-container matColumnDef="ImprimirPrecio">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.ImprimirPrecio}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.ImprimirPrecio)}} </td>
        </ng-container>

        <!-- RecintosAsociados Column -->
        <!-- <ng-container matColumnDef="RecintosAsociados">
          <th mat-header-cell *matHeaderCellDef>{{translation.RecintosAsociados}}</th>
          <td mat-cell *matCellDef="let row"> {{separarOrdenDeRecinto(separaRecintos(row.RecintosAsociados)).valor}}
          </td>
        </ng-container> -->

        <!-- ActividadesAsociados Column -->
        <!-- <ng-container matColumnDef="ActividadesAsociados">
          <th mat-header-cell *matHeaderCellDef>{{translation.ActividadesAsociadas}}</th>
          <td mat-cell *matCellDef="let row"> {{separarOrdenDeRecinto(separaRecintos(row.ActividadesAsociadas)).valor}}
          </td>
        </ng-container> -->

        <!-- Impresora Column -->
        <!-- <ng-container matColumnDef="Impresora">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{translation.TipoControl}} </th>
          <td mat-cell *matCellDef="let row"> {{traeNombreImpresoraxID(row.ImpresoraSecundaria)}} </td>
        </ng-container> -->

        <!-- Butacas Column -->
        <ng-container matColumnDef="Butacas">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.TiposButacas}}</th>
          <td mat-cell *matCellDef="let row"> {{traeNombreTipoButacaxID(row.Butacas)}} </td>
        </ng-container>

        <!-- CuentaVisitante Column -->
        <ng-container matColumnDef="CuentaVisitante">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{translation.CuentaComoVisitante}}</th>
          <td mat-cell *matCellDef="let row"> {{intToBool(row.CuentaVisitante)}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="editar(row)">
        </tr>
      </table>

    </div>

    <span _ngcontent-c0="" class="div-separador"></span>

  </div>
  <!-------------------------------------------------------------------------------->
  <!-------------------------------------------------------------------------------->
  <!-------------------------------------------------------------------------------->
  <!-------------------------------------------------------------------------------->
  <!-------------------------------------------------------------------------------->
  <!-------------------------------------------------------------------------------->
  <div [hidden]="!editando" class="editor-registro">
    <span class="bajar" (click)='subirBajar(fin)'><img src="/assets/icon/desplegable.svg" alt=""></span>
    <form [formGroup]="editorRegistroForm" id="formulario" (click)="validacion()">


      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Nombre}}</mat-label>
            <input matInput formControlName="nombre" [(ngModel)]="model.Nombre" (blur)="cambiaNombre($event)"
              [ngClass]="{ 'is-invalid': f.nombre.errors }">
            <mat-error *ngIf="f.nombre.errors">
              <span *ngIf="f.nombre.errors.required">{{translation.SeRequiereNombre}}</span>
            </mat-error>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.Descripcion}}</mat-label>
            <input matInput formControlName="descripcion" [(ngModel)]="model.Descripcion"
              [ngClass]="{ 'is-invalid': f.descripcion.errors }">
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.TextoEnPantalla}}</mat-label>
            <input matInput formControlName="textopantalla" [(ngModel)]="model.TextoPantalla">
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.TextoEnDisplay}}</mat-label>
            <input matInput formControlName="textodisplay" [(ngModel)]="model.TextoDisplay">
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.TextoImpresion}}</mat-label>
            <input matInput formControlName="textoimpresion" [(ngModel)]="model.TextoImpresion">
          </mat-form-field>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.NombreAbreviado}}</mat-label>
            <input matInput formControlName="nombreabreviado" [(ngModel)]="model.TipoAbreviado">
          </mat-form-field>
        </div>
      </div>
      <!--<div class='grupo-editor-campo-registro separadorLateral'>
        <label>{{translation.NombreAbreviado}}</label><input formControlName="nombreabreviado"
          class="editor-campo-registro form-control" [(ngModel)]="model.TipoAbreviado">
      </div>-->

      <!----------->
      <!--SELECTS-->
      <!----------->
      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.GrupoEntrada}}</mat-label>
            <mat-select placeholder="{{translation.TipoGrupoDeEntradas}}" [formControl]="grupoentradacontrol"
              name="tipoGrupoEntradaControl" [(ngModel)]="model.GrupoId">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let tipoGrupos1 of tiposGrupoTipoEntrada" [value]="tipoGrupos1.pkId">
                {{tipoGrupos1.NombreGrupo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.GrupoMotivo}}</mat-label>
            <mat-select placeholder="{{translation.TipoGrupoDeMotivosDeEntradas}}" [formControl]="grupoentradamotivo"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.GrupoMotivoId">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let tipoGrupos2 of tiposGrupoTipoEntradaMotivo" [value]="tipoGrupos2.pkId">
                {{tipoGrupos2.NombreGrupo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.TipoProducto}}</mat-label>
            <mat-select placeholder="{{translation.TipoProducto}}" [formControl]="tipoproductocontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.TipoProducto">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of tiposProductosDatos" [value]="element.pkId">
                {{element.Descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.GrupoAsociado}}</mat-label>
            <mat-select placeholder="{{translation.GrupoAsociado}}" [formControl]="grupoasociadocontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.GrupoAsociado">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of gruposAsociadosDatos" [value]="element.pkId">
                {{element.Descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.TipoVisita}}</mat-label>
            <mat-select placeholder="{{translation.TipoVisita}}" [formControl]="tipovisitacontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.TipoVisita">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of tipoVisitaDatos" [value]="element.PkId">
                {{element.Descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.TipoNivel}}</mat-label>
            <mat-select placeholder="{{translation.TipoNivel}}" [formControl]="tiponivelcontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.TipoNivel">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of tiposNivelesDatos" [value]="element.pkId">
                {{element.Codigo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.Categoria}}</mat-label>
            <mat-select placeholder="{{translation.Categoria}}" [formControl]="categoriacontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.Categoria">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of categoriaDatos" [value]="element.pkId">
                {{element.chNombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.DatosNominativos}}</mat-label>
            <mat-select placeholder="{{translation.DatosNominativos}}" [formControl]="TEDatoscontrol"
              name="tipoGrupoEntradaMotivo" [(ngModel)]="model.TEDatos">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of tiposEntradaDatos" [value]="item.TEDatosId">
                {{item.Nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-------------------------------------------------------------------------------------------->
      <div *ngIf="editorRegistroForm.get('centroCosteId')">
        <div class="form-group row">
          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.CentroCoste}}</mat-label>
              <mat-select placeholder="{{translation.CentroCoste}}" formControlName="centroCosteId"
                name="tipoGrupoEntradaMotivo" [(ngModel)]="model.CentroCosteId">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let element of centroCosteDatos" [value]="element.CentroCosteId">
                  {{element.CentroCoste}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-sm-12 col-md-6">
            <mat-form-field class="input-select" appearance="outline">
              <mat-label>{{translation.Canales}}</mat-label>
              <mat-select placeholder="{{translation.Canales}}" formControlName="canalId" name="tipoGrupoEntradaMotivo"
                [(ngModel)]="model.CanalId">
                <mat-option>--</mat-option>
                <mat-option *ngFor="let item of canalesDatos" [value]="item.Id">
                  {{item.Canal}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <!-------------------------------------------------------------------------------------------->

      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.TipoControl}}</mat-label>
            <mat-select placeholder="{{translation.TipoControlAcceso}}" [formControl]="tipocontrol"
              name="tipoTipoControl" [(ngModel)]="model.CodigoTipoControl">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let tipoGrupos3 of tiposTiposControl" [value]="tipoGrupos3.CodigoTipoControl">
                {{tipoGrupos3.Nombre}}
              </mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="tipoControlTipoControl.hasError('required')">Por favor Seleccione un tipo de Control</mat-error> -->
          </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
          <!--<label>{{translation.ControlaAcceso}}</label>
          <label class="switch">
            <input formControlName="contolaacceso" type="checkbox"
              class="editor-campo-registro editor-campo-registro-check form-check-input form-control"
              [(ngModel)]="model.ControlarAcceso">-->
          <mat-slide-toggle color="primary" formControlName="contolaacceso" [(ngModel)]="model.ControlarAcceso">
            {{translation.ControlaAcceso}}</mat-slide-toggle>
          <!--<span class="slider round"></span>
          </label>-->
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="alaventa" [(ngModel)]="model.ALaVenta">
            {{translation.ALaVenta}}</mat-slide-toggle>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="imprimirticket" [(ngModel)]="model.ImprimirTicket">
            {{translation.ImprimirTicket}}</mat-slide-toggle>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="imprimircbarras" [(ngModel)]="model.CodigoBarras">
            {{translation.ImprimirCodigoDeBarras}}</mat-slide-toggle>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="imprimircbarrasonline" [(ngModel)]="model.BarCodeInternet">
            {{translation.ImprimirCodigoDeBarrasOnline}}</mat-slide-toggle>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="imprimirprecio" [(ngModel)]="model.ImprimirPrecio">
            {{translation.ImprimirPrecio}}</mat-slide-toggle>
        </div>
        <div class="col-sm-12 col-md-4">
          <mat-slide-toggle color="primary" formControlName="cuentavisitante" [(ngModel)]="model.CuentaVisitante">
            {{translation.ControlComoVisitante}}</mat-slide-toggle>
        </div>
      </div>


      <!-- Botonera -->
      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.PosicionEnBotonera}}</mat-label>
            <mat-select placeholder="{{translation.TeclaRapidaEnBotoneraAsignacionActual}}"
               formControlName="tipoTipoControlBotonera" [(ngModel)]="model.TeclaRapida">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let element of tiposBotoneraEntrada" [value]="element.TeclaRapida">
                {{element.TeclaRapida}} : {{element.NombreTipo}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- Recintos -->
      <div class="form-group row" style="margin: 20px;">
        <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
          <mat-list class="w33 izquierda" dense>
            <p mat-subheader>{{translation.RecintosAsignados}}</p>
            <mat-list-item *ngFor="let recintoi of daTiposRecintos(true)">
              <div style="display: flex">
                <input class="small-input" type="text" [formControlName]="recintoi.PkId">
                <span mat-line>{{recintoi.Nombre}} <mat-icon (click)="desasignarTipoRecinto(recintoi, $event)">
                    arrow_forward_ios</mat-icon> </span>
              </div>
            </mat-list-item>
          </mat-list>
          <div class="w33 alinear-contenido-centrado">
            <mat-icon (click)="asignarTodosRecintos()">arrow_back_ios</mat-icon> {{translation.Todos}}
            <mat-icon (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>
            <!--<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
            (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>-->
          </div>
          <mat-list class="w33 derecha" dense>
            <p mat-subheader>{{translation.RecintosSinAsignar}}</p>
            <mat-list-item *ngFor="let recintod of filtrarTiposRecintos(true)">
              <span mat-line>
                <mat-icon (click)="asignarTipoRecinto(recintod, $event)">arrow_back_ios</mat-icon> {{recintod.Nombre}}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>

      <div class="form-group row" style="margin: 20px;">
        <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
          <mat-list class="w33 izquierda" dense>
            <p mat-subheader>{{translation.ActividadesAsignadas}}</p>
            <mat-list-item *ngFor="let actividadi of daTiposRecintos(false)">
              <div style="display: flex">
                <input class="small-input" type="text" [formControlName]="actividadi.PkId">
                <span mat-line>{{actividadi.Nombre}} <mat-icon (click)="desasignarTipoActividad(actividadi, $event)">
                    arrow_forward_ios</mat-icon> </span>
              </div>
            </mat-list-item>
          </mat-list>
          <div class="w33 alinear-contenido-centrado">
            <mat-icon (click)="asignarTodasActividades()">arrow_back_ios</mat-icon> {{translation.Todos}}
            <mat-icon (click)="deasignarTodasActividades()">arrow_forward_ios</mat-icon>
            <!--<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
            (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>-->
          </div>
          <mat-list class="w33 derecha" dense>
            <p mat-subheader>{{translation.ActividadesSinAsignar}}</p>
            <mat-list-item *ngFor="let recintod of filtrarTiposRecintos(false)">
              <span mat-line>
                <mat-icon (click)="asignarTipoActividad(recintod, $event)">arrow_back_ios</mat-icon> {{recintod.Nombre}}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <!-------------------------------------------------->
      <div class="form-group row" style="margin: 20px;">
        <div class="input-lista-editor mat-elevation-z8" style="height: 150px;">
          <mat-list class="w33 izquierda" dense>
            <p mat-subheader>{{translation.CentrosAsignados}}</p>
            <mat-list-item *ngFor="let centrosi of daTiposCentros()">
              <span mat-line>{{centrosi.Nombre}} <mat-icon (click)="desasignarTipoCentro(centrosi, $event)">
                  arrow_forward_ios</mat-icon> </span>
            </mat-list-item>
          </mat-list>
          <div class="w33 alinear-contenido-centrado">
            <mat-icon (click)="asignarTodosCentros()">arrow_back_ios</mat-icon> {{translation.Todos}}
            <mat-icon (click)="deasignarTodosCentros()">arrow_forward_ios</mat-icon>
            <!--<mat-icon (click)="asignarTodas()">arrow_back_ios</mat-icon> {{translation.Todos}} <mat-icon
            (click)="deasignarTodosRecintos()">arrow_forward_ios</mat-icon>-->
          </div>
          <mat-list class="w33 derecha" dense>
            <p mat-subheader>{{translation.CentrosSinAsignar}}</p>
            <mat-list-item *ngFor="let centrod of filtrarTiposCentros()">
              <span mat-line>
                <mat-icon (click)="asignarTipoCentro(centrod, $event)">arrow_back_ios</mat-icon> {{centrod.Nombre}}
              </span>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <!--------------------------------------------------->


      <div class="form-group row">
        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.Impresoras}}</mat-label>
            <mat-select placeholder="{{translation.SeleccioneImpresoras}}" [formControl]="tipoimpresora"
              name="tipoControlImpresora" [compareWith]="comparaObjetosSelect" [(ngModel)]="model.ImpresoraSecundaria">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let impresora of globales.impresorasSeleccionables" [value]="impresora.id">
                {{impresora.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-sm-12 col-md-6">
          <mat-form-field class="input-select" appearance="outline">
            <mat-label>{{translation.TipoButacas}}</mat-label>
            <mat-select placeholder="{{translation.TiposDeButacas}}" [formControl]="tiponivelcontrol"
              name="tipoTipoControlButacas" [(ngModel)]="model.TipoButacaId">
              <mat-option>--</mat-option>
              <mat-option *ngFor="let tipoGrupos4 of tiposTiposButaca" [value]="tipoGrupos4.Id">
                {{tipoGrupos4.Nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>


    </form>
<!--     <span class="subir" id="bottom" (click)='subirBajar(top)'><img src="/assets/icon/desplegable.svg" alt=""></span>
 -->
    <div>
      <button class="btn btn-success btn-magento-naranja" (click)="guardar()" [disabled]="!compruebadrops()"
      [hidden]="!permisobtnGuardar || !editando">{{translation.Guardar}}</button>
      <button class="btn btn-warning btn-magento-gris-claro" (click)="editando=false;" [hidden]="!editando">
        {{translation.Descartar}}</button>
    </div>
  </div>
  <span id="fin" #fin></span>
</div>