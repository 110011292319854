import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { Formas } from '@sharedV11/classes/FormasPago/formas';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { EditFormasPagoService } from '@sharedV11/services/components/edit_formasPago/edit-formas-pago.service';
import { takeUntil } from 'rxjs/operators';
import { Globales } from '@clasesV6/globales';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { FormaPago } from '../../../../shared/classes/FormasPagoResponse/formas_pago_Respuesta';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-edit-formas-pago-v11',
  templateUrl: './edit-formas-pago-v11.component.html',
  styleUrls: ['./edit-formas-pago-v11.component.scss']
})


export class EditFormasPagoV11Component implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();
  private isValidPrivilegiosVar = /^-?\d{1,9}(\.\d{1,2})?$/;
  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editFormaPago: FormGroup;
  formasPago: Formas;
  globales: Globales;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };
  
  constructor(
    private editFormasPagoService: EditFormasPagoService,
    private translator: Translator,
    private fb: FormBuilder,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private _cds: ChangeDetectorRef,
    private gsCentros: GlobalServicCentros

  ) { 

  }


  ngOnDestroy() {
  
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();

  }
  ngAfterViewInit() {
    this.manageForm(this.pkId, this.mode);
    this._cds.detectChanges()

  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  changeValueForm(fp: Formas) {
    this.formControls["pkId"].setValue(fp.pkId);
    this.formControls["Denominacion"].setValue(fp.Denominacion);
    this.formControls["Descripcion"].setValue(fp.Descripcion);
    this.formControls["siDiasPrimerVto"].setValue(fp.siDiasPrimerVto);
    this.formControls["siNumVencimientos"].setValue(fp.siNumVencimientos);
    this.formControls["siVencimiento"].setValue(fp.siVencimiento);
    this.formControls["iCodigoFormaPago"].setValue(fp.iCodigoFormaPago);
    this.formControls["chFOP"].setValue(fp.chFOP);
    this.formControls["chFOP1"].setValue(fp.chFOP1);
  }
  initializeFormWithoutData() {
    this.editFormaPago = this.fb.group({
      pkId: [""],
      Denominacion: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      Descripcion: [""],
      siDiasPrimerVto: ["0", [Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      siNumVencimientos: ["0", [Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      siVencimiento: ["0", [Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      iCodigoFormaPago: ["", [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      chFOP: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      chFOP1: ["", [Validators.pattern(/[A-Za-z]/)]],
      CentrosIds:[""]
    });
  
  }
  validateFields(field: string) {
    return this.isValidAnyField(field);
  }
  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    const errors = fc_pv[field].errors;
    return (errors != null) ? true : false;
  }
  getErrorMessage(field: string) {
    let mensaje: string;
    let fc_pv = this.editFormaPago.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        mensaje = "Este campo es requerido";
      } else if (field === "siDiasPrimerVto" || field === "siNumVencimientos" || field === "siVencimiento" || field === "iCodigoFormaPago") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Denominacion" || field === "chFOP" || field === "chFOP1") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloLetras"];
        }
      }
      else if (field === "rDescProntoPago" || field === "rAumentoRecargo") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloPorCiento"];
        }
      }
    }
    return mensaje;
  }

  notEmptyFields(formasPago: FormaPago): boolean {
    let res = true;
    if (formasPago.Denominacion.length === 0 || formasPago.chFOP.length === 0 || formasPago.iCodigoFormaPago.length === 0) {
      res = false;
    }
    return res;
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach(element => {
        this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
      });
    } else {
      this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
    }
  }

  stringToBoolean(value) {

    if (typeof value !== "boolean") {

      if (value == "1") {
        value = true;
      } else {
        value = false;
      }
    }
    return value
  }

  get formControls() {
    return this.editFormaPago.controls
  }
  manageForm(pkId, mode) {
    if (mode == "I") {
      this.formasPago = this.editFormaPago.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findFormaPago(pkId)
    }
  }
  findFormaPago(pkId) {
    this.editFormasPagoService.findFormaByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(userApi => {
      let formaPagodata: Formas = userApi.DatosResult.customanFormaPago[0]
      if (formaPagodata != null) {   
        this.changeValueForm(formaPagodata);
        this.formasPago = this.editFormaPago.value;
        this.formasPago.CentrosIds = formaPagodata.CentrosIds;
        this.valuedChanged();
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.formasPago);
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }
  valuedChanged() {
    this.editFormaPago.valueChanges.subscribe(value => {
      if (this.formasPago != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.formasPago)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }
  manageApi(formasPago: Formas, mode) {
    formasPago.rDescProntoPago = "0";
    formasPago.rAumentoRecargo = "0";
    formasPago.iFacturar = "0";
    formasPago.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        formasPago.pkId = "0"
        this.InsFormasPago(formasPago)
        console.log(formasPago)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdFormasPago(formasPago)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        formasPago.pkId = "0"
        this.InsFormasPago(formasPago)
        break;

      default:
        break;
    }
  }
  UpdFormasPago(formasPago) {
    //if (this.notEmptyFields(formasPago)) {
    this.editFormasPagoService.updFormasPago(formasPago, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
    },
    error => { this.httpErrorService.identificarErrores(error);
     })
    //}
  }
  InsFormasPago(formasPago) {
    //if (this.notEmptyFields(formasPago)) {
    this.editFormasPagoService.insFormasPago(formasPago, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
    //}

  }
  submit() {
    let formasPago: Formas = this.editFormaPago.value;
    this.editFormaPago.markAllAsTouched();
    if (this.editFormaPago.valid) {
      //formasPago.iFacturar = formasPago.iFacturar.toString().toLowerCase() == "true" ? "1" : "0";
      this.manageApi(formasPago, this.mode);
    }
  }
  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.formasPago)
  }
}