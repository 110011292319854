import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AutentificacionService } from '@sharedV11/services/otros_proveedores/autentificacion.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditUserComponent } from './edit-user/edit-user.component';

@Component({
  selector: 'app-user-v11',
  templateUrl: './user-v11.component.html',
  styleUrls: ['./user-v11.component.scss']
})
export class UserV11Component implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;

  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef

  ) { }

  ngOnInit(): void {
    this.closepanel();
    //Comprobamos logueo en QueryString
/*     this.autentificador.verificarAccesoQueryString(this._ruta).then(

      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
       
        let ruta = "/";
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          
          ruta = ruta + elemento.path + "/";
          console.log("res: ",elemento,ruta);
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        //console.log(this.permisoFormulario);
        if (!this.permisoFormulario) { this.router.navigate(['/portada']) };
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
      }
    ); */

  }

 

  openPanel(){
    this.customanpanel=EditUserComponent
    this.editChangeCss();
    this.cdRef.detectChanges();

  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      this.customanpanel = undefined;
      this.closePanelChangeCss();

    })
    
  }


  
  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){

    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }


}
