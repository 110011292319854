<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="editor-registro">
  <div class="divForm">
    <form [formGroup]="editRegistroForm" (onSubmit)="submit()">

        <div class="form-group">

            <mat-form-field appearance="outline">
                <mat-label>{{translation.Nombre}}</mat-label>
                <input matInput formControlName="Nombre">
                <mat-error *ngIf="validateFields('Nombre')">
                {{getErrorMessage("Nombre")}}
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>{{translation.Descripcion}}</mat-label>
                <input matInput formControlName="Descripcion">
                <mat-error *ngIf="validateFields('Descripcion')">
                {{getErrorMessage("Descripcion")}}
                </mat-error>
            </mat-form-field>

        </div>
        <div class="form-group">
<!--             <mat-form-field appearance="outline">
            <mat-label>{{translation.Lugar}}</mat-label>
            <input type="number" matInput formControlName="Lugar">
            <mat-error *ngIf="validateFields('Lugar')">
                {{getErrorMessage("Lugar")}}
            </mat-error>
            </mat-form-field> -->
            <div class="col-sm-12 col-md-6">
            <app-input-filter *ngIf="lugares" formControlText="LugarId" [disabled]="lugares.length<1"
            [label]="translation.Lugar" [placeHolderTranslation]="translation.Lugar"
            [list]="lugares" textShow="Nombre" dataInput="pkId" required="false"
            [data]="Tpv['LugarId']" (close)="closeInputFilter($event)"></app-input-filter>
            </div>
            <div class="col-sm-12 col-md-6">
            <mat-form-field appearance="outline">
            <mat-label>{{translation.DireccionIP}}</mat-label>
            <input mask="IP" type="text" matInput formControlName="DireccionIP">
            <mat-error *ngIf="validateFields('DireccionIP')">
                {{getErrorMessage("DireccionIP")}}
            </mat-error>
            </mat-form-field>
            </div>
        </div>
        <div class="form-group">
<!--             <mat-form-field appearance="outline">
                <mat-label>{{translation.NombrePC}}</mat-label>
                <input type="text" matInput formControlName="NombrePC">
            </mat-form-field> -->
            <div class="col-sm-12 col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>{{translation.NombrePC}}</mat-label>
                    <input type="text" matInput formControlName="VMPath">
                </mat-form-field>
            </div>
            
            <div class="col-sm-12 col-md-6">
            <mat-label>{{translation.DeBaja}} <mat-slide-toggle name="DeBaja" formControlName="DeBaja"></mat-slide-toggle>
            </mat-label>
    
    
            <mat-label>{{translation.Asignado}}<mat-slide-toggle matInput name="Asignado" formControlName="Asignado"></mat-slide-toggle>
            </mat-label>

            <mat-label>{{translation.Ocupado}}<mat-slide-toggle matInput name="NumTotTickets" formControlName="NumTotTickets"></mat-slide-toggle>
            </mat-label>

            </div>
            
    
        </div>

        <mat-accordion *ngIf="mode!='I' && mode!='D'">
            <mat-expansion-panel [expanded]="true">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Cajas
                </mat-panel-title>
                <mat-panel-description>
                    <input matInput [matDatepicker]="FechaCajas" placeholder="{{translation.FechaDeBaja}}"
                     (dateChange)="setDate($event)" [value]="fechacajas">
                    <mat-datepicker-toggle matSuffix [for]="FechaCajas"></mat-datepicker-toggle>
                    <mat-datepicker #FechaCajas></mat-datepicker>
                </mat-panel-description>
              </mat-expansion-panel-header>
                <mat-progress-bar *ngIf="loadingcajas" mode="indeterminate"></mat-progress-bar>
                <mat-selection-list #lascajas *ngIf="!loadingcajas" (selectionChange)="selectionChange($event.option)">
                    <mat-list-option role="listitem"  *ngFor="let caja of cajas" [selected]="caja.Estado =='CC'" [disabled]="caja.Estado =='CC'"  [id]="caja.pkId" (click)="seguro($event, caja.pkId)">

                        <table>
                            <thead>
                            <tr>
                                <td></td>
                                <td>Inicio</td>
                                <td><span *ngIf="caja.FechaFin!=''">Fin</span></td>
                            </tr>
                            </thead>

                            <tr>
                                <td>
                                    {{caja.Codigo}}
                                </td>
                                <td>
                                    {{caja.FechaInicio}}
                                </td>
                                <td>
                                    {{caja.FechaFin}}
                                </td>
                            </tr>
                        </table>

                    </mat-list-option>
                </mat-selection-list>
            </mat-expansion-panel>
        </mat-accordion>

        <div *ngIf="GruposTipoEntradasSinAsignar.length>0" style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Entradas}}</mat-label>
            <customan-dual-list [source]="GruposTipoEntradasSinAsignar"
            [(destination)]="GruposTipoEntradasAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
        </div>


<!--         <div *ngIf="centrosSinAsignar.length>0 && Centrosloaded" style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Centros}}</mat-label>
            <customan-dual-list [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
             </customan-dual-list>
        </div> -->


    
        <div *ngIf="GruposTipoBonoSinAsignar.length>0" style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Promociones}}</mat-label>
            <customan-dual-list [source]="GruposTipoBonoSinAsignar"
            [(destination)]="GruposTipoBonoAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
        </div>


        
<!--         <div *ngIf="GruposTipoAbonadosSinAsignar.length>0 && Abonadosloaded" style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Abonados}}</mat-label>
            <customan-dual-list [source]="GruposTipoAbonadosSinAsignar"
            [(destination)]="GruposTipoAbonadosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
        </div> -->

        <div style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Centros}}</mat-label>
      
<!--             <customan-dual-list [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list> -->

            <app-input-filter *ngIf="centrosSinAsignar.length>0" formControlText="CentrosIds" [disabled]="centrosSinAsignar.length<1"
                [label]="translation.Centros" [placeHolderTranslation]="translation.Centros"
                [list]="centrosSinAsignar" textShow="Nombre" dataInput="pkId" required="false"
                [data]="Tpv.CentrosIds" (close)="closeInputFilter($event)"></app-input-filter>
        
        </div>



    </form>


  </div>
</div>