<div class="data-grid">
    <div class="row flex">
     
      <div class="">
        <mat-form-field appearance="outline" class="form-field-keyValue">
          <mat-label>{{ translation.PalabraClave}}</mat-label>
          <input matInput #keyValue placeholder="{{translation.BuscarPorPalabraClave}}"
          (keydown.enter)="filterByAll(keyValue.value)">
          <button mat-icon-button matSuffix (click)="filterByAll(keyValue.value)">
            <mat-icon>search</mat-icon>
            </button>
        </mat-form-field>
      </div>
 
    <!--Botonera-->
    <div class="buttons-options">
      <!-- <button class="btn btn-warning btn-magento-gris-claro" [class.filtro-activado]="panelFiltrosvisible"
        (click)="MostrarFiltros()"><i class="fa fa-filter fa-lg"></i>&nbsp;&nbsp;{{translation.Filtros}}</button>-->
        <button matRipple mat-raised-button color="accent" #filterButtonOption (click)="MostrarFiltros()"><span class="material-icons">filter_alt</span> {{translation.Filtros}}</button>

      <!--<button *ngIf="permisobtnEliminar && model.PkId!=null && editando" class="btn btn-danger btn-magento-gris" (click)="pregborrarRegistro()">Borrar</button>-->
     <!-- <button class="btn btn-primary btn-magento-atras" (click)="MostrarColumnas()"><i
          class="fa fa-cog fa-lg"></i>&nbsp;&nbsp;{{translation.Columnas}}&nbsp;&nbsp;<i class="fa fa-sm"
          [class.fa-caret-up]="panelColumnasvisible" [class.fa-caret-down]="!panelColumnasvisible"></i></button>-->
        <button matRipple mat-raised-button color="accent" #columnButtonOption (click)="MostrarColumnas()"><span class="material-icons">view_week</span> {{translation.Columnas}}</button>

    </div>
    </div>
    <!--Panel de Columnas-->
    <div *ngIf="panelColumnasvisible && table" class="customan-panel-columnas-tabla" #ColumnPanelVisible>
      Columns {{columnas.selectedOptions.selected.length}} {{translation.De}} {{ filterLenghtWithoutAColumn() }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #columnas (selectionChange)="visibilidadColumna($event, columnas.selectedOptions.selected)"
          class="columnas-tabla-listado" [formControl]="formControlListaColumnas">
          <ng-container *ngFor="let columna of table.columns">
          <mat-list-option class="columnas-listado-elemento"  *ngIf="columna.column==true" checkboxPosition="before"
             [value]="columna.name">
            <span class="accent-color">{{translation[columna.labelTranslate]}}</span>
          </mat-list-option>
        </ng-container>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel selector de Filtros-->
    <div *ngIf="panelSelectorFiltrosvisible && table" class="customan-panel-columnas-tabla" #filterPanel>
      Filters {{filtersS.selectedOptions.selected.length}} {{translation.De}} {{  numColconFiltro.length }}
      {{translation.Visibles}}.
      <div class="containerColumnas-tabla" cdk-scrollable>
        <mat-selection-list #filtersS  (selectionChange)="visibilidadFiltro($event, filtersS.selectedOptions.selected)"
         class="columnas-tabla-listado" [formControl]="formControlListaFiltros">
         <ng-container *ngFor="let filtro of table.columns">
          <mat-list-option  *ngIf="filtro.filter==true" class="columnas-listado-elemento"  checkboxPosition="before"
           [value]="filtro.name"  >
           <span class="accent-color"> {{translation[filtro.labelTranslate]}}</span>
          </mat-list-option>
        </ng-container>
        </mat-selection-list>
      </div>
    </div>
    <!--Panel de Filtros-->
    <div  [ngClass]="calculateClassesFiltersTable()" *ngIf="table">
      <div class="containerFiltros-tabla">
        <form [formGroup]="editFilterForm">
          <ng-container *ngFor="let columnDB of table.columns;">
          <div   *ngIf="columnDB.filter==true && columnDB.filterActive!=''"  class="grupo-filtros-campo-registro">
          
              <ng-container [ngSwitch]="columnDB.type">
                <!-- case String -->
                <ng-container *ngSwitchCase="'string'">
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                  <input matInput placeholder="{{translation[columnDB.placeHolderTranslate]}}" type="text" formControlName="{{columnDB.name}}">
                </mat-form-field>
                </ng-container>

              

                 <!-- case Number -->
                <ng-container *ngSwitchCase="'number'">
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                  <input matInput placeholder="{{translation[columnDB.placeHolderTranslate]}}" type="number" formControlName="{{columnDB.name}}">
                 </mat-form-field>
                </ng-container>
                
               
                <!-- case Select -->
                <ng-container *ngSwitchCase="'select'">
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                  <mat-select formControlName="{{columnDB.name}}">
                    <mat-option> -- </mat-option>
                    <mat-option  *ngFor="let option of columnDB.filterOptions.selectOptions" value="{{option.value}}">{{option.name}}</mat-option >
                  </mat-select>
                 </mat-form-field>
                </ng-container>
                
                <!-- case Select -->
                <ng-container *ngSwitchCase="'multiselect'">
                  <mat-form-field  appearance="outline">
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                  <mat-select formControlName="{{columnDB.name}}" multiple>
                    <mat-option  *ngFor="let option of columnDB.filterOptions.selectOptions" value="{{option.value}}">{{option.name}}</mat-option >
                  </mat-select>
                  </mat-form-field>
                </ng-container>

                <!-- case range date -->
                <ng-container *ngSwitchCase="'rangeDate'">
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                    <mat-date-range-input formGroupName="{{columnDB.name}}" [rangePicker]="picker">
                    <input matStartDate  formControlName="{{columnDB.name}}Start" placeholder="{{translation.dtFechaInicio}}">
                    <input matEndDate formControlName="{{columnDB.name}}End" placeholder="{{translation.dtFechaFin}}">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker></mat-date-range-picker>

                 </mat-form-field>
                  </ng-container> 

                   <!-- case date -->
                <ng-container *ngSwitchCase="'date'">
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="{{columnDB.name}}" placeholder="{{translation.dtFechaInicio}}">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>

                 </mat-form-field>
                  </ng-container> 

                   <!-- case hour -->
                <ng-container *ngSwitchCase="'hour'">
                  <mat-form-field appearance="outline">
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                    <input matInput formControlName="{{columnDB.name}}" placeholder="{{translation.Hora}}" type="time">
                  </mat-form-field>
                  </ng-container> 

                   <!-- case String -->
                <ng-container *ngSwitchCase="'inputFilter'">
                    <app-input-filter *ngIf="columnDB.filterOptions.selectOptions"  formControlText="{{columnDB.name}}" [label]="translation[columnDB.labelTranslate]" 
                    [placeHolderTranslation]="translation[columnDB.placeHolderTranslate]" [list]="columnDB.filterOptions.selectOptions" textShow="name" dataInput="value" required="false" 
                     (close)="closeInputFilter($event)"></app-input-filter>
                </ng-container>

                <!-- <app-input-filter  formControlText="{{columnDB.name}}" [label]="translation[columnDB.labelTranslate]" 
        [placeHolderTranslation]="translation[columnDB.placeHolderTranslate]" [list]="columnDB.filterOptions.selectOptions" textShow="{{option.name}}" dataInput="{{option.value}}" 
         (close)="closeInputFilter($event)"></app-input-filter> -->
                
<!-- 
        <mat-form-field  appearance="outline" >
          <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
        <mat-select formControlName="{{columnDB.name}}">
          <mat-option> -- </mat-option>
          <mat-option  *ngFor="let option of columnDB.filterOptions.selectOptions" value="{{option.value}}">{{option.name}}</mat-option >
        </mat-select> -->

                 <!-- case Default -->
                 <ng-container *ngSwitchDefault>
                  <mat-form-field  appearance="outline" >
                    <mat-label   >{{ translation[columnDB.labelTranslate]}}</mat-label>
                  <input matInput placeholder="{{translation[columnDB.placeHolderTranslate]}}" type="text" formControlName="{{columnDB.name}}">
                 </mat-form-field>
                </ng-container>


            </ng-container>
          </div>
          </ng-container>
         
        </form>
      </div>
       <!--Filtros Activos -->
    <div  *ngIf="filters.length!=0" class="customan-panel-filtros-activos">{{translation.FiltrosActivos}}:
      <mat-chip-list aria-label="Fish selection" class="custom-list-horizontal">
        <mat-chip *ngFor="let filter of filters" > {{translation[filter.translation]}}:
            {{filter.show|json}}
            &nbsp;<i class="fa fa-times-circle fa-sm"  (click)="removeFilter(filter)"></i>
          </mat-chip>
      </mat-chip-list>
    </div>
      <div class="margin-right no-padding" [ngClass]="calculateClassesFiltersButton()">
        <!--<button class="btn btn-warning btn-magento-gris"
          (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>-->
          <button  matRipple mat-raised-button color="primary"  (click)="AplicarFiltros()">{{translation.AplicarFiltros}}</button>

      </div>
    </div>
    

  </div>