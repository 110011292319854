import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from './translate.pipe';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';



import { DialogoPopUp } from './components/alert/dialog/popup.service'
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '../shared/material/material.module';
import { MyCurrencyPipe } from './my-currency.pipe';



@NgModule({
  declarations: [TranslatePipe,MyCurrencyPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    //MaterialModule
    
    
    
    
  ],

  exports: [TranslatePipe, MyCurrencyPipe, FlexLayoutModule/* , MaterialModule */]

})
export class SharedModule {
  // constructor() {
  //   library.add(fas, far, fab);
  // }
  constructor() {

  
  }
}
