import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Categoria } from '@sharedV11/classes/categoria/categoria';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { EditCategoriasService } from '@sharedV11/services/components/Categorias/edit-categorias.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-edit-categorias',
  templateUrl: './edit-categorias.component.html',
  styleUrls: ['./edit-categorias.component.scss']
})
export class EditCategoriasComponent implements OnInit {


  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;

  editRegistroForm: FormGroup;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };


  Categoria:Categoria
  constructor(
    private editCategoriaService:EditCategoriasService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)

    // this.pkId="4"

  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithoutData() {

    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Nombre: ["", Validators.required],
      Descripcion: [""],
      Orden: [""],
      CentrosIds:[""]
    
    });
  }
  manageForm(pkId, mode) {

    if (mode == "I") {
      this.Categoria = this.editRegistroForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findCategoria(pkId)
    }
  }

  findCategoria(pkId) {
    this.editCategoriaService.findCategoriaByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(CategoriaApi => {
      // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
      console.log(CategoriaApi)
      let Categoria: Categoria = CategoriaApi["DatosResult"]["lista"][0]

      if (Categoria != null) {
        this.changeValueForm(Categoria)
        this.Categoria = this.editRegistroForm.value;
        this.Categoria.CentrosIds = Categoria.CentrosIds;
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.Categoria);
        this.valuedChanged();
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  changeValueForm(Categoria:Categoria) {
    this.editRegistroForm.get("pkId").setValue(Categoria.pkId);
   
    this.editRegistroForm.get("Nombre").setValue(Categoria.chNombre==undefined?Categoria.Nombre:Categoria.chNombre)
    this.editRegistroForm.get("Descripcion").setValue(Categoria.chDescripcion==undefined?Categoria.Descripcion:Categoria.chDescripcion)
    this.editRegistroForm.get("Orden").setValue(Categoria.Orden)
 
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe(value => {
      if (this.Categoria != null) {
        this.panelService.setDiscardChanges(false)
        console.log(value.Baja)
        if (JSON.stringify(value) === JSON.stringify(this.Categoria)) {
          console.log("same")
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }
  submit() {
    //this.flagInput=!this.flagInput
    /*  console.log(this.editRegistroForm.value)
     console.log(this.editRegistroForm.valid)
     console.log(this.editRegistroForm) */
     this.editRegistroForm.markAllAsTouched()
    let Categoria: Categoria = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(Categoria, this.mode)
    } 

  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true)

    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.Categoria)
  }
  manageApi(Categoria: Categoria, mode) {
    Categoria.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        Categoria.pkId = "0"
        this.InsCategoria(Categoria)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdUsuario(Categoria)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        Categoria.pkId = "0"
        this.InsCategoria(Categoria)

        break;

      default:
        break;
    }
  }
  UpdUsuario(Categoria){
    this.editCategoriaService.updCategoria(Categoria, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });    
   }
   InsCategoria(Categoria){
    this.editCategoriaService.insCategoria(Categoria, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   
   }
   nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach(element => {
          this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
        });
      } else {
        this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
      }
    }
  }
  existErrors() {
    return (this.editRegistroForm.get("pkId").errors || this.editRegistroForm.get("Nombre").errors || this.editRegistroForm.get("Descripcion").errors || this.editRegistroForm.get("Orden").errors) ? true : false;
  }
  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if(fc_pv){
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }
    
    return "Error";
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }
}
