import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog_Data } from '@sharedV11/classes/dialog/dialog_data';


export interface dataEmail{
  mensaje: string;
  name: string;
  eMails: string;
}
@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  myGroup;
  
/*   public data:dataEmail ={
    mensaje: "",
    name: "",
    eMails: ""
  } */
  constructor(
    public dialogRef: MatDialogRef<EmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data:dataEmail) {
    }
    
    ngOnInit(): void {
      this.myGroup = new FormGroup({
        ListEmails: new FormControl(this.data.eMails)
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }


}
