import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  params
  constructor(
    private auth:AuthenticationService,
    private router:Router,
    private activatedRoute: ActivatedRoute

    ){
      //console.log(router.getCurrentNavigation().extractedUrl.queryParamMap.get("auth"));
     
    this.params=router.getCurrentNavigation().extractedUrl.queryParamMap.get("auth")
     //console.log(router.getCurrentNavigation().finalUrl.toString())
    }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      let logged=this.auth.verificar()
      console.log(this.router.url)

      if(!logged){
        if (this.params) {
          console.log("---------------------")
          console.log(this.params)
          this.router.navigate(["/login"],{queryParams:{as:"asdasd"}});
          
        }else{
          console.log("---------------------2")
          this.router.navigate(["/login"]);
        }
        return false
      }

    return true;
  }
  
}
