<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>

<div class="divForm">
  <form [formGroup]="editorRegistroForm">

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-slide-toggle color="primary" formControlName="activa" id="activaCheck4">
          {{translation.bALaVenta}}</mat-slide-toggle>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.PlataformasDeVenta}}</mat-label>
          <mat-select multiple placeholder="{{translation.PlataformasDeVenta}}" formControlName="tipoControlPlataforma"
            (selectionChange)="actualizarPlataformasSeleccionadas()">
            <mat-option value="1">Punto Venta Kore</mat-option>
            <mat-option *ngIf="TieneInternet === '1'" value="2">Internet
            </mat-option>
            <mat-option *ngIf="TieneTaquillaAutomatica === '1'" value="3">Taquilla
              Automática</mat-option>
            <mat-option *ngIf="TieneAdmon === '1'" value="4">Administración
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoDeProducto}}</mat-label>
          <mat-select placeholder="{{translation.TipoDeProducto}}" formControlName="tipoControlproductoAsociado"
            (selectionChange)="actualizarProductoSeleccionado()">
            <mat-option *ngIf="tipoProductoEntrada === true" value="1">Entrada</mat-option>
            <mat-option *ngIf="tipoProductoPaquete === true" value="2">Paquete</mat-option>
            <mat-option *ngIf="tipoProductoAbonado === true" value="3">Abonado</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- TIPO ENTRADA -->
    <div class="form-group row" *ngIf="platSelected === '1'">
      <div class="col-sm-12">
        <app-input-filter *ngIf="editorRegistroForm" formControlText="tipoControlproductoAsociadoEntrada"
        [label]="translation.TipoDeEntrada" [placeHolderTranslation]="translation.TipoDeEntrada"
        [list]="filteredEntradas | async" textShow="TextoPantalla" dataInput="pkId" required="true"
        [data]="editorRegistroForm.value.tipoControlproductoAsociadoEntrada.pkId" (close)="closeInputFilter($event)"></app-input-filter>


        <!-- <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoDeEntrada}}</mat-label>
          <input type="text" placeholder="{{translation.TipoDeEntrada}}" matInput
            formControlName="tipoControlproductoAsociadoEntrada" [matAutocomplete]="entrada">
          <mat-autocomplete #entrada="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let entrada of filteredEntradas | async" [value]="entrada">
              {{entrada.TextoPantalla}} ({{entrada.Descripcion}})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
      </div>
    </div>

    <!-- 
    <div class="form-group row" *ngIf="platSelected === '1'">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoDeEntrada}}</mat-label>
          <mat-select placeholder="{{translation.TipoDeEntrada}}"
            formControlName="tipoControlproductoAsociadoEntrada"
            (selectionChange)="actualizarProductoEntrada($event.value)">
              <mat-option *ngFor="let entrada of ProductosEntrada" [value]="entrada.EntradaId">
                {{entrada.TextoPantalla}}
              </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
     -->
    <!-- TIPO PAQUETE -->
    <div class="form-group row" *ngIf="platSelected === '2'">
      <div class="col-sm-12">
        <app-input-filter *ngIf="editorRegistroForm" formControlText="tipoControlproductoAsociadoPaquete"
        [label]="translation.TipoDePaquete" [placeHolderTranslation]="translation.TipoDePaquete"
        [list]="filteredProductos | async" textShow="chTextoPantalla" dataInput="pkId" required="true"
        [data]="editorRegistroForm.value.tipoControlproductoAsociadoPaquete.pkId" (close)="closeInputFilter($event)"></app-input-filter>

        <!-- <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoDePaquete}}</mat-label>
          <input type="text" placeholder="{{translation.TipoDePaquete}}" matInput
            formControlName="tipoControlproductoAsociadoPaquete" [matAutocomplete]="paquete">
          <mat-autocomplete #paquete="matAutocomplete" [displayWith]="displayFnProducto"
            (optionSelected)="actualizarProductoPaquete($event)">
            <mat-option *ngFor="let paquete of filteredProductos | async" [value]="paquete">
              {{paquete.chTextoPantalla}} ({{paquete.chDescripcion}})
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
      </div>
    </div>
    <!--
    <div class="form-group row" *ngIf="platSelected === '2'">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoDePaquete}}</mat-label>
          <mat-select placeholder="{{translation.TipoDePaquete}}"
            formControlName="tipoControlproductoAsociadoPaquete"
            (selectionChange)="actualizarProductoPaquete($event.value)">
            <mat-option *ngFor="let paquete of ProductosPaquete" [value]="paquete.pkId">
              {{paquete.chTextoPantalla}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="platSelected === '3'">
      <div class="col-sm-12">
<!--         <label class="col-sm-12 col-md-2 col-form-label">{{translation.TipoDeAbonado}}</label>-->        <div class="col-sm-12 col-md-10">
          <!-- <app-input-filter *ngIf="editorRegistroForm" formControlText="tipoControlproductoAsociadoPaquete"
          [label]="translation.TipoDeAbonado" [placeHolderTranslation]="translation.TipoDeAbonado"
          [list]="ProductosAbono" textShow="Nombre" dataInput="pkId" 
          [data]="editorRegistroForm.value.tipoControlproductoAsociadoAbono" (close)="closeInputFilter($event)"></app-input-filter>
   -->
          <!-- <mat-select formControlName="tipoControlproductoAsociadoAbono"
            class="custom-select2 select col-12 form-control" style="width: 100%;">
            <mat-option *ngFor="let abono of ProductosAbono" [value]="abono.pkId">
              {{abono.Nombre}}
            </mat-option>
          </mat-select> -->
          <!-- <mat-select formControlName="tipoControlproductoAsociadoAbono"
            (selectionChange)="actualizarProductoAbonado($event.value)"
            class="custom-select2 select col-12 form-control" style="width: 100%;">
            <mat-option *ngFor="let abono of ProductosAbono" [value]="abono.pkId">
              {{abono.Nombre}}
            </mat-option>
          </mat-select> -->
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="PVKSelected || TaqAutSelected">
      <div class="col-sm-12">
      <mat-form-field class="input-select" appearance="outline">
        <mat-label>{{translation.Prefijo}}</mat-label>
        <input matInput type="text" placeholder="{{translation.Prefijo}}" formControlName="chPrefijo">
      </mat-form-field>
      </div>
    </div>

    <!--  <div class="form-group row" *ngIf="PVKSelected || TaqAutSelected">
      <div class="col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Prefijo}}</mat-label>
          <label>
            <input matInput type="text" placeholder="{{translation.Prefijo}}" formControlName="chPrefijo"
              [(ngModel)]="model.chPrefijo">
          </label>
        </mat-form-field>
      </div>
    </div> -->

    <div class="form-group row" *ngIf="IntSelected || AdminSelected">
      <div class="col-sm-12">
        <!-- TIPO ENTRADA -->
      <ng-container *ngIf="editorRegistroForm">
        <ng-container *ngIf="editorRegistroForm.value.tipoGrupoInternet !=null">
          <app-input-filter *ngIf="editorRegistroForm" formControlText="tipoGrupoInternet"
          [label]="translation.GrupoInternet" [placeHolderTranslation]="translation.GrupoInternet"
          [list]="filteredInternet | async" textShow="Nombre" dataInput="pkId" required="true"
          [data]="editorRegistroForm.value.tipoGrupoInternet.pkId" (close)="closeInputFilter($event)"></app-input-filter>
        </ng-container>
        <ng-container *ngIf="editorRegistroForm.value.tipoGrupoInternet == null">
          <app-input-filter *ngIf="editorRegistroForm" formControlText="tipoGrupoInternet"
          [label]="translation.GrupoInternet" [placeHolderTranslation]="translation.GrupoInternet"
          [list]="filteredInternet | async" textShow="Nombre" dataInput="pkId" required="true"
          (close)="closeInputFilter($event)"></app-input-filter>
        </ng-container>
        </ng-container>
       
        <!-- <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.GrupoInternet}}</mat-label>
          <input type="text" placeholder="{{translation.GrupoInternet}}" matInput formControlName="tipoGrupoInternet"
            [matAutocomplete]="internet">
          <mat-autocomplete #internet="matAutocomplete" [displayWith]="displayFnInternet">
            <mat-option *ngFor="let tipoGruposGi of filteredInternet | async" [value]="tipoGruposGi">
              {{tipoGruposGi.Nombre}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field> -->
        <!-- <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.GrupoInternet}}</mat-label>
          <mat-select formControlName="tipoGrupoInternet" [(ngModel)]="model.iGrupoInternetId">
            <mat-option [value]="0">--</mat-option>
            <mat-option *ngFor="let tipoGruposGi of tiposGInternet" [value]="tipoGruposGi.pkId">
              {{tipoGruposGi.Nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>

    <div class="form-group row" *ngIf="IntSelected || AdminSelected">
      <div class="col-sm-12">
        <mat-slide-toggle color="primary" formControlName="agrupada" id="tarifaAgrupadaCheck4">
          {{translation.TarifaAgrupada}}</mat-slide-toggle>
      </div>
    
    </div>

    <!------------------------CONDICIONAL MOSTRAR IDIOMAS------------------------>
    <div  *ngIf="IntSelected || AdminSelected">
    <div *ngFor="let idioma of editorListaIdiomas">
      <div class="form-group row">
        <div class="col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>{{translation.NombreInternet}} ({{idioma.CodIdioma}})</mat-label>
            <label>
              <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                formControlName="{{'nombre' + idioma.CodIdioma}}">
            </label>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
            <label>
              <input matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                [value]="idioma.Descripcion" formControlName="{{'Descripcion' + idioma.CodIdioma}}">
            </label>
          </mat-form-field>
        </div>
      </div>
    </div>
    </div>
    <!--------------------------------------------->
    <!-----------------fechas---------------------->
    <!--------------------------------------------->
   <div class="clearfix">
      <div class="pull-left">
        <h4 class="text-blue h4">{{translation.PeriodoDeCompra}}</h4>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput formControlName="fechaDesdeCompra" [matDatepicker]="dpdesdecompra"
            placeholder="{{translation.PeriodoDeCompra}}" [min]="fechaFV_minDesde" [max]="fechaPC_maxHasta"
            (dateChange)="cambiaFCdesde($event)" [value]="getFechaFCInicio()">
          <mat-datepicker-toggle matSuffix [for]="dpdesdecompra"></mat-datepicker-toggle>
          <mat-datepicker #dpdesdecompra></mat-datepicker>
        </mat-form-field>
      </div> -->
    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput placeholder="{{translation.PeriodoDeCompra}}" formControlName="fechaDesdeCompra"
            [matDatepicker]="dpdesdecompra" [min]="fechaFV_minDesde" [max]="fechaPC_maxHasta" (dateChange)="reloadVal('fechaHastaCompra')">
          <mat-datepicker-toggle matSuffix [for]="dpdesdecompra"></mat-datepicker-toggle>
          <mat-datepicker #dpdesdecompra></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaHasta}}</mat-label>
          <input matInput formControlName="fechaHastaCompra" [matDatepicker]="dphastacompra"
            placeholder="{{translation.FechaHasta}}" [min]="fecha_minHasta" (dateChange)="cambiaFChasta($event)"
            [value]="getFechaFCFin()">
          <mat-datepicker-toggle matSuffix [for]="dphastacompra"></mat-datepicker-toggle>
          <mat-datepicker #dphastacompra></mat-datepicker>
        </mat-form-field>
      </div>
    </div>



    <div class="clearfix">
      <div class="pull-left">
        <h4 class="text-blue h4">{{translation.PeriodoDeVisita}}</h4>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-slide-toggle color="primary" (change)="sinFechaFin()" formControlName="sinFechaFin" id="sinFechaFin">
          {{translation.SinFechaFin}}</mat-slide-toggle>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput formControlName="fechaDesdeVisita" [matDatepicker]="dpdesdevisita"
            placeholder="{{translation.FechaDesde}}" [min]="fechaFV_minDesde" [max]="fechaFV_maxDesde"
            (dateChange)="cambiaFVdesde($event)" [value]="getFechaFVInicio()">
          <mat-datepicker-toggle matSuffix [for]="dpdesdevisita"></mat-datepicker-toggle>
          <mat-datepicker #dpdesdevisita></mat-datepicker>
        </mat-form-field>
      </div> -->
    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaDesde}}</mat-label>
          <input matInput placeholder="{{translation.FechaDesde}}" formControlName="fechaDesdeVisita"
            [matDatepicker]="dpdesdevisita" [min]="fechaFV_minDesde" [max]="fechaFV_maxDesde" (dateChange)="reloadVal('fechaHastaVisita')">
          <mat-datepicker-toggle matSuffix [for]="dpdesdevisita"></mat-datepicker-toggle>
          <mat-datepicker #dpdesdevisita></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.FechaHasta}}</mat-label>
          <input matInput formControlName="fechaHastaVisita" [matDatepicker]="dphastavisita"
            placeholder="{{translation.FechaHasta}}" [min]="fechaFV_minHasta" (dateChange)="cambiaFVhasta($event)"
            [value]="getFechaFVFin()" [disabled]="this.editorRegistroForm.get('sinFechaFin').value">
          <mat-datepicker-toggle matSuffix [for]="dphastavisita"></mat-datepicker-toggle>
          <mat-datepicker #dphastavisita></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <!------------FIN FECHAS--------------->

    <div class="clearfix">
      <div class="pull-left">
        <h4 class="text-blue h4">{{translation.HoraDeVisita}}</h4>
      </div>
    </div>

    <!-- <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.HoraInicio}}</mat-label>
          <input matInput formControlName="horarioDeVisitaInicio" type="time" [(ngModel)]="model.HoraIni" step="2"
            placeholder="{{translation.SeleccionaHoraInicio}}" [value]="getHoraInicio()">
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.HoraInicio}}</mat-label>
          <input matInput formControlName="horarioDeVisitaFin" type="time" [(ngModel)]="model.HoraFin" step="2"
            placeholder="{{translation.SeleccionaHoraFin}}" [value]="getHoraFin()">
        </mat-form-field>
      </div>
    </div> -->
    <div class="form-group row">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.HoraInicio}}</mat-label>
          <input matInput formControlName="horarioDeVisitaInicio" type="time" step="2"
            placeholder="{{translation.SeleccionaHoraInicio}}" [value]="getHoraInicio()" (change)="reloadVal('horarioDeVisitaFin')">
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.HoraInicio}}</mat-label>
          <input matInput formControlName="horarioDeVisitaFin" type="time"  step="2"
            placeholder="{{translation.SeleccionaHoraFin}}" [value]="getHoraFin()">
        </mat-form-field>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.DiasConDisponibilidad}}</mat-label>
          <mat-select placeholder="{{translation.DiasEnLosQueEstaraDisponible}}" formControlName="tipoControlDias"
            name="tipoDias" multiple 
            (selectionChange)="CambioDiasSemanaMultiple($event)">
            <mat-option value="1">{{translation.Lunes}}</mat-option>
            <mat-option value="2">{{translation.Martes}}</mat-option>
            <mat-option value="3">{{translation.Miercoles}}</mat-option>
            <mat-option value="4">{{translation.Jueves}}</mat-option>
            <mat-option value="5">{{translation.Viernes}}</mat-option>
            <mat-option value="6">{{translation.Sabado}}</mat-option>
            <mat-option value="7">{{translation.Domingo}}</mat-option>
          </mat-select>
          <mat-hint>
            {{translation.ParaSeleccionarTodaLaSemanaHayQueDejarTodosLosDiasSinMarcar}}
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <!------------------------------------------------------------------------>
    <!------------------------------------------------------------------------>
    <!------------------------------------------------------------------------>
    <!-------------------------CAMPOS INDIVIDUALES---------------------------->
    <!------------------------------------------------------------------------>
    <!------------------------------------------------------------------------>
    <!------------------------MOSTRAR TODOS----------------------------------->
    <!--<div class="form-group row" *ngIf="PVKSelected || TaqAutSelected">-->
    <div class="form-group row" *ngIf="platSelected !== '2'">
      <div class="col-sm-12 col-md-4">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoIVA}}</mat-label>
          <mat-select formControlName="tipoIVA" (selectionChange)="cambiapvp('pvp')">
            <mat-option value="0">{{translation.ExentoONA}}</mat-option>
            <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.TipoIvaId">
              {{tipoGruposIC.Iva}}%
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.PVP}}</mat-label>
          <input matInput (change)="cambiapvp('pvp', $event)" formControlName="pvp" type="number"
            class="example-right-align" placeholder="{{translation.PVP}}"><!-- [(ngModel)]="model.PrecioPVP" -->
          <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
          <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
          <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
          <div *ngIf="f.pvp.errors" class="invalid-feedback">
            <mat-error *ngIf="f.pvp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.BaseImponible}}</mat-label>
          <input matInput (change)="cambiabaseImp('bimp', $event)" formControlName="bimp" type="number"
            class="example-right-align" placeholder="{{translation.BaseImponible}}">
          <span matSuffix>{{simbolo}}</span>
          <div *ngIf="f.bimp.errors" class="invalid-feedback">
            <mat-error *ngIf="f.bimp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>
    </div>
    <!------------------------------------------------------------------------->

    <div class="form-group row">
      <div class="col-sm-12">
        <mat-slide-toggle color="primary" formControlName="desglosarGastosGestion" id="gastosDeGestionCheck4">
          {{translation.GastosDeGestion}}</mat-slide-toggle>
      </div>
    </div>

    <!---------------------------SEGUNDA FILA--------------------------------------------->
    <div class="form-group row" *ngIf="platSelected !== '2'">
      <div class="col-sm-12 col-md-4">
        <mat-form-field class="input-select" appearance="outline">
          <mat-label>{{translation.TipoIVAComision}}</mat-label>
          <mat-select formControlName="tipoControlTIVAComision" (selectionChange)="cambiapvp('pvpc')">
            <mat-option value="0">{{translation.ExentoONA}}</mat-option>
            <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.TipoIvaId">
              {{tipoGruposIC.Iva}}%
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.PVPComision}}</mat-label>
          <input matInput (change)="cambiapvp('pvpc', $event)" formControlName="pvpc" type="number"
            class="example-right-align" placeholder="{{translation.BaseImponible}}" value="">
          <span matSuffix>{{simbolo}}</span>
          <div *ngIf="f.pvpc.errors" class="invalid-feedback">
            <mat-error *ngIf="f.pvpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.BaseImponibleComision}}</mat-label>
          <input matInput (change)="cambiabaseImp('bimpc', $event)" formControlName="bimpc" value=""
            class="example-right-align" type="number" placeholder="{{translation.BaseImponibleComision}}">
          <span matSuffix>{{simbolo}}</span>
          <div *ngIf="f.bimpc.errors" class="invalid-feedback">
            <mat-error *ngIf="f.bimpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>
    </div>
    <!---------------------------------------------------------------------->
    <!---------------------------------------------------------------------->
    <!------------------------MOSTRAR SOLO PVP------------------------------>
    <!---------------------------------------------------------------------->
    <!---------------------------------------------------------------------->
    <div class="form-group row" *ngIf="platSelected === '2'">
      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.PVP}}</mat-label>
          <input matInput (change)="cambiapvpTabla('pvp')" formControlName="pvp" type="number"
            class="example-right-align" placeholder="{{translation.PVP}}" >
          <!-- <input matInput (change)="cambiapvpTabla('pvp')" formControlName="pvp" type="number"
            class="example-right-align" placeholder="{{translation.PVP}}" [(ngModel)]="model.PrecioPVP"> -->
          <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
          <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
          <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
          <div *ngIf="f.pvp.errors" class="invalid-feedback">
            <mat-error *ngIf="f.pvp.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.PVPComision}}</mat-label>
          <input matInput (change)="cambiapvpTabla('pvpc')" formControlName="pvpc" type="number"
            class="example-right-align" placeholder="{{translation.PVP}}" >
          <!-- <input matInput (change)="cambiapvpTabla('pvpc')" formControlName="pvpc" type="number"
            class="example-right-align" placeholder="{{translation.PVP}}" [(ngModel)]="model.Comision"> -->
          <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
          <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
          <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
          <div *ngIf="f.pvpc.errors" class="invalid-feedback">
            <mat-error *ngIf="f.pvpc.errors.pattern">{{translation.ValorNumerico}}</mat-error>
          </div>
        </mat-form-field>
      </div>
    </div>
    <!-----------------------FIN CAMPOS SOLO PVP------------------------------>

    <div class="form-group row">
      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Minimo}}</mat-label>
          <label>
            <input matInput type="number" value="" class="example-right-align"
              placeholder="{{translation.Minimo}}" formControlName="minimo">
            <!-- <input matInput type="number" value="" [(ngModel)]="model.Minimo" class="example-right-align"
              placeholder="{{translation.Minimo}}" formControlName="minimo"> -->
          </label>
          <div class="invalid-feedback">
            <!-- <div *ngIf="f.minimo.errors" class="invalid-feedback"> -->
            <mat-error>{{translation.ValorNumerico}}</mat-error>
            <!-- <mat-error *ngIf="f.minimo.pattern">{{translation.ValorNumerico}}</mat-error> -->
          </div>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Maximo}}</mat-label>
          <label>
            <input matInput type="number" value="" (change)="minimoValidator()" 
              class="example-right-align" placeholder="{{translation.Maximo}}" formControlName="maximo">
            <!-- <input matInput type="number" value="" (change)="minimoValidator()" [(ngModel)]="model.Maximo"
              class="example-right-align" placeholder="{{translation.Maximo}}" formControlName="maximo"> -->
          </label>
          <div *ngIf="f.maximo.errors" class="invalid-feedback">
            <!-- <div *ngIf="f.maximo.errors" class="invalid-feedback"> -->
            <mat-error>{{translation.ValorNumerico}}</mat-error>
            <!--<mat-error *ngIf="f.maximo.pattern">{{translation.ValorNumerico}}</mat-error>-->
          </div>
        </mat-form-field>
      </div>

      <div class="col-sm-12 col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>{{translation.Orden}}</mat-label>
          <label>
            <input matInput value="" (change)="minimoValidator()" class="example-right-align"
              formControlName="orden" type="number" placeholder="{{translation.Orden}}">
           <!--  <input matInput value="" (change)="minimoValidator()" [(ngModel)]="model.Orden" class="example-right-align"
              formControlName="orden" type="number" placeholder="{{translation.Orden}}"> -->
          </label>
          <div *ngIf="f.orden.errors" class="invalid-feedback">
            <mat-error>{{translation.ValorNumerico}}</mat-error>
            <!--                       <mat-error *ngIf="f.orden.pattern">{{translation.ValorNumerico}}</mat-error>
-->
          </div>
        </mat-form-field>
      </div>
    </div>
    <!------------------------------>

    <!-- tabla ripeada TODO::: a la espera de tener un componente unico para unificar todas las tablas -->
    <!--<div class="pd-20 card-box mb-30" *ngIf="platSelected === '2'">
      <div class="clearfix mb-20">
        <div class="pull-left">
          <h4 class="text-blue h4">Tarifas Promoción Entrada</h4>
        </div>
      </div>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Nombre Entrada</th>
            <th scope="col">Porcentaje</th>
            <th scope="col">Tipo IVA</th>
            <th scope="col">PVP</th>
            <th scope="col">Base Imponible</th>
            <th scope="col">Tipo IVA Comisión</th>
            <th scope="col">PVP Comisión</th>
            <th scope="col">Base Imponible Comisión</th>
            <th scope="col">Nombre</th>
            <th scope="col">Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of TarifaPromocion">
            <td>{{item.NomTipoEntrada}}</td>

            <td>
              {{item.Porcentaje}}%
            </td>

            <td>
              <select class="custom-select col-12"
                (input)="actualizarTarifaPromocion(item.pkId, 'TipoIVA', $event.target.value)">
                <option value="0">{{translation.ExentoONA}}</option>
                <option *ngFor="let tipoGruposIC of tiposIVA" [value]="tipoGruposIC.pkId">
                  {{tipoGruposIC.Nombre}}
                </option>
              </select>
            </td>

            <td>
              <input type="text" value="{{item.Precio}}" class="form-control table-size1"
                (input)="actualizarTarifaPromocion(item.pkId, 'Precio', $event.target.value)">
            </td>

            <td>
              <input type="text" value="{{item.BaseImponible}}" class="form-control table-size1"
                (input)="actualizarTarifaPromocion(item.pkId, 'BaseImponible', $event.target.value)">
            </td>

            <td>
              <select class="custom-select col-12"
                (input)="actualizarTarifaPromocion(item.pkId, 'TipoIVAComision', $event.target.value)">
                <option value="0">{{translation.ExentoONA}}</option>
                <option *ngFor="let tipoGruposIC of tiposIVA" [value]="tipoGruposIC.pkId">
                  {{tipoGruposIC.Nombre}}
                </option>
              </select>
            </td>

            <td>
              <input type="text" value="{{item.Comision}}" class="form-control table-size1"
                (input)="actualizarTarifaPromocion(item.pkId, 'Comision', $event.target.value)">
            </td>

            <td>
              <input type="text" value="{{item.ComisionBI}}" class="form-control table-size1"
                (input)="actualizarTarifaPromocion(item.pkId, 'ComisionBI', $event.target.value)">
            </td>

            <td>
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'NombreESES', $event.target.value)"
                type="text" value="{{item.Idiomas.NombreESES}}" placeholder="es-ES"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'NombreCAES', $event.target.value)"
                type="text" value="{{item.Idiomas.NombreCAES}}" placeholder="ca-ES"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'NombreDEDE', $event.target.value)"
                type="text" value="{{item.Idiomas.NombreDEDE}}" placeholder="de-DE"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'NombreENGB', $event.target.value)"
                type="text" value="{{item.Idiomas.NombreENGB}}" placeholder="en-GB"
                class="form-control table-size">
            </td>

            <td>
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'DescripcionESES', $event.target.value)"
                type="text" value="{{item.Idiomas.DescripcionESES}}" placeholder="es-ES"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'DescripcionCAES', $event.target.value)"
                type="text" value="{{item.Idiomas.DescripcionCAES}}" placeholder="ca-ES"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'DescripcionDEDE', $event.target.value)"
                type="text" value="{{item.Idiomas.DescripcionDEDE}}" placeholder="de-DE"
                class="form-control table-size">
              <input (input)="actualizarIdiomaPromocion(item.pkId, 'DescripcionENGB', $event.target.value)"
                type="text" value="{{item.Idiomas.DescripcionENGB}}" placeholder="en-GB"
                class="form-control table-size">
            </td>
          </tr>
        </tbody>
      </table>
    </div>-->
    <!-- fin tabla ripeada -->


    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!-------------------------------NUEVA TABLA----------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <div class="pull-left" *ngIf="TarifaPromocion && platSelected === '2'">
      <h4 class="text-blue h4">Tarifas Promoción Entrada</h4>
    </div>
    <br>
    <br>
    <div class="mat-elevation-z8 overflow-table" *ngIf="TarifaPromocion && platSelected === '2'">
      <table mat-table *ngIf="platSelected === '2'" [dataSource]="TarifaPromocion" multiTemplateDataRows>
        <ng-container matColumnDef="{{column}}" *ngFor="let column of columnasTabla; let i = index"
          [stickyEnd]="i === columnasTabla.length - 1 || i === 0">
          <th mat-header-cell *matHeaderCellDef> {{column}} </th>
          <td mat-cell *matCellDef="let element">
            <p *ngIf="i === 0">{{element.NomTipoEntrada}}</p>

            <!--BASES IMPONIBLES-->
            <mat-form-field
              *ngIf="i !== columnasTabla.length - 1 && i !== 0 && i !== 1 && i !== 2 && i !== 3 && i !== 6 && i !== 4 && i !== 7"
              appearance="outline" style="width: 100px; margin: 0 10px" >
              <label>
                <input matInput type="number" placeholder="{{column}}"
                  (change)="revesBaseImponibleDesdeTabla(i === 5 ? 'baseImponible' : 'baseImponibleComision', element)"
                  class="example-right-align" formControlName="{{columnasVanila[i] + element.pkId}}">
              </label>
              <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
              <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
              <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
            </mat-form-field>

            <!--Porcentaje-->
            <p *ngIf="i === 1 && element.rPorcentaje">{{element.rPorcentaje}}%</p>
            <p *ngIf="i === 1 && element.Porcentaje">{{element.Porcentaje}}%</p>

            <!--Cantidad ....REEMPLAZAR!!-->
            <p *ngIf="i === 2 && element.iNumEntradas">{{element.iNumEntradas}}</p>
            <p *ngIf="i === 2 && element.NumEntradas">{{element.NumEntradas}}</p>

            <!--Tipo iva-->
            <mat-form-field class="input-select" appearance="outline" *ngIf="i === 3" >
              <mat-label>{{translation.TipoIVA}}</mat-label>
              <mat-select  formControlName="{{'tipoIva' + element.pkId}}"
                (selectionChange)="cambiapvpDesdeTabla('pvp')">
                <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.TipoIvaId">
                  {{tipoGruposIC.Iva}}%
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!---PVPs-->
            <mat-form-field *ngIf="i === 4 || i === 7" appearance="outline" style="width: 100px; margin: 0 10px"
              >
              <label>
                <input matInput type="number" placeholder="{{column}}" class="example-right-align"
                  (change)="cambiapvpDesdeTabla(i === 4 ? 'pvp' : 'pvpc')"
                  formControlName="{{(i === 4 ? 'pvp' : 'pvpComision') + element.pkId}}">
              </label>
              <span matSuffix *ngIf="PosicionDivisa == 'D'">{{simbolo}}</span>
              <span matSuffix *ngIf="SimboloDerecha">{{SimboloDerecha}}</span>
              <span matPrefix *ngIf="PosicionDivisa == 'I'">{{simbolo}}&nbsp;</span>
            </mat-form-field>

            <!--Tipo iva COMISION-->
            <mat-form-field class="input-select" appearance="outline" *ngIf="i === 6">
              <mat-label>{{translation.TipoIVAComision}}</mat-label>
              <mat-select  formControlName="{{'tipoIvaComision' + element.pkId}}"
                (selectionChange)="cambiapvpDesdeTabla('pvpc')">
                <mat-option value="0">{{translation.ExentoONA}}</mat-option>
                <mat-option *ngFor="let tipoGruposIC of calendarioTiposIVA" [value]="tipoGruposIC.TipoIvaId">
                  {{tipoGruposIC.Iva}}%
                </mat-option>
              </mat-select>
            </mat-form-field>

            <!--ICONO-->
            <div *ngIf="i === columnasTabla.length - 1">
              <button mat-icon-button (click)="expandedElement = expandedElement === element ? null : element">
                <mat-icon>more_vert</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnasTabla.length">
            <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
              <div *ngFor="let idioma of editorListaIdiomas" class="form-group row">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Nombre {{idioma.CodIdioma}}</mat-label>
                    <label>
                      <input matInput formControlName="{{'nombre' + idioma.CodIdioma + element.pkId}}" type="text"
                        placeholder="{{'nombre' + idioma.CodIdioma}}" />
                    </label>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field appearance="outline" style="width: 100%">
                    <mat-label>Descripcion {{idioma.CodIdioma}}</mat-label>
                    <label>
                      <input matInput formControlName="{{'Descripcion' + idioma.CodIdioma + element.pkId}}" type="text"
                        placeholder="{{'Descripcion' + idioma.CodIdioma}}" />
                    </label>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnasTabla"></tr>
        <tr mat-row *matRowDef="let element; columns: columnasTabla" class="example-element-row"
          [class.example-expanded-row]="expandedElement === element"
          >
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <!----------------------------------------------------------------------------------->
    <br>

    <div style="margin: 20px 5px 0 5px; text-align: center;">
      <mat-label>{{translation.Centros}}</mat-label>

      <customan-dual-list [source]="centrosSinAsignar"
      [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
      </customan-dual-list>
    </div>

  </form>
</div>
</div>