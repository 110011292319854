import { ChangeDetectorRef, EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import data from 'src/app/shared/jsons/tables/tables.json';
import { Filter } from '../../classes/filters/filter';
import { table } from '../../classes/tables/table';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../alerts/alert/alert.service';
import { LowerCasePipe } from '@angular/common';
import moment, { localeData } from 'moment';
import { Usuario_response } from '../../classes/userResponse/usuarioResponse/usuario_response';
import { GlobalService } from '../global/global.service';
import { Request_tarifa_list } from '../../classes/tarifas/request_tarifa_list';
import { Tarifas } from '../../classes/tarifaResponse/tarifas';
import { Tarifas_response } from '../../classes/tarifaResponse/tarifas_response';
import { Tarifas_show } from '../../classes/tarifaResponse/tarifas_show';
import { Select_option } from '../../classes/tables/select_option';
import { UserType_response } from '@sharedV11/classes/userType/UserType_response';
import { MatSort, Sort } from '@angular/material/sort';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
// import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { InternetGroups_response } from '@sharedV11/classes/tarifaResponse/internetgroups_response';
import { InternetGroup } from '@sharedV11/classes/tarifaResponse/internetgroup';
import { InternetGroups_show } from '@sharedV11/classes/tarifaResponse/internetgroups_show';
import { Client_show } from '@sharedV11/classes/userResponse/clientResponse/client_show';
import { Paginator } from '@sharedV11/classes/paginator/paginator';
import { Translator } from '../translator/translator.service';
import { first, takeUntil, map } from 'rxjs/operators';
import { formas_pago_Respuesta } from '@sharedV11/classes/userRequest/clientes/formas-pago-Respuesta.model';
import { TiposSesion_show } from '@sharedV11/classes/tables/tipossesion_show';
import { SinButaca_show } from '@sharedV11/classes/tables/sinbutaca_show';
import { Breakpoints } from '@angular/cdk/layout';
import { grupos_tipos_entrada_Respuesta } from '@sharedV11/classes/EntradasPromociones/GrupoEntradas/grupo_tipos_entrada_Respuesta';
import { EntryType } from '@sharedV11/classes/entradas-y-promociones/tipo-entrada/entry-type';
import { EditUserService } from '../components/edit_user/edit-user.service';
import { UserType } from '@sharedV11/classes/userType/user_type';
import { Customan_usuario } from '@sharedV11/classes/userResponse/usuarioResponse/customan_usuario';
import { async } from '@angular/core/testing';
import { DatosResult } from '../../classes/clients/client/usuario-Respuesta.model';
import { EntryTypeService } from '../components/entradas-y-promociones/entry-type/entry-type.service';
import { ItemsMotivosGroup } from '../../classes/EntradasPromociones/ItemsMotivosGroup/items_motivos_group';
import { ItemsMotivosGruposV11Service } from '../components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service';
import { GrupoTiposEntrada } from '@sharedV11/classes/EntradasPromociones/GrupoEntradas/GrupoTiposEntrada';
import { RecintosVisitaService } from '../components/recintos/recintos-visita/recintos-visita.service';
import { Recintos } from '@sharedV11/classes/recintos/recintos-visita/recintos';
import { HttpErrorsService } from '../alerts/errors/http-errors.service';
import { SnackbarInfoService } from '../alerts/snackbar-info/snackbar-info.service';
import { CategoriasV11 } from '@sharedV11/classes/maestros/categorias/categoria';
import { EditTipoCodigoPromocionesService } from '../components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service';
import { EditCodigoPromocionesService } from '../components/edit-codigo-promociones/edit-codigo-promociones.service';
import { Tpvs } from '@sharedV11/classes/maestros/tpvs/tpvs';
import { EspectaculosService } from '../espectaculos/espectaculos.service';




@Injectable({
  providedIn: 'root'
})



export class FilterService {
  respuestaAPI: any;
  table: BehaviorSubject<table>;
  dataSource: MatTableDataSource<any>;

  dataTable: any;
  dataTableFilter: BehaviorSubject<any> = new BehaviorSubject('');

  private ngUnsubscribe = new Subject();

  paginator: Paginator;
  filters: Filter[] = [];
  translation: any;

  userTypes: UserType[] = [];

  grupos: any[] = [];

  private dataTableFilterSubject: BehaviorSubject<any>;
  public dataTableFilter$: Observable<any>;

  private tableSubject: BehaviorSubject<any>;
  public table$: Observable<any>;

  tableLoaded: EventEmitter<boolean> = new EventEmitter();

  constructor(
    public servicioAPI: ConectarApiService,
    public espectaculosService: EspectaculosService,
    public entryTypeService: EntryTypeService,
    public alertaServicio: AlertService,
    public global: GlobalService,
    public translator: Translator,
    public editUserService: EditUserService,
    public itemsMotivosGruposService: ItemsMotivosGruposV11Service,
    public recintosVisitaService: RecintosVisitaService,
    public httpErrorService: HttpErrorsService,
    public snackvar_service: SnackbarInfoService,
    public editTipoCodigoPromocionesService: EditTipoCodigoPromocionesService,
    public editCodigoPromocionesService:EditCodigoPromocionesService

  ) {

    this.inicitializeSubjectsAndObservable();

    this.translation = this.translator.GetTranslations();
  }

  inicitializeSubjectsAndObservable() {

    this.dataTableFilterSubject = new BehaviorSubject<any>('');
    this.dataTableFilter$ = this.dataTableFilterSubject.asObservable();


    let l_table: table = { 'tableName': null, 'filterByApi': true, 'maxItems': 10, 'columns': null };

    this.tableSubject = new BehaviorSubject<table>(l_table);
    this.table$ = this.tableSubject.asObservable();


  }

  get dataTableFilterValue() {
    return this.dataTableFilterSubject.value;
  }

  public setdataTableFilter(dataTable) {
    this.dataTableFilterSubject.next(dataTable);
  }


  get tableValue() {
    return this.tableSubject.value;
  }

  public setTable(table) {
    console.log("Set Table ", table);
    //table.columns.push({"name":"updated","type":"boolean","BBDD":"chCodigo","filter":false,"column":false});
    this.tableSubject.next(table);
  }


  findTable(table) {
    this.filters = [];
    console.log("Find Table ", table);
    this.setTable(data.tables.find(data => data.tableName == table));

  }

  loadSelect(columnName, data, columnData, columnValue) {
    
    var col;
    if (this.tableValue.columns)  {
      col = this.tableValue.columns.find(find => find.name == columnName);
    }
    const array = [];
    data.map(dataColumn => {
      const option = new Select_option();
      option.name = dataColumn[columnData];
      option.value = dataColumn[columnValue];
      array.push(option);

    });
    if (col && col.filterOptions) col.filterOptions.selectOptions = array;
 
  }



  changeTable(table: table) {
    this.setTable(table);
  }

  checkType(data: Filter) {
    return this.tableValue.columns.find(find => find.name == data.name).type;
  }

  filterInRquest() {
    const filterFind = this.filters.find(find => find.name == 'keyValue');
    //console.log('filterFind: ', filterFind);
    if (filterFind != undefined) {
      this.filterByAll(filterFind);

    }
  }
  filterByString(data, elem: Filter) {
    // console.log(data[elem.name].toLowerCase())
    if (data[elem.name] != undefined) {

      return data[elem.name].toLowerCase().includes(elem.value.toLowerCase());
    } else {
      return false;
    }
  }

  filterByNumber(data, elem: Filter) {
    // console.log(data[elem.name].toLowerCase())
    if (data[elem.name] != undefined) {

      if (data[elem.name] == elem.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  filterBySelect(data, elem: Filter) {
    // console.log(data[elem.name].toLowerCase())
    if (data[elem.name] != undefined) {

      if (data[elem.name] == elem.value) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
  filterByRangeDate(data, elem: Filter) {
    // console.log(data[elem.name].toLowerCase())
    if (data[elem.name] != undefined) {

      const dates = elem.value.split('-');
      const dateStart = moment(dates[0], 'DD/MM/YYYY');
      const dateEnd = moment(dates[1], 'DD/MM/YYYY');
      const dataToCompare = moment(data[elem.name], 'DD/MM/YYYY');


      if (dataToCompare.isBetween(dateStart, dateEnd)) {
        return true;
      } else {
        return false;

      }
    } else {
      return false;
    }
  }

  filterByDate(data, elem: Filter) {
    // console.log(data[elem.name].toLowerCase())
    if (data[elem.name] != undefined) {

      const date = moment(elem.value, 'DD/MM/YYYY');
      const dataToCompare = moment(data[elem.name]);
      if (date == dataToCompare) {
        return true;
      } else {
        return false;

      }
    } else {
      return false;
    }
  }

  selectFunctionByType(type, data, elem) {
    /*   console.log(type)
      console.log(data)
      console.log(elem) */

    let flag = false;
    switch (type) {
      case 'string':
        flag = this.filterByString(data, elem);
        break;
      case 'number':
        flag = this.filterByNumber(data, elem);
        break;

      case 'select':
        flag = this.filterBySelect(data, elem);
        break;
      case 'rangeDate':
        flag = this.filterByRangeDate(data, elem);
        break;
      case 'date':
        flag = this.filterByDate(data, elem);
        break;
      default:
        break;
    }
    return flag;
  }

  filterByAll(filter: Filter) {
    const filterIndex = this.filters.findIndex(find => find.name == 'keyValue');
    console.log(filterIndex);
    if (filterIndex != -1) {
      this.filters[filterIndex].value = filter.value;
    } else {
      this.filters.push(filter);
    }
    console.log(this.filters);
    const filterdb = this.dataTableFilterValue.filter = filter.value;

    console.log(this.dataSource.filteredData);

  }
  filterByPkId(param){
    console.log(this.tableValue)
    console.log(this.tableValue.tableName)
    let filter=[{db: "iCodigoCliente",filterApi: "Codigo",name: "Codigo",show: param,translation: "CodigoCliente",value: param}]
    this.filter(this.tableValue.tableName,filter)
  }
  filter(tablename, filters: Filter[]) {
    console.log(tablename,filters)

    if (this.tableValue.filterByApi) {
      this.filters = [];
      this.filters = [...filters];
      this.paginator.page = 1
      this.manageGetDataDB(tablename, this.paginator);
    } else {
      // filters  table
      let booleanArray = [];
      const data = this.dataTable.data.filter(data => {
        booleanArray = [];
        for (let i = 0; i < filters.length; i++) {
          let flag = false;

          const elem = filters[i];
          if (elem.name != 'keyValue') {
            const type = this.checkType(elem);
            flag = this.selectFunctionByType(type, data, elem);
            // this.filterByString(data,elem)
            booleanArray.push(flag);
          }
        }

        if (booleanArray.find(map => map == false) == false) {
          return false;
        } else {
          return true;
        }

      });
      this.setdataTableFilter(this.convertToMatTable(data));
    }
  }

  convertToMatTable(data) {
    return new MatTableDataSource(data);
  }

  manageFilterGetDataDB(tableName, parent_pkId?) {
    this.tableLoaded.emit(false);
    switch (tableName) {
      case 'perfilUsuarios':
        this.cargarDatosCentros();
      break;
      case 'tarifas':
        // this.cargarDatoTipoEntrada();
        this.cargarDatosGruposInternet();
        this.cargarDatosCentros();
        break;

      case 'cliente':
        this.cargarDatosTipoClientes();
        this.cargarDatosTiposPagos();
        this.cargarDatosCentros();
      break;
      
      case 'clientGroup':
        this.cargarDatosCentros();
      break;

      case 'clientType':
        this.cargarDatosCentros();
      break;

      case 'gruposinternet':
        this.cargarDatosTiposReserva();
        this.cargarDatosCentros();
        break;

      case "tipossesion":
        this.cargarRecintosTipossesion();
        this.cargarIdionmas();
        this.cargarDatosCentros();

        break;

      case 'sinbutaca':
        this.cargarRecintos();
        this.cargarTiposSesion();
        this.cargarIdionmas();
        this.cargarDatosCentros();
        break;
      case 'usuarios':
        this.cargarDatosCentros();
        this.cargarDatosTipoUsuarios();
        break;
      case 'entryType':
        this.cargarDatosTipoVisita();
        this.cargarDatosCategoria();
        this.cargarDatosTipoEntrada();
        this.cargarDatosGrupoMotivo();
        this.cargarDatosTipoPublico();
        this.cargarDatosCentros();
        break;
      case 'tipobono':
        this.cargarDatosTipoGrupos();
        this.cargarDatosTiposProductos();
        this.cargarDatosTipoVisita_tiposbono();
        this.cargarDatosTiposNivel();
        this.cargarDatosCategorias();
        this.cargarDatosGruposAsociados();
        //this.cargarDatosMotivosGrupos();
        this.cargarDatosGrupoMotivo();

        this.cargarTiposEntradaDatos();
        this.cargarDatosCentros();
        break;
      case 'itemsMotivosGrupos':
        this.cargarDatosGrupos();
        this.cargarDatosCentros();
        break;
      case 'recintosVisitas':
        this.cargarGrupoEntradas();
        this.cargarGrupoRecintos();
        this.cargarGrupoActividadesTaller();
        this.cargarTipoAforo();
        this.cargarTipoControl();
        this.cargarDatosCentros();
        break;
      case 'gruporecintos':
        this.cargarCentrosCombo();
        this.cargarDatosCentros();
        break;
      case 'categorias':
        this.cargarDatosCentros();
        break;
      case 'festivos':
        this.cargarDatosCentros();
        break;     
      case 'formaspago':
        this.cargarDatosCentros();
        break;
      case 'grupoentrada':
        this.cargarDatosCentros();
        break;     
      case 'grupoTipoBono':
        this.cargarDatosCentros();
        break;    
      case 'gruposactividadtaller':
        this.cargarDatosCentros();
        break;   
      case 'tiposreservas':
        this.cargarDatosCentros();
        break;
      case 'typeAccessControl':
        this.cargarDatosCentros();
      break;                                          
      case 'tpvs':
        this.cargarListadeLugares();
        this.cargarDatosCentros();
        break;
      case "tbTiposCodigosPromocionales":
        this.cargarListadeClientes();
        this.cargarDatosCentros();
      break;
      case 'espectaculos':
        this.cargarGrupoEntradas();
        this.cargarGrupoRecintos();
        this.cargarDatosCentros();
        break;   
      case 'sesionesespectaculos':
        this.cargarGrupoRecintos();
        this.cargarEspectaculosData();
        //this.cargarDatosCentros();
        break;   
            
          
      default:
        break;
    }
  }

  manageGetDataDB(tableName, paginator: Paginator, parent_pkId?) {
    this.manageFilterGetDataDB(tableName);
    this.tableLoaded.emit(false);
    this.alertaServicio.limpiar();
    this.paginator = paginator;
    const filters = [...this.filters];
    filters.map(filter => {
      filter.name = filter.filterApi;
    });
    const filterIndex = filters.findIndex(find => find.name == 'keyValue');
    if (filterIndex != -1) {
      filters.splice(filterIndex, 1);
    }
    switch (tableName) {
      case 'perfilUsuarios':
        this.cargarDatosPerfilUsuariosAsync(paginator, filters);
        break;
      case 'usuarios':
        this.cargarDatosusUariosAsync(paginator, filters);
        break;
      case 'tarifas':
        // this.filters.push({ name : "pkId",value:"1",translation:"",db:"" })
        this.cargarDatosTarifaAsync(paginator, filters);
        break;
      case 'gruposinternet':
        this.cargarDatosGruposInternetAsync(paginator, filters);
        break;
      case "cliente":

        this.cargarDatosClienteAsync(tableName, paginator, filters);
        break;
      case 'clientGroup':
        this.cargarDatosClientGroupAsync(tableName, paginator, filters);
        break;
      case 'clientType':
       
        this.cargarDatosClientTypeAsync(tableName, paginator, filters);
        break;        
      case 'typeAccessControl':
        this.cargarDatosTiposControl(paginator, filters);
        break;
      case "tipossesion":
        this.cargarDatosTiposSesionAsync(paginator, filters);
        break;
      case "sinbutaca":
        this.cargarDatosSinButacaAsync(paginator, filters);
        break;
      case "formaspago":
        this.cargarFormaspago(paginator, filters);
        break;
      case "grupoentrada":
        this.cargarGrupoTipoEntrada(paginator, filters);
        break;
      case "recintosVisitas":
        this.cargarDatosRecintosVisita(paginator, filters);
        break;
      case "entryType":
        this.cargarDatosEntryTypes(paginator, filters);
        break;
      case "tipobono":
        this.cargarDatosTipoBono(paginator, filters);
        break;
      case "itemsMotivosGrupos":
        this.cargarGruposItemsMotivosGrupos(paginator, filters);
        break;
      case "grupoTipoBono":
        this.cargarGruposTipoBono(paginator, filters);
        break;
      case "gruporecintos":
        this.cargarGruposRecintos(paginator, filters);
        break;
      case "gruposactividadtaller":
        this.cargarGruposActividadTaller(paginator, filters);
        break;
      case "categorias":
        this.cargarCategorias(paginator, filters);
        break;
      case "tpvs":
        this.cargarTpvs(paginator, filters);
        break;  
      case "festivos":
        this.cargarFestivos(paginator, filters);
        break;  
        case "tiposreservas":
          this.cargarTiposreservas(paginator, filters);
          break;  
      case "tbTiposCodigosPromocionales":
        this.tbTiposCodigosPromocionales(paginator, filters);
        break;
      case "tbCodigosPromocionales":

        this.tbCodigosPromocionales(paginator, filters);
        break;
      case "espectaculos":
        this.cargarEspectaculos(paginator, filters);
        break;
      case "sesionesespectaculos":
        this.cargarSesionesEspectaculos(paginator, filters);
        break;
      default:
        break;
    }
  }
  tbCodigosPromocionales(paginator,filters){
    this.editCodigoPromocionesService.GetIacpos(paginator, filters).subscribe(
      async data => {

        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformtbCodigosPromocionales(this.respuestaAPI.DatosResult.lista);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log(listaToShow)
          console.log(paginator.pagelements)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          console.log(this.dataSource)

          this.loadData(this.dataSource);
          this.filterInRquest();

        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }
  transformtbCodigosPromocionales(promociones: any[]) {
    const listCategoriasShow: CategoriasV11[] = [];
    promociones.map(value => {
      const NewCategorias :any ={};

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(NewCategorias, value, 'pkId', 'pkId');
      this.assignDataByObj(NewCategorias, value, 'Codigo', 'Codigo');
      this.assignDataByObj(NewCategorias, value, 'TipoCodigoPromocional', 'TipoDeCodigo');
      this.assignDataByObj(NewCategorias, value, 'FechaVisitaInicio', 'FechaVisitaInicio');
      this.assignDataByObj(NewCategorias, value, 'FechaVisitaFin', 'FechaVisitaFin');
      this.assignDataByObj(NewCategorias, value, 'FechaCompraInicio', 'FechaCompraInicio');
      this.assignDataByObj(NewCategorias, value, 'FechaCompraFin', 'FechaCompraFin');
      this.assignDataByObj(NewCategorias, value, 'Habilitado', 'Habilitado');

      console.log(NewCategorias)
      listCategoriasShow.push(NewCategorias);
    });
    console.log(listCategoriasShow)
    return listCategoriasShow;
  }
  
  tbTiposCodigosPromocionales(paginator,filters){
    this.editTipoCodigoPromocionesService.getTiposCodigoPromocional(paginator, filters).subscribe(
      async data => {

        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformtbTiposCodigosPromocionales(this.respuestaAPI.DatosResult.ListaCodigoPromocional);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log(listaToShow)
          console.log(paginator.pagelements)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          console.log(this.dataSource)

          this.loadData(this.dataSource);
          this.filterInRquest();

        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  cargarListadeClientes() {
    let clientes;
    this.servicioAPI.traerDatosClientes().subscribe(data => {
      
      if (data.DatosListas && data.DatosListas.length > 0 ) {
        if (data.DatosListas.length < 10000) 
          localStorage.setItem('ClientesLista', JSON.stringify(data.DatosListas));
        clientes = data.DatosListas;
      } else {

        clientes = [];
      }
      this.loadSelect('CodigoClienteFilter', clientes, 'Nombre', 'Id');
      
      return clientes;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarListadeLugares() {
    let lugares;
    this.servicioAPI.getLugares().subscribe(data => {
      
      if (data.DatosResult.customanLugares && data.DatosResult.customanLugares.length > 0) {
        localStorage.setItem('LugaresLista', JSON.stringify(data.DatosResult.customanLugares));
        lugares = data.DatosResult.customanLugares;
      } else {

        lugares = [];
      }
      this.loadSelect('LugarFilter', lugares, 'Nombre', 'pkId');
      
      return lugares;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  /*

  */
  transformtbTiposCodigosPromocionales(promociones: any[]) {
    const ListaCodigoPromocionalShow: any[] = [];
    promociones.map(value => {
      const CodigoPromocional :any ={};

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(CodigoPromocional, value, 'pkId', 'pkId');
      this.assignDataByObj(CodigoPromocional, value, 'Nombre', 'Nombre');
      this.assignDataByObj(CodigoPromocional, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(CodigoPromocional, value, 'CodigoClienteFilter', 'CodigoCliente');

      switch (value.DeBaja) {
        case '0':
          this.assignDataByValue(CodigoPromocional, "Baja", "No");
          break;
        case '1':
          this.assignDataByValue(CodigoPromocional, "Baja", "Si");
          break;
      }


      let ListaCliente: any = localStorage.getItem('ClientesLista');
      if (ListaCliente != undefined) {
        ListaCliente = JSON.parse(ListaCliente);
        const codigodecliente = ListaCliente.find(find => find.Id == value.CodigoCliente);
        if (codigodecliente != null) {
          this.assignDataByValue(CodigoPromocional, 'CodigoClienteFilter', codigodecliente.Nombre);
        }
      }
      
      console.log(CodigoPromocional)
      ListaCodigoPromocionalShow.push(CodigoPromocional);
    });
    console.log(ListaCodigoPromocionalShow)
    return ListaCodigoPromocionalShow;
  }
  cargarCategorias(paginator, filters) {
    //customanGrupoActividadTaller
    this.servicioAPI.getCategorias(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformCategorias(this.respuestaAPI.DatosResult.lista);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log(listaToShow)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }



  

  
  transformCategorias(categorias: any[]) {
    const listCategoriasShow: CategoriasV11[] = [];
    categorias.map(value => {
      const NewCategorias = new CategoriasV11();

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(NewCategorias, value, 'pkId', 'pkId');
      this.assignDataByObj(NewCategorias, value, 'Nombre', 'chNombre');
      this.assignDataByObj(NewCategorias, value, 'Descripcion', 'chDescripcion');
      this.assignDataByObj(NewCategorias, value, 'Orden', 'Orden');
      this.assignDataByObj(NewCategorias, value, 'CentrosNombres', 'CentrosNombres');


      console.log(NewCategorias)
      listCategoriasShow.push(NewCategorias);
    });
    console.log(listCategoriasShow)
    return listCategoriasShow;
  }


  cargarGruposActividadTaller(paginator, filters) {
    //customanGrupoActividadTaller
    this.servicioAPI.getGruposActividadTaller(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.customanGrupoActividadTaller);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  cargarTpvs(paginator, filters) {
    //customanGrupoActividadTaller
    this.servicioAPI.getTPVs(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        console.log("data",data);
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformTPVs(this.respuestaAPI.DatosResult.ListaTPVs);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log("listaToShow",listaToShow)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  transformTPVs(categorias: any[]) {
    const listTpvsShow: Tpvs[] = [];
    categorias.map(value => {
      const NewTpv = new Tpvs();

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(NewTpv, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTpv, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewTpv, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewTpv, value, 'IpAddress', 'DireccionIP');
      this.assignDataByObj(NewTpv, value, 'NombrePC', 'VMPath');
      this.assignDataByObj(NewTpv, value, 'CentrosNombres', 'CentrosNombres');
      //this.assignDataByObj(NewTpv, value, 'LugarFilter', 'LugarId');

      let ListaLugar: any = localStorage.getItem('LugaresLista');
      if (ListaLugar != undefined) {
        ListaLugar = JSON.parse(ListaLugar);
        const codigodelugar = ListaLugar.find(find => find.pkId == value.LugarId);
        if (codigodelugar != null) {
          this.assignDataByValue(NewTpv, 'LugarFilter', codigodelugar.Nombre);
        }
      }

      switch (value.DeBaja) {
        case 0:
          this.assignDataByValue(NewTpv, "DeBaja", "No");
          break;
        case 1:
          this.assignDataByValue(NewTpv, "DeBaja", "Si");
          break;
      }

      switch (value.Asignado) {
        case 0:
          this.assignDataByValue(NewTpv, "Asignado", "No");
          break;
        case 1:
          this.assignDataByValue(NewTpv, "Asignado", "Si");
          break;
      }

      console.log("NewTpv",NewTpv,value.bDeBaja)
      listTpvsShow.push(NewTpv);
    });
    console.log(listTpvsShow)
    return listTpvsShow;
  }


  
  cargarFestivos(paginator, filters) {
    //customanGrupoActividadTaller
    this.servicioAPI.getFestivos(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        console.log("data",data);
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformFestivos(this.respuestaAPI.DatosResult.listaFestivos);
          this.dataSource = new MatTableDataSource(listaToShow);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  transformFestivos(festivos: any[]) {
    const listTpvsShow: Tpvs[] = [];
    festivos.map(value => {
      const NewFestivo = new Tpvs();

      this.assignDataByObj(NewFestivo, value, 'pkId', 'pkId');
      this.assignDataByObj(NewFestivo, value, 'Motivo', 'Motivo');
      this.assignDataByValue(NewFestivo,  "Fecha", moment(value.Fecha, "DD/MM/YYYY").format("DD-MM-YYYY"));
      this.assignDataByObj(NewFestivo, value, 'CentrosNombres', 'CentrosNombres');
/*       this.assignDataByValue(NewFestivo, "HoraInicio1Venta", value.HoraInicio.split(" ")[1]);
      this.assignDataByValue(NewFestivo, "HoraFin1Venta", value.HoraInicio.split(" ")[1]);
      this.assignDataByValue(NewFestivo, "HoraInicio2Venta", value.HoraInicio.split(" ")[1]);
      this.assignDataByValue(NewFestivo, "HoraFin2Venta", value.HoraInicio.split(" ")[1]);
      this.assignDataByValue(NewFestivo, "HoraInicio", value.HoraInicio.split(" ")[1]); */



      switch (value.Venta) {
        case '0':
          this.assignDataByValue(NewFestivo, "Venta", "No");
          break;
        case '1':
          this.assignDataByValue(NewFestivo, "Venta", "Si");
          break;
      }

      switch (value.Reserva) {
        case '0':
          this.assignDataByValue(NewFestivo, "Reserva", "No");
          break;
        case '1':
          this.assignDataByValue(NewFestivo, "Reserva", "Si");
          break;
      }

      listTpvsShow.push(NewFestivo);
    });
    console.log(listTpvsShow)
    return listTpvsShow;
  }

  cargarTiposreservas(paginator, filters) {
    //customanGrupoActividadTaller
    this.servicioAPI.getTiposreservas(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        console.log("data",data);
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformTiposreservas(this.respuestaAPI.DatosResult.listaTipoReserva);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log("listaToShow",listaToShow)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  transformTiposreservas(tiposreservas: any[]) {
    const listTpvsShow: Tpvs[] = [];
    tiposreservas.map(value => {
      const NewTiporeserva = new Tpvs();

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(NewTiporeserva, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTiporeserva, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewTiporeserva, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewTiporeserva, value, 'MaximoInternet', 'MaximoInternet');
      this.assignDataByObj(NewTiporeserva, value, 'MinimoInternet', 'MinimoInternet');
      this.assignDataByObj(NewTiporeserva, value, 'CentrosNombres', 'CentrosNombres');

      switch (value.deBaja) {
        case '0':
          this.assignDataByValue(NewTiporeserva, "Baja", "No");
          break;
        case '1':
          this.assignDataByValue(NewTiporeserva, "Baja", "Si");
          break;
      }

      switch (value.Internet) {
        case '0':
          this.assignDataByValue(NewTiporeserva, "Internet", "No");
          break;
        case '1':
          this.assignDataByValue(NewTiporeserva, "Internet", "Si");
          break;
      }
      listTpvsShow.push(NewTiporeserva);
    });
    console.log(listTpvsShow)
    return listTpvsShow;
  }


  cargarGruposRecintos(paginator, filters) {
    this.servicioAPI.getGruposRecintos(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = await this.transFormGruposRecintos(this.respuestaAPI.DatosResult.ListaGrupoRecintos);
          this.dataSource = new MatTableDataSource(listaToShow);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  async transFormGruposRecintos(gruposRecintos: any[]) {
    let listaToShow: any[] = [];

    let centros: any[] = [];
    await this.entryTypeService.getCentrosAsync().then(centro => {
      centro.DatosResult.ListaCentros.map(cent => {
        centros.push(cent);
      });
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

    console.log("Centros en transFormGruposRecintos: ", centros);

    gruposRecintos.map(value => {
      let NewGrupoRecinto: any = {};
      this.assignDataByObj(NewGrupoRecinto, value, 'pkId', 'pkId');
      this.assignDataByObj(NewGrupoRecinto, value, 'Activo', 'Activo');
      this.assignDataByObj(NewGrupoRecinto, value, 'CentroId', 'CentroId');
      this.assignDataByObj(NewGrupoRecinto, value, 'Idiomas', 'Idiomas');
      this.assignDataByObj(NewGrupoRecinto, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewGrupoRecinto, value, 'subGrupo', 'subGrupo');
      this.assignDataByObj(NewGrupoRecinto, value, 'CentrosNombres', 'CentrosNombres');

      switch (value.Activo) {
        case '0':
          this.assignDataByValue(NewGrupoRecinto, "Activo", "False");
          break;
        case '1':
          this.assignDataByValue(NewGrupoRecinto, "Activo", "True");
          break;
      }

      switch (value.subGrupo) {
        case '0':
          this.assignDataByValue(NewGrupoRecinto, "subGrupo", "False");
          break;
        case '1':
          this.assignDataByValue(NewGrupoRecinto, "subGrupo", "True");
          break;
      }

      const nombreCentro = centros.find(centro => centro.pkId === NewGrupoRecinto.CentroId);

      if (nombreCentro != undefined) {
        this.assignDataByValue(NewGrupoRecinto, "CentroId", nombreCentro.Nombre);
      }

      listaToShow.push(NewGrupoRecinto);
    });
    return listaToShow;
  }



  cargarDatosTipoBono(paginator, filters) {
    this.servicioAPI.getTiposBonos(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          let tiposBonoList: any;
          tiposBonoList = this.respuestaAPI.DatosResult.lista;

          await this.transFormDataTipoBonoToShow(tiposBonoList).then(data => {
            tiposBonoList = data
          })

          this.dataSource = new MatTableDataSource(tiposBonoList);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados
          this.dataSource["pagelements"] = paginator.pagelements
          this.loadData(this.dataSource);
          this.filterInRquest()
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cargarGruposTipoBono(paginator, filters) {
    console.log("En cargarGruposTipoBono paginator es: ", paginator);
    console.log("En cargarGruposTipoBono filters es: ", filters);
    this.servicioAPI.getGrupoTipoBono(paginator, filters).subscribe(
      data => {
        this.respuestaAPI = data;
        console.log("Valor de this.respuestaAPI: ", this.respuestaAPI)
        if (this.respuestaAPI.DatosResult != null) {

          this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.customanGrupoTipoBono);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);

          this.filterInRquest();
        } else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  cargarGruposItemsMotivosGrupos(paginator, filters) {
    this.getGrupos();
    this.servicioAPI.getItemsMotivosGrupo(paginator, filters).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {

          const listaToShow = this.transFormGruposItemsMotivosGrupos(this.respuestaAPI.DatosResult.customanItemMotGrupo);

          this.dataSource = new MatTableDataSource(listaToShow);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);

          this.filterInRquest();
        } else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();



        }
        this.tableLoaded.emit(true);
      },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  async transFormDataTipoBonoToShow(tiposBono: any[]) {
    let results = []
    await this.servicioAPI.getGruposTiposEntradaAsync().then(data => {
      results = data.DatosResult.customanGrupoTipoEntrada
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
    const listTiposBonoShow: any[] = [];

    tiposBono.map(value => {
      let NewTipoBono: any = {};

      this.assignDataByObj(NewTipoBono, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTipoBono, value, 'chCodigo', 'chCodigo');
      this.assignDataByObj(NewTipoBono, value, 'chNombrePromo', 'chNombrePromo');
      this.assignDataByObj(NewTipoBono, value, 'chDescripcionNombre', 'chDescripcionNombre');
      this.assignDataByObj(NewTipoBono, value, 'chTextoPantalla', 'chTextoPantalla');
      this.assignDataByObj(NewTipoBono, value, 'chTextoImpresion', 'chTextoImpresion');
      this.assignDataByObj(NewTipoBono, value, 'AlaVenta', 'ALaVenta');


      this.assignDataByObj(NewTipoBono, value, 'iValidez', 'iValidez');
      this.assignDataByObj(NewTipoBono, value, 'iGrupoAsociadoIdFilter', 'iGrupoAsociadoId');
      this.assignDataByObj(NewTipoBono, value, 'iTipoVisitaIdFilter', 'iTipoVisitaId');
      this.assignDataByObj(NewTipoBono, value, 'iTipoNivelIdFilter', 'iTipoNivelId');
      this.assignDataByObj(NewTipoBono, value, 'iCategoriaIdFilter', 'iCategoriaId');
      this.assignDataByObj(NewTipoBono, value, 'TipoProductoId', 'TipoProductoId');
      this.assignDataByObj(NewTipoBono, value, 'iTEDatosIdFilter', 'iTEDatosId');
      this.assignDataByObj(NewTipoBono, value, 'CodigoBarras', 'CodigoBarras');
      this.assignDataByObj(NewTipoBono, value, 'BarCodeInternet', 'BarCodeInternet');
      this.assignDataByObj(NewTipoBono, value, 'GrupoMotivoIdFilter', 'GrupoMotivoId');
      this.assignDataByObj(NewTipoBono, value, 'CentrosNombres', 'CentrosNombres');
      
      
 
      switch (value.chTipoValidez) {
        case 'D':
          this.assignDataByValue(NewTipoBono, "chTipoValidez", "Dias")
          break;
        case 'M':
          this.assignDataByValue(NewTipoBono, "chTipoValidez", "Meses")
          break;
        case 'S':
          this.assignDataByValue(NewTipoBono, "chTipoValidez", "Sin Caducidad")
          break;          
      }

      switch (value.BarCodeInternet) {
        case '0':
          this.assignDataByValue(NewTipoBono, "BarCodeInternet", "No")
          break;
        case '1':
          this.assignDataByValue(NewTipoBono, "BarCodeInternet", "Si")
          break;
      }

      switch (value.CodigoBarras) {
        case '0':
          this.assignDataByValue(NewTipoBono, "CodigoBarras", "No")
          break;
        case '1':
          this.assignDataByValue(NewTipoBono, "CodigoBarras", "Si")
          break;
      }

      switch (value.bALaVenta) {
        case '0':
          this.assignDataByValue(NewTipoBono, "bALaVenta", "No")
          break;
        case '1':
          this.assignDataByValue(NewTipoBono, "bALaVenta", "Si")
          break;
      }


      let listadoGruposAsociados: any = localStorage.getItem('ListadoGruposAsociados');
      if (listadoGruposAsociados != undefined) {
        listadoGruposAsociados = JSON.parse(listadoGruposAsociados);
        const dataTiposVisita = listadoGruposAsociados.find(find => find.pkId == value.iGrupoAsociadoId);
        if (dataTiposVisita != null) {

          this.assignDataByValue(NewTipoBono, 'iGrupoAsociadoIdFilter', dataTiposVisita.Descripcion);
        }
      }
      let tiposVisita: any = localStorage.getItem('TipoVisita');
      if (tiposVisita != undefined) {
        tiposVisita = JSON.parse(tiposVisita);
        const dataTiposVisita = tiposVisita.find(find => find.pkId == value.iTipoVisitaId);
        if (dataTiposVisita != null) {

          this.assignDataByValue(NewTipoBono, 'iTipoVisitaIdFilter', dataTiposVisita.Descripcion);
        }
      }
      let GruposMotivoId: any = localStorage.getItem('GrupoMotivoId');
      if (GruposMotivoId != undefined) {
        GruposMotivoId = JSON.parse(GruposMotivoId);
        const dataTiposVisita = GruposMotivoId.find(find => find.pkId == value.GrupoMotivoId);
        if (dataTiposVisita != null) {

          this.assignDataByValue(NewTipoBono, 'GrupoMotivoIdFilter', dataTiposVisita.NombreGrupo);
        }
      }

      let gruposasociados: any = localStorage.getItem('NombreGrupos');
      if (gruposasociados != undefined) {
        gruposasociados = JSON.parse(gruposasociados);
        const datagruposasociados = gruposasociados.find(find => find.pkId == value.iGrupoId);
        if (datagruposasociados != null) {

          this.assignDataByValue(NewTipoBono, 'iGrupoIdFilter', datagruposasociados.NombreGrupo);
        }
      }

      let TipoNivel: any = localStorage.getItem('TipoNivel');
      if (TipoNivel != undefined) {
        TipoNivel = JSON.parse(TipoNivel);
        const dataniveles = TipoNivel.find(find => find.pkId == value.iTipoNivelId);
        if (dataniveles != null) {

          this.assignDataByValue(NewTipoBono, 'iTipoNivelIdFilter', dataniveles.Descripcion);
        }
      }

      let TipoProducto: any = localStorage.getItem('TipoProducto');
      if (TipoProducto != undefined) {
        TipoProducto = JSON.parse(TipoProducto);
        const dataniveles = TipoProducto.find(find => find.pkId == value.iTipoProductoId);
        if (dataniveles != null) {

          this.assignDataByValue(NewTipoBono, 'iTipoProductoIdFilter', dataniveles.Descripcion);
        }
      }

      let Categorias: any = localStorage.getItem('Categorias');
      if (Categorias != undefined) {
        Categorias = JSON.parse(Categorias);
        const datacategorias = Categorias.find(find => find.pkId == value.iCategoriaId);
        if (datacategorias != null) {

          this.assignDataByValue(NewTipoBono, 'iCategoriaIdFilter', datacategorias.chNombre);
        }
      }

      let TipoEntradaDatos: any = localStorage.getItem('TipoEntradaDatos');
      if (TipoEntradaDatos != undefined) {
        TipoEntradaDatos = JSON.parse(TipoEntradaDatos);
        const tipoentradadatos = TipoEntradaDatos.find(find => find.pkId == value.iTEDatosId);
        if (tipoentradadatos != null) {

          this.assignDataByValue(NewTipoBono, 'iTEDatosIdFilter', tipoentradadatos.Nombre);
        }
      }




      listTiposBonoShow.push(NewTipoBono);


    });

    return listTiposBonoShow;
  }



  getGrupos() {
    this.itemsMotivosGruposService.getGruposTiposEntrada("").pipe(takeUntil(this.ngUnsubscribe)).subscribe(item => {
      if (item.DatosResult != null) {
        this.grupos = item.DatosResult.customanGrupoTipoEntrada.filter(x => x.ConMotivo.trim().toLowerCase() == 'true' || x.ConMotivo.trim().toLowerCase() == '1');
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  transFormGruposItemsMotivosGrupos(imGroup: any[]) {
    const listaToShow: ItemsMotivosGroup[] = [];
    imGroup.map(value => {
      const NewIMG = new ItemsMotivosGroup();
      this.assignDataByObj(NewIMG, value, 'pkId', 'pkId');
      this.assignDataByObj(NewIMG, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewIMG, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewIMG, value, 'Seleccionable', 'Seleccionable');
      this.assignDataByObj(NewIMG, value, 'GrupoIdFilter', 'GrupoIdFilter');
      this.assignDataByObj(NewIMG, value, 'CentrosNombres', 'CentrosNombres');

      if (this.grupos != undefined) {
        const imgSelec = this.grupos.find(find => find.pkId === value.GrupoId);
        if (imgSelec != undefined) {
          this.assignDataByValue(NewIMG, 'GrupoIdFilter', imgSelec.NombreGrupo);
        }
      }

      switch (value.Seleccionable) {
        case '0':
          this.assignDataByValue(NewIMG, "Seleccionable", "No")
          break;
        case '1':
          this.assignDataByValue(NewIMG, "Seleccionable", "Si")
          break;
      }

      listaToShow.push(NewIMG);
    });
    return listaToShow;
  }

  transFormRecintosVisitas(imGroup: any[]) {
    const listaToShow: Recintos[] = [];
    imGroup.map(value => {
      const NewObj = new Recintos();
      this.assignDataByObj(NewObj, value, 'pkId', 'pkId');
      this.assignDataByObj(NewObj, value, 'Nombre', 'Nombre');
      this.assignDataByValue(NewObj, 'Activo', this.global.aBooleano(value.Activo));
      this.assignDataByValue(NewObj, 'ControlarAforo', this.global.aBooleano(value.ControlarAforo));
      this.assignDataByObj(NewObj, value, 'AforoMaximo', 'AforoMaximo');
      this.assignDataByValue(NewObj, 'HoraInicio', moment(value.HoraInicio).format('HH:mm:ss'));
      this.assignDataByValue(NewObj, 'HoraFin', moment(value.HoraFin).format('HH:mm:ss'));
      this.assignDataByValue(NewObj, 'MinimoAforo', value.MinimoAforo);
      this.assignDataByValue(NewObj, 'AforoInternet', value.MaximoInternet);
      this.assignDataByValue(NewObj, 'NumProj', value.NumProj);
      this.assignDataByValue(NewObj, 'Taller', this.global.aBooleano(value.Taller));
      this.assignDataByValue(NewObj, 'MostrarInternet', this.global.aBooleano(value.MostrarInternet));
      this.assignDataByObj(NewObj, value, 'CentrosNombres', 'CentrosNombres');

      if (value.TipoAforo) {

        let aforo = this.global.tiposAforo.find(find => find.id.toLowerCase() == value.TipoAforo.toLowerCase()).value
        this.assignDataByValue(NewObj, 'TipoAforoFilter', aforo);
      }
      let grupoRecintos: any = localStorage.getItem('grupoRecintos');
      if (grupoRecintos != undefined) {
        grupoRecintos = JSON.parse(grupoRecintos);
        const datagrupoRecintos = grupoRecintos.find(find => find.pkId == value.GrupoRecintosId);
        if (datagrupoRecintos != null) {

          this.assignDataByValue(NewObj, 'GrupoRecintosIdFilter', datagrupoRecintos.Nombre);
        }
      }
      let grupoActividadesTaller: any = localStorage.getItem('grupoActividadTaller');
      if (grupoActividadesTaller != undefined) {
        grupoActividadesTaller = JSON.parse(grupoActividadesTaller);
        const dataGrupoActividadesTaller = grupoActividadesTaller.find(find => find.pkId == value.GrupoActividadId);
        if (dataGrupoActividadesTaller != null) {

          this.assignDataByValue(NewObj, 'GrupoActividadIdFilter', dataGrupoActividadesTaller.Nombre);
        }
      }
      let tipoControl: any = localStorage.getItem('tipoControl');
      if (tipoControl != undefined) {
        tipoControl = JSON.parse(tipoControl);
        const dataTipoControl = tipoControl.find(find => find.pkId == value.CodigoTipoControl);
        if (dataTipoControl != null) {

          this.assignDataByValue(NewObj, 'TipoControl', dataTipoControl.Nombre);
        }
      }

      listaToShow.push(NewObj);
    });
    return listaToShow;
  }

  async cargarDatosRecintosVisita(paginator, filters) {
    console.log("Paginator es :", paginator, " y filters : ", filters);
    this.servicioAPI.getRecintos(paginator, filters).subscribe(
      data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {

          let listaToShow = this.transFormRecintosVisitas(this.respuestaAPI.DatosResult.ListaRecintos);

          this.dataSource = new MatTableDataSource(listaToShow);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          //this.dataSource.paginator = this.paginator;
          //this.dataSource.sort = this.sort;
          this.loadData(this.dataSource);

          this.filterInRquest();
        } else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar');
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.alertaServicio.error(error);
      }
    );
  }

  async cargarDatosEntryTypes(paginator, filters) {
    let filtroTarifa: Request_tarifa_list = new Request_tarifa_list();
    this.servicioAPI.getTiposEntradas(paginator, filters).subscribe(
      async data => {

        this.respuestaAPI = data;

        if (this.respuestaAPI.DatosResult != null) {
          let entryTypesList: any;
          //let listatemp:  Map<string, Tarifas>;
          //console.log(this.respuestaAPI.DatosResult.ListaTarifas);
          //ListaTarifa = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaTarifas);
          entryTypesList = this.respuestaAPI.DatosResult.Lista;

          //let tarifasListShow = this.transFormDataTarifaToShow(ListaTarifa);
          // console.log(tarifasListShow)
          await this.transFormDataEntryTypesToShow(entryTypesList).then(data => {
            entryTypesList = data
          })

          this.dataSource = new MatTableDataSource(entryTypesList);
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados
          this.dataSource["pagelements"] = paginator.pagelements
          //this.dataSource.paginator = this.paginator;
          //this.dataSource.sort = this.sort;
          this.loadData(this.dataSource);

          this.filterInRquest()



          //this.columnsToDisplay = this.displayedColumns.slice();
          //F Idiomas
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }

  async transFormDataEntryTypesToShow(entryTypes: any[]) {
    let results = []
    await this.servicioAPI.getGruposTiposEntradaAsync().then(data => {
      results = data.DatosResult.customanGrupoTipoEntrada
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
    const listEntryTypesShow: any[] = [];
    entryTypes.map(value => {
      let NewEntryType: any = {};



      this.assignDataByObj(NewEntryType, value, 'pkId', 'pkId');
      this.assignDataByObj(NewEntryType, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewEntryType, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewEntryType, value, 'TextoPantalla', 'TextoPantalla');
      this.assignDataByObj(NewEntryType, value, 'ControlarAcceso', 'ControlarAcceso');
      this.assignDataByObj(NewEntryType, value, 'CentrosNombres', 'CentrosNombres');
      this.assignDataByObj(NewEntryType, value, 'AlaVenta', 'ALaVenta');
      let grupoId = results.find(find => find.pkId == value.GrupoId)

      let GrupoMotivoId = results.find(find => find.pkId == value.GrupoMotivoId)


      let tiposPublicos: any = localStorage.getItem('TipoPublico');
      if (tiposPublicos != undefined) {
        tiposPublicos = JSON.parse(tiposPublicos);
        const datatiposPublicos = tiposPublicos.find(find => find.pkId == value.TipoPublicoId);
        if (datatiposPublicos != null) {

          this.assignDataByValue(NewEntryType, 'TipoPublicoFilter', datatiposPublicos.Descripcion);
        }
      }

      if (grupoId) 
        this.assignDataByValue(NewEntryType, "GrupoIdFilter", grupoId.NombreGrupo)

      if (GrupoMotivoId)
        this.assignDataByValue(NewEntryType, "GrupoMotivoIdFilter", GrupoMotivoId.NombreGrupo)

      listEntryTypesShow.push(NewEntryType);
    });
    return listEntryTypesShow;
  }

  transFormDataTiposControlToShow(dataResult: Array<any>) {
    let listTarifasShow: any[] = []

    let arr_comprobaciones: Array<any> = [{ id: 'CompHoraria', value: 'Hora' }, { id: 'PermisoSinFecha', value: 'Sin Fecha' }, { id: 'CompDiaria', value: 'Diaria' }
      , { id: 'CompDiaSemana', value: 'Dia Semana' }, { id: 'CompIntervalo', value: 'Intervalo' }, { id: 'CompPasoPrevio', value: 'Paso Previo' }
      , { id: 'CompCalendario', value: 'Calendario' }, { id: 'CompCalendHorario', value: 'Calendario Horario' }, { id: 'DenegarAcceso', value: 'Sin Acceso' }];

    dataResult.map(value => {
      const NewTipoControl: any = value;
      let comprobacion = null;

      if (value.CompHoraria == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompHoraria').value; }
      if (value.PermisoSinFecha == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'PermisoSinFecha').value; }
      if (value.CompDiaria == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompDiaria').value; }
      if (value.CompDiaSemana == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompDiaSemana').value; }
      if (value.CompIntervalo == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompIntervalo').value; }
      if (value.CompPasoPrevio == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompPasoPrevio').value; }
      if (value.CompCalendario == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompCalendario').value; }
      if (value.CompCalendHorario == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'CompCalendHorario').value; }
      if (value.DenegarAcceso == 'True') { comprobacion = arr_comprobaciones.find(find => find.id == 'DenegarAcceso').value; }

      this.assignDataByValue(NewTipoControl, 'Comprobaciones', comprobacion);
      this.assignDataByObj(NewTipoControl, value, 'CentrosNombres', 'CentrosNombres');

      if (value.NivelEntrada) {

        let nivel =  value.NivelEntrada.toLowerCase().value ? this.global.arr_nivlelesEntrada.find(find => find.id.toLowerCase() == value.NivelEntrada.toLowerCase()).value : "";
        this.assignDataByValue(NewTipoControl, 'NivelEntrada', nivel);
      }


      listTarifasShow.push(NewTipoControl);
    });
    return listTarifasShow;
  }
  cargarDatosTiposControl(paginator, filters) {
    // Cargamos Datos
    this.servicioAPI.getTiposControl(paginator, filters).pipe(first())
      .subscribe(
        data => {
          this.respuestaAPI = data;
          if (this.respuestaAPI.DatosResult != null) {
            this.respuestaAPI = this.transFormDataTiposControlToShow(this.respuestaAPI.DatosResult.customanTiposControl);
            this.dataSource = new MatTableDataSource(this.respuestaAPI);
            this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
            this.dataSource['pagelements'] = paginator.pagelements;
            this.loadData(this.dataSource);
            // console.log(this.dataSource);
            this.filterInRquest();
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          }
          this.tableLoaded.emit(true);
        },
        error => { this.httpErrorService.identificarErrores(error); }
      );
  }
  async cargarDatosGruposInternetAsync(paginator: Paginator, filters: Filter[]) {

    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;

    await this.servicioAPI.getGruposInternet(paginator, filters).subscribe(
      data => {
        console.log('-------------', data);
        let globalInterno = this.global;
        this.respuestaAPI = new InternetGroups_response().deserialize(data);

        if (this.respuestaAPI.DatosResult != null) {
          let ListaInternetGroup: InternetGroup[];
          // let listatemp:  Map<string, Tarifas>;
          // console.log(this.respuestaAPI.DatosResult.ListaTarifas);
          ListaInternetGroup = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.listaGrupoInternet);

          const internetGroupListShow = this.transFormDataInternetGroupToShow(ListaInternetGroup);

          this.dataSource = new MatTableDataSource(internetGroupListShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.loadData(this.dataSource);


          this.filterInRquest();
          // this.columnsToDisplay = this.displayedColumns.slice();
          // F Idiomas
          this.tableLoaded.emit(true);
        }
        else {

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);

        }

      },
      error => {
        this.tableLoaded.emit(true);
        this.httpErrorService.identificarErrores(error);
      }
    );
  }


  async cargarDatosTiposSesionAsync(paginator: Paginator, filters: Filter[]) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    const _paginator = (this.tableValue.paginator as PageEvent);
    const _sort = (this.tableValue.sort as Sort);
    await this.servicioAPI.getTiposSesion(paginator, filters).subscribe(
      data => {
        let globalInterno = this.global;
        console.log('data:: ', data);
        //this.respuestaAPI = new InternetGroups_response().deserialize(data);
        this.respuestaAPI = data;
        
        if (this.respuestaAPI.DatosResult != null) {
          let ListaTiposSesion = [];
          // let listatemp:  Map<string, Tarifas>;
          // console.log(this.respuestaAPI.DatosResult.ListaTarifas);
          ListaTiposSesion = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.Lista);

          const internetGroupListShow = this.transFormDataTiposSesionToShow(ListaTiposSesion);

          this.dataSource = new MatTableDataSource(internetGroupListShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.loadData(this.dataSource);

          this.filterInRquest();

          // this.columnsToDisplay = this.displayedColumns.slice();
          // F Idiomas
          this.tableLoaded.emit(true);
        }
        else {
          console.log("Servicio DATA: ", this.respuestaAPI.Mensajes[0].DescripcionMensaje);
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }

      },
      error => {
        this.alertaServicio.error(error);
      }
    );
  }

  async cargarDatosSinButacaAsync(paginator: Paginator, filters: Filter[]) {
    this.tableValue.paginator = new MatPaginator(new MatPaginatorIntl, null);
    this.tableValue.sort = MatSort;
    const _paginator = (this.tableValue.paginator as PageEvent);
    const _sort = (this.tableValue.sort as Sort);
    await this.servicioAPI.getSinButacas(paginator, filters).subscribe(
      data => {
        let globalInterno = this.global;
        this.tableLoaded.emit(true);
        console.log('data:: ', data);
        this.respuestaAPI = new InternetGroups_response().deserialize(data);
        if (this.respuestaAPI.DatosResult != null) {
          let ListaSinButaca = [];
          // let listatemp:  Map<string, Tarifas>;
          // console.log(this.respuestaAPI.DatosResult.ListaTarifas);
          ListaSinButaca = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.Lista);

          const internetGroupListShow = this.transFormDataSinButacaToShow(ListaSinButaca);

          this.dataSource = new MatTableDataSource(internetGroupListShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;
          // this.dataSource.paginator = this.paginator;
          // this.dataSource.sort = this.sort;
          this.loadData(this.dataSource);

          this.filterInRquest();

          // this.columnsToDisplay = this.displayedColumns.slice();
          // F Idiomas
          this.tableLoaded.emit(true);

        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }


  async cargarDatosTarifaAsync(paginator, filters) {
    const filtroTarifa: Request_tarifa_list = new Request_tarifa_list();
    await this.servicioAPI.getTarifasAsync(paginator, filters)
      .then(
        data => {
          let globalInterno = this.global;
          this.respuestaAPI = new Tarifas_response().deserialize(data);

          if (this.respuestaAPI.DatosResult != null) {
            let ListaTarifa: Tarifas[];
            // let listatemp:  Map<string, Tarifas>;
            // console.log(this.respuestaAPI.DatosResult.ListaTarifas);
            // ListaTarifa = this.mapDiccionario2Array(this.respuestaAPI.DatosResult.ListaTarifas);
            ListaTarifa = this.respuestaAPI.DatosResult.ListaTarifas;

            console.log(ListaTarifa);
            const tarifasListShow = this.transFormDataTarifaToShow(ListaTarifa);
            console.log(tarifasListShow);

            this.dataSource = new MatTableDataSource(tarifasListShow);
            this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
            this.dataSource['pagelements'] = paginator.pagelements;
            // this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
            this.loadData(this.dataSource);

            this.filterInRquest();

            this.tableLoaded.emit(true);

            // this.columnsToDisplay = this.displayedColumns.slice();
            // F Idiomas
          }
          else {
            console.log("testtt")

          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          this.tableLoaded.emit(true);
          }
        },
        error => {
          console.log("testtt")
          this.alertaServicio.error(error);
        }
      );
  }
  cargarDatosClientGroupAsync(tableName, paginator: Paginator, filters: Filter[]) {
    /* this.tableValue.paginator = new  MatPaginator(new MatPaginatorIntl,null);
  this.tableValue.sort = MatSort;
    const _paginator = (this.tableValue.paginator as PageEvent);
    const _sort = (this.tableValue.sort as Sort); */
    this.servicioAPI.getGruposCliente(paginator, filters).subscribe(
      data => {

        console.log('.-------------.---------------: ', data);
        if (data.DatosResult != null) {

          const clientsGroupListShow: any = data.DatosResult;
          // let clientsListShow = this.transFormDataClientToShow(data.DatosResult.ResultadoBuscadorClientes);
          // console.log(clientsListShow)
          this.dataSource = new MatTableDataSource(clientsGroupListShow.customanGrupoCliente);
          this.dataSource['totalResultados'] = clientsGroupListShow.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;

          // console.log(this.dataSource.paginator = new MatPaginator(new MatPaginatorIntl,null))
          // this.dataSource.paginator = new MatPaginator(new MatPaginatorIntl,null)
          // console.log(this.dataSource.paginator)
          /*            this.dataSource.paginator.length =data.TotalResultados
           */

          // this.dataSource.paginator.length = 100
          console.log(this.dataSource);
          this.loadData(this.dataSource);

          console.log(this.filters);
          this.filterInRquest();

          // this.columnsToDisplay = this.displayedColumns.slice();
          // F Idiomas

        }
        else {
          this.snackvar_service.openSnackBar(data.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',data.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.alertaServicio.error(error);
      }
    );
  }

  cargarDatosClientTypeAsync(tableName, paginator: Paginator, filters: Filter[]) {
    /* this.tableValue.paginator = new  MatPaginator(new MatPaginatorIntl,null);
  this.tableValue.sort = MatSort;
    const _paginator = (this.tableValue.paginator as PageEvent);
    const _sort = (this.tableValue.sort as Sort); */
    this.servicioAPI.getTiposCliente(paginator, filters).subscribe(
      data => {

        console.log('.------------- getTiposCliente ---------------: ', data);
        if (data.DatosResult != null) {

          const clientsGroupListShow: any = data.DatosResult;
          // let clientsListShow = this.transFormDataClientToShow(data.DatosResult.ResultadoBuscadorClientes);
          // console.log(clientsListShow)
          this.dataSource = new MatTableDataSource(clientsGroupListShow.tipoClientes);
          this.dataSource['totalResultados'] = clientsGroupListShow.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;

          // console.log(this.dataSource.paginator = new MatPaginator(new MatPaginatorIntl,null))
          // this.dataSource.paginator = new MatPaginator(new MatPaginatorIntl,null)
          // console.log(this.dataSource.paginator)
          /*            this.dataSource.paginator.length =data.TotalResultados
           */

          // this.dataSource.paginator.length = 100
          console.log(this.dataSource);
          this.loadData(this.dataSource);

          console.log(this.filters);
          this.filterInRquest();

          // this.columnsToDisplay = this.displayedColumns.slice();
          // F Idiomas

        }
        else {
          this.snackvar_service.openSnackBar(data.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',data.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.alertaServicio.error(error);
      }
    );
  }
  
  cargarDatosClienteAsync(tableName, paginator: Paginator, filters: Filter[]) {

    this.servicioAPI.getClientes(paginator, filters).subscribe(
      data => {

        console.log('.-------------.---------------: ', data);
        if (data.DatosResult != null) {

          console.log(data.DatosResult.ResultadoBuscadorClientes);
          const clientsListShow = this.transFormDataClientToShow(data.DatosResult.ResultadoBuscadorClientes);
          console.log(clientsListShow);
          // console.log(clientsListShow)
          this.dataSource = new MatTableDataSource(clientsListShow);
          this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
          this.dataSource['pagelements'] = paginator.pagelements;

          console.log(this.dataSource);
          this.loadData(this.dataSource);

          console.log(this.filters);
          this.filterInRquest();

        }
        else {
          console.log(data);
          this.snackvar_service.openSnackBar(data.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',data.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarGrupoTipoEntrada(paginator: Paginator, filters: Filter[]) {
    this.servicioAPI.getGruposTiposEntrada(paginator, filters).pipe(first())
      .subscribe(
        data => {
          this.respuestaAPI = new grupos_tipos_entrada_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            let lista = this.transFormGrupoTipoEntradaToShow(this.respuestaAPI.DatosResult.customanGrupoTipoEntrada);
            this.dataSource = new MatTableDataSource(lista);
            this.loadData(this.dataSource);
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          }
          this.tableLoaded.emit(true);
        },
        error => { this.httpErrorService.identificarErrores(error); }
      );
  }
  transFormGrupoTipoEntradaToShow(grupos: any[]) {
    const listaGruposToShow: GrupoTiposEntrada[] = [];

    grupos.map(value => {
      const NewGroup = new GrupoTiposEntrada();

      this.assignDataByObj(NewGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewGroup, value, 'NombreGrupo', 'NombreGrupo');
      this.assignDataByObj(NewGroup, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewGroup, value, 'ConMotivo', 'ConMotivo');
      this.assignDataByObj(NewGroup, value, 'CentrosNombres', 'CentrosNombres');

      let conMotivo: string;

      (NewGroup.ConMotivo === '1') ? conMotivo = "True" : conMotivo = "False";

      this.assignDataByValue(NewGroup, 'ConMotivo', conMotivo);

      listaGruposToShow.push(NewGroup);

    });
    return listaGruposToShow;
  }
  cargarFormaspago(paginator: Paginator, filters: Filter[]) {
    //Cargamos Datos
    this.servicioAPI.getFormasPago(paginator, filters).pipe(first())
      .subscribe(
        data => {
          this.respuestaAPI = data;
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.customanFormaPago);
            this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
            this.dataSource['pagelements'] = paginator.pagelements;
            this.loadData(this.dataSource);
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          }
          this.tableLoaded.emit(true);

        },
        error => { this.httpErrorService.identificarErrores(error); }
      );
  }
  async cargarFormaspagoAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    await this.servicioAPI.getFormasPagoAsync()
      .then(
        data => {
          this.respuestaAPI = new formas_pago_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.customanFormaPago);
            //this.dataSource.paginator = this.paginator;
            //this.dataSource.sort = this.sort;
            resultado = true;
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    return resultado;
  }
  transFormDataClientToShow(clientes: any[]) {
    const listClientsShow: Client_show[] = [];
    clientes.map(value => {
      const NewClient = new Client_show();


      this.assignDataByObj(NewClient, value.DatosComunes, 'pkId', 'CodigoCliente');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Nombre', 'NombreCliente');
      this.assignDataByObj(NewClient, value.Cliente, 'Email', 'EMail');
      this.assignDataByObj(NewClient, value.Cliente, 'NombreComercial', 'NombreComercial');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Domicilio', 'Domicilio');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Localidad', 'Localidad');
      this.assignDataByObj(NewClient, value.DatosComunes, 'CodPostal', 'CP');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Provincia', 'Provincia');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Pais', 'Pais');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Telefono', 'Telefono');
      this.assignDataByObj(NewClient, value.DatosComunes, 'CIFoDNI', 'CIFoDNI');
      this.assignDataByObj(NewClient, value.DatosComunes, 'Codigo', 'CodigoCliente');
      this.assignDataByObj(NewClient, value.Cliente, 'CentrosNombres', 'CentrosNombres');


      let tiposClientes: any = localStorage.getItem('TiposClientes');
      if (tiposClientes != undefined) {
        tiposClientes = JSON.parse(tiposClientes);
        const dataTiposClientes = tiposClientes.find(find => find.pkId == value.Cliente.TipoClienteId);
        if (dataTiposClientes != null) {

          this.assignDataByValue(NewClient, 'TipoClienteFilter', dataTiposClientes.Nombre);
        }
      }
      let tiposPagos: any = localStorage.getItem('TiposPagos');

      if (tiposPagos != undefined) {
        tiposPagos = JSON.parse(tiposPagos);
        const dataTiposPagos = tiposPagos.find(find => find.pkId == value.Cliente.TipoPagoId);
        if (dataTiposPagos != null) {

          this.assignDataByValue(NewClient, 'TipoPago', dataTiposPagos.Denominacion);
        }
      }

      listClientsShow.push(NewClient);
    });
    return listClientsShow;
  }
  //Cargar tipos perfil usuarios
  async cargarDatosPerfilUsuariosAsync(paginator, filters): Promise<boolean> {
    // Cargamos Datos
    let resultado = false;
    await this.servicioAPI.getTiposUsuarioAsync(paginator, filters)
      .then(
        data => {
          this.respuestaAPI = data;
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.tipoUsuarios);
            this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
            this.dataSource['pagelements'] = paginator.pagelements;
            this.loadData(this.dataSource);
            // this.dataSource.paginator = this.paginator;
            // this.dataSource.sort = this.sort;
            this.filterInRquest();
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          }
          this.tableLoaded.emit(true);
        },
        error => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );
    return resultado;
  }
  //Darle valor a tipos de usuarios
  getUserType() {
    this.editUserService.getTiposUsuario().pipe(takeUntil(this.ngUnsubscribe)).subscribe(userTypes => {
      let respuestaAPI: UserType_response;
      respuestaAPI = new UserType_response().deserialize(userTypes);
      this.userTypes = respuestaAPI.DatosResult.tipoUsuarios;
    })
  }
  //Aqui esta el cargar usuarios
  async cargarDatosusUariosAsync(paginator, filters): Promise<boolean> {
    this.getUserType();
    // Cargamos Datos
    let resultado = false;
    let tiposUsuariotmp;

    await this.servicioAPI.getUsuariosAsync(paginator, filters)
      .then(
        data => {
          this.respuestaAPI = new Usuario_response().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            const usersToShow = this.transFormDataUsuarioShow(this.respuestaAPI.DatosResult.customanUsuarios);


            this.dataSource = new MatTableDataSource(usersToShow);
            this.dataSource['totalResultados'] = data.DatosResult.TotalResultados;
            this.dataSource['pagelements'] = paginator.pagelements;
            this.loadData(this.dataSource);

            resultado = true;
            this.filterInRquest();
          }
          else {
            this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar', this.respuestaAPI.Mensajes[0].CodigoMensaje);
            resultado = false;
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
          }
          this.tableLoaded.emit(true);
        },
        error => {
          this.httpErrorService.identificarErrores(error);
          resultado = false;
        }
      );

    return resultado;
  }
  transFormDataUsuarioShow(users: any[]) {
    const listaUsuariosShow: Customan_usuario[] = [];
    users.map(value => {
      const NewUser = new Customan_usuario();

      this.assignDataByObj(NewUser, value, 'pkId', 'pkId');
      this.assignDataByObj(NewUser, value, 'Personal', 'Personal');
      this.assignDataByObj(NewUser, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewUser, value, 'Clave', 'Clave');
      this.assignDataByObj(NewUser, value, 'Baja', 'Baja');
      this.assignDataByObj(NewUser, value, 'Conectado', 'Conectado');
      this.assignDataByObj(NewUser, value, 'Empleado', 'Empleado');
      this.assignDataByObj(NewUser, value, 'Tipo', 'Tipo');
      this.assignDataByObj(NewUser, value, 'CentrosNombres', 'CentrosNombres');


      let userTypes: any = localStorage.getItem('TiposUsuarios');
      if (userTypes != undefined) {
        userTypes = JSON.parse(userTypes);
        const userSelect = userTypes.find(find => find.pkId == NewUser.Tipo);
        if (userSelect != undefined) {
          this.assignDataByValue(NewUser, 'TipoFilter', userSelect.Nombre);
        }
      }

      listaUsuariosShow.push(NewUser);
    });
    return listaUsuariosShow;
  }

  cargarDatosGruposInternet() {
    let gruposInternet;
    this.servicioAPI.getGruposInternet().subscribe(GrupoInternet => {

      if (GrupoInternet.DatosResult && GrupoInternet.DatosResult.listaGrupoInternet.length > 0) {
        localStorage.setItem('GrupoInternet', JSON.stringify(GrupoInternet.DatosResult.listaGrupoInternet));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        gruposInternet = GrupoInternet.DatosResult.listaGrupoInternet;

      } else {

        gruposInternet = [];
      }
      this.loadSelect('GrupoInternet', gruposInternet, 'Nombre', 'pkId');
      return gruposInternet;
    });
  }
  cargarDatosTipoClientes() {
    let tiposClientes;
    this.servicioAPI.getTiposCliente().subscribe(tiposCliente => {

      if (tiposCliente.DatosResult && tiposCliente.DatosResult.tipoClientes.length > 0) {
        localStorage.setItem('TiposClientes', JSON.stringify(tiposCliente.DatosResult.tipoClientes));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposClientes = tiposCliente.DatosResult.tipoClientes;

      } else {

        tiposClientes = [];
      }
      this.loadSelect('TipoClienteFilter', tiposClientes, 'Nombre', 'pkId');
      return tiposClientes;
    });
  }

  cargarDatosGrupoMotivo() {
    let grupoMotivos;
    this.servicioAPI.getGruposTiposEntrada().subscribe(grupoMotivo => {

      if (grupoMotivo.DatosResult && grupoMotivo.DatosResult.customanGrupoTipoEntrada.length > 0) {
        grupoMotivos = grupoMotivo.DatosResult.customanGrupoTipoEntrada.filter(filter => filter.ConMotivo == 1);
        localStorage.setItem('GrupoMotivoId', JSON.stringify(grupoMotivos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));

      } else {

        grupoMotivos = [];
      }
      this.loadSelect('GrupoMotivoIdFilter', grupoMotivos, 'NombreGrupo', 'pkId');
      return grupoMotivos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarDatosTipoEntrada() {
    let grupoEntradas;
    this.servicioAPI.getGruposTiposEntrada().subscribe(grupoEntrada => {

      if (grupoEntrada.DatosResult && grupoEntrada.DatosResult.customanGrupoTipoEntrada.length > 0) {
        //grupoEntradas = grupoEntrada.DatosResult.customanGrupoTipoEntrada.filter(filter => filter.ConMotivo == 0);
        grupoEntradas = grupoEntrada.DatosResult.customanGrupoTipoEntrada;
        localStorage.setItem('GrupoId', JSON.stringify(grupoEntradas));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));

      } else {

        grupoEntradas = [];
      }
      this.loadSelect('GrupoIdFilter', grupoEntradas, 'NombreGrupo', 'pkId');
      return grupoEntradas;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarDatosCategoria() {
    let categorias;
    this.entryTypeService.ObtenerCustomanCategoria().subscribe(categoria => {

      if (categoria.DatosResult && categoria.DatosResult.lista.length > 0) {
        localStorage.setItem('Categoria', JSON.stringify(categoria.DatosResult.lista));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        categorias = categoria.DatosResult.lista;

      } else {

        categorias = [];
      }
      this.loadSelect('CategoriaFilter', categorias, 'chNombre', 'pkId');
      return categorias;
    });
  }

  cargarDatosTipoVisita() {
    let tiposVisitas;
    this.entryTypeService.ObtenerCustomanTipoVisita().subscribe(tiposVisita => {

      if (tiposVisita.DatosResult && tiposVisita.DatosResult.lista.length > 0) {
        localStorage.setItem('TipoVisita', JSON.stringify(tiposVisita.DatosResult.lista));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposVisitas = tiposVisita.DatosResult.lista;

      } else {

        tiposVisitas = [];
      }
      this.loadSelect('TipoVisitaFilter', tiposVisitas, 'Descripcion', 'pkId');
      return tiposVisitas;
    });
  }

  async cargarDatosTipoPublico() {
    let tiposPublicos;
    await this.entryTypeService.ObtenerCustomanTipoPublico().subscribe(tiposPublico => {

      if (tiposPublico.DatosResult && tiposPublico.DatosResult.lista.length > 0) {
        localStorage.setItem('TipoPublico', JSON.stringify(tiposPublico.DatosResult.lista));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposPublicos = tiposPublico.DatosResult.lista;

      } else {

        tiposPublicos = [];
      }
      this.loadSelect('TipoPublicoFilter', tiposPublicos, 'Descripcion', 'pkId');
      return tiposPublicos;
    });
  }


  cargarDatosCentros() {
    let centros;
    this.servicioAPI.getCentros().subscribe(centro => {

      if (centro.DatosResult && centro.DatosResult.ListaCentros.length > 0) {
        localStorage.setItem('CentrosIds', JSON.stringify(centro.DatosResult.ListaCentros));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        centros = centro.DatosResult.ListaCentros;

      } else {

        centros = [];
      }
      this.loadSelect('CentrosIds', centros, 'Nombre', 'pkId');
      return centros;
    });
  }

  cargarDatosTipoUsuarios() {
    let tiposUsuarios;
    this.editUserService.getTiposUsuario().subscribe(tiposUsuario => {

      if (tiposUsuario.DatosResult && tiposUsuario.DatosResult.tipoUsuarios.length > 0) {
        localStorage.setItem('TiposUsuarios', JSON.stringify(tiposUsuario.DatosResult.tipoUsuarios));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposUsuarios = tiposUsuario.DatosResult.tipoUsuarios;

      } else {

        tiposUsuarios = [];
      }
      this.loadSelect('TipoFilter', tiposUsuarios, 'Nombre', 'pkId');
      return tiposUsuarios;
    });
  }

  async cargarCentrosCombo() {
    let centros: any[] = [];
    await this.entryTypeService.getCentrosAsync().then(centro => {
      centro.DatosResult.ListaCentros.map(cent => {
        centros.push(cent);
      });
    });
    console.log("Los centros en cargarCentrosCombo es: ", centros);
    console.log("EN la posicion inicial: ", centros[0]);
    this.loadSelect('CentroId', centros, 'Nombre', 'pkId');
    return centros;
  }

  cargarGrupoEntradas() {
    let grupos;
    this.recintosVisitaService.getGruposRecintos("").subscribe(item => {

      if (item.DatosResult && item.DatosResult.ListaGrupoRecintos.length > 0) {
        localStorage.setItem('Subgrupo', JSON.stringify(item.DatosResult.ListaGrupoRecintos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        grupos = item.DatosResult.ListaGrupoRecintos.filter(x => x.subGrupo == "1");

      } else {

        grupos = [];
      }
      this.loadSelect('Subgrupo', grupos, 'Nombre', 'pkId');
      return grupos;
    });
  }
  cargarGrupoRecintos() {
    let grupos;
    this.recintosVisitaService.getGruposRecintos("").subscribe(item => {

      if (item.DatosResult && item.DatosResult.ListaGrupoRecintos.length > 0) {
        grupos = item.DatosResult.ListaGrupoRecintos.filter(x => x.subGrupo == "0");
        localStorage.setItem('grupoRecintos', JSON.stringify(grupos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));

      } else {

        grupos = [];
      }
      this.loadSelect('GrupoRecintosIdFilter', grupos, 'Nombre', 'pkId');
      return grupos;
    });
  }

  cargarEspectaculosData() {
    let espectaculos;
    this.espectaculosService.getEspectaculos().subscribe(item => {

      if (item.DatosResult && item.DatosResult.espectaculos.length > 0) {
        espectaculos = item.DatosResult.espectaculos;
        localStorage.setItem('Espectaculos', JSON.stringify(espectaculos));
      } else {
        espectaculos = [];
      }
      this.loadSelect('EspectaculoIdFilter', espectaculos, 'chNombreEspectaculo', 'pkId');
      return espectaculos;
    });
  }

  cargarGrupoActividadesTaller() {
    let grupos;
    this.recintosVisitaService.getGruposActividadTaller("").subscribe(item => {

      if (item.DatosResult && item.DatosResult.customanGrupoActividadTaller.length > 0) {
        grupos = item.DatosResult.customanGrupoActividadTaller;
        localStorage.setItem('grupoActividadTaller', JSON.stringify(grupos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));

      } else {

        grupos = [];
      }
      this.loadSelect('GrupoActividadIdFilter', grupos, 'Nombre', 'pkId');
      return grupos;
    });
  }
  cargarTipoControl() {
    let grupos;
    this.recintosVisitaService.getTiposControl("").subscribe(item => {

      if (item.DatosResult && item.DatosResult.customanTiposControl.length > 0) {
        grupos = item.DatosResult.customanTiposControl;
        localStorage.setItem('tipoControl', JSON.stringify(grupos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));

      } else {

        grupos = [];
      }
      this.loadSelect('TipoControl', grupos, 'Nombre', 'pkId');
      return grupos;
    });
  }
  cargarTipoAforo() {
    this.loadSelect('TipoAforoFilter', this.global.tiposAforo, 'value', 'id');
  }
  cargarDatosGrupos() {
    let grupos;
    this.itemsMotivosGruposService.getGruposTiposEntrada("").subscribe(item => {

      if (item.DatosResult && item.DatosResult.customanGrupoTipoEntrada.length > 0) {
        localStorage.setItem('Grupos', JSON.stringify(item.DatosResult.customanGrupoTipoEntrada));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        grupos = item.DatosResult.customanGrupoTipoEntrada.filter(x => x.ConMotivo.trim().toLowerCase() == 'true' || x.ConMotivo.trim().toLowerCase() == '1');

      } else {

        grupos = [];
      }
      this.loadSelect('GrupoIdFilter', grupos, 'NombreGrupo', 'pkId');
      return grupos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosTiposPagos() {
    let tiposPagos;
    this.servicioAPI.getFormasPago().subscribe(tiposCliente => {

      if (tiposCliente.DatosResult && tiposCliente.DatosResult.customanFormaPago.length > 0) {
        localStorage.setItem('TiposPagos', JSON.stringify(tiposCliente.DatosResult.customanFormaPago));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposPagos = tiposCliente.DatosResult.customanFormaPago;

      } else {

        tiposPagos = [];
      }
      this.loadSelect('TipoPago', tiposPagos, 'Denominacion', 'pkId');
      return tiposPagos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarDatoTipoEntrada() {
    let tiposEntradas;
    this.servicioAPI.getTiposEntradas().subscribe(tiposEntrada => {
      if (tiposEntrada.DatosResult && tiposEntrada.DatosResult.ListadoGruposAsociados.length > 0) {

        tiposEntradas = tiposEntrada.DatosResult.ListadoGruposAsociados;
      } else {

        tiposEntradas = [];
      }
      this.loadSelect('entrada', tiposEntradas, 'Nombre', 'Nombre');
      return tiposEntradas;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosTipoGrupos() {
    let tiposGrupos;
    this.servicioAPI.getTiposGrupos().subscribe(tipoGrupo => {
      if (tipoGrupo.DatosResult && tipoGrupo.DatosResult.customanGrupoTipoBono.length > 0) {
        localStorage.setItem('NombreGrupos', JSON.stringify(tipoGrupo.DatosResult.customanGrupoTipoBono));
        tiposGrupos = tipoGrupo.DatosResult.customanGrupoTipoBono;
      } else {

        tiposGrupos = [];
      }
      this.loadSelect('iGrupoIdFilter', tiposGrupos, 'NombreGrupo', 'pkId');
      return tiposGrupos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosTiposProductos() {
    let tiposProductos;
    this.servicioAPI.GetTiposProductos().subscribe(tiposProducto => {
      if (tiposProducto.DatosResult && tiposProducto.DatosResult.ListadoTiposProductos.length > 0) {
        localStorage.setItem('TipoProducto', JSON.stringify(tiposProducto.DatosResult.ListadoTiposProductos));

        tiposProductos = tiposProducto.DatosResult.ListadoTiposProductos;
      } else {

        tiposProductos = [];
      }
      this.loadSelect('iTipoProductoIdFilter', tiposProductos, 'Descripcion', 'pkId');
      return tiposProductos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosTipoVisita_tiposbono() {
    let tiposVisitas;
    this.entryTypeService.ObtenerCustomanTipoVisita().subscribe(tiposVisita => {

      if (tiposVisita.DatosResult && tiposVisita.DatosResult.lista.length > 0) {
        localStorage.setItem('TipoVisita', JSON.stringify(tiposVisita.DatosResult.lista));

        tiposVisitas = tiposVisita.DatosResult.lista;

      } else {

        tiposVisitas = [];
      }
      this.loadSelect('iTipoVisitaIdFilter', tiposVisitas, 'Descripcion', 'pkId');
      return tiposVisitas;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }


  cargarDatosTiposNivel() {
    let tiposNivel;
    this.servicioAPI.GetTiposNivel().subscribe(tipoNivel => {
      if (tipoNivel.DatosResult && tipoNivel.DatosResult.ListadoTiposNiveles.length > 0) {
        localStorage.setItem('TipoNivel', JSON.stringify(tipoNivel.DatosResult.ListadoTiposNiveles));

        tiposNivel = tipoNivel.DatosResult.ListadoTiposNiveles;
      } else {

        tiposNivel = [];
      }
      this.loadSelect('iTipoNivelIdFilter', tiposNivel, 'Nombre', 'pkId');
      return tiposNivel;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarDatosCategorias() {
    let categorias;
    this.servicioAPI.GetCategorias().subscribe(categoria => {
      if (categoria.DatosResult && categoria.DatosResult.lista.length > 0) {
        localStorage.setItem('Categorias', JSON.stringify(categoria.DatosResult.lista));
        categorias = categoria.DatosResult.lista;
      } else {

        categorias = [];
      }
      this.loadSelect('iCategoriaIdFilter', categorias, 'chNombre', 'pkId');
      return categorias;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarDatosGruposAsociados() {
    let categorias;
    this.servicioAPI.GetGruposAsociados().subscribe(categoria => {
      if (categoria.DatosResult && categoria.DatosResult.ListadoGruposAsociados.length > 0) {
        localStorage.setItem('ListadoGruposAsociados', JSON.stringify(categoria.DatosResult.ListadoGruposAsociados));
        categorias = categoria.DatosResult.ListadoGruposAsociados;
      } else {

        categorias = [];
      }
      this.loadSelect('iGrupoAsociadoIdFilter', categorias, 'Descripcion', 'pkId');
      return categorias;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  cargarDatosMotivosGrupos() {
    let motgrupos;
    this.servicioAPI.GetMotivosGrupos().subscribe(motgrupo => {
      if (motgrupo.DatosResult && motgrupo.DatosResult.customanItemMotGrupo.length > 0) {
        localStorage.setItem('MotivosGrupo', JSON.stringify(motgrupo.DatosResult.customanItemMotGrupo));
        motgrupos = motgrupo.DatosResult.customanItemMotGrupo;
      } else {

        motgrupos = [];
      }
      this.loadSelect('GrupoMotivoId', motgrupos, 'Nombre', 'pkId');
      return motgrupos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  cargarTiposEntradaDatos() {
    let tiposEntradaDatos;
    this.servicioAPI.GetTiposEntradaDatos().subscribe(tiposEntrada => {
      if (tiposEntrada.DatosResult && tiposEntrada.DatosResult.ListadoTiposEntradaDatos.length > 0) {
        localStorage.setItem('TipoEntradaDatos', JSON.stringify(tiposEntrada.DatosResult.ListadoTiposEntradaDatos));
        tiposEntradaDatos = tiposEntrada.DatosResult.ListadoTiposEntradaDatos;
      } else {

        tiposEntradaDatos = [];
      }
      this.loadSelect('iTEDatosIdFilter', tiposEntradaDatos, 'Nombre', 'pkId');
      return tiposEntradaDatos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }


  transFormDataTarifaToShow(tarifas: Tarifas[]) {
    const listTarifasShow: Tarifas_show[] = [];
    const daysWeek = [{ day: 'Lunes', value: 'lun' }, { day: 'Martes', value: 'mar' }, { day: 'Miercoles', value: 'mier' }, { day: 'Jueves', value: 'jue' }, { day: 'Viernes', value: 'vie' }, { day: 'Sabado', value: 'sab' }, { day: 'Domingo', value: 'dom' },];
    let semana;
    const numeroDecimales: any = localStorage.getItem('NumeroDecimales');

    tarifas.map(value => {
      const NewTarifa = new Tarifas_show();

      this.assignDataByObj(NewTarifa, value, 'pkId', 'pkId');
      this.assignDataByObj(NewTarifa, value, 'Internet', 'Internet');
      this.assignDataByObj(NewTarifa, value, 'Taquilla', 'Taquilla');
      this.assignDataByObj(NewTarifa, value, 'Admin', 'IntAdmon');
      this.assignDataByObj(NewTarifa, value, 'AlaVenta', 'AlaVenta');

      this.assignDataByValue(NewTarifa, 'FecIniVisita', moment(value.FecIniVisita, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewTarifa, 'FecFinVisita', moment(value.FecFinVisita, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewTarifa, 'FecCompraDesde', moment(value.FecCompraDesde, 'DD/MM/YYYY').format('DD-MM-YYYY'));
      this.assignDataByValue(NewTarifa, 'FecCompraHasta', moment(value.FecCompraHasta, 'DD/MM/YYYY').format('DD-MM-YYYY'));


      this.assignDataByValue(NewTarifa, 'PrecioPVP', parseFloat(value.PrecioPVP.replace(',', '.')).toFixed(numeroDecimales));
      this.assignDataByObj(NewTarifa, value, 'nombre', 'NombreEntTPromTAbo');
      this.assignDataByObj(NewTarifa, value, 'Descripcion', 'DescripcionEntTPromTAbo');
      this.assignDataByObj(NewTarifa, value, 'CentrosNombres', 'CentrosNombres');

      let gruposInternet: any = localStorage.getItem('GrupoInternet');
      if (gruposInternet != undefined) {
        gruposInternet = JSON.parse(gruposInternet);
        console.log(gruposInternet)
        console.log(value.iGrupoInternetId)
        if(value.iGrupoInternetId){
          const dataGrupoInternet = gruposInternet.find(find => find.pkId == value.iGrupoInternetId);
          console.log(dataGrupoInternet)
          if(dataGrupoInternet){
            this.assignDataByValue(NewTarifa, 'GrupoInternet', dataGrupoInternet.Nombre);
          }
        }
      }




      // idiomas
      value.Idiomas.map(language => {

        this.assignDataByObj(NewTarifa, language, language.CodIdioma + 'Nombre', 'Nombre');
        this.assignDataByObj(NewTarifa, language, language.CodIdioma + 'Descripcion', 'Descripcion');
      });



      semana = '';
      daysWeek.map(day => {

        if (value[day.day]) {
          if (day.day != 'Lunes') {
            semana += ' ' + day.value;

          } else {
            semana += day.value + ' '

          }
        }
      });
      

      this.assignDataByValue(NewTarifa, 'Semana', semana);

      // console.log(NewTarifa)

      listTarifasShow.push(NewTarifa);
    });
    return listTarifasShow;
  }

  assignDataByObj(obj, objData, columnName, columnNameData) {
    obj[columnName] = objData[columnNameData];


  }

  assignDataByValue(obj, columnName, data) {
    obj[columnName] = data;


  }

  // TRY MAP dictionary 2 Array
  mapDiccionario2Array<T>(diccionario: Map<string, T>): Array<T> {

    const arrayLista = new Array<T>();
    try {
      for (let element in diccionario) {
        arrayLista.push(diccionario[element]);
      }
      // diccionario.forEach(element => {
      //  arrayLista.push(element);
      // });
      return arrayLista;
    }
    catch (Error) {
      alert(Error.message);
    }
    finally { return arrayLista; }
  }

  loadData(data) {
    data["page"] = this.paginator.page
    this.dataTable = data;
   
    console.log("Data## ", data);
    this.setdataTableFilter(this.dataTable);

  }

  transFormDataInternetGroupToShow(internetgroups: InternetGroup[]) {

    const listInternetGroupShow: InternetGroups_show[] = [];

    internetgroups.map(value => {
      const NewInternetGroup = new InternetGroups_show();

      let ValidarGratuitas = this.translation.No;
      if (value.ValidarGratuitas == '1') { ValidarGratuitas = this.translation.Si; }

      this.assignDataByObj(NewInternetGroup, value, 'pkId', 'pkId');
      this.assignDataByObj(NewInternetGroup, value, 'Nombre', 'Nombre');
      this.assignDataByObj(NewInternetGroup, value, 'Descripcion', 'Descripcion');
      this.assignDataByObj(NewInternetGroup, value, 'Minimo', 'Minimo');
      this.assignDataByObj(NewInternetGroup, value, 'Maximo', 'Maximo');
      this.assignDataByObj(NewInternetGroup, value, 'Orden', 'Orden');
      this.assignDataByValue(NewInternetGroup, 'TipoReserva', '');
      this.assignDataByValue(NewInternetGroup, 'ValidarGratuitas', ValidarGratuitas);
      this.assignDataByValue(NewInternetGroup, 'EnlacesMenu', '');
      this.assignDataByObj(NewInternetGroup, value, 'CentrosNombres', 'CentrosNombres');

      // idiomas
      value.Idiomas.map(language => {
        this.assignDataByObj(NewInternetGroup, language, language.CodIdioma + 'Nombre', 'Nombre');
      });



      let tiposReserva: any = localStorage.getItem('TiposReserva');
      if (tiposReserva != undefined) {
        tiposReserva = JSON.parse(tiposReserva);
        const datatiposReserva = tiposReserva.find(find => find.pkId == value.TipoReservaId);
        console.log(datatiposReserva)
        if(datatiposReserva)
          this.assignDataByValue(NewInternetGroup, 'TipoReserva', datatiposReserva.Nombre);
      }



      listInternetGroupShow.push(NewInternetGroup);
    });
    return listInternetGroupShow;
  }


  transFormDataTiposSesionToShow(tipossesion: any[]) {

    let listTiposSesionShow: TiposSesion_show[] = []
    let idiomas = JSON.parse(localStorage.getItem("Idiomas"));

    tipossesion.map(value => {
      let NewInternetGroup = new TiposSesion_show();
      let idioma = {};
      if (idiomas) idioma = idiomas.filter(f => value.CodIdioma == f.CodIdioma)
      if (idioma[0]) idioma = idioma[0].Nombre
      /*
      let ValidarGratuitas = this.translation.No;
      if (value.ValidarGratuitas=="1") ValidarGratuitas = this.translation.Si;
      */
      this.assignDataByObj(NewInternetGroup, value, "pkId", "pkId")
      this.assignDataByValue(NewInternetGroup, "RecintoFilter", "")
      this.assignDataByObj(NewInternetGroup, value, "Descripcion", "Descripcion")

      //this.assignDataByObj(NewInternetGroup,value,"FechaFin","FechaFin")
      //this.assignDataByObj(NewInternetGroup,value,"FechaInicio","FechaInicio")
      //moment(value.FecIniVisita,"DD/MM/YYYY").format("DD-MM-YYYY")
      this.assignDataByValue(NewInternetGroup, "FechaFin", moment(value.FechaFin, "DD/MM/YYYY").format("DD-MM-YYYY"))
      this.assignDataByValue(NewInternetGroup, "FechaInicio", moment(value.FechaInicio, "DD/MM/YYYY").format("DD-MM-YYYY"))

      this.assignDataByValue(NewInternetGroup, "Aforos", "")
      this.assignDataByObj(NewInternetGroup, value, "Intervalo", "Intervalo")
      this.assignDataByObj(NewInternetGroup, value, "Duracion", "Duracion")
      this.assignDataByValue(NewInternetGroup, "Dias", "")
      this.assignDataByValue(NewInternetGroup, "CodIdioma", idioma)
      this.assignDataByObj(NewInternetGroup, value, 'CentrosNombres', 'CentrosNombres');

      switch (value.chTipoSesion) {
        case 'SF':
          this.assignDataByValue(NewInternetGroup, 'chTipoSesion', 'Regular');
          break;
        case 'SI':
          this.assignDataByValue(NewInternetGroup, 'chTipoSesion', 'Irregular');
          break;
        case 'SD':
          this.assignDataByValue(NewInternetGroup, 'chTipoSesion', 'Diaria');
          break;
      }

      //
      let recintos: any = localStorage.getItem('Recintos');
      if (recintos != undefined) {
        recintos = JSON.parse(recintos);
        const dataRecintos = recintos.find(find => find.pkId == value.Recinto);
        this.assignDataByValue(NewInternetGroup, 'RecintoFilter', dataRecintos.Nombre);
      }

      listTiposSesionShow.push(NewInternetGroup);
    });
    return listTiposSesionShow;
  }


  transFormDataSinButacaToShow(sinbutacagroups: any[]) {

    let listSinButacaShow: SinButaca_show[] = [];

    let arrayTiposSesion = [];
    let idiomas = JSON.parse(localStorage.getItem("Idiomas"));


    sinbutacagroups.map(value => {
      let NewInternetGroup = new SinButaca_show();

      let idioma = {};
      if (idiomas) idioma = idiomas.filter(f => value.CodIdioma == f.CodIdioma)
      if (idioma[0]) idioma = idioma[0].Nombre

      let tiposesion = arrayTiposSesion.filter(f => f.pkId == value.TipoSesion);
      this.assignDataByObj(NewInternetGroup, value, "pkId", "pkId");
      this.assignDataByValue(NewInternetGroup, "CodIdioma", idioma);
      this.assignDataByValue(NewInternetGroup, "Recinto", "");
      this.assignDataByValue(NewInternetGroup, "TipoSesion", "");
      this.assignDataByObj(NewInternetGroup, value, "Descripcion", "Descripcion");
      this.assignDataByValue(NewInternetGroup, "Fecha", value.Fecha.split(" ")[0]);
      this.assignDataByValue(NewInternetGroup, "HoraInicio", value.HoraInicio.split(" ")[1]);
      this.assignDataByValue(NewInternetGroup, "HoraFin", value.HoraFin.split(" ")[1]);
      this.assignDataByValue(NewInternetGroup, "AforoInicial",  this.showDisponibleTaquilla(value));
      this.assignDataByObj(NewInternetGroup, value, "Reservadas", "Reservadas");
      //this.assignDataByValue(NewInternetGroup, "Disponibles", this.showDisponible(value).split('/')[0] + " / " + this.showDisponible(value).split('/')[1]);
      this.assignDataByValue(NewInternetGroup, "Disponibles", this.showDisponibleInternet(value));
      this.assignDataByObj(NewInternetGroup, value, 'CentrosNombres', 'CentrosNombres');

      
      console.log("Value::", value);


      switch (value.Activa) {
        case '0':
          this.assignDataByValue(NewInternetGroup, "Activa", "No")
          break;
        case '1':
          this.assignDataByValue(NewInternetGroup, "Activa", "Si")
          break;
      }


      let recintos: any = localStorage.getItem("Recintos")
      if (recintos != undefined) {
        recintos = JSON.parse(recintos)
        let dataRecintos = recintos.find(find => find.pkId == value.Recinto)
        if (dataRecintos) this.assignDataByValue(NewInternetGroup, "RecintoFilterSinbutaca", dataRecintos.Nombre)
      }

      let tipossesion: any = localStorage.getItem("TipoSesion")
      if (tipossesion != undefined) {
        tipossesion = JSON.parse(tipossesion)
        let datatipossesion = tipossesion.find(find => find.pkId == value.TipoSesion)
        if (datatipossesion) this.assignDataByValue(NewInternetGroup, "chTipoSesionFilter", datatipossesion.Descripcion)
      }


      listSinButacaShow.push(NewInternetGroup);
    });
    return listSinButacaShow;
  }

  cargarEspectaculos(paginator, filters) {
    //customanGrupoActividadTaller
    this.espectaculosService.getEspectaculos(paginator, filters).subscribe(
      async data => {
        this.respuestaAPI = data;
        if (this.respuestaAPI.DatosResult != null) {
          const listaToShow = this.transformEspectaculos(this.respuestaAPI.DatosResult.espectaculos);
          this.dataSource = new MatTableDataSource(listaToShow);
          console.log(listaToShow)
          this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
          this.dataSource["pagelements"] = paginator.pagelements;
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        else {
          this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
          this.dataSource = new MatTableDataSource([]);
          this.loadData(this.dataSource);
          this.filterInRquest();
        }
        this.tableLoaded.emit(true);
      },
      error => {
        this.httpErrorService.identificarErrores(error);
      }
    );
  }



  

  
  transformEspectaculos(categorias: any[]) {
    const listEspectaculosShow = [];
    categorias.map(value => {
      const NeEspectaculos = new CategoriasV11();

      console.log(value)
      console.log(value.chNombre)
      this.assignDataByObj(NeEspectaculos, value, 'pkId', 'pkId');
      this.assignDataByObj(NeEspectaculos, value, 'Nombre', 'chNombreEspectaculo');
      this.assignDataByObj(NeEspectaculos, value, 'PathMapaSVG', 'PathMapaSVG');
      this.assignDataByObj(NeEspectaculos, value, 'Promotor', 'Promotor');
      this.assignDataByObj(NeEspectaculos, value, 'CIF', 'CIF');
      this.assignDataByObj(NeEspectaculos, value, 'Activo', 'bActivo');

      switch (value.bActivo) {
        case 0:
          this.assignDataByValue(NeEspectaculos, "Activo", "No");
          break;
        case 1:
          this.assignDataByValue(NeEspectaculos, "Activo", "Si");
          break;
      }


/*       let grupoRecintos: any = localStorage.getItem('grupoRecintos');
      if (grupoRecintos != undefined) {
        grupoRecintos = JSON.parse(grupoRecintos);
        const datagrupoRecintos = grupoRecintos.find(find => find.pkId == value.GrupoRecintosId);
        if (datagrupoRecintos != null) {

          this.assignDataByValue(NeEspectaculos, 'GrupoRecintosIdFilter', datagrupoRecintos.Nombre);
        }
      } */


      listEspectaculosShow.push(NeEspectaculos);
    });
    console.log(listEspectaculosShow)
    return listEspectaculosShow;
  }



  cargarSesionesEspectaculos(paginator, filters) {
  //customanGrupoActividadTaller
  this.espectaculosService.getSesionesEspectaculos(paginator, filters).subscribe(
    async data => {
      this.respuestaAPI = data;
      if (this.respuestaAPI.DatosResult != null) {
        const listaToShow = this.transformSesionesEspectaculos(this.respuestaAPI.DatosResult.sesiones);
        this.dataSource = new MatTableDataSource(listaToShow);
        console.log(listaToShow)
        this.dataSource["totalResultados"] = data.DatosResult.TotalResultados;
        this.dataSource["pagelements"] = paginator.pagelements;
        this.loadData(this.dataSource);
        this.filterInRquest();
      }
      else {
        this.snackvar_service.openSnackBar(this.respuestaAPI.Mensajes[0].DescripcionMensaje, "", 'red-snackbar',this.respuestaAPI.Mensajes[0].CodigoMensaje);
        this.dataSource = new MatTableDataSource([]);
        this.loadData(this.dataSource);
        this.filterInRquest();
      }
      this.tableLoaded.emit(true);
    },
    error => {
      this.httpErrorService.identificarErrores(error);
    }
  );
}






transformSesionesEspectaculos(categorias: any[]) {
  const listEspectaculosShow = [];
  categorias.map(value => {
    const NeEspectaculos = new CategoriasV11();

    console.log(value)
    console.log(value.chNombre)
    this.assignDataByObj(NeEspectaculos, value, 'pkId', 'pkId');
    this.assignDataByObj(NeEspectaculos, value, 'EspectaculoId', 'iEspectaculoId');
    this.assignDataByObj(NeEspectaculos, value, 'EspectaculoIdFilter', 'chNombreEspectaculo');
    this.assignDataByObj(NeEspectaculos, value, 'GrupoRecintosIdFilter', 'chNombreGrupoRecintos');
    this.assignDataByObj(NeEspectaculos, value, 'Descripcion', 'chDescripcion');
    this.assignDataByObj(NeEspectaculos, value, 'Fecha', 'dtFecha');
    this.assignDataByObj(NeEspectaculos, value, 'HoraInicio', 'dtHoraInicio');
    this.assignDataByObj(NeEspectaculos, value, 'Activo', 'bActiva');

    switch (value.bActiva) {
      case "0":
        this.assignDataByValue(NeEspectaculos, "Activo", "No");
        break;
      case "1":
        this.assignDataByValue(NeEspectaculos, "Activo", "Si");
        break;
    }


    let grupoRecintos: any = localStorage.getItem('grupoRecintos');
    if (grupoRecintos != undefined) {
      grupoRecintos = JSON.parse(grupoRecintos);
      const datagrupoRecintos = grupoRecintos.find(find => find.pkId == value.GrupoRecintosId);
      if (datagrupoRecintos != null) {

        this.assignDataByValue(NeEspectaculos, 'GrupoRecintosIdFilter', datagrupoRecintos.Nombre);
      }
    }


    listEspectaculosShow.push(NeEspectaculos);
  });
  console.log(listEspectaculosShow)
  return listEspectaculosShow;
}




  cargarDatosTiposReserva() {

    let tiposReservas;
    this.servicioAPI.getTiposReserva().subscribe(tiposReserva => {

      console.log(tiposReserva);
      if (tiposReserva.DatosResult && tiposReserva.DatosResult.listaTipoReserva.length > 0) {
        localStorage.setItem('TiposReserva', JSON.stringify(tiposReserva.DatosResult.listaTipoReserva));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        tiposReservas = tiposReserva.DatosResult.listaTipoReserva;

      } else {

        tiposReservas = [];
      }
      console.log(tiposReservas);
      //1-El campo al que se la a insertar estos datos 2- El array que le pasas a la funcion 3- El campo es lo que se muestra 4- Es el value 
      this.loadSelect('TipoReserva', tiposReservas, 'Nombre', 'pkId');
      return tiposReservas;
    });

  }

  cargarRecintos() {

    let Recintos;
    this.servicioAPI.getRecintos().subscribe(Recinto => {

      console.log(Recintos);
      if (Recinto.DatosResult && Recinto.DatosResult.ListaRecintos.length > 0) {
        localStorage.setItem('Recintos', JSON.stringify(Recinto.DatosResult.ListaRecintos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        Recintos = Recinto.DatosResult.ListaRecintos;

      } else {

        Recintos = [];
      }
      console.log(Recintos)
      this.loadSelect("RecintoFilterSinbutaca", Recintos, "Nombre", "pkId");
      return Recintos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

  }
  cargarRecintosTipossesion() {

    let Recintos;
    this.servicioAPI.getRecintos().subscribe(Recinto => {

      console.log(Recintos);
      if (Recinto.DatosResult && Recinto.DatosResult.ListaRecintos.length > 0) {
        localStorage.setItem('Recintos', JSON.stringify(Recinto.DatosResult.ListaRecintos));
        // tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        Recintos = Recinto.DatosResult.ListaRecintos;

      } else {

        Recintos = [];
      }
      console.log(Recintos)
      this.loadSelect("RecintoFilter", Recintos, "Nombre", "pkId");
      return Recintos;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

  }


  cargarTiposSesion() {
    let tipossesion;
    this.servicioAPI.getTiposSesion().subscribe(Tiposesion => {

      if (Tiposesion.DatosResult && Tiposesion.DatosResult.Lista.length > 0) {
        localStorage.setItem("TipoSesion", JSON.stringify(Tiposesion.DatosResult.Lista))
        tipossesion = Tiposesion.DatosResult.Lista

      } else {

        tipossesion = [];
      }
      this.loadSelect("chTipoSesionFilter", tipossesion, "Descripcion", "pkId");
      return tipossesion;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

  }


  cargarIdionmas() {

    let Idiomas;
    this.servicioAPI.getIdiomas().subscribe(Idioma => {

      if (Idioma.DatosResult && Idioma.DatosResult.ListaIdiomas.length > 0) {
        localStorage.setItem("Idiomas", JSON.stringify(Idioma.DatosResult.ListaIdiomas))
        //tiposEntradas = tiposCliente.DatosResult.tipoClientes.filter(x => this.global.aBooleano(x.ALaVenta));
        Idiomas = Idioma.DatosResult.ListaIdiomas

      } else {

        Idiomas = [];
      }
      this.loadSelect("CodIdioma", Idiomas, "Nombre", "CodIdioma");
      return Idiomas;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );

  }

  showDisponible(item): string //Sesion
  {
    //
    //let respuesta: string = '';
    let maxInternet: number = Number(item.MaximoInternet) || 0;
    let maxTotal: number = Number(item.MaximoAforo) || 0;
    let numVendidasInet: number = Number(item.VendidasInternet) || 0;
    let numVendidasTaquilla: number = Number(item.Vendidas) || 0;
    let numReservadasInet: number = Number(item.Reservadas) || 0;
    let online = maxInternet - numVendidasInet - numReservadasInet;
    let total = maxTotal - numVendidasInet - numReservadasInet - numVendidasTaquilla;
    return (total != null ? total.toString() : '') + ' / ' + (online != null ? online.toString() : '');
  }

  showDisponibleTaquilla(item): string
  {
    let MaximoAforo = Number(item.MaximoAforo);
    let Vendidas = Number(item.Vendidas);
    let VendidasAdmon = Number(item.VendidasAdmon);
    return (MaximoAforo - (Vendidas + VendidasAdmon)).toString();
  }

  showDisponibleInternet(item): string
  {
    let MaximoInternet = Number(item.MaximoInternet);
    let VendidasInternet = Number(item.VendidasInternet);
    return (MaximoInternet - VendidasInternet).toString();
  }

}



