import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { CodigoPromocional } from '@sharedV11/classes/CodigoPromociones/CodigoPromocional';
import { clientes_select } from '@sharedV11/classes/tipoCodigoPromociones/clientes_select';
import { tipoCodigoPromociones, tipoEntradaPlantilla, tipoPromoEntradaPlantilla } from '@sharedV11/classes/tipoCodigoPromociones/tipoCodigoPromociones';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { EditCodigoPromocionesService } from '@sharedV11/services/components/edit-codigo-promociones/edit-codigo-promociones.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import moment from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-edit-codigo-promocional',
  templateUrl: './edit-codigo-promocional.component.html',
  styleUrls: ['./edit-codigo-promocional.component.scss']
})
export class EditCodigoPromocionalComponent implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  ///clientesASs: any;
  listaClientes:clientes_select[]= [
    {value: 1, nombre: 'jordi'},
    {value: 2, nombre: 'pepe'},
    {value: 3, nombre: 'juan'}
  ];
//////
  tiposEntrada: any;
  translation: any;
  elementosBorrados: string[] = [];
  tiposBonoEntrada: tipoEntradaPlantilla[];
  promocionEntradaFormGroup: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada: string[];

  editorRegistroForm: FormGroup;
  itemsTipoCodigoPromociones;

  dataSourceTarifaPromocionEntrada: MatTableDataSource<tipoEntradaPlantilla>;
///////
  tiposBonoEntrada1: tipoPromoEntradaPlantilla[];
  tiposEntrada1: any;
  //translation: any;
  elementosBorrados1: string[] = [];
  promocionEntradaFormGroup1: FormArray = this.formBuilder.array([]);
  columnsToDisplayTarifaEntrada1: string[];

  editorRegistroForm1: FormGroup;

  dataSourceTarifaPromocionEntrada1: MatTableDataSource<tipoEntradaPlantilla>;
///////
  editRegistroForm: FormGroup;

  tablavalida = true;
  tablavalida1 = true;

  CodigoPromocional:CodigoPromocional
  tiposCodigosPromocionales=[]
  constructor(
    private editCodigoPromocionesService:EditCodigoPromocionesService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private snackvar_service: SnackbarInfoService,
    private httpErrorService: HttpErrorsService,
    private formBuilder: FormBuilder,
  ) { }

  getNombreGrupoById(id: string) {
    // TODO: falta acceso a la tabla tbGrupoTipoBono.PkId
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    //this.cargarDatos();
    this.cargarDatos();

    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode);

    // this.pkId="4"

    this.translation = this.translator.GetTranslations();
    this.columnsToDisplayTarifaEntrada = ['Acciones', 'NombreTipoEntrada', 'iNumEntradas'];

    this.columnsToDisplayTarifaEntrada1 = ['Acciones', 'NombreTipoEntrada', 'iNumEntradas'];
   /*  this.tiposEntrada = {"DatosResult":{"tipoCodigoPromo":[{
      pkId: "1",
            Nombre: "ejemplo",
            chNombre: "ejemplo1",
            chDescripcion: "Descripción...",
            Descripcion: "Descripción...",
            bDeBaja: 0,
            iCodigoCliente: 0
    }]}}; */

  }

  cargarDatos() {
    //Cargamos Datos
    let resultado: boolean = false;
    /* this.editCodigoPromocionesService.GetIacpos().subscribe(data => {
      this.tiposEntrada = data.DatosResult.customanGrupoTipoBono;
    },
    error => { this.httpErrorService.identificarErrores(error); });
    */
    this.editCodigoPromocionesService.GetTipoCodigoPromocional().subscribe(data=>{
     this.tiposCodigosPromocionales = data["DatosResult"]["lista"]
     console.log(this.tiposCodigosPromocionales)
    });

    return resultado;
  }


  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithoutData() {

    this.editRegistroForm = this.fb.group({
      pkId: [""],
      Habilitado: [""],
      TipoDeCodigo: [""],
      Codigo: [""],
      ClaseDeCodigo: [""],
      TipoDeVenta: [""],
      Cantidad: [""],
      FechaVisitaInicio: [""],
      FechaVisitaFin: [""],
      FechaCompraInicio: [""],
      FechaCompraFin: [""],
    });
  }
  manageForm(pkId, mode) {

    if (mode == "I") {
      this.itemsTipoCodigoPromociones = this.editRegistroForm.value;
      this.loading = false;
      this.valuedChanged();

    }
    if (pkId != null) {
      this.findCategoria(pkId)
    }
  }
 
  findCategoria(pkId) {
    this.editCodigoPromocionesService.GetIacpos().pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      // let Categoria:Categoria =  CategoriaApi["DatosResult"]["customanUsuarios"][0];
      let Categoria = data["DatosResult"]["lista"][0]
      console.log(Categoria)

      if (Categoria != null) {
        this.changeValueForm(Categoria)
        this.CodigoPromocional = this.editRegistroForm.value;
        this.valuedChanged();
        this.loading = false;
        
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  } 

  changeValueForm(CodigoPromocional:CodigoPromocional) {
    this.editRegistroForm.get("pkId").setValue(CodigoPromocional.pkId);
   console.log(moment(CodigoPromocional.FechaVisitaInicio,"DD/MM/YYYY").isValid())
    this.editRegistroForm.get("Habilitado").setValue(CodigoPromocional.Habilitado)
    this.editRegistroForm.get("TipoDeCodigo").setValue(CodigoPromocional.TipoDeCodigo)
    this.editRegistroForm.get("Codigo").setValue(CodigoPromocional.Codigo)
    this.editRegistroForm.get("ClaseDeCodigo").setValue(CodigoPromocional.ClaseDeCodigo)
    this.editRegistroForm.get("TipoDeVenta").setValue(CodigoPromocional.TipoDeVenta)
    this.editRegistroForm.get("Cantidad").setValue(CodigoPromocional.Cantidad)
    this.editRegistroForm.get("FechaVisitaInicio").setValue(moment(CodigoPromocional.FechaVisitaInicio,"DD/MM/YYYY").toDate())
    this.editRegistroForm.get("FechaVisitaFin").setValue(moment(CodigoPromocional.FechaVisitaFin,"DD/MM/YYYY").toDate())
    this.editRegistroForm.get("FechaCompraInicio").setValue(moment(CodigoPromocional.FechaCompraInicio,"DD/MM/YYYY").toDate())
    this.editRegistroForm.get("FechaCompraFin").setValue(moment(CodigoPromocional.FechaCompraFin,"DD/MM/YYYY").toDate())
 
  }

  valuedChanged() {
    this.editRegistroForm.valueChanges.subscribe(value => {
      if (this.CodigoPromocional != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.CodigoPromocional)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }
  submit() {
    //this.flagInput=!this.flagInput
    /*  console.log(this.editRegistroForm.value)
     console.log(this.editRegistroForm.valid)
     console.log(this.editRegistroForm) */
     this.editRegistroForm.markAllAsTouched()
    let tipoCodigoPromociones: tipoCodigoPromociones = this.editRegistroForm.value;
    if (this.editRegistroForm.valid) {
      this.manageApi(tipoCodigoPromociones, this.mode)
    } 

  }

  destroy() {
    this.panelService.setDiscardChanges(true);
    this.panelService.setClosePanel(null);
  }

  save() {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.CodigoPromocional)
  }
  manageApi(tipoCodigoPromociones, mode) {
    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        tipoCodigoPromociones.pkId = "0"
        this.InsCategoria(tipoCodigoPromociones)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdUsuario(tipoCodigoPromociones)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        tipoCodigoPromociones.pkId = "0"
        this.InsCategoria(tipoCodigoPromociones)

        break;

      default:
        break;
    }
  }
  UpdUsuario(Categoria){
    this.editCodigoPromocionesService.updTipoPromo(Categoria, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });    
   }
   InsCategoria(Categoria){
    this.editCodigoPromocionesService.insTipoPromo(Categoria, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   
   }
   nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach(element => {
          this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
        });
      } else {
        this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
      }
    }
  }
  existErrors() {
    return (this.editRegistroForm.get("pkId").errors || this.editRegistroForm.get("Nombre").errors || this.editRegistroForm.get("Descripcion").errors || this.editRegistroForm.get("Cliente").errors) ? true : false;
  }
  getErrorMessage(field: string) {
    let fc_pv = this.editRegistroForm.get(field);
    if(fc_pv){
      if (fc_pv.errors) {
        if (fc_pv.errors.required) {
          return this.translation["CampoRequerido"];
        } else if (field === "Empleado") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloNumeros"];
          }
        } else if (field === "Nombre") {
          if (fc_pv.hasError('pattern')) {
            return this.translation["SoloLetras"];
          }
        }
      }
    }
    
    return "Error";
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.editRegistroForm.controls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }
  closeInputFilter(enventValues){
    //this.insertDataInForm()

    this.editorRegistroForm.get(enventValues.name).setValue(enventValues.value)


   // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)

  }

}
