import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, LOCALE_ID, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogoPopUp } from '@sharedV11/components/alert/dialog/popup.service';
import { AlertService } from '@sharedV11/services/alerts/alert/alert.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { EditCategoriasService } from '@sharedV11/services/components/Categorias/edit-categorias.service';
import { ClientService } from '@sharedV11/services/components/client/client.service';
import { EditTipoCodigoPromocionesService } from '@sharedV11/services/components/edit-tipo-codigo-promociones/edit-tipo-codigo-promociones.service';
import { EditClientGroupService } from '@sharedV11/services/components/edit_client_group/edit-client-group.service';
import { GrupoTipoBonoV11Service } from '@sharedV11/services/components/entradas-y-promociones/grupo-tipo-bono-v11/grupo-tipo-bono-v11.service';
import { ItemsMotivosGruposV11Service } from '@sharedV11/services/components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service';
import { BonoTypeService } from '@sharedV11/services/components/entradas-y-promociones/tipobono/bono-type.service';
import { EditDiasdeCierreService } from '@sharedV11/services/components/festivos/festivos';
import { EditGrupoRecintosService } from '@sharedV11/services/components/grupo-recinto/edit-grupo-recintos.service';
import { GruposActividadTallerService } from '@sharedV11/services/components/grupos-actividad-taller/grupos-actividad-taller.service';
import { RecintosVisitaService } from '@sharedV11/services/components/recintos/recintos-visita/recintos-visita.service';
import { TableInteractiveService } from '@sharedV11/services/filter/tableinteractive.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { TableComponent } from '../table/table.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'DD-MM-YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'DD-MM-YYYY',
  },
};


@Component({ 
  selector: 'app-table-interactive',
  templateUrl: './table-interactive.component.html',
  styleUrls: ['./table-interactive.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  providers: [TableInteractiveService,
    {
      provide: MAT_DATE_FORMATS, useValue: MY_FORMATS
    },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] }]
})

export class TableInteractiveComponent extends TableComponent implements OnInit, AfterViewInit {

  @ViewChild(MatTable) inttable: MatTable<any>;
  @ViewChild("inttable") innerTable: QueryList<MatTable<any>>;
  @Output() guardatabla:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cargadatabla:EventEmitter<boolean> = new EventEmitter<boolean>();

  pagination= false;
  tablavalida = true;
  guardarcambios = false;
  insertando = false;
  copiaData;
  TipoCodigoPromocionalEntradas: any[];
  expandedElement: any | null;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR> = [];
  editItemsMotivosGroupForm: FormGroup;


  constructor( 
    translator: Translator,
    tokenService: ApiTokenService,
    editClientService: EditClientGroupService,
    //filterService: FilterService,
    public tableInteractiveService: TableInteractiveService, 
    public cdRef: ChangeDetectorRef,
    panelService: PanelService,
    public dialog: MatDialog,
    public servicioAPI: ConectarApiService,
    alertaService: AlertService,
    clientService: ClientService,
    public snackvar_service: SnackbarInfoService,
    imgroupService: ItemsMotivosGruposV11Service,
    grupoTipoBonoService: GrupoTipoBonoV11Service,
    grupoRecintosService: EditGrupoRecintosService,
    recintosVisitaService: RecintosVisitaService,
    gruposActividadTallerService: GruposActividadTallerService,
    categoriasService: EditCategoriasService,
    diasdeCierreService: EditDiasdeCierreService,
    TiposCodigoPromocionesService: EditTipoCodigoPromocionesService,
    bonoTypeBono: BonoTypeService,
    public httpErrorService: HttpErrorsService,
    route: ActivatedRoute,
    router: Router
    

    ) {

      
    
    super(translator,
      tokenService,
      editClientService,
      tableInteractiveService,
      cdRef,
      panelService,
      dialog,
      servicioAPI,
      alertaService,
      clientService,
      snackvar_service,
      imgroupService,
      grupoTipoBonoService,
      grupoRecintosService,
      recintosVisitaService,
      gruposActividadTallerService,
      categoriasService,
      diasdeCierreService,
      TiposCodigoPromocionesService,
      bonoTypeBono,
      httpErrorService,
      route,
      router);
      
  }

  ngOnInit(): void {

    this.findTable();
    super.ngOnInit();
    this.getIdiomas([]);

  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
     this.tableInteractiveService.tableLoaded.subscribe(ev => {
      this.tableLoaded = ev;
      this.copiaData = this.dataSource.data;
      this.cargadatabla.emit(true);
      this.dataSource.data.forEach((ds,i) => {
        this.dataSource.data[i]["updated"] = false;
      } );
      
    }); 
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  findTable() {
    // aqui se podria añadir el campo actualizado en la tabla
    this.tableInteractiveService.findTable(this.tableName);
  }

  somethingChanged(e,v,i,n) {

    //actualizada la fila, se tendrá que guardar
    //this.guardarcambios = true;
    this.dataSource.data[i][n] = v;
    this.dataSource.data[i]["updated"] = true;
    this.validateRowRequired(i);
  }

  somethingChangedDate(e,v,i,n) {
    console.log("somethingChangedDate valor:: ",v);

    //this.guardarcambios = true;
    this.dataSource.data[i][n] = new FormControl(moment(v,"DD-MM-YYYY"));
    this.dataSource.data[i]["updated"] = true;
    this.validateRowRequired(i);
  }


  focusOutFunction(e,v,i,n,row) {
    console.log("somethingChangedDate valor:: ",v);

    //this.guardarcambios = true;
    
    //this.dataSource.data[i][n] = new FormControl(moment(v,"DD-MM-YYYY"));
    //this.dataSource.data[i]["updated"] = true;
    //this.validateRowRequired(i);

    let dataSource = this.dataSource;
    switch (this.tableName) {
      case 'Calendariodias_tiposcontrol':
        break;
      case 'Calendariohoras_tiposcontrol':
        break;
      case 'tbTiposPromocionEntradas':
          //comprobamos porcentajes
          let porcentajeTotal = 0;
          dataSource.data.forEach(item => {
            if (item.rPorcentaje == "") item.rPorcentaje = 0;
            if (!item.deleted) porcentajeTotal += parseFloat(item.rPorcentaje);
          });
          
          if ((porcentajeTotal > 0 && (porcentajeTotal === 100))) {
            //dataSource.data.splice(dataSource.data.findIndex(item => item.pkId == row.pkId),1);
            //this.guardarTabla(dataSource.data);
          } else {

            this.snackvar_service.openSnackBar(this.translation.ElPorcentajeHaDeSerDelCienPorciento,"",'red-snackbar');
            this.guardarcambios = false;
/*             const dialogRef = this.dialog.open(DialogoPopUp, {
              width: '250px',
              data: {
                titulo: this.translation.PorcentajeIncorrecto,
                texto: this.translation.ElPorcentajeHaDeSerDelCienPorciento,
                ok: this.translation.Confirmar
              }
            }); */
          }
      break;
      default:
          this.dataSource.data.splice(this.dataSource.data.findIndex(item => item.pkId == row.pkId),1);
          this.guardarTabla(dataSource.data);
      break;
    }

  }


  validateRowRequired(i) {

    this.guardarcambios = true;
    document.getElementById("row_"+i).classList.remove("requiredrow");
    //this.insertando = true;
    for (var key in this.dataSource.data[i]) {
      if (this.dataSource.data[i].hasOwnProperty(key)) {
          console.log(key + " -> " + this.dataSource.data[i][key]);
          let col = this.table.columns.find(f => f.name == key);
          if (col["required"] && col["required"] == true) {

            switch (col.type) {
              case "date":
                if (this.dataSource.data[i][key].value== undefined || this.dataSource.data[i][key].value=='' || !this.dataSource.data[i][key].value._isValid)
                {
                  this.guardarcambios = false;
                  document.getElementById("row_"+i).classList.add("requiredrow");
                }
                else {
                  if (this.guardarcambios==true) document.getElementById("row_"+i).classList.remove("requiredrow");
                  //this.insertando = false;
                }

                break;
              default:
                if (this. dataSource.data[i][key]=="") 
                {
                  this.guardarcambios = false;
                  document.getElementById("row_"+i).classList.add("requiredrow");
                }
                else {
                  if (this.guardarcambios==true) document.getElementById("row_"+i).classList.remove("requiredrow");
                  //this.insertando = false;
                }
                break;
            }

          }
      }
    }

    
  }


  guardarTabla(dataSourceData?) {
    
    if (dataSourceData) this.dataSource.data = dataSourceData;
    this.dataSource.data  = this.dataSource.data.filter( f=> { return !f.deleted});
    this.insertando = false;
    this.guardarcambios  = false;
    this.guardatabla.emit(true);
    console.log("Datos tabla: ", this.dataSource.data)
    // eviar datos al servicio TODO
  }

  cancelarCambios() {

    super.ngOnInit();

/*     this.dataSource.data = this.copiaData;
    this.dataSource.filter = "";

    this.cdRef.detectChanges();
    this.inttable.renderRows(); */
    this.guardarcambios = false;
    this.insertando = false;
  }

  actionTableManage(action, row,i) {
    switch (action) {
      case 'borrar':
        this.borrarFila(row,this.dataSource);
        break;

      default:
        break;
    }
  }

  anadirEntrada() {
    //if (!this.insertando) {
    this.guardarcambios = false;
    this.insertando = true;
    let fila = {};
    this.table.columns.forEach(c => {
      if (c.name!="Actions") {
      switch (c.type) {
        case "number":
          if (c.name=="pkId")
            fila[c.name] ="";
          else 
            fila[c.name] ="0";
          break;
        default:
          fila[c.name] ="";
          break;
      }
    }
      
    });
    this.dataSource.data.push(fila);
  
    this.dataSource.filter = "";
    //let c1 = this.inttable._getRenderedRows(this.inttable._rowOutlet);
    let trs = this.inttable._getRenderedRows(this.inttable._rowOutlet);
    //this.validateRowRequired(this.dataSource.data.length-1);
/*     console.log("???(trs,columns):", trs,this.table.columns,trs.length);
    let c11 = trs[trs.length-2];
    trs[0].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = true;
    trs[1].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = true; */
    //trs[2].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
    let _that = this;
    setTimeout(function() {
      //trs[trs.length-1].children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
/*       _that.table.columns.forEach( (c,i) => {
        if (c.name!="pkId" && c.name!="Actions") {
          if (c.type=="inputFilter") {
            trs[trs.length-1].children[i-1].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false;
            //trs[trs.length-1].children[i-1].children[0].children[0].children[0].children[0].children[0].children[2].children[0].focus();
          } if (c.type=="select") {
            //trs[trs.length-1].children[i-1].children[0].setAttribute("disabled","false");
          } else {
            trs[trs.length-1].children[i-1].children[0]["disabled"] = false;
          }
          //trs[trs.length-1].children[i-1].children[0].setAttribute("focus","true");
        }
      }); */

    },1000);
    //this.cdRef.detectChanges();
    //this.inttable.renderRows();


    //let c12 = c1[c1.length-1];
    //c12.children[0].children[0].children[0].children[0].children[0].children[0].children[2].children[0]["disabled"] = false

    //let cc1 = c1.children[0].children[0].focus();
    //console.log("Focus2: ",c1,cc1);

    //console.log("FocusHTML: ",this.inttable.dataSource.connect());
    //this.inttable.
/*     this.inttable.renderRows();
    this.cdRef.detectChanges(); */
    //}
  }

  borrarFila(row,dataSource): void {

    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarRegistro, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });

    this.guardarcambios = true;
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
/*         this.dataSource.data.findIndex(item=>
          item.pkId == row.pkId)); */
          console.log("Borrar", 
          row,
          this.dataSource.data,
          this.dataSource.data.findIndex(item => item.pkId == row.pkId)
         
          );  
          //this.dataSource.data = this.dataSource.data.splice(this.dataSource.data.findIndex(item => item.pkId == row.pkId),1);
/*           this.dataSource.data = this.dataSource.data.splice(this.dataSource.data.findIndex(item=>
          item.pkId == row.pkId)); */
              
          // mat-table does not support detecting datasource change, have to manually render rows
          //this.inttable.renderRows();
          //this.cdRef.detectChanges();
          //this.innerTable.first.renderRows();
          switch (this.tableName) {
            case 'Calendariodias_tiposcontrol':
              dataSource.data.splice(dataSource.data.findIndex(item => item.pkId == row.pkId),1);
              this.servicioAPI.delCalendariosTiposControl(row.pkId,row.CodigoTipoControl).subscribe(delcal => {
                if (delcal.Mensajes!=null && delcal.Mensajes.length>0)  
                  this.snackvar_service.openSnackBar(this.translation[delcal.Mensajes[0].CodigoMensaje],"",'red-snackbar');
                else  {
                  this.guardarTabla(dataSource.data);
                  this.reloadTable();
                }
              });
              break;
            case 'Calendariohoras_tiposcontrol':
              dataSource.data.splice(dataSource.data.findIndex(item => item.pkId == row.pkId),1);
              this.servicioAPI.delCalendariosTiposControl(row.pkId,row.CodigoTipoControl).subscribe(delcal => {
                if (delcal.Mensajes!=null && delcal.Mensajes.length>0)  
                  this.snackvar_service.openSnackBar(this.translation[delcal.Mensajes[0].CodigoMensaje],"",'red-snackbar');
                else {
                  this.guardarTabla(dataSource.data);
                  this.reloadTable();
                }
                  
              });
              break;

              case 'tbTiposPromocionEntradas':
                //comprobamos porcentajes
                let index = this.dataSource.data.findIndex(item => item.pkId == row.pkId);
                document.getElementById("row_"+index).style.display = "none";
                this.dataSource.data[index].deleted = true;
                let porcentajeTotal = 0;
                dataSource.data.forEach(item => {
                  if (item.rPorcentaje == "") item.rPorcentaje = 0;
                  if (item && item.pkId!=row.pkId && !row.deleted) porcentajeTotal += parseFloat(item.rPorcentaje);
                });
                
                if ((porcentajeTotal > 0 && (porcentajeTotal === 100))) {
                  dataSource.data.splice(dataSource.data.findIndex(item => item.pkId == row.pkId),1);
                  this.guardarTabla(dataSource.data);
                } else {
                  this.guardarcambios = false;
                  this.snackvar_service.openSnackBar(this.translation.ElPorcentajeHaDeSerDelCienPorciento,"",'red-snackbar');

/*                   const dialogRef = this.dialog.open(DialogoPopUp, {
                    width: '250px',
                    data: {
                      titulo: this.translation.PorcentajeIncorrecto,
                      texto: this.translation.ElPorcentajeHaDeSerDelCienPorciento,
                      ok: this.translation.Confirmar
                    }
                  }); */
                }
              break;
              default:
                this.dataSource.data.splice(this.dataSource.data.findIndex(item => item.pkId == row.pkId),1);
                this.guardarTabla(dataSource.data);
              break;
          }

      }
    });
  }



  closeInputFilter(enventValues,i,n){

    console.log("closeInput: ",i,n);
    //this.guardarcambios = true;
    this.dataSource.data[i][n] = enventValues.value;
    this.validateRowRequired(i);
    
  }

  expandRow(e,row) {

    if(e.target.innerHTML!="more_vert") {
      this.expandedElement = this.expandedElement === row ? null : row;
    }
    
  }

  getIdiomas(idiomasgrupo?, mode?) {
    this.servicioAPI.getIdiomas().subscribe(losidiomas => {
      losidiomas.DatosResult.ListaIdiomas.map(idioma => {
        console.log(idioma)
        let idiomaselect = new IdiomaGR();
        idiomaselect.CodIdioma = idioma.CodIdioma;
        idiomaselect.Nombre = idioma.Nombre;
        this.idiomasApp.push(idiomaselect);

        if (idiomasgrupo) {
        let idiomaObj = idiomasgrupo.find(i=>i.CodIdioma == idioma.CodIdioma); 
              

                this.editorListaIdiomas.push(idioma);
                //this.editItemsMotivosGroupForm.addControl('nombre' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Nombre:""));
                //this.editItemsMotivosGroupForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Descripcion:""));

        }          
        
       
        console.log("Mode", mode);
      });

      //this.itemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value
      //this.valuedChanged();
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  cambiaNombre(evento, idioma) {

    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
    //
  }

  getDatoIdioma(v,codId, campo) {
    return v.Idiomas.find(f => f.CodIdioma == codId)[campo];
  }

  idiomaChanged(e,v,i,codid,campo) {
    this.guardarcambios = true;
    this.dataSource.data[i]["Idiomas"].find(f => f.CodIdioma == codid)[campo] = v;
  }

/*   getTable() {
    this.tableInteractiveService.table$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      this.table = data;
      if (this.table.columns != null) {
        this.columnsToDisplay = [];
        this.showTable = false;
        this.displayedColumnsFunction();
      }
    });
  }

  displayedColumnsFunction() {
    this.table.columns.map(elem => {
      if (elem.columnActive != '' && elem.column) {
        this.showTable = true;
        this.columnsToDisplay.push(elem.name);
      }
    });
  } */


  /*
  //modifica el estado para que se pueda
  ocultarCeldas(celda) {
    celda.editar = true;
    this.filas.forEach(f => {
      f.forEach(c => {
        if (c != celda) c.editar = false;
      });
    });
  } */

}
