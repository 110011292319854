import { Component, Input } from '@angular/core';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
    selector: 'customan-dual-list',
    templateUrl: './customan-dual-list.component.html',
    styleUrls: [ './customan-dual-list.component.scss' ]
})

export class CustomanDualListComponent extends DualListComponent {
    @Input() listLabel: string;
    @Input() copyList: any;
    @Input() filter: boolean;

    listanmenu;
    
    verlista(nombre) {
        event.stopPropagation();
        console.log("Esta es la lista de enlaces: ", this.copyList);
    }


    executeCopy(text) {
        var input = document.createElement('textarea');
        document.body.appendChild(input);
        input.value = text;
        input.focus();
        input.select();
        document.execCommand('Copy');
        input.remove();
    }

    clipboard(url,params) {
       
        //navigator.clipboard.writeText(url+params); // posibles problemas con seguridad, es para copiar cosas grandes y binarias.

        this.executeCopy(url+params);
        /*
        var r = document.createRange();
        console.log("Create Range", r);
        document.getElementById("myclipboard").innerHTML= url+params;
        r.selectNode(document.getElementById("myclipboard"));
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(r);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
        */
        

/*     if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(document.getElementById(containerid));
        range.select().createTextRange();
        document.execCommand("copy");
        } else if (window.getSelection) {
        var range = document.createRange();
        range.selectNode(document.getElementById(containerid));
        window.getSelection().addRange(range);
        document.execCommand("copy");
        alert("Text has been copied, now paste in the text-area")
        } */

/*         var urllink = document.querySelector('.laurl');
        var range = document.createRange();
        range.selectNode(urllink);
        window.getSelection().addRange(range);
    
        try {
        // Now that we've selected the anchor text, execute the copy command
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copy email command was ' + msg,range);
        } catch(err) {
        console.log('Oops, unable to copy');
        } */
    }
}