<ng-container>

  <mat-toolbar  class="main-header" >
    <mat-toolbar-row class="md-tall">
      <div class="header-menu">
        <div>
          <mat-icon matRipple (click)="menutoogle()" class="main-toolbar-icon">menu</mat-icon>
          <span class="toolbar-title"  [ngClass]="(!opened) ? 'toolbar-title-off' : 'toolbar-title-on' ">{{sectionTitle}}</span>
        </div>
          <mat-icon (click)="logout()" class="main-toolbar-icon">logout</mat-icon>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row class="md-tall">
      <app-breadcrumbs></app-breadcrumbs>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container class="main-container">

    <mat-sidenav #sidenav mode="side" opened class="main-sidenav"
                 [fixedInViewport]="true" [fixedTopGap]="0"
                 [fixedBottomGap]="0">

    <mat-icon matRipple (click)="sidenavClose()" class="clear-sidenav">clear</mat-icon>
    <div class="logomenu" style="position: fixed;width:300px;height: 120px;z-index: 999;">
      <div id="logomenu"></div>

<!--       <img style="margin: 20px 0px 0px 35px; width: 140px;" src="../assets/layout-img/logo-footer.png"/>
 -->    </div>

    <!-- sidenav content-->
    <div class="sidenav-menu">
      <app-sidemenu></app-sidemenu>
    </div>
    <!-- Fin sidenav content -->
    </mat-sidenav>

    <mat-sidenav-content id="eltope">
      <router-outlet></router-outlet>
      <button mat-icon-button color="primary" class="totop" aria-label="top to page">
        <mat-icon (click)="toTop();">keyboard_arrow_up</mat-icon>
      </button>
    </mat-sidenav-content>

  </mat-sidenav-container>

  <!-- footer -->
  <mat-toolbar class="main-footer" [ngClass]="(!opened) ? 'toolbar-footer-off' : 'toolbar-footer-on' ">
    <mat-toolbar-row>
      <!--span>footer</span-->
      <span class="example-spacer"></span>
      <span class="copyright"><span style="margin-top:10px"><mat-icon style="font-size: 18px;vertical-align: middle;">copyright</mat-icon></span><span>Copyright IACPOS {{ currentYear | date  : 'yyyy' }}</span></span>
    </mat-toolbar-row>
  </mat-toolbar>
  <!-- Fin footer -->

</ng-container>
