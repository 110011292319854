import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, LOCALE_ID } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditInternetGroupService } from '@sharedV11/services/components/internetGroups/edit-internet-group.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { InternetGroup } from '@sharedV11/classes/tarifaResponse/internetgroup';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';
import { AlertService } from '@sharedV11/services/alerts/alert/alert.service';
import { TiposSesion } from '@sharedV11/classes/recintos/tipos_sesion';
import { EditTipoSesionService } from '@sharedV11/services/components/tiposesion/edit-tipo-sesion.service';
import { DatePipe } from '@angular/common';
import { CalendariosTipoControl } from '@sharedV11/classes/maestros/calendarios/CalendariosTiposControl_Respuesta';
import { GlobalService } from '@sharedV11/services/global/global.service';
import moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { AppDateAdapter, APP_FORMATOS_FECHA } from '@sharedV11/classes/dataAdapter/date-format.adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ApiWaitComponent } from '@sharedV11/components/dialogs/api_wait/api_wait.component';
import { Directionality } from '@angular/cdk/bidi';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';



class duallistmodel {
  pkId: string;
  Nombre: string;
}

class intervalosAforo {
  id: number;
  texto: string;
  minutos: number;
}

@Component({
  selector: 'app-edit-sessiontype',
  templateUrl: './edit-sessiontype.component.html',
  styleUrls: ['./edit-sessiontype.component.scss'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})



export class EditSessionTypeComponent implements OnInit, AfterViewInit {
  
  loading = true;
  disabled =false;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR>;
  editTiposSesionForm: FormGroup;


  tiposesiondata: TiposSesion;
  TiposReservas: any[] = [];
  EnlacesMenu: any[] = [];
  Recintos: any;
  RecintoInfo: any;
  fecha_minDesde: Date;
  fecha_minHasta: Date;
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  arraydias: Array<string> = [];
  defaultseldays:  Array<string> = ["Lunes","Martes","Miércoles","Jueves","Viernes","Sábado","Domingo"];
  objedicionCalendarioHorario: CalendariosTipoControl = new CalendariosTipoControl;
  arr_intervalos_aforo: Array<intervalosAforo> = [{ id: 0, texto: 'SinIntervalo', minutos: 0 }, {
    id: 1,
    texto: '5 minutos',
    minutos: 5
  }, { id: 2, texto: '10 minutos', minutos: 10 }
    , { id: 3, texto: '15 minutos', minutos: 15 }, { id: 4, texto: '20 minutos', minutos: 20 }, {
    id: 5,
    texto: '25 minutos',
    minutos: 25
  }, { id: 6, texto: '30 minutos', minutos: 30 }
    , { id: 7, texto: '35 minutos', minutos: 35 }, { id: 8, texto: '40 minutos', minutos: 40 }, {
    id: 5,
    texto: '45 minutos',
    minutos: 45
  }, { id: 9, texto: '50 minutos', minutos: 50 }
    , { id: 10, texto: '55 minutos', minutos: 55 }, { id: 11, texto: '1 hora', minutos: 60 }, {
    id: 12,
    texto: '1 hora 30 minutos',
    minutos: 90
  }, { id: 13, texto: '2 horas', minutos: 120 }
    , { id: 14, texto: '2 horas 30 minutos', minutos: 150 }, { id: 15, texto: '3 horas', minutos: 180 }, {
    id: 16,
    texto: '3 horas 30 minutos',
    minutos: 210
  }, { id: 17, texto: '4 horas', minutos: 240 }
    , { id: 18, texto: '4 horas 30 minutos', minutos: 270 }, { id: 19, texto: '5 horas', minutos: 300 }, {
    id: 20,
    texto: '5 horas 30 minutos',
    minutos: 330
  }, { id: 21, texto: '6 horas', minutos: 360 }
    , { id: 22, texto: '6 horas 30 minutos', minutos: 390 }, { id: 23, texto: '7 horas', minutos: 420 }, {
    id: 24,
    texto: '7 horas 30 minutos',
    minutos: 450
  }, { id: 25, texto: '8 horas', minutos: 480 }];


  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  constructor(
    private editInternetGroupService: EditTipoSesionService,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private tokenService: ApiTokenService,
    private alertaService: AlertService,
    public globales: GlobalService,
    private httpErrorService: HttpErrorsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private gsCentros: GlobalServicCentros

  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)
    this.getIdiomas();
    this.translation = this.translator.GetTranslations();
  }

  ngAfterViewInit() {

    // this.pkId="4"

  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  get formControls() {
    return this.editTiposSesionForm.controls;
  }


  manageForm(pkId, mode) {

    if(mode=="I"){
      this.tiposesiondata=this.editTiposSesionForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.tiposesiondata);
      this.loading = false;
      this.valuedChanged()
      this.getIdiomas([], this.mode)
      this.getRecintos();

    }
    if(mode=="U"){
      console.log("mode U")
      this.disabled=true
    }
    if (pkId != null) {
      this.findTipoSesion(pkId)
    }
  }



  findTipoSesion(pkId) {
    let ClaveEmpresa = this.tokenService.getConfig('chClaveEmpresa')
    //let ProyectoOnLine= this.tokenService.getConfig('chProyectoOnLine')
    let ConexionEntorno = this.tokenService.getConfig('chEntornoConexion')
    this.editInternetGroupService.findTipoSesionPkId(pkId, ClaveEmpresa, ConexionEntorno).pipe(takeUntil(this.ngUnsubscribe)).subscribe(userApi => {
      // let user:User =  userApi["DatosResult"]["tipoUsuarios"][0];
      let tiposesiondata: TiposSesion = userApi.DatosResult.Lista[0]
      if (tiposesiondata != null) {
        this.getArrayDias(tiposesiondata);
        this.changeValueForm(tiposesiondata)
        this.tiposesiondata=this.editTiposSesionForm.value
        this.tiposesiondata.CentrosIds = tiposesiondata.CentrosIds;
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.tiposesiondata);
        this.loading = false;
      }
      this.getRecintos();
      //this.getIdiomas(tiposesiondata.Idiomas,this.mode)
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )

  }

  /*
  initializeFormWithData(ts:TiposSesion){
    
    this.editTiposSesionForm= this.fb.group({
      pkId:[ts.pkId],
      Recinto:[ts.Recinto,Validators.required],
      Descripcion:[ts.Descripcion,Validators.required],
      CodIdioma:[ts.CodIdioma],
      AforoMaximo:[ts.AforoMaximo,[Validators.required, Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.Recintos))]],
      MinimoAforo:[ts.MinimoAforo,Validators.required],
      MaximoInternet:[ts.MaximoInternet],
      Intervalo:[ts.Intervalo,Validators.required],
      NumeroGrupos:[ts.NumeroGrupos],
      HoraInicio:[ts.HoraInicio,Validators.required],
      HoraFin:[ts.HoraFin,Validators.required],
      FechaInicio:[ts.FechaInicio, Validators.required],
      FechaFin:[ts.FechaFin,Validators.required],
      tipoControlDias:[this.arraydias],
      Butacas:[this.string01ToBoolean(ts.Butacas)],
      EnvioExternos:[this.string01ToBoolean(ts.EnvioExternos)],
      Evento:[this.string01ToBoolean(ts.Evento)],
      haygrupos:[ts.NumeroGrupos != "0" ? true : false]

    });
  }
  */

  changeValueForm(ts: TiposSesion) {


    let checkgrupos = false;
    let numerodegrupos = 0;
    if (ts.NumeroGrupos != "0") {
      checkgrupos = true;
      numerodegrupos = parseInt(ts.NumeroGrupos) - 1;

    }

    this.formControls["pkId"].setValue(ts.pkId);
    this.formControls["Recinto"].setValue(ts.Recinto)
    this.formControls["Descripcion"].setValue(ts.Descripcion)
    this.formControls["CodIdioma"].setValue(ts.CodIdioma)
    this.formControls["AforoMaximo"].setValue(ts.AforoMaximo)
    this.formControls["MinimoAforo"].setValue(ts.MinimoAforo)
    this.formControls["MaximoInternet"].setValue(ts.MaximoInternet)
    this.formControls["NumeroGrupos"].setValue(numerodegrupos)
    this.formControls["Intervalo"].setValue(ts.Intervalo)
    this.formControls["HoraInicio"].setValue(this.globales.anadirCeroHoraLeft(ts.HoraInicio.substring(10, 20).trim()))
    this.formControls["HoraFin"].setValue(this.globales.anadirCeroHoraLeft(ts.HoraFin.substring(10, 20).trim()))
    this.formControls["FechaInicio"].setValue(this.globales.aFecha(ts.FechaInicio))
    this.formControls["FechaFin"].setValue(this.globales.aFecha(ts.FechaFin))
    this.formControls["duracionhora"].setValue(Math.floor(parseInt(ts.Duracion) / 60))
    this.formControls["duracionminuto"].setValue(parseInt(ts.Duracion) % 60)
    this.formControls["tipoControlDias"].setValue(this.arraydias)

    this.formControls["Butacas"].setValue(ts.Butacas == "1" ? true : false)
    this.formControls["EnvioExternos"].setValue(ts.EnvioExternos == "1" ? true : false)
    this.formControls["Evento"].setValue(ts.Evento == "1" ? true : false)
    this.formControls["Didactica"].setValue(ts.Didactica)
    this.formControls["haygrupos"].setValue(ts.NumeroGrupos != "0" ? true : false)



  }
  initializeFormWithoutData() {

    this.editTiposSesionForm = this.fb.group({
      pkId: [""],
      Recinto: [""],
      Descripcion: [""],
      CodIdioma: [""],
      AforoMaximo: [""],
      MinimoAforo: [""],
      MaximoInternet: [""],
      NumeroGrupos: ["0"],
      HoraInicio: [""],
      HoraFin: ["", [this.isAfterDate("HoraInicio")]],
      FechaInicio: [""],
      FechaFin: ["", [Validators.required, this.isAfterDate("FechaInicio")]],
      Intervalo: [""],
      duracionhora: [0],
      duracionminuto: [0],
      tipoControlDias: [],
      Butacas: [false],
      EnvioExternos: [false],
      Evento: [false],
      Didactica: [""],
      haygrupos: [false]



    });
  }

  get f() {
    return this.editTiposSesionForm.controls;
  } //Editor

  string01ToBoolean(value) {

    if (typeof value !== "boolean") {

      if (value.toLowerCase() == "1") {
        value = true;
      } else {
        value = false;
      }
    }
    return value
  }

  getHoras(s) {
    return Math.floor(parseInt(s) / 60)
  }

  getTipoReserva() {
    this.servicioAPI.getTiposReserva().subscribe(TiposReservas => {
      this.TiposReservas = TiposReservas.DatosResult.listaTipoReserva;
    })
  }

  CambioRecintoSeleccionado(value) {

    let recinto = this.Recintos.filter(f => f.pkId == value);
    console.log("Cambirecinto: ", recinto)
    if (recinto) this.formControls["AforoMaximo"].setValidators([Validators.required, this.RangoValidador_Aforo(0, parseInt(recinto[0].AforoMaximo))]);
  }

  filtrarRecintosSinButacas(recintos: any[]) {
    if (recintos == null) {
      return [];
    } else {
      console.log("recintos", recintos);
      let respuesta = recintos["DatosResult"].ListaRecintos.filter(x => x.Butacas != '1');
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }

  cambiodeRecinto(e) {
    if (this.Recintos) {
      this.Recintos.forEach(element => {
        if (element.Nombre == e.srcElement.value) { 
          this.RecintoInfo = element;
          this.formControls["FechaInicio"].enable();
          this.formControls["FechaFin"].enable();
          this.formControls["Didactica"].enable();
          this.formControls["Butacas"].enable();
          this.formControls["EnvioExternos"].enable();
          this.formControls["Evento"].enable();
          this.formControls["tipoControlDias"].enable();
          this.formControls["Intervalo"].enable();
          this.formControls["duracionhora"].enable();
          this.formControls["duracionminuto"].enable();
          
          //if (this.mode=="I") {
            this.formControls["AforoMaximo"].setValue(this.RecintoInfo.AforoMaximo);
            this.formControls["MinimoAforo"].setValue(this.RecintoInfo.MinimoAforo);
            this.formControls["MaximoInternet"].setValue(this.RecintoInfo.MaximoInternet);
            this.formControls["HoraInicio"].setValue(this.globales.anadirCeroHoraLeft(this.RecintoInfo.HoraInicio.substring(10, 20).trim()));
            this.formControls["HoraFin"].setValue(this.globales.anadirCeroHoraLeft(this.RecintoInfo.HoraFin.substring(10, 20).trim()));
            this.formControls["Didactica"].setValue(0);
          //}
          
          switch (this.RecintoInfo.TipoAforo) {
            case "SF":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
                break;
            case "SI":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
                //if (this.mode=="I") {
                  this.formControls["FechaInicio"].disable();
                  this.formControls["FechaFin"].disable();
                  this.formControls["Didactica"].disable();
                  this.formControls["Butacas"].disable();
                  this.formControls["EnvioExternos"].disable();
                  this.formControls["Evento"].disable();
                  this.formControls["tipoControlDias"].disable();

                  this.formControls["Intervalo"].disable();
                  this.formControls["duracionhora"].disable();
                  this.formControls["duracionminuto"].disable();
                  this.formControls["HoraInicio"].disable();
                  this.formControls["HoraFin"].disable();
                //}
               
                break;
            case "SD":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
                //if (this.mode=="I") {
                  this.formControls["Intervalo"].disable();
                  this.formControls["duracionhora"].disable();
                  this.formControls["duracionminuto"].disable();
                  this.formControls["Didactica"].disable();
                  this.formControls["Butacas"].disable();
                //}
                break;
            default:
        } 
          
          }
      });
    }


  }

  getRecintos() {
    this.servicioAPI.getRecintos().subscribe(recintos => {
      this.Recintos = this.filtrarRecintosSinButacas(recintos);
      let recinto = this.Recintos.filter(f => f.pkId == this.tiposesiondata.Recinto);
      console.log("recinto!! ",recinto[0]);
      switch (recinto[0].TipoAforo) {
        case "SF":
            //this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
            break;
        case "SI":
            //this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
            //if (this.mode=="I") {
              this.formControls["FechaInicio"].disable();
              this.formControls["FechaFin"].disable();
              this.formControls["Didactica"].disable();
              this.formControls["Butacas"].disable();
              this.formControls["EnvioExternos"].disable();
              this.formControls["Evento"].disable();
              this.formControls["tipoControlDias"].disable();
  
              this.formControls["Intervalo"].disable();
              this.formControls["duracionhora"].disable();
              this.formControls["duracionminuto"].disable();
              this.formControls["HoraInicio"].disable();
              this.formControls["HoraFin"].disable();
            //}
           
            break;
        case "SD":
            //this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
            //if (this.mode=="I") {
              this.formControls["Intervalo"].disable();
              this.formControls["duracionhora"].disable();
              this.formControls["duracionminuto"].disable();
              this.formControls["Didactica"].disable();
              this.formControls["Butacas"].disable();
            //}
            break;
        default:
      }  
      this.Recintos.forEach(element => {
        if (element.pkId == this.tiposesiondata.Recinto) { 
          this.RecintoInfo = element;
          switch (this.RecintoInfo.TipoAforo) {
            case "SF":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
                break;
            case "SI":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
                break;
            case "SD":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
                break;
            default:
        } 
          
          }
      });

      if (this.RecintoInfo) this.formControls["AforoMaximo"].setValidators([Validators.required, this.RangoValidador_Aforo(0, parseInt(this.RecintoInfo.AforoMaximo))]);

    })
  }


  submit() {

    this.editTiposSesionForm.markAllAsTouched()
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editTiposSesionForm.valid) {
      let tiposesion: TiposSesion = this.editTiposSesionForm.value;
      this.manageApi(tiposesion, this.mode)
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.loading = false;
    this.panelService.setClosePanel(null);
  }

  save(){
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.tiposesiondata)
  }


  manageApi(tiposesion: TiposSesion, mode) {
    //this.insertDataInObj();
    if (!tiposesion.duracionhora) tiposesion.duracionhora = "0";
    if (!tiposesion.duracionminuto) tiposesion.duracionminuto = "0";

    tiposesion.Duracion = ((parseInt(tiposesion.duracionhora) * 60) + parseInt(tiposesion.duracionminuto)).toString()

    console.log(tiposesion)
    tiposesion.Lunes = tiposesion.Martes = tiposesion.Miercoles = tiposesion.Jueves = tiposesion.Viernes = tiposesion.Sabado = tiposesion.Domingo = "0";
    console.log(tiposesion)
    this.arraydias.forEach(d => {
      console.log(d)
      if (d == "1" || d["id"] == "1") tiposesion.Lunes = "1";
      if (d == "2" || d["id"] == "2") tiposesion.Martes = "1";
      if (d == "3" || d["id"] == "3") tiposesion.Miercoles = "1";
      if (d == "4" || d["id"] == "4") tiposesion.Jueves = "1";
      if (d == "5" || d["id"] == "5") tiposesion.Viernes = "1";
      if (d == "6" || d["id"] == "6") tiposesion.Sabado = "1";
      if (d == "7" || d["id"] == "7") tiposesion.Domingo = "1";
      console.log(tiposesion)
    })

    if (this.formControls["Evento"].value == true)
      tiposesion.Evento = "1"
    else
      tiposesion.Evento = "0";

    if (this.formControls["Butacas"].value == true)
      tiposesion.Butacas = "1"
    else
      tiposesion.Butacas = "0";

    if (this.formControls["EnvioExternos"].value == true)
      tiposesion.EnvioExternos = "1"
    else
      tiposesion.EnvioExternos = "0";

    if(moment((this.editTiposSesionForm.get('FechaInicio').value)).isValid()){
      tiposesion.FechaInicio = moment((this.editTiposSesionForm.get('FechaInicio').value)).format('DD/MM/YYYY 0:00:00')
    }
    if(moment((this.editTiposSesionForm.get('FechaFin').value)).isValid()){
      tiposesion.FechaFin = moment((this.editTiposSesionForm.get('FechaFin').value)).format('DD/MM/YYYY 0:00:00')
    }

    let numerodegrupos = parseInt(this.formControls["NumeroGrupos"].value) + 1;
    if (!this.formControls["haygrupos"].value) numerodegrupos = 0;
    tiposesion.NumeroGrupos = numerodegrupos.toString();

    tiposesion.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        tiposesion.pkId = "0"
        this.InsTipoSesion(tiposesion)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdTipoSesion(tiposesion)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        tiposesion.pkId = "0"
        this.InsTipoSesion(tiposesion)

        break;

      default:
        break;
    }
  }

  UpdTipoSesion(tiposesion){
    
    const dialogRef = this.dialog.open(ApiWaitComponent, {
      width: '650px',
      disableClose: true,
      data: {
        mensaje: this.translation.apiwait,
      }
    });

    this.editInternetGroupService.updTipoSesion(tiposesion,"U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response=>{
      if (response.DatosResult != null) {
        this.save()
        this.destroy()
      }
      else {
        //this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
        //this.destroy()
        this.loading = false;
        let snack = this._snackBar.open(this.translation[response.Mensajes[0].CodigoMensaje], null, {
          duration: 6000,
          //panelClass: ['blue-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      dialogRef.close();
    },
      error => { this.httpErrorService.identificarErrores(error);  dialogRef.close();}
    )

  }

  InsTipoSesion(tiposesion) {

    const dialogRef = this.dialog.open(ApiWaitComponent, {
      width: '650px',
      disableClose: true,
      data: {
        mensaje: this.translation.apiwait,
      }
    });

    this.editInternetGroupService.insTipoSesion(tiposesion,"I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response=>{
    if (response.DatosResult != null) {
      this.save()
      this.destroy()
    }
    else {
      //this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
      //this.destroy()
      this.loading = false;
      let snack = this._snackBar.open(this.translation[response.Mensajes[0].CodigoMensaje], null, {
        duration: 6000,
        //panelClass: ['blue-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'top'
      });
    }
    dialogRef.close();
  },
  error => { this.httpErrorService.identificarErrores(error); dialogRef.close();});
  }

  getIdiomas(idiomasgrupo?, mode?) {
    this.servicioAPI.getIdiomas().subscribe(losidiomas => {
      this.idiomasApp = losidiomas.DatosResult.ListaIdiomas;
      if (mode) {
        losidiomas.DatosResult.ListaIdiomas.map(idioma => {
          idiomasgrupo.forEach(ig => {
            if (ig.CodIdioma == idioma.CodIdioma) {
              let elidioma: IdiomaGR = new IdiomaGR();
              elidioma.CodIdioma = ig.CodIdioma;
              elidioma.Nombre = ig.Nombre;
              elidioma.Descripcion = ig.Descripcion;
              this.editorListaIdiomas.push(elidioma);
            }
          })
          /*
          if (mode=="U") {
          this.editorListaIdiomas.forEach(idioma => {
            if (this.editTiposSesionForm.get('nombre' + idioma.CodIdioma)) { // form field ya existe
              this.editTiposSesionForm.controls['nombre' + idioma.CodIdioma].setValue(idioma.Nombre);
            } else {
              this.editTiposSesionForm.addControl('nombre' + idioma.CodIdioma, new FormControl(idioma.Nombre));
            }
            if (this.editTiposSesionForm.get('descripcion' + idioma.CodIdioma)) { // form field ya existe
              this.editTiposSesionForm.controls['descripcion' + idioma.CodIdioma].setValue(idioma.Descripcion);
            } else {
              this.editTiposSesionForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(idioma.Descripcion));
            }
          });
          } else {
            console.log("losidiomas", losidiomas);
            losidiomas.DatosResult.ListaIdiomas.forEach(idioma => {
              let elidioma:IdiomaGR = new IdiomaGR();
              elidioma.CodIdioma=idioma.CodIdioma;
              elidioma.Nombre=idioma.CodIdioma+"Nombre";
              elidioma.Descripcion="";
              this.editorListaIdiomas.push(elidioma);
              this.editTiposSesionForm.addControl('nombre' + elidioma.CodIdioma, new FormControl(""));
              this.editTiposSesionForm.addControl('descripcion' + elidioma.CodIdioma, new FormControl(""));
            })
          }
          console.log("Mode", mode);
          */
        });
        this.tiposesiondata = this.editTiposSesionForm.value
        this.valuedChanged();
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }


  cambiadesde(evento) {
    //
    this.fecha_minHasta = evento.value;
    this.tiposesiondata.FechaInicio = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();


    this.editTiposSesionForm.get('FechaInicio').setValue(evento.value);
    this.tiposesiondata.FechaInicio = evento.value;
  }

  cambiahasta(evento) {
    //
    this.fecha_maxDesde = evento.value;
    this.tiposesiondata.FechaFin = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
    this.editTiposSesionForm.get('FechaFin').setValue(evento.value);
  }

  getFechaInicio(): Date {
    //let split = this.model.FechaInicio.split(' ');
    //split = split[0].split('/');
    // return this.aFecha(this.model.FechaInicio);
    //if (this.editTiposSesionForm.get('FechaInicio')) 
    return this.editTiposSesionForm.get('FechaInicio').value
    //else 
    //  return null;
  }

  getFechaFin(): Date {
    // return this.aFecha(this.model.FechaFin);
    //if (this.editTiposSesionForm.get('fechaHasta')) 
    return this.editTiposSesionForm.get('FechaFin').value
    //else 
    //  return null;
  }


  cambiaHoradesde(evento) {
    //
    //this.objedicionCalendarioHorario.FechaAcceso = evento.target.value + ':00';
  }

  cambiaHorahasta(evento) {
    //
    //this.objedicionCalendarioHorario.FechaAccesoFin = evento.target.value + ':00';
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  cambiarDuracionDisabled(evento) {

    if (evento.value !== '0') {
      this.editTiposSesionForm.get('duracionhora').enable();
      this.editTiposSesionForm.get('duracionminuto').enable();
    } else {
      this.editTiposSesionForm.get('duracionhora').disable();
      this.editTiposSesionForm.get('duracionminuto').disable();
    }
  }

  getArrayDias(ts) {

    this.arraydias = [];
    if (ts.Lunes == "1") this.arraydias.push(this.globales.diasSemana[0]);
    if (ts.Martes == "1") this.arraydias.push(this.globales.diasSemana[1]);
    if (ts.Miercoles == "1") this.arraydias.push(this.globales.diasSemana[2]);
    if (ts.Jueves == "1") this.arraydias.push(this.globales.diasSemana[3]);
    if (ts.Viernes == "1") this.arraydias.push(this.globales.diasSemana[4]);
    if (ts.Sabado == "1") this.arraydias.push(this.globales.diasSemana[5]);
    if (ts.Domingo == "1") this.arraydias.push(this.globales.diasSemana[6]);

  }

  CambioDiasSemanaMultiple(evento) {
    //
    //En principio no lo vamos a usar
    this.arraydias = evento.value;
    console.log("evento:", evento, this.arraydias);
  }


  ///Validadores



  RangoValidador_AforoMaximo(): ValidatorFn {

    if (this.Recintos && this.editTiposSesionForm) {
      this.RecintoInfo = this.Recintos.filter(f => f.pkId == this.editTiposSesionForm["Recinto"].value);
      console.log("RecintoInfo: ", this.RecintoInfo);
      let maxaforo = 10000;
      return (control: AbstractControl): { [key: string]: boolean } | null => {

        if (parseInt(this.editTiposSesionForm["AforoMaximo"].value) > maxaforo) {

          return { 'noenRango': true };

        }
        return null;
      };
    }
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return null;
    }

  }

  RangoValidador_Aforo(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }
      return null;
    };

  }

  RangoValidador_AforoInternet(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  RangoValidador_Hora(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let minutos = control.value !== undefined ? (parseInt(control.value.toString().split(':')[0]) * 60) + parseInt(control.value.toString().split(':')[1]) : 0;

      if (control.value !== undefined && (min !== max) && (control.value.trim() == '' || minutos < min || minutos > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  ///Validadores
  isAfterDate(inputCheck): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      console.log(control)
      // if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
      if (control.value !== undefined) {
        console.log(control.value)
        if (moment(control.value, "h:mm:ss").isValid()) {
          let momentValue = moment(control.value, "h:mm:ss")
          console.log("Valid", control.value)
          let fechaDesde = this.editTiposSesionForm.get(inputCheck).value
          if (moment(fechaDesde, "h:mm:ss").isValid()) {
            if (moment(fechaDesde, "h:mm:ss").isAfter(momentValue)) {
              return { 'afterDate': true };
            }
          }
        }

      }
      return null;
    };
  }
  reloadVal(input) {
    console.log("------------------------------", input)
    // this.clearValidators(input);
    //this.editorRegistroForm.get(input).setValidators([Validators.required,this.isAfterDate]);
    this.editTiposSesionForm.get(input).updateValueAndValidity();
  }

  //End Validadores

  valuedChanged() {
    this.editTiposSesionForm.valueChanges.subscribe(value => {
      if (this.tiposesiondata != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.tiposesiondata)) {
          this.panelService.setDiscardChanges(true)


        }
      }
    })
  }
  /* insertDataInForm() {
    console.log((<HTMLInputElement>document.getElementById("Recinto-hidden")).value)
    this.editTiposSesionForm.get("Recinto").setValue((<HTMLInputElement>document.getElementById("Recinto-hidden")).value)

  }
  insertDataInObj() {
    this.tiposesiondata.Recinto = (<HTMLInputElement>document.getElementById("Recinto-hidden")).value

  } */
  closeInputFilter(enventValues) {
    console.log(enventValues)

    
    //this.insertDataInForm()
    this.editTiposSesionForm.get(enventValues.name).setValue(enventValues.value)

    if(enventValues.name=="Recinto"){
      if(enventValues.value!="")
        this.CambioRecintoSeleccionado(enventValues.value)
    }
    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
    console.log(enventValues)

  }


}
