import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild, ComponentRef, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UserType } from 'src/app/shared/classes/userType/user_type';
import { AlertService } from 'src/app/shared/services/alerts/alert/alert.service';
import { PopupService } from 'src/app/shared/services/alerts/popup/popup.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { GlobalService } from 'src/app/shared/services/global/global.service';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { BotonService } from '../../services/boton/boton.service';
import { Boton } from '../../classes/botones/boton';
import { AlertComponent } from '../alert/alert.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { ProfileSaveComponent } from 'src/app/components/user/user-profile-v11/profile-save/profile-save.component';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {

  private ngUnsubscribe = new Subject();


  componentRef: ComponentRef<AlertComponent>;
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() discard = new EventEmitter();
  @Output() open = new EventEmitter();
  @Output() desactivar = new EventEmitter();
  @Input() debaja: boolean;
  @Input() buttonsWrap: string;
  @Input() tableName: string;
  @Input() modificar: boolean = true;
  @Input() addbut: boolean = true;
  

  buttons: object;



  dataSource: MatTableDataSource<UserType>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  translation: any;

  usuarios;


  boton: Boton;
  content:any[]=[];
  layout;
  customanpanel: any;
  eltope: number;

  constructor(
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private botonService: BotonService,
    private panelService: PanelService
  ) { }



  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }
  ngAfterViewInit() {
    //this.initializeForms()
    this.cdRef.detectChanges();
    this.discardValueChanged();
   
    document.getElementById("Cerrar").focus();



  }

  ngOnInit(): void {

    this.eltope = document.getElementById("eltope").scrollTop;
    console.log("El top ", this.eltope);
    
    this.initializeTranslate();

    this.cdRef.detectChanges();

    this.findButton();

    this.botonService.botones$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(b => {
      if(b){

        if (b.buttonsWrap === this.buttonsWrap) {
          this.content = b.content;
        }
      }
    });


  }
  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }
  findButton() {
    this.boton = this.botonService.findTable(this.buttonsWrap);
    this.botonService.setBotones(this.boton);
    if(this.boton){
      this.content = this.boton.content;
      this.layout = this.boton.layout;
    }
  }
  closepanel() {
    this.customanpanel = undefined;

    this.closePanelChangeCss();
  }

  closePanelChangeCss(){
   
    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }
  destroy() {
    //this.appStatus.paneldoc_close.emit();
    this.close.emit(null);
  }

  discardValueChanged() {
    /* let button = (document.getElementById('Descartar') as HTMLButtonElement);
   console.log(button)
   button.disabled=true;  */

    this.panelService.discardChanges$.subscribe(value => {
      console.log(this.content[0][0])
      if (this.content[0] && this.content[0][0]!=undefined) {
        this.content[0].map(c => {
          if (c.buttonTranslate=="Descartar") c.disabled = value;
        })
      } 
      /*       
      let button = (document.getElementById('Descartar') as HTMLButtonElement);
      if (button != null) {
        button.disabled = value;
      } 
      */
    })
  }

  saveRow() {
    this.save.emit()
  }
  adios() {
    console.log("ADIOOOS");
  }
  discardButton() {
    this.discard.emit()
  }

  openPanel() {
    this.open.emit()
  }
  desactivarFunction() {
    this.desactivar.emit()
  }

  funcion1(nombre) {
    switch (nombre) {
      case "save":
        this.saveRow();
        break;
      case "destroy":
        this.destroy();
        break;
      case "discard":
        this.discardButton();
        break;
      case "open":
        this.openPanel();
        break;
      case "desactivar":
        this.desactivarFunction();
        break;
      case "adios":
        this.adios();
        break;
    }

    console.log("El top cierra", this.eltope);
    document.getElementById("eltope").scrollTop = this.eltope;
    
  }

}
