import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { ItemsMotivosGruposV11Service } from '@sharedV11/services/components/entradas-y-promociones/items-motivos-grupos-v11/items-motivos-grupos-v11.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { ItemsMotivosGroupProfile } from '../../../../shared/classes/EntradasPromociones/ItemsMotivosGroup/ItemsMotivosGroupProfile/itemsMotivosGroupsProfile';
import { DatosResult } from '../../../../shared/classes/clients/client/usuario-Respuesta.model';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { T } from '@angular/cdk/keycodes';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-edit-items-motivos-grupos-v11',
  templateUrl: './edit-items-motivos-grupos-v11.component.html',
  styleUrls: ['./edit-items-motivos-grupos-v11.component.scss']
})
export class EditItemsMotivosGruposV11Component implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: any;
  @Input() mode: any;
  @Input() modificar: boolean;
  
  editItemsMotivosGroupForm: FormGroup;
  itemsMotivosGroupProfile: ItemsMotivosGroupProfile;

  grupos: any[] = [];

  translation: any;
  editorListaIdiomas: Array<IdiomaGR> = []; 
  idiomasApp: Array<IdiomaGR> = [];
  
  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,
    private translator: Translator,
    private fb: FormBuilder,
    private itemsMotivosGruposService: ItemsMotivosGruposV11Service,
    private snackvar_service: SnackbarInfoService,
    private servicioAPI: ConectarApiService,
    private globales: GlobalService,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros
  ) { }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    //this.manageForm(this.pkId, this.mode); //
    this.getGrupos();
    //this.getIdiomas(); // 

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  getIdiomas(idiomasgrupo?, mode?) {
    this.servicioAPI.getIdiomas().subscribe(losidiomas => {
      losidiomas.DatosResult.ListaIdiomas.map(idioma => {
        console.log(idioma)
        let idiomaselect = new IdiomaGR();
        idiomaselect.CodIdioma = idioma.CodIdioma;
        idiomaselect.Nombre = idioma.Nombre;
        this.idiomasApp.push(idiomaselect);

        if (idiomasgrupo) {
        let idiomaObj = idiomasgrupo.find(i=>i.CodIdioma == idioma.CodIdioma); 
              

                this.editorListaIdiomas.push(idioma);
                this.editItemsMotivosGroupForm.addControl('nombre' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Nombre:""));
                this.editItemsMotivosGroupForm.addControl('descripcion' + idioma.CodIdioma, new FormControl(idiomaObj?idiomaObj.Descripcion:""));

        }          
        
       
        console.log("Mode", mode);
      });

      this.itemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value
      this.valuedChanged();
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  cambiaNombre(evento, idioma) {

    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Nombre = evento;
    //
  }
  cambiaDescripcion(evento, idioma) {
    this.editorListaIdiomas.find(x => x.CodIdioma == idioma).Descripcion = evento;
    //
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  get formControls() {
    return this.editItemsMotivosGroupForm.controls;
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  submit() {
    /* console.log("-----profile")
    console.log(this.editUserProfileForm.value)
    this.manageApi(user, this.mode)
 */
    let itemMotivosGrupo: ItemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value;
    itemMotivosGrupo.Idiomas = [];
    this.editorListaIdiomas.forEach(i=> {
      let enIdioma:IdiomaGR = new IdiomaGR();
      enIdioma.CodIdioma = i.CodIdioma;
      enIdioma.Nombre = itemMotivosGrupo["nombre"+i.CodIdioma];
      enIdioma.Descripcion = itemMotivosGrupo["descripcion"+i.CodIdioma];
      itemMotivosGrupo.Idiomas.push(enIdioma);
    });

    this.editItemsMotivosGroupForm.markAllAsTouched();
    if (this.editItemsMotivosGroupForm.valid) {
      this.manageApi(itemMotivosGrupo, this.mode)
    }

  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
  }

  discard() {
    this.changeValueForm(this.itemsMotivosGroupProfile);
  }

  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }

  getGrupos() {
    this.itemsMotivosGruposService.getGruposTiposEntrada("").pipe(takeUntil(this.ngUnsubscribe)).subscribe(item => {
      if (item.DatosResult != null) {
        this.grupos = item.DatosResult.customanGrupoTipoEntrada.filter(x => x.ConMotivo.trim().toLowerCase() == 'true' || x.ConMotivo.trim().toLowerCase() == '1');
        
        this.manageForm(this.pkId,this.mode);
        

      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }

  changeValueForm(itemsMotivosGroupProfile: ItemsMotivosGroupProfile) {

    this.formControls["pkId"].setValue(itemsMotivosGroupProfile.pkId);
    this.formControls["Nombre"].setValue(itemsMotivosGroupProfile.Nombre);
    this.formControls["Descripcion"].setValue(itemsMotivosGroupProfile.Descripcion);
    this.formControls["Seleccionable"].setValue(itemsMotivosGroupProfile.Seleccionable == "1" ? true:false);
    this.formControls["GrupoId"].setValue(itemsMotivosGroupProfile.GrupoId);

  }

  initializeFormWithoutData() {
    this.editItemsMotivosGroupForm = this.fb.group({
      pkId: [""],
      Nombre: ["", [Validators.required]],
      Descripcion: [""],
      Seleccionable: [""],
      GrupoId: ["", [Validators.required]]
    });
  }

  manageForm(pkId, mode) {
    console.log(mode)
    if (mode == "I") {
      this.itemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value;
      this.getIdiomas([], this.mode);
      this.loading = false;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.valuedChanged();

    }
    if (pkId != undefined) {
      
      this.serviceCall(pkId);
      
    }

  }

  manageApi(itemsMotivosGrupos: ItemsMotivosGroupProfile, mode) {

    if (this.editItemsMotivosGroupForm.controls["Seleccionable"].value == true)
    itemsMotivosGrupos.Seleccionable = "1"
    else
    itemsMotivosGrupos.Seleccionable = "0"; 

    itemsMotivosGrupos.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);

    this.loading = true;
    //this.insertDataInObj();
    switch (mode) {
      case "I":
        //insert
        console.log("inserS save")
        itemsMotivosGrupos.pkId = "0"
        this.InsItemsMotivo(itemsMotivosGrupos);
        break;
      case "U":
        //update
        //this.getIdiomas([], this.mode);
        this.UpdItemsMotivo(itemsMotivosGrupos);
        break;
      case "D":
        //duplicate
        itemsMotivosGrupos.pkId = "0"
        this.getIdiomas([], this.mode);
        this.InsItemsMotivo(itemsMotivosGrupos);
        break;

      default:
        break;
    }
  }
  UpdItemsMotivo(itemsmotivos){
    this.itemsMotivosGruposService.updItemsMotivo(itemsmotivos, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
     this.nextWindow(response)
    },
    error => { this.httpErrorService.identificarErrores(error); 
    });  
  }
  InsItemsMotivo(itemsmotivos){
    this.itemsMotivosGruposService.insItemsMotivo(itemsmotivos, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error); 
     });   
  }

/*   insUpdItemMotvG(itemsMotivosGrupos: ItemsMotivosGroupProfile){
    console.log("EL valor itemsMotivosGrupos es antes : ", itemsMotivosGrupos);
    itemsMotivosGrupos.Seleccionable = this.globales.aBit(itemsMotivosGrupos.Seleccionable).toString();
    this.itemsMotivosGruposService.insUpdItemsMotivosGrupo(itemsMotivosGrupos).pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response);
    });
  } */

  nextWindow(response: any) {
    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save();
      this.destroy();
      if (response.Mensajes.length > 0) {
        console.table(response.Mensajes);
        response.Mensajes.forEach(element => {
          this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
        });
      } else {
        this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
      }
    }
  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return (fc_pv[field].errors != null || fc_pv[field].invalid) ? true : false;
  }

  existErrors() {
    return (this.formControls.Nombre.errors || this.formControls.Descripcion.errors || this.formControls.Seleccionable.errors || this.formControls.GrupoId.errors) ? true : false;
  }

  getErrorMessage(field: string) {
    let mensaje: string;
    let fc_pv = this.editItemsMotivosGroupForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        mensaje = this.translation["CampoRequerido"];
      } else if (fc_pv.hasError('pattern')) {
        mensaje = this.translation["SoloNumeros"];
      }
    }
    return mensaje;
  }

  valuedChanged() {
    this.editItemsMotivosGroupForm.valueChanges.subscribe(value => {
      console.log(value)
      if (this.itemsMotivosGroupProfile != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.itemsMotivosGroupProfile)) {
          console.log("same")
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }

  serviceCall(pkId) {
    console.log("El pkId que llega a serviceCall es: ", pkId);
    this.itemsMotivosGruposService.findIMGByPkId(pkId).pipe(takeUntil(this.ngUnsubscribe)).subscribe(itemMGprofileApi => {
      console.log("El valor de itemMGprofileApi es : ", itemMGprofileApi);
      let itemsMotivosGroupProfile = itemMGprofileApi["DatosResult"]["customanItemMotGrupo"][0];
      console.log("El itemsMotivosGroupProfile es : ", itemsMotivosGroupProfile);
      if (itemsMotivosGroupProfile != null) {
        this.changeValueForm(itemsMotivosGroupProfile);
        this.itemsMotivosGroupProfile = this.editItemsMotivosGroupForm.value;
        this.itemsMotivosGroupProfile.CentrosIds = itemsMotivosGroupProfile.CentrosIds;
        this.getIdiomas(itemsMotivosGroupProfile.Idiomas, this.mode);
        this.valuedChanged();
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.itemsMotivosGroupProfile);
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
 /*  insertDataInForm() {
    console.log(this.editItemsMotivosGroupForm.value)
    if ((<HTMLInputElement>document.getElementById("GrupoId-hidden")))

      this.editItemsMotivosGroupForm.get("GrupoId").setValue((<HTMLInputElement>document.getElementById("GrupoId-hidden")).value)


  }
  insertDataInObj() {
    this.itemsMotivosGroupProfile.GrupoId = (<HTMLInputElement>document.getElementById("GrupoId-hidden")).value

  } */
  closeInputFilter(enventValues) {
    //this.insertDataInForm()
    this.editItemsMotivosGroupForm.get(enventValues.name).setValue(enventValues.value)

    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)
    console.log(enventValues)

  }

}
