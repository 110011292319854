import { error } from '@angular/compiler/src/util';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { userResponse } from '@sharedV11/classes/userResponse/user-response';
import { AlertService } from '@sharedV11/services/alerts/alert/alert.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { AuthenticationService } from '@sharedV11/services/authentication/authentication.service';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { DialogData } from '@sharedV6/_alerta/popup.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  loginForm!: FormGroup;
  translation: any;
  submitted = false;
  loading = false;
  usuarioenSession: userResponse;
  returnUrl: string;


  errores={
    "0":"EmailNoRegistrado",
    "-1":"MasUsuariosConEsteEmail",
    "-2":"IdentificacionIncorrecta",
    "-5":"NoPermisos"
  }
  Copyright = '';
  constructor(
    private translator: Translator,
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.initializeForm();
    this.initializeTranslate()
  }

  initializeForm() {
    this.loginForm  = new FormGroup({
      'password': new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ]),
      'confirm': new FormControl('', [
        Validators.required,
        Validators.minLength(8),
      ])
    }, (loginForm: FormGroup) => {
      return PasswordValidator.areEqualFields(loginForm);
    });
  }
  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.loginForm.controls;
  }
 
}
export class PasswordValidator {

  static areEqual(formGroup: FormGroup) {
    let value;
    let valid = true;
    for (let key in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty(key)) {
        let control: FormControl = <FormControl>formGroup.controls[key];

        if (value === undefined) {
          value = control.value
        } else {
          if (value !== control.value) {
            valid = false;
            break;
          }
        }
      }
    }

    if (valid) {
      return null;
    }

    return {
      areEqual: true
    };
  }

  static areEqualFields(formGroup: FormGroup) {
    let valid = {};
    console.log(formGroup.get('password').value)
    console.log(formGroup.get('confirm').value)
    console.log("formGroup",formGroup.get('password').value,formGroup.get('confirm').value);
    if (formGroup.get('password').value!==formGroup.get('confirm').value)
      valid = false;

    if (valid) {
      return null;
    }

    return {
      areEqualFields: true
    };
  }

}
