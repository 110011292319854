import { Component, Input, OnInit } from '@angular/core';
import { TipoClientes, tipos_cliente_Respuesta } from '@sharedV11/classes/clients/client/grupos-cliente-Respuesta.model';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { first, takeUntil } from 'rxjs/operators';
import { EditClientGroupService } from '@sharedV11/services/components/edit_client_group/edit-client-group.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-edit-client-type',
  templateUrl: './edit-client-type.component.html',
  styleUrls: ['./edit-client-type.component.scss']
})
export class EditClientTypeComponent implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  translation: any;
  editGroupModelForm: FormGroup;
  tipoCliente: TipoClientes;

  respuestaAPI: tipos_cliente_Respuesta;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };
  
  constructor(private snackvar_service: SnackbarInfoService,
    private editClientService: EditClientGroupService,
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros) { }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();

    this.manageForm(this.pkId, this.mode)
  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithData(tipoClientes: TipoClientes) {

    this.editGroupModelForm = this.fb.group({
      pkId: [tipoClientes.pkId],
      Codigo: [tipoClientes.Codigo],
      Nombre: [tipoClientes.Nombre]
    });
  }

  initializeFormWithoutData() {

    this.editGroupModelForm = this.fb.group({
      pkId: [""],
      Codigo: ["", [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      Nombre: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]]
    });
  }

  changeValueForm(tipoClientes: TipoClientes) {
    this.formControls["pkId"].setValue(tipoClientes.pkId);
    this.formControls["Codigo"].setValue(tipoClientes.Codigo);
    this.formControls["Nombre"].setValue(tipoClientes.Nombre);
  }

  manageForm(pkId, mode) {

    if (mode == "I") {
      this.tipoCliente = this.editGroupModelForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    }
    if (pkId != null) {
      //this.finduser(pkId)
      this.findClientType();
    }
  }

  get formControls() {
    return this.editGroupModelForm.controls;
  }

  submit() {
    let tipoClientes: TipoClientes = this.editGroupModelForm.value;
    //tipoClientes.PkId = this.tipoCliente.PkId;
    this.editGroupModelForm.markAllAsTouched()
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editGroupModelForm.valid) {
      this.manageApi(tipoClientes);
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
    
  }
  save() {
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.tipoCliente)
  }

  manageApi(grupo_cl: TipoClientes) {
    grupo_cl.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    this.loading = true;
    switch (this.mode) {
      case "I":
        console.log("Insert")
        this.InsClienteTipo(grupo_cl);
        //insert
        //this.insUpdUsuario(user)
        break;
      case "U":
        console.log("update")
        //update
        //this.insUpdUsuario(user)
        this.UpdClienteTipo(grupo_cl);
        break;
      case "D":
        //duplicate
        console.log("duplicate")
        this.editGroupModelForm.value.pkId = "0"
        this.InsClienteTipo(grupo_cl);
        break;

      default:
        break;
    }
  }
  
  UpdClienteTipo(clientegrupo){
    this.editClientService.updTiposCliente(clientegrupo, "U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
     this.nextWindow(response)
    },
    error => { this.httpErrorService.identificarErrores(error);
     })   
  }
  InsClienteTipo(clientegrupo){
    this.editClientService.insTipoCliente(clientegrupo, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      this.nextWindow(response)
     },
     error => { this.httpErrorService.identificarErrores(error);
      })  
  }

  nextWindow(response: any) {
    this.save();
    this.destroy();
    if (response.Mensajes.length > 0) {
      response.Mensajes.forEach(element => {
        this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
      });
    } else {
      this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
    }
  }

  notEmptyFields(grupo_cl: TipoClientes): boolean {
    let res = true;
    if (grupo_cl.Nombre.length === 0 || grupo_cl.Codigo.length === 0) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editGroupModelForm.valueChanges.subscribe(value => {
      console.log("El valor de value cuando cambia: ", value)
      if (this.tipoCliente != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.tipoCliente)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }

  findClientType() {
    this.editClientService.findTipoClienteByPkId(this.pkId).subscribe(response => {
      console.log("En findCliente es : ", response);
      let tipoClientes: TipoClientes = response["DatosResult"]["tipoClientes"][0];
      console.log("El objeto grupo clientes en find client Group es: ", tipoClientes)
      if (tipoClientes != null) {
        this.changeValueForm(tipoClientes)
        this.tipoCliente = this.editGroupModelForm.value;
        this.tipoCliente.CentrosIds = tipoClientes.CentrosIds;
        this.valuedChanged();
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.tipoCliente);
        this.loading = false;
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return (fc_pv[field].errors != null) ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editGroupModelForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "Codigo") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  existErrors() {
    return (this.formControls.CodigoGruposClientes.errors || this.formControls.NombreGruposClientes.errors) ? true : false;
  }

}
