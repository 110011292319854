import { Injectable } from '@angular/core';
import { HttpErrorsService } from '../alerts/errors/http-errors.service';
import { ConectarApiService } from '../api/connection/conectar-api.service';


@Injectable({
  providedIn: 'root'
})
export class GlobalServicCentros {
  tipoCentros: any;
  centrosasociados: any;

  constructor(private servicioAPI: ConectarApiService,private httpErrorService: HttpErrorsService) { }

/*     settipoCentros (tc) {
      this.tipoCentros = tc;
      this.centrosasociados = tc;
    }
 */
  async getCentros(){
    if (JSON.parse(localStorage.getItem("CentrosIds")).length>0) 
      {
        this.tipoCentros = JSON.parse(localStorage.getItem("CentrosIds"));
        this.centrosasociados = this.tipoCentros;
      } 
      else {
        await this.servicioAPI.getCentrosAsync().then(data => {
          let CentrosAPI = data;
          this.tipoCentros = [];
          if (CentrosAPI.DatosResult != null) {
            if (CentrosAPI.DatosResult.ListaCentros != null) {
              this.tipoCentros = CentrosAPI.DatosResult.ListaCentros;
              this.centrosasociados = this.tipoCentros;
            }
          }
        },
          error => { this.httpErrorService.identificarErrores(error); }
        );
      }
    }
/*     setcentrosasociados(sa) {
      this.centrosasociados = sa;
    } */

    //centros
    filtrarTiposCentros() {
      // return this.tipoCentros.filter(x => x.Activo == "True");
      let respuesta = [];
      let Centros = []
      if (this.tipoCentros) Centros = this.tipoCentros.filter(x => x.Activo == "True");
      let tmpCentrosAsociados = this.tipoCentros;
      if (Centros != null && Centros.length > 0) {
        respuesta = Centros.filter(function (item) {
          return tmpCentrosAsociados.findIndex(function (itemSub) { return itemSub == item.pkId }) === -1;
        });
      }
      else {
        if (this.tipoCentros) respuesta = this.tipoCentros.filter(x => x.Activo == "True");
      }
      return respuesta;
    }
  
    comparaObjetosSelect(o1: any, o2: any) {
      return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
    }
    asignarTipoCentro(item, evento) {
      this.centrosasociados.push(item.pkId);
    }
    desasignarTipoCentro(item, evento) {
      let arrtemp = [];
      arrtemp = this.centrosasociados.filter(function (x) { return x != item.pkId });
      this.centrosasociados = arrtemp;
  
    }
  
    deasignarTodosCentros() {
      this.centrosasociados = [];
    }
    asignarTodosCentros() {
      this.centrosasociados = this.tipoCentros.filter(x => x.Activo == "True").map(function (x) { return x.pkId });
    }
  
    obtenercentrosAsociadosconOrden(centrosAsignados): string {
      let respuesta: string = '';
      let elemento: string;
      let count = 0;
      if (centrosAsignados) {
  
        centrosAsignados.forEach(centro => {
          //let orden = this.getOrdenRecinto(actividad);
          console.log(centro)
          elemento = centro.pkId + ',';
          // elemento = element + ":" + (this.ordenRecintosAsociados[count] == null ? '0' : this.ordenRecintosAsociados[count]) + ",";
          respuesta = respuesta + elemento;
          count++;
        });
      }
      return respuesta.substring(0, respuesta.length - 1);
    }
  
      daTiposCentros(registroComponente): any[] {
        console.log("--------------------------------------" )
        let respuesta = [];
        if ( this.tipoCentros && this.tipoCentros != null) {
          if (registroComponente && registroComponente.CentrosIds) {
            let arrayCentrosString = registroComponente.CentrosIds.split(",")

  
            let allCentros = this.tipoCentros.filter(x => x.Activo == "True");
    
            let array = arrayCentrosString.map(centropkId => {
              //console.log(recinto)
              let act = allCentros.find(find => find.pkId == centropkId)
              respuesta.push(act)
            })
            console.log("centrosAsignados:: ", this.tipoCentros, arrayCentrosString ,allCentros);
  
          }
        }
        return respuesta;
      }

    //centros

}
