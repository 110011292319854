import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Dialog_Data } from '@sharedV11/classes/dialog/dialog_data';
import moment from 'moment';

@Component({
  selector: 'app-activar-cliente',
  templateUrl: './activar-cliente.component.html',
  styleUrls: ['./activar-cliente.component.css']
})
export class ActivarClienteComponent {

  fecha_baja = moment().format('YYYY-MM-DD');

  constructor(
    public dialogRef: MatDialogRef<ActivarClienteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog_Data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }


}
