import { listLazyRoutes } from '@angular/compiler/src/aot/lazy_routes';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-input-filter',
  templateUrl: './input-filter.component.html',
  styleUrls: ['./input-filter.component.scss']
})
export class InputFilterComponent implements OnInit {

  @Input() formControlText: any;
  @Input() placeHolderTranslation: string;
  @Input() label: string;
  @Input() list: any[]=[];
  // text show in input
  @Input() textShow: any;
  //data input
  @Input() dataInput: any;

  //API data
  @Input() data: any;
  @Input() required: any;
  @Input() disabled: any;
  
  @Output() close = new EventEmitter();
  @Output() selected = new EventEmitter();

  listCopy:any[];
  idList;
  editorRegistroForm: FormGroup;

  intervalId;

  constructor(
    private formBuilder: FormBuilder,
    private _cds: ChangeDetectorRef,


  ) { }
  ngAfterViewInit(){
    if(document.getElementById(this.formControlText)){
      document.getElementById(this.formControlText).setAttribute("list", this.idList)
    }
   // this.addEvent();
    this.showData();
    this.controlErrors()

  }
  ngOnInit(): void {
    this.initlist();
  }

  initlist(list?) {
    if (list) this.list = list;
    if(this.list && this.list.length>0){
      this.listCopy= [...this.list];
    }
    console.log(this.listCopy)
    this.idList = this.formControlText+"-list"
    //document.getElementById(this.formControlText).setAttribute("list", this.idList)
    this.generateForm()
  }

  controlErrors(){
    this.editorRegistroForm.markAllAsTouched();
    this.editorRegistroForm.updateValueAndValidity();
     this._cds.detectChanges()
  }

  showData(){
     console.log("formControlText:",this.formControlText)
    console.log("placeHolderTranslation:",this.placeHolderTranslation)
    console.log("label:",this.label)
    console.log("list:",this.listCopy)
    console.log("textShow:",this.textShow)
    console.log("dataInput:",this.dataInput)
    console.log("data:",this.data)
    console.log("required:",this.required)
    console.log("disabled:",this.disabled)

    console.log(this.formControlText)
    if(this.disabled){
      this.editorRegistroForm.get("answer").disable()

    }
    if(this.data && this.data!=0 && this.listCopy && this.listCopy.length>0){
       
      let value = this.listCopy.find(find=>find[this.dataInput] == this.data);
      if(value){
        value = value[this.textShow]
        //(<HTMLInputElement>document.getElementById(this.formControlText)).value = value
        this.editorRegistroForm.controls["answer"].setValue(value)
      }
      this.editorRegistroForm.controls["answerHidden"].setValue(this.data)
    }
   this._cds.detectChanges();

  }


  generateForm(){
    this.editorRegistroForm = this.formBuilder.group({
      answer:["",this.required=="true"?[Validators.required]:[]],
     // answer:["",[Validators.required]],
      answerHidden:['']
    });
  
  }

  intervaloClose(){
    /* if(this.interval){
      clearInterval(this.interval)
    } */
    let thisobj = this;

    setTimeout(function(){thisobj.closeInput()},1000)
    //clearInterval(this.intervalId)

  }
  closeInput(){
    //clearInterval(this.intervalId)
    console.log("Holaaaaaaaaaa")
    console.log("list: ",this.listCopy)
    
    if(this.listCopy && this.listCopy.length>0){
      let valName= (<HTMLInputElement>document.getElementById(this.formControlText)).value;
      //let val= (<HTMLInputElement>document.getElementById(this.formControlText+"-hidden")).value
      let val= this.editorRegistroForm.get("answerHidden").value
    
      /*   console.log("valName:"+valName)
      console.log("val:"+val)
      console.log("listCopy: ",this.listCopy) */
      console.log(valName.trim().replace("  "," "))
      console.log(val)
      console.log(this.listCopy)
      console.log(this.listCopy.find(find=>find.pkId==206))
      let find = this.listCopy.find(find=> find[this.textShow].trim().replace("  "," ") ==valName.trim().replace("  "," "))
      /*   console.log("findF: ",this.listCopy.find(find=> console.log(find[this.textShow]) ))
      console.log("find: ",find) */
      //console.log(this.listCopy)
      //console.log(find)
      console.log(find)
      if(!find){
        this.editorRegistroForm.controls["answerHidden"].setValue("");
        if(this.editorRegistroForm.controls["answer"].value.trim()!=""){
          this.editorRegistroForm.controls["answer"].setErrors({'incorrect': true});
        }
        //this.editorRegistroForm.controls["answer"].updateValueAndValidity()
        //(<HTMLInputElement>document.getElementById(this.formControlText)).innerHTML=""
      } else{
        let valueFind = find[this.dataInput]
       // console.log(typeof valueFind)
     
        this.editorRegistroForm.get("answerHidden").setValue(valueFind)
       /*  if(this.editorRegistroForm.controls["answer"].value.trim()!=""){
          this.editorRegistroForm.controls["answer"].setErrors(null);
        } */
        this.editorRegistroForm.controls["answer"].setErrors(null);
        //console.log(this.editorRegistroForm.controls["answer"].errors)
        // console.log(val)
        //this.editorRegistroForm.controls["answerHidden"].setValue(val);
      } 
      val= this.editorRegistroForm.get("answerHidden").value
      //console.log(this.editorRegistroForm.controls["answerHidden"].value)
      console.log(this.listCopy)

      this.close.emit({"name":this.formControlText,"value":val})
    }else{
      this.editorRegistroForm.controls["answerHidden"].setValue("");
      this.close.emit({"name":this.formControlText,"value":"-1"})

    }

  }

  addEvent(){
    /* console.log("------addEvent")
    console.log("------document.querySelector('input[listCopy]')",document.querySelectorAll('input[listCopy]')) */
    let thisobj = this;
     document.querySelectorAll('input[list]').forEach(hElement=>{
       hElement.addEventListener('input', function(e) {
        console.log(thisobj.listCopy)

        var input = e.target  as HTMLTextAreaElement,
            listCopy = input.getAttribute('list'),
            options:any = Array.from(document.querySelectorAll('#' + listCopy + ' option')),
            hiddenInput = (<HTMLInputElement>document.getElementById(input.getAttribute('id') + '-hidden')),
            label = input.value;
    
            /* console.log("input: ",input)
            console.log("listCopy: ",listCopy)
            console.log("options: ",options)
            console.log("input: ",input)
            console.log("hiddenInput: ",hiddenInput)
            console.log("label: ",label) */
            
            hiddenInput.nodeValue = label;
            //let find = this.listCopy.find(find=> find[this.textShow] == label)
            let find = thisobj.findOption(label)
/*             console.log(find) */         
           if(find){
              hiddenInput.value = find[thisobj.dataInput]
              thisobj.editorRegistroForm.get("answerHidden").setValue(find[thisobj.dataInput])

    /*           console.log(hiddenInput.value)
              console.log(find[thisobj.dataInput]) */
            }
            console.log(thisobj.listCopy)
           // thisobj.closeInput();
          /* for(var i = 0; i < options.length; i++) {
            var option = options[i];
            option.innerHTML = option.innerHTML.trim()
            if(option.innerHTML == label) {
              hiddenInput.value = option.getAttribute('title');
                break;
            }
        } */
    });
     })
  }
 
  get f() { return this.editorRegistroForm.controls; } 
  
    selectString(str){

    
    if(this.listCopy && this.listCopy.length>0){
      let valName= str;
      let val= this.editorRegistroForm.get("answerHidden").value
    

      let find = this.listCopy.find(find=> find[this.textShow].trim().replace("  "," ") ==valName.trim().replace("  "," "))

      console.log("BBBBB ",find)
      if(!find){
        this.editorRegistroForm.controls["answerHidden"].setValue("");
        if(this.editorRegistroForm.controls["answer"].value.trim()!=""){
          this.editorRegistroForm.controls["answer"].setErrors({'incorrect': true});
        }
        //this.editorRegistroForm.controls["answer"].updateValueAndValidity()
        //(<HTMLInputElement>document.getElementById(this.formControlText)).innerHTML=""
      } else{
        let valueFind = find[this.dataInput]
        //console.log("TTTT", typeof valueFind, valueFind)

        this.editorRegistroForm.controls["answer"].setValue(find[this.textShow])
        this.editorRegistroForm.get("answerHidden").setValue(valueFind)
        
       /*  if(this.editorRegistroForm.controls["answer"].value.trim()!=""){
          this.editorRegistroForm.controls["answer"].setErrors(null);
        } */
        this.editorRegistroForm.controls["answer"].setErrors(null);
        //console.log(this.editorRegistroForm.controls["answer"].errors)
        // console.log(val)
        //this.editorRegistroForm.controls["answerHidden"].setValue(val);
        this.selected.emit(true);
      } 
      val= this.editorRegistroForm.get("answerHidden").value
      //console.log(this.editorRegistroForm.controls["answerHidden"].value)
      //console.log(val,this.listCopy)

      this.close.emit({"name":this.formControlText,"value":val})
    }else{
      this.editorRegistroForm.controls["answerHidden"].setValue("");
      this.close.emit({"name":this.formControlText,"value":"-1"})

    }


  }
 

  findOption(label){
    console.log("listCopy: ",this.listCopy )
    let find = this.listCopy.find(find=> find[this.textShow].trim().replace("  "," ") == label.trim().replace("  "," "))
    
    /* console.log("listOption: '",this.listCopy[0][this.textShow].trim().replace("  "," ")+"'")
    console.log("label: '",label.trim().replace("  "," ")+"'")
    console.log("findOption: ",find) */
    return find
  }


}
