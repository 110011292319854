import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditCodigoPromocionalComponent } from './edit-codigo-promocional/edit-codigo-promocional.component';

@Component({
  selector: 'app-codigo-promocional',
  templateUrl: './codigo-promocional.component.html',
  styleUrls: ['./codigo-promocional.component.scss']
})
export class CodigoPromocionalComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.closepanel();
    //this.customanpanel.cargarDatos();
    //console.log("SSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSSS");
  }

  openPanel() {
    //this.customanpanel=EditClientComponent
    this.customanpanel = EditCodigoPromocionalComponent;
    console.log("abrir panel editar tipo codigo promociones");
    this.editChangeCss()
    this.cdRef.detectChanges();
  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close => {
      console.log()
      this.customanpanel = undefined;
      this.closePanelChangeCss()
    })

  }

  editChangeCss() {
    let classElem;
    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
      classElem[i].style.display = "none"

    }
    /* document.getElementsByTagName("content")[0].classList.remove('mat-drawer-content');
    document.getElementsByTagName("mat-sidenav-content")[0].classList.add('mat-drawer-contentModify'); */
  }
  closePanelChangeCss(){
    /* document.getElementsByTagName("mat-sidenav-content")[0].classList.remove('mat-drawer-contentModify');
    document.getElementsByTagName("mat-sidenav-content")[0].classList.add('mat-drawer-content'); */
    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }
}
