import { Component, OnInit, OnDestroy, ViewChild, DoCheck, Input } from '@angular/core';
import { InfoMapasService } from './servicios/info-mapas.service';
import { EntradasService } from './servicios/entradas.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import * as moment from 'moment'; //VO
import moment from 'moment'; 
import { ButacasService } from './servicios/butacas.service';

@Component({
  selector: 'iacpos-selector-butacas',
  templateUrl: './selector-butacas.component.html',
  styleUrls: ['./selector-butacas.component.css'],
  host: {
    class: 'col-12 col-xs-12 col-sm-12 col-md-12 col-lg-11 a'
  }
})
export class SelectorButacasComponent implements OnInit, OnDestroy, DoCheck {
  public cambiosMapa: Event;
  public cambiosMinimapa: Event;
  public nombreEstadio: string;
  renderMapa = true;
  estadosButacas: any;
  noNumerada = false;
  verMapa = false;
  tiposButacasRecinto = [];
  estadosButacasRecinto = [];
  sliderVisible = false;

  hacerZoom = false;

  datosForm: FormGroup;
  claveEmpresa: string;

  @Input() menucontextual:boolean = false;
  @Input() fecha:string;
  @Input() hora:string;
  @Input() espectaculo: any;
   
  @ViewChild('container', { static: false }) container: any;
  @ViewChild('mapa', { static: false }) mapa: any;
  butacaMarcada = [];

  constructor(
    private formBuilder: FormBuilder,
    private infoMapas: InfoMapasService,
    public entradasService: EntradasService,
    private infoMapasService: InfoMapasService,
    private butacasService: ButacasService
  ) {}

  ngOnInit() {
    this.entradasService.filtroPreparado.subscribe(data => {
      if (data) {
        this.sliderVisible = true;
      }
    });
     var momenthora = moment(this.hora,'HH:mm:ss');
     var momentfecha = moment(this.fecha,'YYYY/MM/DD');
     this.butacasService.setEspectaculoValue(this.espectaculo);
     this.butacasService.setFechaSeleccionadaValue(momentfecha);
     this.butacasService.setHoraSeleccionadaValue( momenthora);
     this.nombreEstadio = this.espectaculo.NombreFicheroSVG.split('.')[0];
    //this.nombreEstadio = this.nombremapa;

    this.entradasService.estadosButacas.subscribe(data => {
      data.map(item => this.estadosButacasRecinto.push(item.Estado));
    });
    this.infoMapasService.getButacas(this.nombreEstadio).subscribe(butacas => {
   
      if (butacas.length === 1) {
        this.noNumerada = true;
        this.verMapa = false;
        this.entradasService.getEstadosButacas(this.infoMapasService.recinto, this.nombreEstadio);
      } else {
        butacas.map(item => {
          if (!this.tiposButacasRecinto.includes(item['data-tipo-butaca'])) {
            this.tiposButacasRecinto.push(item['data-tipo-butaca']);
          }
          
        });
        console.log("Butacas",butacas,this.tiposButacasRecinto );
        this.verMapa = true;
        this.noNumerada = false;
      }
    });
    if (this.container) {
      this.infoMapas.boxWidth = this.container.nativeElement.offsetWidth - 12;
      this.infoMapas.boxHeight = this.container.nativeElement.offsetHeight - 12;
    }
    this.datosForm = this.formBuilder.group({
      numEntradas: [
        '',
        [Validators.required, Validators.maxLength(255), Validators.pattern('^[0-9]*$')]
      ]
    });
    this.entradasService.leyendaSubject.subscribe(data => (this.estadosButacas = data));
    this.claveEmpresa = this.butacasService.getConfig('chClaveEmpresa');

    this.entradasService.butacaMarcada.subscribe((b: any) => {
      this.butacaMarcada = [];

      if (b != undefined && b != null && b.info && b.info.tiposDeEntrada) {
        for (let index = 0; index < b.info.tiposDeEntrada.length; index++) {
          this.butacaMarcada.push(b.info.tiposDeEntrada[index]);
        }
      }
    });



  }

  public onMostrarMarcadasChanged(value: boolean) {

    const listaButacasAnimacion = document.querySelectorAll('circle');

    if (value) {
      for (let iLBA = 0; iLBA < listaButacasAnimacion.length; iLBA++) {
        const elementMapa = listaButacasAnimacion[iLBA];
        if (!elementMapa.classList.contains('marcada')) {
          elementMapa.style.visibility = 'hidden';
        }
      }
    } else {
      for (let iLBA = 0; iLBA < listaButacasAnimacion.length; iLBA++) {
        const elementMapa = listaButacasAnimacion[iLBA];
        if (!elementMapa.classList.contains('marcada')) {
          elementMapa.style.visibility = 'initial';
        }
      }
    }
  }

  preventDefault(e) {
    if (e && e.preventDefault) {
      e.preventDefault(); // TODO : En chrome se lanzara un mensaje por consola debio a que el evento es passive
    }
    e.returnValue = false;
  }

  preventScroll(event) {

    if (window.addEventListener) {
      // older FF
      window.addEventListener('DOMMouseScroll', this.preventDefault, false);
    }

    document.addEventListener('wheel', this.preventDefault, { passive: false }); // Disable scrolling in Chrome

    window.onwheel = this.preventDefault; // modern standard
    // window.onmousewheel = document.onmousewheel = this.preventDefault; // older browsers, IE
    window.ontouchmove = this.preventDefault; // mobile
    // event.preventDefault();
  }

  doScroll(event) {
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
    }
    document.removeEventListener('wheel', this.preventDefault, false); // Enable scrolling in Chrome
    // window.onmousewheel = document.onmousewheel = null;
    window.onwheel = null;
    window.ontouchmove = null;
    document.onkeydown = null;
  }

  ngDoCheck() {
    /*   const cuerpo: any = document.getElementById('scroll');
    if (this.hacerZoom) {
      cuerpo.style.overflow = 'hidden';
    } else {
      cuerpo.style.overflow = 'auto';
    } */
  }

  /* const recinto: any = document.getElementById('recinto');
    const principal: any = document.getElementById('princ');
    if (recinto.scale === 10) {
      principal.style.border = '3px solid green';
    }
    if (recinto.scale === 0.9) {
      principal.style.border = '3px solid green';
    } */

  ngOnDestroy() {
    this.entradasService.setAsientosDeshablitados([]);
  }

  eventMapTransformation(event: Event) {
    this.cambiosMapa = event;

  }

  eventMinimapTransformation(event: Event) {
    this.cambiosMinimapa = event;
  }

  onResize(box) {
    this.infoMapas.boxWidth = box.offsetWidth - 12;
    this.infoMapas.boxHeight = box.offsetHeight - 12;
  }

  masc() {
    this.infoMapas.button('masc');
  }

  arriba() {
    this.infoMapas.button('arriba');
  }

  derecha() {
    this.infoMapas.button('derecha');
  }

  izquierda() {
    this.infoMapas.button('izquierda');
  }

  abajo() {
    this.infoMapas.button('abajo');
  }

  menosc() {
    this.infoMapas.button('menosc');
  }

  recargarMapa() {
    this.mapa.recargarMapa();
  }

  seleccionMultiple(checkbox) {
    if (checkbox.checked) {
      this.entradasService.seleccionMultiple = true;
    } else {
      this.entradasService.seleccionMultiple = false;
    }
  }

  resetButacasSeleccionadas() {
    this.entradasService
      .httpPostMarcarDesmarcarMultiplesButacas(this.entradasService.butacasMarcadasMultiple, '0')
      .subscribe(ans => {
        this.recargarMapa();
      });
  }

  minimapaRender() {
    this.renderMapa = !this.renderMapa;
  }
}
