<div class="component">

    <app-buttons  (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>

    <app-filter [tableName]="'entryType'" ></app-filter>

</div>

<app-table [tableName]="'entryType'" (addbut)="addbut=$event"></app-table>

<app-panelcustoman
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 