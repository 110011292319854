import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfileSaveComponent } from './profile-save/profile-save.component';

@Component({
  selector: 'app-user-profile-v11',
  templateUrl: './user-profile-v11.component.html',
  styleUrls: ['./user-profile-v11.component.scss']
})
export class UserProfileV11Component implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  doc_item: any;

  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,
    private gsCentros: GlobalServicCentros


  ) { }

  ngOnInit(): void {
    this.closepanel();
    this.gsCentros.getCentros();
  }

  openPanel(){
    this.customanpanel=ProfileSaveComponent
    this.editChangeCss();
    this.cdRef.detectChanges();

  }

  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      this.customanpanel = undefined;
      this.closePanelChangeCss();

    })
    
  }


  
  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
  }

  closePanelChangeCss(){

    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }

}
