<app-buttons  (open)="openPanel()" [buttonsWrap]="'add'"  [addbut]="addbut"></app-buttons>


<app-filter [tableName]="'tipossesion'" ></app-filter>

<app-table [tableName]="'tipossesion'" (addbut)="addbut=$event"></app-table>

<app-panelcustoman 
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 