import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { first, takeUntil } from 'rxjs/operators';
import { EditClientGroupService } from '@sharedV11/services/components/edit_client_group/edit-client-group.service';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';
import { DualListComponent } from 'angular-dual-listbox';
import { TipoControl } from '@sharedV11/classes/core/api-maestras.model';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { TableInteractiveComponent } from '@sharedV11/components/tables/table-interactive/table-interactive.component';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';

@Component({
  selector: 'app-edit-type-access-control',
  templateUrl: './edit-type-access-control.component.html',
  styleUrls: ['./edit-type-access-control.component.scss']
})
export class EditAccessControlTypeComponent implements OnInit {

  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("ti_tiposControlCalendario") ti_tiposControlCalendario: TableInteractiveComponent;
  @ViewChild("ti_tiposControlCalendarioHoras") ti_tiposControlCalendarioHoras: TableInteractiveComponent;

  translation: any;
  editGroupModelForm: FormGroup;
  tipoAccesoControl: TipoControl;

  respuestaAPI: Object;

  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  CodigoTipoControl: string;
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };
  
  constructor(private snackvar_service: SnackbarInfoService,
    private conectarAPI: ConectarApiService,
    private editClientService: EditClientGroupService,
    private panelService: PanelService,
    private fb: FormBuilder,
    private translator: Translator,
    private httpErrorService: HttpErrorsService,
    private gsCentros: GlobalServicCentros,
    public globales:GlobalService) { 

  
    }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)
  }


  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeFormWithData(tipoAccesoControls: TipoControl) {

    this.editGroupModelForm = this.fb.group({
      pkId: [tipoAccesoControls.pkId], 
      CodigoTipoControl: [tipoAccesoControls.CodigoTipoControl],
      Descripcion:[tipoAccesoControls.Descripcion],
      Nombre: [tipoAccesoControls.Nombre],
      DiasIntervalo: [tipoAccesoControls.DiasIntervalo],
      DiasIntervaloDesde: [tipoAccesoControls.DiasIntervaloDesde],
      NivelEntrada: [tipoAccesoControls.NivelEntrada],
      NumPasosPrevios: [tipoAccesoControls.NumPasosPrevios],
      NumPasosPreviosDiarios: [tipoAccesoControls.NumPasosPreviosDiarios],
      CentrosIds: [tipoAccesoControls.CentrosIds],
      CentrosNombres: [tipoAccesoControls.CentrosNombres],
      PermisoSinFecha: [tipoAccesoControls.PermisoSinFecha  == "True" ? true : false ],
      AccesoDomingo: [tipoAccesoControls.AccesoDomingo  == "True" ? true : false ],
      AccesoJueves: [tipoAccesoControls.AccesoJueves  == "True" ? true : false ],
      AccesoLunes: [tipoAccesoControls.AccesoLunes  == "True" ? true : false ],
      AccesoMartes: [tipoAccesoControls.AccesoMartes  == "True" ? true : false ],
      AccesoMiercoles: [tipoAccesoControls.AccesoMiercoles  == "True" ? true : false ],
      AccesoSabado: [tipoAccesoControls.AccesoSabado  == "True" ? true : false ],
      AccesoViernes: [tipoAccesoControls.AccesoViernes  == "True" ? true : false ],
      CompCalendHorario: [tipoAccesoControls.CompCalendHorario  == "True" ? true : false ],
      CompCalendario: [tipoAccesoControls.CompCalendario  == "True" ? true : false ],
      CompDiaSemana: [tipoAccesoControls.CompDiaSemana  == "True" ? true : false ],
      CompDiaria: [tipoAccesoControls.CompDiaria  == "True" ? true : false ],
      CompHoraria: [tipoAccesoControls.CompHoraria  == "True" ? true : false ],
      CompIntervalo: [tipoAccesoControls.CompIntervalo  == "True" ? true : false ],
      CompPasoPrevio: [tipoAccesoControls.CompPasoPrevio  == "True" ? true : false ],
      DenegarAcceso: [tipoAccesoControls.DenegarAcceso  == "True" ? true : false ],
      PorDefecto: [tipoAccesoControls.PorDefecto  == "True" ? true : false ]

    

    });
  
  }

  initializeFormWithoutData() {

    this.editGroupModelForm = this.fb.group({
      pkId: [""],
      CodigoTipoControl: ["", [Validators.required, Validators.pattern(/^-?\d{1,9}(\.\d{1,2})?$/)]],
      Nombre: ["", [Validators.required, Validators.pattern(/[A-Za-z]/)]],
      //Nombre:[""],
      Descripcion: [""],
      DiasIntervalo: [""],
      DiasIntervaloDesde:[""],
      NivelEntrada: [""],
      NumPasosPrevios: [""],
      NumPasosPreviosDiarios: [""],
      CentrosIds: [""],
      CentrosNombres: [""],
      PermisoSinFecha:[""],
      AccesoDomingo: [""],
      AccesoJueves: [""],
      AccesoLunes: [""],
      AccesoMartes: [""],
      AccesoMiercoles:[""],
      AccesoSabado: [""],
      AccesoViernes: [""],
      CompCalendHorario: [""],
      CompCalendario: [""],
      CompDiaSemana: [""],
      CompDiaria: [""],
      CompHoraria: [""],
      CompIntervalo: [""],
      CompPasoPrevio:[""],
      DenegarAcceso:[""],
      PorDefecto: [""]


    });
  }

  changeValueForm(tipoAccesoControls: TipoControl) {
    this.formControls["pkId"].setValue(tipoAccesoControls.pkId);
    this.formControls["CodigoTipoControl"].setValue(tipoAccesoControls.CodigoTipoControl);
    this.formControls["Nombre"].setValue(tipoAccesoControls.Nombre);
    //this.formControls["Nombre"].setValue(tipoAccesoControls.Nombre);
    this.formControls["Descripcion"].setValue(tipoAccesoControls.Descripcion);
    this.formControls["DiasIntervalo"].setValue(tipoAccesoControls.DiasIntervalo);
    this.formControls["DiasIntervaloDesde"].setValue(tipoAccesoControls.DiasIntervaloDesde);
    this.formControls["NivelEntrada"].setValue(tipoAccesoControls.NivelEntrada);
    this.formControls["NumPasosPrevios"].setValue(tipoAccesoControls.NumPasosPrevios);
    this.formControls["NumPasosPreviosDiarios"].setValue(tipoAccesoControls.NumPasosPreviosDiarios);
    this.formControls["CentrosIds"].setValue(tipoAccesoControls.CentrosIds);
    this.formControls["CentrosNombres"].setValue(tipoAccesoControls.CentrosNombres);
    this.formControls["PermisoSinFecha"].setValue(tipoAccesoControls.PermisoSinFecha == "True" ? true : false);
    this.formControls["AccesoDomingo"].setValue(tipoAccesoControls.AccesoDomingo == "True" ? true : false);
    this.formControls["AccesoJueves"].setValue(tipoAccesoControls.AccesoJueves == "True" ? true : false);
    this.formControls["AccesoLunes"].setValue(tipoAccesoControls.AccesoLunes == "True" ? true : false);
    this.formControls["AccesoMartes"].setValue(tipoAccesoControls.AccesoMartes == "True" ? true : false);
    this.formControls["AccesoMiercoles"].setValue(tipoAccesoControls.AccesoMiercoles == "True" ? true : false);
    this.formControls["AccesoSabado"].setValue(tipoAccesoControls.AccesoSabado == "True" ? true : false);
    this.formControls["AccesoViernes"].setValue(tipoAccesoControls.AccesoViernes == "True" ? true : false);
    this.formControls["CompCalendHorario"].setValue(tipoAccesoControls.CompCalendHorario == "True" ? true : false);
    this.formControls["CompCalendario"].setValue(tipoAccesoControls.CompCalendario == "True" ? true : false);
    this.formControls["CompDiaSemana"].setValue(tipoAccesoControls.CompDiaSemana == "True" ? true : false);
    this.formControls["CompDiaria"].setValue(tipoAccesoControls.CompDiaria == "True" ? true : false);
    this.formControls["CompHoraria"].setValue(tipoAccesoControls.CompHoraria == "True" ? true : false);
    this.formControls["CompIntervalo"].setValue(tipoAccesoControls.CompIntervalo == "True" ? true : false);
    this.formControls["CompPasoPrevio"].setValue(tipoAccesoControls.CompPasoPrevio == "True" ? true : false);
    this.formControls["DenegarAcceso"].setValue(tipoAccesoControls.DenegarAcceso == "True" ? true : false);
    this.formControls["PorDefecto"].setValue(tipoAccesoControls.PorDefecto == "True" ? true : false);

    if (this.mode=="D") {
      this.formControls["CodigoTipoControl"].setValue("");
      this.formControls["Nombre"].setValue("");
    }

  }

  manageForm(pkId, mode) {

    if (mode == "I") {
      this.tipoAccesoControl = this.editGroupModelForm.value;
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.loading = false;
      this.valuedChanged();
    };

    if (pkId != null) {
      //this.finduser(pkId)
      this.findTipoControl();
    }
  }

  get formControls() {
    return this.editGroupModelForm.controls;
  }

  submit(cerrarpanel:boolean = true) {
    let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    this.editGroupModelForm.markAllAsTouched()
    if (this.editGroupModelForm.valid) {

      
      this.manageApi(tipoAccesoControls,cerrarpanel);


      /* if (this.mode!="D") { */
        if (this.formControls["CompCalendario"].value) {
          console.log("Hay calendario!!!");
          this.submitCal(false);
        }
        else { 
          console.log("Borrar calendario!!!",this.ti_tiposControlCalendario,this.ti_tiposControlCalendario.dataSource);
          if (this.ti_tiposControlCalendario && this.ti_tiposControlCalendario.dataSource) 
           {
            this.borrarCalendarios(this.ti_tiposControlCalendario.dataSource.data);
           } 
        }
    
        if (this.formControls["CompCalendHorario"].value) {
          console.log("Hay calendario Horas!!!");
          this.submitCalHoras(false);
        }
        else { 
          if (this.ti_tiposControlCalendarioHoras && this.ti_tiposControlCalendarioHoras.dataSource) 
           {
            this.borrarCalendarios(this.ti_tiposControlCalendarioHoras.dataSource.data);
           }
        }

      //}


    }
  }

  borrarCalendarios(calaendarios) {
  /*   if (calaendarios.length>0  ) { */

      calaendarios.forEach(async ti_ele => {


        let peticion = {};
        peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
        peticion["CodigoTipoControl"] = ti_ele.CodigoTipoControl;

        await this.conectarAPI.delCalendariosTiposControlAsync(peticion["pkId"] ,peticion["CodigoTipoControl"]).then(delcal => {
          if (delcal.Mensajes!=null)  
            this.snackvar_service.openSnackBar(delcal.Mensajes[0].DescripcionMensaje,"",'red-snackbar');
      
        }); 
        
      })
/*     } */
  }

  submitCal(cerrarpanel:boolean = true) {
    let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    //tipoAccesoControls.PkId = this.tipoAccesoControl.PkId;
    //this.editGroupModelForm.markAllAsTouched()
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editGroupModelForm.valid) {
      this.manageApi(tipoAccesoControls,false)
    }  
    if (this.ti_tiposControlCalendario.dataSource.data.length>0 || this.ti_tiposControlCalendario.dataSource.data.length>0  ) {
      this.ti_tiposControlCalendario.dataSource.data.forEach(ti_ele => {

        console.log("ti_ele.FechaAcceso::", ti_ele.FechaAcceso.value.format("YYYY-MM-DD"),this.ti_tiposControlCalendario.dataSource.data);

/*      let date_FechaAcceso = this.globales.aFecha(ti_ele.FechaAcceso);
        let date_FechaAccesoFin = this.globales.aFecha(ti_ele.FechaAccesoFin); */
  
        let date_FechaAccesoStr = ti_ele.FechaAcceso.value.format("YYYY-MM-DD");
        let date_FechaAccesoFinStr = ti_ele.FechaAccesoFin.value.format("YYYY-MM-DD");
  
/*         let date_FechaAccesoStrlocal = ti_ele.FechaAcceso.value.format("YYYY-MM-DD");
        let date_FechaAccesoFinStrlocal = ti_ele.FechaAcceso.value.format("YYYY-MM-DD"); */


        let peticion = {};
        peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
        peticion["TipoControlId"] = ti_ele.TipoControlId ? ti_ele.TipoControlId : this.tipoAccesoControl.pkId ;
        peticion["CodigoTipoControl"] = tipoAccesoControls.CodigoTipoControl;
        peticion["NombreTipoControl"] = tipoAccesoControls.Nombre;
        peticion["FechaAcceso"] = date_FechaAccesoStr+" 00:00:00";
        peticion["FechaAccesoFin"] = date_FechaAccesoFinStr+" 00:00:00";

        if (ti_ele.updated) {
        this.conectarAPI.insUpdCalendariosTiposControlAsync(peticion["pkId"], peticion["TipoControlId"],peticion["CodigoTipoControl"],peticion["NombreTipoControl"],peticion["FechaAcceso"],peticion["FechaAccesoFin"]).then(insup => {
          if (insup.Mensajes!=null && insup.Mensajes.length>0)  
            this.snackvar_service.openSnackBar(insup.Mensajes[0].DescripcionMensaje,"",'red-snackbar');
        });  
        }
      }); 
      this.ti_tiposControlCalendario.reloadTable();
/*       this.editGroupModelForm.markAllAsTouched();
      let tipoCodigoPromociones: TipoControl = this.editGroupModelForm.value;
      if (this.editGroupModelForm.valid) {
        this.manageApi(tipoAccesoControls,cerrarpanel)
      }  */
    } else {
      this.snackvar_service.openSnackBar("No hay datos que guardar","",'red-snackbar');
    } 
  }

  submitCalHoras(cerrarpanel:boolean = true) {
    let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
    //tipoAccesoControls.PkId = this.tipoAccesoControl.PkId;
    //his.editGroupModelForm.markAllAsTouched()
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editGroupModelForm.valid) {
      this.manageApi(tipoAccesoControls,false)
    }  
    if (this.ti_tiposControlCalendarioHoras.dataSource.data.length>0 || this.ti_tiposControlCalendarioHoras.dataSource.data.length>0  ) {
      console.log("this.ti_tiposControlCalendarioHoras.dataSource.data",this.ti_tiposControlCalendarioHoras.dataSource.data); 
      this.ti_tiposControlCalendarioHoras.dataSource.data.forEach(ti_ele => {

        let tmp1 = ti_ele.FechaAcceso.split(":");
        if (tmp1.length==2) ti_ele.FechaAcceso +=":00";

        let tmp2 = ti_ele.FechaAccesoFin.split(":");
        if (tmp2.length==2) ti_ele.FechaAccesoFin +=":00";

        let peticion = {};
        peticion["pkId"] = ti_ele.pkId ? ti_ele.pkId : 0;
        peticion["TipoControlId"] = ti_ele.TipoControlId ? ti_ele.TipoControlId : this.tipoAccesoControl.pkId ;
        peticion["CodigoTipoControl"] = tipoAccesoControls.CodigoTipoControl;
        peticion["NombreTipoControl"] = tipoAccesoControls.Nombre;
        peticion["FechaAcceso"] = "1900-01-01 "+ti_ele.FechaAcceso;
        peticion["FechaAccesoFin"] = "1900-01-01 "+ti_ele.FechaAccesoFin;

      if (ti_ele.updated) {
        this.conectarAPI.insUpdCalendariosTiposControlAsync(peticion["pkId"], peticion["TipoControlId"],peticion["CodigoTipoControl"],peticion["NombreTipoControl"],peticion["FechaAcceso"],peticion["FechaAccesoFin"]).then(insup => {
          if (insup.Mensajes!=null && insup.Mensajes.length>0)  
            this.snackvar_service.openSnackBar(insup.Mensajes[0].DescripcionMensaje,"",'red-snackbar');
        });  
        }
      }); 
      this.ti_tiposControlCalendario.reloadTable();
/*       this.editGroupModelForm.markAllAsTouched();
      let tipoCodigoPromociones: TipoControl = this.editGroupModelForm.value;
      if (this.editGroupModelForm.valid) {
        this.manageApi(tipoAccesoControls,cerrarpanel)
      }  */
    } else {
      this.snackvar_service.openSnackBar("No hay datos que guardar","",'red-snackbar');
    } 
  }


  destroy() {
    this.panelService.setDiscardChanges(true)
    this.panelService.setClosePanel(null);
    
  }
  save(cerrarpanel?) {
    this.loading = false;
    this.panelService.setDiscardChanges(true);

    this.panelService.setSavePanel(cerrarpanel);
  }


  discard() {
    this.changeValueForm(this.tipoAccesoControl)
  }

  manageApi(grupo_cl: TipoControl, cerrarpanel?) {
    grupo_cl.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);
    //grupo_cl.Nombre = grupo_cl.NombreTipoControl;
    this.loading = true;
    switch (this.mode) {
      case "I":
        console.log("Insert")
        this.InsTiposControl(grupo_cl,cerrarpanel);
        //insert
        //this.insUpdUsuario(user)
        break;
      case "U":
        console.log("update")
        //update
        //this.insUpdUsuario(user)
        this.UpdTiposControl(grupo_cl,cerrarpanel);
        break;
      case "D":
        //duplicate
        console.log("duplicate")
        this.editGroupModelForm.value.pkId = "0";
        this.InsTiposControl(grupo_cl,cerrarpanel);
        break;

      default:
        break;
    }
  }
  
  async UpdTiposControl(clientegrupo, cerrarpanel?){ 
    await this.editClientService.updTipoControlAsync(clientegrupo, "U").then(response => {
     this.nextWindow(response, cerrarpanel)
     //console.log("RES", response);
    },
    error => { this.httpErrorService.identificarErrores(error);
     })   
  }
  
  async InsTiposControl(clientegrupo, cerrarpanel?){
     await this.editClientService.insTipoControlAsync(clientegrupo, "I").then(response => {

      if (!cerrarpanel) {
        //this.findTipoCodigoPromocional(response.DatosResult.id);
        this.mode = "U";
        if (this.pkId = response.DatosResult) {
          this.pkId = response.DatosResult.id;
          //this.cdRef.detectChanges();
          this.ti_tiposControlCalendario.reloadTable();
          this.ti_tiposControlCalendarioHoras.reloadTable();
          //this.entryType.CentrosIds = this.obtenercentrosAsociadosconOrden()
          this.initializeTranslate();
          this.initializeFormWithoutData();
          this.manageForm(this.pkId, this.mode);
          this.translation = this.translator.GetTranslations();
          this.findTipoControl();
          this.gsCentros.getCentros();
        }
      } else {
        this.nextWindow(response, cerrarpanel)
      }

     },
     error => { this.httpErrorService.identificarErrores(error);
      })  
  }

  nextWindow(response: any,  cerrarpanel?) {


    const err = this.existErrors();
    if (!this.existErrors()) {
      this.save(cerrarpanel);
      if (cerrarpanel && cerrarpanel==true) this.destroy();
      if (response.Mensajes.length > 0) {
        response.Mensajes.forEach(element => {
          this.snackvar_service.openSnackBar(element.DescripcionMensaje, "", 'red-snackbar',element.CodigoMensaje);
        });
      } else {
        this.snackvar_service.openSnackBar(this.translation["MensajeSuccessSnackBar"], "", 'green-snackbar');
      }
    }
  }

  notEmptyFields(grupo_cl: TipoControl): boolean {
    let res = true;
    if (grupo_cl.Nombre.length === 0 || grupo_cl.CodigoTipoControl.length === 0) {
      res = false;
    }
    return res;
  }

  valuedChanged() {
    this.editGroupModelForm.valueChanges.subscribe(value => {
      console.log("El valor de value cuando cambia: ", value)
      if (this.tipoAccesoControl != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.tipoAccesoControl)) {
          this.panelService.setDiscardChanges(true)
        }
      }
    })
  }

  findTipoControl() {
    this.editClientService.findTiposControlByPkId(this.pkId).subscribe(response => {
      console.log("En findCliente es : ", response);
      if (response["DatosResult"]) {
      let tipoAccesosControl: TipoControl = response["DatosResult"]["customanTiposControl"][0];
        console.log("El objeto grupo clientes en find client Group es: ", tipoAccesosControl)
        if (tipoAccesosControl != null) {
          this.CodigoTipoControl = tipoAccesosControl.CodigoTipoControl;
          this.changeValueForm(tipoAccesosControl)
          this.tipoAccesoControl = this.editGroupModelForm.value;
          this.tipoAccesoControl.CentrosIds = tipoAccesosControl.CentrosIds;
          this.valuedChanged();
          this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
          this.centrosAsignados = this.gsCentros.daTiposCentros(this.tipoAccesoControl);
          this.loading = false;
        }
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    );
  }
  

  actualizarCodigoenTabla(ev) {
    if (this.mode=="D") {
      let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
      this.ti_tiposControlCalendario.dataSource.data.forEach((tiele,i) => {
        this.ti_tiposControlCalendario.dataSource.data[i].TipoControlId = tipoAccesoControls.CodigoTipoControl.toString();
        this.ti_tiposControlCalendario.dataSource.data[i].updated = true;
      });
      this.ti_tiposControlCalendarioHoras.dataSource.data.forEach((tiele,i) => {
        this.ti_tiposControlCalendarioHoras.dataSource.data[i].TipoControlId = tipoAccesoControls.CodigoTipoControl.toString();
        this.ti_tiposControlCalendarioHoras.dataSource.data[i].updated = true;
      });
    }
    console.log(" this.ti_tiposControlCalendario.dataSource.data2",  this.ti_tiposControlCalendario.dataSource.data);
    console.log(" this.ti_tiposControlCalendario.dataSource.data2",  this.ti_tiposControlCalendarioHoras.dataSource.data);
  }


  tableloaded(tabla) {
    
    if (this.mode=="D") {
      let tipoAccesoControls: TipoControl = this.editGroupModelForm.value;
      tabla.dataSource.data.forEach((tiele,i) => {
        tabla.dataSource.data[i].pkId = "0";
        console.log( tabla.dataSource.data[i]);
        tabla.dataSource.data[i].TipoControlId = tipoAccesoControls.CodigoTipoControl;
        tabla.dataSource.data[i].updated = true;
      })

      console.log(" this.ti_tiposControlCalendario.dataSource.data1",  this.ti_tiposControlCalendario.dataSource.data, tipoAccesoControls);

/*       this.ti_tiposControlCalendarioHoras.dataSource.data.forEach((tiele,i) => {
        this.ti_tiposControlCalendarioHoras.dataSource.data[i].pkId = 0;
        console.log( this.ti_tiposControlCalendarioHoras.dataSource.data[i]);
        this.ti_tiposControlCalendarioHoras.dataSource.data[i].TipoControlId = this.tipoAccesoControl.CodigoTipoControl;
        this.ti_tiposControlCalendarioHoras.dataSource.data[i].updated = true;
      }) */
    }

  }

  validateFields(field: string) {
    return this.isValidAnyField(field);
  }

  isValidAnyField(field: string) {
    let fc_pv = this.formControls;
    return (fc_pv[field].errors != null) ? true : false;
  }

  getErrorMessage(field: string) {
    let fc_pv = this.editGroupModelForm.get(field);
    if (fc_pv.errors) {
      if (fc_pv.errors.required) {
        return this.translation["CampoRequerido"];
      } else if (field === "CodigoTipoControl") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloNumeros"];
        }
      } else if (field === "Nombre") {
        if (fc_pv.hasError('pattern')) {
          return this.translation["SoloLetras"];
        }
      }
    }
    return "Error";
  }

  
  existErrors() {
    return (this.formControls.CodigoTipoControl.errors || this.formControls.Nombre.errors) ? true : false;
  }

}
