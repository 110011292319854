<!-- <div class="menu-acciones-metodo">
    <mat-spinner diameter="25" style="float:left;" *ngIf="loading"></mat-spinner>
    <button class="btn btn-primary btn-magento-atras" (click)="goBack()">
      <i class="fa fa-arrow-left fa-lg"></i>&nbsp;&nbsp;Atrás
    </button>
  </div> -->
<!--   <h2>Sesión detalle - Butacas</h2>
  <button class="btn btn-success btn-magento-gris" (click)="mostrarEditarSesion()">Editar Sesión</button> -->
  <div>
    <div class="editor-registro" *ngIf="editorButacasForm">
  
        <div class="group-block">
          <div class="form-block" id="container">
            
            <section id="subdiv">
            <div *ngIf="contextual" class="d-flex justify-content-right align-items-center" id="contextualzone">
              <div *ngIf="testing">
                <p id="clicktime" style="position:absolute; margin-left:20px; pointer-events: none;">Click Time</p>
                <p id="attributestext" style="position:absolute; margin-left:300px; pointer-events: none;">Attributes</p>
              </div>
                <div class="btn-map2" style="cursor: pointer;" >
                  <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                      <mat-button-toggle value="red" id="popuprender" (click)="drawer.toggle()">              
                        <mat-icon>info</mat-icon>
                      </mat-button-toggle>
                      <mat-button-toggle value="red" id="popuprender" [matMenuTriggerFor]="menu">              
                        <mat-icon>menu</mat-icon>
                      </mat-button-toggle>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [matMenuTriggerFor]="estados">
                            <mat-icon>dialpad</mat-icon>
                            <span>Modificar Selección</span>
                          </button>
                          <button mat-menu-item (click)="borrarSeleccion()">
                            <mat-icon>delete</mat-icon>
                            <span>Borrar Selección</span>
                          </button>
                        </mat-menu>
                        <mat-menu #estados="matMenu">
                          <button [matMenuTriggerFor]="tiposbutacas"  [matMenuTriggerData]="{IdEstado:item.Id,Nombre:item.Nombre}" mat-menu-item *ngFor="let item of listaEstadosButacas">{{item.Nombre}}</button>
                        </mat-menu>
                        <mat-menu #tiposbutacas="matMenu">
                          <ng-template matMenuContent let-IdEstado="IdEstado" let-EstadoNombre="Nombre">
                            <button mat-menu-item *ngFor="let item of listaTiposButacas" (click)="guardarButacasSinNumerarMenuTipo(IdEstado,item.Id)">{{EstadoNombre}} -> {{item.Nombre}}</button>
                          </ng-template>
                        </mat-menu>
                      <mat-button-toggle value="red" id="popuprendermenos" >              
                        <mat-icon>zoom_out</mat-icon>
                      </mat-button-toggle>
                      <mat-button-toggle value="green"  id="popuprendermas">
                        <mat-icon>zoom_in</mat-icon>  
                      </mat-button-toggle>
                   </mat-button-toggle-group>
                </div>
              </div>

              <mat-drawer #drawer class="example-sidenav" mode="side" style="opacity: 0.85;height: fit-content;">
                <!-- <div class="">Leyenda</div> -->
                <mat-icon (click)="drawer.toggle()" style="width: 100%;text-align: end;cursor: pointer;">clear</mat-icon>
                <div class="butacas">
                  <div class="tipo-butaca" *ngFor="let item of getEstadosButacas">
                    <div class="cuadro-tipo" [style.background]="item.Color"></div>
                    <div class="text-tipoButaca">{{item.Descripcion}} / {{traerEstado(item.EstadoButaca)}}</div>
                  </div>
                  <div class="tipo-butaca">
                    <div class="cuadro-tipo color-seleccionada"></div>
                    <div class="text-tipoButaca">Seleccionada</div>
                  </div>
                </div>
              </mat-drawer>

            <svg id="mapaButacas"></svg>
            </section>
          </div>
          <div class="form-block">

<!--             <div *ngIf="numeradas">
                <div class="hline-bottom">Editar Butacas</div>
                <form [formGroup]="editorButacasForm">
                  <div class="field-container">
                    <div class="cell-container">
                      <div class="grupo-editor-campo-registro middle">
                        <label>Estado</label>
                        <select formControlName="EditarEstado" class="editor-campo-registro form-control">
                          <option *ngFor="let item of listaEstadosButacas" value="{{item.Id}}">{{item.Nombre}}</option>
                        </select>
                      </div>
                      <div class="grupo-editor-campo-registro middle">
                        <label>Tipo</label>
                        <select formControlName="EditarTipo" class="editor-campo-registro form-control">
                          <option *ngFor="let item of listaTiposButacas" value="{{item.Id}}">{{item.Nombre}}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-success btn-magento-gris" style="margin: 6%"
                    (click)="guardarButacas()">Guardar</button>
                </form>
            </div> -->
            
<!--             <div class="group-block" *ngIf="nonumeradas">
                <form [formGroup]="editorButacasSinNumerarForm" >
                      <div class="field-container" *ngIf="divTiposButaca.includes('VREDUCIDA')">
                          <div class="hline-bottom">VREDUCIDA | <input type="text" class="btn-contador" formControlName="lblVREDUCIDA"></div>
                          <div class="grupo-editor-campo-registro middle">
                              <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('VREDUCIDA')">-</div>
                              <input type="text" formControlName="cantidadVREDUCIDA" class="form-control" (change)="onChange('VREDUCIDA', $event)">
                              <div class="btn-mas" style="cursor: pointer;" (click)="agregar('VREDUCIDA')">+</div>
                          </div>
                    </div>
                    <div class="field-container" *ngIf="divTiposButaca.includes('NORMAL')">
                        <div class="hline-bottom">NORMAL | <input type="text" class="btn-contador" formControlName="lblNORMAL"></div>
                        <div class="grupo-editor-campo-registro middle">
                            <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('NORMAL')">-  </div>
                            <input type="text" formControlName="cantidadNORMAL" class="form-control" (change)="onChange('NORMAL', $event)">
                            <div class="btn-mas" style="cursor: pointer;" (click)="agregar('NORMAL')">  +</div>
                        </div>
                  </div>
                  <div class="field-container" *ngIf="divTiposButaca.includes('MINUSVALIDO')">
                      <div class="hline-bottom">MINUSVALIDO | <input type="text" class="btn-contador" formControlName="lblMINUSVALIDO"></div>
                      <div class="grupo-editor-campo-registro middle">
                          <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('MINUSVALIDO')">-</div>
                          <input type="text" formControlName="cantidadMINUSVALIDO" class="form-control" (change)="onChange('MINUSVALIDO', $event)">
                          <div class="btn-mas" style="cursor: pointer;" (click)="agregar('MINUSVALIDO')">+</div>
                      </div>
                </div>
                <div class="field-container" *ngIf="divTiposButaca.includes('VIP')">
                    <div class="hline-bottom">VIP | <input type="text" class="btn-contador" formControlName="lblVIP"></div>
                    <div class="grupo-editor-campo-registro middle">
                        <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('VIP')">-</div>
                        <input type="text" formControlName="cantidadVIP" class="form-control" (change)="onChange('VIP', $event)">
                        <div class="btn-mas" style="cursor: pointer;" (click)="agregar('VIP')">+</div>
                    </div>
                </div>
                <div class="field-container" *ngIf="divTiposButaca.includes('BLOQUEOS')">
                    <div class="hline-bottom">BLOQUEOS | <input type="text" class="btn-contador" formControlName="lblBLOQUEOS"></div>
                    <div class="grupo-editor-campo-registro middle">
                        <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('BLOQUEOS')">-</div>
                        <input type="text" formControlName="cantidadBLOQUEOS" class="form-control" (change)="onChange('BLOQUEOS', $event)">
                        <div class="btn-mas" style="cursor: pointer;" (click)="agregar('BLOQUEOS')">+</div>
                    </div>
                </div>
                <div class="field-container" *ngIf="divTiposButaca.includes('RESERVA')">
                    <div class="hline-bottom">RESERVA | <input type="text" class="btn-contador" formControlName="lblRESERVA"></div>
                    <div class="grupo-editor-campo-registro middle">
                        <div class="btn-mas btn-menos" style="cursor: pointer;" (click)="eliminar('RESERVA')">-</div>
                        <input type="text" formControlName="cantidadRESERVA" class="form-control" (change)="onChange('RESERVA', $event)">
                        <div class="btn-mas" style="cursor: pointer;" (click)="agregar('RESERVA')">+</div>
                    </div>
                </div>
                <div class="hline-top">
                  <div class="grupo-editor-campo-registro middle">
                    <label>Estado</label>
                    <select formControlName="EditarEstado" class="editor-campo-registro form-control" >
                      <option *ngFor="let item of listaEstadosButacas" value="{{item.Id}}">{{item.Nombre}}</option>
                    </select>
                  </div>
                  <div class="grupo-editor-campo-registro middle">
                    <label>Tipo</label>
                    <select formControlName="EditarTipo" class="editor-campo-registro form-control" >
                      <option *ngFor="let item of listaTiposButacas" value="{{item.Id}}">{{item.Nombre}}</option>
                    </select>
                  </div>
                </div>
                  <button class="btn btn-success btn-magento-gris" style="margin: 6%"
                    (click)="guardarButacasSinNumerar()">Guardar</button>
                </form>
            </div>
            <div class="hline-bottom">Controles Mapa</div>
            <button class="btn btn-success btn-magento-gris" style="margin: 5%" (click)="borrarSeleccion()">Borrar
              Selección</button>


            <div class="">Leyenda</div>
            <div class="butacas" [formGroup]="estadosLeyenda">
              <div class="tipo-butaca" *ngFor="let item of getEstadosButacas">
                <div class="cuadro-tipo" [style.background]="item.Color"></div>
                <div class="text-tipoButaca">{{item.Descripcion}} / {{traerEstado(item.EstadoButaca)}}</div>
              </div>
              <div class="tipo-butaca">
                <div class="cuadro-tipo color-seleccionada"></div>
                <div class="text-tipoButaca">Seleccionada</div>
              </div>
            </div> -->
          </div>
        </div>
   
    </div>
  </div>
  
  