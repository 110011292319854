import { Component, Inject, OnInit, HostBinding, ViewChild, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator,MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { Idiomas_Respuesta, Idiomas } from '../../_modelos/Idiomas-Respuesta.model';
import { first } from 'rxjs/operators';
import * as moment from 'moment';

import * as tipoAforo from './tipoAforo.json';
import { FormControl, FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn, MaxLengthValidator } from '@angular/forms';

import { TipoSesion_Respuesta, TipoSesion, DatosResult_TipoSesion } from '../../_modelos/tipos-sesion-Respuesta.model';
import { TiposControl_Respuesta, TipoControl } from '../../_modelos/tipos-control-Respuesta.model';

import { ConectarAPIService } from '../../_API/conectar-api.service';
//import {AlertaService} from '../../_otrosproveedores/alerta.service';
import { AlertaService } from '../../shared/_alerta/alerta.service';
import { AutentificacionService } from '../../_otrosproveedores/autentificacion.service';
import { Router, ActivatedRoute } from '@angular/router';
//import { DialogoPopUp } from '../../_otrosproveedores/popup.service';
import { DialogoPopUp } from '../../shared/_alerta/popup.service';
import { Globales } from '@clasesV6/globales';
import {
  CalendariosTiposControl_Respuesta,
  CalendariosTipoControl,
  DatosResult_CalendariosTiposControl
} from '@modelosV6/clendarios-tipos-control-Respuesta.model';
import { Recintos, Recintos_Respuesta } from '@modelosV6/recintos-Respuesta.model';
import { NativeDateAdapter, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_FORMATOS_FECHA } from '../../_otrosproveedores/date-format.adapter';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { Translator } from 'src/app/shared/services/translator/translator.service';


export interface DialogData {
  titulo: string;
  texto: string;
  eleccion: string;
  ok: string;
  ko: string;
}

export interface AccionesDialogo {
  accion: string;
  texto: string;
  funcion: string;
}

export class traspasomodelo {
  pkId: string;
  Descripcion: string;
  chTipoSesion: string;
  Recinto: string;
  FechaInicio: string;
  FechaFin: string;
  HoraInicio: string;
  HoraFin: string;
  Intervalo: string;
  Lunes: boolean;
  Martes: boolean;
  Miercoles: boolean;
  Jueves: boolean;
  Viernes: boolean;
  Sabado: boolean;
  Domingo: boolean;
  Evento: boolean;
  TipoButaca: string;
  AforoMaximo: string;
  MinimoAforo: string;
  MaximoInternet: string;
  Butacas: boolean;
  Duracion: string;
  EspectaculoId: string;
  EnvioExternos: string;
  NumeroGrupos: string;
  deserialize: null;
}

export class filtrosActivos {
  Nombre: string;
  CodigoTipoRecinto: string;
  desde: string;
  hasta: string;
  lunes: string;
  martes: string;
  miercoles: string;
  jueves: string;
  viernes: string;
  sabado: string;
  domingo: string;
  evento: string;
  textoGeneral: string;
}

export class intervalosAforo {
  id: number;
  texto: string;
  minutos: number;
}

@Component({
  selector: 'app-tipos-sesion',
  templateUrl: './tipos-sesion.component.html',
  styleUrls: ['./tipos-sesion.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA,
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})
export class TiposSesionComponent implements OnInit {
  displayedColumns: string[] = ['Acciones', 'Recinto', 'Nombre', 'Desde', 'Hasta', 'Irregular', 'Aforo',
    'Intervalo', 'Duracion', 'Semana'];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  displayedFilters: string[] = ['NombreTipoSesion', 'Lunes', 'Martes', 'Recinto', 'Miercoles', 'Jueves', 'FechaInicio', 'Viernes', 'Sabado', 'Domingo', 'EventoEspecial'];
  filtersToDisplay: string[] = this.displayedFilters.slice();

  globals: Globales = new Globales();

  tiposIdiomasAPI: Idiomas_Respuesta;
  tiposIdiomas: Array<Idiomas>;

  controlaaforo = false;
  controlaaforoonline = false;
  CodIdioma = '';

  dataSource: MatTableDataSource<TipoSesion>;
  seleccion = new SelectionModel<TipoSesion>(true, []);
  //
  @ViewChild('paginatorPrincipal') paginator: MatPaginator;
  @ViewChild('sortPrincipal') sort: MatSort;
  //
  @ViewChild('paginatorCalendario') paginatorCalendario: MatPaginator;
  @ViewChild('sortCalendario') sortCalendario: MatSort;
  @ViewChild('paginatorCalendarioHorario') paginatorCalendarioHorario: MatPaginator;
  @ViewChild('sortCalendarioHorario') sortCalendarioHorario: MatSort;

  respuestaAPI: TipoSesion_Respuesta;
  respuestaAPICalendario: CalendariosTiposControl_Respuesta;
  //--
  respuestaAPIRecintos: Recintos_Respuesta;
  tiposRecintos: Array<Recintos>;
  //--
  editando: boolean = false;
  editandoCalendarios: boolean = false;
  objedicionCalendario: CalendariosTipoControl = new CalendariosTipoControl;
  editandoCalendariosHorario: boolean = false;
  objedicionCalendarioHorario: CalendariosTipoControl = new CalendariosTipoControl;
  leyendo: boolean = true;
  mensaje_dialogo: string;
  eleccion_dialogo: string;
  titulo_dialogo: string;
  ok_dialogo: string;
  ko_dialogo: string;
  editorRegistroForm: FormGroup;
  editorFiltrosForm: FormGroup;
  permisoFormulario: boolean = true;
  permisobtnNuevo: boolean = false;
  permisobtnGuardar: boolean = false;
  permisobtnEliminar: boolean = false;
  permisobtnDuplicar: boolean = false;
  Acciones: Array<AccionesDialogo>;
  // recinto = new FormControl('');
  // tipoRecinto = new FormControl('');
  accionselecionada: string = null;
  panelFiltrosvisible: boolean = false;
  panelColumnasvisible: boolean = false;
  filtrosactivosModelo: filtrosActivos = new filtrosActivos;
  filtrosactivos: filtrosActivos = Object.assign({}, this.filtrosactivosModelo);
  fecha_minDesde = new Date();
  fecha_minHasta = new Date();
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  arr_dias_semana_seleccionadas: Array<any> = [];
  arr_intervalos_aforo: Array<intervalosAforo> = [{ id: 0, texto: 'SinIntervalo', minutos: 0 }, {
    id: 1,
    texto: '5 minutos',
    minutos: 5
  }, { id: 2, texto: '10 minutos', minutos: 10 }
    , { id: 3, texto: '15 minutos', minutos: 15 }, { id: 4, texto: '20 minutos', minutos: 20 }, {
    id: 5,
    texto: '25 minutos',
    minutos: 25
  }, { id: 6, texto: '30 minutos', minutos: 30 }
    , { id: 7, texto: '35 minutos', minutos: 35 }, { id: 8, texto: '40 minutos', minutos: 40 }, {
    id: 5,
    texto: '45 minutos',
    minutos: 45
  }, { id: 9, texto: '50 minutos', minutos: 50 }
    , { id: 10, texto: '55 minutos', minutos: 55 }, { id: 11, texto: '1 hora', minutos: 60 }, {
    id: 12,
    texto: '1 hora 30 minutos',
    minutos: 90
  }, { id: 13, texto: '2 horas', minutos: 120 }
    , { id: 14, texto: '2 horas 30 minutos', minutos: 150 }, { id: 15, texto: '3 horas', minutos: 180 }, {
    id: 16,
    texto: '3 horas 30 minutos',
    minutos: 210
  }, { id: 17, texto: '4 horas', minutos: 240 }
    , { id: 18, texto: '4 horas 30 minutos', minutos: 270 }, { id: 19, texto: '5 horas', minutos: 300 }, {
    id: 20,
    texto: '5 horas 30 minutos',
    minutos: 330
  }, { id: 21, texto: '6 horas', minutos: 360 }
    , { id: 22, texto: '6 horas 30 minutos', minutos: 390 }, { id: 23, texto: '7 horas', minutos: 420 }, {
    id: 24,
    texto: '7 horas 30 minutos',
    minutos: 450
  }, { id: 25, texto: '8 horas', minutos: 480 }];
  DuracionHoras: number = 0;
  DuracionMinutos: number = 0;
  numerogrupos: number = 0;
  recintoSeleccionado: Recintos = new Recintos();
  isLoadingResults = true;
  translation: any;
  panelSelectorFiltrosvisible: boolean = false;
  formControlListaFiltros: FormControl;
  formControlListaColumnas: FormControl;

  constructor(private servicioAPI: ConectarAPIService,
    private alertaServicio: AlertaService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private autentificador: AutentificacionService,
    private router: Router,
    private _ruta: ActivatedRoute,
    public globales: Globales,
    private translator: Translator) {
  }

  @HostBinding('class.col') true;
  model = new traspasomodelo();

  async ngOnInit() {
    this.translation = this.translator.GetTranslations();
    this.displayedFilters = [this.translation.NombreTipoSesion, this.translation.Lunes, this.translation.Martes, this.translation.Recinto, this.translation.Miercoles, this.translation.Jueves, this.translation.FechaInicio, this.translation.Viernes, this.translation.Sabado, this.translation.Domingo, this.translation.EventoEspecial];
    this.filtersToDisplay = this.displayedFilters.slice();
    //Comprobamos logueo en QueryString
    this.autentificador.verificarAccesoQueryString(this._ruta).then(
      //montamos los segmentos de la Ruta uno a uno porque la URL contiene los parametros y no nos sirve
      res => {
        let ruta = '/';
        this._ruta.snapshot.pathFromRoot[this._ruta.snapshot.pathFromRoot.length - 1].url.forEach(elemento => {
          ruta = ruta + elemento.path + '/';
        });
        ruta = ruta.substring(0, ruta.length - 1);
        this.permisoFormulario = this.autentificador.visualizacionAccion(ruta, 'VER');
        //
        if (!this.permisoFormulario) {
          this.router.navigate(['/portada']);
        }
        ;
        this.permisobtnNuevo = this.autentificador.visualizacionAccion(ruta, 'NUEVO');
        this.permisobtnGuardar = this.autentificador.visualizacionAccion(ruta, 'MODIFICAR');
        this.permisobtnEliminar = this.autentificador.visualizacionAccion(ruta, 'ELIMINAR');
        this.permisobtnDuplicar = this.autentificador.visualizacionAccion(ruta, 'DUPLICAR');
        //Creamos el desplegable de acciones
        this.Acciones = new Array<AccionesDialogo>();
        if (this.permisobtnEliminar) {
          this.Acciones.push({ accion: 'borrar', texto: this.translation.Borrar, funcion: 'BorrarSeleccionados' });
        }
      }
    );

    //Validators.pattern('^ [a-zA-Z0-9 ]+$')
    this.editorRegistroForm = this.formBuilder.group({
      recinto: [''],
      nombre: ['', [Validators.required]],
      aforomaximo: [this.recintoSeleccionado.AforoMaximo, [Validators.required, Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]],
      aforominimo: [this.recintoSeleccionado.MinimoAforo, [Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]],
      aforointernet: ['', [Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]],
      fechaDesde: ['', Validators.required],
      fechaHasta: ['', Validators.required],
      horainicio: ['', Validators.required],
      horafin: ['', Validators.required],
      intervaloaforo: ['0'],
      duracionhora: ['', Validators.pattern('^([0-9]|[0-1][0-9]|2[0-4])$')],
      duracionminuto: ['', Validators.pattern('^([0-9]|[0-5][0-9]|6[0])$')],
      tipoControlDias: [''],
      evento: [],
      numerogrupos: ['0', [Validators.pattern('^[0-9]+$')]],
      idioma: [''],
      controlaaforo: [],
      controlaaforoonline: [],
    });
    this.editorRegistroForm.get('duracionhora').disable();
    this.editorRegistroForm.get('duracionminuto').disable();

    //TODO  Validators
    this.editorFiltrosForm = this.formBuilder.group({
      nombre: [''],
      tipoControl: [''],
      UOdesde: [''],
      UOhasta: [''],
      lunes: [''],
      martes: [''],
      miercoles: [''],
      jueves: [''],
      viernes: [''],
      sabado: [''],
      domingo: [''],
      evento: ['']
    });

    //Seg uimos
    this.cargarDatos();
    this.resetearFiltrosActivos();
    this.formControlListaColumnas = new FormControl(this.displayedColumns);
    this.formControlListaFiltros = new FormControl(this.displayedFilters);
  }
  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.editorRegistroForm.controls;
  } //Editor
  get fFiltro() {
    return this.editorFiltrosForm.controls;
  } //Filtros

  AnadirRegistro() {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    this.model = new traspasomodelo();
    this.editando = true;
    this.alertaServicio.limpiar();
    //Ponemos los booleanos a false, pqu si no se modifican el check no les da valor
    this.model.Lunes = false;
    this.model.Martes = false;
    this.model.Miercoles = false;
    this.model.Jueves = false;
    this.model.Viernes = false;
    this.model.Sabado = false;
    this.model.Domingo = false;
    this.model.Evento = false;

  }

  editar(item) {
    Object.keys(this.editorRegistroForm.controls).forEach(key => {
      this.editorRegistroForm.get(key).reset();
    });
    
    this.editorRegistroForm.get('duracionhora').enable();
    this.editorRegistroForm.get('duracionminuto').enable();
    //this.editorRegistroForm.controls['fechaDesde'].setValue(item.FechaInicio);
    //this.editorRegistroForm.controls['fechaHasta'].setValue(item.FechaFin);


    let objtraspaso = new traspasomodelo();
    objtraspaso.pkId = item.pkId;
    objtraspaso.Descripcion = item.Descripcion;
    objtraspaso.chTipoSesion = item.chTipoSesion;
    objtraspaso.Recinto = item.Recinto;
    objtraspaso.FechaInicio = item.FechaInicio;
    objtraspaso.FechaFin = item.FechaFin;
    objtraspaso.HoraInicio = item.HoraInicio == null || item.HoraInicio.length == 0 ? null : item.HoraInicio.substr(10, 20).trim();
    objtraspaso.HoraFin = item.HoraFin == null || item.HoraFin.length == 0 ? null : item.HoraFin.substr(10, 20).trim();
    objtraspaso.Intervalo = item.Intervalo;
    objtraspaso.Lunes = this.globales.aBooleano(item.Lunes);
    objtraspaso.Martes = this.globales.aBooleano(item.Martes);
    objtraspaso.Miercoles = this.globales.aBooleano(item.Miercoles);
    objtraspaso.Jueves = this.globales.aBooleano(item.Jueves);
    objtraspaso.Viernes = this.globales.aBooleano(item.Viernes);
    objtraspaso.Sabado = this.globales.aBooleano(item.Sabado);
    objtraspaso.Domingo = this.globales.aBooleano(item.Domingo);
    objtraspaso.Evento = this.globales.aBooleano(item.Evento);
    objtraspaso.TipoButaca = item.TipoButaca;
    objtraspaso.AforoMaximo = item.AforoMaximo;
    objtraspaso.MinimoAforo = item.MinimoAforo;
    objtraspaso.MaximoInternet = item.MaximoInternet;
    objtraspaso.Butacas = this.globales.aBooleano(item.Butacas);
    objtraspaso.Duracion = item.Duracion;
    objtraspaso.EspectaculoId = item.EspectaculoId;
    objtraspaso.EnvioExternos = item.EnvioExternos;
    objtraspaso.NumeroGrupos = item.NumeroGrupos === '0' ? item.NumeroGrupos : (parseInt(item.NumeroGrupos) - 1).toString();
    //Dias
    this.arr_dias_semana_seleccionadas = [];
    if (objtraspaso.Lunes) {
      this.arr_dias_semana_seleccionadas.push({ id: '1', value: 'Lunes' });
    }
    ;
    if (objtraspaso.Martes) {
      this.arr_dias_semana_seleccionadas.push({ id: '2', value: 'Martes' });
    }
    ;
    if (objtraspaso.Miercoles) {
      this.arr_dias_semana_seleccionadas.push({ id: '3', value: 'Miércoles' });
    }
    ;
    if (objtraspaso.Jueves) {
      this.arr_dias_semana_seleccionadas.push({ id: '4', value: 'Jueves' });
    }
    ;
    if (objtraspaso.Viernes) {
      this.arr_dias_semana_seleccionadas.push({ id: '5', value: 'Viernes' });
    }
    ;
    if (objtraspaso.Sabado) {
      this.arr_dias_semana_seleccionadas.push({ id: '6', value: 'Sábado' });
    }
    ;
    if (objtraspaso.Domingo) {
      this.arr_dias_semana_seleccionadas.push({ id: '7', value: 'Domingo' });
    }
    ;
    this.f['tipoControlDias'].setValue(this.arr_dias_semana_seleccionadas);
    this.DuracionHoras = this.separaHM(item.Duracion, 'H');
    this.DuracionMinutos = this.separaHM(item.Duracion, 'M');
    this.seleccionaRecinto(item.Recinto);
    this.model = objtraspaso;
    this.editorRegistroForm.get('fechaDesde').setValue(this.aFecha(this.model.FechaInicio));
    this.editorRegistroForm.get('fechaHasta').setValue(this.aFecha(this.model.FechaFin));
    //Validadores de Horario de Recinto
    //this.f.horainicio.setValidators([this.RangoValidador_Hora(this.calculaFecha2minutos_recinto(this.recintoSeleccionado.HoraInicio), 1440)]);
    //this.f.horafin.setValidators([this.RangoValidador_Hora(0, this.calculaFecha2minutos_recinto(this.recintoSeleccionado.HoraFin))]);

    this.editando = true;
    //
    this.alertaServicio.limpiar();
    Object.keys(this.editorRegistroForm.controls).forEach(field => { // {1}
      const control = this.editorRegistroForm.get(field);            // {2}
      control.markAsTouched({ onlySelf: true });       // {3}
    });
  }

  clickAforoOnline() {
    if (this.recintoSeleccionado.ControlarAforoReserva == '1') {
      this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.required, Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]);
      this.tiposIdiomas.forEach((idioma, i) => {
        this.editorRegistroForm.addControl('nombreInternet_' + idioma.PkId, new FormControl(''));
        //this.editorRegistroForm.addControl('descripcionInternet_' + idioma.PkId, new FormControl(''));
        //this.editorRegistroForm.addControl('URL_' + idioma.PkId, new FormControl(''));
      });
      return true;
    } else {
      this.editorRegistroForm.controls['aforointernet'].setValidators([]);
      return false;
    }
    //!this.controlaaforoonline ?
    //  this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.required, Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]) :
    //  this.editorRegistroForm.controls['aforointernet'].setValidators([Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]);
    /*if (this.editorRegistroForm.get('nombreInternet_' + this.tiposIdiomas[0].PkId)) {
      if (!this.controlaaforoonline) { // controla foro online activado
        
        this.editorRegistroForm.get('nombreInternet_' + this.tiposIdiomas[0].PkId).setValidators([Validators.required]);
        this.editorRegistroForm.get('descripcionInternet_' + this.tiposIdiomas[0].PkId).setValidators([Validators.required]);
        this.editorRegistroForm.get('URL_' + this.tiposIdiomas[0].PkId).setValidators([Validators.required]);
      } else {
        
        this.editorRegistroForm.get('nombreInternet_' + this.tiposIdiomas[0].PkId).setValidators([]);
        this.editorRegistroForm.get('descripcionInternet_' + this.tiposIdiomas[0].PkId).setValidators([]);
        this.editorRegistroForm.get('URL_' + this.tiposIdiomas[0].PkId).setValidators([]);
      }
    } else {
      
      this.tiposIdiomas.forEach((idioma, i) => {
        if (i === 0 && !this.controlaaforoonline) {
          this.editorRegistroForm.addControl('nombreInternet_' + idioma.PkId, new FormControl('', Validators.required));
          this.editorRegistroForm.addControl('descripcionInternet_' + idioma.PkId, new FormControl('', Validators.required));
          this.editorRegistroForm.addControl('URL_' + idioma.PkId, new FormControl('', Validators.required));
        } else {
          this.editorRegistroForm.addControl('nombreInternet_' + idioma.PkId, new FormControl(''));
          this.editorRegistroForm.addControl('descripcionInternet_' + idioma.PkId, new FormControl(''));
          this.editorRegistroForm.addControl('URL_' + idioma.PkId, new FormControl(''));
        }
      });
    }*/
  }

  x() {
    
  }

  guardar() {
    this.isLoadingResults = true;
    let insertando = this.model.pkId == null;
    let globales: Globales = new Globales();
    if (!this.editorRegistroForm.invalid) {
      
      //Comprobamos que el Pkid no sea null (estariamos insertando)
      this.calculaModeloDiasSemana(false);
      let objtraspaso = new traspasomodelo;
      if (this.model.pkId != null) {
        
        objtraspaso = this.dataSource.data.map<traspasomodelo>(function (item) {
          return {
            pkId: item.pkId,
            Descripcion: item.Descripcion,
            chTipoSesion: item.chTipoSesion,
            Recinto: item.Recinto,
            FechaInicio: (this.editorRegistroForm.get('fechaDesde').value).format('DD/MM/YYYY 0:00:00'),
            FechaFin: (this.editorRegistroForm.get('fechaHasta').value).format('DD/MM/YYYY 0:00:00'),
            HoraInicio: item.HoraInicio,
            HoraFin: item.HoraFin,
            Intervalo: item.Intervalo,
            Lunes: globales.aBooleano(item.Lunes),
            Martes: globales.aBooleano(item.Martes),
            Miercoles: globales.aBooleano(item.Miercoles),
            Jueves: globales.aBooleano(item.Jueves),
            Viernes: globales.aBooleano(item.Viernes),
            Sabado: globales.aBooleano(item.Sabado),
            Domingo: globales.aBooleano(item.Domingo),
            Evento: globales.aBooleano(item.Evento),
            TipoButaca: item.TipoButaca,
            AforoMaximo: item.AforoMaximo,
            MinimoAforo: item.MinimoAforo,
            MaximoInternet: item.MaximoInternet,
            Butacas: globales.aBooleano(item.Butacas),
            Duracion: item.Duracion,
            EspectaculoId: item.EspectaculoId,
            EnvioExternos: item.EnvioExternos,
            NumeroGrupos: item.NumeroGrupos === '0' ? item.NumeroGrupos : (parseInt(item.NumeroGrupos) + 1).toString(),
            deserialize: null
          };
        }).find(item => item.pkId == this.model.pkId);
      }
      
      objtraspaso.Descripcion = this.model.Descripcion;
      objtraspaso.chTipoSesion = this.model.chTipoSesion;
      objtraspaso.Recinto = this.model.Recinto;
      objtraspaso.FechaInicio = (this.editorRegistroForm.get('fechaDesde').value).format('DD/MM/YYYY 0:00:00');
      objtraspaso.FechaFin = (this.editorRegistroForm.get('fechaHasta').value).format('DD/MM/YYYY 0:00:00');
      objtraspaso.HoraInicio = this.model.HoraInicio;
      objtraspaso.HoraFin = this.model.HoraFin;
      objtraspaso.Intervalo = this.model.Intervalo ? this.model.Intervalo : "0";
      //Dias Semana
      objtraspaso.Lunes = this.model.Lunes;
      objtraspaso.Martes = this.model.Martes;
      objtraspaso.Miercoles = this.model.Miercoles;
      objtraspaso.Jueves = this.model.Jueves;
      objtraspaso.Viernes = this.model.Viernes;
      objtraspaso.Sabado = this.model.Sabado;
      objtraspaso.Domingo = this.model.Domingo;
      //F 
      objtraspaso.Evento = this.model.Evento;
      objtraspaso.TipoButaca = this.model.TipoButaca;
      objtraspaso.AforoMaximo = this.model.AforoMaximo;
      objtraspaso.MinimoAforo = this.model.MinimoAforo;
      objtraspaso.MaximoInternet = this.model.MaximoInternet;
      objtraspaso.Butacas = this.model.Butacas;
      //objtraspaso.Duracion = this.model.Duracion;
      
      
      objtraspaso.Duracion = this.intervalo();
      /* objtraspaso.Duracion = this.uneHM(
        this.f.horainicio.touched ? this.f.horainicio.value.toString() : this.DuracionHoras.toString(),
        this.f.horafin.touched ? this.f.horafin.value.toString() : this.DuracionMinutos.toString()).toString(); */
      objtraspaso.EspectaculoId = this.model.EspectaculoId;
      objtraspaso.EnvioExternos = this.model.EnvioExternos;
      if (this.editorRegistroForm.get('numerogrupos').value) {
        objtraspaso.NumeroGrupos = this.editorRegistroForm.get('numerogrupos').value === '0' ? this.editorRegistroForm.get('numerogrupos').value : (parseInt(this.editorRegistroForm.get('numerogrupos').value) + 1).toString();
      } else {
        objtraspaso.NumeroGrupos = this.editorRegistroForm.get('numerogrupos').value;
      }
      //Salvar a API
      
      
      
      
      
      

      const idiomasArray = []
      if (this.recintoSeleccionado.ControlarAforoReserva == '1') {
        this.tiposIdiomas.forEach((idioma, i) => {
          if (this.editorRegistroForm.get('nombreInternet_' + idioma.PkId).value === '') {
            //this.editorRegistroForm.get('descripcionInternet_' + idioma.PkId).value ||
            //this.editorRegistroForm.get('URL_' + idioma.PkId).value) === '') {
          } else {
            idiomasArray.push({
              "CodIdioma": idioma.CodIdioma,
              "Nombre": this.editorRegistroForm.get('nombreInternet_' + idioma.PkId).value,
              //"Descripcion": this.editorRegistroForm.get('descripcionInternet_' + idioma.PkId).value,
              //"URL": this.editorRegistroForm.get('URL_' + idioma.PkId).value,
            })
          }
        });
      }

      

      this.servicioAPI.insUpdTiposSesion(
        this.model.pkId,
        this.model.Descripcion,
        this.recintoSeleccionado.TipoAforo,
        this.model.Recinto,
        (this.editorRegistroForm.get('fechaDesde').value).format('YYYY-MM-DDTHH:mm:ss'),
        (this.editorRegistroForm.get('fechaHasta').value).format('YYYY-MM-DDTHH:mm:ss'),
        //this.model.FechaFin,
        this.model.HoraInicio,
        this.model.HoraFin,
        this.model.Intervalo,
        this.globales.aBit(this.model.Lunes).toString(),
        this.globales.aBit(this.model.Martes).toString(),
        this.globales.aBit(this.model.Miercoles).toString(),
        this.globales.aBit(this.model.Jueves).toString(),
        this.globales.aBit(this.model.Viernes).toString(),
        this.globales.aBit(this.model.Sabado).toString(),
        this.globales.aBit(this.model.Domingo).toString(),
        this.globales.aBit(this.model.Evento).toString(),
        this.model.TipoButaca,
        this.model.AforoMaximo,
        this.model.MinimoAforo,
        this.model.MaximoInternet,
        this.globales.aBit(this.model.Butacas).toString(),
        objtraspaso.Duracion,
        this.model.EspectaculoId,
        this.model.EnvioExternos,
        objtraspaso.NumeroGrupos,
        this.CodIdioma,
        idiomasArray)
        .pipe(first())
        .subscribe(
          data => {
            
            this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
            if (this.respuestaAPI.DatosResult != null) {
              this.dialog.closeAll();
              this.alertaServicio.success('Datos Guardados Correctamente');
              //
              let max = Math.max.apply(Math, this.dataSource.data.map(function (item) {
                return item.pkId;
              }));
              this.cargarDatosAsync();
              //
              //Hacemos un push al data para que se muestre
              if (insertando) {
                this.model.pkId = '' + (max + 1);
                let pusheddata = this.dataSource.data;
                pusheddata.push(this.modeloaclaseAPI(this.model));
                this.dataSource.data = pusheddata;
                this.isLoadingResults = false;
              } else {
                this.dialog.closeAll();
                this.cargarDatos();
                this.isLoadingResults = false;
              }

              this.editando = false;
            } else {
              this.dialog.closeAll();
              this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              //this.editando = false;
              this.isLoadingResults = false;
            }
            this.dialog.closeAll();
          },
          error => {
            this.dialog.closeAll();
            this.alertaServicio.error(error);
            //this.editando = false;
            this.isLoadingResults = false;
          }
        );

    }
  }

  duplicarRegistro() {
    //this.model = new CustomanUsuario();
    if (this.model.pkId != null) {
      this.editorRegistroForm.get('duracionhora').enable();
      this.editorRegistroForm.get('duracionminuto').enable();
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
    }
  }

  intervalo() {
    const interH = this.editorRegistroForm.get('duracionhora').value;
    const interM = this.editorRegistroForm.get('duracionminuto').value;
    if (interH && !interM) {
      return parseInt(interH) * 60;
    }
    if (!interH && interM) {
      return interM;
    }
    if (interH && interM) {
      return parseInt(interH) * 60 + parseInt(interM);
    }
  }

  pregborrarRegistro() {
    //Borrar
    if (this.model.pkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = this.translation.Borrar;
      this.mensaje_dialogo = this.translation.EstaSeguroQueDeseaBorrarEsteRegistro;
      this.ok_dialogo = this.translation.Si;
      this.ko_dialogo = this.translation.Cancelar;
      this.openDialog();
    }
  }

  pregBorrarSesiones() {
    this.guardar();
    //Borrar
    /* if (this.model.pkId != null) {//Si no estamos añadiendo
      this.titulo_dialogo = 'GENERAR SESIONES';
      this.mensaje_dialogo = 'Se borrarán todas las sesiones anteriores a la fecha actual y se crearán nuevas sesiones con la configuración seleccionada.';
      this.ok_dialogo = 'Si';
      this.ko_dialogo = 'Cancelar';
      
      this.openDialog();
    } else {
      
      this.guardar();
    } */
  }

  async applyFilter(filterValue: string) {
    this.alertaServicio.limpiar();
    await this.AplicarFiltros();
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.filtrosactivosModelo.textoGeneral = filterValue.trim();
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);


    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.alertaServicio.limpiar();
  }

  //Acciones
  HacerAccion(any) {
    this.alertaServicio.limpiar();
    if (any != null && any != undefined) {
      if (this.seleccion.selected == null || this.seleccion.selected.length == 0) {
        this.titulo_dialogo = this.translation.Error;
        this.mensaje_dialogo = this.translation.NoHayRegistrosSeleccionadosParaEstaAccion;
        this.ok_dialogo = this.translation.Ok;
        this.openDialogSeleccionVacia();
        //
      } else {
        this.titulo_dialogo = this.translation.ConfirmarBorrado;
        this.mensaje_dialogo = this.translation.VaAProcederAlBorradoDe + this.seleccion.selected.length.toString() + this.translation.RegistrosDeseaConfirmarElBorrado;
        this.ok_dialogo = this.translation.Si;
        this.ko_dialogo = this.translation.No;
        this.openDialogBorradoSeleccionadas();
      }
    }

  }

  MostrarColumnas() {
    this.alertaServicio.limpiar();
    this.panelColumnasvisible = !this.panelColumnasvisible;
    //Cambiar el boton
  }

  visibilidadColumna(evento, seleccioncolumnas) {
    if (evento != null && seleccioncolumnas != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin columnas
        if (this.columnsToDisplay.length) {
          this.columnsToDisplay.splice(this.columnsToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.columnsToDisplay = [];
        for (let columna of this.displayedColumns) {
          if (seleccioncolumnas.findIndex(x => x.value === columna) >= 0) {
            this.columnsToDisplay.push(columna);
          }
        }
        // for(let a of seleccioncolumnas) {
        //   
      }
    }
  }


  //Click en el boton de aplicar Filtros a los datos
  async AplicarFiltros(filtro?: filtrosActivos) {
    this.alertaServicio.limpiar();
    this.isLoadingResults = true;
    //CArgamos los datos de muevo solo porsiacaso
    await this.cargarDatosAsync().then(
      respuesta => {
        let siono = new Boolean(respuesta);
        //
        if (siono) {
          let datostemporales = this.dataSource.data;
          //aplicamos los filtros sobre los datos temporales
          if (filtro != null) {
            if (filtro.Nombre != null && filtro.Nombre.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Descripcion.indexOf(filtro.Nombre.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = filtro.Nombre.trim();
            }
            this.fFiltro['nombre'].setValue(filtro.Nombre.trim());

            if (filtro.CodigoTipoRecinto != null && filtro.CodigoTipoRecinto != '') {
              datostemporales = datostemporales.filter(x => x.Recinto == filtro.CodigoTipoRecinto);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoRecinto = filtro.CodigoTipoRecinto;
            }
            this.fFiltro['tipoControl'].setValue(filtro.CodigoTipoRecinto);

            if ((filtro.desde != null || filtro.hasta != null) && (filtro.desde.trim() != '' || filtro.hasta.trim() != '')) {
              datostemporales = datostemporales.filter(x => this.aFecha(x.FechaInicio.substr(0, 10)) >= (this.aFecha((filtro.desde.trim() == '' ? '01/01/1970 00:00:00' : filtro.desde.trim() + ' 00:00:00'))) &&
                this.aFecha(x.FechaInicio.substr(0, 10)) <= (this.aFecha(filtro.hasta.trim() == '' ? '31/12/2999 23:59:50' : filtro.hasta.trim() + ' 23:59:50')));
              //Desde Hasta por Fechas
              this.filtrosactivosModelo.desde = filtro.desde.trim();
              this.filtrosactivosModelo.hasta = filtro.hasta.trim();
            }
            this.fFiltro['UOdesde'].setValue(this.aFecha(filtro.desde.trim()));
            this.fFiltro['UOhasta'].setValue(this.aFecha(filtro.hasta.trim()));

            if (filtro.lunes != null && filtro.lunes.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Lunes.trim().toLowerCase() == (filtro.lunes.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.lunes = filtro.lunes.trim();
            }
            this.fFiltro['lunes'].setValue(filtro.lunes.trim());

            if (filtro.martes != null && filtro.martes.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Martes.trim().toLowerCase() == (filtro.martes.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.martes = filtro.martes.trim();
            }
            this.fFiltro['martes'].setValue(filtro.martes.trim());

            if (filtro.miercoles != null && filtro.miercoles.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Miercoles.trim().toLowerCase() == (filtro.miercoles.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.miercoles = filtro.miercoles.trim();
            }
            this.fFiltro['miercoles'].setValue(filtro.miercoles.trim());

            if (filtro.jueves != null && filtro.jueves.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Jueves.trim().toLowerCase() == (filtro.jueves.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.jueves = filtro.jueves.trim();
            }
            this.fFiltro['jueves'].setValue(filtro.jueves.trim());

            if (filtro.viernes != null && filtro.viernes.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Viernes.trim().toLowerCase() == (filtro.viernes.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.viernes = filtro.viernes.trim();
            }
            this.fFiltro['viernes'].setValue(filtro.viernes.trim());

            if (filtro.sabado != null && filtro.sabado.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Sabado.trim().toLowerCase() == (filtro.sabado.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.sabado = filtro.sabado.trim();
            }
            this.fFiltro['sabado'].setValue(filtro.sabado.trim());

            if (filtro.domingo != null && filtro.domingo.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Domingo.trim().toLowerCase() == (filtro.domingo.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.domingo = filtro.domingo.trim();
            }
            this.fFiltro['domingo'].setValue(filtro.domingo.trim());

            if (filtro.evento != null && filtro.evento.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Evento.trim().toLowerCase() == (filtro.evento.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.evento = filtro.evento.trim();
            }
            this.fFiltro['evento'].setValue(filtro.evento.trim());

          } else {

            if (this.fFiltro['nombre'].value != null && this.fFiltro['nombre'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Descripcion.indexOf(this.fFiltro['nombre'].value.trim()) >= 0);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.Nombre = this.fFiltro['nombre'].value;
            }

            if (this.fFiltro['tipoControl'].value != null && this.fFiltro['tipoControl'].value != '') {
              datostemporales = datostemporales.filter(x => x.Recinto == this.fFiltro['tipoControl'].value);
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.CodigoTipoRecinto = this.fFiltro['tipoControl'].value;
            }

            if ((this.fFiltro['UOdesde'].value != null || this.fFiltro['UOhasta'].value != null) && (this.fFiltro['UOdesde'].value != '' || this.fFiltro['UOhasta'].value != '')) {
              let FechasDesde = new DatePipe('es').transform((new Date(this.fFiltro['UOdesde'].value)), 'dd/MM/yyyy').trim();
              let FechasHasta = this.fFiltro['UOhasta'].value != null ? new DatePipe('es').transform((new Date(this.fFiltro['UOhasta'].value)), 'dd/MM/yyyy').trim() : '';
              datostemporales = datostemporales.filter(x => this.aFecha(x.FechaInicio.substr(0, 10)) >= (this.aFecha(FechasDesde == '' ? '01/01/1970 00:00:00' : FechasDesde + ' 00:00:00')) &&
                this.aFecha(x.FechaInicio.substr(0, 10)) <= (this.aFecha(FechasHasta == '' ? '31/12/2999 23:59:50' : FechasHasta + ' 23:59:50')));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.desde = FechasDesde;
              this.filtrosactivosModelo.hasta = FechasHasta;
            }

            if (this.fFiltro['lunes'].value != null && this.fFiltro['lunes'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Lunes.trim().toLowerCase() == (this.fFiltro['lunes'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.lunes = this.fFiltro['lunes'].value;
            }

            if (this.fFiltro['martes'].value != null && this.fFiltro['martes'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Martes.trim().toLowerCase() == (this.fFiltro['martes'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.martes = this.fFiltro['martes'].value;
            }

            if (this.fFiltro['miercoles'].value != null && this.fFiltro['miercoles'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Miercoles.trim().toLowerCase() == (this.fFiltro['miercoles'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.miercoles = this.fFiltro['miercoles'].value;
            }

            if (this.fFiltro['jueves'].value != null && this.fFiltro['jueves'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Jueves.trim().toLowerCase() == (this.fFiltro['jueves'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.jueves = this.fFiltro['jueves'].value;
            }

            if (this.fFiltro['viernes'].value != null && this.fFiltro['viernes'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Viernes.trim().toLowerCase() == (this.fFiltro['viernes'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.viernes = this.fFiltro['viernes'].value;
            }

            if (this.fFiltro['sabado'].value != null && this.fFiltro['sabado'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Sabado.trim().toLowerCase() == (this.fFiltro['sabado'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.sabado = this.fFiltro['sabado'].value;
            }

            if (this.fFiltro['domingo'].value != null && this.fFiltro['domingo'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Domingo.trim().toLowerCase() == (this.fFiltro['domingo'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.domingo = this.fFiltro['domingo'].value;
            }

            if (this.fFiltro['evento'].value != null && this.fFiltro['evento'].value.trim() != '') {
              datostemporales = datostemporales.filter(x => x.Evento.trim().toLowerCase() == (this.fFiltro['evento'].value.trim().toLowerCase() == 'si' ? '1' : '0'));
              //Meter en FiltrosActivos
              this.filtrosactivosModelo.evento = this.fFiltro['evento'].value;
            }
          }

          this.dataSource.data = datostemporales;

          if (this.filtrosactivosModelo.textoGeneral != null && this.filtrosactivosModelo.textoGeneral.trim() != '') {
            this.dataSource.filter = this.filtrosactivosModelo.textoGeneral.trim().toLowerCase();
          } else {
            //Solo borramos el input text, el filtro se actualiza Ok
            //this.filtrogeneral.nativeElement.value = '';
            //
          }
          this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
          this.isLoadingResults = false;
          if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
          }
        } else {
          //No hemos podido cargar los datos
          this.isLoadingResults = false;
        }

      },
      error => {
        //No hemos podido cargar los datos
        this.isLoadingResults = false;
      });
  }

  //Para resetear filtros, se pasa el filtro o se ejecuta sin parametro y se resetean todos
  resetearFiltrosActivos(filtro?: string) {
    this.filtrosactivosModelo.Nombre = filtro == null || filtro == 'Nombre' ? '' : this.filtrosactivosModelo.Nombre;
    this.filtrosactivosModelo.desde = filtro == null || filtro == 'fecha' ? '' : this.filtrosactivosModelo.desde;
    this.filtrosactivosModelo.hasta = filtro == null || filtro == 'fecha' ? '' : this.filtrosactivosModelo.hasta;
    this.filtrosactivosModelo.CodigoTipoRecinto = filtro == null || filtro == 'CodigoTipoRecinto' ? null : this.filtrosactivosModelo.CodigoTipoRecinto;
    this.filtrosactivosModelo.lunes = filtro == null || filtro == 'lunes' ? '' : this.filtrosactivosModelo.lunes;
    this.filtrosactivosModelo.martes = filtro == null || filtro == 'martes' ? '' : this.filtrosactivosModelo.martes;
    this.filtrosactivosModelo.miercoles = filtro == null || filtro == 'miercoles' ? '' : this.filtrosactivosModelo.miercoles;
    this.filtrosactivosModelo.jueves = filtro == null || filtro == 'jueves' ? '' : this.filtrosactivosModelo.jueves;
    this.filtrosactivosModelo.viernes = filtro == null || filtro == 'viernes' ? '' : this.filtrosactivosModelo.viernes;
    this.filtrosactivosModelo.sabado = filtro == null || filtro == 'sabado' ? '' : this.filtrosactivosModelo.sabado;
    this.filtrosactivosModelo.domingo = filtro == null || filtro == 'domingo' ? '' : this.filtrosactivosModelo.domingo;
    this.filtrosactivosModelo.evento = filtro == null || filtro == 'evento' ? '' : this.filtrosactivosModelo.evento;
    this.filtrosactivosModelo.textoGeneral = filtro == null || filtro == 'General' ? '' : this.filtrosactivosModelo.textoGeneral;
    this.filtrosactivos = Object.assign({}, this.filtrosactivosModelo);
    this.AplicarFiltros(this.filtrosactivos);
    if (this.editorFiltrosForm != null) {
      this.editorFiltrosForm.updateValueAndValidity({ onlySelf: true });
      this.editorFiltrosForm.markAsPristine();
      //
    }

  }

  hayFiltros(filtros: filtrosActivos): boolean {
    if ((filtros.Nombre != null && filtros.Nombre.trim() != '') || (filtros.desde != null && filtros.desde.trim() != '') ||
      (filtros.hasta != null && filtros.hasta.trim() != '') || (filtros.CodigoTipoRecinto != null && filtros.CodigoTipoRecinto.trim() != '') ||
      (filtros.lunes != null && filtros.lunes.trim() != '') ||
      (filtros.martes != null && filtros.martes.trim() != '') || (filtros.miercoles != null && filtros.miercoles.trim() != '') ||
      (filtros.jueves != null && filtros.jueves.trim() != '') || (filtros.viernes != null && filtros.viernes.trim() != '') ||
      (filtros.sabado != null && filtros.sabado.trim() != '') || (filtros.domingo != null && filtros.domingo.trim() != '') ||
      (filtros.evento != null && filtros.evento.trim() != '') ||
      (filtros.textoGeneral != null && filtros.textoGeneral.trim() != '')) {
      return true;
    } else {
      return false;
    }
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.seleccion.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.seleccion.clear() :
      this.dataSource.data.forEach(row => this.seleccion.select(row));
  }

  ///Validadores

  RangoValidador_Aforo(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  RangoValidador_AforoInternet(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  RangoValidador_Hora(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let minutos = control.value !== undefined ? (parseInt(control.value.toString().split(':')[0]) * 60) + parseInt(control.value.toString().split(':')[1]) : 0;

      if (control.value !== undefined && (min !== max) && (control.value.trim() == '' || minutos < min || minutos > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  //End Validadores

  CambioDiasSemanaMultiple(evento) {
    //
    //En principio no lo vamos a usar
    console.log("CambioDiasSemanaMultiple: ", evento);
  }

  ///Funcionalidades
  traeNombreRecintoxID(id) {
    if (this.tiposRecintos == null || id == null) {
      return null;
    } else {
      let respuesta = this.tiposRecintos.filter(x => x.PkId == id);
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].Nombre;
    }
  }

  traeTipoAforoRecintoxID(id) {
    if (this.tiposRecintos == null || id == null) {
      return null;
    } else {
      let respuesta = this.tiposRecintos.filter(x => x.PkId == id);
      return respuesta == null || respuesta.length == 0 ? null : respuesta[0].TipoAforo;
    }
  }

  CambioRecintoSeleccionado(evento) {
    //
    this.seleccionaRecinto(evento.value);
  }

  seleccionaRecinto(id) {
    this.recintoSeleccionado = Object.assign({}, this.tiposRecintos.find(x => x.PkId == id));
    this.f.aforomaximo.setValidators([Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.recintoSeleccionado.AforoMaximo))]);
    this.f.aforointernet.setValidators([Validators.maxLength(6), this.RangoValidador_AforoInternet(0, parseInt(this.recintoSeleccionado.MaximoInternet))]);
    if (this.model.pkId == null || this.model.pkId == '0') {
      //Metemos al insertar los valores de aforo del Recinto
      this.f.aforomaximo.setValue(this.recintoSeleccionado.AforoMaximo);
      this.f.aforointernet.setValue(this.recintoSeleccionado.MaximoInternet);
      this.f.aforominimo.setValue(this.recintoSeleccionado.MinimoAforo);
    }
    //Horarios de Recinto
    //this.f.horainicio.setValidators([this.RangoValidador_Hora(this.calculaFecha2minutos_recinto(this.recintoSeleccionado.HoraInicio), 1440)]);
    //this.f.horafin.setValidators([this.RangoValidador_Hora(0, this.calculaFecha2minutos_recinto(this.recintoSeleccionado.HoraFin))]);
    //
  }

  calculaFecha2minutos_recinto(hora: string): number {
    if (hora == null || hora.trim() == '' || hora.trim().length < 10) {
      return 0;
    } else {
      let tiempo = hora.substring(10, 20).split(':');
      return this.uneHM(tiempo[0], tiempo[1]);
    }

  }

  filtrarRecintosUsados(recintos: Recintos[]) {
    if (recintos == null) {
      return [];
    } else {
      let respuesta = recintos.filter(x => this.dataSource.data.findIndex(y => y.Recinto == x.PkId) >= 0);
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }

  filtrarRecintosSinButacas(recintos: Recintos[]) {
    if (recintos == null) {
      return [];
    } else {
      let respuesta = recintos.filter(x => x.Butacas != '1');
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }

  calculaModeloDiasSemana(negar: boolean) {
    let lista = this.f['tipoControlDias'].value;
    if (lista != null && lista != '' && lista.length >= 0) {
      //
      if (negar) {
        this.model.Lunes = this.model.Martes = this.model.Miercoles = this.model.Jueves = this.model.Viernes = this.model.Sabado = this.model.Domingo = false;
      } else {
        if (lista.findIndex(x => x == '1' || x.id == '1') >= 0) {
          this.model.Lunes = true;
        } else {
          this.model.Lunes = false;
        }
        if (lista.findIndex(x => x == '2' || x.id == '2') >= 0) {
          this.model.Martes = true;
        } else {
          this.model.Martes = false;
        }
        if (lista.findIndex(x => x == '3' || x.id == '3') >= 0) {
          this.model.Miercoles = true;
        } else {
          this.model.Miercoles = false;
        }
        if (lista.findIndex(x => x == '4' || x.id == '4') >= 0) {
          this.model.Jueves = true;
        } else {
          this.model.Jueves = false;
        }
        if (lista.findIndex(x => x == '5' || x.id == '5') >= 0) {
          this.model.Viernes = true;
        } else {
          this.model.Viernes = false;
        }
        if (lista.findIndex(x => x == '6' || x.id == '6') >= 0) {
          this.model.Sabado = true;
        } else {
          this.model.Sabado = false;
        }
        if (lista.findIndex(x => x == '7' || x.id == '7') >= 0) {
          this.model.Domingo = true;
        } else {
          this.model.Domingo = false;
        }
      }
    }
  }

  cargarDatos() {
    //Cargamos Datos
    this.isLoadingResults = true;
    this.servicioAPI.getIdiomas(null, null, "1").pipe(first())
      .subscribe(
        data => {
          this.tiposIdiomasAPI = new Idiomas_Respuesta().deserialize(data);
          if (this.tiposIdiomasAPI.DatosResult != null) {
            this.tiposIdiomas = this.tiposIdiomasAPI.DatosResult.ListaIdiomas;
            this.servicioAPI.getRecintos().pipe(first())
              .subscribe(
                data => {
                  this.respuestaAPIRecintos = new Recintos_Respuesta().deserialize(data);
                  if (this.respuestaAPIRecintos.DatosResult != null) {
                    this.tiposRecintos = this.respuestaAPIRecintos.DatosResult.ListaRecintos.filter(x => x.ControlarAforo == '1' || x.ControlarAforoReserva == '1');
                    this.servicioAPI.getTiposSesion().pipe(first())
                      .subscribe(
                        data => {
                          this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
                          if (this.respuestaAPI.DatosResult != null) {
                            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.Lista);
                            this.dataSource.paginator = this.paginator;
                            this.dataSource.sort = this.sort;
                            //
                            this.isLoadingResults = false;
                          } else {
                            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
                            this.isLoadingResults = false;
                          }

                        },
                        error => {
                          this.alertaServicio.error(error);
                          this.isLoadingResults = false;
                        }
                      );
                  } else {
                    this.alertaServicio.error(this.respuestaAPIRecintos.Mensajes[0].DescripcionMensaje);
                    this.isLoadingResults = false;
                  }

                },
                error => {
                  this.alertaServicio.error(error);
                  this.isLoadingResults = false;
                }
              );
          }
          else {
            this.alertaServicio.error(this.tiposIdiomasAPI.Mensajes[0].DescripcionMensaje);
            //return false;
          }
        },
        error => {
          this.alertaServicio.error(error);
          //return false;
        });
  }

  async cargarDatosAsync(): Promise<boolean> {
    //Cargamos Datos
    let resultado: boolean = false;
    this.isLoadingResults = true;
    await this.servicioAPI.getTiposSesionAsync()
      .then(
        data => {
          
          
          
          
          
          this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
          if (this.respuestaAPI.DatosResult != null) {
            this.dataSource = new MatTableDataSource(this.respuestaAPI.DatosResult.Lista);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            //
          } else {
            this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    //Lanzamos encadenado
    await this.servicioAPI.getRecintosAsync()
      .then(
        data => {
          
          
          
          
          
          this.respuestaAPIRecintos = new Recintos_Respuesta().deserialize(data);
          if (this.respuestaAPIRecintos.DatosResult != null) {
            this.tiposRecintos = this.respuestaAPIRecintos.DatosResult.ListaRecintos.filter(x => x.ControlarAforo == '1' || x.ControlarAforoReserva == '1');
            //
          } else {
            this.alertaServicio.error(this.respuestaAPIRecintos.Mensajes[0].DescripcionMensaje);
            resultado = false;
          }

        },
        error => {
          this.alertaServicio.error(error);
          resultado = false;
        }
      );
    this.isLoadingResults = false;
    return resultado;
  }


  cambiadesde(evento) {
    //
    this.fecha_minHasta = evento.value;
    this.model.FechaInicio = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
    
    
    this.editorRegistroForm.get('fechaDesde').setValue(evento.value);
    this.model.FechaInicio = evento.value;
  }

  cambiahasta(evento) {
    //
    this.fecha_maxDesde = evento.value;
    this.model.FechaFin = new DatePipe('es').transform(evento.value, 'dd/MM/yyyy').trim();
    this.editorRegistroForm.get('fechaHasta').setValue(evento.value);
  }

  getFechaInicio(): Date {
    //let split = this.model.FechaInicio.split(' ');
    //split = split[0].split('/');
    // return this.aFecha(this.model.FechaInicio);
    return this.editorRegistroForm.get('fechaDesde').value
  }

  getFechaFin(): Date {
    // return this.aFecha(this.model.FechaFin);
    return this.editorRegistroForm.get('fechaHasta').value
  }


  cambiaHoradesde(evento) {
    //
    this.objedicionCalendarioHorario.FechaAcceso = evento.target.value + ':00';
  }

  cambiaHorahasta(evento) {
    //
    this.objedicionCalendarioHorario.FechaAccesoFin = evento.target.value + ':00';
  }

  cambiarDuracionDisabled(evento) {
    
    if (evento.value !== '0') {
      this.editorRegistroForm.get('duracionhora').enable();
      this.editorRegistroForm.get('duracionminuto').enable();
    } else {
      this.editorRegistroForm.get('duracionhora').disable();
      this.editorRegistroForm.get('duracionminuto').disable();
    }
  }


  checkCodigoNoUsado(codigoID: string) {
    // if (this.dataSource == null || this.dataSource.data == null || this.dataSource.data.length == 0)
    // {
    //   return [{codigoNOUsado:true}];
    // }
    // else
    // {
    //   return [{codigoNOUsado:this.dataSource.data.filter(x => x.CodigoTipoControl.trim() == codigoID.trim()) == null}];
    // }

  }

  estaUsado() {
    // return (control: AbstractControl) => {
    //   return this.checkCodigoNoUsado(control.value).map(res => {
    //     return res.codigoNOUsado ? null : {codigoUsado: true};
    //   });
    // }
  }

  aFecha(fecha): Date {
    if (fecha == null || fecha.length == 0) {
      return null;
    } else {
      if (fecha.getDay) {
        fecha = (fecha.getDate().toString() + '/' + (fecha.getMonth() + 1).toString() + '/' + fecha.getFullYear().toString());
      }
      var parts = fecha.split('/');
      return new Date(parseInt(parts[2].substring(0, 4)), parseInt(parts[1]) - 1, parseInt(parts[0]));
    }
  }

  separaHM(valor: number, tipo: string): number {
    let hora = this.globales.truncarDecimales(valor / 60);
    if (tipo == 'H') {
      return hora;
    } else {
      return valor - (hora * 60);
    }
  }

  uneHM(hora, minuto) {
    
    
    return (parseInt((hora != null && hora.trim() != '') ? hora : '0') * 60) + parseInt((minuto != null && minuto.trim() != '') ? minuto : '0');
  }

  //Cambiar el modelo API (string) a modelo con boolean para los checks
  modeloaclaseAPI(item: traspasomodelo): TipoSesion {
    let respuesta = new TipoSesion;
    respuesta.pkId = item.pkId;
    respuesta.Descripcion = item.Descripcion;
    respuesta.chTipoSesion = item.chTipoSesion;
    respuesta.Recinto = item.Recinto;
    respuesta.FechaInicio = item.FechaInicio;
    respuesta.FechaFin = item.FechaFin;
    respuesta.HoraInicio = item.HoraInicio;
    respuesta.HoraFin = item.HoraFin;
    respuesta.Intervalo = item.Intervalo;
    respuesta.Lunes = item.Lunes ? '1' : '0';
    respuesta.Martes = item.Martes ? '1' : '0';
    respuesta.Miercoles = item.Miercoles ? '1' : '0';
    respuesta.Jueves = item.Jueves ? '1' : '0';
    respuesta.Viernes = item.Viernes ? '1' : '0';
    respuesta.Sabado = item.Sabado ? '1' : '0';
    respuesta.Domingo = item.Domingo ? '1' : '0';
    respuesta.Evento = item.Evento ? '1' : '0';
    respuesta.TipoButaca = item.TipoButaca;
    respuesta.AforoMaximo = item.AforoMaximo;
    respuesta.MinimoAforo = item.MinimoAforo;
    respuesta.MaximoInternet = item.MaximoInternet;
    respuesta.Butacas = item.Butacas ? '1' : '0';
    respuesta.Duracion = item.Duracion;
    respuesta.EspectaculoId = item.EspectaculoId;
    respuesta.EnvioExternos = item.EnvioExternos;
    respuesta.NumeroGrupos = item.NumeroGrupos;

    return respuesta;
  }

  //Se podria instanciar este openDialog si requiriesemos mas popups (este seria openDialogBorrar)
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result.eleccion;
      
      //
      if (result == 'SI') {
        
        //Salvar a API
        this.servicioAPI.delTiposSesion(
          this.model.pkId)
          .pipe(first())
          .subscribe(
            data => {
              this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.guardar();
                //this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== this.model.pkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
        this.editando = false;
      } else {
        
      }
    });
  }

  //Instancia para Accionse sobre guarar sesiones
  openDialogSesiones(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        var resultado: string = '';
        this.guardar();
      }
    }
    );
  }

  //Instancia para Accionse sobre seleccion vacia
  openDialogSeleccionVacia(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: { titulo: this.titulo_dialogo, texto: this.mensaje_dialogo, eleccion: this.eleccion_dialogo, ok: this.ok_dialogo, ko: null }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.accionselecionada = null;
    });
  }

  //Instancia Borrado Seleccionadas
  openDialogBorradoSeleccionadas(): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.titulo_dialogo,
        texto: this.mensaje_dialogo,
        eleccion: this.eleccion_dialogo,
        ok: this.ok_dialogo,
        ko: this.ko_dialogo
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      //
      this.eleccion_dialogo = result;
      //
      if (result == 'SI') {
        var resultado: string = '';
        this.seleccion.selected.forEach(
          element => {
            //Recorremos los seleccionados y vamos borrando
            this.servicioAPI.delTiposSesionAsync(
              element.pkId)
              .then(
                data => {
                  this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
                  if (this.respuestaAPI.DatosResult != null) {
                    this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== element.pkId);
                  } else {
                    resultado += this.respuestaAPI.Mensajes[0].DescripcionMensaje + ' <BR />';
                  }
                },
                error => {
                  resultado += error + ' <BR />';
                }
              );
          });
        if (resultado != null && resultado.length > 0) {
          this.alertaServicio.error(resultado);
        } else {
          this.alertaServicio.success('Datos Borrados Correctamente');
        }

        //End For
      }
      //F Si
      this.seleccion.clear();
      this.accionselecionada = null;
    });
    //F Subscribe
  }

  //----------------vvvvsiguevvv
  duplicar(row) {
    this.model = row; // cambiar por los datos de la fila
    if (this.model.pkId != null) {
      this.model.pkId = null; //si no estamos ya con uno nuevo, ponemos el Pkid a null y lo trataremos como insercion.
      this.arr_dias_semana_seleccionadas = [];
      if (this.model.Lunes) {
        this.arr_dias_semana_seleccionadas.push({ id: '1', value: 'Lunes' });
      }
      ;
      if (this.model.Martes) {
        this.arr_dias_semana_seleccionadas.push({ id: '2', value: 'Martes' });
      }
      ;
      if (this.model.Miercoles) {
        this.arr_dias_semana_seleccionadas.push({ id: '3', value: 'Miércoles' });
      }
      ;
      if (this.model.Jueves) {
        this.arr_dias_semana_seleccionadas.push({ id: '4', value: 'Jueves' });
      }
      ;
      if (this.model.Viernes) {
        this.arr_dias_semana_seleccionadas.push({ id: '5', value: 'Viernes' });
      }
      ;
      if (this.model.Sabado) {
        this.arr_dias_semana_seleccionadas.push({ id: '6', value: 'Sábado' });
      }
      ;
      if (this.model.Domingo) {
        this.arr_dias_semana_seleccionadas.push({ id: '7', value: 'Domingo' });
      }
      ;
      this.f['tipoControlDias'].setValue(this.arr_dias_semana_seleccionadas);
    }
    this.model.HoraInicio = row.HoraInicio == null || row.HoraInicio.length == 0 ? null : row.HoraInicio.substr(10, 20).trim();
    this.model.HoraFin = row.HoraFin == null || row.HoraFin.length == 0 ? null : row.HoraFin.substr(10, 20).trim();

    this.editorRegistroForm.get('fechaDesde').setValue(this.aFecha(this.model.FechaInicio));
    this.editorRegistroForm.get('fechaHasta').setValue(this.aFecha(this.model.FechaFin));

    this.editando = true;
    this.alertaServicio.limpiar();
  }

  borrar(pkId: string): void {
    const dialogRef = this.dialog.open(DialogoPopUp, {
      width: '250px',
      data: {
        titulo: this.translation.BorrarCliente, texto: this.translation.EstaSeguroQueDeseaBorrarElRegistro,
        eleccion: this.translation.Patatas, ok: this.translation.Confirmar, ko: this.translation.Cancelar
      }
    });
    

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'SI') {
        
        this.servicioAPI.delTiposSesion(
          pkId)
          .pipe(first())
          .subscribe(
            data => {
              this.respuestaAPI = new TipoSesion_Respuesta().deserialize(data);
              if (this.respuestaAPI.DatosResult != null) {
                this.alertaServicio.success('Datos Borrados Correctamente');
                //
                //this.dataSource.data.splice(,1)
                this.dataSource.data = this.dataSource.data.filter(item => item.pkId !== pkId);
              } else {
                this.alertaServicio.error(this.respuestaAPI.Mensajes[0].DescripcionMensaje);
              }

            },
            error => {
              this.alertaServicio.error(error);
            }
          );
          
        this.editando = false;
      }
     
    });
  }

  visibilidadFiltro(evento, seleccionfiltros) {
    if (evento != null && seleccionfiltros != null) {
      //

      if (!evento.option.selected) {
        //Borramos si no nos quedamos sin filtros
        if (this.filtersToDisplay.length) {
          this.filtersToDisplay.splice(this.filtersToDisplay.findIndex(x => x === evento.option.value), 1);
        }
      } else {
        //Añadimos
        this.filtersToDisplay = [];
        for (let filtro of this.displayedFilters) {
          if (seleccionfiltros.findIndex(x => x.value === filtro) >= 0) {
            this.filtersToDisplay.push(filtro);
          }
        }
        // for(let a of seleccionfiltros) {
        //   
      }
    }
  }

  MostrarFiltros() {
    this.alertaServicio.limpiar();
    this.panelSelectorFiltrosvisible = !this.panelSelectorFiltrosvisible;
    this.panelColumnasvisible = false;
    this.formControlListaColumnas = new FormControl(this.columnsToDisplay);
    this.formControlListaFiltros = new FormControl(this.filtersToDisplay);
  }

}
