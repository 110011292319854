<div class="component">

    <app-buttons (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>


    <app-filter [tableName]="'clientType'"></app-filter>

</div>
<app-table [tableName]="'clientType'" (addbut)="addbut=$event"></app-table>
<app-panelcustoman *ngIf="customanpanel!==undefined" [ConfigPanel]="customanpanel" [mode]="'I'">
</app-panelcustoman>