<span>{{ 'DESCRIPCION_SELECCION' | translate }}</span>
<div>
  <div class="row" *ngIf="verMapa">
    <iacpos-slider-precios *ngIf="sliderVisible"></iacpos-slider-precios>
    <div
      id="princ"
      class="principal"
      [ngClass]="{'solomapa':menucontextual}"
      #container
      (window:resize)="onResize(container)"
      (mouseover)="preventScroll($event)"
      (mouseout)="doScroll($event)"
    >
    <div>
      <iacpos-mapa-recinto
      #mapa
      [contextual]="menucontextual"
      [nombreRecinto]="nombreEstadio"
      (eventMapTransformation)="eventMapTransformation($event)"
      [minimapaZoomTransformation]="cambiosMinimapa"
      (mouseover)="hacerZoom = true"
      (mouseout)="hacerZoom = false"
    > 
    </iacpos-mapa-recinto>


    </div>

    </div>

    <!-- Mini Mapa-->
    <div class="iconosNav" *ngIf="!menucontextual" >
      <button class="botonMostrar" style="margin-left:5px" (click)="minimapaRender()">
        <img src="assets/{{ claveEmpresa }}/img/body/ocultar.svg" alt="Ocultar mapa" />
      </button>
      <div [ngStyle]="renderMapa ? { display: 'block' } : { display: 'none' }" >
        <div class="miniMapa">
          <iacpos-minimapa-recinto
            [contextual]="menucontextual"
            [nombreRecinto]="nombreEstadio"
            (eventMinimapTransformation)="eventMinimapTransformation($event)"
            [mapaZoomTransformation]="cambiosMapa"
          >
          </iacpos-minimapa-recinto>
        </div>
        <button class="navFunct" style="margin-left:15px">
          <img src="assets/{{ claveEmpresa }}/img/body/flecha.svg" (click)="arriba()" alt="Flecha arriba" />
        </button>
        <button class="navFunct">
          <img
            class="NavDerecha"
            src="assets/{{ claveEmpresa }}/img/body/flecha.svg" alt="Flecha derecha"
            (click)="derecha()"
          />
        </button>

        <button class="navFunct">
          <img
            class="NavIzquierda"
            src="assets/{{ claveEmpresa }}/img/body/flecha.svg" alt="Flecha izquierda"
            (click)="izquierda()"
          />
        </button>

        <button class="navFunct">
          <img
            class="NavAbajo"
            src="assets/{{ claveEmpresa }}/img/body/flecha.svg" alt="Flecha abajo"
            (click)="abajo()"
          />
        </button>
        <button class="navFunct">
          <img src="assets/{{ claveEmpresa }}/img/body/menos.svg" alt="Menos zoom" (click)="menosc()" />
        </button>
        <button class="navFunct">
          <img src="assets/{{ claveEmpresa }}/img/body/mas.svg" alt="Mas zoom" (click)="masc()" />
        </button>
      </div>

      <input
        type="checkbox"
        #seleccionMultipleCheck
        (click)="seleccionMultiple(seleccionMultipleCheck)"
      />
      <div class="text-tipoButaca">{{ 'SELECCIONMULTIPLE' | translate }}</div>

      <div *ngIf="entradasService.seleccionMultiple">
        <button (click)="resetButacasSeleccionadas()">{{ 'BORRARSELECCION' | translate }}</button>
      </div>
      <div class="butacas">
        <div class="tipo-butaca" *ngFor="let estadoButaca of estadosButacas">
          <div class="cuadro-tipo" [style.background]="estadoButaca.Color"></div>
          <div class="text-tipoButaca">{{ estadoButaca.Descripcion }}</div>
        </div>
      </div>

      <button class="navFunct" style="margin-left:15px">
        <img src="assets/{{ claveEmpresa }}/img/body/refrescar.svg" (click)="recargarMapa()" alt="Actualizar estado de las butacas"/>
      </button>
      <div class="TextoRefres">{{ 'REFRESCARMAPA' | translate }}</div>
      <div class="checkMarcadas">
        <input #mostrarMarcadasCheckBox
        id="mostrarMarcadasCheckBox"
        type="checkbox"

        (change)="onMostrarMarcadasChanged(mostrarMarcadasCheckBox.checked)" />
        <div class="TextoRefres">{{ 'MOSTRARBUTACASMARCADAS' | translate }}</div>
      </div>
    </div>
    <!-- -->
    <!-- Mini Contextual-->
    <div class="iconosNav2" id="minimapadiv" *ngIf="menucontextual">
<!--       <button class="botonMostrar" style="margin-left:5px" (click)="minimapaRender()">
        <img src="assets/{{ claveEmpresa }}/img/body/ocultar.svg" alt="Ocultar mapa" />
      </button> -->
      <div [ngStyle]="renderMapa ? { display: 'block' } : { display: 'none' }" >
        <mat-card>
          <mat-card-content>

            <div class="miniMapa" id="Mapvisor">
              <iacpos-minimapa-recinto
                [contextual]="menucontextual"
                [nombreRecinto]="nombreEstadio"
                (eventMinimapTransformation)="eventMinimapTransformation($event)"
                [mapaZoomTransformation]="cambiosMapa"
              >
              </iacpos-minimapa-recinto>
            </div>

            <div class="navbuttons">
              <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
                <mat-button-toggle mat-icon-button  aria-label="" matTooltip="Zoom Out" (click)="menosc()">              
      <!--             <span class="button right">
                  <i class="fa fa-search-minus" aria-hidden="true"></i>
                  </span> -->
                  <mat-icon>zoom_out</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle mat-icon-button  aria-label="" matTooltip="Zoom In" (click)="masc()">
      <!--             <span class="button right">
                    <i class="fa fa-search-plus" aria-hidden="true"></i>
                    </span> -->
                    <mat-icon>zoom_in</mat-icon>  
                </mat-button-toggle>
                <mat-button-toggle mat-icon-button aria-label="" matTooltip="{{ 'REFRESCARMAPA' | translate }}" (click)="recargarMapa()">
                  <mat-icon>refresh</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
              </div>


          </mat-card-content>
        </mat-card>



        <mat-divider></mat-divider>


<!--         <div class="navbuttons">
          <button class="navFunct" style="margin-left:15px">
            <div class="btn-map" style="cursor: pointer;" (click)="menosc()">
              <span class="button right">
              <i class="fa fa-search-minus" aria-hidden="true"></i>
              </span>
            </div>
          </button>
          <button class="navFunct" style="margin-left:15px">
            <div class="btn-map" style="cursor: pointer;" (click)="masc()">
              <span class="button right">
              <i class="fa fa-search-plus" aria-hidden="true"></i>
              </span>
            </div>
          </button>
        </div> -->
        <div class="navbuttons">
        <button class="navFunct" style="margin-left:15px">
          <div class="btn-map" style="cursor: pointer;" (click)="izquierda()">
            <span class="button right">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </span>
          </div>
        </button>
        <button class="navFunct">
          <div class="btn-map" style="cursor: pointer;" (click)="derecha()">
            <span class="button right">
            <i class="fa fa-arrow-right" aria-hidden="true"></i>
            </span>
          </div>
        </button>

        <button class="navFunct">
          <div class="btn-map" style="cursor: pointer;" (click)="arriba()">
            <span class="button right">
            <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </span>
          </div>
        </button>

        <button class="navFunct">
          <div class="btn-map" style="cursor: pointer;" (click)="abajo()">
            <span class="button right">
            <i class="fa fa-arrow-down" aria-hidden="true"></i>
            </span>
          </div>
        </button>
        </div>
      </div>

<!--       <div>
      <span class="btn-map" style="cursor: pointer;" (click)="recargarMapa()">
        <span class="button right">
        <i class="fa fa-refresh" aria-hidden="true"></i>
        </span>
      </span>
      <span class="TextoRefres">{{ 'REFRESCARMAPA' | translate }}</span>
      </div> -->

      <input
        type="checkbox"
        #seleccionMultipleCheck
        (click)="seleccionMultiple(seleccionMultipleCheck)"
      />
      <div class="text-tipoButaca">{{ 'SELECCIONMULTIPLE' | translate }}</div>

      <div *ngIf="entradasService.seleccionMultiple">
        <button (click)="resetButacasSeleccionadas()">{{ 'BORRARSELECCION' | translate }}</button>
      </div>
      <div class="butacas">
        <div class="tipo-butaca" *ngFor="let estadoButaca of estadosButacas">
          <div class="cuadro-tipo" [style.background]="estadoButaca.Color"></div>
          <div class="text-tipoButaca">{{ estadoButaca.Descripcion }}</div>
        </div>
      </div>

<!--       <button class="navFunct" style="margin-left:15px">
        <img src="assets/{{ claveEmpresa }}/img/body/refrescar.svg" (click)="recargarMapa()" alt="Actualizar estado de las butacas"/>
      </button> -->


      <div class="checkMarcadas">

<!--         <section class="example-section">
          <mat-slide-toggle
              class="example-margin"
              [color]="color"
              [checked]="checked"
              [disabled]="disabled">
            Slide me!
          </mat-slide-toggle>
        </section> -->

        <input #mostrarMarcadasCheckBox
        id="mostrarMarcadasCheckBox"
        type="checkbox"

        (change)="onMostrarMarcadasChanged(mostrarMarcadasCheckBox.checked)" />
        <div class="TextoRefres">{{ 'MOSTRARBUTACASMARCADAS' | translate }}</div>
      </div>
    </div>
    <!-- -->

  </div>


      <!--       <div class="butacas">
        <div class="tipo-butaca">
          <div class="cuadro-tipo color-general"></div>
          <div class="text-tipoButaca">{{ 'GENERAL' | translate }}</div>
        </div>
        <div class="tipo-butaca">
          <div class="cuadro-tipo color-preferente"></div>
          <div class="text-tipoButaca">{{ 'PREFERENTE' | translate }}</div>
        </div>
        <div class="tipo-butaca">
          <div class="cuadro-tipo color-noDisponible"></div>
          <div class="text-tipoButaca">{{ 'NODISPONIBLE' | translate }}</div>
        </div>

      </div> -->

      <!-- Seleccion multiple -->

  <div *ngIf="noNumerada">
      <h4>{{ 'SELECTORENTRADASNONUMERADAS' | translate }}</h4>
      <br />
    <div *ngFor="let itemTarifa1 of butacaMarcada">
    <iacpos-selector-no-numeradas [itemTarifa]="itemTarifa1" [firstInit]="true"></iacpos-selector-no-numeradas>
    </div>
  </div>
  <!-- <form [formGroup]="datosForm" *ngIf="noNumerada">
  <div>
    <label>{{ 'NUMENTRADAS' | translate }}</label>
    <input formControlName="numEntradas" type="text">

  </div>
</form> -->
  <iacpos-modal id="selector" style="display: none"></iacpos-modal>
  <iacpos-modal-no-numerado id="selectorNONumerado" class="modalNoNumerado" style="display: none"></iacpos-modal-no-numerado>
</div>
