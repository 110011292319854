import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ApiTokenService } from '../../api/token/api-token.service';
import * as data from "@sharedV11/jsons/user/user.json"
import { Request_insertion_upd_userTypes } from '@sharedV11/classes/userRequest/users/userProfile/request_insertion_upd_userTypes';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { Request_insertion_upd_user } from '@sharedV11/classes/userRequest/users/user/request_insertion_upd_user';
import { catchError, map } from 'rxjs/operators';
import { Request_userTypes_list } from '@sharedV11/classes/userRequest/users/userProfile/request_userTypes_list';
import { User } from '@sharedV11/classes/users/user/user';
import { Categoria } from '@sharedV11/classes/categoria/categoria';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class EditCategoriasService {


  private extractData(res: Response) {
    let body = res;
    return body || {};
  }
  constructor(
    private http: HttpClient,
    private tokenService: ApiTokenService,
    private global:GlobalService,


    ) { }


  findCategoriaByPkId(pkId:string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = ""; 
    peticion.Descripcion = ""; 
    peticion.Orden = ""; 
    peticion.pkId =pkId
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanCategoria', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

/*   findEspectaculoByPkId(pkId:string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = ""; 
    peticion.Descripcion = ""; 
    peticion.Orden = ""; 
    peticion.pkId =pkId
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/GetEspectaculos', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  } */


  findUserByPkIdLocal(pkId:string): Observable<any> {
    console.log(data)
    return of(data["default"])
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  insCategoria(categoria,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = categoria;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanCategoria', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanUsuarios '))
    );
  }
  updCategoria  (categoria,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = categoria;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanCategoria ', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanUsuarios '))
    );
  }


  insUpdUsuario(user,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = user;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarActualizarCustomanUsuarios'))
    );
  }

  delCategoria(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanCategoria', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }

  delTiposReservas(pkId: string): Observable<any> {
    let peticion: any = {};
    peticion.clienteAPI = this.global.clienteapi; // TODO

    peticion.pkId = pkId;
    //peticion.PkId = pkId;

    // Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/EliminarCustomanTipoReserva', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
  
  
/*   insUpdUsuarios(user:User): Observable<any> {
    // let Baja1:boolean = Baja.trim().toLowerCase() == "true" || Baja.trim().toLowerCase() == "1"?true:false;
    // let Conectado1:boolean = Conectado.trim().toLowerCase() == "true" || Conectado.trim().toLowerCase() == "1"?true:false;
    //Montamos la peticion Listado de Usuario (necesitamos una generica no es peticionUsuario)
    console.log(user);
    let peticion: Request_insertion_upd_user = new Request_insertion_upd_user();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Nombre = user.Nombre;
    peticion.Clave = user.Clave;
    peticion.Tipo = user.Tipo;
    if (user.Empleado != null) { peticion.Empleado = user.Empleado; } else { peticion.Empleado = '' }
    if (user.Personal != null) { peticion.Personal = user.Personal; } else { peticion.Personal = '' }
    if (user.Baja) { peticion.Baja = "1"; } else { peticion.Baja = "0"; }
    if (user.Conectado) { peticion.Conectado = "1"; } else { peticion.Conectado = "0"; }
    if (user.pkId != null && user.pkId != "0") {
      peticion.Funcion = "U";
      peticion.pkId = user.pkId;
    }
    else {
      peticion.Funcion = "I";
      peticion.pkId = "0"
    }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  } */

  getTiposUsuario(nombre?: string, descripcion?: string): Observable<any> {    
    //Montamos la peticion Listado de Tipos Usuario
    let peticion: Request_userTypes_list = new Request_userTypes_list();
    peticion.clienteAPI = this.global.clienteapi; //TODO
    if (nombre != null) { peticion.Nombre = nombre; } else { peticion.Nombre = ""; }
    if (descripcion != null) { peticion.Descripcion = descripcion; } else { peticion.Descripcion = ""; }
    //Enviamos la peticion
    const response = this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanTipoUsuarios', JSON.stringify(peticion), httpOptions).pipe(
      map(data => {
        return data;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
    return response;
  }
}
