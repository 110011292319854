import { ChangeDetectorRef, Component, Input, OnInit, AfterViewInit, LOCALE_ID, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EditInternetGroupService } from '@sharedV11/services/components/internetGroups/edit-internet-group.service';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { InternetGroup } from '@sharedV11/classes/tarifaResponse/internetgroup';
import { DualListComponent } from 'angular-dual-listbox';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { IdiomaGR } from '@sharedV11/classes/tarifaResponse/idiomaGR';
import { AlertService } from '@sharedV11/services/alerts/alert/alert.service';
import { AppDateAdapter, APP_FORMATOS_FECHA } from '@sharedV11/classes/dataAdapter/date-format.adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorIntlEses } from '@otrosProveedoresV6/ES-es.paginator';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { EditTipoSesionService } from '@sharedV11/services/components/tiposesion/edit-tipo-sesion.service';
import { CalendariosTipoControl } from '@sharedV11/classes/maestros/calendarios/CalendariosTiposControl_Respuesta';
import { TiposSesion } from '@sharedV11/classes/recintos/tipos_sesion';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { SinButacas } from '@sharedV11/classes/recintos/sin_butacas';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { ApiWaitComponent } from '@sharedV11/components/dialogs/api_wait/api_wait.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import { InputFilterComponent } from '@sharedV11/components/input-filter/input-filter.component';
import { GlobalServicCentros } from '@sharedV11/services/global/global.service centros';




class duallistmodel {
  pkId: string;
  Nombre: string;
}

class intervalosAforo {
  id: number;
  texto: string;
  minutos: number;
}

@Component({
  selector: 'app-edit-sinbutacas',
  templateUrl: './edit-sinbutacas.component.html',
  styleUrls: ['./edit-sinbutacas.component.scss'], providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_FORMATOS_FECHA
    },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEses },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ]
})


export class EditSinButacasComponent implements OnInit, AfterViewInit {
  
  loading = true;
  private ngUnsubscribe = new Subject();

  @Input() pkId: string;
  @Input() mode: string;
  @Input() modificar: boolean;

  @ViewChild("recintoInput") recintoInput: InputFilterComponent;
  @ViewChild("tiposesionInput") tiposesionInput: InputFilterComponent;
  
  dataDesde: Date;
  dataHasta: Date;

  recintoDisabled=false;
  TiposSessionOfRecinto = [];

  translation: any;
  editorListaIdiomas: Array<IdiomaGR> = [];
  idiomasApp: Array<IdiomaGR>;
  editSinButacasForm: FormGroup;
  horainiciodisable = false;


  sinbutacadata: SinButacas;
  TiposReservas: any[] = [];
  EnlacesMenu: any[] = [];
  Recintos: any;
  TiposSesion: any;
  RecintoInfo: any;
  TipoSesionInfo: any;
  fecha_minDesde: Date;
  fecha_minHasta: Date;
  fecha_maxDesde: Date;
  fecha_maxHasta: Date;
  LabelDisponibleTotaltxt: string;
  LabelDisponibleOnlinetxt: string;
  LabelDisponibleGrupo: string;
  AforoInicial: string;
  arraydias: Array<string> = [];
  objedicionCalendarioHorario: CalendariosTipoControl = new CalendariosTipoControl;
  arr_intervalos_aforo: Array<intervalosAforo> = [{ id: 0, texto: 'SinIntervalo', minutos: 0 }, {
    id: 1,
    texto: '5 minutos',
    minutos: 5
  }, { id: 2, texto: '10 minutos', minutos: 10 }
    , { id: 3, texto: '15 minutos', minutos: 15 }, { id: 4, texto: '20 minutos', minutos: 20 }, {
    id: 5,
    texto: '25 minutos',
    minutos: 25
  }, { id: 6, texto: '30 minutos', minutos: 30 }
    , { id: 7, texto: '35 minutos', minutos: 35 }, { id: 8, texto: '40 minutos', minutos: 40 }, {
    id: 5,
    texto: '45 minutos',
    minutos: 45
  }, { id: 9, texto: '50 minutos', minutos: 50 }
    , { id: 10, texto: '55 minutos', minutos: 55 }, { id: 11, texto: '1 hora', minutos: 60 }, {
    id: 12,
    texto: '1 hora 30 minutos',
    minutos: 90
  }, { id: 13, texto: '2 horas', minutos: 120 }
    , { id: 14, texto: '2 horas 30 minutos', minutos: 150 }, { id: 15, texto: '3 horas', minutos: 180 }, {
    id: 16,
    texto: '3 horas 30 minutos',
    minutos: 210
  }, { id: 17, texto: '4 horas', minutos: 240 }
    , { id: 18, texto: '4 horas 30 minutos', minutos: 270 }, { id: 19, texto: '5 horas', minutos: 300 }, {
    id: 20,
    texto: '5 horas 30 minutos',
    minutos: 330
  }, { id: 21, texto: '6 horas', minutos: 360 }
    , { id: 22, texto: '6 horas 30 minutos', minutos: 390 }, { id: 23, texto: '7 horas', minutos: 420 }, {
    id: 24,
    texto: '7 horas 30 minutos',
    minutos: 450
  }, { id: 25, texto: '8 horas', minutos: 480 }];


  //centros
  centrosSinAsignar = [];
  centrosAsignados = [];
  tipoCentros: any[] = [];
  //centros
  format = { add: 'Añadir', remove: 'Borrar', all: 'Todos', none: 'Ninguno', direction: DualListComponent.LTR, draggable: true, locale: 'es' };

  constructor( 
    private editInternetGroupService: EditTipoSesionService,
    private servicioAPI: ConectarApiService,
    private fb: FormBuilder,
    private translator: Translator,
    private cdRef: ChangeDetectorRef,
    private panelService: PanelService,
    private tokenService: ApiTokenService,
    private alertaService: AlertService,
    public globales: GlobalService,
    private httpErrorService: HttpErrorsService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private gsCentros: GlobalServicCentros

  ) { }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngOnInit(): void {
    this.loadCentros();
    this.initializeTranslate();
    this.initializeFormWithoutData();
    this.manageForm(this.pkId, this.mode)
    this.getIdiomas();
  }

  ngAfterViewInit() {

    // this.pkId="4"

  }

  async loadCentros() {
    await this.gsCentros.getCentros();
  }

  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  get formControls() {
    return this.editSinButacasForm.controls;
  }


  manageForm(pkId,mode){
   


    if(mode=="I"){
      this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
      this.centrosAsignados = this.gsCentros.daTiposCentros(this.sinbutacadata);
      this.sinbutacadata=this.editSinButacasForm.value;

      this.loading = false;
      this.valuedChanged()
      this.getIdiomas([], this.mode)
      this.getRecintos();
      this.getTipoSesion();

    }
    if(mode=="U"){
      this.recintoDisabled=true
    }
    if (pkId != null) {
      this.findSinButaca(pkId)
    }
  }



  findSinButaca(pkId) {
    let ClaveEmpresa = this.tokenService.getConfig('chClaveEmpresa')
    //let ProyectoOnLine= this.tokenService.getConfig('chProyectoOnLine')
    let ConexionEntorno = this.tokenService.getConfig('chEntornoConexion')
    this.editInternetGroupService.SinButacasPkId(pkId, ClaveEmpresa, ConexionEntorno).pipe(takeUntil(this.ngUnsubscribe)).subscribe(userApi => {
      // let user:User =  userApi["DatosResult"]["tipoUsuarios"][0];
      let sinbutacadata: SinButacas = userApi.DatosResult.Lista[0]
      if (sinbutacadata != null) {
        this.getArrayDias(sinbutacadata);
        this.changeValueForm(sinbutacadata)
        this.sinbutacadata = this.editSinButacasForm.value
        this.sinbutacadata.CentrosIds = sinbutacadata.CentrosIds;
        this.centrosSinAsignar = this.gsCentros.filtrarTiposCentros();
        this.centrosAsignados = this.gsCentros.daTiposCentros(this.sinbutacadata);
      }
      this.loading = false;
      this.getRecintos();
      this.getTipoSesion();

      //this.getIdiomas(tiposesiondata.Idiomas,this.mode)
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )

  }

  /*
  initializeFormWithData(ts:TiposSesion){
    
    this.editSinButacasForm= this.fb.group({
      pkId:[ts.pkId],
      Recinto:[ts.Recinto,Validators.required],
      Descripcion:[ts.Descripcion,Validators.required],
      CodIdioma:[ts.CodIdioma],
      AforoMaximo:[ts.AforoMaximo,[Validators.required, Validators.maxLength(6), this.RangoValidador_Aforo(0, parseInt(this.Recintos))]],
      MinimoAforo:[ts.MinimoAforo,Validators.required],
      MaximoInternet:[ts.MaximoInternet],
      Intervalo:[ts.Intervalo,Validators.required],
      NumeroGrupos:[ts.NumeroGrupos],
      HoraInicio:[ts.HoraInicio,Validators.required],
      HoraFin:[ts.HoraFin,Validators.required],
      FechaInicio:[ts.FechaInicio, Validators.required],
      FechaFin:[ts.FechaFin,Validators.required],
      tipoControlDias:[this.arraydias],
      Butacas:[this.string01ToBoolean(ts.Butacas)],
      EnvioExternos:[this.string01ToBoolean(ts.EnvioExternos)],
      Evento:[this.string01ToBoolean(ts.Evento)],
      haygrupos:[ts.NumeroGrupos != "0" ? true : false]

    });
  }
  */

  changeValueForm(sb: SinButacas) {

    let checkgrupos = false;
    let controlahorario = 0;
    if (sb.ControlaHorario != "0") {
      checkgrupos = true;
      controlahorario = parseInt(sb.ControlaHorario) - 1;

    }

    this.formControls["pkId"].setValue(sb.pkId);
    this.formControls["Recinto"].setValue(sb.Recinto)
    this.formControls["Descripcion"].setValue(sb.Descripcion)
    this.formControls["CodIdioma"].setValue(sb.CodIdioma)
    this.formControls["MaximoAforo"].setValue(sb.MaximoAforo)
    this.formControls["LimiteAforo"].setValue(sb.LimiteAforo)
    this.formControls["MaximoInternet"].setValue(sb.MaximoInternet)
    this.formControls["MaximoGrupos"].setValue(sb.MaximoGrupos)
    this.formControls["TipoSesion"].setValue(sb.TipoSesion)
    //this.formControls["ControlaHorario"].setValue(controlahorario)
    //this.formControls["Intervalo"].setValue(sb.Intervalo)
    this.formControls["HoraInicio"].setValue(this.globales.anadirCeroHoraLeft(sb.HoraInicio.substring(10, 20).trim()))
    this.formControls["HoraFin"].setValue(this.globales.anadirCeroHoraLeft(sb.HoraFin.substring(10, 20).trim()))
    this.formControls["Fecha"].setValue(this.globales.aFecha(sb.Fecha))
    this.formControls["FechaHasta"].setValue(this.globales.aFecha(sb.Fecha))
    this.dataDesde = this.globales.aFecha(sb.Fecha);
    this.dataHasta = this.globales.aFecha(sb.Fecha);


    //this.formControls["duracionhora"].setValue(Math.floor(parseInt(sb.Duracion) / 60))
    //this.formControls["duracionminuto"].setValue(parseInt(sb.Duracion) % 60)
    //this.formControls["tipoControlDias"].setValue(this.arraydias)

    this.formControls["Activa"].setValue(sb.Activa == "1" ? true : false)
    this.formControls["SesionBloqueada"].setValue(sb.SesionBloqueada == "1" ? true : false)
    this.formControls["Didactica"].setValue(sb.Didactica)
    this.formControls["haygrupos"].setValue(checkgrupos)

/*     this.LabelDisponibleOnlinetxt = this.showDisponible(sb).split('/')[1];
    this.LabelDisponibleTotaltxt = this.showDisponible(sb).split('/')[0]; */
    this.LabelDisponibleOnlinetxt = this.showDisponibleInternet(sb);
    this.LabelDisponibleTotaltxt = this.showDisponibleTaquilla(sb);
    this.LabelDisponibleGrupo = controlahorario.toString();
    this.AforoInicial = sb.AforoInicial;
  }
  initializeFormWithoutData() {

    this.editSinButacasForm = this.fb.group({
      pkId: [""],
      Recinto: [""],
      Descripcion: [""],
      CodIdioma: [""],
      MaximoAforo: [""],
      LimiteAforo: [""],
      MaximoInternet: [""],
      MaximoGrupos:[""],
      TipoSesion: [""],
      ControlaHorario: ["0"],
      HoraInicio: [""],
      HoraFin: ["", [this.isAfterDate("HoraInicio")]],
      Fecha: [""],
      FechaHasta: [""],
      //FechaFin:["", [Validators.required, this.isAfterDate("FechaInicio")]],
      //Intervalo:[""],
      //duracionhora:[""],
      //duracionminuto:[""],
      //tipoControlDias:[],
      Activa: [false],
      SesionBloqueada: [false],
      Didactica: [""],
      haygrupos: [false]



    });
  }

  get f() {
    return this.editSinButacasForm.controls;
  } //Editor

  string01ToBoolean(value) {

    if (typeof value !== "boolean") {

      if (value.toLowerCase() == "1") {
        value = true;
      } else {
        value = false;
      }
    }
    return value
  }

  getHoras(s) {
    return Math.floor(parseInt(s) / 60)
  }

  CambioRecintoSeleccionado(ev) {
    let recinto = this.Recintos.filter(f => f.pkId == ev.value);
    if (recinto) this.formControls["MaximoAforo"].setValidators([Validators.required, this.RangoValidador_Aforo(0, parseInt(recinto[0].MaximoAforo))]);
    this.getTipoSesion(recinto[0]);
  }

  getTipoReserva() {
    this.servicioAPI.getTiposReserva().subscribe(TiposReservas => {
      this.TiposReservas = TiposReservas.DatosResult.listaTipoReserva;
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  filtrarRecintosSinButacas(recintos: any[]) {
    if (recintos == null) {
      return [];
    } else {
      console.log("recintos", recintos);
      let respuesta = recintos["DatosResult"].ListaRecintos.filter(x => x.Butacas != '1');
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }

  cambiodeTipoSesion(e) {

    this.TipoSesionInfo = this.TiposSesion.find(f => f.Descripcion.trim() == e.srcElement.value.trim());
    let RecintoInfo = this.Recintos.find(f => f.pkId == this.TipoSesionInfo.Recinto);

    if (this.mode=="I") {
      this.recintoInput.selectString(RecintoInfo.Nombre);
      this.formControls["CodIdioma"].setValue(RecintoInfo.CodIdioma);
      this.formControls["Descripcion"].setValue(RecintoInfo.Descripcion);
      this.formControls["MaximoAforo"].setValue(RecintoInfo.MaximoAforo);
      this.formControls["MaximoGrupos"].setValue(RecintoInfo.MaximoGrupos);
    }

    //this.cdRef.detectChanges();
    console.log("CCC ", this.sinbutacadata.Recinto); 
  }

  cambiodeTipoSesionSelect(e) {

    this.TipoSesionInfo = this.TiposSesion.find(f => f.pkId == e.value);
    let RecintoInfo = this.Recintos.find(f => f.pkId == this.TipoSesionInfo.Recinto)
    if (this.mode=="I") {
      this.recintoInput.selectString(RecintoInfo.Nombre);
      this.formControls["CodIdioma"].setValue(RecintoInfo.CodIdioma);
      this.formControls["Descripcion"].setValue(RecintoInfo.Descripcion);
      this.formControls["MaximoAforo"].setValue(RecintoInfo.MaximoAforo);
      this.formControls["MaximoGrupos"].setValue(RecintoInfo.MaximoGrupos);
    }
    this.cdRef.detectChanges();

/*     if (this.TiposSesion) {
      this.Recintos.forEach(element => {
        if (element.Descripcion == e.srcElement.value) { 
          //this.RecintoInfo = element;
        }
      });
    } */

  }

  cambiodeRecinto(e) {
    this.RecintoInfo = null;
    if (this.Recintos) {
      this.Recintos.forEach(element => {
        if (element.Nombre == e.srcElement.value) { 
          this.RecintoInfo = element;
        }
      });
      console.log("this.RecintoInfo ",this.RecintoInfo);
      if (this.RecintoInfo) {
        this.TiposSessionOfRecinto = this.TiposSesion.filter(f => f.Recinto == this.RecintoInfo.pkId );
      }
      else {
        this.TiposSessionOfRecinto = this.TiposSesion;
      }

      console.log("Tipos de sesion filtrados", this.TiposSessionOfRecinto);


      this.tiposesionInput.initlist(this.TiposSessionOfRecinto);
      
      if (this.mode=="I") {
        //this.formControls["TipoSesion"].setValue(this.TipoSesionInfo.Recinto); RECARGAR
        this.formControls["MaximoAforo"].setValue(this.RecintoInfo.MaximoAforo);
        this.formControls["MaximoInternet"].setValue(this.RecintoInfo.MaximoInternet);
        this.formControls["LimiteAforo"].setValue(this.RecintoInfo.LimiteAforo);
      }


      switch (this.RecintoInfo.TipoAforo) {
        case "SF":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
            break;
        case "SI":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
            break;
        case "SD":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
            break;
        default:
      } 
    } 
  }

  cambiodeRecintoSelect(e) {
    console.log("SELECT: ",e, this.TiposSesion);
    if (this.Recintos) {
      this.Recintos.forEach(element => {
        if (element.pkId == e.value) { 
          this.RecintoInfo = element;
        }
      });


      if (this.mode=="I") {
        //this.formControls["TipoSesion"].setValue(this.TipoSesionInfo.Recinto); RECARGAR
        this.formControls["MaximoAforo"].setValue(this.RecintoInfo.MaximoAforo);
        this.formControls["MaximoInternet"].setValue(this.RecintoInfo.MaximoInternet);
        this.formControls["LimiteAforo"].setValue(this.RecintoInfo.LimiteAforo);
      }

      switch (this.RecintoInfo.TipoAforo) {
        case "SF":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
            break;
        case "SI":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
            break;
        case "SD":
            this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
            break;
        default:
      } 
    }
  }
  
  getRecintos() {
    this.servicioAPI.getRecintos().subscribe(recintos => {
      console.log(recintos)
      this.Recintos = this.filtrarRecintosSinButacas(recintos);
      
      console.log(this.Recintos)
      console.log(this.sinbutacadata.Recinto)
      this.Recintos.forEach(element => {
        if (element.pkId == this.sinbutacadata.Recinto) {
          this.RecintoInfo = element
          switch (this.RecintoInfo.TipoAforo) {
            case "SF":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesFijas;
                break;
            case "SI":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesIrregulares;
                break;
            case "SD":
                this.RecintoInfo.TipoAforoDesc = this.translation.SesionesDiarias;
                break;
            default:
        }
        }
       
      });
      console.log("this.RecintoInfo", this.RecintoInfo)
      if(this.RecintoInfo){
        this.formControls["MaximoAforo"].setValidators([Validators.required, this.RangoValidador_Aforo(0, parseInt(this.RecintoInfo.MaximoAforo))]);
        
      }
      
    })
  }
  

  onDate(e,modo) {
    this.f["pkId"].setValue("");
    
    if (this.mode=='U') {
      // Cuando cambia una fecha y las fechas son diferentes recuperar hora inicio original de la carga
      // si vuelve a las fecha inciales desbloquear campo horainiciodisable
      if (modo == "desde") this.dataDesde = e["value"].toDate(); 
      if (modo == "hasta") this.dataHasta = e["value"].toDate(); 


      let dataDesdeSrc = new Date(this.sinbutacadata.Fecha);
      let dataHastaSrc = new Date(this.sinbutacadata.FechaHasta);

      if (this.dataDesde.getTime() != dataDesdeSrc.getTime() || this.dataHasta.getTime() != dataHastaSrc.getTime()) {
        this.f["HoraInicio"].setValue(this.sinbutacadata.HoraInicio);
        this.horainiciodisable = true;
      }

      if (this.dataDesde.getTime() == dataDesdeSrc.getTime() && this.dataHasta.getTime() == dataHastaSrc.getTime()) {
        this.horainiciodisable = false;
      }

      /*
      Si ((fecha inicio <> fecha inicio original) or (fecha fin<> fecha fin original))  
      horainicio = this.sinbutacadata.HoraInicio
      horainicio bloqueado 

      Si ((fecha inicio = fecha inicio original) and (fecha fin= fecha fin original))  
      horainicio desbloqueado
      */

      // 


  
      // caundo se bloquea vuelvo a valor inicial
    }
    console.log("horainiciodisable : ",this.f["pkId"],e);
  }

  filtrarTiposDeSessionSinButacas(tipossesion: any[]) {
    if (tipossesion == null) {
      return [];
    } else {
      console.log("recintos", tipossesion);
      let respuesta = tipossesion.filter(x => x.Butacas != '1');
      return respuesta; //== null || respuesta.length == 0? null: respuesta;
    }
  }
  getTipoSesion(recinto?) {
    let filter = [];
    console.log("tiposessio: ", recinto);
    if (recinto) filter = [{ value: recinto.pkId, name: "Recinto" }];
    this.servicioAPI.getTiposSesion(null, filter).subscribe(ts => {
      console.log("tiposession1: ",ts.DatosResult.Lista )
      this.TiposSesion = this.filtrarTiposDeSessionSinButacas(ts.DatosResult.Lista);
      this.TiposSessionOfRecinto = this.TiposSesion;
      console.log("tiposession: ",this.TiposSesion )
      this.cdRef.detectChanges();
    },
      error => { this.httpErrorService.identificarErrores(error); })
  }


  submit() {

    this.editSinButacasForm.markAllAsTouched()
    // let client:Cliente=this.editorRegistroForm.value;
    if (this.editSinButacasForm.valid) {
      let sinbutacas: SinButacas = this.editSinButacasForm.value;
      this.manageApi(sinbutacas, this.mode)
    }
  }

  destroy() {
    this.panelService.setDiscardChanges(true)
    this.loading = false;
    this.panelService.setClosePanel(null);
  }

  save(){
    this.loading = false;
    this.panelService.setSavePanel(null);
  }
  discard() {
    this.changeValueForm(this.sinbutacadata)
  }


  manageApi(sinbutacas: SinButacas, mode) {

    //tiposesion.Duracion = ((parseInt(tiposesion.duracionhora) * 60) + parseInt(tiposesion.duracionminuto)).toString() 

    if (this.formControls["Activa"].value == true)
      sinbutacas.Activa = "1"
    else
      sinbutacas.Activa = "0";

    if (this.formControls["SesionBloqueada"].value == true)
      sinbutacas.SesionBloqueada = "1"
    else
      sinbutacas.SesionBloqueada = "0";


    sinbutacas.Fecha = moment((this.editSinButacasForm.get('Fecha').value)).format('DD/MM/YYYY 0:00:00')
    sinbutacas.FechaHasta = moment((this.editSinButacasForm.get('FechaHasta').value)).format('DD/MM/YYYY 0:00:00')
    let numerodegrupos = parseInt(this.formControls["MaximoGrupos"].value) + 1;

    if (!this.formControls["haygrupos"].value) numerodegrupos = 0;
    sinbutacas.ControlaHorario = numerodegrupos.toString();
    sinbutacas.CentrosIds = this.gsCentros.obtenercentrosAsociadosconOrden(this.centrosAsignados);

    if (!sinbutacas.CodIdioma) sinbutacas.CodIdioma="";

    this.loading = true;
    switch (mode) {
      case "I":
        console.log("Insert")
        //insert
        sinbutacas.pkId = "0"
        this.InsSinButaca(sinbutacas)
        break;
      case "U":
        console.log("update")
        //update
        this.UpdSinButaca(sinbutacas)

        break;
      case "D":
        //duplicate
        console.log("duplicate")
        sinbutacas.pkId = "0"
        this.InsSinButaca(sinbutacas)

        break;

      default:
        break;
    }
  }

  UpdSinButaca(sinbutaca){
    const dialogRef = this.dialog.open(ApiWaitComponent, {
      width: '650px',
      disableClose: true,
      data: {
        mensaje: this.translation.apiwait,
      }
    });

    this.editInternetGroupService.insUpdSinButaca(sinbutaca,"U").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response=>{
      if (response.DatosResult != null) {
        this.save()
        this.destroy()
      }
      else {
        //this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
        //this.destroy()
        this.loading = false;
        let snack = this._snackBar.open(this.translation[response.Mensajes[0].CodigoMensaje], null, {
          duration: 4000,
          //panelClass: ['blue-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      dialogRef.close();
    },
      error => { this.httpErrorService.identificarErrores(error); dialogRef.close();}
    )

  }

  InsSinButaca(sinbutaca) {

    const dialogRef = this.dialog.open(ApiWaitComponent, {
      width: '650px',
      disableClose: true,
      data: {
        mensaje: this.translation.apiwait,
      }
    });

    this.editInternetGroupService.insUpdSinButaca(sinbutaca, "I").pipe(takeUntil(this.ngUnsubscribe)).subscribe(response => {
      if (response.DatosResult != null) {
        this.save()
        this.destroy()
      }
      else {
        //this.alertaService.error(response.Mensajes[0].DescripcionMensaje);
        //this.destroy()
        this.loading = false;
        let snack = this._snackBar.open(this.translation[response.Mensajes[0].CodigoMensaje], null, {
          duration: 4000,
          //panelClass: ['blue-snackbar'],
          horizontalPosition: 'center',
          verticalPosition: 'top'
        });
      }
      dialogRef.close();

    },
      error => { this.httpErrorService.identificarErrores(error); dialogRef.close(); }
    )

  }

  getIdiomas(idiomasgrupo?, mode?) {
    this.servicioAPI.getIdiomas().subscribe(losidiomas => {
      this.idiomasApp = losidiomas.DatosResult.ListaIdiomas;
      if (mode) {
        losidiomas.DatosResult.ListaIdiomas.map(idioma => {
          idiomasgrupo.forEach(ig => {
            if (ig.CodIdioma == idioma.CodIdioma) {
              let elidioma: IdiomaGR = new IdiomaGR();
              elidioma.CodIdioma = ig.CodIdioma;
              elidioma.Nombre = ig.Nombre;
              elidioma.Descripcion = ig.Descripcion;
              this.editorListaIdiomas.push(elidioma);
            }
          })

        });
        this.sinbutacadata = this.editSinButacasForm.value
        this.valuedChanged();
      }
    },
      error => { this.httpErrorService.identificarErrores(error); }
    )
  }

  getFechaInicio(): Date {
    //let split = this.model.FechaInicio.split(' ');
    //split = split[0].split('/');
    // return this.aFecha(this.model.FechaInicio);
    //if (this.editSinButacasForm.get('FechaInicio')) 
    return this.editSinButacasForm.get('FechaInicio').value
    //else 
    //  return null;
  }

  getFechaFin(): Date {
    // return this.aFecha(this.model.FechaFin);
    //if (this.editSinButacasForm.get('fechaHasta')) 
    return this.editSinButacasForm.get('FechaFin').value
    //else 
    //  return null;
  }


  cambiaHoradesde(evento) {
    //
    //this.objedicionCalendarioHorario.FechaAcceso = evento.target.value + ':00';
  }

  cambiaHorahasta(evento) {
    //
    //this.objedicionCalendarioHorario.FechaAccesoFin = evento.target.value + ':00';
  }

  //Para que funcione le mat select cuando al option le metes objetos en lugar de strings simples
  comparaObjetosSelect(o1: any, o2: any) {
    return o1 && o2 ? o1.id === o2.id || o1 === o2.id || o1.id === o2 : o1 === o2;
  }

  cambiarDuracionDisabled(evento) {

    if (evento.value !== '0') {
      this.editSinButacasForm.get('duracionhora').enable();
      this.editSinButacasForm.get('duracionminuto').enable();
    } else {
      this.editSinButacasForm.get('duracionhora').disable();
      this.editSinButacasForm.get('duracionminuto').disable();
    }
  }

  getArrayDias(ts) {

    this.arraydias = [];
    if (ts.Lunes == "1") this.arraydias.push(this.globales.diasSemana[0]);
    if (ts.Martes == "1") this.arraydias.push(this.globales.diasSemana[1]);
    if (ts.Miercoles == "1") this.arraydias.push(this.globales.diasSemana[2]);
    if (ts.Jueves == "1") this.arraydias.push(this.globales.diasSemana[3]);
    if (ts.Viernes == "1") this.arraydias.push(this.globales.diasSemana[4]);
    if (ts.Sabado == "1") this.arraydias.push(this.globales.diasSemana[5]);
    if (ts.Domingo == "1") this.arraydias.push(this.globales.diasSemana[6]);

  }

  CambioDiasSemanaMultiple(evento) {
    //
    //En principio no lo vamos a usar
    this.arraydias = evento.value;
    console.log("evento:", evento, this.arraydias);
  }
  actualizarTipoSesion(ev) {

  }

  showDisponible(item): string //Sesion
  {
    //
    //let respuesta: string = '';
    let maxInternet: number = Number(item.MaximoInternet) || 0;
    let maxTotal: number = Number(item.MaximoAforo) || 0;
    let numVendidasInet: number = Number(item.VendidasInternet) || 0;
    let numVendidasTaquilla: number = Number(item.Vendidas) || 0;
    let numReservadasInet: number = Number(item.Reservadas) || 0;
    let online = maxInternet - numVendidasInet - numReservadasInet;
    let total = maxTotal - numVendidasInet - numReservadasInet - numVendidasTaquilla;
    return (total != null ? total.toString() : '') + ' / ' + (online != null ? online.toString() : '');
  }

  ///Validadores



  RangoValidador_AforoMaximo(): ValidatorFn {

    if (this.Recintos && this.editSinButacasForm) {
      this.RecintoInfo = this.Recintos.filter(f => f.pkId == this.editSinButacasForm["Recinto"].value);
      console.log("RecintoInfo: ", this.RecintoInfo);
      let maxaforo = 10000;
      return (control: AbstractControl): { [key: string]: boolean } | null => {

        if (parseInt(this.editSinButacasForm["MaximoAforo"].value) > maxaforo) {

          return { 'noenRango': true };

        }
        return null;
      };
    }
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      return null;
    }

  }

  RangoValidador_Aforo(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }
      return null;
    };

  }

  RangoValidador_AforoInternet(min: number, max: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  RangoValidador_Hora(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      let minutos = control.value !== undefined ? (parseInt(control.value.toString().split(':')[0]) * 60) + parseInt(control.value.toString().split(':')[1]) : 0;

      if (control.value !== undefined && (min !== max) && (control.value.trim() == '' || minutos < min || minutos > max)) {

        return { 'noenRango': true };

      }

      return null;

    };

  }

  ///Validadores
  isAfterDate(inputCheck): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      console.log(control)
      // if (control.value !== undefined && (isNaN(control.value) || control.value < min || control.value > max)) {
      if (control.value !== undefined) {
        console.log(control.value)
        if (moment(control.value, "h:mm:ss").isValid()) {
          let momentValue = moment(control.value, "h:mm:ss")
          console.log("Valid", control.value)
          let fechaDesde = this.editSinButacasForm.get(inputCheck).value
          if (moment(fechaDesde, "h:mm:ss").isValid()) {
            if (moment(fechaDesde, "h:mm:ss").isAfter(momentValue)) {
              return { 'afterDate': true };
            }
          }
        }

      }
      return null;
    };
  }
  reloadVal(input) {
    console.log("------------------------------", input)
    // this.clearValidators(input);
    //this.editorRegistroForm.get(input).setValidators([Validators.required,this.isAfterDate]);
    this.editSinButacasForm.get(input).updateValueAndValidity();
  }

  //End Validadores

  valuedChanged() {
    this.editSinButacasForm.valueChanges.subscribe(value => {
      if (this.sinbutacadata != null) {
        this.panelService.setDiscardChanges(false)
        if (JSON.stringify(value) === JSON.stringify(this.sinbutacadata)) {
          this.panelService.setDiscardChanges(true)


        }
      }
    })
  }

 /*  insertDataInForm() {
    this.editSinButacasForm.get("Recinto").setValue((<HTMLInputElement>document.getElementById("Recinto-hidden")).value)

  }
  insertDataInObj() {
    this.sinbutacadata.Recinto = (<HTMLInputElement>document.getElementById("Recinto-hidden")).value

  } */
  closeInputFilter(enventValues) {
    console.log("XXXX:",enventValues, this.TiposSesion, this.sinbutacadata)
    //this.insertDataInForm()
    this.editSinButacasForm.get(enventValues.name).setValue(enventValues.value);
    
    // this.f[enventValues.name].value == enventValues.value ? this.panelService.setDiscardChanges(true):this.panelService.setDiscardChanges(false)

  }

  showDisponibleTaquilla(item): string
  {
    let MaximoAforo = Number(item.MaximoAforo);
    let Vendidas = Number(item.Vendidas);
    let VendidasAdmon = Number(item.VendidasAdmon);
    return (MaximoAforo - (Vendidas + VendidasAdmon)).toString();
  }

  showDisponibleInternet(item): string
  {
    let MaximoInternet = Number(item.MaximoInternet);
    let VendidasInternet = Number(item.VendidasInternet);
    return (MaximoInternet - VendidasInternet).toString();
  }
  
/*   showDisponibleGrupo(item): string
  {
    let ControlaHorario = Number(item.ControlaHorario);
    
    if (ControlaHorario>0) 
      ControlaHorario = ControlaHorario - 1;
    else 
      ControlaHorario = 0;

    return ControlaHorario.toString();
  } */

}