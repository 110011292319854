import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PanelService } from '@sharedV11/services/panel/panel.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EditEntryTypeComponent } from './edit-entry-type/edit-entry-type.component';

@Component({
  selector: 'app-entry-type',
  templateUrl: './entry-type.component.html',
  styleUrls: ['./entry-type.component.scss']
})
export class EntryTypeComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  customanpanel: any;
  addbut;
  
  constructor(
    private panelService: PanelService,
    private cdRef: ChangeDetectorRef,

  ) { }

  ngOnInit(): void {
    this.closepanel()

  }

 

  openPanel(){
    this.customanpanel=EditEntryTypeComponent;
    this.editChangeCss()
    this.cdRef.detectChanges();

  }


  closepanel() {
    this.panelService.closePanel$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(close=>{
      console.log()
      this.customanpanel = undefined;
     this.closePanelChangeCss()
    })
    
  }

  editChangeCss(){
    let classElem;
    classElem= document.getElementsByClassName("component")
       for (let i = 0; i < classElem.length; i++) {
        classElem[i].style.display = "none"
         
       }
    /* document.getElementsByTagName("content")[0].classList.remove('mat-drawer-content');
    document.getElementsByTagName("mat-sidenav-content")[0].classList.add('mat-drawer-contentModify'); */
  }
  closePanelChangeCss(){
    /* document.getElementsByTagName("mat-sidenav-content")[0].classList.remove('mat-drawer-contentModify');
    document.getElementsByTagName("mat-sidenav-content")[0].classList.add('mat-drawer-content'); */
    let classElem ;

    classElem = document.getElementsByClassName("component")
    for (let i = 0; i < classElem.length; i++) {
     classElem[i].style.display = "block"
      
    }

  }


}
