import { ThrowStmt } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';


//https://stackoverflow.com/questions/14379274/how-to-iterate-over-a-javascript-object

@Component({
  selector: 'app-json2control',
  templateUrl: './json2control.component.html',
  styleUrls: ['./json2control.component.scss']
})

export class JSON2ControlComponent implements OnInit, AfterViewInit {

  @Input() StringJSON: string;
  @Input() contextHelp: string = "";
  @Input() textHeader: string = "";
  @Input() mayremove: boolean = true;
  @Input() mayadd: boolean = true;
  @Input() config: Array<Object> = [];

  strjson;
  controls = [];

  jsonFormGroup: FormGroup;
  subscribedValidity="";
  
  constructor(private _cds: ChangeDetectorRef) {

   
  }

  ngOnInit() {
    this.jsonFormGroup = new FormGroup({});
    this.refresh();

    this.jsonFormGroup.statusChanges.subscribe((val) => {
      console.log("subscribedValidity ", val);
      this.subscribedValidity = val;
    });

  }

  ngAfterViewInit() {
    
  }

  setJSON(strsjon) {
    this.StringJSON  = strsjon;
    this.refresh();
  }

  refresh() {
    
    if (this.StringJSON!='') {
    this.removeControls();
    this.strjson = JSON.parse(this.StringJSON);
  

      for (let key in this.strjson) {
        if (this.strjson.hasOwnProperty(key)) {
          let configobj = this.lookForName(key);
          let controlstr = '{"name":"'+key+'","value":"'+this.strjson[key]+'","title":"'+configobj["title"]+'","help":"'+configobj["help"]+'","type":"'+configobj["type"]+'"}'; 
          this.controls.push(JSON.parse(controlstr));
          console.log(key, this.strjson[key],controlstr,this.controls);
          this.jsonFormGroup.addControl("name_"+key,new FormControl(key, [Validators.required],this.checkValidField));
          this.jsonFormGroup.addControl("title_"+key,new FormControl(configobj["title"], [Validators.required],this.checkValidField));
          this.jsonFormGroup.addControl("value_"+key,new FormControl(this.strjson[key], [Validators.required],this.checkValidField));
        }
        
    }
    this.subscribedValidity = "VALID";
    //this._cds.detectChanges();

    }
  }

  get formControls() {
    return this.jsonFormGroup.controls;
  }

  checkValidField(control: AbstractControl) {
    return new Promise((resolve, reject) => {
      //setTimeout(() => {
        if (control.value === '') {
            resolve(true )
        } else {resolve(null)}
      //}, 2000)
    })
}

  public findInvalidControls() {
    //const invalid = [];
    let invalid = false;
    const controls = this.formControls.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            //invalid.push(name);
            invalid = true;
            console.log("invalid ",name,controls[name]);
        }
    }
    return invalid;
}

  lookForName(key) {
    let retobj = {};
    retobj = this.config.find(e => e["atributo"] == key);
    console.log("RTE2: ",retobj);
    let title ="";
    let help = "";
    let type = "text";
    if (retobj) {
      if (retobj["title"]) title = retobj["title"];
      if (retobj["help"]) help = retobj["help"];
      if (retobj["type"]) type = retobj["type"];
    }
    return {"title":title,"help":help,"type":type};
  }

  removeControls() {
    console.log("Controls: ", this.jsonFormGroup.controls);
    this.strjson = JSON.parse(this.StringJSON);
    for (let key in this.strjson) {
      this.jsonFormGroup.removeControl("name_"+key);
      this.jsonFormGroup.removeControl("value_"+key);
    }
    this.controls.splice(0, this.controls.length);
  }

  deleteItem(thename) {

    console.log("thename: ", thename,this.jsonFormGroup.controls);
    this.jsonFormGroup.removeControl("name_"+thename);
    this.jsonFormGroup.removeControl("title_"+thename);
    this.jsonFormGroup.removeControl("value_"+thename);

    var elem = this.controls.find((i)=>i.name == thename);
    this.controls.splice(this.controls.indexOf(elem), 1)

  }

  addElement() {
    this.refresh();
    let key = "campo";
    let controlstr = '{"name":"'+key+'","value":"'+key+'","title":"","help":"","type":"text"}'; 
    this.controls.push(JSON.parse(controlstr));
    this.jsonFormGroup.addControl("name_"+key,new FormControl("", [Validators.required],this.checkValidField));
    this.jsonFormGroup.addControl("title_"+key,new FormControl("Titulo_ "+key, [Validators.required],this.checkValidField));
    this.jsonFormGroup.addControl("value_"+key,new FormControl("", [Validators.required],this.checkValidField));
    this.subscribedValidity = "INVALID";
  }

  getJSON() {
    console.log("Controls:", this.jsonFormGroup);
    let strout = '{';
    let n= "";
    let elements = this.controls.length;
    let i = 0;
    this.controls.forEach(c => {
      n = c.name;
      strout += '"'+this.jsonFormGroup.controls['name_'+n].value+'":"'+this.jsonFormGroup.controls['value_'+n].value+'"';
      //strout += '\u0022'+this.jsonFormGroup.controls['name_'+n].value+'\u0022:\u0022'+this.jsonFormGroup.controls['value_'+n].value+'\u0022';
      // https://www.htmlsymbols.xyz/unicode/U+0022
      
      //strout += '\\\"'+this.jsonFormGroup.controls['name_'+n].value+'\\\":\\\"'+this.jsonFormGroup.controls['value_'+n].value+'\\\"';
      
      //strout += String.fromCharCode(34)+this.jsonFormGroup.controls['name_'+n].value+String.fromCharCode(34)+':'+String.fromCharCode(34)+this.jsonFormGroup.controls['value_'+n].value+String.fromCharCode(34); // "
      //strout += String.fromCharCode(39)+this.jsonFormGroup.controls['name_'+n].value+String.fromCharCode(39)+':'+String.fromCharCode(39)+this.jsonFormGroup.controls['value_'+n].value+String.fromCharCode(39); // '
      //strout += "'"+this.jsonFormGroup.controls['name_'+n].value+"':'"+this.jsonFormGroup.controls['value_'+n].value+"'";
      i++;
      if (i<elements) strout +=',';
    });
    strout += '}';
    console.log("STROut: ",strout);
    //return JSON.parse(strout);
    return strout;
  }

  
}
