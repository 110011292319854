import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PanelService {

  private closePanelSubject: BehaviorSubject<any>;
  public closePanel$: Observable<any>;
 
  private savePanelSubject: BehaviorSubject<any>;
  public savePanel$: Observable<any>;
  
  private discardChangesSubject: BehaviorSubject<any>;
  public discardChanges$: Observable<any>;
  constructor() {
    this.inicitializeSubjectsAndObservable();
   }


  inicitializeSubjectsAndObservable(){
    
    this.closePanelSubject = new BehaviorSubject<any>(null);
    this.closePanel$ = this.closePanelSubject.asObservable();
  

    this.savePanelSubject = new BehaviorSubject<any>(null);
    this.savePanel$ = this.savePanelSubject.asObservable();
  

    this.discardChangesSubject = new BehaviorSubject<any>(true);
    this.discardChanges$ = this.discardChangesSubject.asObservable();
    }

    get discardChangesValue() {
      return this.discardChangesSubject.value;
    }
  
    public setDiscardChanges(value) {
        this.discardChangesSubject.next(value);
    }

    get savePanelValue() {
      return this.savePanelSubject.value;
    }
  
    public setSavePanel(closePanel) {
        this.savePanelSubject.next(closePanel);
    }
    get closePanelValue() {
      return this.closePanelSubject.value;
    }
  
    public setClosePanel(closePanel) {
        this.closePanelSubject.next(closePanel);
    }

}
