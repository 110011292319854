<h1 mat-dialog-title>{{data.titulo}}</h1>
<div mat-dialog-content>
  <p>{{data.texto}}</p>
  <!-- <mat-form-field>
    <input matInput [(ngModel)]="data.eleccion">
  </mat-form-field> -->
</div>
<!-- <div mat-dialog-actions class="btn-group pull-right" role="group">
  <button mat-button  (click)="onNoClick()" class="btn btn-danger">{{data.ko}}</button>
  <button mat-button  (click)="onSiClick()" class="btn btn-success" cdkFocusInitial>{{data.ok}}</button>
</div> -->
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{data.ko}}</button>
  <button mat-button (click)="onSiClick()" cdkFocusInitial>{{data.ok}}</button>
</div>
