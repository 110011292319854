import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiTokenService } from '@sharedV11/services/api/token/api-token.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { GlobalService } from '@sharedV11/services/global/global.service';
import { catchError, map } from 'rxjs/operators';
import { Request_userTypes_list } from '@sharedV11/classes/userRequest/users/userProfile/request_userTypes_list';
import { UserProfile } from '@sharedV11/classes/users/userProfile/userProfile';
import { Request_insertion_upd_userTypes } from '@sharedV11/classes/userRequest/users/userProfile/request_insertion_upd_userTypes';
import { Request_internetGroupos_list } from '@sharedV11/classes/tarifas/request_internetGroups_list';
import * as data from "@sharedV11/jsons/tarifas/internetgroup.json"
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type' : 'application/json'
  })
}
@Injectable({
  providedIn: 'root'
})
export class EditGruposTipoEntradaService {
  private extractData(res: Response){
    let body = res;
    return body || {};
  }
  constructor(
    private http : HttpClient,
    private tokenService: ApiTokenService,
    private global: GlobalService,
  ) { }
  findGrupoEntradaByPkId(pkId:string): Observable<any> {
    let peticion: any = {}
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.pkId =pkId;
    
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ObtenerCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),catchError(this.handleError<any>('getTiposUsuario'))
    )
    //return of(data["default"])

  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  insGruposTipoEntrada(grupoTipoEntrada,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = grupoTipoEntrada;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarCustomanGrupoTipoEntrada'))
    );
  }
  updGruposTipoEntrada(grupoTipoEntrada,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = grupoTipoEntrada;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/ActualizarCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('ActualizarCustomanGrupoTipoEntrada'))
    );
  }
  insUpdGruposTipoEntrada(grupoTipoEntrada,mode): Observable<any> {
    //Montamos la peticion insercion/Update
    let peticion = grupoTipoEntrada;
    peticion.clienteAPI = this.global.clienteapi; //TODO
    peticion.Funcion = mode;
    //Enviamos la peticion
    return this.http.post<any>(this.tokenService.getConfig('API_URL') + 'Customan/InsertarActualizarCustomanGrupoTipoEntrada', JSON.stringify(peticion), httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError<any>('InsertarActualizarCustomanGrupoTipoEntrada'))
    );
  }
}
