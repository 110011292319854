<!-- <svg></svg>
<div
  (window:resize)="onResize($event)"
></div> -->


<div id="container">
  <section id="subdiv">

    <div *ngIf="contextual" class="d-flex justify-content-right align-items-center" id="contextualzone">
      <div class="btn-map2" style="cursor: pointer;" >
<!--         <span class="button right">
        <i class="fa fa-bars" aria-hidden="true"></i>
        </span> -->
        <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color">
          <mat-button-toggle value="red" id="popuprender">              
          <mat-icon>menu</mat-icon>
          </mat-button-toggle>
            <mat-button-toggle value="red" id="popuprendermenos">              
                <mat-icon>zoom_out</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="green"  id="popuprendermas">
                <mat-icon>zoom_in</mat-icon>  
            </mat-button-toggle>
         </mat-button-toggle-group>
      </div>
    </div>

<!--     <div class="miniMapa">
      <iacpos-minimapa-recinto
        [nombreRecinto]="nombreEstadio"
        (eventMinimapTransformation)="eventMinimapTransformation($event)"
        [mapaZoomTransformation]="cambiosMapa"
      >
      </iacpos-minimapa-recinto>
    </div> -->
    
  </section>
  <svg></svg>
<!--   <ul class='custom-menu' id="container-menu">
    <li data-action="load">Load Schema</li>
    <li data-action="clear">Clear container</li>
  </ul> -->
</div>
<div
  (window:resize)="onResize($event)"
></div>