<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<div [ngClass]="{zonaoculta: loading===true}">
<app-buttons (save)="submit()" (close)="destroy()" (discard)="discard()" [buttonsWrap]="'panel'" [modificar]="modificar"></app-buttons>
<div class="divForm">
    <form [formGroup]="editGruposRecintosForm" (onSubmit)="submit()">
        <div class="form-group">
            <mat-form-field appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="Nombre">
                <mat-error *ngIf="validateFields('Nombre')">
                    {{getErrorMessage("Nombre")}}
                </mat-error>
            </mat-form-field>

            <app-input-filter *ngIf="grupoRecinto && centros.length>0" formControlText="CentroId" [label]="translation.Centro"
                  [placeHolderTranslation]="translation.Centro" [list]="centros" [disabled]="centros.length<1" textShow="Nombre"
                  dataInput="pkId" [data]="grupoRecinto.CentroId" required="true" (close)="closeInputFilter($event)"></app-input-filter>

           <!--  <mat-form-field appearance="outline">
                <mat-label>Centro</mat-label>
                <mat-select formControlName="CentroId">
                    <mat-option *ngFor="let centro of centros" [value]="centro.pkId">
                        {{centro.Nombre}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="validateFields('CentroId')">
                    {{getErrorMessage("CentroId")}}
                </mat-error>
            </mat-form-field> -->
        </div>
        <div class="form-group">
            <mat-label>Activo <mat-slide-toggle #conectador formControlName="Activo"></mat-slide-toggle>
            </mat-label>

            <mat-label>SubGrupo <mat-slide-toggle formControlName="subGrupo"></mat-slide-toggle>
            </mat-label>
        </div>

        <div style="margin: 20px 5px 0 5px; text-align: center;">
            <mat-label>{{translation.Centros}}</mat-label>
      
            <customan-dual-list [source]="centrosSinAsignar"
            [(destination)]="centrosAsignados" key="pkId" display="Nombre" [format]="format" [filter]='true'>
            </customan-dual-list>
        </div>

<!--         <mat-card>
            <mat-card-header>
                <mat-card-title>{{translation.NombreInternet}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div *ngFor="let idioma of idiomas">
                    <div class="form-group">
                        <mat-form-field appearance="outline">
                            <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                            <label>
                                <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                                    [value]="idioma.Nombre"
                                    
                                    formControlName="{{'Nombre-' + idioma.CodIdioma}}">
                            </label>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                            <label>
                                <input matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                                    [value]="idioma.Descripcion" formControlName="{{'Descripcion-' + idioma.CodIdioma}}"
                                    >
                            </label>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card-content>
        </mat-card> -->

        <!-- -->
        <h2>{{translation.NombreInternet}}</h2>
        <mat-accordion *ngFor="let idioma of idiomas; let i = index" >
            <mat-expansion-panel [expanded]="i==0">
                <mat-expansion-panel-header>
                <mat-panel-title>
                    {{idioma.Nombre}}
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
                </mat-expansion-panel-header>
            
                <mat-form-field appearance="outline">
                <mat-label>{{translation.Nombre}} ({{idioma.CodIdioma}})</mat-label>
                    <input matInput type="text" placeholder="{{translation.Nombre}} en {{idioma.Nombre}}"
                    [value]="idioma.Nombre" (change)="cambiaNombre($any($event.target).value, idioma.CodIdioma)"
                    formControlName="Nombre-{{idioma.CodIdioma}}">
                </mat-form-field>
    
                <mat-form-field appearance="outline">
                <mat-label>{{translation.Descripcion}} ({{idioma.CodIdioma}})</mat-label>
                    <textarea matInput type="text" placeholder="{{translation.Descripcion}} {{idioma.Nombre}}"
                    [value]="idioma.Descripcion" formControlName="Descripcion-{{idioma.CodIdioma}}"></textarea>
                </mat-form-field>
            
            </mat-expansion-panel>
        
            </mat-accordion>

        <!-- -->

    </form>
</div>
</div>