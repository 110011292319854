import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Translator } from '@sharedV11/services/translator/translator.service';
import { throwError } from 'rxjs';
import { SnackbarInfoService } from '../snackbar-info/snackbar-info.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorsService {

  translation: any;

  constructor(private snackvar_service: SnackbarInfoService,
    private translator: Translator) {
    this.initializeTranslate();
  }

  private initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  identificarErrores(error: HttpErrorResponse) {
    console.log("El valor de error.status es : ", error.status)
    if (error.status === 0) {
      this.snackvar_service.openSnackBar(this.translation.ErrorConexion, '', 'red-snackbar');
    } else {
      switch (error.status) {
        //Errores de cliente
        case 400:
          this.snackvar_service.openSnackBar(this.translation.Error400, '', 'red-snackbar');
          break;
        case 401:
          this.snackvar_service.openSnackBar(this.translation.Error401, '', 'red-snackbar');
          break;
        case 403:
          this.snackvar_service.openSnackBar(this.translation.Error403, '', 'red-snackbar');
          break;
        case 404:
          this.snackvar_service.openSnackBar(this.translation.Error404, '', 'red-snackbar');
          break;
        case 407:
          this.snackvar_service.openSnackBar(this.translation.Error407, '', 'red-snackbar');
          break;
        case 408:
          this.snackvar_service.openSnackBar(this.translation.Error408, '', 'red-snackbar');
          break;
        //Errores de servidor
        case 500:
          this.snackvar_service.openSnackBar(this.translation.Error500, '', 'red-snackbar');
          break;
        case 502:
          this.snackvar_service.openSnackBar(this.translation.Error502, '', 'red-snackbar');
          break;
        case 503:
          this.snackvar_service.openSnackBar(this.translation.Error503, '', 'red-snackbar');
          break;
        case 504:
          this.snackvar_service.openSnackBar(this.translation.Error504, '', 'red-snackbar');
          break;

        default:
          this.snackvar_service.openSnackBar(this.translation.ErrorNoIdentificado + error.status, '', 'red-snackbar');
          break;
      }
    }
  }
}
