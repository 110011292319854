<app-buttons  (open)="openPanel()" [buttonsWrap]="'add'" [addbut]="addbut"></app-buttons>


<app-filter [tableName]="'sinbutaca'" ></app-filter>

<app-table  [tableName]="'sinbutaca'" (addbut)="addbut=$event"></app-table>

<app-panelcustoman 
*ngIf="customanpanel!==undefined" 
[ConfigPanel]="customanpanel"
[mode]="'I'" >
</app-panelcustoman> 