<h1 mat-dialog-title>¿Desea dar de baja el Cliente?</h1>
<div mat-dialog-content>
<!-- 
  <div class="grupo-editor-campo-registro">
    <label>Fecha de baja</label>
    <input type="date" [ngModel] ="fecha_baja | date:'yyyy-MM-dd'" (ngModelChange)="fecha_baja = $event">
  </div> -->

  <div class="grupo-editor-campo-registro">
    <label>Fecha de baja</label>
    <input type="date" [(ngModel)] ="fecha_baja">
  </div>
</div>
<div mat-dialog-actions>
  <button class="btn btn-magento-gris-claro"(click)="onNoClick()">CANCELAR</button>
  <button class="btn btn-magento-naranja" [mat-dialog-close]="fecha_baja" cdkFocusInitial>ACEPTAR</button>
</div>
