<div style="margin-top: 120px">
    <!-- Production False List-->
    <div  *ngIf="menuDevelopment.length!=0">
  <mat-accordion>
    <mat-expansion-panel *ngFor = "let item of menuDevelopment" class="menu-expansion-panel">
      <mat-expansion-panel-header class="disable_ripple">
         <mat-panel-title>
          {{item.name}}
         </mat-panel-title>
      </mat-expansion-panel-header>
      
      <mat-list role="list" *ngFor = "let subitem of item.subMenu">
        <mat-list-item (click)="clicksubmenu(subitem.route)" role="listitem">{{subitem.name}}</mat-list-item>
      </mat-list>
   </mat-expansion-panel>
  </mat-accordion> 
  <mat-divider color="accent"></mat-divider>
</div>

  <!-- Production True List-->
    <mat-accordion>
      <mat-expansion-panel *ngFor = "let item of Botones" class="menu-expansion-panel">
        <mat-expansion-panel-header class="disable_ripple" matRipple>
           <mat-panel-title>
            {{item.texto}}
           </mat-panel-title>
        </mat-expansion-panel-header>
        
        <mat-list role="list" *ngFor = "let subitem of item.submenu">
          <mat-list-item (click)="clicksubmenu(subitem.route)" role="listitem">{{subitem.texto}}</mat-list-item>
        </mat-list>
     </mat-expansion-panel>
    </mat-accordion> 
    </div>