import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { first } from 'rxjs/operators';
import { userResponse } from 'src/app/shared/classes/userResponse/user-response';
import { ApiTokenService } from 'src/app/shared/services/api/token/api-token.service';
import { ConectarApiService } from '@sharedV11/services/api/connection/conectar-api.service';
import { Translator } from 'src/app/shared/services/translator/translator.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication/authentication.service';
import { AlertService } from 'src/app/shared/services/alerts/alert/alert.service';
import { HttpErrorsService } from '@sharedV11/services/alerts/errors/http-errors.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { RecaptchaErrorParameters } from 'ng-recaptcha';
import { SnackbarInfoService } from '@sharedV11/services/alerts/snackbar-info/snackbar-info.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm!: FormGroup;
  translation: any;
  submitted = false;
  loading = false;
  loadingDivisas = false;
  usuarioenSession: userResponse;
  returnUrl: string;
  des:any =null;

  restorePassword = false;
  robot=false;
  vercodigoform= false;
  resolved_captcha = false;

  errores={
    "0":"EmailNoRegistrado",
    "-1":"MasUsuariosConEsteEmail",
    "-2":"IdentificacionIncorrecta",
    "-5":"NoPermisos"
  }
  Copyright = '';
  restore: boolean = false;
  tokenOrigen: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private servicioAPI: ConectarApiService,
    private alertaServicio: AlertService,
    private translator: Translator,
    private tokenService: ApiTokenService,
    private autentificador: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private httpErrorService: HttpErrorsService,
    public dialog: MatDialog,
    private snackbarInfoService:SnackbarInfoService
  ) {
    

   }

  ngOnInit(): void {
    this.loading = true;
    this.initializeForm();
    this.initializeTranslate()
    this.initializeRouteUrl()
    this.tokenService.traerToken().subscribe(tk => {
      this.loading = false;
      this.checkParams()
    });

  }
checkParams(){
  this.route.queryParams.subscribe(params => {
    let param = params["auth"];
   //let dataJson = '{"nombre":"jordi","contrasena":"Jordijordi2!","pkId":1,"ruta":"/Clientes/Cliente"}';

   let parametro = this.funcionDesencript("48D30A030B08160349494625362A2751094B515C5A474653470E04074649DE455F2C42066D95439A2D08445050545E4A4844141312120753101C775F5D575A1F0F154B30904F4A2E5741639B11850B0703042B1402140108445C44376E3968697F6A705C012A5B303618AB6565367B1172F959B20F0A37123C003D151428151F0019683956647E0779517E3303003641B44E7C0E16562F8470B80B2A2D530000240002332C0D5F46483970775E75640203043F2426348F404011574A36ED5987270D555E12113B2331293712072A66026A7E7C4545676D122312325181");
   //console.log(this.funcionEncript(dataJson))
    if(param){
       this.des=this.funcionDesencript(param)
       this.des =JSON.parse(this.des)

      console.log(this.des)
      this.returnUrl=this.des["ruta"];
      this.tokenOrigen=this.des["tokenOrigen"];
      this.setValueForm(this.des)
      this.onSubmit()
    }

  });
}

  setValueForm(des){
    this.loginForm.get("username").setValue(des["nombre"])
    this.loginForm.get("password").setValue(des["contrasena"])
  }

  initializeForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      codigosec: [''],
      email: ['']
    });
  }
  initializeTranslate() {
    this.translation = this.translator.GetTranslations();
  }

  initializeRouteUrl() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/portada';
   // console.log(this.returnUrl)
  }

  // un getter para facilitar el acceso a los campos del form
  get f() {
    return this.loginForm.controls;
  }
  changeLoggin(value){
    this.submitted = false;

    this.restorePassword = value
    this.changeValidations();
  }
  f_restorePassword(value) {
    this.changeLoggin(value)

  }
  changeValidations() {
    //console.log("valids")
    //console.log(this.restorePassword)
    if (this.restorePassword) {
      this.loginForm.get("email").setValidators([Validators.required]);
      this.loginForm.get("email").updateValueAndValidity();

      this.loginForm.get("password").clearValidators();
      this.loginForm.get("password").updateValueAndValidity();
    } else {
      this.loginForm.get("password").setValidators([Validators.required]);
      this.loginForm.get("password").updateValueAndValidity();

      this.loginForm.get("email").clearValidators();
      this.loginForm.get("email").updateValueAndValidity();
    }
  }

  submitRestorePassword() {
    this.submitted = true;

    if(this.loginForm.valid) {
      this.servicioAPI.restorePassword(this.loginForm.value).subscribe(data => {
          if(data["DatosResult"] && data["DatosResult"]["MensajeRespuesta"] == "true" ){
            this.changeLoggin(false);
            this.loading = false;
            console.log("---------------------------")
          }else{
            console.log("++++++++++++++++++++++++++");
            this.alertaServicio.error(this.translation[this.errores[data["Mensajes"][0]["CodigoMensaje"]]]);
            this.loading = false;
            this.robot = false;
            this.restore = false;
            this.restorePassword = false;
          }
      })
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
      width: '650px',
      disableClose: true 
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      console.log(result);
      this.servicioAPI.changePassword(result,this.f.password.value,this.f.username.value,this.usuarioenSession.DatosResult.Id).subscribe(data=>{
        console.log(data)

        //this.login()  si todo es correcto se llama a this.login()
        this.loginForm.get("password").setValue(result.password)
        this.onSubmit();
      })

    });
  }
  
  robotChallenge() {
    this.loading = true;
    this.robot = true;
    this.restore = false;
    //this.resolved("");
    
  }

  restoreChallenge() {
    this.loading = true;
    this.robot = true;
    this.restore = true;
    //this.resolved("");
  }

  onSubmit(codigo?:string) {
    // administrador  14acpo02s
    // iacpos iacpos
    console.log("submit...");
    this.submitted = true;
    this.f.codigosec.setValue('');
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.servicioAPI
      .getUsuario(this.f.username.value, this.f.password.value, this.tokenOrigen,this.tokenService.getConfig("CODIGO_APLICACION"), codigo)
      .pipe(first())
      .subscribe(
        data => {
          this.usuarioenSession = new userResponse().deserialize(data);
          console.log(this.usuarioenSession)
          console.log("CodigoMensaje: ", this.usuarioenSession.Mensajes["CodigoMensaje"]);

           if (this.usuarioenSession.Mensajes!=null && this.usuarioenSession.Mensajes.length>0) {
            switch (this.usuarioenSession.Mensajes[0]["CodigoMensaje"]) {
              case '-1':
                this.loading = false;
                this.robot = false;
                this.resolved_captcha = false;
                this.snackbarInfoService.openSnackBar(this.translation.UsuarioInvalido,'','red-snackbar');
                break;
              case '-5':
                this.loading = false;
                this.robot = false;
                this.resolved_captcha = false;
                this.snackbarInfoService.openSnackBar(this.translation.UsuarioBloqueado,'','red-snackbar');
                break;  
              case '-6':
                this.openDialog();
                this.loading = false;
                console.log("CAMBIA PASSWORD 195");
                break;
              case '10':
                console.log("doble actulización!!");
                
                this.vercodigoform = true;
                this.loading = false;
                break;    
              case '-11':
                console.log("código incorrecto!!");
                this.snackbarInfoService.openSnackBar(this.usuarioenSession.Mensajes[0]["DescripcionMensaje"],'','red-snackbar');
                this.vercodigoform = true;
                this.loading = false;
                break;                              
              default:
                //console.log(`Sorry, we are out of ${this.usuarioenSession.Mensajes[0]["CodigoMensaje"]}.`);
            
            }
           }
           else {
              if (this.usuarioenSession.DatosResult != null && this.usuarioenSession.DatosResult.DeBaja.toLowerCase() == 'false') {
                console.log("login...............................");
                this.loading = true;
                this.login();

              } else {
                this.loading = false;
                this.robot = false;
                this.alertaServicio.error(
                  'No se ha iniciado sesión correctamente o tu cuenta está deshabilitada temporalmente.'
                );
              }
          }

/*           if(this.usuarioenSession.Mensajes!=null && this.usuarioenSession.Mensajes.length>0 && this.usuarioenSession.Mensajes[0]["CodigoMensaje"]=="-6"){
            this.openDialog();
            this.loading = false;
            console.log("CAMBIA PASSWORD 195");

          } else 
            if (this.usuarioenSession.Mensajes!=null && this.usuarioenSession.Mensajes.length>0 && this.usuarioenSession.Mensajes[0]["CodigoMensaje"]=="10") {
              console.log("doble actulización!!");
              this.vercodigoform = true;
              this.loading = false;

            } else 
            if (this.usuarioenSession.Mensajes!=null && this.usuarioenSession.Mensajes.length>0 && this.usuarioenSession.Mensajes[0]["CodigoMensaje"]=="-11") {
              console.log("código incorrecto!!");
              this.vercodigoform = true;
              this.loading = false;
              this.alertaServicio.error(this.usuarioenSession.Mensajes[0]["DescripcionMensaje"]);
            } 
            else {
            
            if (this.usuarioenSession.DatosResult != null && this.usuarioenSession.DatosResult.DeBaja.toLowerCase() == 'false') {
              console.log("login...............................");
              this.loading = true;
              this.login();

            } else {
              this.loading = false;
              this.robot = false;
              this.alertaServicio.error(
                'No se ha iniciado sesión correctamente o tu cuenta está deshabilitada temporalmente.'
              );
            }
            
          } */
        },
        error => {
          this.httpErrorService.identificarErrores(error);
          this.loading = false;
          console.log("CAMBIA 215");
        }
      );
  }
  login(){
    console.log(this.returnUrl);
    this.loading = true;
    this.loadingDivisas = true;
    this.autentificador.login(this.usuarioenSession);
    this.servicioAPI.GetAppConfig(this.usuarioenSession.DatosResult.Id).subscribe(data => {

      localStorage.setItem('TieneInternet', data.DatosResult.AppConfigJsonCustommanConfigOtros.TieneInternet);
      localStorage.setItem('TieneTaquillaAutomatica', data.DatosResult.AppConfigJsonCustommanConfigOtros.TieneTaquillaAutomatica);
      localStorage.setItem('TieneAdmon', data.DatosResult.AppConfigJsonCustommanConfigOtros.TieneAdmon);
      localStorage.setItem('AsociarCentrosCosteCanales', data.DatosResult.AppConfigJsonCustommanConfigOtros.AsociarCentrosCosteCanales);
      localStorage.setItem('AsociarCentros', data.DatosResult.AppConfigJsonCustommanConfigOtros.AsociarCentros);

      localStorage.setItem('Language', data.DatosResult.AppConfigJsonCustommanConfig.Idioma);


      this.servicioAPI.GetDatosInicio().subscribe(datos => {
        localStorage.setItem('DivisaRefId', datos.DatosResult.DivisaRefId);

        this.servicioAPI.getDivisas().subscribe(divisas => {
          const DivisaRefId = datos.DatosResult.DivisaRefId; // se obtendra de /GetDatosInicio
          if (divisas == null || !divisas.DatosResult.ListaDivisas.length) {
            if(this.des){

              this.router.navigate([this.returnUrl],{queryParams:{pkid:this.des["pkid"]}});
            }else{

              this.router.navigate([this.returnUrl]);
            }
          }
          divisas.DatosResult.ListaDivisas.forEach(divisa => {
            if (divisa.pkId == DivisaRefId) {
              localStorage.setItem('NumeroDecimales', divisa.NumeroDecimales);
              localStorage.setItem('PosicionDivisa', divisa.PosicionDivisa);
              localStorage.setItem('SimboloDecimal', divisa.SimboloDecimal);
              localStorage.setItem('SimboloDerecha', divisa.SimboloDerecha);
              localStorage.setItem('simbolo', divisa.simbolo);
              if(this.des){

                this.router.navigate([this.returnUrl],{queryParams:{pkId:this.des["pkId"]}});
              }else{
  
                this.router.navigate([this.returnUrl]);
              }
              this.loadingDivisas = false;
              return;
            }
          });

       

        });
       /*  this.loading = false; */

      });

    });
  }

  funcionEncript(json) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    for (let index = 1; index <= json.length; index++) {
      const tokenMio = this.Mid(json, index, 1);
      const codigo = this.Mid(clavePrivada, ((index - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      const a = (this.Asc(codigo) ^ this.Asc(tokenMio)).toString(16);
      final = final + this.Right('0' + a, 2);
    }
    return final;
  }

  funcionDesencript(token) {
    const clavePrivada = '3ñdlfjdfksdffffs%i2343424kjfdsü&/@#$A·';
    let final = '';
    let j = 1;
    for (let index = 1; index <= token.length; index += 2) {
      const tokenMio = this.Mid(token, index, 2);
      const codigo = this.Mid(clavePrivada, ((j - 1) % clavePrivada.length) + 1, 1);
      // tslint:disable-next-line:no-bitwise
      final = final + this.Chr(this.Asc(codigo) ^ (parseInt('0x' + tokenMio, 16)));
      j = j + 1;
    }
    return final;
  }
  Mid(strMid, intBeg, intEnd) {
    if (strMid === null || strMid === '' || intBeg < 0) {
      return '';
    }
    intBeg -= 1;
    if (intEnd === null || intEnd === '') {
      return strMid.substr(intBeg);
    } else {
      return strMid.substr(intBeg, intEnd);
    }
  }

 Chr(num) {
    const res = String.fromCharCode(num);
    return res;
  }

  Asc(str) {
    return str.charCodeAt(0);
  }

  Right(str, n) {
    if (n <= 0) {
      return '';
    } else if (n > String(str).length) {
      return str;
    } else {
      const iLen = String(str).length;
      return String(str).substring(iLen, iLen - n);
    }
  }

  //https://dethariel.github.io/ng-recaptcha/basic 

  public resolved(captchaResponse: string): void {
    if (!this.restore) {
      if (!this.resolved_captcha) {
        this.resolved_captcha = true;
        this.onSubmit();
      } 
    }
    else { 
      console.log("Restore Pass");
      this.submitRestorePassword();
    }
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    console.log(`reCAPTCHA error encountered; details:`, errorDetails);
  }

  reenviarcodigo() {
    this.snackbarInfoService.openSnackBar('Se ha generado otro código de seguridad, consulta tu email','','red-snackbar');
    this.onSubmit();

  }
}
